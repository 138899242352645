import { Feature, FeatureCollection, MultiPolygon, Polygon } from 'geojson';
import { multiPolygon, polygon } from '@turf/helpers';
import isEmpty from 'lodash.isempty';
import { parseEnv } from '@plotr/common-utils';
import { CustomTerritory } from '@plotr/plotr-multiplayer-data';

const env = parseEnv({ API_V1: process.env.API_V1 });

async function getTerritoryZipBoundary(
  zipcodes: string[]
): Promise<Feature<Polygon | MultiPolygon> | null> {
  const endpoint = `${env.API_V1}/merge`;
  const mergeResponse = await fetch(endpoint, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ zipcodes: zipcodes, bbox: false }),
  });

  if (!mergeResponse.ok) {
    throw new Error(
      `Failed to fetch zip code boundaries: ${mergeResponse.statusText}`
    );
  }

  const responseBody = await mergeResponse.text();
  const data: FeatureCollection<MultiPolygon | Polygon> | null = responseBody
    ? JSON.parse(responseBody)
    : null;

  if (data?.features != null) {
    const coordinates = data.features.reduce<MultiPolygon['coordinates']>(
      (acc, feature) => {
        if (feature.geometry.type === 'Polygon') {
          acc.push(feature.geometry.coordinates);
        } else if (feature.geometry.type === 'MultiPolygon') {
          acc.push(...feature.geometry.coordinates);
        }
        return acc;
      },
      []
    );

    return multiPolygon(coordinates);
  }

  return null;
}

function getTerritoryCustomBoundary(
  territory: CustomTerritory
): Feature<Polygon> | null {
  const firstBoundary = Object.values(territory.boundaries)[0];
  if (firstBoundary && firstBoundary.coordinates) {
    return polygon([firstBoundary.coordinates]);
  }
  return null;
}

export interface GetTerritoryParams {
  accessToken: string;
  territory: CustomTerritory;
}

export default async function getTerritoryBoundary(params: GetTerritoryParams) {
  const { accessToken, territory } = params;

  if (isEmpty(territory.boundaries) || accessToken == null) return null;

  const zipcodes = Array.from(
    Object.values(territory.boundaries).map((boundary) =>
      boundary.id.padStart(5, '0')
    )
  );

  if (territory.type === 'ZIP' && zipcodes.length === 0) return null;

  try {
    if (territory.type === 'ZIP') {
      return getTerritoryZipBoundary(zipcodes);
    } else {
      return getTerritoryCustomBoundary(territory);
    }
  } catch (error) {
    console.error(error);
    return null;
  }
}
