import { useMemo } from 'react';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import useCustomPins from '~/src/global/hooks/useCustomPins';
import GroupCustomPinsMenu from './GroupCustomPinsMenu';
import MainCustomPinsMenu from './MainCustomPinsMenu';

import { Box, Breadcrumbs, Typography, IconButton } from '@mui/material';

const CustomPinsMenu = () => {
  const evaluatedPinId = useDynamicMapStore((state) => state.evaluatedPinId);
  const setEvaluatedPinId = useDynamicMapStore(
    (state) => state.setEvaluatedPinId
  );

  const selectedPinGroup = useDynamicMapStore(
    (state) => state.selectedPinGroup
  );
  const setSelectedPinGroup = useDynamicMapStore(
    (state) => state.setSelectedPinGroup
  );

  const customPins = useCustomPins();
  const evaluatedPin = useMemo(() => {
    if (evaluatedPinId == null) return null;
    return customPins.find(({ id }) => id === evaluatedPinId);
  }, [customPins, evaluatedPinId]);

  const breadcrumbs: { label: string; onClick?: () => void }[] = useMemo(() => {
    const defaultBreadcrumbs = [
      {
        label: 'Custom Pins',
        ...(selectedPinGroup != null || evaluatedPin != null
          ? {
              onClick: () => {
                setSelectedPinGroup(null);
                setEvaluatedPinId(null);
              },
            }
          : {}),
      },
    ];

    if (evaluatedPin == null && selectedPinGroup != null) {
      return [...defaultBreadcrumbs, { label: selectedPinGroup }];
    }

    if (evaluatedPin != null) {
      return [
        ...defaultBreadcrumbs,
        {
          label: evaluatedPin.group,
          onClick: () => {
            setSelectedPinGroup(evaluatedPin.group);
            setEvaluatedPinId(null);
          },
        },
        { label: evaluatedPin.label },
      ];
    }

    return defaultBreadcrumbs;
  }, [evaluatedPin, selectedPinGroup, setEvaluatedPinId, setSelectedPinGroup]);

  return (
    <Box display="flex" height="100%" flexDirection="column">
      <Box display="flex" alignItems="center" padding={2}>
        {(selectedPinGroup != null || evaluatedPin != null) && (
          <IconButton
            onClick={() => {
              if (evaluatedPin != null) {
                setEvaluatedPinId(null);
              } else if (selectedPinGroup != null) {
                setSelectedPinGroup(null);
              }
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs.map((crumb, index) => {
            const clickable = crumb.onClick != null;
            const textColor = clickable || index === 0 ? 'MenuText' : 'inherit';

            return (
              <Typography
                key={crumb.label}
                variant="h6"
                color={textColor}
                onClick={clickable ? crumb.onClick : undefined}
                sx={{
                  cursor: clickable ? 'pointer' : 'default',
                  textDecoration: clickable ? 'underline' : 'none',
                }}
              >
                {crumb.label}
              </Typography>
            );
          })}
        </Breadcrumbs>
      </Box>
      <Box padding={2} paddingRight={0} flex="1 1 auto" overflow="auto">
        {selectedPinGroup != null ? (
          <GroupCustomPinsMenu />
        ) : (
          <MainCustomPinsMenu />
        )}
      </Box>
    </Box>
  );
};

export default CustomPinsMenu;
