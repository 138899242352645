import {
  useState,
  useEffect,
  useContext,
  createContext,
  type ReactNode,
} from 'react';
import type { MapRef } from 'react-map-gl';

// Context to store the map
const MapContext = createContext<MapRef | null>(null);

export interface MapProviderProps {
  children: ReactNode;
  map: MapRef | null;
}

// Provides the map when its style is fully loaded
export function MapProvider(props: MapProviderProps) {
  const [isLoaded, setIsLoaded] = useState(false);

  // Mark the map as not loaded when the style is loading
  useEffect(() => {
    if (props.map == null) return;

    const handleDataLoading = () => {
      setIsLoaded(false);
    };

    props.map?.on('styledataloading', handleDataLoading);
    return () => {
      props.map?.off('styledataloading', handleDataLoading);
    };
  }, [props.map]);

  // Mark the map as loaded when the style loads
  useEffect(() => {
    if (props.map == null) return;

    const handleLoad = () => {
      setIsLoaded(true);
    };

    if (props.map.isStyleLoaded()) {
      handleLoad();
    }

    props.map?.on('style.load', handleLoad);
    return () => {
      props.map?.off('style.load', handleLoad);
    };
  }, [props.map]);

  const value = isLoaded ? props.map : null;

  return (
    <MapContext.Provider value={value}>{props.children}</MapContext.Provider>
  );
}

export default function useMapContext() {
  return useContext(MapContext);
}
