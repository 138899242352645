import { Geometry } from 'geojson';
import { useMemo } from 'react';

const MAX_CACHE_SIZE_BYTES = 20 * 1024 * 1024; // 20 MB

export interface GeometryCacheEntry {
  data: Geometry;
  lastAccessed: number;
  sizeInBytes: number;
  metadata?: Record<string, unknown>;
}

function estimateSizeInBytes(geometry: Geometry): number {
  return new Blob([JSON.stringify(geometry)]).size;
}

function getCacheSize(geometryCache: Map<string, GeometryCacheEntry>) {
  return Array.from(geometryCache.values()).reduce(
    (totalSize, cached) => totalSize + cached.sizeInBytes,
    0,
  );
}

function checkCacheSize(geometryCache: Map<string, GeometryCacheEntry>) {
  const cacheSize = getCacheSize(geometryCache);

  if (cacheSize > MAX_CACHE_SIZE_BYTES) {
    console.debug('Geometry cache size exceeded, pruning...');
    const sortedCache = Array.from(geometryCache.entries()).sort(
      ([, a], [, b]) => a.lastAccessed - b.lastAccessed,
    );

    let currentSize = cacheSize;
    let i = 0;

    // reduce cache maintenance operations by pruning down to 80% of the max cache size
    const TARGET_SIZE = MAX_CACHE_SIZE_BYTES * 0.8;

    while (currentSize > TARGET_SIZE && i < sortedCache.length) {
      const [key, entry] = sortedCache[i];

      geometryCache.delete(key);
      currentSize -= entry.sizeInBytes;
      i += 1;
    }
    console.debug('Geometry cache pruned');
  }
}

function setCached(
  key: string,
  geometry: Geometry,
  metadata?: Record<string, unknown>,
): void {
  const size = estimateSizeInBytes(geometry);
  const entry: GeometryCacheEntry = {
    data: geometry,
    lastAccessed: Date.now(),
    sizeInBytes: size,
    metadata,
  };
  geometryCache.set(key, entry);
  checkCacheSize(geometryCache);
}

function getCached(key: string): GeometryCacheEntry | null {
  const cached: GeometryCacheEntry | null = geometryCache.get(key) ?? null;

  if (cached == null) return null;

  cached.lastAccessed = Date.now();
  geometryCache.set(key, cached);
  return cached;
}

// global geometry cache
const geometryCache = new Map() as Map<string, GeometryCacheEntry>;

export default function useGeometryCache() {
  return useMemo(
    () => ({
      get: getCached,
      set: setCached,
    }),
    [],
  );
}
