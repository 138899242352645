import * as Y from 'yjs';

export function yFromJSON(data: unknown): unknown {
  if (Array.isArray(data)) {
    const yArray = new Y.Array();
    yArray.push(data.map(yFromJSON));
    return yArray;
  } else if (data != null && typeof data === 'object') {
    const yMap = new Y.Map();
    Object.entries(data).forEach(([key, value]) => {
      yMap.set(key, yFromJSON(value));
    });
    return yMap;
  } else {
    return data;
  }
}
