import { useMemo } from 'react';
import useCustomPins from '~/src/global/hooks/useCustomPins';
import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';
import PinGroupCard from '../pin-cards/PinGroupCard';

import { Box, Grid } from '@mui/material';

const MainCustomPinsMenu = () => {
  const customPins = useCustomPins();

  const pinGroups = useMemo(() => {
    const groups = new Map<string, number>();
    customPins.forEach((pin) => {
      groups.set(pin.group, (groups.get(pin.group) || 0) + 1);
    });
    return Array.from(groups.entries());
  }, [customPins]);

  const hasPins = customPins.length > 0;
  const hasPinGroups = pinGroups.length > 0;

  const setSelectedPinGroup = useDynamicMapStore(
    (state) => state.setSelectedPinGroup
  );

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {!hasPins && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ fontWeight: 'light' }}>
              To use the Custom Pins feature, simply right-click on the map at
              the location where you want to drop a pin. Once a pin is placed,
              demographics about the surrounding area can be generated.
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ fontWeight: 'light' }}>
              Explore and mark locations on the map with Custom Pins to gather
              insights about different areas.
            </Box>
          </Grid>
        </Grid>
      )}
      {hasPinGroups && (
        <Box>
          {pinGroups.map(([group, count]) => (
            <PinGroupCard
              key={group}
              group={group}
              onAction={() => setSelectedPinGroup(group)}
              pinCount={count}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default MainCustomPinsMenu;
