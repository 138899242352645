import FlyToIcon from '@mui/icons-material/FlightTakeoff';
import {
  Box,
  Chip,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  CustomTerritory,
  TerritoryType,
  plotrMultiplayerData,
} from '@plotr/plotr-multiplayer-data/src';
import { featureCollection, polygon } from '@turf/helpers';
import {
  Feature,
  Geometry,
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
  Polygon,
} from 'geojson';
import { Ref } from 'react';
import useGeometryCache, {
  GeometryCacheEntry,
} from '~/src/common/hooks/useGeometryCache';
import { parseEnv } from '../../../../../common-utils/src';

import fitMapToBoundary from '~/src/features/dynamic-map/helpers/fitMapToBoundary';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import useMapContext from '~/src/features/dynamic-map/hooks/useMapContext';

const env = parseEnv({ API_V1: process.env.API_V1 });

const TerritoryCard = ({
  cardRef,
  territory,
  onEdit,
}: {
  cardRef?: Ref<HTMLDivElement>;
  territory: CustomTerritory;
  onEdit: () => void;
}) => {
  const customTerritoryMethods = plotrMultiplayerData.methods?.territories;

  const map = useMapContext();

  const setEvaluatedTerritoryId = useDynamicMapStore(
    (state) => state.setEvaluatedTerritoryId
  );

  const selectTerritoryId = useDynamicMapStore(
    (state) => state.selectTerritoryId
  );

  const geometryCache = useGeometryCache();

  if (territory == null || customTerritoryMethods == null) {
    return null;
  }

  const handleSelectBoundaries = (e: React.MouseEvent) => {
    e.stopPropagation();
    setEvaluatedTerritoryId(territory.id);
    selectTerritoryId(territory.id);
  };

  //TODO: Extend this function to handle other territory types other than zipcodes
  const flyToTerritory = async (territory: CustomTerritory) => {
    //return if not map ready or territory has no boundaries
    if (map == null || Object.keys(territory.boundaries).length === 0) return;

    let data: Feature<Polygon>;
    if (territory.type !== TerritoryType.Custom) {
      // First check if we have cached geometry for this territory
      const zoomRangeFourToSixteen = new Array(12).fill(0).map((_, i) => i + 4);
      const potentialCacheKeys = zoomRangeFourToSixteen.map(
        (zoom) => `${territory.id}::${zoom}`
      );
      const cachedTerritories = potentialCacheKeys.map((key) =>
        geometryCache.get(key)
      );
      const isGeometryWithCoordinates = (
        geometry: Geometry
      ): geometry is
        | Point
        | LineString
        | Polygon
        | MultiPoint
        | MultiLineString
        | MultiPolygon => {
        return 'coordinates' in geometry;
      };

      const highestQualityCached =
        cachedTerritories.reduce<GeometryCacheEntry | null>((acc, curr) => {
          if (
            curr &&
            isGeometryWithCoordinates(curr.data) &&
            acc &&
            isGeometryWithCoordinates(acc?.data)
          ) {
            if (
              !acc ||
              curr.data.coordinates.length > (acc.data.coordinates?.length ?? 0)
            ) {
              return curr;
            }
          }
          return acc;
        }, null);
      // If we have cached geometry, use it to fit the map
      if (highestQualityCached != null) {
        fitMapToBoundary(
          map,
          highestQualityCached.data as unknown as Feature<Polygon>
        );
        return;
      }

      // If we don't have cached geometry, fetch it from the API
      const zipcodes = Object.values(territory.boundaries).map((boundary) =>
        boundary.id.padStart(5, '0')
      );

      try {
        const endpoint = `${env.API_V1}/merge`;
        const response = await fetch(endpoint, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ zipcodes, bbox: true }),
        });
        data = await response.json();
      } catch (err) {
        console.error('Error fetching merged boundaries:', err);
      }
    } else {
      const coordinates =
        territory.boundaries[Object.keys(territory.boundaries)[0]].coordinates;
      data = featureCollection([polygon([coordinates])]);
    }
    fitMapToBoundary(map, data);
  };

  const boundaryCount = territory && Object.keys(territory.boundaries).length;

  return (
    <Paper
      ref={cardRef}
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        cursor: 'pointer',
        padding: 1,
        height: '100%',
        '&:hover': {
          boxShadow: 6,
        },
        width: '100%',
      }}
      onClick={handleSelectBoundaries}
    >
      <Box flex={1} display="flex" flexDirection="column" alignItems="center">
        <Typography
          variant="body1"
          sx={{
            mb: '0px',
            display: 'flex',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {territory.label}
        </Typography>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ cursor: 'pointer', '&:hover': { color: 'text.primary' } }}
          onClick={(e) => {
            onEdit();
            e.stopPropagation();
          }}
        >
          Edit
        </Typography>
      </Box>
      {territory.type && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent={'space-between'}
          textAlign={'center'}
        >
          <Tooltip title="Fly To Territory">
            <IconButton
              onClick={(e) => {
                flyToTerritory(territory);
                e.stopPropagation();
              }}
            >
              <FlyToIcon />
            </IconButton>
          </Tooltip>
          <Chip
            label={
              territory.type === TerritoryType.Custom
                ? territory.type
                : `${territory.type} ${boundaryCount}`
            }
            size="small"
            sx={{
              backgroundColor: '#f5f5f5',
              color: 'text.secondary',
            }}
          />
        </Box>
      )}
    </Paper>
  );
};

export default TerritoryCard;
