import logo from 'data-url:src/assets/images/plotr_logo.svg';
import * as styles from './Page404.module.css';

export default function Page404() {
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.contained}`}>
        <img src={logo} style={{ height: 60 }} alt="Plotr" />
        <h1 className={`${styles.h1}`}>Error: 404</h1>
        <p className={`${styles.par}`}>Page not Found</p>
      </div>
    </div>
  );
}
