import { create, StateCreator } from 'zustand';
import { persist, PersistOptions } from 'zustand/middleware';
import {
  Expression,
  InsightTilesetSources,
} from '~/src/features/dynamic-map-controller/components/MapLayersMenu/components/EditLayerModal/EditLayerModal';

export interface GroupComponentProps {
  beforeId: string | undefined;
  showLayerZoom: number;
  enabled: boolean;
  opacity: number;
}

export interface ControllableLayerGroup {
  layerIds: string[];
  groupComponentProps?: GroupComponentProps;
}

const formatNumber = (num: number, fractionDigits: number) =>
  Intl.NumberFormat('en-US', {
    maximumFractionDigits: fractionDigits,
  }).format(num);

const formatDollars = (num: number, fractionDigits: number) =>
  Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: fractionDigits,
  }).format(num);

export type Format =
  | 'integer'
  | 'percent'
  | 'currency'
  | 'dollars'
  | 'raw_change'
  | 'chips';
export function formatValue(
  value: string | number,
  format?: Format
): string | string[] {
  const numValue = Number(value);

  if (!isNaN(numValue) && format === 'integer') {
    return formatNumber(numValue, 0);
  }

  if (!isNaN(numValue) && format === 'percent') {
    return `${Math.round(numValue * 100)}%`;
  }

  if (!isNaN(numValue) && format === 'currency') {
    return formatDollars(numValue, 2);
  }

  if (!isNaN(numValue) && format === 'dollars') {
    return formatDollars(numValue, 0);
  }

  if (!isNaN(numValue) && format === 'raw_change') {
    const sign = numValue > 0 ? '+' : '';
    return `${sign}${formatNumber(numValue, 2)}`;
  }

  if (typeof value === 'number') {
    return formatNumber(value, 2);
  }

  if (format === 'chips') {
    try {
      return JSON.parse(value);
    } catch (error) {
      console.error('Failed to parse JSON for chips format:', error);
      return [];
    }
  }

  return value;
}

type DataFieldConfig = {
  format?: Format;
  default?: string | number;
} & (
  | {
      key: string;
    }
  | {
      expression: Expression;
    }
);

export interface LayerStyleConfig {
  idLabelFlag?: boolean;
  colors: string[];
  threshold?: number[] | string[];
  expression?: Expression;
  fallback?: number | string;
  format?: Format;
  opacityStops?: number[];
  unit?: string;
  boundLabels?: string[];
  hideBoundValues?: boolean;
}

export interface LayerDataConfig {
  header?: string;
  caption?: string;
  fields?: Array<{
    [Field: string]: string | DataFieldConfig;
  }>;
}

export interface LayerCard {
  id: string;
  type: string;
  featureType: 'point' | 'line' | 'polygon';
  sourceTileset?: InsightTilesetSources;
  insight: string;
  displayName: string;
  boundLabels?: string[];
  imageUrl?: string;
  opacity: number;
  layerGroup?: ControllableLayerGroup;
  styleConfig: LayerStyleConfig;
  dataConfig?: LayerDataConfig;
  isBoundary?: boolean;
}

export interface LayerStoreState {
  layers: LayerCard[];
  setLayers: (layers: LayerCard[]) => void;
  updateLayer: (id: string, changes: Partial<LayerCard>) => void;
  showData: boolean;
  setShowData: (showData: boolean) => void;
}

const createStore: StateCreator<LayerStoreState> = (set) => ({
  layers: [],
  setLayers: (layers) => set({ layers }),
  updateLayer: (id, changes) => {
    set((state) => ({
      layers: state.layers.map((layer) =>
        layer.id === id ? { ...layer, ...changes } : layer
      ),
    }));
  },
  showData: false,
  setShowData: (showData) => set({ showData: showData }),
});

const persistConfig: PersistOptions<LayerStoreState> = {
  name: 'layer-data',
  version: 1, // increment version with each breaking change to prevent old cached data from being used
};

// Create the store with the persist middleware
const useLayersStore = create(persist(createStore, persistConfig));

export default useLayersStore;
