import turfBbox from '@turf/bbox';
import { useEffect, useState } from 'react';
import { Feature, Geometry } from 'geojson';
import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  FlightTakeoff as FlightTakeoffIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';

import useMapContext from '~/src/features/dynamic-map/hooks/useMapContext';
import { ClientGeometry } from '~/src/features/dynamic-map/services/clientGeometriesService';
import useClientGeometriesStore from '../../dynamic-map/hooks/useClientGeometriesStore';

const VisibilityButton = (props: { filename: string }) => {
  const hiddenGeometryFiles = useClientGeometriesStore(
    (state) => state.hiddenGeometryFiles,
  );
  const toggleGeometryVisibility = useClientGeometriesStore(
    (state) => state.toggleGeometryVisibility,
  );

  return (
    <Box display="flex" alignItems="center" marginRight={2}>
      <IconButton
        onClick={() => {
          toggleGeometryVisibility(props.filename);
        }}
        size="small"
        sx={{ marginLeft: 1 }}
      >
        {hiddenGeometryFiles.includes(props.filename) ? (
          <VisibilityOffIcon />
        ) : (
          <VisibilityIcon />
        )}
      </IconButton>
    </Box>
  );
};

const GeometryGroupCard = ({
  label,
  filename,
  onAction,
}: {
  label: string;
  filename?: string;
  onAction: () => void;
}) => (
  <Paper variant="outlined" sx={{ marginBottom: 1 }}>
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding={3}
    >
      <Typography variant="body1" sx={{ flexGrow: 1 }}>
        {label}
      </Typography>
      {filename != null ? <VisibilityButton filename={filename} /> : null}
      <Button
        variant="outlined"
        color="primary"
        onClick={onAction}
        size="small"
        sx={{
          borderRadius: 1,
          textTransform: 'none',
          whiteSpace: 'nowrap',
          minWidth: 'auto',
        }}
      >
        View Geometries
      </Button>
    </Box>
  </Paper>
);

const GeometryCard = ({
  geometry,
  onFlyTo,
}: {
  geometry: Feature<Geometry>;
  onFlyTo: () => void;
}) => {
  const name =
    geometry.properties?.name ||
    geometry.properties?.grouping[0].name ||
    'Zone';

  return (
    <Paper variant="outlined" sx={{ marginBottom: 1 }}>
      <Box display="flex" justifyContent="space-between" padding={3}>
        <Typography variant="body1" sx={{ paddingRight: 1 }}>
          {name}
        </Typography>
        <Button
          variant="outlined"
          startIcon={<FlightTakeoffIcon />}
          onClick={onFlyTo}
          sx={{
            whiteSpace: 'nowrap',
            minWidth: 'auto',
          }}
        >
          Fly to
        </Button>
      </Box>
    </Paper>
  );
};

type GroupingOption = { type: string; name: string };

const hierarchyOptions = ['region', 'place', 'postcode'];

export const ClientGeometriesMenu = () => {
  const map = useMapContext();
  const { clientGeometries } = useClientGeometriesStore();

  const [selectedFile, setSelectedFile] = useState<ClientGeometry | null>(null);
  const [allFileFeatures, setAllFileFeatures] = useState<Feature<Geometry>[]>(
    [],
  );
  const [filteredFileFeatures, setFilteredFileFeatures] = useState<
    Feature<Geometry>[]
  >([]);
  const [groupOptions, setGroupOptions] = useState<GroupingOption[]>([]);
  const [groupBreadcrumbs, setGroupBreadcrumbs] = useState<GroupingOption[]>(
    [],
  );
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');

  // const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handleSelectFile = (file: ClientGeometry) => {
    setSelectedFile(file);
    setAllFileFeatures(file.data.features);
  };

  const groupableFeatureCheck = (feature: Feature<Geometry>) => {
    return (
      feature.properties?.grouping != null &&
      feature?.properties?.grouping[0].type
    );
  };

  useEffect(() => {
    if (groupBreadcrumbs.length > 0) {
      //filter by existing breadcrumbs
      const newFilteredFileFeatures: Feature<Geometry>[] =
        allFileFeatures.filter(
          (feature) =>
            //check the feature is groupable and that it has all the breadcrumbs starting from the first in the array
            groupableFeatureCheck(feature) &&
            groupBreadcrumbs.every((breadcrumb) => {
              //check that the feature has the breadcrumb type
              return feature.properties?.grouping.find(
                (option: GroupingOption) =>
                  option.type === breadcrumb.type &&
                  option.name === breadcrumb.name,
              );
            }),
        );
      setFilteredFileFeatures(newFilteredFileFeatures);
    } else {
      setFilteredFileFeatures(allFileFeatures);
    }
  }, [allFileFeatures, groupBreadcrumbs]);

  useEffect(() => {
    const newGroupOptions: GroupingOption[] = [];

    //the length of groupBreadcrumbs should be the heirarchy grouping level we display
    const displayHeirarchyLevel = hierarchyOptions[groupBreadcrumbs.length];
    const uniqueGroupingOptions = new Set<string>();

    function addGroupingOption(option: GroupingOption) {
      const optionKey = `${option.type}-${option.name}`;
      if (!uniqueGroupingOptions.has(optionKey)) {
        newGroupOptions.push(option);
        uniqueGroupingOptions.add(optionKey);
      }
    }

    filteredFileFeatures.forEach((feature) => {
      const featureGroupingOptions = feature.properties?.grouping;
      if (!featureGroupingOptions) return;
      const relevantGroupingOption = featureGroupingOptions.find(
        //find option that has type of displayHeirarchyLevel
        (groupingOption: GroupingOption) =>
          groupingOption.type.includes(displayHeirarchyLevel),
      );
      if (relevantGroupingOption) {
        addGroupingOption(relevantGroupingOption);
      }
    });

    setGroupOptions(newGroupOptions);
  }, [filteredFileFeatures, groupBreadcrumbs]);

  const handleBack = () => {
    if (groupBreadcrumbs.length > 0) {
      setGroupBreadcrumbs((prevGroupBreadcrumbs) =>
        prevGroupBreadcrumbs.slice(0, -1),
      );
    } else {
      setSelectedFile(null);
      setAllFileFeatures([]);
      setFilteredFileFeatures([]);
    }
  };

  const handleSelectGrouping = (grouping: { type: string; name: string }) => {
    setGroupBreadcrumbs([...groupBreadcrumbs, grouping]);
  };

  const handleFlyTo = (geometry: Feature<Geometry>) => {
    const [minX, minY, maxX, maxY] = turfBbox(geometry);
    const bounds: [[number, number], [number, number]] = [
      [minX, minY],
      [maxX, maxY],
    ];
    map?.fitBounds(bounds, { padding: 50 });
  };

  function cleanUpBreadcrumbs(breadcrumbs: GroupingOption[]): GroupingOption[] {
    return breadcrumbs.map((breadcrumb, index, array) => {
      if (index === 0) return breadcrumb; // First breadcrumb remains unchanged

      const previousBreadcrumb = array[index - 1].name;
      const currentBreadcrumb = breadcrumb.name;

      // Splitting breadcrumb names into parts
      const prevParts = previousBreadcrumb.split(', ');
      const currentParts = currentBreadcrumb.split(', ');

      // Removing parts from currentBreadcrumb that are found in previousBreadcrumb
      const cleanedParts = currentParts.filter(
        (part) => !prevParts.includes(part),
      );

      return { ...breadcrumb, name: cleanedParts.join(', ') };
    });
  }

  return (
    <Box display="flex" flexDirection="column" overflow="auto">
      <Typography variant="h6" sx={{ padding: 2 }}>
        Geometry Files
      </Typography>
      {selectedFile && (
        <Box paddingLeft={2} paddingRight={0} paddingTop={1}>
          <TextField
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
          />
        </Box>
      )}
      <Box display="flex" alignItems="center" padding={1}>
        {(selectedFile || filteredFileFeatures.length > 0) && (
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        )}
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {selectedFile && (
            <Typography variant="body1" color="inherit">
              {selectedFile.filename.replace(/(\.json|\.geojson)\.gz$/, '')}
            </Typography>
          )}
          {cleanUpBreadcrumbs(groupBreadcrumbs).map((breadcrumb, index) => (
            <Typography
              key={`breadcrumb-${index}`}
              variant="body1"
              color="inherit"
            >
              {breadcrumb.name}
            </Typography>
          ))}
        </Breadcrumbs>
      </Box>
      <Box padding={2} paddingRight={0} flex={1}>
        {!selectedFile && (
          <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
            {clientGeometries.map((file) => (
              <GeometryGroupCard
                key={file.filename}
                label={file.filename.replace(/(\.json|\.geojson)\.gz$/, '')}
                filename={file.filename}
                onAction={() => handleSelectFile(file)}
              />
            ))}
          </Box>
        )}
        {selectedFile && groupOptions.length > 0 && (
          <>
            <Typography variant="h6">Groups</Typography>
            <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
              {groupOptions
                .filter((option) =>
                  option.name
                    .toLowerCase()
                    .includes(debouncedSearchTerm.toLowerCase()),
                )
                .map(
                  (option: GroupingOption) =>
                    option.name &&
                    option.type && (
                      <GeometryGroupCard
                        key={option.name}
                        label={option.name}
                        onAction={() => handleSelectGrouping(option)}
                      />
                    ),
                )}
            </Box>
          </>
        )}

        {selectedFile && (
          <>
            <Typography variant="h6">Zones</Typography>
            <Box sx={{ paddingTop: 1, paddingBottom: 1 }} overflow={'auto'}>
              {filteredFileFeatures
                .filter(
                  (feature) =>
                    feature?.properties?.grouping != null &&
                    feature?.properties?.grouping[0].name &&
                    feature?.properties?.grouping[0].name
                      .toLowerCase()
                      .includes(debouncedSearchTerm.toLowerCase()),
                )
                .map((geometry) => (
                  <GeometryCard
                    key={geometry.id || geometry.properties?.grouping[0].name}
                    geometry={geometry}
                    onFlyTo={() => handleFlyTo(geometry)}
                  />
                ))}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ClientGeometriesMenu;
