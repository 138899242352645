import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import CustomDrawer from '../custom-drawer/CustomDrawer';
import DemographicSummary from '../demographic-point-lookup/DemographicSummary';
import { MenuName } from './DynamicMapController';
import useDemographicStore from '../demographic-point-lookup/hooks/useDemographicStore';
import useDynamicMapStore from '../dynamic-map/hooks/useDynamicMapStore';

export default function DemographicInfoController() {
  const evaluatedPinId = useDynamicMapStore((state) => state.evaluatedPinId);

  const evaluatedDemographicEntity = useDynamicMapStore(
    (state) => state.evaluatedDemographicEntity
  );
  const setEvaluatedDemographicEntity = useDynamicMapStore(
    (state) => state.setEvaluatedDemographicEntity
  );

  const [, setActiveMenu] = useState<MenuName>(MenuName.Layers);

  const setClickedPOIFeature = useDynamicMapStore(
    (state) => state.setClickedPOiFeature
  );
  const isEditingTerritory = useDynamicMapStore(
    (state) => state.isEditingTerritory
  );
  const summaryData = useDemographicStore(
    (state) => state.demographicSummaryData
  );

  const toggleDrawer = () => {
    if (evaluatedDemographicEntity?.type === 'point') {
      setClickedPOIFeature(null);
    }
    if (evaluatedDemographicEntity != null) {
      setEvaluatedDemographicEntity(null);
    }
  };

  useEffect(() => {
    if (evaluatedPinId != null) {
      setActiveMenu(MenuName.CustomPins);
    }
  }, [evaluatedPinId]);

  const shouldDrawerBeOpen =
    evaluatedDemographicEntity != null &&
    !isEditingTerritory &&
    summaryData != null;

  return (
    <CustomDrawer
      drawerOpen={shouldDrawerBeOpen}
      handleClose={toggleDrawer}
      showCloseButton
    >
      <Box flex={1} paddingY={1} overflow="auto" position="relative">
        <Box height={1} padding={1}>
          <DemographicSummary />
        </Box>
      </Box>
    </CustomDrawer>
  );
}
