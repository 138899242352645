import type { LayerConfig } from '../components/MapLayersMenu/components/EditLayerModal/EditLayerModal';
import { LayerType } from '../components/MapLayersMenu/components/LayerManager';

const squareMetersPerSquareMile = 2589988.11;
export const Color = {
  transparent: 'rgba(0, 0, 0, 0)',
  white: '#ffffff',
  blue: '#0000FF',
  yellow: '#eef91a',
  yellowOrange: '#f9d116',
  orange: '#fcac03',
  darkOrange: '#fc8003',
  red: '#ff0000',
  purple: '#a020f0',
  darkPurple: '#4b0082',
  lightBlue: '#80c3e6',
  lightGreen: '#a6d96a',
  darkGreen: '#1a9641',
  lightGray: '#d8daeb',
  darkGray: '#a6a6a6',
  lightRed: '#e34a33',
  darkRed: '#b30000',
  lightYellow: '#fdae61',
  darkYellow: '#ff0000',

  urbanCore: '#FF0000',
  urban: '#FFA500',
  metropolitan: '#FFD700',
  suburban: '#FFFFE0',
  inTown: '#98FB98',
  rural: '#008000',
};

// HACK: I don't like that this has to use trafficLayerIds as a parameter, but for now it'll do.
export default function getMapLayerDefinitions(
  trafficLayerIds: string[]
): Record<string, LayerConfig> {
  return {
    bg_urbanicity_urban_core: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Block Group - Urban Core',
      category: 'Population.Urbanicity',
      sourceTileset: {
        label: 'Block Group Urbanicity',
        source: 'luketruitt1.bg_urbanicity',
        source_layer: 'bg_urbanicity',
      },
      styleConfig: {
        colors: [Color.transparent, Color.urbanCore, Color.transparent],
        threshold: [5, 6, 7],
        expression: ['get', 'urbanicity'],
        fallback: 0,
        opacityStops: [0, 1, 0],
      },
      dataConfig: {
        header: 'id',
      },
    },
    bg_urbanicity_urban: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Block Group - Urban',
      category: 'Population.Urbanicity',
      sourceTileset: {
        label: 'Block Group Urbanicity',
        source: 'luketruitt1.bg_urbanicity',
        source_layer: 'bg_urbanicity',
      },
      styleConfig: {
        colors: [Color.transparent, Color.urban, Color.transparent],
        threshold: [4, 5, 6],
        expression: ['get', 'urbanicity'],
        fallback: 0,
        opacityStops: [0, 1, 0],
      },
      dataConfig: {
        header: 'id',
      },
    },
    bg_urbanicity_metropolitan: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Block Group - Metropolitan',
      category: 'Population.Urbanicity',
      sourceTileset: {
        label: 'Block Group Urbanicity',
        source: 'luketruitt1.bg_urbanicity',
        source_layer: 'bg_urbanicity',
      },
      styleConfig: {
        colors: [Color.transparent, Color.metropolitan, Color.transparent],
        threshold: [3, 4, 5],
        expression: ['get', 'urbanicity'],
        fallback: 0,
        opacityStops: [0, 1, 0],
      },
      dataConfig: {
        header: 'id',
      },
    },
    bg_urbanicity_suburban: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Block Group - Suburban',
      category: 'Population.Urbanicity',
      sourceTileset: {
        label: 'Block Group Urbanicity',
        source: 'luketruitt1.bg_urbanicity',
        source_layer: 'bg_urbanicity',
      },
      styleConfig: {
        colors: [Color.transparent, Color.suburban, Color.transparent],
        threshold: [2, 3, 4],
        expression: ['get', 'urbanicity'],
        fallback: 0,
        opacityStops: [0, 1, 0],
      },
      dataConfig: {
        header: 'id',
      },
    },
    bg_urbanicity_in_town: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Block Group - In-Town',
      category: 'Population.Urbanicity',
      sourceTileset: {
        label: 'Block Group Urbanicity',
        source: 'luketruitt1.bg_urbanicity',
        source_layer: 'bg_urbanicity',
      },
      styleConfig: {
        colors: [Color.transparent, Color.inTown, Color.transparent],
        threshold: [1, 2, 3],
        expression: ['get', 'urbanicity'],
        fallback: 0,
        opacityStops: [0, 1, 0],
      },
      dataConfig: {
        header: 'id',
      },
    },
    bg_urbanicity_rural: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Block Group - Rural',
      category: 'Population.Urbanicity',
      sourceTileset: {
        label: 'Block Group Urbanicity',
        source: 'luketruitt1.bg_urbanicity',
        source_layer: 'bg_urbanicity',
      },
      styleConfig: {
        colors: [Color.transparent, Color.rural, Color.transparent],
        threshold: [0, 1, 2],
        expression: ['get', 'urbanicity'],
        fallback: 0,
        opacityStops: [0, 1, 0],
      },
      dataConfig: {
        header: 'id',
      },
    },
    bg_total_population: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Block Group - Population',
      category: 'Population',
      sourceTileset: {
        label: 'Block Group Insights',
        source: 'luketruitt1.block_group_insights',
        source_layer: 'block_group_insights',
      },
      styleConfig: {
        threshold: [0, 3000, 10000, 60000],
        colors: [
          Color.white,
          Color.lightGray,
          Color.lightGreen,
          Color.darkGreen,
        ],
        fallback: 0,
        expression: [
          'case',
          ['==', ['get', 'area'], 0],
          0,
          [
            'round',
            [
              '*',
              ['/', ['get', 'total_population'], ['get', 'area']],
              squareMetersPerSquareMile,
            ],
          ],
        ],
        opacityStops: [0, 1, 1, 1],
        unit: 'people / mi\u00B2',
      },
      dataConfig: {
        header: 'id',
        fields: [
          { Population: 'total_population' },
          {
            'People / mi\u00B2': {
              expression: [
                'case',
                ['==', ['get', 'area'], 0],
                0,
                [
                  'round',
                  [
                    '*',
                    ['/', ['get', 'total_population'], ['get', 'area']],
                    squareMetersPerSquareMile,
                  ],
                ],
              ],
            },
          },
        ],
      },
    },
    seasonal_population: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Block Group - Seasonal Population',
      category: 'Population.Seasonal',
      sourceTileset: {
        label: 'Block Group Insights',
        source: 'luketruitt1.block_group_insights',
        source_layer: 'block_group_insights',
      },
      styleConfig: {
        threshold: [0, 10, 100, 1000],
        colors: [Color.lightYellow, Color.yellow, Color.orange, Color.red],
        fallback: 0,
        expression: [
          'case',
          ['==', ['get', 'area'], 0],
          0,
          [
            'round',
            [
              '*',
              ['/', ['get', 'seasonal_population'], ['get', 'area']],
              squareMetersPerSquareMile,
            ],
          ],
        ],
        opacityStops: [0, 1, 1, 1],
        unit: 'people / mi\u00B2',
      },
      dataConfig: {
        header: 'id',
        fields: [
          { Population: 'seasonal_population' },
          {
            'People / mi\u00B2': {
              expression: [
                'case',
                ['==', ['get', 'area'], 0],
                0,
                [
                  'round',
                  [
                    '*',
                    ['/', ['get', 'seasonal_population'], ['get', 'area']],
                    squareMetersPerSquareMile,
                  ],
                ],
              ],
            },
          },
        ],
      },
    },
    bg_population_growth_5yr: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Block Group - 5-Year Projected Population Change',
      permission: 'read:insight_pop_growth_5yr',
      category: 'Population.Change',
      sourceTileset: {
        label: 'Block Group Insights',
        source: 'luketruitt1.block_group_insights',
        source_layer: 'block_group_insights',
      },
      styleConfig: {
        threshold: [-2000, 0, 1000, 10000],
        colors: [Color.lightRed, Color.white, Color.lightBlue, Color.blue],
        fallback: 0,
        expression: [
          'case',
          ['==', ['get', 'area'], 0],
          0,
          [
            'round',
            [
              '*',
              [
                '/',
                [
                  '-',
                  ['get', 'population_growth_5yr'],
                  ['get', 'total_population'],
                ],
                ['get', 'area'],
              ],
              squareMetersPerSquareMile,
            ],
          ],
        ],
        opacityStops: [1, 0, 1, 1],
        format: 'raw_change',
        unit: 'people / mi\u00B2',
      },
      dataConfig: {
        header: 'id',
        fields: [
          {
            '5-Year Population Change': {
              expression: [
                '-',
                ['get', 'population_growth_5yr'],
                ['get', 'total_population'],
              ],
              format: 'raw_change',
            },
          },
          {
            '5-Year Change in People / mi\u00B2': {
              expression: [
                'case',
                ['==', ['get', 'area'], 0],
                0,
                [
                  'round',
                  [
                    '*',
                    [
                      '/',
                      [
                        '-',
                        ['get', 'population_growth_5yr'],
                        ['get', 'total_population'],
                      ],
                      ['get', 'area'],
                    ],
                    squareMetersPerSquareMile,
                  ],
                ],
              ],
              format: 'raw_change',
            },
          },
        ],
      },
    },
    bg_population_growth_10yr: {
      type: LayerType.Insights,
      featureType: 'polygon',
      permission: 'read:insight_pop_growth_10yr',
      category: 'Population.Growth',
      sourceTileset: {
        label: 'Block Group Insights',
        source: 'luketruitt1.block_group_insights',
        source_layer: 'block_group_insights',
      },
      displayName: 'Block Group - 10 Year Projected Population Change',
      styleConfig: {
        threshold: [-2000, 0, 1000, 10000],
        colors: [Color.lightRed, Color.white, Color.lightBlue, Color.blue],
        opacityStops: [1, 0, 1, 1],
        fallback: 0,
        expression: [
          'case',
          ['==', ['get', 'area'], 0],
          0,
          [
            'round',
            [
              '*',
              [
                '/',
                [
                  '-',
                  ['get', 'population_growth_10yr'],
                  ['get', 'total_population'],
                ],
                ['get', 'area'],
              ],
              squareMetersPerSquareMile,
            ],
          ],
        ],
        format: 'raw_change',
        unit: 'people / mi\u00B2',
      },
      dataConfig: {
        header: 'id',
        fields: [
          {
            '10 Year Projected Population Change': {
              expression: [
                '-',
                ['get', 'population_growth_10yr'],
                ['get', 'total_population'],
              ],
              format: 'raw_change',
            },
          },
          {
            '10-Year Change in People / mi\u00B2': {
              expression: [
                'case',
                ['==', ['get', 'area'], 0],
                0,
                [
                  'round',
                  [
                    '*',
                    [
                      '/',
                      [
                        '-',
                        ['get', 'population_growth_10yr'],
                        ['get', 'total_population'],
                      ],
                      ['get', 'area'],
                    ],
                    squareMetersPerSquareMile,
                  ],
                ],
              ],
              format: 'raw_change',
            },
          },
        ],
      },
    },
    bg_median_home_value: {
      type: LayerType.Insights,
      featureType: 'polygon',
      permission: 'read:insight_median_home_value',
      category: 'Population.Income',
      sourceTileset: {
        label: 'Block Groups',
        source: 'luketruitt1.block_group_insights',
        source_layer: 'block_group_insights',
      },
      displayName: 'Block Group - Median Home Value',
      styleConfig: {
        colors: [Color.white, Color.yellow, Color.red, Color.purple],
        threshold: [0, 400000, 600000, 1000000],
        expression: ['get', 'median_home_value'],
        fallback: 0,
        format: 'dollars',
        opacityStops: [0, 1, 1, 1],
      },
      dataConfig: {
        header: 'id',
        fields: [
          {
            'Median Home Value': {
              key: 'median_home_value',
              format: 'dollars',
            },
          },
        ],
      },
    },
    bg_median_household_income: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Block Group - Median Household Income',
      category: 'Population.Income',
      sourceTileset: {
        label: 'Block Groups',
        source: 'luketruitt1.block_group_insights',
        source_layer: 'block_group_insights',
      },
      styleConfig: {
        threshold: [40000, 80000, 120000, 200000],
        colors: [Color.lightBlue, Color.white, Color.orange, Color.purple],
        expression: ['get', 'median_household_income'],
        fallback: 80000,
        opacityStops: [1, 0, 1, 1],
        format: 'dollars',
      },
      dataConfig: {
        header: 'id',
        fields: [
          {
            'Median Household Income': {
              key: 'median_household_income',
              format: 'dollars',
            },
          },
        ],
      },
      permission: 'read:insight_median_hhi',
    },
    block_group_boundaries: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Block Group - Boundaries',
      category: 'Miscellaneous.Search American',
      isBoundary: true,
      sourceTileset: {
        label: 'Block Groups',
        source: 'luketruitt1.block_group_insights',
        source_layer: 'block_group_insights',
      },
      styleConfig: {
        colors: [Color.transparent],
      },
      dataConfig: {
        header: 'id',
      },
    },
    zip_boundaries: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Zipcode - Boundaries',
      category: 'Miscellaneous.Search American',
      isBoundary: true,
      sourceTileset: {
        label: 'Zip Codes',
        source: 'luketruitt1.insights_zipcode',
        source_layer: 'insights_zipcode',
      },
      styleConfig: {
        idLabelFlag: true,
        colors: [Color.transparent],
      },
      dataConfig: {
        header: 'zip_code',
        caption: 'primary_city',
      },
    },
    zip_median_home_value: {
      type: LayerType.Insights,
      featureType: 'polygon',
      permission: 'read:insight_median_home_value',
      category: 'Population.Income',
      sourceTileset: {
        label: 'Zip Codes',
        source: 'luketruitt1.insights_zipcode',
        source_layer: 'insights_zipcode',
      },
      displayName: 'Zipcode - Median Home Value',
      styleConfig: {
        idLabelFlag: true,
        colors: [Color.white, Color.yellow, Color.red, Color.purple],
        threshold: [0, 400000, 600000, 1000000],
        expression: ['get', 'median_home_value'],
        fallback: 0,
        format: 'dollars',
        opacityStops: [0, 1, 1, 1],
      },
      dataConfig: {
        header: 'zip_code',
        caption: 'primary_city',
        fields: [
          {
            'Median Home Value': {
              key: 'median_home_value',
              format: 'dollars',
            },
          },
        ],
      },
    },
    zip_median_household_income: {
      type: LayerType.Insights,
      featureType: 'polygon',
      category: 'Population.Income',
      permission: 'read:insight_median_hhi',
      sourceTileset: {
        label: 'Zip Codes',
        source: 'luketruitt1.insights_zipcode',
        source_layer: 'insights_zipcode',
      },
      displayName: 'Zipcode - Median Household Income',
      styleConfig: {
        idLabelFlag: true,
        threshold: [40000, 80000, 120000, 200000],
        colors: [Color.lightBlue, Color.white, Color.orange, Color.purple],
        fallback: 80000,
        expression: ['get', 'household_income'],
        opacityStops: [1, 0, 1, 1],
        format: 'dollars',
      },
      dataConfig: {
        header: 'zip_code',
        caption: 'primary_city',
        fields: [
          {
            'Median Household Income': {
              key: 'household_income',
              format: 'dollars',
            },
          },
        ],
      },
    },
    zip_owner_occ_housing: {
      type: LayerType.Insights,
      featureType: 'polygon',
      category: 'Population.Income',
      permission: 'read:owner_occupied_housing',
      displayName: 'Zipcode - Owner Occupied Housing',
      styleConfig: {
        idLabelFlag: true,
        colors: [
          Color.lightYellow,
          Color.darkYellow,
          Color.orange,
          Color.darkOrange,
          Color.red,
        ],
        threshold: [0, 0.877, 0.939, 0.97, 1],
        expression: ['get', 'owner_occupancy'],
        fallback: 0,
        opacityStops: [0, 0.25, 1, 1, 1],
      },
      dataConfig: {
        header: 'zip_code',
        caption: 'primary_city',
        fields: [
          {
            'Owner Occupied Housing': {
              key: 'owner_occupancy',
              format: 'percent',
            },
          },
        ],
      },
      sourceTileset: {
        label: 'Zip Codes',
        source: 'luketruitt1.insights_zipcode',
        source_layer: 'insights_zipcode',
      },
    },
    cbsa_boundaries: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'CBSA - Boundaries',
      category: 'Miscellaneous.Search American',
      isBoundary: true,
      sourceTileset: {
        label: 'CBSA',
        source: 'luketruitt1.cbsa',
        source_layer: 'cbsa',
      },
      styleConfig: { colors: [Color.transparent] },
      // FIXME: the names in this CBSA tileset are completely wrong, like with Waco TX being named "Palatka FL". They're all wrong. *facepalm*
      // dataConfig: { header: 'name', fields: [] },
    },
    city_boundaries: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Cities - Boundaries',
      category: 'Miscellaneous.Search American',
      isBoundary: true,
      sourceTileset: {
        label: 'Cities',
        source: 'luketruitt1.cities',
        source_layer: 'cities',
      },
      styleConfig: { colors: [Color.transparent] },
      dataConfig: { header: 'city_id', fields: [] },
    },
    traffic_volume: {
      type: LayerType.Enterprise,
      featureType: 'line',
      displayName: 'Traffic Volume Heatmap',
      component: { layerIds: trafficLayerIds },
      styleConfig: {
        threshold: [],
        colors: [
          'rgba(241, 197, 204, .1)',
          'rgba(241, 197, 204, .5)',
          '#f1c5cc',
          '#c76bb3',
          '#950097',
        ],
        unit: 'trips',
      },
      permission: 'read:traffic',
      category: 'Miscellaneous.Traffic Volume',
    },
    taid_urbanicity_urban_core: {
      type: LayerType.Insights,
      featureType: 'point',
      displayName: 'Trade Area ID - Urban Core',
      category: 'Population.Urbanicity',
      sourceTileset: {
        label: 'TAID Urbanicity',
        source: 'luketruitt1.TAID',
        source_layer: 'TAID',
      },
      styleConfig: {
        colors: [Color.transparent, Color.urbanCore, Color.transparent],
        threshold: [5, 6, 7],
        expression: ['get', 'urbanization_classification'],
        fallback: 0,
        opacityStops: [0, 1, 0],
      },
      dataConfig: {
        header: 'id',
        fields: [
          { CBSA: 'cbsa' },
          { Address: 'taid_address' },
          { City: 'taid_city' },
          { State: 'taid_state' },
          { 'ZIP Code': 'taid_zip_code' },
        ],
      },
    },
    taid_urbanicity_urban: {
      type: LayerType.Insights,
      featureType: 'point',
      displayName: 'Trade Area ID - Urban',
      category: 'Population.Urbanicity',
      sourceTileset: {
        label: 'TAID Urbanicity',
        source: 'luketruitt1.TAID',
        source_layer: 'TAID',
      },
      styleConfig: {
        colors: [Color.transparent, Color.urban, Color.transparent],
        threshold: [4, 5, 6],
        expression: ['get', 'urbanization_classification'],
        fallback: 0,
        opacityStops: [0, 1, 0],
      },
      dataConfig: {
        header: 'id',
        fields: [
          { CBSA: 'cbsa' },
          { Address: 'taid_address' },
          { City: 'taid_city' },
          { State: 'taid_state' },
          { 'ZIP Code': 'taid_zip_code' },
        ],
      },
    },
    taid_urbanicity_metropolitan: {
      type: LayerType.Insights,
      featureType: 'point',
      displayName: 'Trade Area ID - Metropolitan',
      category: 'Population.Urbanicity',
      sourceTileset: {
        label: 'TAID Urbanicity',
        source: 'luketruitt1.TAID',
        source_layer: 'TAID',
      },
      styleConfig: {
        colors: [Color.transparent, Color.metropolitan, Color.transparent],
        threshold: [3, 4, 5],
        expression: ['get', 'urbanization_classification'],
        fallback: 0,
        opacityStops: [0, 1, 0],
      },
      dataConfig: {
        header: 'id',
        fields: [
          { CBSA: 'cbsa' },
          { Address: 'taid_address' },
          { City: 'taid_city' },
          { State: 'taid_state' },
          { 'ZIP Code': 'taid_zip_code' },
        ],
      },
    },
    taid_urbanicity_suburban: {
      type: LayerType.Insights,
      featureType: 'point',
      displayName: 'Trade Area ID - Suburban',
      category: 'Population.Urbanicity',
      sourceTileset: {
        label: 'TAID Urbanicity',
        source: 'luketruitt1.TAID',
        source_layer: 'TAID',
      },
      styleConfig: {
        colors: [Color.transparent, Color.suburban, Color.transparent],
        threshold: [2, 3, 4],
        expression: ['get', 'urbanization_classification'],
        fallback: 0,
        opacityStops: [0, 1, 0],
      },
      dataConfig: {
        header: 'id',
        fields: [
          { CBSA: 'cbsa' },
          { Address: 'taid_address' },
          { City: 'taid_city' },
          { State: 'taid_state' },
          { 'ZIP Code': 'taid_zip_code' },
        ],
      },
    },
    taid_urbanicity_in_town: {
      type: LayerType.Insights,
      featureType: 'point',
      displayName: 'Trade Area ID - In-Town',
      category: 'Population.Urbanicity',
      sourceTileset: {
        label: 'TAID Urbanicity',
        source: 'luketruitt1.TAID',
        source_layer: 'TAID',
      },
      styleConfig: {
        colors: [Color.transparent, Color.inTown, Color.transparent],
        threshold: [1, 2, 3],
        expression: ['get', 'urbanization_classification'],
        fallback: 0,
        opacityStops: [0, 1, 0],
      },
      dataConfig: {
        header: 'id',
        fields: [
          { CBSA: 'cbsa' },
          { Address: 'taid_address' },
          { City: 'taid_city' },
          { State: 'taid_state' },
          { 'ZIP Code': 'taid_zip_code' },
        ],
      },
    },
    taid_urbanicity_rural: {
      type: LayerType.Insights,
      featureType: 'point',
      displayName: 'Trade Area ID - Rural',
      category: 'Population.Urbanicity',
      sourceTileset: {
        label: 'TAID Urbanicity',
        source: 'luketruitt1.TAID',
        source_layer: 'TAID',
      },
      styleConfig: {
        colors: [Color.transparent, Color.rural, Color.transparent],
        threshold: [0, 1, 2],
        expression: ['get', 'urbanization_classification'],
        fallback: 0,
        opacityStops: [0, 1, 0],
      },
      dataConfig: {
        header: 'id',
        fields: [
          { CBSA: 'cbsa' },
          { Address: 'taid_address' },
          { City: 'taid_city' },
          { State: 'taid_state' },
          { 'ZIP Code': 'taid_zip_code' },
        ],
      },
    },
  };
}
