import * as Y from 'yjs';
import { z } from 'zod';
import { yFromJSON } from '@plotr/multiplayer-data';

export enum TargetType {
  Pins = 'pins',
  Territories = 'territories',
}

export enum SourceType {
  Tag = 'Tag',
  KeyValue = 'Property',
}

const EvaluationSchema = z.object({
  operator: z.string(),
  value: z.union([z.string(), z.number(), z.boolean()]),
});

export type Evaluation = z.infer<typeof EvaluationSchema>;

const RuleSchema = z.object({
  id: z.string(),
  targetType: z.nativeEnum(TargetType), // Use TypeScript enum with Zod
  sourceType: z.nativeEnum(SourceType),
  propertyKey: z.string().optional(),
  evaluation: EvaluationSchema,
  effect: z.string(),
});

export type YRule = Y.Map<
  string | TargetType | SourceType | Y.Map<string | number | boolean> | string
>;

export type Rule = z.infer<typeof RuleSchema>;

const getRulesets = (ydoc: Y.Doc) => ydoc.getArray<YRule>('rulesets');

const findRuleset = (ydoc: Y.Doc, territoryId: string) => {
  const rulesets = getRulesets(ydoc);
  const rIndex =
    rulesets.toArray().findIndex((ruleset) => {
      // territory.get('id') as string === territoryId)
      const id = ruleset.get('id') as unknown as string;
      return id ? id === territoryId : false;
    }) ?? -1;
  return { rulesets, rIndex, ruleset: rulesets.get(rIndex) };
};

export default (ydoc: Y.Doc) => ({
  addRule: (Rule: Rule) => {
    const yCustomRule = yFromJSON(Rule) as YRule;
    getRulesets(ydoc).push([yCustomRule]);
  },
  updateRule: (ruleId: string, newValues: Rule) => {
    const { ruleset } = findRuleset(ydoc, ruleId);

    if (ruleset && ruleset instanceof Y.Map) {
      Object.entries(newValues).forEach(([key, value]) => {
        if (
          key === 'evaluation' &&
          typeof value === 'object' &&
          value !== null
        ) {
          const evalMap = ruleset.get('evaluation') as Y.Map<any>;
          if (evalMap && evalMap instanceof Y.Map) {
            Object.entries(value).forEach(([subKey, subValue]) => {
              evalMap.set(subKey, subValue); // Make sure subValue is a string, number, or boolean
            });
          }
        } else if (
          typeof value === 'string' ||
          typeof value === 'number' ||
          typeof value === 'boolean' ||
          value instanceof Y.Map
        ) {
          ruleset.set(key, value); // Ensures only valid types are set
        }
      });
    } else {
      console.error('Failed to update rule with ID:', ruleId);
    }
  },
  deleteRule: (ruleId: string) => {
    const { rulesets, rIndex } = findRuleset(ydoc, ruleId);
    if (rIndex !== -1) {
      rulesets.delete(rIndex);
    } else {
      console.error('Rule not found for deletion:', ruleId);
    }
  },
  getRules: () => {
    const rulesets = getRulesets(ydoc);
    return rulesets.toArray().map((rule) => {
      if (rule instanceof Y.Map) {
        return Object.fromEntries(rule.entries());
      }
      return {}; // This line is just a safeguard and might need proper handling based on your data structure.
    });
  },
  moveRule: (fromId: string, toIndex: number) => {
    const { rulesets, rIndex } = findRuleset(ydoc, fromId);
    if (rIndex !== -1) {
      const rules = rulesets.toArray();
      const [rule] = rules.splice(rIndex, 1); // Remove the rule from the array
      rulesets.delete(rIndex);
      rulesets.insert(toIndex, [rule]); // Insert the rule at the new index
    } else {
      console.error('Rule not found for moving:', fromId);
    }
  },
});
