import React from 'react';
import { Layer, Source } from 'react-map-gl';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import useMapImage from '~/src/features/dynamic-map/hooks/useMapImage';
import { increasedIconSize } from './POISource/POIHelpers';

interface MFHPulseSourceLayerProps {
  mfhId: string;
  mfhPinImage: string;
}

const MFHPulseSourceLayer: React.FC<MFHPulseSourceLayerProps> = ({
  mfhId,
  mfhPinImage,
}) => {
  const evaluatedDemographicEntity = useDynamicMapStore(
    (state) => state.evaluatedDemographicEntity
  );

  const evaluatedDemographicPoint =
    evaluatedDemographicEntity?.type === 'point'
      ? evaluatedDemographicEntity.pos
      : null;

  const selectedMFH = useDynamicMapStore((state) => state.selectedMFH);
  const isPOiEnabled = useDynamicMapStore((state) => state.poisEnabled);
  const feature = useDynamicMapStore((state) => state.clickedPOiFeature);

  const isPinReady = useMapImage({
    id: mfhId,
    src: mfhPinImage,
  });

  return (
    <>
      {evaluatedDemographicPoint && feature && feature.properties && (
        <Source
          id={`mfh-pulse-source`}
          type="geojson"
          data={{
            type: 'Point',
            coordinates: [
              evaluatedDemographicPoint.lng,
              evaluatedDemographicPoint.lat,
            ],
          }}
        >
          <Layer
            type="symbol"
            id={`mfh-pulse`}
            source={`mfh-pulse-source`}
            layout={{
              visibility:
                selectedMFH.completed && isPOiEnabled ? 'visible' : 'none',
              ...(isPinReady && mfhId ? { 'icon-image': `${mfhId}` } : {}),
              'icon-size': increasedIconSize,
              'icon-anchor': 'bottom',
              'icon-allow-overlap': false,
              'text-field': feature.properties.name,
              'text-allow-overlap': false,
              'text-anchor': 'top',
              'text-size': {
                type: 'exponential',
                stops: [
                  [10, 12],
                  [16, 16],
                ],
              },
            }}
          />
        </Source>
      )}
    </>
  );
};

export default MFHPulseSourceLayer;
