import { parseEnv } from '@plotr/common-utils';

const env = parseEnv({ API_V1: process.env.API_V1 });

export async function generateReport(
  latitude: number,
  longitude: number,
  accessToken: string,
  reportTemplateId?: string,
): Promise<string | null> {
  try {
    const response = await fetch(`${env.API_V1}/report`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        latitude,
        longitude,
        reportTemplateId,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data: { url: string } = await response.json();
    return data.url;
  } catch (error) {
    console.error(
      'Error generating report:',
      error instanceof Error ? error.message : error,
    );
    return null;
  }
}
