import { parseEnv } from '@plotr/common-utils';

const env = parseEnv({ API_V1: process.env.API_V1 });

export async function checkPermission(
  accessToken: string,
  permission: string,
): Promise<boolean> {
  try {
    const response = await fetch(
      `${env.API_V1}/check_permission/${permission}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data: { hasPermission: boolean } = await response.json();
    return data.hasPermission;
  } catch (error) {
    // Log error to the console or handle it as needed.
    console.error(
      'There was a problem with the fetch operation:',
      error instanceof Error ? error.message : error,
    );
    return false;
  }
}
