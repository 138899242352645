import { useCallback, useEffect, useMemo, useState } from 'react';

import styled from '@emotion/styled';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { Alert, AlertProps, Tab, Tabs } from '@mui/material';
import { plotrMultiplayerData } from '@plotr/plotr-multiplayer-data/src';
import usePrevious from '~/src/common/hooks/usePrevious';
import useCustomPins from '~/src/global/hooks/useCustomPins';
import { debounce } from '../../../../../common-utils/src';
import PopupWithoutWheel from '../../dynamic-map/components/PopupWithoutWheel';
import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';
import CustomPinKVTable from './CustomPinKVTable';

const StyledPopup = styled(PopupWithoutWheel)`
  .mapboxgl-popup-content {
    padding: 0.5rem;
    border-radius: 0.5rem;
    min-width: 25rem;
    position: relative;
    top: 0;
  }
`;

const defaultGroupName = 'Default Group';

const EditPinPopup: React.FC = () => {
  const selectPinId = useDynamicMapStore((state) => state.selectCustomPinId);
  const deselectPin = () => selectPinId(null);

  const selectedPinId = useDynamicMapStore(
    (state) => state.selectedCustomPinId
  );
  const previousPinId = usePrevious(selectedPinId);
  const customPins = useCustomPins();
  const customPinMethods = plotrMultiplayerData.methods?.pins;

  const pin = useMemo(
    () => customPins.find((p) => p.id === selectedPinId) ?? null,
    [customPins, selectedPinId]
  );
  const pinGroups = useMemo(
    () => [...new Set(customPins.map((p) => p.group))],
    [customPins]
  );

  const alertMessagePin = useDynamicMapStore((state) => state.alertMessagePin);
  const alertSeverity = useDynamicMapStore((state) => state.alertSeverityPin);
  const setAlertMessagePin = useDynamicMapStore(
    (state) => state.setAlertMessagePin
  );
  const setAlertSeverity = useDynamicMapStore(
    (state) => state.setAlertSeverityPin
  );
  const clearAlert = () => setAlertMessagePin('');

  const [pinNameInput, setPinNameInput] = useState(pin?.label ?? '');
  const [pinGroupInput, setPinGroupInput] = useState(defaultGroupName);
  const [tagInput, setTagInput] = useState('');

  const previousPinLabel = usePrevious(pin?.label);
  useEffect(() => {
    if (pin?.label != null && previousPinLabel == null) {
      setPinNameInput(pin.label);
    }
  }, [pin?.label, previousPinLabel]);

  const previousPinGroup = usePrevious(pin?.group);
  useEffect(() => {
    if (pin?.group != null && previousPinGroup == null) {
      setPinGroupInput(pin.group);
    }
  }, [pin?.group, previousPinGroup]);

  useEffect(() => {
    if (selectedPinId === previousPinId) return;

    setPinNameInput(pin?.label ?? '');
    setPinGroupInput(pin?.group ?? defaultGroupName);
    setTagInput('');
  }, [selectedPinId, previousPinId, pin]);

  const debouncedSetLabel = useCallback(
    debounce((value: string) => {
      if (customPinMethods != null && pin?.id != null) {
        customPinMethods.setLabel(pin.id, value);
      }
    }, 250),
    [pin?.id]
  );

  useEffect(() => {
    debouncedSetLabel(pinNameInput);
  }, [pinNameInput, debouncedSetLabel]);

  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (alertMessagePin) {
      const timeout = setTimeout(() => {
        setAlertMessagePin('');
      }, 1500);
      return () => clearTimeout(timeout);
    }
    return;
  }, [alertMessagePin, setAlertMessagePin]);

  const nameAndGroup = pin != null && customPinMethods != null && (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      style={{ minHeight: '10px' }}
    >
      <TextField
        id="pin-name"
        label="Pin Name"
        variant="outlined"
        margin="normal"
        fullWidth
        value={pinNameInput}
        onChange={(e) => setPinNameInput(e.target.value)}
        inputProps={{ maxLength: 40 }}
      />
      <Autocomplete
        id="pin-group"
        freeSolo
        options={pinGroups}
        inputValue={pinGroupInput}
        autoSelect
        onInputChange={(_e, value) => setPinGroupInput(value)}
        onChange={() => {
          if (pinGroupInput.length > 0) {
            customPinMethods.setGroup(pin.id, pinGroupInput);
          } else {
            customPinMethods.setGroup(pin.id, defaultGroupName);
            setPinGroupInput(defaultGroupName);
          }
        }}
        onBlur={() => {
          if (pinGroupInput.length > 0) {
            customPinMethods.setGroup(pin.id, pinGroupInput);
          } else {
            customPinMethods.setGroup(pin.id, defaultGroupName);
            setPinGroupInput(defaultGroupName);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{ ...params.inputProps }}
            label="Pin Group"
            variant="outlined"
            margin="normal"
          />
        )}
      />
    </Box>
  );

  const tags = pin != null && customPinMethods != null && (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={4} mt={1}>
        <TextField
          id="tag"
          label="Add Tag"
          variant="outlined"
          size="small"
          value={tagInput}
          onChange={(e) => setTagInput(e.target.value)}
          inputProps={{ maxLength: 30 }}
          onKeyDown={(keyEvent) => {
            const finalValue = tagInput.toLowerCase().trim();
            if (keyEvent.key === 'Enter' && finalValue.length > 0) {
              if (pin.tags.includes(finalValue)) {
                setAlertMessagePin(
                  'A tag with this name already exists! Please choose another name.'
                );
                setAlertSeverity('error');
              } else {
                customPinMethods.addTag(pin.id, finalValue);
              }
              setTagInput('');
            }
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      {pin.tags.map((tag) => (
        <Grid item key={tag}>
          <Chip
            size="small"
            label={tag}
            color="primary"
            onDelete={() => {
              customPinMethods.removeTag(pin.id, tag);
            }}
          />
        </Grid>
      ))}
    </Grid>
  );

  const paddingAroundContent = 1;

  const stopPropagation = (e: React.WheelEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return pin != null && customPinMethods != null ? (
    <Card>
      <StyledPopup
        offset={[0, -50] as [number, number]}
        longitude={pin.pos.lng}
        latitude={pin.pos.lat}
        closeOnClick={false}
        onClose={deselectPin}
      >
        <CardHeader
          title={
            <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <EditIcon />
                <Typography variant="h6" fontWeight={700}>
                  Edit Pin
                </Typography>
              </Box>
            </Box>
          }
        />

        <CardContent sx={{ padding: 2 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Edit pin tabs"
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Name" sx={{ fontSize: 11 }} />
            <Tab label="Properties" sx={{ fontSize: 11 }} />
            <Tab label="Tags" sx={{ fontSize: 11 }} />
          </Tabs>
          {value === 0 && <Box p={paddingAroundContent}>{nameAndGroup}</Box>}
          {value === 1 && (
            <Box p={paddingAroundContent} onWheel={stopPropagation}>
              <CustomPinKVTable pin={pin} />
            </Box>
          )}
          {value === 2 && <Box p={paddingAroundContent}>{tags}</Box>}
        </CardContent>

        {value === 1 && alertMessagePin && (
          <Alert
            severity={alertSeverity as AlertProps['severity']}
            onClose={clearAlert}
            sx={{ margin: 2 }}
          >
            {alertMessagePin}
          </Alert>
        )}

        <CardActions>
          <Button
            variant="outlined"
            endIcon={<DeleteIcon />}
            disabled={pin == null}
            onClick={() => {
              customPinMethods.removePin(pin.id);
              deselectPin();
            }}
            color="error"
          >
            Delete Pin
          </Button>
        </CardActions>
      </StyledPopup>
    </Card>
  ) : null;
};

export default EditPinPopup;
