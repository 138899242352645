import MapboxDraw, { MapMouseEvent } from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import {
  TerritoryType,
  plotrMultiplayerData,
} from '@plotr/plotr-multiplayer-data';
import { Feature, Polygon } from 'geojson';
import React, { useEffect, useRef, useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import tinycolor from 'tinycolor2';

import useCustomTerritories from '../../dynamic-map/hooks/useCustomTerritories';
import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';
import useMapContext from '../../dynamic-map/hooks/useMapContext';
import useRulesets from '../../dynamic-map/hooks/useRulesets';
import { getColor } from '../menu/GlobalTerritorySettingsMenu';

function isPolygon(feature: Feature): feature is Feature<Polygon> {
  return feature.geometry.type === 'Polygon';
}

const CustomDrawnTerritories: React.FC = () => {
  const map = useMapContext();
  const draw = useRef<MapboxDraw | null>(null);
  const [polygonFeatures, setPolygonFeatures] = useState<Feature<Polygon>[]>(
    []
  );
  const [hoveredFeatureId, setHoveredFeatureId] = useState<string | null>(null);

  const customTerritories = useCustomTerritories();
  const customTerritoryMethods = plotrMultiplayerData.methods?.territories;

  const rulesets = useRulesets();
  const evaluatedTerritoryId = useDynamicMapStore(
    (state) => state.evaluatedTerritoryId
  );

  const evaluatedTerritory = customTerritories.find(
    (territory) => territory.id === evaluatedTerritoryId
  );
  const evaluatedBoundaries = evaluatedTerritory?.boundaries;
  const isEditingTerritory = useDynamicMapStore(
    (state) => state.isEditingTerritory
  );
  const territoryFillEnabled = useDynamicMapStore(
    (state) => state.territoryFillEnabled
  );

  const territoriesEnabled = useDynamicMapStore(
    (state) => state.territoriesEnabled
  );

  const isActive = evaluatedTerritory?.type === TerritoryType.Custom;

  const hiddenTerritoryGroups = useDynamicMapStore(
    (state) => state.hiddenTerritoryGroups
  );

  useEffect(() => {
    if (!map) return;

    if (!draw.current && isEditingTerritory && isActive) {
      draw.current = new MapboxDraw({
        displayControlsDefault: false,
        controls: {},
        defaultMode: 'draw_polygon',
      });
      map.addControl(draw.current);
    }

    const features = customTerritories.reduce(
      (acc: Feature<Polygon>[], territory, index) => {
        if (territory.type === TerritoryType.Custom && territory.boundaries) {
          Object.values(territory.boundaries).forEach((boundary) => {
            if (boundary.coordinates) {
              const color = getColor(rulesets, {
                ...territory.keyValuePairs,
                tags: territory.tags,
              });
              const hoverColor = tinycolor(color).darken(20).toString();
              const feature: Feature<Polygon> = {
                type: 'Feature',
                id: `${index}${Date.now().toString()}`,
                properties: {
                  ...territory.keyValuePairs,
                  territoryId: territory.id,
                  label: `${territory.label} - Custom`,
                  tags: territory.tags,
                  color,
                  hoverColor,
                  group: territory.group,
                },
                geometry: {
                  type: 'Polygon',
                  coordinates: [boundary.coordinates],
                },
              };
              acc.push(feature);
            }
          });
        }
        return acc;
      },
      []
    );

    setPolygonFeatures(features);

    // Handle creation or update of drawings
    const handleDrawCreateOrUpdate = () => {
      if (!draw.current) return;
      const allFeatures = draw.current.getAll();
      if (allFeatures.features.length > 1) {
        const lastFeature =
          allFeatures.features[allFeatures.features.length - 1];
        draw.current.deleteAll().add(lastFeature);
      }

      if (!allFeatures.features.length) return;
      const feature = allFeatures.features[0];

      if (isPolygon(feature)) {
        const coordinates = feature.geometry.coordinates[0];
        if (evaluatedTerritoryId) {
          customTerritoryMethods?.addBoundary(
            evaluatedTerritoryId,
            'Custom',
            coordinates
          );
        }
        setPolygonFeatures([feature]);
      }
    };

    // Attach the event listeners to MapboxDraw
    map.on('draw.create', handleDrawCreateOrUpdate);
    map.on('draw.update', handleDrawCreateOrUpdate);
    map.on('draw.delete', handleDrawCreateOrUpdate);

    return () => {
      // Cleanup
      map.off('draw.create', handleDrawCreateOrUpdate);
      map.off('draw.update', handleDrawCreateOrUpdate);
      map.off('draw.delete', handleDrawCreateOrUpdate);
      if (draw.current) {
        map.removeControl(draw.current);
        draw.current = null;
      }
    };
  }, [
    map,
    isEditingTerritory,
    isActive,
    evaluatedTerritoryId,
    customTerritoryMethods,
    evaluatedBoundaries,
    customTerritories,
    rulesets,
  ]);

  // Additional useEffect for hover effect
  useEffect(() => {
    if (!map || polygonFeatures.length === 0 || !territoriesEnabled) return;

    const handleMouseMove = (e: MapMouseEvent) => {
      const feature = map.queryRenderedFeatures(e.point, {
        layers: ['custom-drawn-territories-fill'],
      })?.[0];

      if (feature != null) {
        const featureId = feature.id;
        if (featureId && featureId !== hoveredFeatureId) {
          if (hoveredFeatureId) {
            map.setFeatureState(
              { source: 'custom-drawn-territories', id: hoveredFeatureId },
              { hover: false }
            );
          }
          setHoveredFeatureId(featureId.toString());
          map.setFeatureState(
            { source: 'custom-drawn-territories', id: featureId },
            { hover: true }
          );
        }
      } else {
        if (hoveredFeatureId) {
          map.setFeatureState(
            { source: 'custom-drawn-territories', id: hoveredFeatureId },
            { hover: false }
          );
          setHoveredFeatureId(null);
        }
      }
    };

    const handleMouseLeave = () => {
      if (hoveredFeatureId) {
        map.setFeatureState(
          { source: 'custom-drawn-territories', id: hoveredFeatureId },
          { hover: false }
        );
        setHoveredFeatureId(null);
      }
    };

    map.on('mousemove', handleMouseMove);
    map.on('mouseleave', handleMouseLeave);

    return () => {
      map.off('mousemove', handleMouseMove);
      map.off('mouseleave', handleMouseLeave);
    };
  }, [map, hoveredFeatureId, polygonFeatures.length, territoriesEnabled]);

  // Render the polygons as multiple layers
  return (
    <>
      {polygonFeatures.length > 0 && territoriesEnabled && (
        <Source
          id="custom-drawn-territories"
          type="geojson"
          data={{
            type: 'FeatureCollection',
            features: polygonFeatures.filter(
              (feature) =>
                feature.properties &&
                !hiddenTerritoryGroups.includes(feature.properties.group)
            ),
          }}
        >
          <Layer
            id="custom-drawn-territories-label"
            type="symbol"
            source="custom-drawn-territories"
            layout={{
              'text-field': ['get', 'label'],
              'text-font': ['Arial Unicode MS Regular'],
              'text-size': 16,
              visibility: 'visible',
            }}
            paint={{
              'text-color': 'black',
              'text-halo-color': 'rgba(255, 255, 255, 0.5)',
              'text-halo-width': 2,
            }}
          />
          <Layer
            id="custom-drawn-territories-fill"
            type="fill"
            source="custom-drawn-territories"
            paint={{
              'fill-color': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                ['get', 'hoverColor'], // Use hover color when hovered
                ['get', 'color'], // Normal color otherwise
              ],
              'fill-opacity': 0.4,
            }}
            layout={{
              visibility: territoryFillEnabled ? 'visible' : 'none',
            }}
            beforeId="custom-drawn-territories-label"
          />
          <Layer
            id="custom-drawn-territories-line"
            type="line"
            source="custom-drawn-territories"
            paint={{
              'line-color': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                ['get', 'hoverColor'], // Use hover color when hovered
                ['get', 'color'], // Normal color otherwise
              ],
              'line-width': 2,
            }}
            layout={{
              visibility: 'visible',
            }}
          />
        </Source>
      )}
    </>
  );
};

export default CustomDrawnTerritories;
