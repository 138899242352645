import { useEffect, useState } from 'react';
import useAccessToken from '~/src/global/hooks/useAccessToken';
import { parseEnv } from '@plotr/common-utils';

const env = parseEnv({ PLOTR_API: process.env.PLOTR_API });

export interface UserProject {
  _id: string;
  name: string;
  owner: string;
  room: {
    _id: string;
    version: number;
    data?: string;
    users: {
      userId: string;
      permissions: {
        read: boolean;
        write: boolean;
      };
    }[];
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
}

// HACK: this is a temporary solution to the problem of users not having any projects and us not yet supporting multiple projects per user
export default function useDefaultProject(): UserProject | null {
  const { accessToken } = useAccessToken();
  const [projectsLoaded, setProjectsLoaded] = useState(false);
  const [projects, setProjects] = useState<UserProject[]>([]);

  // get the user's projects
  useEffect(() => {
    if (accessToken != null) {
      fetch(`${env.PLOTR_API}/project`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setProjects(data);
          setProjectsLoaded(true);
        });
    }
  }, [accessToken]);

  // if the user doesn't have any projects, create a new project
  useEffect(() => {
    if (projectsLoaded && projects.length === 0) {
      setProjectsLoaded(false);
      fetch(`${env.PLOTR_API}/project`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: 'Default Project',
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setProjects([data]);
          setProjectsLoaded(true);
        });
    }
  }, [projectsLoaded, projects.length, accessToken]);

  // use the first project as the "default" for now
  return projects[0] ?? null;
}
