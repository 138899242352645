import * as Y from 'yjs';
import { v4 as uuidV4 } from 'uuid';
import { yFromJSON } from '@plotr/multiplayer-data';

import { TerritoryType } from '..';
import { SourceType, TargetType } from '../lib/rulesets';

export const statusToTagsAndRuleset = (ydoc: Y.Doc): void => {
  const territories = ydoc.getArray('customTerritories');
  const rulesets = ydoc.getArray('rulesets');
  let statusFound = false;

  territories.forEach((territory) => {
    if (territory instanceof Y.Map) {
      if (!territory.has('type')) territory.set('type', TerritoryType.Zip);

      if (territory.has('status')) {
        const status = territory.get('status');
        if (typeof status === 'string') {
          statusFound = true;
        }

        // get tags, or set to empty array if not present
        let tags = territory.get('tags') as Y.Array<string>;
        if (tags == null) {
          tags = new Y.Array();
          territory.set('tags', tags);
        }

        if (typeof status === 'string' && tags instanceof Y.Array) {
          // add legacy status to tags, only if not already present
          if (!tags.toArray().includes(status.toLowerCase())) {
            tags.push([status.toLowerCase()]);
          }
          territory.delete('status');
        }
      }
    }
  });

  // Add rulesets for each status
  const statusRules = [
    { status: 'active', color: '#C0392B' },
    { status: 'pre-launch', color: '#FFBF00' },
    { status: 'open', color: 'darkgreen' },
  ];

  if (statusFound) {
    statusRules.forEach(({ status, color }) => {
      const rule = new Y.Map();
      rule.set('id', uuidV4());
      rule.set('targetType', TargetType.Territories);
      rule.set('sourceType', SourceType.Tag);
      rule.set('propertyKey', 'tags');
      rule.set(
        'evaluation',
        yFromJSON({
          operator: '=',
          value: status,
        })
      );
      rule.set('effect', color);

      rulesets.push([rule]);
    });
  }
};
