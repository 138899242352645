import * as React from 'react';
export function useEvent(handler) {
  if (typeof window === 'undefined') {
    // useLayoutEffect doesn't work on the server side, don't bother
    // trying to make callback functions stable
    return handler;
  }
  const handlerRef = React.useRef(null);
  React.useLayoutEffect(() => {
    handlerRef.current = handler;
  });
  return React.useCallback((...args) => {
    var _handlerRef$current;
    (_handlerRef$current = handlerRef.current) === null || _handlerRef$current === void 0 || _handlerRef$current.call(handlerRef, ...args);
  }, []);
}