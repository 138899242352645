import { removeDuplicates } from '@plotr/common-utils';
import { Layer, Source } from 'react-map-gl';

import getMatchOpacity from '../../../helpers/getMatchOpacity';
import useBlockGroupsStore from '../../../hooks/useBlockGroupStore';
import useDynamicMapStore from '../../../hooks/useDynamicMapStore';
import { GroupComponentProps } from '../../../hooks/useLayersStore';

export const BASE_LAYER_ID = 'block_group_insights';

const purple = '3604C2';

export default function BlockGroupsSource({
  opacity = 0,
  enabled,
  // beforeId,
}: GroupComponentProps) {
  const blockGroups = useBlockGroupsStore((state) => state.blockGroups);
  const selectedBlockGroups = useDynamicMapStore(
    (state) => state.selectedBlockGroups,
  );

  const coreLayerId = 'block_group_match';

  return (
    <Source
      id={BASE_LAYER_ID}
      type="vector"
      url="mapbox://luketruitt1.block_group_insights"
    >
      <Layer
        id={coreLayerId}
        type="fill"
        source={BASE_LAYER_ID}
        source-layer={BASE_LAYER_ID}
        minzoom={6}
        layout={{
          visibility: enabled ? 'visible' : 'none',
        }}
        paint={{
          'fill-outline-color': 'rgba(0,0,0,0.1)',
          'fill-color': 'transparent',
          'fill-opacity': 0.5,
        }}
        // beforeId={beforeId}
      />
      {blockGroups.map((blockGroupSet) => {
        const layerId = `${coreLayerId}-${blockGroupSet.groupName}`;
        const filteredGeoIDs = removeDuplicates(blockGroupSet.geoIDFilter);
        const isLayerVisible = selectedBlockGroups.includes(layerId);

        return (
          <Layer
            id={layerId}
            key={layerId}
            type="fill"
            source={BASE_LAYER_ID}
            source-layer={BASE_LAYER_ID}
            minzoom={6}
            layout={{
              visibility: isLayerVisible && enabled ? 'visible' : 'none',
            }}
            paint={{
              'fill-outline-color': `#${purple}`,
              'fill-color': `#${purple}`,
              'fill-opacity':
                getMatchOpacity(blockGroupSet.groupName) * opacity,
            }}
            filter={['all', ['match', ['id'], filteredGeoIDs, true, false]]}
            beforeId={coreLayerId}
          />
        );
      })}
    </Source>
  );
}
