import {
  Box,
  ToggleButton,
  Typography,
  ToggleButtonProps,
  styled,
} from '@mui/material';

import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import { MapStyle } from '~/src/features/dynamic-map/hooks/useDynamicMapStore';

const titleByMapStyle: Record<MapStyle, string> = {
  [MapStyle.Streets]: 'Streets',
  [MapStyle.Satellite]: 'Satellite',
  [MapStyle.Light]: 'Light',
  [MapStyle.Dark]: 'Dark',
};

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  flex: 1,
  flexBasis: 'auto',
  overflow: 'hidden',
  border: `4px solid transparent`, // Transparent border for unselected state
  padding: 0,
  borderRadius: theme.spacing(2),
  boxSizing: 'border-box', // Include padding and border in the width and height
  minHeight: '120px', // Set a minimum height
  '&.Mui-selected': {
    pointerEvents: 'none',
    backgroundColor: 'transparent',
    borderColor: theme.palette.secondary.main, // Highlight with a border when selected
    borderStyle: 'solid',
  },
  '& img': {
    transition: theme.transitions.create('opacity'),
    opacity: 1,
    width: '100%', // Image will fill the button size including border
    height: '100%', // Image will fill the button size including border
    objectFit: 'cover',
  },
  '&:hover img': {
    opacity: 0.8,
  },
  margin: theme.spacing(0.5),
}));

interface MapStyleToggleProps {
  src: string;
  value: MapStyle;
}

export default function MapStyleToggle({
  src,
  value,
  ...rest
}: MapStyleToggleProps & ToggleButtonProps) {
  const mapStyle = useDynamicMapStore((state) => state.mapStyle);
  const setMapStyle = useDynamicMapStore((state) => state.setMapStyle);

  return (
    <StyledToggleButton
      {...rest}
      value={value}
      selected={mapStyle === value}
      onChange={(_, value: MapStyle) => {
        setMapStyle(value);
      }}
    >
      <Box overflow="hidden" position="relative" height="100%">
        <img
          src={src}
          alt={titleByMapStyle[value]}
          title={titleByMapStyle[value]}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            textAlign: 'center',
            p: 0.5,
          }}
        >
          <Typography variant="caption">{titleByMapStyle[value]}</Typography>
        </Box>
      </Box>
    </StyledToggleButton>
  );
}
