import {
  CustomTerritory,
  YCustomTerritory,
} from '@plotr/plotr-multiplayer-data';
import { useMultiplayer } from '@plotr/react-multiplayer';

export default function useCustomTerritories() {
  return useMultiplayer.State<CustomTerritory[]>(
    (ydoc) =>
      ydoc
        ?.getArray<YCustomTerritory>('customTerritories')
        ?.toArray()
        .map((v) => v.toJSON() as CustomTerritory) ?? []
  );
}
