import type { SetStateAction } from 'react';
import type { WebrtcProvider } from 'y-webrtc';
import type { Presence } from './useMultiplayerContext';

import { useEffect, useMemo, useState } from 'react';

export interface PresenceResult<T> {
  presence: Presence<T> | null;
  localPresence: T | null;
  setLocalPresence: (nextState: SetStateAction<T | null>) => void;
}

const usePresence = <T extends object>(
  provider: WebrtcProvider | null,
): PresenceResult<T> => {
  const [presence, setPresence] = useState<Presence<T> | null>(null);
  const [localPresence, setLocalPresence] = useState<T | null>(null);
  const [changeListenersReady, setChangeListenersReady] =
    useState<boolean>(false);

  // FIXME: if two users join the same room at the same time, they wont see each other's presence

  // set up awareness change listeners to keep `presence` up to date
  useEffect(() => {
    if (provider == null) {
      setPresence(null);
      return;
    }

    const handleChange = (): void => {
      setPresence(
        Object.fromEntries(provider.awareness.getStates()) as Presence<T>,
      );
    };

    provider.awareness.on('change', handleChange);
    setChangeListenersReady(true);
    return () => {
      provider.awareness.off('change', handleChange);
      setChangeListenersReady(false);
    };
  }, [provider]);

  // keep awareness up to date with `localPresence` changes
  useEffect(() => {
    if (provider == null || !changeListenersReady) return;

    provider.awareness.setLocalState(localPresence);
  }, [localPresence, provider, changeListenersReady]);

  const clientId = useMemo(
    () => provider?.awareness.clientID ?? null,
    [provider],
  );

  // remove local client from presence
  const filteredPresence = useMemo(() => {
    if (clientId == null || presence == null) return presence;
    const { [clientId]: _, ...rest } = presence;
    return rest;
  }, [presence, clientId]);

  return {
    presence: filteredPresence,
    localPresence,
    setLocalPresence,
  };
};

export default usePresence;
