import type { POIGroupRequest } from '../../features/dynamic-map/services/poiService';
import { parseEnv } from '@plotr/common-utils';

const env = parseEnv({ PLOTR_API: process.env.PLOTR_API });

export interface EnterpriseLayerConfig {
  retailProximityHeatmap?: {
    mapboxURLs: string[];
    minWomThreshold?: number;
  };
  blockGroupMatches?: {
    apiPath: string;
  };
}

export interface UserSettings {
  userId: string;
  poiGroups?: POIGroupRequest[];
  enterpriseLayerConfig?: EnterpriseLayerConfig;
  clientZonesAPIPath?: string;
  userGeoAPIPath?: string;
  customerLayers?: string[];
  assignedRoomId?: string;
  featureFlags?: Record<string, boolean>;
}

export async function fetchUserSettings(
  accessToken: string,
): Promise<UserSettings | null> {
  const res = await fetch(`${env.PLOTR_API}/settings`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (res.ok) {
    return res.json() as Promise<UserSettings | null>;
  } else {
    console.error('Error fetching user settings: ', res.statusText);
    return null;
  }
}
