import type mapboxgl from 'mapbox-gl';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Marker } from 'react-map-gl';
import { CircularProgress, Box, Typography } from '@mui/material';
import {
  Bolt as BoltIcon,
  CloudDownload as CloudDownloadIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  GpsFixed as GpsFixedIcon,
} from '@mui/icons-material';
import { CustomPin, plotrMultiplayerData } from '@plotr/plotr-multiplayer-data';

import useDynamicMapStore, {
  MapStyle,
} from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import getPinURL from '~/src/common/helpers/getPinURL';
import useContextMenuStore, {
  ContextMenuOption,
} from '~/src/features/context-menu/useContextMenuStore';
import useMapContext from '~/src/features/dynamic-map/hooks/useMapContext';
import useAccessToken from '~/src/global/hooks/useAccessToken';
import { checkPermission } from '~/src/global/services/permissionService';
import { useGenerateReport } from '../dynamic-map/hooks/useGenerateReport';

const blackTextLightHalo = {
  color: 'black',
  textShadow: `
    1px 1px 0 white, 
    -1px -1px 0 white, 
    1px -1px 0 white, 
    -1px 1px 0 white,
    1px 0px 0 white,
    0px 1px 0 white,
    -1px 0px 0 white,
    0px -1px 0 white
  `,
};

const whiteTextDarkHalo = {
  color: 'white',
  textShadow: `
    1px 1px 0 black, 
    -1px -1px 0 black, 
    1px -1px 0 black, 
    -1px 1px 0 black,
    1px 0px 0 black,
    0px 1px 0 black,
    -1px 0px 0 black,
    0px -1px 0 black
  `,
};

export default function CustomPinMarker({ pin }: { pin: CustomPin }) {
  const { accessToken } = useAccessToken();
  const evaluatedPinId = useDynamicMapStore((state) => state.evaluatedPinId);
  const setEvaluatedPinId = useDynamicMapStore(
    (state) => state.setEvaluatedPinId
  );
  const setEvaluatedDemographicEntity = useDynamicMapStore(
    (state) => state.setEvaluatedDemographicEntity
  );

  const setContextMenu = useContextMenuStore((state) => state.setContextMenu);
  const position = useContextMenuStore((state) => state.position);
  const customPinMethods = plotrMultiplayerData.methods?.pins;

  const [canGenerateReport, setCanGenerateReport] = useState(false);
  const selectCustomPinId = useDynamicMapStore(
    (state) => state.selectCustomPinId
  );
  const setSelectedPinGroup = useDynamicMapStore(
    (state) => state.setSelectedPinGroup
  );
  const map = useMapContext();

  const {
    generate,
    isLoading: reportIsLoading,
    error: reportError,
    url: reportUrl,
  } = useGenerateReport({
    lat: pin.pos.lat,
    lng: pin.pos.lng,
    accessToken,
  });

  const centerMapOnPin = useCallback(
    (position: { lng: number; lat: number }) => {
      if (map != null) {
        map.flyTo({ center: [position.lng, position.lat], essential: true });
      }
    },
    [map]
  );

  useEffect(() => {
    if (accessToken) {
      checkPermission(accessToken, 'read:idealspot_reporting').then(
        setCanGenerateReport
      );
    }
  }, [accessToken]);

  const generateContextMenuOptions = useCallback(() => {
    if (customPinMethods == null) return [];
    const options: ContextMenuOption[] = [
      {
        icon: <BoltIcon fontSize="small" />,
        label: 'Get Pulse',
        onClick: () => {
          setEvaluatedDemographicEntity({
            type: 'point',
            pos: pin.pos,
          });
          setEvaluatedPinId(pin.id);
          setSelectedPinGroup(pin.group);
          selectCustomPinId(null);
        },
      },
      {
        icon: <EditIcon fontSize="small" />,
        label: 'Edit Pin',
        onClick: () => {
          selectCustomPinId(pin.id);
        },
      },
      {
        icon: <GpsFixedIcon fontSize="small" />,
        label: 'Center on Pin',
        onClick: () => centerMapOnPin(pin.pos),
      },
      {
        icon: <DeleteIcon fontSize="small" />,
        label: 'Remove Pin',
        onClick: () => {
          customPinMethods.removePin(pin.id);
          selectCustomPinId(null);
          if (evaluatedPinId === pin.id) {
            setEvaluatedPinId(null);
          }
        },
      },
    ];

    if (accessToken && canGenerateReport) {
      const reportOption = reportIsLoading
        ? {
            icon: <CircularProgress size={20} />,
            label: 'Generating Report...',
            onClick: () => {},
            remainOpenOnClick: true,
          }
        : reportUrl
          ? {
              icon: <CloudDownloadIcon fontSize="small" />,
              label: 'View Report',
              onClick: () => {
                window.open(reportUrl, '_blank');
              },
              remainOpenOnClick: true,
            }
          : reportError
            ? {
                icon: <CloudDownloadIcon fontSize="small" />,
                label: 'Report Generation Failed - Retry?',
                onClick: generate,
                remainOpenOnClick: true,
              }
            : {
                icon: <CloudDownloadIcon fontSize="small" />,
                label: 'Generate Report',
                onClick: generate,
                remainOpenOnClick: true,
              };

      options.splice(1, 0, reportOption);
    }
    return options;
  }, [
    customPinMethods,
    accessToken,
    canGenerateReport,
    setEvaluatedDemographicEntity,
    pin.pos,
    pin.id,
    pin.group,
    setEvaluatedPinId,
    setSelectedPinGroup,
    selectCustomPinId,
    centerMapOnPin,
    evaluatedPinId,
    reportIsLoading,
    reportUrl,
    reportError,
    generate,
  ]);

  //update context menu options if the array changes
  useEffect(() => {
    //don't open the context menu if it's closed
    //make sure the context menu is for the current pin
    if (
      position !== null &&
      position.lng === pin.pos.lng &&
      position.lat === pin.pos.lat
    ) {
      const options = generateContextMenuOptions();
      setContextMenu({
        position: pin.pos,
        offset: [0, -50],
        options,
      });
    }
  }, [
    generateContextMenuOptions,
    pin.pos,
    reportUrl,
    setContextMenu,
    position,
  ]);

  const handleContextMenu = useCallback(
    (e: MouseEvent) => {
      if (customPinMethods == null) return;

      e.preventDefault();
      e.stopPropagation();

      selectCustomPinId(null);
      const options = generateContextMenuOptions();

      setContextMenu({
        position: pin.pos,
        offset: [0, -50],
        options,
      });
    },
    [
      generateContextMenuOptions,
      pin.pos,
      selectCustomPinId,
      setContextMenu,
      customPinMethods,
    ]
  );

  const ref = useRef<mapboxgl.Marker>(null);

  // need to get at the underlying mapboxgl marker element to add the contextmenu event listener
  useEffect(() => {
    const node = ref.current;
    if (node != null) {
      node.getElement().addEventListener('contextmenu', handleContextMenu);
    }

    return () => {
      if (node != null) {
        node.getElement().removeEventListener('contextmenu', handleContextMenu);
      }
    };
  }, [ref, handleContextMenu]);

  // TODO: add shadow beneath all pin images (see default <Marker /> for example)

  const pinURL = getPinURL({
    color: '#3FB1CE',
    background: '#FFFFFF',
  });

  // this helps position the base of the marker image over the pin's lat/lng
  const rootFontSize = getComputedStyle(document.documentElement).fontSize;
  const markerOffset = pin.label.length > 0 ? parseFloat(rootFontSize) : 0;

  const mapStyle = useDynamicMapStore((state) => state.mapStyle);
  const useWhiteText =
    mapStyle === MapStyle.Satellite || mapStyle === MapStyle.Dark;

  const mapStyleLoaded = useMapContext() != null;

  // HACK: only render Markers when the map style is fully loaded
  return mapStyleLoaded && customPinMethods != null ? (
    <Marker
      ref={ref}
      key={pin.id}
      longitude={pin.pos.lng}
      latitude={pin.pos.lat}
      scale={1.5}
      onClick={() => selectCustomPinId(pin.id)}
      anchor="bottom"
      offset={[0, markerOffset]}
    >
      <Box sx={{ textAlign: 'center', cursor: 'pointer' }}>
        <img style={{ width: '3rem' }} src={pinURL} alt="custom pin" />
        <Typography
          variant="body1"
          sx={useWhiteText ? whiteTextDarkHalo : blackTextLightHalo}
        >
          {pin.label}
        </Typography>
      </Box>
    </Marker>
  ) : null;
}
