type EnvInput<T extends string> = T[] | Record<T, string | undefined>;

export function parseEnv<T extends string>(
  envInput: EnvInput<T>,
): Record<T, string> {
  const env = {} as Record<T, string>;

  if (Array.isArray(envInput)) {
    // Input is an array of environment variable names
    return envInput.reduce((result, name) => {
      const value = process.env[name];
      if (value === undefined || value === '') {
        throw new Error(`Environment variable ${name} is not set`);
      }
      result[name] = value;
      return result;
    }, env);
  } else {
    // Input is a record of environment variable names to values
    Object.entries(envInput).forEach(([key, value]) => {
      if (typeof value !== 'string' || value === '') {
        throw new Error(`Environment variable ${key} is not set`);
      }
      env[key as T] = value;
    });
    return env;
  }
}
