import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { create } from 'zustand';
import { checkPermission } from '../../services/permissionService';
import useAccessToken from '../../hooks/useAccessToken';
import EnterprisePaywall from './components/EnterprisePaywall';
import NewUserDialog from './components/NewUserDialog';
import { Dialog } from '@mui/material';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

interface SubscriptionDialogStore {
  isOpen: boolean;
  open: () => void;
  close: () => void;
}

export const useSubscriptionDialog = create<SubscriptionDialogStore>((set) => ({
  isOpen: false,
  open: () => set({ isOpen: true }),
  close: () => set({ isOpen: false }),
}));

export default function SubscriptionDialog() {
  const { isOpen, close } = useSubscriptionDialog();
  const [hasPro, setHasPro] = useState<boolean>(false);

  const { user } = useAuth0();
  const { accessToken } = useAccessToken();

  const hasStripeClaim =
    (user && user[`https://app.getplotr.com/stripe_customer_id`] != null) ??
    false;

  useEffect(() => {
    const fetchPro = async () => {
      if (accessToken) {
        const status = await checkPermission(accessToken, 'read:pro');
        setHasPro(status);
      }
    };
    fetchPro();
  }, [accessToken]);

  const userId = user?.sub?.split('|')[1];

  const stripeFonts =
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif';

  return userId != null ? (
    <Dialog open={isOpen} fullWidth maxWidth={'md'}>
      {hasStripeClaim && hasPro && (
        <EnterprisePaywall stripeFonts={stripeFonts} handleClose={close} />
      )}
      {hasStripeClaim && !hasPro && (
        <NewUserDialog
          handleClose={close}
          user={user}
          userId={userId}
          stripeFonts={stripeFonts}
          header={'Welcome back'}
          subheader="Pick right back up where you left off."
        />
      )}
      {!hasStripeClaim && (
        <NewUserDialog
          handleClose={close}
          user={user}
          userId={userId}
          stripeFonts={stripeFonts}
          header={'Unlock Nationwide Data Today'}
          subheader="Instantly access and analyze any location you want."
        />
      )}
    </Dialog>
  ) : null;
}
