import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { clearPersistedState } from '~/src/common/helpers/clearPersistedState';

import { Box, Grid } from '@mui/material';

function LoadingComponent() {
  return (
    <Grid container justifyContent="center" alignItems="center" height="100vh">
      <Grid item>
        <Box>Loading...</Box>
      </Grid>
    </Grid>
  );
}

// if user is not authenticated, redirect to marketing site (where sign-in button is)
export default function useAuthRedirect() {
  const { isAuthenticated, isLoading, user } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      clearPersistedState();
      window.location.replace('https://www.plotr.com');
    }
  }, [isAuthenticated, isLoading]);

  return { user, isLoading, LoadingComponent };
}
