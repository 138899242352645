import { useState } from 'react';
import darkMap from '~/src/assets/images/map-styles/mapbox_dark.png';

import lightMap from '~/src/assets/images/map-styles/mapbox_light.png';
import streetMap from '~/src/assets/images/map-styles/mapbox_streets.png';
import satStreetMap from '~/src/assets/images/map-styles/mapbox_satellite.png';
import { MapStyle } from '~/src/features/dynamic-map/hooks/useDynamicMapStore';

import LayerManager from './components/LayerManager';
import MapStyleToggle from './components/MapStyleToggle';
import { Box } from '@mui/material';

export default function MapLayersMenu() {
  const [open, setOpen] = useState(false);

  // TODO: Enable extra base layers for enterprise when zones fetch new style ordering layer.

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'space-between',
        rowGap: '1em',
        height: '100%',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          textAlign: 'center',
        }}
      >
        <Box className="controller-section-label">Map Layers</Box>
        <Box
          onClick={() => setOpen(true)}
          sx={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
        >
          add
        </Box>
      </Box>
      <LayerManager open={open} setOpen={setOpen} />
      <hr />
      <Box>
        <p className="controller-section-label">Base Map</p>
        <Box display="flex" width="100%">
          <MapStyleToggle value={MapStyle.Streets} src={streetMap} />
          <MapStyleToggle value={MapStyle.Satellite} src={satStreetMap} />
          <MapStyleToggle value={MapStyle.Light} src={lightMap} />
          <MapStyleToggle value={MapStyle.Dark} src={darkMap} />
        </Box>
      </Box>
    </div>
  );
}
