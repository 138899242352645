{
  "32405": { "Barber": 43, "Cosmetologist": 332 },
  "34947": { "Barber": 34, "Cosmetologist": 111 },
  "33155": { "Barber": 87, "Cosmetologist": 681 },
  "32960": { "Barber": 41, "Cosmetologist": 338 },
  "33716": { "Barber": 25, "Cosmetologist": 186 },
  "33076": { "Barber": 43, "Cosmetologist": 488 },
  "33980": { "Barber": 21, "Cosmetologist": 117 },
  "33319": { "Barber": 107, "Cosmetologist": 621 },
  "33144": { "Barber": 105, "Cosmetologist": 559 },
  "33614": { "Barber": 217, "Cosmetologist": 979 },
  "34609": { "Barber": 77, "Cosmetologist": 512 },
  "32164": { "Barber": 45, "Cosmetologist": 692 },
  "33175": { "Barber": 92, "Cosmetologist": 878 },
  "32804": { "Barber": 35, "Cosmetologist": 265 },
  "32751": { "Barber": 26, "Cosmetologist": 272 },
  "33156": { "Barber": 25, "Cosmetologist": 227 },
  "32131": { "Barber": 4, "Cosmetologist": 45 },
  "33012": { "Barber": 213, "Cosmetologist": 1250 },
  "32505": { "Barber": 78, "Cosmetologist": 307 },
  "33880": { "Barber": 83, "Cosmetologist": 453 },
  "32327": { "Barber": 38, "Cosmetologist": 211 },
  "33850": { "Barber": 17, "Cosmetologist": 60 },
  "34994": { "Barber": 34, "Cosmetologist": 276 },
  "34102": { "Barber": 25, "Cosmetologist": 144 },
  "32792": { "Barber": 96, "Cosmetologist": 588 },
  "32460": { "Barber": 5, "Cosmetologist": 34 },
  "32277": { "Barber": 72, "Cosmetologist": 289 },
  "34208": { "Barber": 66, "Cosmetologist": 364 },
  "33480": { "Barber": 10, "Cosmetologist": 63 },
  "32176": { "Barber": 21, "Cosmetologist": 156 },
  "32216": { "Barber": 81, "Cosmetologist": 463 },
  "32955": { "Barber": 54, "Cosmetologist": 462 },
  "32420": { "Barber": 4, "Cosmetologist": 16 },
  "32503": { "Barber": 52, "Cosmetologist": 372 },
  "33813": { "Barber": 54, "Cosmetologist": 381 },
  "34665": { "Barber": 1, "Cosmetologist": 2 },
  "34205": { "Barber": 43, "Cosmetologist": 305 },
  "33709": { "Barber": 40, "Cosmetologist": 437 },
  "32209": { "Barber": 85, "Cosmetologist": 235 },
  "32205": { "Barber": 72, "Cosmetologist": 331 },
  "34606": { "Barber": 72, "Cosmetologist": 315 },
  "32937": { "Barber": 18, "Cosmetologist": 340 },
  "34285": { "Barber": 24, "Cosmetologist": 145 },
  "32927": { "Barber": 45, "Cosmetologist": 285 },
  "32114": { "Barber": 38, "Cosmetologist": 261 },
  "34630": { "Barber": 1 },
  "33325": { "Barber": 43, "Cosmetologist": 573 },
  "33860": { "Barber": 50, "Cosmetologist": 210 },
  "32922": { "Barber": 32, "Cosmetologist": 151 },
  "33771": { "Barber": 78, "Cosmetologist": 292 },
  "33483": { "Barber": 19, "Cosmetologist": 175 },
  "33143": { "Barber": 25, "Cosmetologist": 281 },
  "32570": { "Barber": 32, "Cosmetologist": 248 },
  "34683": { "Barber": 31, "Cosmetologist": 374 },
  "34698": { "Barber": 64, "Cosmetologist": 398 },
  "33604": { "Barber": 145, "Cosmetologist": 567 },
  "34237": { "Barber": 42, "Cosmetologist": 177 },
  "32506": { "Barber": 62, "Cosmetologist": 315 },
  "32204": { "Barber": 16, "Cosmetologist": 97 },
  "32656": { "Barber": 12, "Cosmetologist": 122 },
  "33852": { "Barber": 39, "Cosmetologist": 140 },
  "33803": { "Barber": 46, "Cosmetologist": 265 },
  "32608": { "Barber": 41, "Cosmetologist": 297 },
  "32303": { "Barber": 110, "Cosmetologist": 528 },
  "33334": { "Barber": 61, "Cosmetologist": 435 },
  "33558": { "Barber": 34, "Cosmetologist": 275 },
  "32703": { "Barber": 93, "Cosmetologist": 593 },
  "33770": { "Barber": 50, "Cosmetologist": 289 },
  "32310": { "Barber": 40, "Cosmetologist": 85 },
  "34217": { "Barber": 3, "Cosmetologist": 23 },
  "33406": { "Barber": 67, "Cosmetologist": 437 },
  "33314": { "Barber": 45, "Cosmetologist": 453 },
  "33162": { "Barber": 94, "Cosmetologist": 518 },
  "34239": { "Barber": 31, "Cosmetologist": 240 },
  "32962": { "Barber": 54, "Cosmetologist": 315 },
  "34134": { "Barber": 24, "Cosmetologist": 173 },
  "34430": { "Barber": 3, "Cosmetologist": 8 },
  "32210": { "Barber": 125, "Cosmetologist": 723 },
  "33884": { "Barber": 52, "Cosmetologist": 411 },
  "32137": { "Barber": 49, "Cosmetologist": 556 },
  "32233": { "Barber": 31, "Cosmetologist": 172 },
  "34695": { "Barber": 34, "Cosmetologist": 192 },
  "32696": { "Barber": 22, "Cosmetologist": 103 },
  "33455": { "Barber": 15, "Cosmetologist": 198 },
  "32780": { "Barber": 41, "Cosmetologist": 385 },
  "33477": { "Barber": 5, "Cosmetologist": 108 },
  "33908": { "Barber": 32, "Cosmetologist": 442 },
  "34997": { "Barber": 53, "Cosmetologist": 498 },
  "33311": { "Barber": 144, "Cosmetologist": 597 },
  "33624": { "Barber": 89, "Cosmetologist": 622 },
  "33578": { "Barber": 95, "Cosmetologist": 733 },
  "32571": { "Barber": 45, "Cosmetologist": 380 },
  "34447": { "Barber": 2, "Cosmetologist": 9 },
  "34266": { "Barber": 11, "Cosmetologist": 196 },
  "32720": { "Barber": 43, "Cosmetologist": 314 },
  "32208": { "Barber": 96, "Cosmetologist": 307 },
  "32504": { "Barber": 58, "Cosmetologist": 336 },
  "32462": { "Barber": 8, "Cosmetologist": 22 },
  "32701": { "Barber": 42, "Cosmetologist": 287 },
  "33550": { "Barber": 3, "Cosmetologist": 4 },
  "34974": { "Barber": 36, "Cosmetologist": 199 },
  "33853": { "Barber": 28, "Cosmetologist": 104 },
  "34231": { "Barber": 68, "Cosmetologist": 469 },
  "33706": { "Barber": 15, "Cosmetologist": 129 },
  "33825": { "Barber": 38, "Cosmetologist": 147 },
  "33165": { "Barber": 132, "Cosmetologist": 967 },
  "32266": { "Barber": 4, "Cosmetologist": 78 },
  "45405": { "Barber": 4, "Cosmetologist": 1 },
  "32812": { "Barber": 55, "Cosmetologist": 393 },
  "32789": { "Barber": 29, "Cosmetologist": 326 },
  "33183": { "Barber": 53, "Cosmetologist": 540 },
  "33710": { "Barber": 54, "Cosmetologist": 471 },
  "34654": { "Barber": 28, "Cosmetologist": 270 },
  "33186": { "Barber": 101, "Cosmetologist": 944 },
  "34741": { "Barber": 140, "Cosmetologist": 804 },
  "34461": { "Barber": 6, "Cosmetologist": 78 },
  "33702": { "Barber": 45, "Cosmetologist": 398 },
  "32707": { "Barber": 78, "Cosmetologist": 464 },
  "32564": { "Barber": 2, "Cosmetologist": 16 },
  "34284": { "Barber": 1, "Cosmetologist": 7 },
  "33435": { "Barber": 64, "Cosmetologist": 389 },
  "34475": { "Barber": 45, "Cosmetologist": 96 },
  "34601": { "Barber": 45, "Cosmetologist": 194 },
  "33594": { "Barber": 67, "Cosmetologist": 393 },
  "34234": { "Barber": 44, "Cosmetologist": 181 },
  "32693": { "Barber": 13, "Cosmetologist": 104 },
  "34243": { "Barber": 42, "Cosmetologist": 433 },
  "33169": { "Barber": 89, "Cosmetologist": 456 },
  "32148": { "Barber": 13, "Cosmetologist": 50 },
  "34203": { "Barber": 61, "Cosmetologist": 495 },
  "32442": { "Barber": 3, "Cosmetologist": 50 },
  "33625": { "Barber": 58, "Cosmetologist": 551 },
  "32825": { "Barber": 143, "Cosmetologist": 1185 },
  "32308": { "Barber": 38, "Cosmetologist": 218 },
  "33823": { "Barber": 50, "Cosmetologist": 308 },
  "34209": { "Barber": 31, "Cosmetologist": 325 },
  "33193": { "Barber": 74, "Cosmetologist": 755 },
  "34420": { "Barber": 34, "Cosmetologist": 161 },
  "33870": { "Barber": 62, "Cosmetologist": 231 },
  "33881": { "Barber": 70, "Cosmetologist": 343 },
  "32725": { "Barber": 110, "Cosmetologist": 536 },
  "33069": { "Barber": 46, "Cosmetologist": 347 },
  "33157": { "Barber": 77, "Cosmetologist": 840 },
  "32502": { "Barber": 6, "Cosmetologist": 93 },
  "33523": { "Barber": 29, "Cosmetologist": 100 },
  "34761": { "Barber": 66, "Cosmetologist": 807 },
  "32909": { "Barber": 63, "Cosmetologist": 361 },
  "32250": { "Barber": 44, "Cosmetologist": 334 },
  "32304": { "Barber": 56, "Cosmetologist": 214 },
  "34667": { "Barber": 44, "Cosmetologist": 317 },
  "33954": { "Barber": 15, "Cosmetologist": 180 },
  "32526": { "Barber": 50, "Cosmetologist": 514 },
  "32501": { "Barber": 26, "Cosmetologist": 85 },
  "34995": { "Barber": 1, "Cosmetologist": 9 },
  "32401": { "Barber": 31, "Cosmetologist": 236 },
  "32025": { "Barber": 21, "Cosmetologist": 181 },
  "32578": { "Barber": 14, "Cosmetologist": 283 },
  "45177": { "Barber": 2 },
  "33055": { "Barber": 104, "Cosmetologist": 696 },
  "36549": { "Barber": 2, "Cosmetologist": 18 },
  "32627": { "Barber": 6, "Cosmetologist": 7 },
  "32008": { "Barber": 5, "Cosmetologist": 56 },
  "32668": { "Barber": 6, "Cosmetologist": 50 },
  "33062": { "Barber": 24, "Cosmetologist": 370 },
  "33031": { "Barber": 8, "Cosmetologist": 62 },
  "44060": { "Barber": 50, "Cosmetologist": 4 },
  "32822": { "Barber": 176, "Cosmetologist": 725 },
  "32334": { "Barber": 2, "Cosmetologist": 11 },
  "32724": { "Barber": 55, "Cosmetologist": 363 },
  "33308": { "Barber": 33, "Cosmetologist": 440 },
  "33547": { "Barber": 19, "Cosmetologist": 201 },
  "62002": { "Barber": 4, "Cosmetologist": 31 },
  "33145": { "Barber": 41, "Cosmetologist": 470 },
  "34983": { "Barber": 95, "Cosmetologist": 599 },
  "38571": { "Barber": 30, "Cosmetologist": 3 },
  "32217": { "Barber": 39, "Cosmetologist": 233 },
  "32819": { "Barber": 44, "Cosmetologist": 530 },
  "33142": { "Barber": 112, "Cosmetologist": 415 },
  "32778": { "Barber": 28, "Cosmetologist": 215 },
  "33189": { "Barber": 51, "Cosmetologist": 376 },
  "32809": { "Barber": 103, "Cosmetologist": 378 },
  "33549": { "Barber": 27, "Cosmetologist": 194 },
  "32561": { "Barber": 14, "Cosmetologist": 70 },
  "33177": { "Barber": 105, "Cosmetologist": 867 },
  "32514": { "Barber": 84, "Cosmetologist": 489 },
  "33598": { "Barber": 47, "Cosmetologist": 234 },
  "33812": { "Barber": 9, "Cosmetologist": 166 },
  "80922": { "Barber": 26, "Cosmetologist": 176 },
  "33755": { "Barber": 67, "Cosmetologist": 258 },
  "34614": { "Barber": 11, "Cosmetologist": 92 },
  "32566": { "Barber": 44, "Cosmetologist": 547 },
  "33432": { "Barber": 40, "Cosmetologist": 375 },
  "34133": { "Barber": 1, "Cosmetologist": 7 },
  "32605": { "Barber": 24, "Cosmetologist": 201 },
  "33133": { "Barber": 37, "Cosmetologist": 303 },
  "33615": { "Barber": 138, "Cosmetologist": 779 },
  "33565": { "Barber": 21, "Cosmetologist": 164 },
  "34639": { "Barber": 53, "Cosmetologist": 366 },
  "33024": { "Barber": 176, "Cosmetologist": 1342 },
  "33431": { "Barber": 19, "Cosmetologist": 319 },
  "33181": { "Barber": 30, "Cosmetologist": 345 },
  "32455": { "Barber": 1, "Cosmetologist": 30 },
  "33609": { "Barber": 31, "Cosmetologist": 292 },
  "33472": { "Barber": 12, "Cosmetologist": 210 },
  "33777": { "Barber": 23, "Cosmetologist": 219 },
  "33556": { "Barber": 26, "Cosmetologist": 362 },
  "33511": { "Barber": 136, "Cosmetologist": 657 },
  "33487": { "Barber": 20, "Cosmetologist": 294 },
  "32708": { "Barber": 60, "Cosmetologist": 481 },
  "33704": { "Barber": 24, "Cosmetologist": 157 },
  "33321": { "Barber": 84, "Cosmetologist": 627 },
  "34954": { "Barber": 4, "Cosmetologist": 1 },
  "34221": { "Barber": 53, "Cosmetologist": 488 },
  "38553": { "Barber": 2, "Cosmetologist": 1 },
  "34470": { "Barber": 50, "Cosmetologist": 225 },
  "32092": { "Barber": 21, "Cosmetologist": 383 },
  "33130": { "Barber": 45, "Cosmetologist": 374 },
  "32615": { "Barber": 13, "Cosmetologist": 119 },
  "32404": { "Barber": 35, "Cosmetologist": 388 },
  "33713": { "Barber": 48, "Cosmetologist": 602 },
  "19707": { "Barber": 3 },
  "32757": { "Barber": 42, "Cosmetologist": 313 },
  "47714": { "Barber": 17, "Cosmetologist": 2 },
  "33067": { "Barber": 34, "Cosmetologist": 379 },
  "32180": { "Barber": 8, "Cosmetologist": 31 },
  "32177": { "Barber": 26, "Cosmetologist": 185 },
  "32207": { "Barber": 80, "Cosmetologist": 382 },
  "34769": { "Barber": 72, "Cosmetologist": 288 },
  "32086": { "Barber": 26, "Cosmetologist": 352 },
  "33001": { "Barber": 1, "Cosmetologist": 1 },
  "34787": { "Barber": 80, "Cosmetologist": 1087 },
  "34981": { "Barber": 9, "Cosmetologist": 33 },
  "33484": { "Barber": 34, "Cosmetologist": 293 },
  "32967": { "Barber": 32, "Cosmetologist": 243 },
  "32068": { "Barber": 44, "Cosmetologist": 480 },
  "34135": { "Barber": 54, "Cosmetologist": 595 },
  "32170": { "Barber": 2, "Cosmetologist": 8 },
  "32567": { "Barber": 7, "Cosmetologist": 36 },
  "34952": { "Barber": 97, "Cosmetologist": 551 },
  "33629": { "Barber": 19, "Cosmetologist": 238 },
  "34481": { "Barber": 30, "Cosmetologist": 130 },
  "34685": { "Barber": 4, "Cosmetologist": 172 },
  "33060": { "Barber": 77, "Cosmetologist": 394 },
  "29607": { "Barber": 1, "Cosmetologist": 15 },
  "33126": { "Barber": 94, "Cosmetologist": 842 },
  "34957": { "Barber": 25, "Cosmetologist": 289 },
  "32680": { "Barber": 8, "Cosmetologist": 58 },
  "34953": { "Barber": 169, "Cosmetologist": 1067 },
  "33619": { "Barber": 128, "Cosmetologist": 400 },
  "33569": { "Barber": 44, "Cosmetologist": 358 },
  "32302": { "Barber": 3, "Cosmetologist": 6 },
  "30016": { "Barber": 2, "Cosmetologist": 20 },
  "33903": { "Barber": 36, "Cosmetologist": 207 },
  "33605": { "Barber": 60, "Cosmetologist": 149 },
  "33761": { "Barber": 20, "Cosmetologist": 218 },
  "32920": { "Barber": 15, "Cosmetologist": 90 },
  "34607": { "Barber": 12, "Cosmetologist": 89 },
  "33781": { "Barber": 62, "Cosmetologist": 644 },
  "32073": { "Barber": 73, "Cosmetologist": 469 },
  "32244": { "Barber": 116, "Cosmetologist": 774 },
  "33029": { "Barber": 67, "Cosmetologist": 547 },
  "28781": { "Barber": 1, "Cosmetologist": 7 },
  "32129": { "Barber": 17, "Cosmetologist": 286 },
  "33020": { "Barber": 73, "Cosmetologist": 635 },
  "22030": { "Barber": 1, "Cosmetologist": 1 },
  "32776": { "Barber": 5, "Cosmetologist": 120 },
  "33617": { "Barber": 117, "Cosmetologist": 360 },
  "32312": { "Barber": 32, "Cosmetologist": 333 },
  "32926": { "Barber": 29, "Cosmetologist": 227 },
  "28789": { "Barber": 1, "Cosmetologist": 38 },
  "32746": { "Barber": 48, "Cosmetologist": 570 },
  "34428": { "Barber": 3, "Cosmetologist": 92 },
  "33315": { "Barber": 11, "Cosmetologist": 154 },
  "32309": { "Barber": 23, "Cosmetologist": 248 },
  "32311": { "Barber": 25, "Cosmetologist": 214 },
  "33444": { "Barber": 39, "Cosmetologist": 269 },
  "34105": { "Barber": 13, "Cosmetologist": 248 },
  "33707": { "Barber": 28, "Cosmetologist": 275 },
  "33607": { "Barber": 108, "Cosmetologist": 331 },
  "32347": { "Barber": 11, "Cosmetologist": 79 },
  "33469": { "Barber": 13, "Cosmetologist": 160 },
  "32305": { "Barber": 59, "Cosmetologist": 145 },
  "32080": { "Barber": 6, "Cosmetologist": 212 },
  "32211": { "Barber": 82, "Cosmetologist": 370 },
  "33918": { "Barber": 6, "Cosmetologist": 3 },
  "33184": { "Barber": 65, "Cosmetologist": 370 },
  "32763": { "Barber": 46, "Cosmetologist": 243 },
  "33844": { "Barber": 122, "Cosmetologist": 411 },
  "34452": { "Barber": 13, "Cosmetologist": 118 },
  "32082": { "Barber": 16, "Cosmetologist": 195 },
  "33872": { "Barber": 19, "Cosmetologist": 141 },
  "34110": { "Barber": 11, "Cosmetologist": 273 },
  "32034": { "Barber": 27, "Cosmetologist": 343 },
  "34684": { "Barber": 44, "Cosmetologist": 341 },
  "32579": { "Barber": 10, "Cosmetologist": 97 },
  "33461": { "Barber": 112, "Cosmetologist": 605 },
  "33782": { "Barber": 23, "Cosmetologist": 511 },
  "33916": { "Barber": 51, "Cosmetologist": 276 },
  "32631": { "Barber": 3, "Cosmetologist": 3 },
  "33134": { "Barber": 79, "Cosmetologist": 569 },
  "34948": { "Barber": 1, "Cosmetologist": 4 },
  "33170": { "Barber": 20, "Cosmetologist": 188 },
  "32003": { "Barber": 21, "Cosmetologist": 273 },
  "72619": { "Barber": 1, "Cosmetologist": 1 },
  "34949": { "Barber": 5, "Cosmetologist": 68 },
  "32541": { "Barber": 12, "Cosmetologist": 285 },
  "33566": { "Barber": 40, "Cosmetologist": 253 },
  "33618": { "Barber": 49, "Cosmetologist": 342 },
  "45068": { "Barber": 1, "Cosmetologist": 1 },
  "33584": { "Barber": 57, "Cosmetologist": 283 },
  "32246": { "Barber": 81, "Cosmetologist": 929 },
  "33756": { "Barber": 45, "Cosmetologist": 312 },
  "34281": { "Barber": 3, "Cosmetologist": 2 },
  "33013": { "Barber": 102, "Cosmetologist": 580 },
  "32218": { "Barber": 121, "Cosmetologist": 764 },
  "32439": { "Barber": 4, "Cosmetologist": 158 },
  "33811": { "Barber": 51, "Cosmetologist": 231 },
  "32536": { "Barber": 44, "Cosmetologist": 283 },
  "32301": { "Barber": 97, "Cosmetologist": 255 },
  "33436": { "Barber": 49, "Cosmetologist": 705 },
  "34202": { "Barber": 19, "Cosmetologist": 225 },
  "25360": { "Barber": 1 },
  "33596": { "Barber": 26, "Cosmetologist": 284 },
  "34711": { "Barber": 94, "Cosmetologist": 770 },
  "33009": { "Barber": 90, "Cosmetologist": 822 },
  "33579": { "Barber": 86, "Cosmetologist": 537 },
  "32839": { "Barber": 114, "Cosmetologist": 740 },
  "34219": { "Barber": 36, "Cosmetologist": 338 },
  "33559": { "Barber": 36, "Cosmetologist": 225 },
  "33538": { "Barber": 2, "Cosmetologist": 30 },
  "33774": { "Barber": 20, "Cosmetologist": 207 },
  "32174": { "Barber": 66, "Cosmetologist": 639 },
  "34145": { "Barber": 10, "Cosmetologist": 160 },
  "34291": { "Barber": 8, "Cosmetologist": 106 },
  "33317": { "Barber": 67, "Cosmetologist": 478 },
  "32818": { "Barber": 103, "Cosmetologist": 783 },
  "32954": { "Barber": 1, "Cosmetologist": 7 },
  "34448": { "Barber": 5, "Cosmetologist": 92 },
  "33776": { "Barber": 6, "Cosmetologist": 146 },
  "32601": { "Barber": 25, "Cosmetologist": 111 },
  "15650": { "Barber": 2, "Cosmetologist": 10 },
  "34608": { "Barber": 77, "Cosmetologist": 386 },
  "33772": { "Barber": 30, "Cosmetologist": 302 },
  "21641": { "Barber": 1 },
  "34786": { "Barber": 31, "Cosmetologist": 464 },
  "32118": { "Barber": 17, "Cosmetologist": 159 },
  "33873": { "Barber": 18, "Cosmetologist": 100 },
  "33935": { "Barber": 24, "Cosmetologist": 194 },
  "32260": { "Barber": 1, "Cosmetologist": 3 },
  "32257": { "Barber": 52, "Cosmetologist": 523 },
  "33810": { "Barber": 71, "Cosmetologist": 492 },
  "32159": { "Barber": 17, "Cosmetologist": 249 },
  "32934": { "Barber": 14, "Cosmetologist": 182 },
  "32225": { "Barber": 71, "Cosmetologist": 712 },
  "34471": { "Barber": 42, "Cosmetologist": 310 },
  "33433": { "Barber": 33, "Cosmetologist": 641 },
  "33040": { "Barber": 43, "Cosmetologist": 394 },
  "33765": { "Barber": 38, "Cosmetologist": 197 },
  "34293": { "Barber": 28, "Cosmetologist": 459 },
  "32779": { "Barber": 17, "Cosmetologist": 270 },
  "34638": { "Barber": 40, "Cosmetologist": 363 },
  "32317": { "Barber": 27, "Cosmetologist": 158 },
  "33136": { "Barber": 24, "Cosmetologist": 172 },
  "32767": { "Barber": 1, "Cosmetologist": 18 },
  "32169": { "Barber": 2, "Cosmetologist": 105 },
  "33407": { "Barber": 72, "Cosmetologist": 388 },
  "33146": { "Barber": 3, "Cosmetologist": 54 },
  "32163": { "Barber": 8, "Cosmetologist": 140 },
  "34442": { "Barber": 21, "Cosmetologist": 138 },
  "32333": { "Barber": 25, "Cosmetologist": 77 },
  "33603": { "Barber": 45, "Cosmetologist": 180 },
  "32903": { "Barber": 10, "Cosmetologist": 121 },
  "32038": { "Barber": 6, "Cosmetologist": 58 },
  "32424": { "Barber": 9, "Cosmetologist": 36 },
  "33065": { "Barber": 107, "Cosmetologist": 834 },
  "33950": { "Barber": 15, "Cosmetologist": 196 },
  "33150": { "Barber": 48, "Cosmetologist": 202 },
  "30606": { "Barber": 1, "Cosmetologist": 9 },
  "33330": { "Barber": 8, "Cosmetologist": 176 },
  "32181": { "Barber": 5, "Cosmetologist": 10 },
  "33139": { "Barber": 63, "Cosmetologist": 521 },
  "32321": { "Barber": 5, "Cosmetologist": 39 },
  "45648": { "Barber": 1 },
  "33801": { "Barber": 68, "Cosmetologist": 239 },
  "33542": { "Barber": 48, "Cosmetologist": 167 },
  "33014": { "Barber": 96, "Cosmetologist": 654 },
  "32162": { "Barber": 11, "Cosmetologist": 143 },
  "32951": { "Barber": 12, "Cosmetologist": 94 },
  "32732": { "Barber": 4, "Cosmetologist": 40 },
  "34760": { "Barber": 2, "Cosmetologist": 16 },
  "32953": { "Barber": 35, "Cosmetologist": 283 },
  "34748": { "Barber": 40, "Cosmetologist": 321 },
  "32714": { "Barber": 77, "Cosmetologist": 520 },
  "32219": { "Barber": 43, "Cosmetologist": 136 },
  "32618": { "Barber": 4, "Cosmetologist": 56 },
  "33543": { "Barber": 43, "Cosmetologist": 441 },
  "33610": { "Barber": 131, "Cosmetologist": 377 },
  "33647": { "Barber": 96, "Cosmetologist": 566 },
  "34286": { "Barber": 46, "Cosmetologist": 309 },
  "32808": { "Barber": 94, "Cosmetologist": 512 },
  "33612": { "Barber": 134, "Cosmetologist": 400 },
  "34613": { "Barber": 9, "Cosmetologist": 193 },
  "33744": { "Barber": 1, "Cosmetologist": 6 },
  "28105": { "Barber": 2, "Cosmetologist": 322 },
  "32713": { "Barber": 20, "Cosmetologist": 264 },
  "32340": { "Barber": 11, "Cosmetologist": 52 },
  "30029": { "Barber": 1 },
  "32358": { "Barber": 4, "Cosmetologist": 13 },
  "33983": { "Barber": 13, "Cosmetologist": 176 },
  "32431": { "Barber": 9, "Cosmetologist": 52 },
  "34224": { "Barber": 24, "Cosmetologist": 177 },
  "33064": { "Barber": 113, "Cosmetologist": 766 },
  "32765": { "Barber": 71, "Cosmetologist": 669 },
  "32084": { "Barber": 28, "Cosmetologist": 344 },
  "37331": { "Barber": 4, "Cosmetologist": 1 },
  "62704": { "Barber": 1, "Cosmetologist": 2 },
  "33428": { "Barber": 67, "Cosmetologist": 747 },
  "34991": { "Barber": 3, "Cosmetologist": 14 },
  "36420": { "Barber": 1, "Cosmetologist": 2 },
  "34491": { "Barber": 18, "Cosmetologist": 259 },
  "32806": { "Barber": 45, "Cosmetologist": 328 },
  "34788": { "Barber": 24, "Cosmetologist": 144 },
  "38583": { "Barber": 49, "Cosmetologist": 3 },
  "33027": { "Barber": 90, "Cosmetologist": 1050 },
  "33778": { "Barber": 19, "Cosmetologist": 167 },
  "33351": { "Barber": 84, "Cosmetologist": 536 },
  "33805": { "Barber": 40, "Cosmetologist": 159 },
  "32046": { "Barber": 10, "Cosmetologist": 74 },
  "33703": { "Barber": 13, "Cosmetologist": 245 },
  "32773": { "Barber": 69, "Cosmetologist": 342 },
  "32810": { "Barber": 62, "Cosmetologist": 482 },
  "33563": { "Barber": 81, "Cosmetologist": 248 },
  "32531": { "Barber": 1, "Cosmetologist": 40 },
  "33437": { "Barber": 26, "Cosmetologist": 336 },
  "33328": { "Barber": 20, "Cosmetologist": 474 },
  "29708": { "Barber": 2, "Cosmetologist": 123 },
  "33510": { "Barber": 69, "Cosmetologist": 275 },
  "32716": { "Barber": 3, "Cosmetologist": 16 },
  "32534": { "Barber": 43, "Cosmetologist": 175 },
  "32750": { "Barber": 45, "Cosmetologist": 265 },
  "33071": { "Barber": 50, "Cosmetologist": 488 },
  "34275": { "Barber": 19, "Cosmetologist": 228 },
  "24202": { "Barber": 1, "Cosmetologist": 3 },
  "33418": { "Barber": 24, "Cosmetologist": 514 },
  "33611": { "Barber": 47, "Cosmetologist": 386 },
  "33637": { "Barber": 26, "Cosmetologist": 140 },
  "33876": { "Barber": 5, "Cosmetologist": 40 },
  "34736": { "Barber": 30, "Cosmetologist": 237 },
  "49721": { "Barber": 4, "Cosmetologist": 77 },
  "33173": { "Barber": 38, "Cosmetologist": 383 },
  "32952": { "Barber": 19, "Cosmetologist": 256 },
  "27243": { "Barber": 1, "Cosmetologist": 20 },
  "34479": { "Barber": 33, "Cosmetologist": 136 },
  "33634": { "Barber": 91, "Cosmetologist": 374 },
  "34604": { "Barber": 10, "Cosmetologist": 113 },
  "33309": { "Barber": 99, "Cosmetologist": 532 },
  "32168": { "Barber": 38, "Cosmetologist": 385 },
  "33613": { "Barber": 66, "Cosmetologist": 265 },
  "32588": { "Barber": 2, "Cosmetologist": 2 },
  "33809": { "Barber": 71, "Cosmetologist": 327 },
  "30041": { "Barber": 1, "Cosmetologist": 30 },
  "34656": { "Barber": 3, "Cosmetologist": 5 },
  "32206": { "Barber": 33, "Cosmetologist": 150 },
  "33705": { "Barber": 45, "Cosmetologist": 300 },
  "33708": { "Barber": 15, "Cosmetologist": 160 },
  "34120": { "Barber": 65, "Cosmetologist": 660 },
  "92677": { "Barber": 2, "Cosmetologist": 1 },
  "33545": { "Barber": 24, "Cosmetologist": 281 },
  "33527": { "Barber": 19, "Cosmetologist": 109 },
  "34655": { "Barber": 57, "Cosmetologist": 583 },
  "34488": { "Barber": 5, "Cosmetologist": 71 },
  "39525": { "Barber": 1 },
  "44313": { "Barber": 7, "Cosmetologist": 1 },
  "47960": { "Barber": 9, "Cosmetologist": 2 },
  "29662": { "Barber": 1, "Cosmetologist": 4 },
  "34240": { "Barber": 16, "Cosmetologist": 248 },
  "27699": { "Barber": 1, "Cosmetologist": 2 },
  "32433": { "Barber": 8, "Cosmetologist": 111 },
  "34472": { "Barber": 52, "Cosmetologist": 344 },
  "32221": { "Barber": 53, "Cosmetologist": 433 },
  "32359": { "Barber": 1, "Cosmetologist": 19 },
  "34233": { "Barber": 26, "Cosmetologist": 323 },
  "32940": { "Barber": 32, "Cosmetologist": 535 },
  "32726": { "Barber": 39, "Cosmetologist": 219 },
  "33967": { "Barber": 35, "Cosmetologist": 468 },
  "33948": { "Barber": 17, "Cosmetologist": 202 },
  "33763": { "Barber": 22, "Cosmetologist": 194 },
  "32407": { "Barber": 12, "Cosmetologist": 191 },
  "34238": { "Barber": 21, "Cosmetologist": 239 },
  "32681": { "Barber": 6, "Cosmetologist": 4 },
  "32102": { "Barber": 4, "Cosmetologist": 22 },
  "33544": { "Barber": 46, "Cosmetologist": 325 },
  "33714": { "Barber": 24, "Cosmetologist": 397 },
  "33172": { "Barber": 69, "Cosmetologist": 729 },
  "33410": { "Barber": 30, "Cosmetologist": 730 },
  "33952": { "Barber": 47, "Cosmetologist": 377 },
  "33534": { "Barber": 43, "Cosmetologist": 171 },
  "32908": { "Barber": 29, "Cosmetologist": 138 },
  "32328": { "Barber": 5, "Cosmetologist": 27 },
  "32821": { "Barber": 35, "Cosmetologist": 316 },
  "33875": { "Barber": 15, "Cosmetologist": 116 },
  "33955": { "Barber": 13, "Cosmetologist": 94 },
  "34432": { "Barber": 9, "Cosmetologist": 97 },
  "33245": { "Barber": 5, "Cosmetologist": 8 },
  "32807": { "Barber": 106, "Cosmetologist": 607 },
  "33904": { "Barber": 71, "Cosmetologist": 435 },
  "34236": { "Barber": 16, "Cosmetologist": 168 },
  "32245": { "Barber": 2, "Cosmetologist": 13 },
  "33759": { "Barber": 40, "Cosmetologist": 202 },
  "33018": { "Barber": 109, "Cosmetologist": 935 },
  "30215": { "Barber": 4, "Cosmetologist": 10 },
  "32351": { "Barber": 32, "Cosmetologist": 96 },
  "32346": { "Barber": 3, "Cosmetologist": 11 },
  "33928": { "Barber": 27, "Cosmetologist": 353 },
  "34116": { "Barber": 68, "Cosmetologist": 448 },
  "32004": { "Barber": 1, "Cosmetologist": 5 },
  "33912": { "Barber": 10, "Cosmetologist": 242 },
  "34223": { "Barber": 25, "Cosmetologist": 119 },
  "33331": { "Barber": 12, "Cosmetologist": 197 },
  "33541": { "Barber": 39, "Cosmetologist": 190 },
  "32811": { "Barber": 80, "Cosmetologist": 427 },
  "33682": { "Barber": 4, "Cosmetologist": 7 },
  "37934": { "Barber": 8, "Cosmetologist": 1 },
  "47001": { "Barber": 5 },
  "32063": { "Barber": 10, "Cosmetologist": 134 },
  "48183": { "Barber": 17, "Cosmetologist": 372 },
  "30312": { "Barber": 3, "Cosmetologist": 12 },
  "33830": { "Barber": 54, "Cosmetologist": 213 },
  "33070": { "Barber": 15, "Cosmetologist": 88 },
  "33901": { "Barber": 36, "Cosmetologist": 245 },
  "32127": { "Barber": 31, "Cosmetologist": 390 },
  "33023": { "Barber": 182, "Cosmetologist": 812 },
  "32011": { "Barber": 8, "Cosmetologist": 132 },
  "32223": { "Barber": 25, "Cosmetologist": 241 },
  "33947": { "Barber": 6, "Cosmetologist": 89 },
  "34602": { "Barber": 6, "Cosmetologist": 72 },
  "33462": { "Barber": 59, "Cosmetologist": 418 },
  "33008": { "Barber": 2, "Cosmetologist": 13 },
  "37865": { "Barber": 18, "Cosmetologist": 6 },
  "32326": { "Barber": 2, "Cosmetologist": 5 },
  "34480": { "Barber": 40, "Cosmetologist": 228 },
  "34112": { "Barber": 39, "Cosmetologist": 306 },
  "33486": { "Barber": 21, "Cosmetologist": 237 },
  "33981": { "Barber": 18, "Cosmetologist": 153 },
  "32798": { "Barber": 4, "Cosmetologist": 17 },
  "33936": { "Barber": 55, "Cosmetologist": 249 },
  "32234": { "Barber": 5, "Cosmetologist": 64 },
  "28748": { "Barber": 1, "Cosmetologist": 80 },
  "34232": { "Barber": 59, "Cosmetologist": 495 },
  "32609": { "Barber": 48, "Cosmetologist": 131 },
  "30518": { "Barber": 1, "Cosmetologist": 23 },
  "30513": { "Barber": 2, "Cosmetologist": 12 },
  "33567": { "Barber": 18, "Cosmetologist": 125 },
  "33322": { "Barber": 75, "Cosmetologist": 513 },
  "34668": { "Barber": 101, "Cosmetologist": 473 },
  "33898": { "Barber": 17, "Cosmetologist": 118 },
  "32805": { "Barber": 75, "Cosmetologist": 211 },
  "33966": { "Barber": 16, "Cosmetologist": 245 },
  "34990": { "Barber": 15, "Cosmetologist": 330 },
  "36421": { "Barber": 1, "Cosmetologist": 1 },
  "33411": { "Barber": 100, "Cosmetologist": 1066 },
  "33525": { "Barber": 33, "Cosmetologist": 161 },
  "32829": { "Barber": 69, "Cosmetologist": 311 },
  "13166": { "Barber": 1 },
  "33616": { "Barber": 21, "Cosmetologist": 181 },
  "32356": { "Barber": 1, "Cosmetologist": 1 },
  "33905": { "Barber": 37, "Cosmetologist": 394 },
  "12801": { "Barber": 1, "Cosmetologist": 1 },
  "32060": { "Barber": 10, "Cosmetologist": 157 },
  "34109": { "Barber": 25, "Cosmetologist": 338 },
  "33467": { "Barber": 67, "Cosmetologist": 929 },
  "33920": { "Barber": 7, "Cosmetologist": 91 },
  "32055": { "Barber": 17, "Cosmetologist": 140 },
  "33313": { "Barber": 150, "Cosmetologist": 547 },
  "33054": { "Barber": 56, "Cosmetologist": 270 },
  "33513": { "Barber": 19, "Cosmetologist": 98 },
  "33312": { "Barber": 118, "Cosmetologist": 632 },
  "32738": { "Barber": 87, "Cosmetologist": 481 },
  "32796": { "Barber": 16, "Cosmetologist": 205 },
  "34433": { "Barber": 13, "Cosmetologist": 53 },
  "34785": { "Barber": 9, "Cosmetologist": 163 },
  "32259": { "Barber": 32, "Cosmetologist": 566 },
  "33185": { "Barber": 50, "Cosmetologist": 421 },
  "33773": { "Barber": 23, "Cosmetologist": 287 },
  "15228": { "Barber": 1, "Cosmetologist": 1 },
  "32968": { "Barber": 15, "Cosmetologist": 207 },
  "78234": { "Barber": 1, "Cosmetologist": 6 },
  "28734": { "Barber": 1, "Cosmetologist": 212 },
  "32258": { "Barber": 40, "Cosmetologist": 495 },
  "48386": { "Barber": 10, "Cosmetologist": 151 },
  "34715": { "Barber": 20, "Cosmetologist": 220 },
  "33687": { "Barber": 9, "Cosmetologist": 23 },
  "36532": { "Barber": 1, "Cosmetologist": 27 },
  "32958": { "Barber": 43, "Cosmetologist": 324 },
  "33138": { "Barber": 44, "Cosmetologist": 283 },
  "33573": { "Barber": 27, "Cosmetologist": 206 },
  "15003": { "Barber": 7, "Cosmetologist": 4 },
  "33026": { "Barber": 43, "Cosmetologist": 390 },
  "33913": { "Barber": 14, "Cosmetologist": 285 },
  "32352": { "Barber": 14, "Cosmetologist": 23 },
  "32344": { "Barber": 15, "Cosmetologist": 65 },
  "34652": { "Barber": 63, "Cosmetologist": 334 },
  "34737": { "Barber": 6, "Cosmetologist": 37 },
  "32343": { "Barber": 15, "Cosmetologist": 37 },
  "32224": { "Barber": 28, "Cosmetologist": 400 },
  "34689": { "Barber": 30, "Cosmetologist": 381 },
  "40291": { "Barber": 1, "Cosmetologist": 12 },
  "30013": { "Barber": 2, "Cosmetologist": 10 },
  "34287": { "Barber": 45, "Cosmetologist": 265 },
  "34473": { "Barber": 86, "Cosmetologist": 199 },
  "32065": { "Barber": 52, "Cosmetologist": 389 },
  "32222": { "Barber": 33, "Cosmetologist": 215 },
  "34690": { "Barber": 23, "Cosmetologist": 183 },
  "33865": { "Barber": 2, "Cosmetologist": 9 },
  "34982": { "Barber": 58, "Cosmetologist": 280 },
  "33917": { "Barber": 28, "Cosmetologist": 247 },
  "33679": { "Barber": 3, "Cosmetologist": 7 },
  "33907": { "Barber": 43, "Cosmetologist": 346 },
  "33990": { "Barber": 69, "Cosmetologist": 558 },
  "32669": { "Barber": 19, "Cosmetologist": 147 },
  "12459": { "Barber": 1, "Cosmetologist": 1 },
  "32095": { "Barber": 19, "Cosmetologist": 164 },
  "32712": { "Barber": 85, "Cosmetologist": 541 },
  "34677": { "Barber": 26, "Cosmetologist": 240 },
  "33701": { "Barber": 14, "Cosmetologist": 172 },
  "34951": { "Barber": 19, "Cosmetologist": 184 },
  "34450": { "Barber": 10, "Cosmetologist": 93 },
  "29579": { "Barber": 1, "Cosmetologist": 42 },
  "33166": { "Barber": 58, "Cosmetologist": 362 },
  "33914": { "Barber": 62, "Cosmetologist": 629 },
  "33635": { "Barber": 37, "Cosmetologist": 273 },
  "32766": { "Barber": 9, "Cosmetologist": 115 },
  "33576": { "Barber": 3, "Cosmetologist": 51 },
  "32507": { "Barber": 50, "Cosmetologist": 242 },
  "32861": { "Barber": 5, "Cosmetologist": 10 },
  "33991": { "Barber": 54, "Cosmetologist": 429 },
  "33063": { "Barber": 128, "Cosmetologist": 985 },
  "32721": { "Barber": 3, "Cosmetologist": 5 },
  "34771": { "Barber": 46, "Cosmetologist": 324 },
  "33301": { "Barber": 35, "Cosmetologist": 221 },
  "33408": { "Barber": 14, "Cosmetologist": 265 },
  "85208": { "Barber": 12, "Cosmetologist": 4 },
  "33548": { "Barber": 7, "Cosmetologist": 68 },
  "34731": { "Barber": 13, "Cosmetologist": 165 },
  "30512": { "Barber": 1, "Cosmetologist": 22 },
  "34653": { "Barber": 48, "Cosmetologist": 459 },
  "29374": { "Barber": 1, "Cosmetologist": 1 },
  "33470": { "Barber": 24, "Cosmetologist": 475 },
  "33847": { "Barber": 1 },
  "33137": { "Barber": 49, "Cosmetologist": 323 },
  "33521": { "Barber": 6, "Cosmetologist": 10 },
  "32256": { "Barber": 47, "Cosmetologist": 633 },
  "33417": { "Barber": 56, "Cosmetologist": 403 },
  "33415": { "Barber": 140, "Cosmetologist": 737 },
  "80100": { "Barber": 1 },
  "33324": { "Barber": 46, "Cosmetologist": 623 },
  "32905": { "Barber": 65, "Cosmetologist": 205 },
  "34756": { "Barber": 7, "Cosmetologist": 33 },
  "32141": { "Barber": 14, "Cosmetologist": 183 },
  "33021": { "Barber": 98, "Cosmetologist": 607 },
  "45215": { "Barber": 3, "Cosmetologist": 1 },
  "27612": { "Barber": 2, "Cosmetologist": 182 },
  "33326": { "Barber": 26, "Cosmetologist": 454 },
  "33906": { "Barber": 3, "Cosmetologist": 10 },
  "70433": { "Barber": 2, "Cosmetologist": 1 },
  "33841": { "Barber": 8, "Cosmetologist": 53 },
  "32568": { "Barber": 2, "Cosmetologist": 13 },
  "76310": { "Barber": 6, "Cosmetologist": 246 },
  "33033": { "Barber": 105, "Cosmetologist": 796 },
  "33715": { "Barber": 3, "Cosmetologist": 49 },
  "33540": { "Barber": 32, "Cosmetologist": 74 },
  "33602": { "Barber": 26, "Cosmetologist": 167 },
  "32923": { "Barber": 5, "Cosmetologist": 8 },
  "32817": { "Barber": 77, "Cosmetologist": 591 },
  "32715": { "Barber": 1, "Cosmetologist": 4 },
  "33712": { "Barber": 71, "Cosmetologist": 266 },
  "32203": { "Barber": 10, "Cosmetologist": 20 },
  "33802": { "Barber": 6, "Cosmetologist": 10 },
  "33174": { "Barber": 79, "Cosmetologist": 529 },
  "33081": { "Barber": 2, "Cosmetologist": 6 },
  "30535": { "Barber": 1, "Cosmetologist": 3 },
  "34119": { "Barber": 19, "Cosmetologist": 450 },
  "33764": { "Barber": 24, "Cosmetologist": 308 },
  "32904": { "Barber": 25, "Cosmetologist": 454 },
  "51503": { "Barber": 6, "Cosmetologist": 114 },
  "37825": { "Barber": 4, "Cosmetologist": 6 },
  "30506": { "Barber": 2, "Cosmetologist": 21 },
  "33016": { "Barber": 131, "Cosmetologist": 784 },
  "78006": { "Barber": 13, "Cosmetologist": 265 },
  "63125": { "Barber": 10, "Cosmetologist": 275 },
  "78741": { "Barber": 29, "Cosmetologist": 254 },
  "32935": { "Barber": 69, "Cosmetologist": 547 },
  "30815": { "Barber": 2, "Cosmetologist": 17 },
  "32444": { "Barber": 16, "Cosmetologist": 267 },
  "19047": { "Barber": 5, "Cosmetologist": 16 },
  "32530": { "Barber": 3, "Cosmetologist": 1 },
  "34987": { "Barber": 15, "Cosmetologist": 238 },
  "33626": { "Barber": 25, "Cosmetologist": 377 },
  "34241": { "Barber": 10, "Cosmetologist": 164 },
  "32949": { "Barber": 4, "Cosmetologist": 24 },
  "32828": { "Barber": 112, "Cosmetologist": 842 },
  "34242": { "Barber": 5, "Cosmetologist": 47 },
  "34436": { "Barber": 10, "Cosmetologist": 59 },
  "18871": { "Barber": 1 },
  "33956": { "Barber": 1, "Cosmetologist": 25 },
  "32428": { "Barber": 16, "Cosmetologist": 111 },
  "34292": { "Barber": 7, "Cosmetologist": 133 },
  "34211": { "Barber": 20, "Cosmetologist": 235 },
  "32826": { "Barber": 34, "Cosmetologist": 250 },
  "34482": { "Barber": 30, "Cosmetologist": 133 },
  "33849": { "Barber": 5, "Cosmetologist": 10 },
  "34669": { "Barber": 21, "Cosmetologist": 132 },
  "36330": { "Barber": 2, "Cosmetologist": 15 },
  "37803": { "Barber": 16, "Cosmetologist": 7 },
  "32907": { "Barber": 78, "Cosmetologist": 454 },
  "33909": { "Barber": 66, "Cosmetologist": 555 },
  "46901": { "Barber": 22, "Cosmetologist": 3 },
  "33034": { "Barber": 31, "Cosmetologist": 171 },
  "32043": { "Barber": 16, "Cosmetologist": 266 },
  "31603": { "Barber": 1, "Cosmetologist": 1 },
  "17801": { "Barber": 4, "Cosmetologist": 19 },
  "34103": { "Barber": 19, "Cosmetologist": 197 },
  "33426": { "Barber": 50, "Cosmetologist": 386 },
  "47906": { "Barber": 6, "Cosmetologist": 5 },
  "33187": { "Barber": 36, "Cosmetologist": 243 },
  "34235": { "Barber": 17, "Cosmetologist": 134 },
  "33815": { "Barber": 36, "Cosmetologist": 70 },
  "33068": { "Barber": 143, "Cosmetologist": 745 },
  "32533": { "Barber": 44, "Cosmetologist": 291 },
  "33680": { "Barber": 6, "Cosmetologist": 17 },
  "32220": { "Barber": 7, "Cosmetologist": 119 },
  "45882": { "Barber": 1, "Cosmetologist": 1 },
  "33261": { "Barber": 2, "Cosmetologist": 12 },
  "33743": { "Barber": 2, "Cosmetologist": 5 },
  "33131": { "Barber": 31, "Cosmetologist": 184 },
  "32569": { "Barber": 20, "Cosmetologist": 159 },
  "33570": { "Barber": 73, "Cosmetologist": 340 },
  "39819": { "Barber": 4, "Cosmetologist": 23 },
  "33583": { "Barber": 4, "Cosmetologist": 12 },
  "34474": { "Barber": 47, "Cosmetologist": 295 },
  "33496": { "Barber": 19, "Cosmetologist": 251 },
  "32836": { "Barber": 15, "Cosmetologist": 280 },
  "47990": { "Barber": 1 },
  "32771": { "Barber": 77, "Cosmetologist": 612 },
  "47711": { "Barber": 14, "Cosmetologist": 2 },
  "33442": { "Barber": 42, "Cosmetologist": 518 },
  "34269": { "Barber": 6, "Cosmetologist": 36 },
  "37122": { "Barber": 56, "Cosmetologist": 15 },
  "33404": { "Barber": 59, "Cosmetologist": 350 },
  "32240": { "Barber": 3, "Cosmetologist": 1 },
  "33595": { "Barber": 1, "Cosmetologist": 9 },
  "06905": { "Barber": 1 },
  "34222": { "Barber": 10, "Cosmetologist": 172 },
  "33403": { "Barber": 47, "Cosmetologist": 235 },
  "34276": { "Barber": 1, "Cosmetologist": 7 },
  "34104": { "Barber": 33, "Cosmetologist": 362 },
  "02649": { "Barber": 1 },
  "33304": { "Barber": 44, "Cosmetologist": 305 },
  "33056": { "Barber": 57, "Cosmetologist": 334 },
  "33826": { "Barber": 3, "Cosmetologist": 7 },
  "32607": { "Barber": 32, "Cosmetologist": 287 },
  "32640": { "Barber": 10, "Cosmetologist": 76 },
  "32653": { "Barber": 7, "Cosmetologist": 112 },
  "33949": { "Barber": 2, "Cosmetologist": 8 },
  "33332": { "Barber": 6, "Cosmetologist": 91 },
  "32827": { "Barber": 12, "Cosmetologist": 179 },
  "34637": { "Barber": 7, "Cosmetologist": 71 },
  "33160": { "Barber": 79, "Cosmetologist": 809 },
  "33154": { "Barber": 22, "Cosmetologist": 144 },
  "34476": { "Barber": 35, "Cosmetologist": 295 },
  "32128": { "Barber": 13, "Cosmetologist": 311 },
  "33919": { "Barber": 27, "Cosmetologist": 395 },
  "46703": { "Barber": 7, "Cosmetologist": 5 },
  "92253": { "Barber": 1, "Cosmetologist": 4 },
  "34697": { "Barber": 1, "Cosmetologist": 5 },
  "37013": { "Barber": 104, "Cosmetologist": 20 },
  "34679": { "Barber": 4, "Cosmetologist": 4 },
  "41059": { "Barber": 1 },
  "33401": { "Barber": 44, "Cosmetologist": 333 },
  "34220": { "Barber": 2, "Cosmetologist": 5 },
  "34705": { "Barber": 4, "Cosmetologist": 14 },
  "30349": { "Barber": 6, "Cosmetologist": 29 },
  "23666": { "Barber": 1, "Cosmetologist": 13 },
  "33445": { "Barber": 43, "Cosmetologist": 360 },
  "33180": { "Barber": 48, "Cosmetologist": 479 },
  "32085": { "Barber": 1, "Cosmetologist": 6 },
  "32948": { "Barber": 24, "Cosmetologist": 35 },
  "32236": { "Barber": 4, "Cosmetologist": 13 },
  "32226": { "Barber": 20, "Cosmetologist": 215 },
  "32054": { "Barber": 8, "Cosmetologist": 87 },
  "33482": { "Barber": 2, "Cosmetologist": 7 },
  "33066": { "Barber": 17, "Cosmetologist": 236 },
  "32337": { "Barber": 1, "Cosmetologist": 1 },
  "33446": { "Barber": 10, "Cosmetologist": 276 },
  "34743": { "Barber": 120, "Cosmetologist": 470 },
  "94520": { "Barber": 1, "Cosmetologist": 2 },
  "32202": { "Barber": 17, "Cosmetologist": 41 },
  "68123": { "Barber": 1, "Cosmetologist": 12 },
  "32539": { "Barber": 23, "Cosmetologist": 254 },
  "32314": { "Barber": 3, "Cosmetologist": 12 },
  "33804": { "Barber": 9, "Cosmetologist": 17 },
  "32254": { "Barber": 30, "Cosmetologist": 104 },
  "31313": { "Barber": 5, "Cosmetologist": 19 },
  "74210": { "Barber": 1 },
  "33050": { "Barber": 15, "Cosmetologist": 114 },
  "34744": { "Barber": 177, "Cosmetologist": 853 },
  "32024": { "Barber": 13, "Cosmetologist": 211 },
  "33019": { "Barber": 10, "Cosmetologist": 141 },
  "33025": { "Barber": 128, "Cosmetologist": 1046 },
  "32459": { "Barber": 9, "Cosmetologist": 305 },
  "33973": { "Barber": 16, "Cosmetologist": 77 },
  "44139": { "Barber": 1, "Cosmetologist": 1 },
  "33182": { "Barber": 21, "Cosmetologist": 220 },
  "34478": { "Barber": 6, "Cosmetologist": 21 },
  "33463": { "Barber": 154, "Cosmetologist": 948 },
  "32094": { "Barber": 2, "Cosmetologist": 24 },
  "34446": { "Barber": 16, "Cosmetologist": 150 },
  "32803": { "Barber": 39, "Cosmetologist": 650 },
  "33972": { "Barber": 21, "Cosmetologist": 172 },
  "33601": { "Barber": 2, "Cosmetologist": 1 },
  "32736": { "Barber": 12, "Cosmetologist": 71 },
  "32563": { "Barber": 15, "Cosmetologist": 285 },
  "10010": { "Barber": 1, "Cosmetologist": 6 },
  "33430": { "Barber": 20, "Cosmetologist": 114 },
  "32562": { "Barber": 2, "Cosmetologist": 4 },
  "46514": { "Barber": 11, "Cosmetologist": 8 },
  "23464": { "Barber": 1, "Cosmetologist": 20 },
  "33161": { "Barber": 104, "Cosmetologist": 459 },
  "77449": { "Barber": 130, "Cosmetologist": 1379 },
  "34113": { "Barber": 21, "Cosmetologist": 234 },
  "33125": { "Barber": 109, "Cosmetologist": 648 },
  "30809": { "Barber": 2, "Cosmetologist": 20 },
  "32058": { "Barber": 4, "Cosmetologist": 27 },
  "45320": { "Barber": 1, "Cosmetologist": 2 },
  "25840": { "Barber": 1, "Cosmetologist": 1 },
  "33127": { "Barber": 61, "Cosmetologist": 237 },
  "89148": { "Barber": 3, "Cosmetologist": 13 },
  "33458": { "Barber": 29, "Cosmetologist": 710 },
  "33434": { "Barber": 19, "Cosmetologist": 244 },
  "48609": { "Barber": 4, "Cosmetologist": 111 },
  "32110": { "Barber": 12, "Cosmetologist": 89 },
  "25405": { "Barber": 1, "Cosmetologist": 1 },
  "33168": { "Barber": 60, "Cosmetologist": 227 },
  "32730": { "Barber": 9, "Cosmetologist": 78 },
  "33409": { "Barber": 87, "Cosmetologist": 452 },
  "33733": { "Barber": 3, "Cosmetologist": 8 },
  "34691": { "Barber": 42, "Cosmetologist": 260 },
  "22193": { "Barber": 1, "Cosmetologist": 8 },
  "32119": { "Barber": 46, "Cosmetologist": 280 },
  "33176": { "Barber": 43, "Cosmetologist": 517 },
  "60656": { "Barber": 1, "Cosmetologist": 1 },
  "34772": { "Barber": 95, "Cosmetologist": 420 },
  "34758": { "Barber": 101, "Cosmetologist": 390 },
  "33413": { "Barber": 25, "Cosmetologist": 262 },
  "33179": { "Barber": 63, "Cosmetologist": 594 },
  "33931": { "Barber": 6, "Cosmetologist": 52 },
  "32704": { "Barber": 4, "Cosmetologist": 9 },
  "33178": { "Barber": 55, "Cosmetologist": 757 },
  "31778": { "Barber": 2, "Cosmetologist": 3 },
  "32446": { "Barber": 10, "Cosmetologist": 108 },
  "20007": { "Barber": 1, "Cosmetologist": 1 },
  "34714": { "Barber": 42, "Cosmetologist": 270 },
  "34797": { "Barber": 1, "Cosmetologist": 11 },
  "34946": { "Barber": 7, "Cosmetologist": 43 },
  "32041": { "Barber": 1, "Cosmetologist": 7 },
  "33471": { "Barber": 8, "Cosmetologist": 34 },
  "33509": { "Barber": 8, "Cosmetologist": 23 },
  "32178": { "Barber": 3, "Cosmetologist": 4 },
  "33323": { "Barber": 25, "Cosmetologist": 274 },
  "33766": { "Barber": 1, "Cosmetologist": 8 },
  "33141": { "Barber": 61, "Cosmetologist": 509 },
  "33646": { "Barber": 2, "Cosmetologist": 11 },
  "34434": { "Barber": 8, "Cosmetologist": 80 },
  "34207": { "Barber": 50, "Cosmetologist": 310 },
  "34212": { "Barber": 18, "Cosmetologist": 282 },
  "32754": { "Barber": 3, "Cosmetologist": 87 },
  "34114": { "Barber": 17, "Cosmetologist": 190 },
  "33017": { "Barber": 4, "Cosmetologist": 8 },
  "32833": { "Barber": 9, "Cosmetologist": 88 },
  "40517": { "Barber": 1, "Cosmetologist": 2 },
  "32719": { "Barber": 1, "Cosmetologist": 3 },
  "34984": { "Barber": 45, "Cosmetologist": 306 },
  "37064": { "Barber": 25, "Cosmetologist": 13 },
  "32859": { "Barber": 1, "Cosmetologist": 9 },
  "33015": { "Barber": 110, "Cosmetologist": 1074 },
  "37303": { "Barber": 5, "Cosmetologist": 7 },
  "33196": { "Barber": 67, "Cosmetologist": 684 },
  "33537": { "Barber": 1, "Cosmetologist": 5 },
  "33010": { "Barber": 122, "Cosmetologist": 662 },
  "34746": { "Barber": 164, "Cosmetologist": 733 },
  "33265": { "Barber": 5, "Cosmetologist": 6 },
  "34950": { "Barber": 51, "Cosmetologist": 125 },
  "32577": { "Barber": 8, "Cosmetologist": 40 },
  "33441": { "Barber": 56, "Cosmetologist": 405 },
  "75071": { "Barber": 29, "Cosmetologist": 600 },
  "33405": { "Barber": 40, "Cosmetologist": 258 },
  "32621": { "Barber": 7, "Cosmetologist": 48 },
  "32591": { "Barber": 1, "Cosmetologist": 6 },
  "33953": { "Barber": 10, "Cosmetologist": 64 },
  "29936": { "Barber": 1, "Cosmetologist": 8 },
  "31052": { "Barber": 1, "Cosmetologist": 4 },
  "32835": { "Barber": 94, "Cosmetologist": 666 },
  "32091": { "Barber": 14, "Cosmetologist": 128 },
  "33400": { "Barber": 1 },
  "34142": { "Barber": 31, "Cosmetologist": 157 },
  "32824": { "Barber": 115, "Cosmetologist": 857 },
  "32768": { "Barber": 1, "Cosmetologist": 4 },
  "33073": { "Barber": 58, "Cosmetologist": 674 },
  "16148": { "Barber": 2, "Cosmetologist": 5 },
  "32801": { "Barber": 28, "Cosmetologist": 184 },
  "33327": { "Barber": 12, "Cosmetologist": 115 },
  "33140": { "Barber": 20, "Cosmetologist": 173 },
  "32113": { "Barber": 10, "Cosmetologist": 42 },
  "33460": { "Barber": 65, "Cosmetologist": 348 },
  "32617": { "Barber": 4, "Cosmetologist": 46 },
  "32132": { "Barber": 10, "Cosmetologist": 92 },
  "35079": { "Barber": 1 },
  "33147": { "Barber": 93, "Cosmetologist": 428 },
  "32353": { "Barber": 7, "Cosmetologist": 4 },
  "32044": { "Barber": 1, "Cosmetologist": 9 },
  "10012": { "Barber": 1, "Cosmetologist": 4 },
  "97459": { "Barber": 1 },
  "33194": { "Barber": 8, "Cosmetologist": 82 },
  "33032": { "Barber": 91, "Cosmetologist": 814 },
  "33045": { "Barber": 1, "Cosmetologist": 5 },
  "33421": { "Barber": 2, "Cosmetologist": 15 },
  "33035": { "Barber": 21, "Cosmetologist": 175 },
  "32837": { "Barber": 130, "Cosmetologist": 2467 },
  "32062": { "Barber": 1, "Cosmetologist": 27 },
  "33498": { "Barber": 15, "Cosmetologist": 194 },
  "33993": { "Barber": 60, "Cosmetologist": 499 },
  "33084": { "Barber": 2, "Cosmetologist": 13 },
  "32686": { "Barber": 10, "Cosmetologist": 20 },
  "33711": { "Barber": 38, "Cosmetologist": 217 },
  "33004": { "Barber": 33, "Cosmetologist": 231 },
  "33135": { "Barber": 91, "Cosmetologist": 526 },
  "33606": { "Barber": 20, "Cosmetologist": 154 },
  "34108": { "Barber": 14, "Cosmetologist": 183 },
  "32189": { "Barber": 7, "Cosmetologist": 27 },
  "32117": { "Barber": 52, "Cosmetologist": 343 },
  "46163": { "Barber": 5, "Cosmetologist": 1 },
  "47441": { "Barber": 7 },
  "33028": { "Barber": 22, "Cosmetologist": 348 },
  "33345": { "Barber": 2, "Cosmetologist": 12 },
  "33414": { "Barber": 48, "Cosmetologist": 777 },
  "32318": { "Barber": 1, "Cosmetologist": 6 },
  "32548": { "Barber": 30, "Cosmetologist": 284 },
  "33779": { "Barber": 2, "Cosmetologist": 10 },
  "33149": { "Barber": 8, "Cosmetologist": 62 },
  "34295": { "Barber": 3, "Cosmetologist": 5 },
  "28630": { "Barber": 6, "Cosmetologist": 110 },
  "33440": { "Barber": 35, "Cosmetologist": 171 },
  "77423": { "Barber": 13, "Cosmetologist": 132 },
  "32756": { "Barber": 2, "Cosmetologist": 6 },
  "34268": { "Barber": 5, "Cosmetologist": 3 },
  "32081": { "Barber": 9, "Cosmetologist": 172 },
  "32906": { "Barber": 3, "Cosmetologist": 3 },
  "33167": { "Barber": 35, "Cosmetologist": 145 },
  "34215": { "Barber": 2, "Cosmetologist": 7 },
  "34288": { "Barber": 15, "Cosmetologist": 150 },
  "32056": { "Barber": 1, "Cosmetologist": 18 },
  "32413": { "Barber": 7, "Cosmetologist": 159 },
  "12312": { "Barber": 1 },
  "32399": { "Barber": 2 },
  "22342": { "Barber": 1 },
  "33129": { "Barber": 12, "Cosmetologist": 108 },
  "33838": { "Barber": 16, "Cosmetologist": 54 },
  "33910": { "Barber": 2, "Cosmetologist": 3 },
  "34747": { "Barber": 32, "Cosmetologist": 246 },
  "33855": { "Barber": 1, "Cosmetologist": 2 },
  "34986": { "Barber": 41, "Cosmetologist": 298 },
  "33037": { "Barber": 17, "Cosmetologist": 148 },
  "33412": { "Barber": 6, "Cosmetologist": 210 },
  "32239": { "Barber": 5, "Cosmetologist": 4 },
  "31548": { "Barber": 4, "Cosmetologist": 68 },
  "34429": { "Barber": 26, "Cosmetologist": 113 },
  "34745": { "Barber": 3, "Cosmetologist": 13 },
  "34465": { "Barber": 14, "Cosmetologist": 142 },
  "30228": { "Barber": 9, "Cosmetologist": 17 },
  "33890": { "Barber": 5, "Cosmetologist": 26 },
  "32425": { "Barber": 11, "Cosmetologist": 89 },
  "33305": { "Barber": 27, "Cosmetologist": 176 },
  "32762": { "Barber": 2, "Cosmetologist": 9 },
  "32445": { "Barber": 5, "Cosmetologist": 7 },
  "33310": { "Barber": 4, "Cosmetologist": 21 },
  "32097": { "Barber": 20, "Cosmetologist": 229 },
  "34453": { "Barber": 12, "Cosmetologist": 105 },
  "32580": { "Barber": 5, "Cosmetologist": 37 },
  "33092": { "Barber": 2, "Cosmetologist": 4 },
  "33975": { "Barber": 1, "Cosmetologist": 18 },
  "34759": { "Barber": 96, "Cosmetologist": 358 },
  "33402": { "Barber": 3, "Cosmetologist": 10 },
  "32572": { "Barber": 1 },
  "33030": { "Barber": 59, "Cosmetologist": 291 },
  "19136": { "Barber": 1, "Cosmetologist": 10 },
  "32641": { "Barber": 34, "Cosmetologist": 90 },
  "33971": { "Barber": 51, "Cosmetologist": 380 },
  "31558": { "Barber": 12, "Cosmetologist": 49 },
  "32872": { "Barber": 4, "Cosmetologist": 11 },
  "34972": { "Barber": 29, "Cosmetologist": 96 },
  "11374": { "Barber": 5, "Cosmetologist": 12 },
  "33897": { "Barber": 62, "Cosmetologist": 305 },
  "32136": { "Barber": 8, "Cosmetologist": 131 },
  "33837": { "Barber": 84, "Cosmetologist": 426 },
  "33911": { "Barber": 1 },
  "35173": { "Barber": 1, "Cosmetologist": 4 },
  "33896": { "Barber": 26, "Cosmetologist": 199 },
  "32604": { "Barber": 2, "Cosmetologist": 1 },
  "33316": { "Barber": 18, "Cosmetologist": 168 },
  "92105": { "Barber": 1, "Cosmetologist": 2 },
  "33684": { "Barber": 6, "Cosmetologist": 10 },
  "34477": { "Barber": 6, "Cosmetologist": 15 },
  "33425": { "Barber": 4, "Cosmetologist": 8 },
  "34138": { "Barber": 2, "Cosmetologist": 1 },
  "32583": { "Barber": 16, "Cosmetologist": 180 },
  "39828": { "Barber": 5, "Cosmetologist": 19 },
  "32448": { "Barber": 2, "Cosmetologist": 49 },
  "32547": { "Barber": 45, "Cosmetologist": 415 },
  "30019": { "Barber": 3, "Cosmetologist": 19 },
  "33508": { "Barber": 4, "Cosmetologist": 8 },
  "34956": { "Barber": 6, "Cosmetologist": 43 },
  "30088": { "Barber": 3, "Cosmetologist": 13 },
  "32443": { "Barber": 1, "Cosmetologist": 18 },
  "32320": { "Barber": 1, "Cosmetologist": 21 },
  "33597": { "Barber": 8, "Cosmetologist": 56 },
  "32412": { "Barber": 1, "Cosmetologist": 2 },
  "33011": { "Barber": 1, "Cosmetologist": 6 },
  "34621": { "Barber": 1, "Cosmetologist": 1 },
  "36530": { "Barber": 1, "Cosmetologist": 10 },
  "32802": { "Barber": 2, "Cosmetologist": 4 },
  "33473": { "Barber": 4, "Cosmetologist": 82 },
  "32727": { "Barber": 2, "Cosmetologist": 10 },
  "12308": { "Barber": 2, "Cosmetologist": 2 },
  "32626": { "Barber": 6, "Cosmetologist": 75 },
  "33429": { "Barber": 1, "Cosmetologist": 6 },
  "32901": { "Barber": 42, "Cosmetologist": 296 },
  "34210": { "Barber": 27, "Cosmetologist": 154 },
  "32820": { "Barber": 21, "Cosmetologist": 120 },
  "33767": { "Barber": 3, "Cosmetologist": 54 },
  "32963": { "Barber": 3, "Cosmetologist": 73 },
  "32902": { "Barber": 3, "Cosmetologist": 11 },
  "16506": { "Barber": 3, "Cosmetologist": 5 },
  "29045": { "Barber": 1, "Cosmetologist": 10 },
  "32408": { "Barber": 24, "Cosmetologist": 171 },
  "33857": { "Barber": 2, "Cosmetologist": 12 },
  "20877": { "Barber": 24, "Cosmetologist": 4 },
  "33519": { "Barber": 1 },
  "85638": { "Barber": 1, "Cosmetologist": 1 },
  "78108": { "Barber": 35, "Cosmetologist": 382 },
  "33976": { "Barber": 32, "Cosmetologist": 219 },
  "21811": { "Barber": 1, "Cosmetologist": 1 },
  "34973": { "Barber": 1, "Cosmetologist": 11 },
  "33336": { "Barber": 1 },
  "28043": { "Barber": 3, "Cosmetologist": 129 },
  "75231": { "Barber": 28, "Cosmetologist": 192 },
  "32759": { "Barber": 1, "Cosmetologist": 28 },
  "33491": { "Barber": 1 },
  "34753": { "Barber": 15, "Cosmetologist": 59 },
  "34688": { "Barber": 1, "Cosmetologist": 77 },
  "34618": { "Barber": 1 },
  "60538": { "Barber": 1, "Cosmetologist": 3 },
  "33591": { "Barber": 1 },
  "32784": { "Barber": 7, "Cosmetologist": 77 },
  "11435": { "Barber": 1, "Cosmetologist": 2 },
  "33197": { "Barber": 1, "Cosmetologist": 11 },
  "32662": { "Barber": 1 },
  "32868": { "Barber": 3, "Cosmetologist": 16 },
  "32705": { "Barber": 1, "Cosmetologist": 5 },
  "33882": { "Barber": 3, "Cosmetologist": 3 },
  "34789": { "Barber": 1, "Cosmetologist": 3 },
  "21701": { "Barber": 29, "Cosmetologist": 2 },
  "29485": { "Barber": 1, "Cosmetologist": 27 },
  "19074": { "Barber": 2, "Cosmetologist": 1 },
  "33481": { "Barber": 1, "Cosmetologist": 8 },
  "32112": { "Barber": 5, "Cosmetologist": 44 },
  "34143": { "Barber": 7, "Cosmetologist": 12 },
  "33132": { "Barber": 27, "Cosmetologist": 272 },
  "33353": { "Barber": 1 },
  "32306": { "Barber": 3 },
  "32456": { "Barber": 4, "Cosmetologist": 65 },
  "33128": { "Barber": 11, "Cosmetologist": 64 },
  "32322": { "Barber": 3, "Cosmetologist": 17 },
  "33758": { "Barber": 4, "Cosmetologist": 13 },
  "36345": { "Barber": 1, "Cosmetologist": 1 },
  "32814": { "Barber": 13, "Cosmetologist": 75 },
  "36607": { "Barber": 1 },
  "32790": { "Barber": 1, "Cosmetologist": 14 },
  "59858": { "Barber": 1 },
  "34605": { "Barber": 1, "Cosmetologist": 3 },
  "55439": { "Barber": 2, "Cosmetologist": 1 },
  "34992": { "Barber": 2, "Cosmetologist": 5 },
  "34734": { "Barber": 3, "Cosmetologist": 68 },
  "33674": { "Barber": 3, "Cosmetologist": 8 },
  "32447": { "Barber": 3, "Cosmetologist": 3 },
  "32628": { "Barber": 3, "Cosmetologist": 34 },
  "78725": { "Barber": 11, "Cosmetologist": 75 },
  "33685": { "Barber": 7, "Cosmetologist": 6 },
  "28031": { "Barber": 2, "Cosmetologist": 245 },
  "78711": { "Barber": 1, "Cosmetologist": 6 },
  "34117": { "Barber": 19, "Cosmetologist": 249 },
  "32709": { "Barber": 2, "Cosmetologist": 23 },
  "32238": { "Barber": 5, "Cosmetologist": 5 },
  "34985": { "Barber": 3, "Cosmetologist": 10 },
  "32550": { "Barber": 3, "Cosmetologist": 117 },
  "35136": { "Barber": 1 },
  "32064": { "Barber": 9, "Cosmetologist": 80 },
  "75207": { "Barber": 3, "Cosmetologist": 44 },
  "30188": { "Barber": 1, "Cosmetologist": 31 },
  "32134": { "Barber": 5, "Cosmetologist": 36 },
  "33675": { "Barber": 1, "Cosmetologist": 6 },
  "33233": { "Barber": 2, "Cosmetologist": 7 },
  "76541": { "Barber": 31, "Cosmetologist": 97 },
  "33859": { "Barber": 10, "Cosmetologist": 83 },
  "54952": { "Barber": 1, "Cosmetologist": 3 },
  "20120": { "Barber": 1, "Cosmetologist": 7 },
  "10456": { "Barber": 1, "Cosmetologist": 2 },
  "33468": { "Barber": 3, "Cosmetologist": 11 },
  "32438": { "Barber": 1, "Cosmetologist": 15 },
  "31023": { "Barber": 1, "Cosmetologist": 3 },
  "32832": { "Barber": 39, "Cosmetologist": 350 },
  "33539": { "Barber": 3, "Cosmetologist": 4 },
  "32966": { "Barber": 19, "Cosmetologist": 245 },
  "23832": { "Barber": 1, "Cosmetologist": 8 },
  "78727": { "Barber": 16, "Cosmetologist": 203 },
  "32860": { "Barber": 2, "Cosmetologist": 12 },
  "32616": { "Barber": 5, "Cosmetologist": 16 },
  "33845": { "Barber": 3, "Cosmetologist": 12 },
  "30354": { "Barber": 2, "Cosmetologist": 4 },
  "32735": { "Barber": 8, "Cosmetologist": 45 },
  "32175": { "Barber": 1, "Cosmetologist": 4 },
  "30017": { "Barber": 1, "Cosmetologist": 4 },
  "32066": { "Barber": 3, "Cosmetologist": 30 },
  "37659": { "Barber": 14, "Cosmetologist": 7 },
  "33974": { "Barber": 26, "Cosmetologist": 171 },
  "33075": { "Barber": 2, "Cosmetologist": 9 },
  "33164": { "Barber": 2, "Cosmetologist": 5 },
  "34431": { "Barber": 5, "Cosmetologist": 78 },
  "28303": { "Barber": 11, "Cosmetologist": 297 },
  "23454": { "Barber": 2, "Cosmetologist": 10 },
  "76120": { "Barber": 19, "Cosmetologist": 183 },
  "32179": { "Barber": 1, "Cosmetologist": 56 },
  "33834": { "Barber": 7, "Cosmetologist": 40 },
  "32666": { "Barber": 10, "Cosmetologist": 49 },
  "32961": { "Barber": 3, "Cosmetologist": 8 },
  "33465": { "Barber": 1, "Cosmetologist": 3 },
  "30173": { "Barber": 1, "Cosmetologist": 1 },
  "32145": { "Barber": 8, "Cosmetologist": 41 },
  "39232": { "Barber": 1, "Cosmetologist": 4 },
  "33302": { "Barber": 1, "Cosmetologist": 6 },
  "33868": { "Barber": 10, "Cosmetologist": 77 },
  "34762": { "Barber": 1, "Cosmetologist": 9 },
  "34755": { "Barber": 1, "Cosmetologist": 13 },
  "33190": { "Barber": 25, "Cosmetologist": 191 },
  "34421": { "Barber": 3, "Cosmetologist": 13 },
  "32158": { "Barber": 1, "Cosmetologist": 11 },
  "32877": { "Barber": 3, "Cosmetologist": 20 },
  "32858": { "Barber": 3, "Cosmetologist": 7 },
  "33862": { "Barber": 3, "Cosmetologist": 3 },
  "11211": { "Barber": 2, "Cosmetologist": 6 },
  "32739": { "Barber": 1 },
  "34230": { "Barber": 2, "Cosmetologist": 13 },
  "04953": { "Barber": 1 },
  "32643": { "Barber": 22, "Cosmetologist": 119 },
  "34610": { "Barber": 14, "Cosmetologist": 141 },
  "49068": { "Barber": 13, "Cosmetologist": 101 },
  "15668": { "Barber": 1, "Cosmetologist": 6 },
  "60131": { "Barber": 1, "Cosmetologist": 1 },
  "32718": { "Barber": 7, "Cosmetologist": 7 },
  "33745": { "Barber": 1 },
  "33946": { "Barber": 2, "Cosmetologist": 12 },
  "33449": { "Barber": 5, "Cosmetologist": 112 },
  "32774": { "Barber": 1, "Cosmetologist": 6 },
  "17603": { "Barber": 29, "Cosmetologist": 12 },
  "35903": { "Barber": 1, "Cosmetologist": 1 },
  "94002": { "Barber": 1 },
  "32855": { "Barber": 3, "Cosmetologist": 9 },
  "11691": { "Barber": 1, "Cosmetologist": 4 },
  "33422": { "Barber": 3, "Cosmetologist": 16 },
  "33938": { "Barber": 2, "Cosmetologist": 10 },
  "32856": { "Barber": 2, "Cosmetologist": 6 },
  "45619": { "Barber": 1 },
  "32059": { "Barber": 6, "Cosmetologist": 22 },
  "32602": { "Barber": 3, "Cosmetologist": 1 },
  "33839": { "Barber": 9, "Cosmetologist": 44 },
  "32348": { "Barber": 8, "Cosmetologist": 64 },
  "23455": { "Barber": 2, "Cosmetologist": 13 },
  "32857": { "Barber": 1, "Cosmetologist": 4 },
  "33592": { "Barber": 12, "Cosmetologist": 90 },
  "32040": { "Barber": 8, "Cosmetologist": 61 },
  "33575": { "Barber": 1, "Cosmetologist": 4 },
  "34251": { "Barber": 1, "Cosmetologist": 73 },
  "33760": { "Barber": 22, "Cosmetologist": 179 },
  "32667": { "Barber": 4, "Cosmetologist": 18 },
  "34979": { "Barber": 7, "Cosmetologist": 7 },
  "33255": { "Barber": 1, "Cosmetologist": 5 },
  "33780": { "Barber": 2, "Cosmetologist": 17 },
  "34484": { "Barber": 5, "Cosmetologist": 157 },
  "19133": { "Barber": 1, "Cosmetologist": 1 },
  "30043": { "Barber": 5, "Cosmetologist": 43 },
  "33072": { "Barber": 1 },
  "32096": { "Barber": 2, "Cosmetologist": 17 },
  "77099": { "Barber": 33, "Cosmetologist": 649 },
  "33416": { "Barber": 3, "Cosmetologist": 16 },
  "39562": { "Barber": 1, "Cosmetologist": 1 },
  "30345": { "Barber": 1, "Cosmetologist": 7 },
  "33572": { "Barber": 32, "Cosmetologist": 260 },
  "32728": { "Barber": 4, "Cosmetologist": 5 },
  "32508": { "Barber": 1, "Cosmetologist": 2 },
  "32931": { "Barber": 10, "Cosmetologist": 131 },
  "32341": { "Barber": 1, "Cosmetologist": 3 },
  "32324": { "Barber": 5, "Cosmetologist": 24 },
  "32565": { "Barber": 4, "Cosmetologist": 46 },
  "31903": { "Barber": 3, "Cosmetologist": 4 },
  "33239": { "Barber": 1, "Cosmetologist": 1 },
  "32893": { "Barber": 1 },
  "30032": { "Barber": 3, "Cosmetologist": 11 },
  "33257": { "Barber": 1, "Cosmetologist": 3 },
  "40509": { "Barber": 1, "Cosmetologist": 10 },
  "36207": { "Barber": 1, "Cosmetologist": 5 },
  "32619": { "Barber": 2, "Cosmetologist": 50 },
  "33300": { "Barber": 1, "Cosmetologist": 2 },
  "31792": { "Barber": 3, "Cosmetologist": 25 },
  "80443": { "Barber": 6, "Cosmetologist": 22 },
  "31040": { "Barber": 1 },
  "32614": { "Barber": 2, "Cosmetologist": 6 },
  "23505": { "Barber": 1, "Cosmetologist": 5 },
  "31015": { "Barber": 1, "Cosmetologist": 2 },
  "33902": { "Barber": 6, "Cosmetologist": 13 },
  "32449": { "Barber": 1, "Cosmetologist": 2 },
  "33082": { "Barber": 3, "Cosmetologist": 8 },
  "80751": { "Barber": 5, "Cosmetologist": 110 },
  "33951": { "Barber": 1, "Cosmetologist": 9 },
  "80908": { "Barber": 10, "Cosmetologist": 110 },
  "32357": { "Barber": 1, "Cosmetologist": 1 },
  "30052": { "Barber": 3, "Cosmetologist": 29 },
  "34712": { "Barber": 1, "Cosmetologist": 4 },
  "34272": { "Barber": 1, "Cosmetologist": 1 },
  "33762": { "Barber": 3, "Cosmetologist": 67 },
  "83014": { "Barber": 4 },
  "20910": { "Barber": 17, "Cosmetologist": 3 },
  "32465": { "Barber": 3, "Cosmetologist": 54 },
  "33478": { "Barber": 2, "Cosmetologist": 125 },
  "48360": { "Barber": 4, "Cosmetologist": 89 },
  "21901": { "Barber": 1, "Cosmetologist": 5 },
  "34742": { "Barber": 3, "Cosmetologist": 16 },
  "29229": { "Barber": 2, "Cosmetologist": 28 },
  "26003": { "Barber": 1, "Cosmetologist": 3 },
  "36567": { "Barber": 1, "Cosmetologist": 15 },
  "34996": { "Barber": 8, "Cosmetologist": 114 },
  "33083": { "Barber": 1, "Cosmetologist": 4 },
  "98012": { "Barber": 1, "Cosmetologist": 4 },
  "32772": { "Barber": 2, "Cosmetologist": 10 },
  "33042": { "Barber": 5, "Cosmetologist": 64 },
  "30294": { "Barber": 3, "Cosmetologist": 15 },
  "33238": { "Barber": 2, "Cosmetologist": 2 },
  "13204": { "Barber": 1 },
  "32878": { "Barber": 2, "Cosmetologist": 9 },
  "46563": { "Barber": 8, "Cosmetologist": 6 },
  "78154": { "Barber": 33, "Cosmetologist": 369 },
  "33122": { "Barber": 4, "Cosmetologist": 42 },
  "31419": { "Barber": 3, "Cosmetologist": 19 },
  "33419": { "Barber": 1, "Cosmetologist": 5 },
  "32606": { "Barber": 17, "Cosmetologist": 243 },
  "77070": { "Barber": 36, "Cosmetologist": 634 },
  "34289": { "Barber": 2, "Cosmetologist": 32 },
  "80111": { "Barber": 12, "Cosmetologist": 115 },
  "38861": { "Barber": 1 },
  "33218": { "Barber": 1, "Cosmetologist": 1 },
  "33488": { "Barber": 1, "Cosmetologist": 6 },
  "33247": { "Barber": 1, "Cosmetologist": 2 },
  "30252": { "Barber": 1, "Cosmetologist": 19 },
  "32869": { "Barber": 1, "Cosmetologist": 15 },
  "34206": { "Barber": 1, "Cosmetologist": 3 },
  "32016": { "Barber": 1 },
  "32071": { "Barber": 3, "Cosmetologist": 22 },
  "30120": { "Barber": 3, "Cosmetologist": 15 },
  "32440": { "Barber": 3, "Cosmetologist": 34 },
  "34136": { "Barber": 1, "Cosmetologist": 12 },
  "78642": { "Barber": 7, "Cosmetologist": 233 },
  "64466": { "Barber": 1, "Cosmetologist": 5 },
  "44212": { "Barber": 1, "Cosmetologist": 3 },
  "31601": { "Barber": 3, "Cosmetologist": 13 },
  "65548": { "Barber": 6, "Cosmetologist": 29 },
  "33306": { "Barber": 13, "Cosmetologist": 102 },
  "79605": { "Barber": 29, "Cosmetologist": 205 },
  "96819": { "Barber": 1, "Cosmetologist": 4 },
  "31047": { "Barber": 1, "Cosmetologist": 6 },
  "32099": { "Barber": 4, "Cosmetologist": 3 },
  "37172": { "Barber": 18, "Cosmetologist": 4 },
  "78109": { "Barber": 59, "Cosmetologist": 499 },
  "32332": { "Barber": 5, "Cosmetologist": 6 },
  "34945": { "Barber": 7, "Cosmetologist": 51 },
  "34778": { "Barber": 1, "Cosmetologist": 3 },
  "74834": { "Barber": 1 },
  "44145": { "Barber": 1, "Cosmetologist": 3 },
  "48603": { "Barber": 16, "Cosmetologist": 223 },
  "31525": { "Barber": 3, "Cosmetologist": 28 },
  "31312": { "Barber": 2, "Cosmetologist": 9 },
  "33585": { "Barber": 4, "Cosmetologist": 14 },
  "77566": { "Barber": 16, "Cosmetologist": 263 },
  "32053": { "Barber": 3, "Cosmetologist": 10 },
  "55906": { "Barber": 1 },
  "11772": { "Barber": 1, "Cosmetologist": 3 },
  "35953": { "Barber": 1 },
  "10022": { "Barber": 2, "Cosmetologist": 1 },
  "63144": { "Barber": 3, "Cosmetologist": 46 },
  "66223": { "Barber": 1, "Cosmetologist": 156 },
  "80108": { "Barber": 10, "Cosmetologist": 158 },
  "32781": { "Barber": 4, "Cosmetologist": 8 },
  "33840": { "Barber": 2, "Cosmetologist": 3 },
  "33283": { "Barber": 1, "Cosmetologist": 5 },
  "95448": { "Barber": 1 },
  "34773": { "Barber": 9, "Cosmetologist": 55 },
  "34578": { "Barber": 2 },
  "32417": { "Barber": 2, "Cosmetologist": 10 },
  "32052": { "Barber": 2, "Cosmetologist": 33 },
  "30044": { "Barber": 2, "Cosmetologist": 42 },
  "60464": { "Barber": 1, "Cosmetologist": 1 },
  "10458": { "Barber": 1, "Cosmetologist": 5 },
  "34451": { "Barber": 1, "Cosmetologist": 6 },
  "23236": { "Barber": 1, "Cosmetologist": 1 },
  "28216": { "Barber": 7, "Cosmetologist": 414 },
  "32195": { "Barber": 2, "Cosmetologist": 36 },
  "34229": { "Barber": 2, "Cosmetologist": 68 },
  "33742": { "Barber": 1, "Cosmetologist": 1 },
  "32831": { "Barber": 1, "Cosmetologist": 3 },
  "33476": { "Barber": 10, "Cosmetologist": 36 },
  "70124": { "Barber": 1 },
  "33922": { "Barber": 1, "Cosmetologist": 27 },
  "80906": { "Barber": 23, "Cosmetologist": 151 },
  "43512": { "Barber": 1, "Cosmetologist": 5 },
  "23060": { "Barber": 1, "Cosmetologist": 3 },
  "32331": { "Barber": 2, "Cosmetologist": 26 },
  "46239": { "Barber": 26, "Cosmetologist": 2 },
  "60423": { "Barber": 1, "Cosmetologist": 5 },
  "29406": { "Barber": 1, "Cosmetologist": 9 },
  "33854": { "Barber": 1, "Cosmetologist": 1 },
  "29461": { "Barber": 1, "Cosmetologist": 14 },
  "33243": { "Barber": 2, "Cosmetologist": 5 },
  "32777": { "Barber": 1, "Cosmetologist": 3 },
  "33090": { "Barber": 1, "Cosmetologist": 7 },
  "75217": { "Barber": 106, "Cosmetologist": 417 },
  "70506": { "Barber": 1, "Cosmetologist": 1 },
  "33775": { "Barber": 1, "Cosmetologist": 8 },
  "33785": { "Barber": 4, "Cosmetologist": 49 },
  "45140": { "Barber": 2, "Cosmetologist": 9 },
  "46545": { "Barber": 6, "Cosmetologist": 9 },
  "34290": { "Barber": 1, "Cosmetologist": 9 },
  "10308": { "Barber": 1, "Cosmetologist": 1 },
  "20603": { "Barber": 1, "Cosmetologist": 9 },
  "33848": { "Barber": 2, "Cosmetologist": 12 },
  "33564": { "Barber": 1, "Cosmetologist": 1 },
  "99504": { "Barber": 1, "Cosmetologist": 4 },
  "79765": { "Barber": 16, "Cosmetologist": 215 },
  "10466": { "Barber": 1, "Cosmetologist": 4 },
  "33885": { "Barber": 4, "Cosmetologist": 8 },
  "36608": { "Barber": 3, "Cosmetologist": 7 },
  "32950": { "Barber": 6, "Cosmetologist": 38 },
  "89014": { "Barber": 1, "Cosmetologist": 3 },
  "11738": { "Barber": 1, "Cosmetologist": 2 },
  "29906": { "Barber": 1, "Cosmetologist": 10 },
  "33269": { "Barber": 2, "Cosmetologist": 4 },
  "62234": { "Barber": 1, "Cosmetologist": 33 },
  "37040": { "Barber": 57, "Cosmetologist": 11 },
  "32157": { "Barber": 1, "Cosmetologist": 4 },
  "36026": { "Barber": 1 },
  "39401": { "Barber": 1 },
  "33077": { "Barber": 1, "Cosmetologist": 13 },
  "17252": { "Barber": 2, "Cosmetologist": 3 },
  "34611": { "Barber": 1, "Cosmetologist": 8 },
  "32976": { "Barber": 7, "Cosmetologist": 85 },
  "34729": { "Barber": 1 },
  "33843": { "Barber": 9, "Cosmetologist": 53 },
  "30214": { "Barber": 3, "Cosmetologist": 8 },
  "35023": { "Barber": 2 },
  "33937": { "Barber": 1 },
  "44124": { "Barber": 2, "Cosmetologist": 3 },
  "32130": { "Barber": 8, "Cosmetologist": 42 },
  "24683": { "Barber": 1 },
  "79703": { "Barber": 10, "Cosmetologist": 155 },
  "53221": { "Barber": 2, "Cosmetologist": 6 },
  "32655": { "Barber": 1, "Cosmetologist": 10 },
  "30680": { "Barber": 2, "Cosmetologist": 14 },
  "28306": { "Barber": 4, "Cosmetologist": 310 },
  "76548": { "Barber": 51, "Cosmetologist": 260 },
  "48917": { "Barber": 24, "Cosmetologist": 222 },
  "27804": { "Barber": 2, "Cosmetologist": 184 },
  "14445": { "Barber": 1, "Cosmetologist": 1 },
  "32255": { "Barber": 1, "Cosmetologist": 3 },
  "33717": { "Barber": 1, "Cosmetologist": 2 },
  "32035": { "Barber": 1, "Cosmetologist": 6 },
  "32247": { "Barber": 1, "Cosmetologist": 3 },
  "90036": { "Barber": 3, "Cosmetologist": 6 },
  "44502": { "Barber": 4, "Cosmetologist": 1 },
  "33427": { "Barber": 1, "Cosmetologist": 3 },
  "62450": { "Barber": 1 },
  "32135": { "Barber": 2, "Cosmetologist": 18 },
  "56093": { "Barber": 1 },
  "54016": { "Barber": 1, "Cosmetologist": 4 },
  "79764": { "Barber": 12, "Cosmetologist": 137 },
  "14216": { "Barber": 2 },
  "11375": { "Barber": 2, "Cosmetologist": 21 },
  "23120": { "Barber": 1, "Cosmetologist": 3 },
  "34228": { "Barber": 4, "Cosmetologist": 28 },
  "10018": { "Barber": 1, "Cosmetologist": 1 },
  "33295": { "Barber": 1 },
  "44256": { "Barber": 1, "Cosmetologist": 11 },
  "48038": { "Barber": 17, "Cosmetologist": 411 },
  "32402": { "Barber": 1, "Cosmetologist": 3 },
  "23462": { "Barber": 2, "Cosmetologist": 11 },
  "32190": { "Barber": 1, "Cosmetologist": 13 },
  "33114": { "Barber": 1, "Cosmetologist": 8 },
  "45601": { "Barber": 1, "Cosmetologist": 9 },
  "29909": { "Barber": 1, "Cosmetologist": 4 },
  "46342": { "Barber": 22, "Cosmetologist": 4 },
  "34603": { "Barber": 1, "Cosmetologist": 7 },
  "38707": { "Barber": 1 },
  "33457": { "Barber": 1 },
  "44833": { "Barber": 2 },
  "32193": { "Barber": 1, "Cosmetologist": 6 },
  "35007": { "Barber": 1 },
  "33593": { "Barber": 1, "Cosmetologist": 3 },
  "85251": { "Barber": 21, "Cosmetologist": 8 },
  "33846": { "Barber": 1, "Cosmetologist": 12 },
  "32120": { "Barber": 1, "Cosmetologist": 8 },
  "38654": { "Barber": 1, "Cosmetologist": 6 },
  "27455": { "Barber": 2, "Cosmetologist": 147 },
  "32702": { "Barber": 2, "Cosmetologist": 22 },
  "90026": { "Barber": 2, "Cosmetologist": 4 },
  "12564": { "Barber": 1, "Cosmetologist": 5 },
  "19473": { "Barber": 4, "Cosmetologist": 4 },
  "34777": { "Barber": 1, "Cosmetologist": 3 },
  "23237": { "Barber": 1, "Cosmetologist": 2 },
  "34958": { "Barber": 1, "Cosmetologist": 6 },
  "10306": { "Barber": 5, "Cosmetologist": 3 },
  "60053": { "Barber": 1, "Cosmetologist": 2 },
  "32764": { "Barber": 1, "Cosmetologist": 21 },
  "43227": { "Barber": 2, "Cosmetologist": 1 },
  "33454": { "Barber": 2, "Cosmetologist": 5 },
  "33043": { "Barber": 3, "Cosmetologist": 35 },
  "23860": { "Barber": 1, "Cosmetologist": 2 },
  "61032": { "Barber": 1, "Cosmetologist": 2 },
  "43207": { "Barber": 6, "Cosmetologist": 4 },
  "32867": { "Barber": 1, "Cosmetologist": 11 },
  "31721": { "Barber": 1, "Cosmetologist": 7 },
  "10583": { "Barber": 1, "Cosmetologist": 3 },
  "17404": { "Barber": 16, "Cosmetologist": 12 },
  "11106": { "Barber": 2, "Cosmetologist": 10 },
  "30677": { "Barber": 1, "Cosmetologist": 6 },
  "60106": { "Barber": 1 },
  "10163": { "Barber": 1 },
  "37915": { "Barber": 8, "Cosmetologist": 2 },
  "92614": { "Barber": 1, "Cosmetologist": 2 },
  "90292": { "Barber": 1, "Cosmetologist": 1 },
  "32330": { "Barber": 1, "Cosmetologist": 4 },
  "30907": { "Barber": 3, "Cosmetologist": 25 },
  "32941": { "Barber": 1, "Cosmetologist": 4 },
  "13206": { "Barber": 3, "Cosmetologist": 4 },
  "38555": { "Barber": 41, "Cosmetologist": 5 },
  "77373": { "Barber": 81, "Cosmetologist": 552 },
  "45240": { "Barber": 7 },
  "30528": { "Barber": 1, "Cosmetologist": 13 },
  "32733": { "Barber": 1, "Cosmetologist": 4 },
  "75409": { "Barber": 24, "Cosmetologist": 186 },
  "43612": { "Barber": 8, "Cosmetologist": 21 },
  "57401": { "Barber": 1, "Cosmetologist": 3 },
  "77044": { "Barber": 57, "Cosmetologist": 361 },
  "50588": { "Barber": 2, "Cosmetologist": 45 },
  "45211": { "Barber": 4, "Cosmetologist": 1 },
  "30045": { "Barber": 5, "Cosmetologist": 20 },
  "33994": { "Barber": 2, "Cosmetologist": 2 },
  "33587": { "Barber": 2, "Cosmetologist": 3 },
  "58104": { "Barber": 1, "Cosmetologist": 2 },
  "36303": { "Barber": 2, "Cosmetologist": 18 },
  "43623": { "Barber": 8, "Cosmetologist": 11 },
  "10033": { "Barber": 1, "Cosmetologist": 5 },
  "22134": { "Barber": 1, "Cosmetologist": 4 },
  "30605": { "Barber": 1, "Cosmetologist": 6 },
  "66208": { "Barber": 4, "Cosmetologist": 66 },
  "94086": { "Barber": 1 },
  "32316": { "Barber": 1, "Cosmetologist": 4 },
  "28655": { "Barber": 5, "Cosmetologist": 264 },
  "94611": { "Barber": 2, "Cosmetologist": 1 },
  "28115": { "Barber": 2, "Cosmetologist": 336 },
  "32535": { "Barber": 1, "Cosmetologist": 16 },
  "91356": { "Barber": 1, "Cosmetologist": 2 },
  "33420": { "Barber": 1, "Cosmetologist": 12 },
  "55130": { "Barber": 1, "Cosmetologist": 1 },
  "53128": { "Barber": 1 },
  "10468": { "Barber": 1, "Cosmetologist": 4 },
  "34201": { "Barber": 4, "Cosmetologist": 52 },
  "91202": { "Barber": 1, "Cosmetologist": 3 },
  "19462": { "Barber": 3, "Cosmetologist": 3 },
  "78227": { "Barber": 25, "Cosmetologist": 237 },
  "34382": { "Barber": 1 },
  "20722": { "Barber": 1, "Cosmetologist": 2 },
  "47905": { "Barber": 17, "Cosmetologist": 4 },
  "10075": { "Barber": 1, "Cosmetologist": 3 },
  "29410": { "Barber": 1, "Cosmetologist": 10 },
  "33681": { "Barber": 1, "Cosmetologist": 6 },
  "33242": { "Barber": 1, "Cosmetologist": 8 },
  "32523": { "Barber": 1, "Cosmetologist": 1 },
  "60647": { "Barber": 1, "Cosmetologist": 8 },
  "33296": { "Barber": 1, "Cosmetologist": 9 },
  "42025": { "Barber": 1, "Cosmetologist": 3 },
  "33757": { "Barber": 2, "Cosmetologist": 5 },
  "95348": { "Barber": 1, "Cosmetologist": 1 },
  "78750": { "Barber": 9, "Cosmetologist": 156 },
  "77354": { "Barber": 25, "Cosmetologist": 386 },
  "30094": { "Barber": 1, "Cosmetologist": 18 },
  "22664": { "Barber": 1, "Cosmetologist": 1 },
  "65109": { "Barber": 12, "Cosmetologist": 228 },
  "12586": { "Barber": 1, "Cosmetologist": 1 },
  "35757": { "Barber": 1, "Cosmetologist": 3 },
  "32124": { "Barber": 6, "Cosmetologist": 88 },
  "92028": { "Barber": 1, "Cosmetologist": 3 },
  "32409": { "Barber": 3, "Cosmetologist": 86 },
  "91740": { "Barber": 1, "Cosmetologist": 2 },
  "37801": { "Barber": 15, "Cosmetologist": 6 },
  "87505": { "Barber": 1, "Cosmetologist": 4 },
  "10304": { "Barber": 1, "Cosmetologist": 4 },
  "76021": { "Barber": 13, "Cosmetologist": 247 },
  "30277": { "Barber": 4, "Cosmetologist": 7 },
  "43724": { "Barber": 1 },
  "76039": { "Barber": 37, "Cosmetologist": 359 },
  "30813": { "Barber": 1, "Cosmetologist": 23 },
  "37042": { "Barber": 108, "Cosmetologist": 27 },
  "32126": { "Barber": 1, "Cosmetologist": 2 },
  "98108": { "Barber": 2 },
  "34965": { "Barber": 1 },
  "16901": { "Barber": 3, "Cosmetologist": 27 },
  "44452": { "Barber": 3, "Cosmetologist": 1 },
  "33388": { "Barber": 4, "Cosmetologist": 1 },
  "30126": { "Barber": 2, "Cosmetologist": 22 },
  "31636": { "Barber": 1, "Cosmetologist": 15 },
  "65584": { "Barber": 14, "Cosmetologist": 65 },
  "70714": { "Barber": 1 },
  "49546": { "Barber": 10, "Cosmetologist": 159 },
  "48322": { "Barber": 19, "Cosmetologist": 257 },
  "14225": { "Barber": 1, "Cosmetologist": 4 },
  "77019": { "Barber": 10, "Cosmetologist": 125 },
  "91403": { "Barber": 1 },
  "37130": { "Barber": 97, "Cosmetologist": 15 },
  "60628": { "Barber": 2, "Cosmetologist": 1 },
  "52208": { "Barber": 2, "Cosmetologist": 15 },
  "76107": { "Barber": 27, "Cosmetologist": 235 },
  "62629": { "Barber": 1 },
  "29615": { "Barber": 1, "Cosmetologist": 18 },
  "60085": { "Barber": 1, "Cosmetologist": 3 },
  "32201": { "Barber": 1, "Cosmetologist": 2 },
  "78209": { "Barber": 14, "Cosmetologist": 176 },
  "38023": { "Barber": 6, "Cosmetologist": 1 },
  "00902": { "Barber": 1 },
  "31406": { "Barber": 2, "Cosmetologist": 27 },
  "95834": { "Barber": 1, "Cosmetologist": 5 },
  "40219": { "Barber": 1, "Cosmetologist": 5 },
  "32524": { "Barber": 1, "Cosmetologist": 15 },
  "34264": { "Barber": 2, "Cosmetologist": 2 },
  "33808": { "Barber": 1 },
  "30301": { "Barber": 1 },
  "32336": { "Barber": 2, "Cosmetologist": 7 },
  "89118": { "Barber": 1, "Cosmetologist": 4 },
  "92823": { "Barber": 1 },
  "80014": { "Barber": 26, "Cosmetologist": 258 },
  "10001": { "Barber": 2, "Cosmetologist": 8 },
  "94066": { "Barber": 1 },
  "76712": { "Barber": 18, "Cosmetologist": 237 },
  "24018": { "Barber": 4, "Cosmetologist": 13 },
  "42503": { "Barber": 1, "Cosmetologist": 3 },
  "89511": { "Barber": 1, "Cosmetologist": 1 },
  "33883": { "Barber": 1, "Cosmetologist": 9 },
  "38545": { "Barber": 5, "Cosmetologist": 2 },
  "48755": { "Barber": 6, "Cosmetologist": 32 },
  "12603": { "Barber": 3, "Cosmetologist": 5 },
  "31763": { "Barber": 1, "Cosmetologist": 4 },
  "99835": { "Barber": 1, "Cosmetologist": 2 },
  "37421": { "Barber": 21, "Cosmetologist": 7 },
  "32527": { "Barber": 1 },
  "91307": { "Barber": 1 },
  "97236": { "Barber": 2, "Cosmetologist": 3 },
  "21015": { "Barber": 1, "Cosmetologist": 3 },
  "39845": { "Barber": 1, "Cosmetologist": 15 },
  "33022": { "Barber": 3, "Cosmetologist": 11 },
  "64116": { "Barber": 12, "Cosmetologist": 88 },
  "32435": { "Barber": 4, "Cosmetologist": 63 },
  "90017": { "Barber": 1 },
  "14424": { "Barber": 1, "Cosmetologist": 1 },
  "32469": { "Barber": 1 },
  "22454": { "Barber": 1 },
  "12203": { "Barber": 1, "Cosmetologist": 4 },
  "33915": { "Barber": 1, "Cosmetologist": 19 },
  "89183": { "Barber": 2, "Cosmetologist": 8 },
  "40065": { "Barber": 1, "Cosmetologist": 2 },
  "32241": { "Barber": 2, "Cosmetologist": 8 },
  "30028": { "Barber": 2, "Cosmetologist": 10 },
  "12547": { "Barber": 1 },
  "34441": { "Barber": 1, "Cosmetologist": 2 },
  "49855": { "Barber": 6, "Cosmetologist": 195 },
  "32710": { "Barber": 1, "Cosmetologist": 3 },
  "72834": { "Barber": 1, "Cosmetologist": 4 },
  "60633": { "Barber": 1, "Cosmetologist": 1 },
  "70364": { "Barber": 1, "Cosmetologist": 2 },
  "30058": { "Barber": 4, "Cosmetologist": 24 },
  "10009": { "Barber": 1, "Cosmetologist": 3 },
  "46236": { "Barber": 22, "Cosmetologist": 4 },
  "78043": { "Barber": 19, "Cosmetologist": 229 },
  "31537": { "Barber": 3, "Cosmetologist": 17 },
  "34892": { "Barber": 1 },
  "30549": { "Barber": 1, "Cosmetologist": 3 },
  "33795": { "Barber": 1 },
  "32050": { "Barber": 1, "Cosmetologist": 4 },
  "76012": { "Barber": 24, "Cosmetologist": 192 },
  "32187": { "Barber": 1, "Cosmetologist": 10 },
  "37812": { "Barber": 1 },
  "32410": { "Barber": 2, "Cosmetologist": 2 },
  "30256": { "Barber": 1 },
  "12538": { "Barber": 1, "Cosmetologist": 3 },
  "91335": { "Barber": 1, "Cosmetologist": 4 },
  "38108": { "Barber": 10 },
  "76013": { "Barber": 24, "Cosmetologist": 336 },
  "30577": { "Barber": 1, "Cosmetologist": 15 },
  "58801": { "Barber": 1, "Cosmetologist": 7 },
  "27519": { "Barber": 2, "Cosmetologist": 214 },
  "55303": { "Barber": 1, "Cosmetologist": 3 },
  "30739": { "Barber": 1, "Cosmetologist": 3 },
  "30830": { "Barber": 1, "Cosmetologist": 2 },
  "36455": { "Barber": 1 },
  "10467": { "Barber": 1, "Cosmetologist": 6 },
  "33827": { "Barber": 3, "Cosmetologist": 25 },
  "49684": { "Barber": 9, "Cosmetologist": 174 },
  "56721": { "Barber": 1 },
  "85014": { "Barber": 1, "Cosmetologist": 6 },
  "32167": { "Barber": 1, "Cosmetologist": 1 },
  "59901": { "Barber": 2, "Cosmetologist": 7 },
  "32694": { "Barber": 1, "Cosmetologist": 13 },
  "48371": { "Barber": 9, "Cosmetologist": 231 },
  "78251": { "Barber": 53, "Cosmetologist": 455 },
  "79602": { "Barber": 23, "Cosmetologist": 154 },
  "46953": { "Barber": 11 },
  "92123": { "Barber": 1, "Cosmetologist": 5 },
  "31545": { "Barber": 1, "Cosmetologist": 5 },
  "33448": { "Barber": 1 },
  "43953": { "Barber": 1, "Cosmetologist": 1 },
  "06134": { "Barber": 1 },
  "32939": { "Barber": 2, "Cosmetologist": 2 },
  "73942": { "Barber": 2, "Cosmetologist": 1 },
  "32519": { "Barber": 1, "Cosmetologist": 1 },
  "30318": { "Barber": 1, "Cosmetologist": 18 },
  "18201": { "Barber": 17, "Cosmetologist": 6 },
  "31405": { "Barber": 4, "Cosmetologist": 6 },
  "37243": { "Barber": 2, "Cosmetologist": 6 },
  "22203": { "Barber": 1, "Cosmetologist": 1 },
  "79924": { "Barber": 44, "Cosmetologist": 341 },
  "13219": { "Barber": 1 },
  "30084": { "Barber": 2, "Cosmetologist": 16 },
  "13662": { "Barber": 1, "Cosmetologist": 6 },
  "32516": { "Barber": 2, "Cosmetologist": 4 },
  "89052": { "Barber": 1, "Cosmetologist": 12 },
  "80120": { "Barber": 23, "Cosmetologist": 190 },
  "34681": { "Barber": 1, "Cosmetologist": 8 },
  "18104": { "Barber": 32, "Cosmetologist": 17 },
  "11360": { "Barber": 1, "Cosmetologist": 1 },
  "94561": { "Barber": 1, "Cosmetologist": 1 },
  "30507": { "Barber": 1, "Cosmetologist": 6 },
  "33464": { "Barber": 1, "Cosmetologist": 2 },
  "37919": { "Barber": 19, "Cosmetologist": 4 },
  "33982": { "Barber": 2, "Cosmetologist": 114 },
  "10021": { "Barber": 1, "Cosmetologist": 8 },
  "30339": { "Barber": 3, "Cosmetologist": 14 },
  "77459": { "Barber": 76, "Cosmetologist": 463 },
  "13492": { "Barber": 1, "Cosmetologist": 1 },
  "33688": { "Barber": 2, "Cosmetologist": 12 },
  "60087": { "Barber": 1 },
  "51108": { "Barber": 3, "Cosmetologist": 21 },
  "50701": { "Barber": 48, "Cosmetologist": 111 },
  "10305": { "Barber": 1, "Cosmetologist": 3 },
  "11235": { "Barber": 1, "Cosmetologist": 16 },
  "32009": { "Barber": 1, "Cosmetologist": 26 },
  "14580": { "Barber": 1, "Cosmetologist": 5 },
  "92647": { "Barber": 2, "Cosmetologist": 7 },
  "11423": { "Barber": 2, "Cosmetologist": 2 },
  "11367": { "Barber": 1, "Cosmetologist": 4 },
  "70806": { "Barber": 1, "Cosmetologist": 1 },
  "11361": { "Barber": 1, "Cosmetologist": 2 },
  "33672": { "Barber": 1, "Cosmetologist": 3 },
  "30092": { "Barber": 2, "Cosmetologist": 19 },
  "37814": { "Barber": 20, "Cosmetologist": 1 },
  "11355": { "Barber": 1, "Cosmetologist": 26 },
  "93010": { "Barber": 1, "Cosmetologist": 1 },
  "32729": { "Barber": 1 },
  "10920": { "Barber": 1 },
  "33061": { "Barber": 2, "Cosmetologist": 8 },
  "48867": { "Barber": 18, "Cosmetologist": 192 },
  "21740": { "Barber": 38, "Cosmetologist": 7 },
  "10470": { "Barber": 1, "Cosmetologist": 2 },
  "14048": { "Barber": 2, "Cosmetologist": 3 },
  "30337": { "Barber": 1, "Cosmetologist": 7 },
  "33318": { "Barber": 1, "Cosmetologist": 8 },
  "46360": { "Barber": 17, "Cosmetologist": 5 },
  "29016": { "Barber": 1, "Cosmetologist": 11 },
  "27320": { "Barber": 5, "Cosmetologist": 252 },
  "19125": { "Barber": 1, "Cosmetologist": 9 },
  "36441": { "Barber": 1, "Cosmetologist": 7 },
  "37402": { "Barber": 5, "Cosmetologist": 1 },
  "30038": { "Barber": 5, "Cosmetologist": 12 },
  "11967": { "Barber": 1, "Cosmetologist": 5 },
  "13088": { "Barber": 1, "Cosmetologist": 3 },
  "34749": { "Barber": 1, "Cosmetologist": 8 },
  "34923": { "Barber": 1 },
  "72701": { "Barber": 1, "Cosmetologist": 2 },
  "50511": { "Barber": 2, "Cosmetologist": 47 },
  "44111": { "Barber": 5, "Cosmetologist": 3 },
  "16301": { "Barber": 2, "Cosmetologist": 18 },
  "89145": { "Barber": 1, "Cosmetologist": 5 },
  "32635": { "Barber": 1, "Cosmetologist": 13 },
  "76706": { "Barber": 29, "Cosmetologist": 163 },
  "37828": { "Barber": 4, "Cosmetologist": 1 },
  "22204": { "Barber": 1, "Cosmetologist": 7 },
  "92118": { "Barber": 1, "Cosmetologist": 3 },
  "30331": { "Barber": 1, "Cosmetologist": 11 },
  "90755": { "Barber": 1, "Cosmetologist": 1 },
  "32423": { "Barber": 1, "Cosmetologist": 7 },
  "34253": { "Barber": 1, "Cosmetologist": 1 },
  "40383": { "Barber": 1, "Cosmetologist": 4 },
  "89120": { "Barber": 3, "Cosmetologist": 4 },
  "47374": { "Barber": 11, "Cosmetologist": 4 },
  "61604": { "Barber": 1, "Cosmetologist": 4 },
  "75235": { "Barber": 12, "Cosmetologist": 122 },
  "20659": { "Barber": 1, "Cosmetologist": 1 },
  "12309": { "Barber": 1, "Cosmetologist": 1 },
  "11385": { "Barber": 1, "Cosmetologist": 9 },
  "31757": { "Barber": 1, "Cosmetologist": 16 },
  "13039": { "Barber": 2, "Cosmetologist": 2 },
  "48167": { "Barber": 8, "Cosmetologist": 153 },
  "20602": { "Barber": 1, "Cosmetologist": 6 },
  "67801": { "Barber": 1, "Cosmetologist": 207 },
  "11710": { "Barber": 1, "Cosmetologist": 11 },
  "93460": { "Barber": 1, "Cosmetologist": 1 },
  "10037": { "Barber": 1, "Cosmetologist": 4 },
  "32965": { "Barber": 1, "Cosmetologist": 8 },
  "27613": { "Barber": 5, "Cosmetologist": 210 },
  "77084": { "Barber": 101, "Cosmetologist": 1341 },
  "67904": { "Barber": 1 },
  "31807": { "Barber": 1, "Cosmetologist": 1 },
  "13212": { "Barber": 1, "Cosmetologist": 4 },
  "37128": { "Barber": 95, "Cosmetologist": 19 },
  "54017": { "Barber": 1, "Cosmetologist": 2 },
  "65613": { "Barber": 13, "Cosmetologist": 96 },
  "31535": { "Barber": 1, "Cosmetologist": 6 },
  "88201": { "Barber": 2, "Cosmetologist": 3 },
  "44514": { "Barber": 3, "Cosmetologist": 3 },
  "77079": { "Barber": 10, "Cosmetologist": 206 },
  "31904": { "Barber": 3, "Cosmetologist": 9 },
  "32879": { "Barber": 1, "Cosmetologist": 1 },
  "38461": { "Barber": 2, "Cosmetologist": 2 },
  "11779": { "Barber": 1, "Cosmetologist": 5 },
  "46140": { "Barber": 23, "Cosmetologist": 3 },
  "34250": { "Barber": 1, "Cosmetologist": 8 },
  "11427": { "Barber": 1, "Cosmetologist": 1 },
  "08816": { "Barber": 41, "Cosmetologist": 188 },
  "19116": { "Barber": 1, "Cosmetologist": 4 },
  "23124": { "Barber": 1, "Cosmetologist": 2 },
  "80212": { "Barber": 17, "Cosmetologist": 99 },
  "22033": { "Barber": 1, "Cosmetologist": 5 },
  "33117": { "Barber": 1, "Cosmetologist": 1 },
  "33057": { "Barber": 1 },
  "10457": { "Barber": 2, "Cosmetologist": 2 },
  "33698": { "Barber": 1 },
  "18974": { "Barber": 12, "Cosmetologist": 21 },
  "19960": { "Barber": 1 },
  "33969": { "Barber": 1 },
  "10553": { "Barber": 1 },
  "44135": { "Barber": 4, "Cosmetologist": 5 },
  "36502": { "Barber": 1, "Cosmetologist": 9 },
  "32248": { "Barber": 1 },
  "23233": { "Barber": 1, "Cosmetologist": 7 },
  "18102": { "Barber": 42, "Cosmetologist": 7 },
  "30263": { "Barber": 2, "Cosmetologist": 20 },
  "33735": { "Barber": 1 },
  "33453": { "Barber": 1, "Cosmetologist": 1 },
  "11793": { "Barber": 1, "Cosmetologist": 3 },
  "45440": { "Barber": 9, "Cosmetologist": 3 },
  "11366": { "Barber": 2, "Cosmetologist": 2 },
  "18015": { "Barber": 7, "Cosmetologist": 7 },
  "10465": { "Barber": 1, "Cosmetologist": 1 },
  "11432": { "Barber": 1, "Cosmetologist": 4 },
  "89146": { "Barber": 1 },
  "32932": { "Barber": 1, "Cosmetologist": 5 },
  "60657": { "Barber": 3, "Cosmetologist": 7 },
  "32542": { "Barber": 2, "Cosmetologist": 15 },
  "11416": { "Barber": 1, "Cosmetologist": 2 },
  "33970": { "Barber": 1, "Cosmetologist": 11 },
  "31217": { "Barber": 1, "Cosmetologist": 11 },
  "55306": { "Barber": 1, "Cosmetologist": 2 },
  "30080": { "Barber": 3, "Cosmetologist": 23 },
  "11950": { "Barber": 1, "Cosmetologist": 4 },
  "80238": { "Barber": 7, "Cosmetologist": 56 },
  "34708": { "Barber": 1 },
  "45229": { "Barber": 2 },
  "95050": { "Barber": 1 },
  "26104": { "Barber": 1, "Cosmetologist": 2 },
  "30116": { "Barber": 1, "Cosmetologist": 4 },
  "33832": { "Barber": 1 },
  "76384": { "Barber": 6, "Cosmetologist": 64 },
  "93555": { "Barber": 1, "Cosmetologist": 1 },
  "12189": { "Barber": 1, "Cosmetologist": 2 },
  "15221": { "Barber": 7, "Cosmetologist": 2 },
  "11581": { "Barber": 1, "Cosmetologist": 2 },
  "33394": { "Barber": 1 },
  "80911": { "Barber": 42, "Cosmetologist": 224 },
  "72076": { "Barber": 1, "Cosmetologist": 9 },
  "33059": { "Barber": 1 },
  "34770": { "Barber": 1, "Cosmetologist": 13 },
  "11233": { "Barber": 1, "Cosmetologist": 2 },
  "14533": { "Barber": 1 },
  "39837": { "Barber": 1, "Cosmetologist": 4 },
  "60419": { "Barber": 1 },
  "34751": { "Barber": 1 },
  "14616": { "Barber": 1, "Cosmetologist": 1 },
  "27603": { "Barber": 3, "Cosmetologist": 287 },
  "54520": { "Barber": 1 },
  "80239": { "Barber": 47, "Cosmetologist": 167 },
  "22911": { "Barber": 1, "Cosmetologist": 2 },
  "32227": { "Barber": 1, "Cosmetologist": 7 },
  "32421": { "Barber": 1, "Cosmetologist": 22 },
  "46041": { "Barber": 10, "Cosmetologist": 1 },
  "13501": { "Barber": 1, "Cosmetologist": 6 },
  "45380": { "Barber": 1, "Cosmetologist": 1 },
  "45231": { "Barber": 6, "Cosmetologist": 2 },
  "41018": { "Barber": 1, "Cosmetologist": 1 },
  "37207": { "Barber": 80, "Cosmetologist": 4 },
  "45414": { "Barber": 5, "Cosmetologist": 2 },
  "28808": { "Barber": 1 },
  "19096": { "Barber": 1, "Cosmetologist": 1 },
  "60585": { "Barber": 1, "Cosmetologist": 8 },
  "11717": { "Barber": 1, "Cosmetologist": 4 },
  "17013": { "Barber": 10, "Cosmetologist": 15 },
  "32622": { "Barber": 1, "Cosmetologist": 5 },
  "89031": { "Barber": 1, "Cosmetologist": 17 },
  "32033": { "Barber": 1, "Cosmetologist": 48 },
  "39909": { "Barber": 1 },
  "33930": { "Barber": 1, "Cosmetologist": 11 },
  "20721": { "Barber": 30, "Cosmetologist": 3 },
  "33871": { "Barber": 1, "Cosmetologist": 6 },
  "43701": { "Barber": 2, "Cosmetologist": 2 },
  "97201": { "Barber": 2, "Cosmetologist": 1 },
  "33120": { "Barber": 1 },
  "55108": { "Barber": 1 },
  "33195": { "Barber": 1, "Cosmetologist": 2 },
  "94536": { "Barber": 1, "Cosmetologist": 1 },
  "36080": { "Barber": 1, "Cosmetologist": 1 },
  "11566": { "Barber": 2, "Cosmetologist": 4 },
  "41042": { "Barber": 1, "Cosmetologist": 4 },
  "41094": { "Barber": 1 },
  "85388": { "Barber": 12, "Cosmetologist": 6 },
  "19709": { "Barber": 1, "Cosmetologist": 8 },
  "38401": { "Barber": 48, "Cosmetologist": 11 },
  "29445": { "Barber": 1, "Cosmetologist": 18 },
  "29617": { "Barber": 1, "Cosmetologist": 12 },
  "70360": { "Barber": 1, "Cosmetologist": 5 },
  "20020": { "Barber": 1, "Cosmetologist": 4 },
  "11050": { "Barber": 1, "Cosmetologist": 2 },
  "47111": { "Barber": 9, "Cosmetologist": 1 },
  "17020": { "Barber": 5, "Cosmetologist": 23 },
  "28215": { "Barber": 5, "Cosmetologist": 500 },
  "32959": { "Barber": 2, "Cosmetologist": 4 },
  "32611": { "Barber": 1 },
  "30344": { "Barber": 4, "Cosmetologist": 12 },
  "35243": { "Barber": 1, "Cosmetologist": 3 },
  "31523": { "Cosmetologist": 14 },
  "30319": { "Cosmetologist": 7 },
  "30308": { "Cosmetologist": 15, "Barber": 3 },
  "31021": { "Cosmetologist": 4 },
  "30165": { "Barber": 2, "Cosmetologist": 13 },
  "36116": { "Cosmetologist": 3 },
  "31093": { "Cosmetologist": 5, "Barber": 2 },
  "30078": { "Cosmetologist": 20 },
  "31302": { "Barber": 2, "Cosmetologist": 4 },
  "30064": { "Cosmetologist": 20 },
  "30501": { "Cosmetologist": 15, "Barber": 1 },
  "30663": { "Cosmetologist": 1 },
  "29831": { "Cosmetologist": 2 },
  "31730": { "Cosmetologist": 4 },
  "30075": { "Cosmetologist": 24, "Barber": 1 },
  "30067": { "Barber": 4, "Cosmetologist": 17 },
  "30014": { "Cosmetologist": 9 },
  "30529": { "Cosmetologist": 5, "Barber": 1 },
  "22191": { "Cosmetologist": 10 },
  "31211": { "Cosmetologist": 6 },
  "30619": { "Cosmetologist": 1 },
  "30607": { "Cosmetologist": 2 },
  "31907": { "Cosmetologist": 16, "Barber": 2 },
  "31322": { "Barber": 1, "Cosmetologist": 19 },
  "35180": { "Cosmetologist": 2 },
  "30079": { "Cosmetologist": 2 },
  "30309": { "Cosmetologist": 11 },
  "31709": { "Cosmetologist": 10 },
  "30132": { "Cosmetologist": 17 },
  "30311": { "Cosmetologist": 11, "Barber": 4 },
  "30022": { "Cosmetologist": 27 },
  "30046": { "Cosmetologist": 19 },
  "39813": { "Cosmetologist": 1 },
  "30721": { "Cosmetologist": 8, "Barber": 1 },
  "30342": { "Cosmetologist": 8 },
  "30307": { "Cosmetologist": 12 },
  "92595": { "Cosmetologist": 6 },
  "30101": { "Cosmetologist": 23, "Barber": 1 },
  "30062": { "Cosmetologist": 20 },
  "30141": { "Cosmetologist": 14 },
  "29841": { "Cosmetologist": 13, "Barber": 2 },
  "30316": { "Barber": 3, "Cosmetologist": 14 },
  "30005": { "Cosmetologist": 15 },
  "30238": { "Cosmetologist": 9, "Barber": 1 },
  "30415": { "Cosmetologist": 2 },
  "30519": { "Cosmetologist": 38 },
  "30204": { "Cosmetologist": 5 },
  "30054": { "Cosmetologist": 3 },
  "30608": { "Cosmetologist": 1 },
  "30701": { "Cosmetologist": 6, "Barber": 1 },
  "31061": { "Cosmetologist": 17, "Barber": 3 },
  "31210": { "Cosmetologist": 10, "Barber": 1 },
  "29577": { "Cosmetologist": 21 },
  "31058": { "Cosmetologist": 1 },
  "30144": { "Cosmetologist": 24, "Barber": 1 },
  "30728": { "Cosmetologist": 5 },
  "94585": { "Cosmetologist": 1 },
  "30707": { "Barber": 1, "Cosmetologist": 4 },
  "30741": { "Barber": 2, "Cosmetologist": 5 },
  "31909": { "Cosmetologist": 15 },
  "30060": { "Cosmetologist": 10, "Barber": 1 },
  "31521": { "Barber": 1 },
  "39502": { "Cosmetologist": 1 },
  "30168": { "Cosmetologist": 13, "Barber": 1 },
  "30546": { "Cosmetologist": 7 },
  "30268": { "Cosmetologist": 4, "Barber": 1 },
  "31063": { "Cosmetologist": 1 },
  "30461": { "Cosmetologist": 6 },
  "10475": { "Cosmetologist": 2 },
  "30047": { "Cosmetologist": 33 },
  "48127": { "Barber": 38, "Cosmetologist": 232 },
  "30097": { "Cosmetologist": 17 },
  "30114": { "Cosmetologist": 34, "Barber": 1 },
  "30024": { "Cosmetologist": 31 },
  "30152": { "Cosmetologist": 24, "Barber": 1 },
  "30736": { "Cosmetologist": 13 },
  "30040": { "Cosmetologist": 34, "Barber": 2 },
  "98118": { "Cosmetologist": 3, "Barber": 1 },
  "16157": { "Cosmetologist": 2, "Barber": 2 },
  "30297": { "Barber": 3, "Cosmetologist": 13 },
  "30302": { "Cosmetologist": 1 },
  "30305": { "Cosmetologist": 41, "Barber": 1 },
  "30281": { "Cosmetologist": 34, "Barber": 2 },
  "30306": { "Cosmetologist": 7, "Barber": 1 },
  "30189": { "Cosmetologist": 27 },
  "30083": { "Cosmetologist": 27, "Barber": 3 },
  "30004": { "Barber": 3, "Cosmetologist": 13 },
  "30313": { "Barber": 1, "Cosmetologist": 4 },
  "30273": { "Cosmetologist": 9, "Barber": 1 },
  "30039": { "Cosmetologist": 14, "Barber": 2 },
  "30127": { "Barber": 1, "Cosmetologist": 22 },
  "30087": { "Cosmetologist": 17, "Barber": 3 },
  "37211": { "Barber": 55, "Cosmetologist": 14 },
  "30030": { "Cosmetologist": 4 },
  "30134": { "Cosmetologist": 19, "Barber": 1 },
  "30076": { "Cosmetologist": 20 },
  "30213": { "Barber": 5, "Cosmetologist": 15 },
  "19144": { "Cosmetologist": 6, "Barber": 1 },
  "90015": { "Cosmetologist": 4 },
  "30320": { "Barber": 1 },
  "31404": { "Barber": 1, "Cosmetologist": 10 },
  "30093": { "Cosmetologist": 21, "Barber": 2 },
  "30021": { "Cosmetologist": 12 },
  "31324": { "Cosmetologist": 20, "Barber": 2 },
  "31749": { "Cosmetologist": 1 },
  "46815": { "Cosmetologist": 3, "Barber": 21 },
  "31204": { "Cosmetologist": 9, "Barber": 2 },
  "30135": { "Cosmetologist": 15, "Barber": 1 },
  "30234": { "Cosmetologist": 2, "Barber": 1 },
  "30274": { "Cosmetologist": 15 },
  "31019": { "Cosmetologist": 1 },
  "30533": { "Cosmetologist": 11 },
  "30055": { "Cosmetologist": 2 },
  "30540": { "Cosmetologist": 9 },
  "31036": { "Cosmetologist": 5 },
  "31519": { "Cosmetologist": 2 },
  "30543": { "Cosmetologist": 2 },
  "30752": { "Cosmetologist": 2 },
  "30176": { "Cosmetologist": 1 },
  "30564": { "Cosmetologist": 2, "Barber": 1 },
  "63559": { "Cosmetologist": 9 },
  "30742": { "Cosmetologist": 2 },
  "30709": { "Barber": 1 },
  "30314": { "Cosmetologist": 9, "Barber": 4 },
  "62501": { "Cosmetologist": 1 },
  "30161": { "Cosmetologist": 9, "Barber": 1 },
  "30103": { "Barber": 1, "Cosmetologist": 4 },
  "30121": { "Cosmetologist": 5, "Barber": 1 },
  "30104": { "Cosmetologist": 1 },
  "30123": { "Barber": 1, "Cosmetologist": 1 },
  "31522": { "Barber": 2, "Cosmetologist": 11 },
  "30241": { "Cosmetologist": 3 },
  "30153": { "Cosmetologist": 5 },
  "46229": { "Cosmetologist": 5, "Barber": 30 },
  "30184": { "Cosmetologist": 2 },
  "31320": { "Cosmetologist": 5 },
  "30102": { "Cosmetologist": 13 },
  "30711": { "Cosmetologist": 3 },
  "30802": { "Cosmetologist": 2, "Barber": 1 },
  "31542": { "Cosmetologist": 1 },
  "30157": { "Cosmetologist": 18, "Barber": 1 },
  "31032": { "Cosmetologist": 2, "Barber": 1 },
  "20037": { "Cosmetologist": 2 },
  "30315": { "Barber": 2, "Cosmetologist": 14 },
  "29856": { "Cosmetologist": 3 },
  "31014": { "Cosmetologist": 7 },
  "31771": { "Cosmetologist": 1 },
  "30904": { "Cosmetologist": 5 },
  "30906": { "Cosmetologist": 12, "Barber": 3 },
  "30034": { "Barber": 1, "Cosmetologist": 13 },
  "31088": { "Cosmetologist": 16, "Barber": 2 },
  "30909": { "Cosmetologist": 17, "Barber": 2 },
  "31024": { "Cosmetologist": 5 },
  "30066": { "Barber": 3, "Cosmetologist": 21 },
  "30009": { "Cosmetologist": 3 },
  "50316": { "Cosmetologist": 37, "Barber": 9 },
  "30122": { "Cosmetologist": 8, "Barber": 1 },
  "30720": { "Cosmetologist": 5, "Barber": 1 },
  "30350": { "Cosmetologist": 15 },
  "48085": { "Cosmetologist": 130, "Barber": 4 },
  "30622": { "Cosmetologist": 3 },
  "29847": { "Cosmetologist": 1 },
  "30068": { "Cosmetologist": 10, "Barber": 1 },
  "30033": { "Cosmetologist": 13 },
  "29631": { "Cosmetologist": 2 },
  "31768": { "Cosmetologist": 7 },
  "35803": { "Cosmetologist": 3 },
  "36870": { "Cosmetologist": 6, "Barber": 1 },
  "30328": { "Barber": 4, "Cosmetologist": 21 },
  "30324": { "Cosmetologist": 16, "Barber": 3 },
  "30096": { "Cosmetologist": 35, "Barber": 2 },
  "30326": { "Cosmetologist": 4 },
  "30338": { "Cosmetologist": 11 },
  "30329": { "Cosmetologist": 12 },
  "30317": { "Cosmetologist": 4 },
  "31308": { "Barber": 1, "Cosmetologist": 1 },
  "80504": { "Cosmetologist": 293, "Barber": 20 },
  "31068": { "Cosmetologist": 2 },
  "30265": { "Cosmetologist": 12 },
  "21401": { "Cosmetologist": 9, "Barber": 28 },
  "28326": { "Cosmetologist": 144, "Barber": 1 },
  "31533": { "Cosmetologist": 5, "Barber": 1 },
  "30136": { "Barber": 1, "Cosmetologist": 1 },
  "30110": { "Cosmetologist": 1, "Barber": 1 },
  "30248": { "Cosmetologist": 15 },
  "30805": { "Cosmetologist": 1 },
  "78254": { "Cosmetologist": 654, "Barber": 48 },
  "31027": { "Cosmetologist": 2 },
  "30474": { "Cosmetologist": 7 },
  "30411": { "Cosmetologist": 2 },
  "30236": { "Barber": 4, "Cosmetologist": 19 },
  "30656": { "Cosmetologist": 5, "Barber": 1 },
  "30828": { "Cosmetologist": 2 },
  "31106": { "Cosmetologist": 1 },
  "30346": { "Cosmetologist": 10 },
  "65806": { "Cosmetologist": 33, "Barber": 6 },
  "30740": { "Cosmetologist": 3 },
  "10705": { "Cosmetologist": 1 },
  "30340": { "Cosmetologist": 11 },
  "84003": { "Cosmetologist": 6, "Barber": 1 },
  "29801": { "Cosmetologist": 7 },
  "31791": { "Cosmetologist": 5 },
  "31820": { "Cosmetologist": 7 },
  "92584": { "Cosmetologist": 3 },
  "31602": { "Cosmetologist": 20 },
  "30205": { "Cosmetologist": 2 },
  "30125": { "Cosmetologist": 6, "Barber": 1 },
  "30521": { "Cosmetologist": 3 },
  "30224": { "Cosmetologist": 5 },
  "30650": { "Cosmetologist": 3 },
  "90262": { "Cosmetologist": 1 },
  "30211": { "Cosmetologist": 1 },
  "30624": { "Cosmetologist": 1 },
  "37416": { "Cosmetologist": 3, "Barber": 14 },
  "30504": { "Cosmetologist": 7 },
  "30642": { "Cosmetologist": 9 },
  "30071": { "Cosmetologist": 13, "Barber": 1 },
  "30655": { "Cosmetologist": 10 },
  "28904": { "Barber": 1, "Cosmetologist": 52 },
  "30223": { "Cosmetologist": 6 },
  "30117": { "Cosmetologist": 4 },
  "30514": { "Cosmetologist": 2 },
  "31401": { "Cosmetologist": 9, "Barber": 1 },
  "31901": { "Barber": 2, "Cosmetologist": 5 },
  "31305": { "Barber": 2, "Cosmetologist": 4 },
  "31055": { "Cosmetologist": 3, "Barber": 2 },
  "94526": { "Cosmetologist": 3 },
  "30471": { "Cosmetologist": 2 },
  "31534": { "Cosmetologist": 2 },
  "10452": { "Cosmetologist": 5 },
  "28027": { "Cosmetologist": 513, "Barber": 1 },
  "31811": { "Cosmetologist": 2 },
  "30178": { "Cosmetologist": 3 },
  "30643": { "Cosmetologist": 14 },
  "31074": { "Barber": 1 },
  "30143": { "Cosmetologist": 4 },
  "30901": { "Cosmetologist": 5 },
  "31331": { "Cosmetologist": 5 },
  "31643": { "Cosmetologist": 1 },
  "30634": { "Cosmetologist": 1 },
  "31516": { "Cosmetologist": 5 },
  "28584": { "Cosmetologist": 123, "Barber": 1 },
  "30012": { "Cosmetologist": 11, "Barber": 1 },
  "31030": { "Cosmetologist": 3, "Barber": 1 },
  "44311": { "Cosmetologist": 2 },
  "31554": { "Cosmetologist": 2 },
  "36027": { "Cosmetologist": 3 },
  "29680": { "Cosmetologist": 14 },
  "29860": { "Cosmetologist": 2 },
  "30115": { "Cosmetologist": 14 },
  "30725": { "Cosmetologist": 1 },
  "31501": { "Cosmetologist": 11 },
  "30310": { "Barber": 2, "Cosmetologist": 7 },
  "30128": { "Cosmetologist": 1 },
  "30601": { "Cosmetologist": 5 },
  "93001": { "Cosmetologist": 1 },
  "30428": { "Cosmetologist": 1 },
  "29812": { "Barber": 1, "Cosmetologist": 1 },
  "31705": { "Cosmetologist": 9 },
  "45680": { "Cosmetologist": 1 },
  "30187": { "Cosmetologist": 3 },
  "29673": { "Cosmetologist": 12 },
  "31906": { "Cosmetologist": 5, "Barber": 1 },
  "31029": { "Cosmetologist": 3, "Barber": 1 },
  "30534": { "Cosmetologist": 13 },
  "30253": { "Cosmetologist": 31, "Barber": 1 },
  "39870": { "Cosmetologist": 2 },
  "31605": { "Cosmetologist": 23 },
  "30756": { "Cosmetologist": 1 },
  "35147": { "Cosmetologist": 1 },
  "30730": { "Cosmetologist": 1 },
  "30240": { "Cosmetologist": 2, "Barber": 1 },
  "28213": { "Cosmetologist": 369, "Barber": 8 },
  "30824": { "Cosmetologist": 3 },
  "31069": { "Cosmetologist": 14 },
  "31216": { "Barber": 3, "Cosmetologist": 4 },
  "30008": { "Cosmetologist": 13, "Barber": 1 },
  "30260": { "Cosmetologist": 19 },
  "30453": { "Cosmetologist": 1 },
  "31780": { "Cosmetologist": 1 },
  "30560": { "Barber": 2, "Cosmetologist": 4 },
  "30531": { "Cosmetologist": 3, "Barber": 1 },
  "30276": { "Cosmetologist": 7 },
  "30341": { "Barber": 3, "Cosmetologist": 8 },
  "36874": { "Cosmetologist": 3 },
  "31816": { "Cosmetologist": 1 },
  "30441": { "Cosmetologist": 2 },
  "30303": { "Cosmetologist": 2 },
  "31008": { "Cosmetologist": 5 },
  "30035": { "Barber": 1, "Cosmetologist": 3 },
  "52803": { "Cosmetologist": 114, "Barber": 5 },
  "30523": { "Cosmetologist": 5, "Barber": 1 },
  "30582": { "Cosmetologist": 9 },
  "31206": { "Cosmetologist": 2 },
  "31707": { "Cosmetologist": 7, "Barber": 1 },
  "79904": { "Barber": 17, "Cosmetologist": 150 },
  "70122": { "Cosmetologist": 5 },
  "30133": { "Cosmetologist": 1 },
  "31201": { "Cosmetologist": 3 },
  "30002": { "Cosmetologist": 3 },
  "30180": { "Cosmetologist": 14 },
  "30566": { "Cosmetologist": 4 },
  "39817": { "Cosmetologist": 13 },
  "30162": { "Cosmetologist": 1 },
  "31624": { "Cosmetologist": 1 },
  "30710": { "Cosmetologist": 3 },
  "31321": { "Cosmetologist": 1 },
  "30233": { "Barber": 1, "Cosmetologist": 3 },
  "31701": { "Cosmetologist": 4 },
  "31781": { "Cosmetologist": 2 },
  "30646": { "Cosmetologist": 2 },
  "30360": { "Cosmetologist": 3 },
  "30633": { "Barber": 1, "Cosmetologist": 4 },
  "31539": { "Cosmetologist": 3 },
  "30554": { "Cosmetologist": 6 },
  "31760": { "Cosmetologist": 1 },
  "30530": { "Cosmetologist": 3 },
  "31808": { "Barber": 1, "Cosmetologist": 4 },
  "39886": { "Cosmetologist": 2 },
  "30025": { "Cosmetologist": 2 },
  "30542": { "Cosmetologist": 10 },
  "30628": { "Cosmetologist": 4 },
  "30061": { "Cosmetologist": 2 },
  "30417": { "Cosmetologist": 2 },
  "31632": { "Cosmetologist": 5 },
  "36869": { "Cosmetologist": 3, "Barber": 1 },
  "97470": { "Cosmetologist": 2 },
  "31513": { "Barber": 2, "Cosmetologist": 2 },
  "36801": { "Cosmetologist": 9 },
  "28717": { "Cosmetologist": 17 },
  "30217": { "Cosmetologist": 2 },
  "30668": { "Cosmetologist": 2 },
  "30436": { "Cosmetologist": 6 },
  "31083": { "Cosmetologist": 1 },
  "30216": { "Cosmetologist": 1 },
  "31765": { "Cosmetologist": 1 },
  "39114": { "Cosmetologist": 1 },
  "31639": { "Cosmetologist": 3 },
  "30669": { "Cosmetologist": 1 },
  "31407": { "Barber": 1, "Cosmetologist": 8 },
  "31410": { "Cosmetologist": 13 },
  "31649": { "Cosmetologist": 2 },
  "30442": { "Cosmetologist": 2 },
  "36877": { "Cosmetologist": 2 },
  "30082": { "Cosmetologist": 9, "Barber": 1 },
  "30145": { "Cosmetologist": 2 },
  "56401": { "Cosmetologist": 4 },
  "30558": { "Barber": 1, "Cosmetologist": 2 },
  "30049": { "Cosmetologist": 1 },
  "78748": { "Cosmetologist": 450, "Barber": 30 },
  "30467": { "Barber": 1, "Cosmetologist": 2 },
  "30291": { "Cosmetologist": 12, "Barber": 1 },
  "36867": { "Cosmetologist": 6 },
  "30183": { "Cosmetologist": 2 },
  "75033": { "Cosmetologist": 4 },
  "30905": { "Barber": 1, "Cosmetologist": 1 },
  "30548": { "Barber": 1, "Cosmetologist": 9 },
  "44306": { "Barber": 6 },
  "42223": { "Cosmetologist": 9 },
  "30635": { "Cosmetologist": 4 },
  "30164": { "Cosmetologist": 1 },
  "30286": { "Cosmetologist": 2 },
  "53223": { "Cosmetologist": 6 },
  "31031": { "Cosmetologist": 2 },
  "31717": { "Cosmetologist": 1 },
  "77081": { "Cosmetologist": 164, "Barber": 17 },
  "31827": { "Cosmetologist": 1 },
  "30065": { "Cosmetologist": 1 },
  "36273": { "Cosmetologist": 1 },
  "30230": { "Cosmetologist": 1 },
  "31642": { "Cosmetologist": 1 },
  "37391": { "Cosmetologist": 1 },
  "30641": { "Cosmetologist": 1 },
  "30293": { "Barber": 1 },
  "95129": { "Cosmetologist": 2 },
  "30179": { "Cosmetologist": 3 },
  "30621": { "Barber": 1, "Cosmetologist": 1 },
  "42701": { "Cosmetologist": 10, "Barber": 1 },
  "30209": { "Cosmetologist": 1 },
  "30327": { "Cosmetologist": 5 },
  "30220": { "Cosmetologist": 2 },
  "30757": { "Cosmetologist": 1 },
  "30427": { "Cosmetologist": 1 },
  "31728": { "Barber": 1 },
  "77640": { "Cosmetologist": 87, "Barber": 7 },
  "30814": { "Barber": 1, "Cosmetologist": 2 },
  "30808": { "Barber": 1 },
  "30113": { "Cosmetologist": 1 },
  "20019": { "Cosmetologist": 3 },
  "20745": { "Cosmetologist": 4 },
  "89108": { "Cosmetologist": 6 },
  "95117": { "Cosmetologist": 3 },
  "28636": { "Cosmetologist": 43 },
  "36863": { "Cosmetologist": 1 },
  "37616": { "Cosmetologist": 3, "Barber": 4 },
  "96205": { "Cosmetologist": 1, "Barber": 1 },
  "36804": { "Cosmetologist": 1 },
  "31205": { "Cosmetologist": 1 },
  "30139": { "Cosmetologist": 2 },
  "31764": { "Cosmetologist": 1 },
  "30683": { "Cosmetologist": 1 },
  "23601": { "Cosmetologist": 6 },
  "75138": { "Cosmetologist": 2 },
  "31316": { "Cosmetologist": 11 },
  "30517": { "Cosmetologist": 2 },
  "20710": { "Cosmetologist": 2, "Barber": 3 },
  "95033": { "Cosmetologist": 1 },
  "78676": { "Cosmetologist": 110, "Barber": 6 },
  "92603": { "Cosmetologist": 1 },
  "85345": { "Cosmetologist": 7, "Barber": 46 },
  "30903": { "Cosmetologist": 1 },
  "20010": { "Cosmetologist": 5 },
  "48724": { "Cosmetologist": 2, "Barber": 1 },
  "31565": { "Cosmetologist": 2 },
  "30011": { "Barber": 1, "Cosmetologist": 3 },
  "31793": { "Cosmetologist": 3 },
  "63304": { "Cosmetologist": 288, "Barber": 10 },
  "28803": { "Cosmetologist": 177, "Barber": 2 },
  "75248": { "Cosmetologist": 213, "Barber": 24 },
  "95242": { "Cosmetologist": 1 },
  "77072": { "Cosmetologist": 1559, "Barber": 27 },
  "29414": { "Cosmetologist": 13 },
  "22315": { "Cosmetologist": 1 },
  "30325": { "Cosmetologist": 3 },
  "31315": { "Cosmetologist": 3 },
  "31326": { "Cosmetologist": 9 },
  "30106": { "Cosmetologist": 13 },
  "29822": { "Cosmetologist": 1 },
  "30288": { "Cosmetologist": 2 },
  "31415": { "Cosmetologist": 3 },
  "30296": { "Barber": 1, "Cosmetologist": 7 },
  "30059": { "Barber": 1 },
  "77845": { "Cosmetologist": 435, "Barber": 22 },
  "31796": { "Cosmetologist": 1 },
  "31750": { "Cosmetologist": 2 },
  "94806": { "Cosmetologist": 1 },
  "30620": { "Cosmetologist": 4 },
  "30036": { "Cosmetologist": 2 },
  "80923": { "Barber": 27, "Cosmetologist": 186 },
  "78756": { "Barber": 13, "Cosmetologist": 60 },
  "81504": { "Barber": 15, "Cosmetologist": 304 },
  "80204": { "Barber": 32, "Cosmetologist": 156 },
  "80027": { "Barber": 15, "Cosmetologist": 105 },
  "80920": { "Barber": 28, "Cosmetologist": 204 },
  "80216": { "Barber": 13, "Cosmetologist": 76 },
  "18702": { "Barber": 6, "Cosmetologist": 8 },
  "80221": { "Barber": 37, "Cosmetologist": 239 },
  "80128": { "Barber": 23, "Cosmetologist": 339 },
  "20904": { "Barber": 66, "Cosmetologist": 7 },
  "80905": { "Barber": 13, "Cosmetologist": 95 },
  "80227": { "Barber": 30, "Cosmetologist": 238 },
  "80121": { "Barber": 11, "Cosmetologist": 93 },
  "80915": { "Barber": 27, "Cosmetologist": 136 },
  "80631": { "Barber": 24, "Cosmetologist": 205 },
  "80236": { "Barber": 14, "Cosmetologist": 135 },
  "80229": { "Barber": 47, "Cosmetologist": 315 },
  "80218": { "Barber": 19, "Cosmetologist": 111 },
  "80013": { "Barber": 48, "Cosmetologist": 453 },
  "80219": { "Barber": 58, "Cosmetologist": 301 },
  "80537": { "Barber": 17, "Cosmetologist": 277 },
  "80304": { "Barber": 9, "Cosmetologist": 65 },
  "80012": { "Barber": 40, "Cosmetologist": 212 },
  "80525": { "Barber": 26, "Cosmetologist": 350 },
  "80022": { "Barber": 27, "Cosmetologist": 260 },
  "80016": { "Barber": 23, "Cosmetologist": 325 },
  "70092": { "Barber": 1, "Cosmetologist": 1 },
  "28304": { "Barber": 9, "Cosmetologist": 303 },
  "80549": { "Barber": 3, "Cosmetologist": 93 },
  "80817": { "Barber": 30, "Cosmetologist": 148 },
  "81005": { "Barber": 32, "Cosmetologist": 228 },
  "80927": { "Barber": 6, "Cosmetologist": 51 },
  "80918": { "Barber": 42, "Cosmetologist": 333 },
  "80011": { "Barber": 20, "Cosmetologist": 157 },
  "80214": { "Barber": 22, "Cosmetologist": 155 },
  "89084": { "Barber": 1, "Cosmetologist": 7 },
  "98501": { "Barber": 1, "Cosmetologist": 2 },
  "80002": { "Barber": 14, "Cosmetologist": 128 },
  "80524": { "Barber": 18, "Cosmetologist": 181 },
  "80233": { "Barber": 32, "Cosmetologist": 351 },
  "80003": { "Barber": 19, "Cosmetologist": 298 },
  "80220": { "Barber": 17, "Cosmetologist": 161 },
  "80501": { "Barber": 18, "Cosmetologist": 250 },
  "80207": { "Barber": 27, "Cosmetologist": 109 },
  "96768": { "Barber": 1, "Cosmetologist": 2 },
  "80602": { "Barber": 34, "Cosmetologist": 315 },
  "80543": { "Barber": 8, "Cosmetologist": 51 },
  "81416": { "Barber": 15, "Cosmetologist": 117 },
  "80916": { "Barber": 50, "Cosmetologist": 196 },
  "80215": { "Barber": 9, "Cosmetologist": 122 },
  "80015": { "Barber": 22, "Cosmetologist": 416 },
  "10023": { "Barber": 1, "Cosmetologist": 4 },
  "80023": { "Barber": 6, "Cosmetologist": 86 },
  "80249": { "Barber": 15, "Cosmetologist": 191 },
  "80919": { "Barber": 17, "Cosmetologist": 133 },
  "80634": { "Barber": 36, "Cosmetologist": 456 },
  "80250": { "Barber": 1, "Cosmetologist": 5 },
  "80917": { "Barber": 31, "Cosmetologist": 204 },
  "80421": { "Barber": 3, "Cosmetologist": 57 },
  "80910": { "Barber": 24, "Cosmetologist": 118 },
  "73099": { "Barber": 1, "Cosmetologist": 5 },
  "80534": { "Barber": 6, "Cosmetologist": 151 },
  "80232": { "Barber": 15, "Cosmetologist": 165 },
  "81067": { "Barber": 7, "Cosmetologist": 27 },
  "80033": { "Barber": 25, "Cosmetologist": 183 },
  "80904": { "Barber": 31, "Cosmetologist": 105 },
  "80123": { "Barber": 26, "Cosmetologist": 333 },
  "97317": { "Barber": 1, "Cosmetologist": 1 },
  "81637": { "Barber": 3, "Cosmetologist": 32 },
  "81004": { "Barber": 27, "Cosmetologist": 140 },
  "80135": { "Barber": 1, "Cosmetologist": 33 },
  "81101": { "Barber": 11, "Cosmetologist": 80 },
  "12553": { "Barber": 1, "Cosmetologist": 6 },
  "81625": { "Barber": 9, "Cosmetologist": 112 },
  "80246": { "Barber": 6, "Cosmetologist": 68 },
  "80241": { "Barber": 20, "Cosmetologist": 236 },
  "81001": { "Barber": 38, "Cosmetologist": 149 },
  "81602": { "Barber": 1, "Cosmetologist": 15 },
  "80112": { "Barber": 13, "Cosmetologist": 168 },
  "80017": { "Barber": 13, "Cosmetologist": 212 },
  "64056": { "Barber": 6, "Cosmetologist": 81 },
  "80126": { "Barber": 14, "Cosmetologist": 225 },
  "80526": { "Barber": 18, "Cosmetologist": 234 },
  "80514": { "Barber": 6, "Cosmetologist": 34 },
  "80538": { "Barber": 20, "Cosmetologist": 367 },
  "80004": { "Barber": 11, "Cosmetologist": 251 },
  "81410": { "Barber": 1, "Cosmetologist": 5 },
  "80516": { "Barber": 8, "Cosmetologist": 134 },
  "80909": { "Barber": 36, "Cosmetologist": 211 },
  "80021": { "Barber": 12, "Cosmetologist": 237 },
  "80211": { "Barber": 28, "Cosmetologist": 145 },
  "80226": { "Barber": 34, "Cosmetologist": 219 },
  "80104": { "Barber": 9, "Cosmetologist": 241 },
  "81008": { "Barber": 5, "Cosmetologist": 104 },
  "53121": { "Barber": 1, "Cosmetologist": 1 },
  "80106": { "Barber": 1, "Cosmetologist": 29 },
  "80031": { "Barber": 27, "Cosmetologist": 268 },
  "80603": { "Barber": 6, "Cosmetologist": 88 },
  "80110": { "Barber": 14, "Cosmetologist": 173 },
  "21617": { "Barber": 1, "Cosmetologist": 1 },
  "80206": { "Barber": 16, "Cosmetologist": 125 },
  "80010": { "Barber": 18, "Cosmetologist": 108 },
  "80222": { "Barber": 15, "Cosmetologist": 118 },
  "80907": { "Barber": 28, "Cosmetologist": 161 },
  "81652": { "Barber": 1, "Cosmetologist": 43 },
  "80521": { "Barber": 8, "Cosmetologist": 113 },
  "86438": { "Barber": 1 },
  "81120": { "Barber": 4, "Cosmetologist": 11 },
  "81623": { "Barber": 7, "Cosmetologist": 67 },
  "81321": { "Barber": 7, "Cosmetologist": 67 },
  "80203": { "Barber": 22, "Cosmetologist": 121 },
  "81252": { "Barber": 2, "Cosmetologist": 18 },
  "81521": { "Barber": 5, "Cosmetologist": 138 },
  "88101": { "Barber": 1, "Cosmetologist": 12 },
  "80831": { "Barber": 21, "Cosmetologist": 199 },
  "81056": { "Barber": 1 },
  "80621": { "Barber": 7, "Cosmetologist": 66 },
  "81212": { "Barber": 9, "Cosmetologist": 173 },
  "81007": { "Barber": 25, "Cosmetologist": 220 },
  "80122": { "Barber": 14, "Cosmetologist": 214 },
  "81301": { "Barber": 11, "Cosmetologist": 117 },
  "81003": { "Barber": 9, "Cosmetologist": 83 },
  "80247": { "Barber": 16, "Cosmetologist": 112 },
  "80209": { "Barber": 11, "Cosmetologist": 92 },
  "80620": { "Barber": 7, "Cosmetologist": 122 },
  "80231": { "Barber": 30, "Cosmetologist": 169 },
  "80924": { "Barber": 6, "Cosmetologist": 48 },
  "80134": { "Barber": 29, "Cosmetologist": 438 },
  "80528": { "Barber": 4, "Cosmetologist": 118 },
  "81144": { "Barber": 4, "Cosmetologist": 50 },
  "80503": { "Barber": 13, "Cosmetologist": 107 },
  "80005": { "Barber": 15, "Cosmetologist": 195 },
  "80230": { "Barber": 2, "Cosmetologist": 38 },
  "80018": { "Barber": 9, "Cosmetologist": 91 },
  "80903": { "Barber": 16, "Cosmetologist": 88 },
  "80202": { "Barber": 10, "Cosmetologist": 80 },
  "80446": { "Barber": 4, "Cosmetologist": 22 },
  "53140": { "Barber": 1, "Cosmetologist": 4 },
  "81226": { "Barber": 2, "Cosmetologist": 44 },
  "80550": { "Barber": 13, "Cosmetologist": 308 },
  "81006": { "Barber": 6, "Cosmetologist": 85 },
  "80640": { "Barber": 12, "Cosmetologist": 95 },
  "80921": { "Barber": 12, "Cosmetologist": 92 },
  "80814": { "Barber": 2, "Cosmetologist": 16 },
  "80109": { "Barber": 9, "Cosmetologist": 149 },
  "81657": { "Barber": 2, "Cosmetologist": 12 },
  "81403": { "Barber": 5, "Cosmetologist": 55 },
  "80138": { "Barber": 8, "Cosmetologist": 195 },
  "80020": { "Barber": 23, "Cosmetologist": 269 },
  "80026": { "Barber": 5, "Cosmetologist": 141 },
  "80235": { "Barber": 4, "Cosmetologist": 59 },
  "81601": { "Barber": 5, "Cosmetologist": 101 },
  "81224": { "Barber": 1, "Cosmetologist": 11 },
  "80601": { "Barber": 20, "Cosmetologist": 262 },
  "28314": { "Barber": 24, "Cosmetologist": 476 },
  "80124": { "Barber": 10, "Cosmetologist": 129 },
  "80513": { "Barber": 12, "Cosmetologist": 107 },
  "78133": { "Barber": 13, "Cosmetologist": 135 },
  "28722": { "Barber": 1, "Cosmetologist": 41 },
  "80701": { "Barber": 7, "Cosmetologist": 88 },
  "80129": { "Barber": 13, "Cosmetologist": 151 },
  "91763": { "Barber": 1, "Cosmetologist": 1 },
  "38018": { "Barber": 37, "Cosmetologist": 6 },
  "80728": { "Barber": 2, "Cosmetologist": 7 },
  "81091": { "Barber": 1, "Cosmetologist": 3 },
  "80163": { "Barber": 1, "Cosmetologist": 3 },
  "81401": { "Barber": 17, "Cosmetologist": 172 },
  "81635": { "Barber": 6, "Cosmetologist": 37 },
  "85326": { "Barber": 53, "Cosmetologist": 4 },
  "80030": { "Barber": 8, "Cosmetologist": 105 },
  "81240": { "Barber": 3, "Cosmetologist": 31 },
  "80237": { "Barber": 9, "Cosmetologist": 111 },
  "80041": { "Barber": 1, "Cosmetologist": 3 },
  "80720": { "Barber": 1, "Cosmetologist": 17 },
  "80223": { "Barber": 12, "Cosmetologist": 97 },
  "80260": { "Barber": 13, "Cosmetologist": 161 },
  "81624": { "Barber": 2, "Cosmetologist": 11 },
  "98513": { "Barber": 1, "Cosmetologist": 9 },
  "80435": { "Barber": 3, "Cosmetologist": 26 },
  "81230": { "Barber": 3, "Cosmetologist": 47 },
  "81611": { "Barber": 2, "Cosmetologist": 35 },
  "87124": { "Barber": 3, "Cosmetologist": 10 },
  "80465": { "Barber": 4, "Cosmetologist": 101 },
  "67846": { "Barber": 1, "Cosmetologist": 249 },
  "80007": { "Barber": 2, "Cosmetologist": 80 },
  "80234": { "Barber": 15, "Cosmetologist": 168 },
  "80758": { "Barber": 2, "Cosmetologist": 19 },
  "80205": { "Barber": 29, "Cosmetologist": 164 },
  "13601": { "Barber": 1, "Cosmetologist": 8 },
  "89074": { "Barber": 3, "Cosmetologist": 11 },
  "29466": { "Barber": 1, "Cosmetologist": 16 },
  "81620": { "Barber": 1, "Cosmetologist": 36 },
  "81413": { "Barber": 1, "Cosmetologist": 31 },
  "80130": { "Barber": 7, "Cosmetologist": 144 },
  "87110": { "Barber": 1, "Cosmetologist": 7 },
  "80547": { "Barber": 5, "Cosmetologist": 38 },
  "80228": { "Barber": 14, "Cosmetologist": 186 },
  "89012": { "Barber": 1, "Cosmetologist": 11 },
  "81501": { "Barber": 13, "Cosmetologist": 161 },
  "80444": { "Barber": 1, "Cosmetologist": 4 },
  "84078": { "Barber": 1, "Cosmetologist": 3 },
  "90047": { "Barber": 1 },
  "80461": { "Barber": 5, "Cosmetologist": 17 },
  "81147": { "Barber": 2, "Cosmetologist": 81 },
  "80210": { "Barber": 15, "Cosmetologist": 116 },
  "80863": { "Barber": 7, "Cosmetologist": 90 },
  "81502": { "Barber": 2, "Cosmetologist": 10 },
  "80925": { "Barber": 10, "Cosmetologist": 87 },
  "80815": { "Barber": 1, "Cosmetologist": 5 },
  "40004": { "Barber": 1, "Cosmetologist": 1 },
  "80001": { "Barber": 3, "Cosmetologist": 6 },
  "86404": { "Barber": 1 },
  "81137": { "Barber": 3, "Cosmetologist": 23 },
  "81050": { "Barber": 5, "Cosmetologist": 69 },
  "85050": { "Barber": 14, "Cosmetologist": 2 },
  "43447": { "Barber": 1, "Cosmetologist": 1 },
  "80477": { "Barber": 4, "Cosmetologist": 34 },
  "09096": { "Barber": 1, "Cosmetologist": 1 },
  "74435": { "Barber": 1 },
  "80403": { "Barber": 6, "Cosmetologist": 116 },
  "80107": { "Barber": 8, "Cosmetologist": 123 },
  "80615": { "Barber": 2, "Cosmetologist": 57 },
  "80047": { "Barber": 4, "Cosmetologist": 11 },
  "81052": { "Barber": 9, "Cosmetologist": 66 },
  "80301": { "Barber": 4, "Cosmetologist": 80 },
  "81612": { "Barber": 1, "Cosmetologist": 9 },
  "80305": { "Barber": 3, "Cosmetologist": 24 },
  "80502": { "Barber": 2, "Cosmetologist": 8 },
  "57108": { "Barber": 1, "Cosmetologist": 10 },
  "81419": { "Barber": 2, "Cosmetologist": 33 },
  "88355": { "Barber": 1 },
  "80645": { "Barber": 3, "Cosmetologist": 22 },
  "81506": { "Barber": 3, "Cosmetologist": 94 },
  "80132": { "Barber": 12, "Cosmetologist": 131 },
  "13603": { "Barber": 2, "Cosmetologist": 3 },
  "78705": { "Barber": 7, "Cosmetologist": 45 },
  "73702": { "Barber": 1 },
  "76016": { "Barber": 25, "Cosmetologist": 297 },
  "78217": { "Barber": 17, "Cosmetologist": 237 },
  "80449": { "Barber": 1, "Cosmetologist": 3 },
  "80401": { "Barber": 11, "Cosmetologist": 165 },
  "87410": { "Barber": 2, "Cosmetologist": 12 },
  "80102": { "Barber": 1, "Cosmetologist": 37 },
  "80044": { "Barber": 2, "Cosmetologist": 5 },
  "80723": { "Barber": 4, "Cosmetologist": 56 },
  "68005": { "Barber": 1, "Cosmetologist": 5 },
  "97526": { "Barber": 1, "Cosmetologist": 1 },
  "49953": { "Barber": 1, "Cosmetologist": 18 },
  "77840": { "Barber": 15, "Cosmetologist": 133 },
  "62656": { "Barber": 2, "Cosmetologist": 2 },
  "72901": { "Barber": 1, "Cosmetologist": 2 },
  "81082": { "Barber": 4, "Cosmetologist": 70 },
  "44302": { "Barber": 4, "Cosmetologist": 1 },
  "81302": { "Barber": 2, "Cosmetologist": 3 },
  "80864": { "Barber": 1, "Cosmetologist": 7 },
  "80951": { "Barber": 5, "Cosmetologist": 32 },
  "87048": { "Barber": 1, "Cosmetologist": 1 },
  "97520": { "Barber": 1, "Cosmetologist": 2 },
  "78641": { "Barber": 36, "Cosmetologist": 746 },
  "81520": { "Barber": 6, "Cosmetologist": 107 },
  "69130": { "Barber": 1 },
  "80224": { "Barber": 12, "Cosmetologist": 108 },
  "80439": { "Barber": 5, "Cosmetologist": 81 },
  "81639": { "Barber": 3, "Cosmetologist": 16 },
  "80902": { "Barber": 3, "Cosmetologist": 20 },
  "67601": { "Barber": 1, "Cosmetologist": 256 },
  "80113": { "Barber": 7, "Cosmetologist": 98 },
  "81141": { "Barber": 1, "Cosmetologist": 13 },
  "68112": { "Barber": 1, "Cosmetologist": 3 },
  "81526": { "Barber": 3, "Cosmetologist": 30 },
  "90717": { "Barber": 1, "Cosmetologist": 3 },
  "50211": { "Barber": 8, "Cosmetologist": 77 },
  "67213": { "Barber": 1, "Cosmetologist": 95 },
  "80938": { "Barber": 1, "Cosmetologist": 3 },
  "76006": { "Barber": 22, "Cosmetologist": 184 },
  "80019": { "Barber": 1, "Cosmetologist": 19 },
  "57709": { "Barber": 1 },
  "80840": { "Barber": 1, "Cosmetologist": 7 },
  "69152": { "Barber": 1 },
  "84043": { "Barber": 2, "Cosmetologist": 5 },
  "72730": { "Barber": 1, "Cosmetologist": 3 },
  "61010": { "Barber": 1, "Cosmetologist": 1 },
  "80530": { "Barber": 2, "Cosmetologist": 40 },
  "77387": { "Barber": 1 },
  "96756": { "Barber": 1, "Cosmetologist": 1 },
  "79106": { "Barber": 24, "Cosmetologist": 173 },
  "80808": { "Barber": 7, "Cosmetologist": 30 },
  "80125": { "Barber": 2, "Cosmetologist": 57 },
  "78751": { "Barber": 8, "Cosmetologist": 96 },
  "82609": { "Barber": 1, "Cosmetologist": 4 },
  "96753": { "Barber": 1, "Cosmetologist": 9 },
  "80498": { "Barber": 1, "Cosmetologist": 20 },
  "81418": { "Barber": 1, "Cosmetologist": 8 },
  "80116": { "Barber": 1, "Cosmetologist": 22 },
  "78665": { "Barber": 30, "Cosmetologist": 525 },
  "65773": { "Barber": 1, "Cosmetologist": 4 },
  "85387": { "Barber": 9, "Cosmetologist": 10 },
  "80932": { "Barber": 2, "Cosmetologist": 3 },
  "92880": { "Barber": 1, "Cosmetologist": 5 },
  "80302": { "Barber": 5, "Cosmetologist": 37 },
  "80734": { "Barber": 3, "Cosmetologist": 31 },
  "43206": { "Barber": 6, "Cosmetologist": 4 },
  "68826": { "Barber": 1 },
  "80118": { "Barber": 3, "Cosmetologist": 31 },
  "90221": { "Barber": 1, "Cosmetologist": 1 },
  "96706": { "Barber": 1, "Cosmetologist": 8 },
  "81303": { "Barber": 4, "Cosmetologist": 38 },
  "78722": { "Barber": 7, "Cosmetologist": 31 },
  "81632": { "Barber": 2, "Cosmetologist": 30 },
  "57703": { "Barber": 1, "Cosmetologist": 2 },
  "80644": { "Barber": 2, "Cosmetologist": 17 },
  "80816": { "Barber": 2, "Cosmetologist": 32 },
  "60640": { "Barber": 1, "Cosmetologist": 18 },
  "81505": { "Barber": 2, "Cosmetologist": 123 },
  "81054": { "Barber": 1, "Cosmetologist": 13 },
  "01568": { "Barber": 1, "Cosmetologist": 1 },
  "80433": { "Barber": 1, "Cosmetologist": 43 },
  "80262": { "Barber": 1 },
  "81155": { "Barber": 1, "Cosmetologist": 2 },
  "48653": { "Barber": 4, "Cosmetologist": 51 },
  "80127": { "Barber": 4, "Cosmetologist": 297 },
  "80624": { "Barber": 1, "Cosmetologist": 8 },
  "80970": { "Barber": 2 },
  "81425": { "Barber": 3, "Cosmetologist": 33 },
  "80830": { "Barber": 1, "Cosmetologist": 2 },
  "80201": { "Barber": 3, "Cosmetologist": 5 },
  "79903": { "Barber": 13, "Cosmetologist": 100 },
  "80527": { "Barber": 1, "Cosmetologist": 4 },
  "81503": { "Barber": 8, "Cosmetologist": 169 },
  "81201": { "Barber": 7, "Cosmetologist": 60 },
  "72762": { "Barber": 2, "Cosmetologist": 5 },
  "80807": { "Barber": 3, "Cosmetologist": 31 },
  "92832": { "Barber": 1 },
  "80828": { "Barber": 1, "Cosmetologist": 12 },
  "85268": { "Barber": 11, "Cosmetologist": 4 },
  "29609": { "Barber": 1, "Cosmetologist": 9 },
  "80733": { "Barber": 1, "Cosmetologist": 3 },
  "80117": { "Barber": 2, "Cosmetologist": 24 },
  "47634": { "Barber": 1 },
  "86025": { "Barber": 1 },
  "96251": { "Barber": 1, "Cosmetologist": 1 },
  "77023": { "Barber": 22, "Cosmetologist": 139 },
  "62702": { "Barber": 1, "Cosmetologist": 1 },
  "97206": { "Barber": 1, "Cosmetologist": 2 },
  "80133": { "Barber": 1, "Cosmetologist": 25 },
  "57783": { "Barber": 1, "Cosmetologist": 2 },
  "81643": { "Barber": 1, "Cosmetologist": 1 },
  "80466": { "Barber": 2, "Cosmetologist": 10 },
  "76092": { "Barber": 7, "Cosmetologist": 109 },
  "79706": { "Barber": 8, "Cosmetologist": 232 },
  "73013": { "Barber": 1, "Cosmetologist": 8 },
  "82941": { "Barber": 1, "Cosmetologist": 3 },
  "81435": { "Barber": 2, "Cosmetologist": 19 },
  "81039": { "Barber": 1, "Cosmetologist": 10 },
  "74133": { "Barber": 1, "Cosmetologist": 6 },
  "73159": { "Barber": 1, "Cosmetologist": 6 },
  "80217": { "Barber": 2, "Cosmetologist": 7 },
  "23435": { "Barber": 1, "Cosmetologist": 10 },
  "15717": { "Barber": 2, "Cosmetologist": 10 },
  "83101": { "Barber": 1 },
  "85718": { "Barber": 1, "Cosmetologist": 3 },
  "81641": { "Barber": 2, "Cosmetologist": 22 },
  "60491": { "Barber": 1 },
  "57270": { "Barber": 1 },
  "87109": { "Barber": 1, "Cosmetologist": 4 },
  "86514": { "Barber": 2 },
  "79360": { "Barber": 4, "Cosmetologist": 123 },
  "66610": { "Barber": 2, "Cosmetologist": 61 },
  "39667": { "Barber": 1 },
  "79720": { "Barber": 7, "Cosmetologist": 147 },
  "87401": { "Barber": 2, "Cosmetologist": 18 },
  "87144": { "Barber": 1, "Cosmetologist": 7 },
  "81069": { "Barber": 2, "Cosmetologist": 9 },
  "96815": { "Barber": 1, "Cosmetologist": 4 },
  "92691": { "Barber": 1, "Cosmetologist": 3 },
  "85041": { "Barber": 63, "Cosmetologist": 3 },
  "80437": { "Barber": 1, "Cosmetologist": 10 },
  "80487": { "Barber": 3, "Cosmetologist": 34 },
  "81524": { "Barber": 1, "Cosmetologist": 20 },
  "81146": { "Barber": 1, "Cosmetologist": 5 },
  "80137": { "Barber": 1, "Cosmetologist": 12 },
  "80829": { "Barber": 2, "Cosmetologist": 26 },
  "20176": { "Barber": 1, "Cosmetologist": 5 },
  "80488": { "Barber": 1, "Cosmetologist": 14 },
  "77036": { "Barber": 42, "Cosmetologist": 558 },
  "11363": { "Barber": 1 },
  "85713": { "Barber": 10, "Cosmetologist": 1 },
  "21220": { "Barber": 16, "Cosmetologist": 4 },
  "93433": { "Barber": 1 },
  "18640": { "Barber": 3, "Cosmetologist": 6 },
  "80819": { "Barber": 1, "Cosmetologist": 2 },
  "03249": { "Barber": 1 },
  "81650": { "Barber": 1, "Cosmetologist": 82 },
  "60660": { "Barber": 1, "Cosmetologist": 5 },
  "98503": { "Barber": 1, "Cosmetologist": 4 },
  "28806": { "Barber": 3, "Cosmetologist": 287 },
  "86040": { "Barber": 1, "Cosmetologist": 3 },
  "80810": { "Barber": 1, "Cosmetologist": 13 },
  "81645": { "Barber": 1, "Cosmetologist": 5 },
  "81527": { "Barber": 1, "Cosmetologist": 23 },
  "80651": { "Barber": 2, "Cosmetologist": 38 },
  "65301": { "Barber": 28, "Cosmetologist": 182 },
  "81055": { "Barber": 2, "Cosmetologist": 2 },
  "84341": { "Barber": 1, "Cosmetologist": 1 },
  "87105": { "Barber": 1, "Cosmetologist": 1 },
  "81152": { "Barber": 1, "Cosmetologist": 3 },
  "80136": { "Barber": 2, "Cosmetologist": 34 },
  "67501": { "Barber": 1, "Cosmetologist": 129 },
  "80452": { "Barber": 1, "Cosmetologist": 11 },
  "80809": { "Barber": 1, "Cosmetologist": 8 },
  "80546": { "Barber": 1, "Cosmetologist": 3 },
  "83706": { "Barber": 1, "Cosmetologist": 1 },
  "80517": { "Barber": 3, "Cosmetologist": 34 },
  "92084": { "Barber": 1, "Cosmetologist": 2 },
  "81621": { "Barber": 1, "Cosmetologist": 33 },
  "75062": { "Barber": 38, "Cosmetologist": 320 },
  "63376": { "Barber": 25, "Cosmetologist": 638 },
  "80759": { "Barber": 1, "Cosmetologist": 32 },
  "55118": { "Barber": 1, "Cosmetologist": 1 },
  "79758": { "Barber": 3, "Cosmetologist": 26 },
  "84040": { "Barber": 1, "Cosmetologist": 5 },
  "44436": { "Barber": 1, "Cosmetologist": 1 },
  "83707": { "Barber": 1 },
  "61064": { "Barber": 1, "Cosmetologist": 3 },
  "80480": { "Barber": 1, "Cosmetologist": 5 },
  "81122": { "Barber": 2, "Cosmetologist": 43 },
  "92629": { "Barber": 1, "Cosmetologist": 1 },
  "67109": { "Barber": 1, "Cosmetologist": 3 },
  "47302": { "Barber": 10, "Cosmetologist": 2 },
  "87301": { "Barber": 1, "Cosmetologist": 3 },
  "78660": { "Barber": 90, "Cosmetologist": 1539 },
  "55444": { "Barber": 1, "Cosmetologist": 1 },
  "78374": { "Barber": 27, "Cosmetologist": 137 },
  "03904": { "Barber": 1, "Cosmetologist": 1 },
  "03833": { "Barber": 1, "Cosmetologist": 1 },
  "80835": { "Barber": 1, "Cosmetologist": 4 },
  "81071": { "Barber": 1, "Cosmetologist": 1 },
  "82604": { "Barber": 1, "Cosmetologist": 5 },
  "84015": { "Barber": 1, "Cosmetologist": 9 },
  "58201": { "Barber": 1, "Cosmetologist": 5 },
  "80929": { "Barber": 1, "Cosmetologist": 3 },
  "92019": { "Barber": 1, "Cosmetologist": 8 },
  "80451": { "Barber": 1, "Cosmetologist": 3 },
  "74331": { "Barber": 1, "Cosmetologist": 5 },
  "89156": { "Barber": 1 },
  "89502": { "Barber": 1, "Cosmetologist": 4 },
  "85016": { "Barber": 33, "Cosmetologist": 9 },
  "39750": { "Barber": 1 },
  "80533": { "Barber": 1, "Cosmetologist": 2 },
  "50441": { "Barber": 3, "Cosmetologist": 18 },
  "79101": { "Barber": 5, "Cosmetologist": 5 },
  "81211": { "Barber": 1, "Cosmetologist": 41 },
  "27836": { "Barber": 1, "Cosmetologist": 2 },
  "65560": { "Barber": 5, "Cosmetologist": 78 },
  "80928": { "Barber": 1, "Cosmetologist": 5 },
  "80936": { "Barber": 1, "Cosmetologist": 4 },
  "87120": { "Barber": 1, "Cosmetologist": 11 },
  "22192": { "Barber": 1, "Cosmetologist": 7 },
  "47713": { "Barber": 12 },
  "59105": { "Barber": 1, "Cosmetologist": 3 },
  "80043": { "Barber": 1 },
  "81402": { "Barber": 1, "Cosmetologist": 5 },
  "80933": { "Barber": 1, "Cosmetologist": 4 },
  "60559": { "Barber": 1 },
  "80303": { "Barber": 1, "Cosmetologist": 43 },
  "80825": { "Barber": 1, "Cosmetologist": 4 },
  "85715": { "Barber": 2, "Cosmetologist": 2 },
  "79912": { "Barber": 31, "Cosmetologist": 422 },
  "80643": { "Barber": 1, "Cosmetologist": 27 },
  "81432": { "Barber": 1, "Cosmetologist": 12 },
  "44113": { "Barber": 4, "Cosmetologist": 108 },
  "35805": { "Barber": 1, "Cosmetologist": 4 },
  "75143": { "Barber": 12, "Cosmetologist": 88 },
  "81325": { "Barber": 1, "Cosmetologist": 1 },
  "80542": { "Barber": 1, "Cosmetologist": 45 },
  "82801": { "Barber": 1, "Cosmetologist": 7 },
  "79414": { "Barber": 14, "Cosmetologist": 114 },
  "57785": { "Barber": 1, "Cosmetologist": 2 },
  "11368": { "Barber": 1, "Cosmetologist": 2 },
  "73112": { "Barber": 1, "Cosmetologist": 5 },
  "37214": { "Barber": 34 },
  "64055": { "Barber": 22, "Cosmetologist": 202 },
  "40202": { "Barber": 1, "Cosmetologist": 3 },
  "80535": { "Barber": 1, "Cosmetologist": 9 },
  "80913": { "Barber": 1, "Cosmetologist": 2 },
  "75165": { "Barber": 53, "Cosmetologist": 448 },
  "97138": { "Barber": 1, "Cosmetologist": 1 },
  "80006": { "Barber": 1, "Cosmetologist": 7 },
  "80540": { "Barber": 1, "Cosmetologist": 14 },
  "81326": { "Barber": 1, "Cosmetologist": 15 },
  "80467": { "Barber": 1, "Cosmetologist": 16 },
  "85742": { "Barber": 4, "Cosmetologist": 6 },
  "01453": { "Barber": 1 },
  "80520": { "Barber": 1, "Cosmetologist": 21 },
  "92708": { "Barber": 1, "Cosmetologist": 6 },
  "77429": { "Barber": 34, "Cosmetologist": 788 },
  "70563": { "Barber": 1, "Cosmetologist": 2 },
  "87047": { "Barber": 1, "Cosmetologist": 1 },
  "83814": { "Cosmetologist": 6 },
  "22304": { "Cosmetologist": 4 },
  "81323": { "Cosmetologist": 26 },
  "82082": { "Cosmetologist": 2 },
  "61201": { "Cosmetologist": 50 },
  "80442": { "Cosmetologist": 10 },
  "81324": { "Cosmetologist": 13 },
  "81244": { "Cosmetologist": 7 },
  "81077": { "Cosmetologist": 5 },
  "84057": { "Cosmetologist": 3 },
  "89503": { "Cosmetologist": 2 },
  "07442": { "Cosmetologist": 77, "Barber": 14 },
  "46060": { "Cosmetologist": 7, "Barber": 18 },
  "55407": { "Cosmetologist": 2 },
  "80155": { "Cosmetologist": 4 },
  "63367": { "Cosmetologist": 206, "Barber": 3 },
  "75167": { "Cosmetologist": 119, "Barber": 13 },
  "89249": { "Cosmetologist": 1 },
  "80470": { "Cosmetologist": 16 },
  "16801": { "Cosmetologist": 19, "Barber": 1 },
  "81223": { "Cosmetologist": 8 },
  "71446": { "Cosmetologist": 4 },
  "77598": { "Cosmetologist": 164, "Barber": 17 },
  "49101": { "Cosmetologist": 28, "Barber": 3 },
  "81507": { "Cosmetologist": 100 },
  "85006": { "Cosmetologist": 4, "Barber": 19 },
  "66609": { "Cosmetologist": 47 },
  "52601": { "Cosmetologist": 136, "Barber": 9 },
  "80536": { "Cosmetologist": 11 },
  "10027": { "Cosmetologist": 8 },
  "87507": { "Cosmetologist": 6 },
  "98290": { "Cosmetologist": 1 },
  "75020": { "Cosmetologist": 170, "Barber": 13 },
  "56464": { "Cosmetologist": 1 },
  "80424": { "Cosmetologist": 36 },
  "85395": { "Cosmetologist": 10, "Barber": 23 },
  "85201": { "Cosmetologist": 5, "Barber": 43 },
  "98105": { "Cosmetologist": 3 },
  "60560": { "Cosmetologist": 5 },
  "85204": { "Cosmetologist": 6, "Barber": 54 },
  "80482": { "Cosmetologist": 9 },
  "85383": { "Cosmetologist": 13, "Barber": 25 },
  "61072": { "Cosmetologist": 3 },
  "80454": { "Cosmetologist": 9 },
  "52635": { "Cosmetologist": 6 },
  "81063": { "Cosmetologist": 7 },
  "17901": { "Cosmetologist": 18, "Barber": 3 },
  "20639": { "Cosmetologist": 4 },
  "95926": { "Cosmetologist": 1 },
  "80474": { "Cosmetologist": 4 },
  "87176": { "Cosmetologist": 1 },
  "85140": { "Cosmetologist": 6, "Barber": 12 },
  "80463": { "Cosmetologist": 1 },
  "12306": { "Cosmetologist": 3 },
  "91767": { "Cosmetologist": 1 },
  "81431": { "Cosmetologist": 6 },
  "29902": { "Cosmetologist": 9 },
  "97229": { "Cosmetologist": 5 },
  "59602": { "Cosmetologist": 6 },
  "98311": { "Cosmetologist": 6 },
  "80614": { "Cosmetologist": 3 },
  "38488": { "Cosmetologist": 1 },
  "80866": { "Cosmetologist": 13 },
  "80162": { "Cosmetologist": 1 },
  "81647": { "Cosmetologist": 36 },
  "89506": { "Cosmetologist": 1 },
  "80623": { "Cosmetologist": 7 },
  "82331": { "Cosmetologist": 5 },
  "07204": { "Cosmetologist": 88, "Barber": 44 },
  "81423": { "Cosmetologist": 9 },
  "87122": { "Cosmetologist": 3 },
  "67871": { "Cosmetologist": 47 },
  "55427": { "Cosmetologist": 3 },
  "81140": { "Cosmetologist": 7 },
  "81424": { "Cosmetologist": 7 },
  "84107": { "Cosmetologist": 2 },
  "85248": { "Cosmetologist": 10, "Barber": 18 },
  "75206": { "Cosmetologist": 245, "Barber": 22 },
  "81062": { "Cosmetologist": 3 },
  "29651": { "Cosmetologist": 30 },
  "73750": { "Cosmetologist": 1 },
  "80832": { "Cosmetologist": 2 },
  "41048": { "Cosmetologist": 4 },
  "82001": { "Cosmetologist": 26 },
  "80648": { "Cosmetologist": 11 },
  "85051": { "Cosmetologist": 4, "Barber": 63 },
  "80512": { "Cosmetologist": 7 },
  "78759": { "Cosmetologist": 237, "Barber": 22 },
  "74083": { "Cosmetologist": 1 },
  "75044": { "Cosmetologist": 1497, "Barber": 40 },
  "80820": { "Cosmetologist": 3 },
  "60431": { "Cosmetologist": 2 },
  "81020": { "Cosmetologist": 4 },
  "80610": { "Cosmetologist": 29 },
  "83617": { "Cosmetologist": 2 },
  "80522": { "Cosmetologist": 4 },
  "58831": { "Cosmetologist": 1 },
  "31806": { "Cosmetologist": 1 },
  "98284": { "Cosmetologist": 2 },
  "21229": { "Cosmetologist": 4, "Barber": 35 },
  "60510": { "Cosmetologist": 27 },
  "92833": { "Cosmetologist": 3 },
  "56011": { "Cosmetologist": 3 },
  "85205": { "Cosmetologist": 6, "Barber": 21 },
  "76210": { "Cosmetologist": 441, "Barber": 23 },
  "53562": { "Cosmetologist": 4 },
  "95634": { "Cosmetologist": 1 },
  "89015": { "Cosmetologist": 10 },
  "81631": { "Cosmetologist": 39 },
  "63368": { "Cosmetologist": 302, "Barber": 7 },
  "97824": { "Cosmetologist": 1 },
  "76052": { "Cosmetologist": 266, "Barber": 21 },
  "94596": { "Cosmetologist": 3 },
  "80428": { "Cosmetologist": 5 },
  "52205": { "Cosmetologist": 39, "Barber": 1 },
  "80103": { "Cosmetologist": 16 },
  "79109": { "Cosmetologist": 314, "Barber": 35 },
  "81076": { "Cosmetologist": 5 },
  "82007": { "Cosmetologist": 14 },
  "69357": { "Cosmetologist": 1 },
  "18976": { "Cosmetologist": 12, "Barber": 2 },
  "89117": { "Cosmetologist": 17 },
  "54481": { "Cosmetologist": 4 },
  "80308": { "Cosmetologist": 3 },
  "88005": { "Cosmetologist": 1 },
  "80731": { "Cosmetologist": 16 },
  "27028": { "Cosmetologist": 150, "Barber": 1 },
  "97408": { "Cosmetologist": 3 },
  "82072": { "Cosmetologist": 7 },
  "81630": { "Cosmetologist": 6 },
  "85032": { "Cosmetologist": 7, "Barber": 55 },
  "85206": { "Cosmetologist": 11, "Barber": 24 },
  "39426": { "Cosmetologist": 3 },
  "78633": { "Cosmetologist": 128, "Barber": 10 },
  "86336": { "Cosmetologist": 3 },
  "95361": { "Cosmetologist": 2 },
  "28704": { "Cosmetologist": 156 },
  "77433": { "Cosmetologist": 1093, "Barber": 62 },
  "85939": { "Cosmetologist": 1 },
  "84097": { "Cosmetologist": 1 },
  "55408": { "Cosmetologist": 4 },
  "85210": { "Cosmetologist": 10, "Barber": 39 },
  "65201": { "Cosmetologist": 110, "Barber": 11 },
  "79707": { "Cosmetologist": 251, "Barber": 18 },
  "59860": { "Cosmetologist": 1 },
  "98424": { "Cosmetologist": 2 },
  "80960": { "Cosmetologist": 1 },
  "92822": { "Cosmetologist": 1 },
  "53205": { "Cosmetologist": 1 },
  "80440": { "Cosmetologist": 10 },
  "95602": { "Cosmetologist": 1 },
  "85143": { "Cosmetologist": 12, "Barber": 27 },
  "81626": { "Cosmetologist": 17 },
  "78418": { "Cosmetologist": 165, "Barber": 13 },
  "70816": { "Cosmetologist": 6 },
  "62439": { "Cosmetologist": 1 },
  "81655": { "Cosmetologist": 5 },
  "42164": { "Cosmetologist": 5 },
  "48317": { "Cosmetologist": 260, "Barber": 6 },
  "80105": { "Cosmetologist": 13 },
  "80544": { "Cosmetologist": 3 },
  "53224": { "Cosmetologist": 1 },
  "49930": { "Cosmetologist": 25 },
  "81151": { "Cosmetologist": 10 },
  "62441": { "Cosmetologist": 1 },
  "81132": { "Cosmetologist": 17 },
  "73160": { "Cosmetologist": 5 },
  "92591": { "Cosmetologist": 5 },
  "80042": { "Cosmetologist": 7 },
  "80654": { "Cosmetologist": 32 },
  "23801": { "Cosmetologist": 3 },
  "84660": { "Cosmetologist": 3 },
  "77498": { "Cosmetologist": 672, "Barber": 32 },
  "74013": { "Cosmetologist": 1 },
  "80035": { "Cosmetologist": 4 },
  "81210": { "Cosmetologist": 1 },
  "97003": { "Cosmetologist": 5 },
  "83001": { "Cosmetologist": 2 },
  "89128": { "Cosmetologist": 11 },
  "25705": { "Cosmetologist": 2 },
  "81253": { "Cosmetologist": 1 },
  "67637": { "Cosmetologist": 37 },
  "81525": { "Cosmetologist": 9 },
  "80459": { "Cosmetologist": 11 },
  "66207": { "Cosmetologist": 52 },
  "81090": { "Cosmetologist": 5 },
  "97496": { "Cosmetologist": 1 },
  "44706": { "Cosmetologist": 1, "Barber": 4 },
  "80743": { "Cosmetologist": 9 },
  "70002": { "Cosmetologist": 1 },
  "85750": { "Cosmetologist": 5 },
  "80046": { "Cosmetologist": 5 },
  "37167": { "Cosmetologist": 11, "Barber": 90 },
  "98665": { "Cosmetologist": 2 },
  "80742": { "Cosmetologist": 3 },
  "85255": { "Cosmetologist": 10, "Barber": 10 },
  "59102": { "Cosmetologist": 9 },
  "81073": { "Cosmetologist": 13 },
  "83716": { "Cosmetologist": 3 },
  "77554": { "Cosmetologist": 44, "Barber": 4 },
  "99517": { "Cosmetologist": 5 },
  "43571": { "Cosmetologist": 2 },
  "83448": { "Cosmetologist": 1 },
  "52806": { "Cosmetologist": 121, "Barber": 5 },
  "81415": { "Cosmetologist": 7 },
  "44122": { "Cosmetologist": 3, "Barber": 8 },
  "80823": { "Cosmetologist": 2 },
  "77868": { "Cosmetologist": 95, "Barber": 14 },
  "64089": { "Cosmetologist": 115, "Barber": 2 },
  "03054": { "Cosmetologist": 1 },
  "67877": { "Cosmetologist": 19 },
  "60586": { "Cosmetologist": 5 },
  "61350": { "Cosmetologist": 5 },
  "83401": { "Cosmetologist": 1 },
  "46062": { "Cosmetologist": 5, "Barber": 13 },
  "49117": { "Cosmetologist": 12, "Barber": 1 },
  "84042": { "Cosmetologist": 2 },
  "52402": { "Cosmetologist": 160, "Barber": 10 },
  "70570": { "Cosmetologist": 2 },
  "98112": { "Cosmetologist": 1 },
  "58750": { "Cosmetologist": 1 },
  "53572": { "Cosmetologist": 2 },
  "76087": { "Cosmetologist": 343, "Barber": 18 },
  "45044": { "Cosmetologist": 9, "Barber": 19 },
  "69201": { "Cosmetologist": 1 },
  "77339": { "Cosmetologist": 319, "Barber": 21 },
  "85253": { "Cosmetologist": 3, "Barber": 4 },
  "66936": { "Cosmetologist": 2 },
  "11725": { "Cosmetologist": 2 },
  "95682": { "Cosmetologist": 3 },
  "55441": { "Cosmetologist": 4 },
  "76135": { "Cosmetologist": 176, "Barber": 8 },
  "63011": { "Cosmetologist": 194, "Barber": 6 },
  "80650": { "Cosmetologist": 16 },
  "80306": { "Cosmetologist": 3 },
  "57769": { "Cosmetologist": 2 },
  "85087": { "Cosmetologist": 3 },
  "28409": { "Cosmetologist": 201, "Barber": 2 },
  "57073": { "Cosmetologist": 1 },
  "92506": { "Cosmetologist": 2 },
  "98569": { "Cosmetologist": 2 },
  "85269": { "Cosmetologist": 1 },
  "75773": { "Cosmetologist": 94, "Barber": 11 },
  "75835": { "Cosmetologist": 61, "Barber": 11 },
  "77479": { "Cosmetologist": 488, "Barber": 15 },
  "55125": { "Cosmetologist": 5 },
  "99507": { "Cosmetologist": 7 },
  "92679": { "Cosmetologist": 4 },
  "85013": { "Cosmetologist": 2, "Barber": 1 },
  "37075": { "Cosmetologist": 18, "Barber": 44 },
  "80150": { "Cosmetologist": 4 },
  "80000": { "Cosmetologist": 1 },
  "80438": { "Cosmetologist": 1 },
  "80427": { "Cosmetologist": 1 },
  "28210": { "Cosmetologist": 246, "Barber": 2 },
  "85730": { "Cosmetologist": 3, "Barber": 6 },
  "07823": { "Cosmetologist": 47, "Barber": 10 },
  "21225": { "Cosmetologist": 3, "Barber": 24 },
  "89129": { "Cosmetologist": 7 },
  "69334": { "Cosmetologist": 2 },
  "86326": { "Cosmetologist": 2 },
  "88220": { "Cosmetologist": 2 },
  "47909": { "Cosmetologist": 3, "Barber": 11 },
  "57042": { "Cosmetologist": 3 },
  "82009": { "Cosmetologist": 19 },
  "80515": { "Cosmetologist": 2 },
  "72113": { "Cosmetologist": 4 },
  "67230": { "Cosmetologist": 83 },
  "84106": { "Cosmetologist": 2 },
  "82930": { "Cosmetologist": 1 },
  "20774": { "Cosmetologist": 7 },
  "80836": { "Cosmetologist": 10 },
  "97015": { "Cosmetologist": 2 },
  "80633": { "Cosmetologist": 4 },
  "52804": { "Cosmetologist": 108, "Barber": 12 },
  "68069": { "Cosmetologist": 1 },
  "92129": { "Cosmetologist": 3 },
  "74370": { "Cosmetologist": 9 },
  "81428": { "Cosmetologist": 23 },
  "87402": { "Cosmetologist": 2 },
  "78643": { "Cosmetologist": 58, "Barber": 3 },
  "92551": { "Cosmetologist": 2 },
  "81019": { "Cosmetologist": 8 },
  "66762": { "Cosmetologist": 143 },
  "73667": { "Cosmetologist": 1 },
  "51106": { "Cosmetologist": 113, "Barber": 4 },
  "95670": { "Cosmetologist": 4 },
  "54853": { "Cosmetologist": 1 },
  "85015": { "Cosmetologist": 2, "Barber": 47 },
  "80741": { "Cosmetologist": 8 },
  "92107": { "Cosmetologist": 4 },
  "74017": { "Cosmetologist": 5 },
  "71075": { "Cosmetologist": 1 },
  "56058": { "Cosmetologist": 1 },
  "51401": { "Cosmetologist": 89, "Barber": 4 },
  "69350": { "Cosmetologist": 2 },
  "57745": { "Cosmetologist": 1 },
  "85212": { "Cosmetologist": 5, "Barber": 11 },
  "93065": { "Cosmetologist": 10 },
  "81128": { "Cosmetologist": 1 },
  "38133": { "Cosmetologist": 3, "Barber": 14 },
  "81658": { "Cosmetologist": 6 },
  "74330": { "Cosmetologist": 1 },
  "80612": { "Cosmetologist": 5 },
  "80471": { "Cosmetologist": 1 },
  "61771": { "Cosmetologist": 1 },
  "80642": { "Cosmetologist": 28 },
  "36526": { "Cosmetologist": 18 },
  "55008": { "Cosmetologist": 1 },
  "66215": { "Cosmetologist": 136, "Barber": 2 },
  "38680": { "Cosmetologist": 1 },
  "60655": { "Cosmetologist": 1 },
  "58540": { "Cosmetologist": 1 },
  "77407": { "Cosmetologist": 1263, "Barber": 47 },
  "84601": { "Cosmetologist": 3 },
  "75074": { "Cosmetologist": 415, "Barber": 28 },
  "75650": { "Cosmetologist": 81, "Barber": 5 },
  "63033": { "Cosmetologist": 317, "Barber": 66 },
  "55376": { "Cosmetologist": 2 },
  "36604": { "Cosmetologist": 3 },
  "95215": { "Cosmetologist": 1 },
  "57701": { "Cosmetologist": 5 },
  "81143": { "Cosmetologist": 4 },
  "80457": { "Cosmetologist": 6 },
  "15656": { "Cosmetologist": 14, "Barber": 2 },
  "89149": { "Cosmetologist": 9, "Barber": 1 },
  "23188": { "Cosmetologist": 4 },
  "99901": { "Cosmetologist": 2 },
  "84780": { "Cosmetologist": 5 },
  "78163": { "Cosmetologist": 119, "Barber": 7 },
  "38570": { "Cosmetologist": 4, "Barber": 18 },
  "27604": { "Cosmetologist": 369, "Barber": 2 },
  "80737": { "Cosmetologist": 14 },
  "81235": { "Cosmetologist": 4 },
  "46307": { "Cosmetologist": 9, "Barber": 47 },
  "69153": { "Cosmetologist": 6 },
  "92021": { "Cosmetologist": 1 },
  "81328": { "Cosmetologist": 13 },
  "71913": { "Cosmetologist": 7 },
  "93436": { "Cosmetologist": 2 },
  "79086": { "Cosmetologist": 17, "Barber": 2 },
  "01002": { "Cosmetologist": 1, "Barber": 4 },
  "93277": { "Cosmetologist": 4 },
  "84713": { "Cosmetologist": 1 },
  "83634": { "Cosmetologist": 2 },
  "78634": { "Cosmetologist": 354, "Barber": 33 },
  "68845": { "Cosmetologist": 4 },
  "64733": { "Cosmetologist": 11 },
  "85375": { "Cosmetologist": 14 },
  "80182": { "Cosmetologist": 1 },
  "80935": { "Cosmetologist": 1 },
  "87104": { "Cosmetologist": 1 },
  "78611": { "Cosmetologist": 114, "Barber": 5 },
  "27529": { "Cosmetologist": 369, "Barber": 3 },
  "77401": { "Cosmetologist": 50, "Barber": 3 },
  "85142": { "Cosmetologist": 14, "Barber": 24 },
  "98662": { "Cosmetologist": 2 },
  "11221": { "Cosmetologist": 9 },
  "93420": { "Cosmetologist": 1 },
  "75019": { "Cosmetologist": 188, "Barber": 16 },
  "92111": { "Cosmetologist": 7 },
  "49307": { "Cosmetologist": 100, "Barber": 5 },
  "77535": { "Cosmetologist": 235, "Barber": 14 },
  "17578": { "Cosmetologist": 6 },
  "85338": { "Cosmetologist": 14, "Barber": 55 },
  "85614": { "Cosmetologist": 7, "Barber": 1 },
  "84020": { "Cosmetologist": 5 },
  "08225": { "Cosmetologist": 63, "Barber": 18 },
  "61101": { "Cosmetologist": 2 },
  "57109": { "Cosmetologist": 2 },
  "48130": { "Cosmetologist": 83, "Barber": 5 },
  "85396": { "Cosmetologist": 10, "Barber": 27 },
  "80318": { "Cosmetologist": 1 },
  "29710": { "Cosmetologist": 127 },
  "63051": { "Cosmetologist": 105, "Barber": 1 },
  "29407": { "Cosmetologist": 11 },
  "90503": { "Cosmetologist": 4 },
  "44064": { "Cosmetologist": 2 },
  "64082": { "Cosmetologist": 120, "Barber": 11 },
  "74136": { "Cosmetologist": 2 },
  "60025": { "Cosmetologist": 3 },
  "85704": { "Cosmetologist": 5 },
  "97305": { "Cosmetologist": 4, "Barber": 1 },
  "78644": { "Cosmetologist": 122, "Barber": 22 },
  "89178": { "Cosmetologist": 13 },
  "86401": { "Cosmetologist": 4 },
  "99224": { "Cosmetologist": 4 },
  "81236": { "Cosmetologist": 2 },
  "67203": { "Cosmetologist": 180 },
  "87747": { "Cosmetologist": 2 },
  "07748": { "Cosmetologist": 140, "Barber": 20 },
  "65714": { "Cosmetologist": 224, "Barber": 19 },
  "39553": { "Cosmetologist": 3 },
  "76244": { "Cosmetologist": 842, "Barber": 39 },
  "27527": { "Cosmetologist": 170, "Barber": 1 },
  "81089": { "Cosmetologist": 16 },
  "71486": { "Cosmetologist": 1 },
  "73012": { "Cosmetologist": 4 },
  "85746": { "Cosmetologist": 3, "Barber": 13 },
  "48082": { "Cosmetologist": 192, "Barber": 13 },
  "80290": { "Cosmetologist": 1 },
  "84403": { "Cosmetologist": 2 },
  "78737": { "Cosmetologist": 111, "Barber": 4 },
  "46825": { "Cosmetologist": 7, "Barber": 15 },
  "48357": { "Cosmetologist": 77, "Barber": 5 },
  "09630": { "Cosmetologist": 1 },
  "58849": { "Cosmetologist": 1 },
  "85209": { "Cosmetologist": 14, "Barber": 17 },
  "22406": { "Cosmetologist": 6 },
  "63025": { "Cosmetologist": 131, "Barber": 3 },
  "98433": { "Cosmetologist": 5 },
  "75219": { "Cosmetologist": 276, "Barber": 19 },
  "98901": { "Cosmetologist": 1 },
  "68407": { "Cosmetologist": 1 },
  "97064": { "Cosmetologist": 1 },
  "98026": { "Cosmetologist": 4 },
  "85053": { "Cosmetologist": 6, "Barber": 37 },
  "91405": { "Cosmetologist": 6 },
  "82601": { "Cosmetologist": 9 },
  "64014": { "Cosmetologist": 165, "Barber": 14 },
  "89431": { "Cosmetologist": 2 },
  "78681": { "Cosmetologist": 298, "Barber": 18 },
  "51575": { "Cosmetologist": 5 },
  "48097": { "Cosmetologist": 42, "Barber": 3 },
  "66049": { "Cosmetologist": 158 },
  "91354": { "Cosmetologist": 2 },
  "89436": { "Cosmetologist": 5 },
  "64150": { "Cosmetologist": 18, "Barber": 1 },
  "83501": { "Cosmetologist": 3 },
  "84041": { "Cosmetologist": 7 },
  "65656": { "Cosmetologist": 32, "Barber": 4 },
  "38134": { "Cosmetologist": 6, "Barber": 45 },
  "75402": { "Cosmetologist": 144, "Barber": 6 },
  "92606": { "Cosmetologist": 2 },
  "43123": { "Cosmetologist": 14, "Barber": 36 },
  "69356": { "Cosmetologist": 1 },
  "89102": { "Cosmetologist": 9 },
  "73505": { "Cosmetologist": 13 },
  "80705": { "Cosmetologist": 5 },
  "76205": { "Cosmetologist": 136, "Barber": 10 },
  "97219": { "Cosmetologist": 4 },
  "85331": { "Cosmetologist": 10, "Barber": 12 },
  "40206": { "Cosmetologist": 2 },
  "90278": { "Cosmetologist": 3 },
  "55360": { "Cosmetologist": 1 },
  "85226": { "Cosmetologist": 10, "Barber": 24 },
  "85019": { "Cosmetologist": 2, "Barber": 22 },
  "92108": { "Cosmetologist": 3 },
  "87015": { "Cosmetologist": 2 },
  "25530": { "Cosmetologist": 1 },
  "84337": { "Cosmetologist": 1 },
  "29492": { "Cosmetologist": 7 },
  "60451": { "Cosmetologist": 3 },
  "21113": { "Cosmetologist": 7 },
  "66106": { "Cosmetologist": 104, "Barber": 2 },
  "89135": { "Cosmetologist": 5 },
  "39531": { "Cosmetologist": 6 },
  "49509": { "Cosmetologist": 204, "Barber": 3 },
  "77901": { "Cosmetologist": 340, "Barber": 34 },
  "66086": { "Cosmetologist": 87 },
  "93551": { "Cosmetologist": 4 },
  "14043": { "Cosmetologist": 1 },
  "76262": { "Cosmetologist": 307, "Barber": 7 },
  "99354": { "Cosmetologist": 4 },
  "82710": { "Cosmetologist": 1 },
  "90025": { "Cosmetologist": 6 },
  "07747": { "Cosmetologist": 173, "Barber": 28 },
  "37062": { "Cosmetologist": 3, "Barber": 5 },
  "87111": { "Cosmetologist": 7 },
  "07675": { "Cosmetologist": 118, "Barber": 28 },
  "59644": { "Cosmetologist": 1 },
  "80456": { "Cosmetologist": 3 },
  "49014": { "Cosmetologist": 111, "Barber": 3 },
  "55901": { "Cosmetologist": 5 },
  "83201": { "Cosmetologist": 1 },
  "94123": { "Cosmetologist": 2 },
  "08005": { "Cosmetologist": 179, "Barber": 42 },
  "73065": { "Cosmetologist": 3 },
  "90720": { "Cosmetologist": 1 },
  "82633": { "Cosmetologist": 2 },
  "67212": { "Cosmetologist": 335 },
  "74008": { "Cosmetologist": 6 },
  "78130": { "Cosmetologist": 836, "Barber": 42 },
  "28539": { "Cosmetologist": 119, "Barber": 1 },
  "80813": { "Cosmetologist": 8 },
  "83113": { "Cosmetologist": 1 },
  "59421": { "Cosmetologist": 1 },
  "87939": { "Cosmetologist": 1 },
  "89123": { "Cosmetologist": 11, "Barber": 1 },
  "80038": { "Cosmetologist": 4 },
  "78223": { "Cosmetologist": 371, "Barber": 31 },
  "80024": { "Cosmetologist": 1 },
  "50322": { "Cosmetologist": 163, "Barber": 11 },
  "81434": { "Cosmetologist": 2 },
  "64079": { "Cosmetologist": 69, "Barber": 3 },
  "85086": { "Cosmetologist": 15, "Barber": 10 },
  "81642": { "Cosmetologist": 1 },
  "60563": { "Cosmetologist": 6 },
  "92234": { "Cosmetologist": 4 },
  "01462": { "Cosmetologist": 1 },
  "46819": { "Cosmetologist": 2, "Barber": 11 },
  "87002": { "Cosmetologist": 2 },
  "80722": { "Cosmetologist": 4 },
  "52807": { "Cosmetologist": 53, "Barber": 2 },
  "53716": { "Cosmetologist": 2 },
  "81154": { "Cosmetologist": 5 },
  "83338": { "Cosmetologist": 1 },
  "61491": { "Cosmetologist": 1 },
  "84009": { "Cosmetologist": 4 },
  "81064": { "Cosmetologist": 2 },
  "22043": { "Cosmetologist": 1 },
  "81233": { "Cosmetologist": 2 },
  "67117": { "Cosmetologist": 14 },
  "86442": { "Cosmetologist": 5 },
  "92104": { "Cosmetologist": 5 },
  "46845": { "Cosmetologist": 8, "Barber": 12 },
  "79936": { "Cosmetologist": 801, "Barber": 75 },
  "80653": { "Cosmetologist": 7 },
  "80821": { "Cosmetologist": 7 },
  "80715": { "Cosmetologist": 1 },
  "81024": { "Cosmetologist": 2 },
  "81036": { "Cosmetologist": 5 },
  "51534": { "Cosmetologist": 30 },
  "66102": { "Cosmetologist": 106, "Barber": 4 },
  "81522": { "Cosmetologist": 3 },
  "83605": { "Cosmetologist": 5 },
  "27549": { "Cosmetologist": 127 },
  "06110": { "Cosmetologist": 1 },
  "88012": { "Cosmetologist": 7 },
  "97013": { "Cosmetologist": 2 },
  "48309": { "Cosmetologist": 140, "Barber": 4 },
  "81047": { "Cosmetologist": 10 },
  "77089": { "Cosmetologist": 1250, "Barber": 37 },
  "28715": { "Cosmetologist": 169, "Barber": 1 },
  "95632": { "Cosmetologist": 1 },
  "76424": { "Cosmetologist": 52, "Barber": 4 },
  "80422": { "Cosmetologist": 23 },
  "99205": { "Cosmetologist": 3 },
  "45895": { "Cosmetologist": 3 },
  "64152": { "Cosmetologist": 154, "Barber": 4 },
  "36117": { "Cosmetologist": 13 },
  "65072": { "Cosmetologist": 8 },
  "71457": { "Cosmetologist": 3 },
  "71067": { "Cosmetologist": 1 },
  "99207": { "Cosmetologist": 1 },
  "74012": { "Cosmetologist": 10 },
  "40162": { "Cosmetologist": 3 },
  "96786": { "Cosmetologist": 10 },
  "73651": { "Cosmetologist": 2 },
  "77494": { "Cosmetologist": 990, "Barber": 31 },
  "15701": { "Cosmetologist": 15, "Barber": 1 },
  "10502": { "Cosmetologist": 1 },
  "97302": { "Cosmetologist": 4 },
  "71112": { "Cosmetologist": 3 },
  "69162": { "Cosmetologist": 2 },
  "95112": { "Cosmetologist": 2 },
  "85138": { "Cosmetologist": 6, "Barber": 29 },
  "07853": { "Cosmetologist": 52, "Barber": 2 },
  "76040": { "Cosmetologist": 251, "Barber": 24 },
  "86335": { "Cosmetologist": 2 },
  "82070": { "Cosmetologist": 4 },
  "53051": { "Cosmetologist": 3 },
  "85029": { "Cosmetologist": 5, "Barber": 37 },
  "84604": { "Cosmetologist": 2 },
  "12514": { "Cosmetologist": 1 },
  "89147": { "Cosmetologist": 17 },
  "99610": { "Cosmetologist": 1 },
  "80476": { "Cosmetologist": 1 },
  "48312": { "Cosmetologist": 284, "Barber": 30 },
  "83232": { "Cosmetologist": 1 },
  "83287": { "Cosmetologist": 1 },
  "81845": { "Cosmetologist": 1 },
  "57103": { "Cosmetologist": 6 },
  "80213": { "Cosmetologist": 1 },
  "18458": { "Cosmetologist": 11 },
  "33036": { "Cosmetologist": 40 },
  "50314": { "Cosmetologist": 25, "Barber": 7 },
  "83002": { "Cosmetologist": 5 },
  "29322": { "Cosmetologist": 9 },
  "82901": { "Cosmetologist": 7 },
  "86413": { "Cosmetologist": 3 },
  "97030": { "Cosmetologist": 3 },
  "70507": { "Cosmetologist": 1 },
  "81092": { "Cosmetologist": 6 },
  "83646": { "Cosmetologist": 9 },
  "98027": { "Cosmetologist": 3 },
  "84090": { "Cosmetologist": 1 },
  "91701": { "Cosmetologist": 1 },
  "98178": { "Cosmetologist": 4 },
  "81220": { "Cosmetologist": 2 },
  "97224": { "Cosmetologist": 3 },
  "28269": { "Cosmetologist": 581, "Barber": 7 },
  "12520": { "Cosmetologist": 3 },
  "95111": { "Cosmetologist": 4 },
  "83686": { "Cosmetologist": 6 },
  "14033": { "Cosmetologist": 2 },
  "79705": { "Cosmetologist": 361, "Barber": 15 },
  "97402": { "Cosmetologist": 2 },
  "90034": { "Cosmetologist": 4 },
  "66062": { "Cosmetologist": 473, "Barber": 4 },
  "99709": { "Cosmetologist": 5 },
  "49506": { "Cosmetologist": 139, "Barber": 5 },
  "47201": { "Cosmetologist": 4, "Barber": 14 },
  "67420": { "Cosmetologist": 35 },
  "83709": { "Cosmetologist": 9 },
  "40379": { "Cosmetologist": 2 },
  "79416": { "Cosmetologist": 219, "Barber": 33 },
  "50317": { "Cosmetologist": 131, "Barber": 15 },
  "73945": { "Cosmetologist": 1 },
  "08559": { "Cosmetologist": 28, "Barber": 4 },
  "72751": { "Cosmetologist": 3 },
  "69168": { "Cosmetologist": 1 },
  "28173": { "Cosmetologist": 296 },
  "59801": { "Cosmetologist": 2 },
  "85258": { "Cosmetologist": 7, "Barber": 7 },
  "59752": { "Cosmetologist": 3 },
  "90005": { "Cosmetologist": 2 },
  "76542": { "Cosmetologist": 446, "Barber": 68 },
  "80926": { "Cosmetologist": 6 },
  "51104": { "Cosmetologist": 83, "Barber": 2 },
  "21237": { "Cosmetologist": 6, "Barber": 12 },
  "57005": { "Cosmetologist": 5 },
  "76230": { "Cosmetologist": 99, "Barber": 8 },
  "06484": { "Cosmetologist": 1 },
  "74033": { "Cosmetologist": 3 },
  "90045": { "Cosmetologist": 3 },
  "65202": { "Cosmetologist": 262, "Barber": 8 },
  "58601": { "Cosmetologist": 4 },
  "14845": { "Cosmetologist": 2 },
  "49424": { "Cosmetologist": 339, "Barber": 6 },
  "84096": { "Cosmetologist": 12 },
  "45424": { "Cosmetologist": 15, "Barber": 27 },
  "87008": { "Cosmetologist": 1 },
  "76401": { "Cosmetologist": 236, "Barber": 8 },
  "83604": { "Cosmetologist": 1 },
  "60613": { "Cosmetologist": 6, "Barber": 1 },
  "52358": { "Cosmetologist": 28 },
  "80402": { "Cosmetologist": 7 },
  "78724": { "Cosmetologist": 173, "Barber": 20 },
  "64127": { "Cosmetologist": 58, "Barber": 12 },
  "85635": { "Cosmetologist": 5 },
  "81133": { "Cosmetologist": 4 },
  "85213": { "Cosmetologist": 2, "Barber": 13 },
  "80156": { "Cosmetologist": 1 },
  "81615": { "Cosmetologist": 5 },
  "76259": { "Cosmetologist": 60, "Barber": 6 },
  "26554": { "Cosmetologist": 2 },
  "34445": { "Cosmetologist": 4 },
  "72758": { "Cosmetologist": 12 },
  "50010": { "Cosmetologist": 111, "Barber": 2 },
  "75034": { "Cosmetologist": 489, "Barber": 33 },
  "81646": { "Cosmetologist": 2 },
  "67756": { "Cosmetologist": 19 },
  "80539": { "Cosmetologist": 5 },
  "85546": { "Cosmetologist": 2 },
  "75287": { "Cosmetologist": 421, "Barber": 36 },
  "67861": { "Cosmetologist": 13 },
  "94501": { "Cosmetologist": 3 },
  "85719": { "Cosmetologist": 7, "Barber": 2 },
  "90042": { "Cosmetologist": 5 },
  "36695": { "Cosmetologist": 16 },
  "81648": { "Cosmetologist": 13 },
  "81427": { "Cosmetologist": 7 },
  "04681": { "Cosmetologist": 1 },
  "28801": { "Cosmetologist": 106 },
  "63108": { "Cosmetologist": 47, "Barber": 11 },
  "77521": { "Cosmetologist": 428, "Barber": 38 },
  "73105": { "Cosmetologist": 1 },
  "50240": { "Cosmetologist": 14 },
  "75070": { "Cosmetologist": 578, "Barber": 41 },
  "61764": { "Cosmetologist": 1 },
  "01527": { "Cosmetologist": 1 },
  "75098": { "Cosmetologist": 721, "Barber": 39 },
  "80930": { "Cosmetologist": 7 },
  "99645": { "Cosmetologist": 5 },
  "53207": { "Cosmetologist": 4 },
  "78613": { "Cosmetologist": 657, "Barber": 31 },
  "95403": { "Cosmetologist": 3 },
  "81149": { "Cosmetologist": 4 },
  "11103": { "Cosmetologist": 6 },
  "81123": { "Cosmetologist": 4 },
  "79424": { "Cosmetologist": 582, "Barber": 51 },
  "81331": { "Cosmetologist": 2 },
  "65010": { "Cosmetologist": 43, "Barber": 2 },
  "12018": { "Cosmetologist": 1 },
  "23608": { "Cosmetologist": 12 },
  "64024": { "Cosmetologist": 99, "Barber": 7 },
  "55446": { "Cosmetologist": 2 },
  "89107": { "Cosmetologist": 3 },
  "75771": { "Cosmetologist": 176, "Barber": 10 },
  "64052": { "Cosmetologist": 110, "Barber": 12 },
  "92694": { "Cosmetologist": 4 },
  "97113": { "Cosmetologist": 2 },
  "22102": { "Cosmetologist": 4 },
  "87571": { "Cosmetologist": 2 },
  "81523": { "Cosmetologist": 3 },
  "64628": { "Cosmetologist": 36, "Barber": 3 },
  "92008": { "Cosmetologist": 6 },
  "65704": { "Cosmetologist": 26, "Barber": 2 },
  "91372": { "Cosmetologist": 1 },
  "92869": { "Cosmetologist": 1 },
  "92585": { "Cosmetologist": 1 },
  "59741": { "Cosmetologist": 1 },
  "10031": { "Cosmetologist": 5 },
  "94404": { "Cosmetologist": 2 },
  "46131": { "Cosmetologist": 1, "Barber": 9 },
  "80160": { "Cosmetologist": 4 },
  "19030": { "Cosmetologist": 9 },
  "82935": { "Cosmetologist": 3 },
  "41075": { "Cosmetologist": 2 },
  "67879": { "Cosmetologist": 8 },
  "92102": { "Cosmetologist": 1 },
  "95687": { "Cosmetologist": 4 },
  "66401": { "Cosmetologist": 8 },
  "66801": { "Cosmetologist": 112 },
  "95630": { "Cosmetologist": 3 },
  "85244": { "Cosmetologist": 1 },
  "80827": { "Cosmetologist": 5 },
  "07456": { "Cosmetologist": 66, "Barber": 12 },
  "78201": { "Cosmetologist": 263, "Barber": 31 },
  "03060": { "Cosmetologist": 1 },
  "84047": { "Cosmetologist": 2 },
  "60143": { "Cosmetologist": 2 },
  "66901": { "Cosmetologist": 52 },
  "67878": { "Cosmetologist": 18 },
  "69149": { "Cosmetologist": 2 },
  "69023": { "Cosmetologist": 1 },
  "64083": { "Cosmetologist": 154, "Barber": 11 },
  "86004": { "Cosmetologist": 2 },
  "94550": { "Cosmetologist": 3 },
  "94118": { "Cosmetologist": 1 },
  "66860": { "Cosmetologist": 5 },
  "87305": { "Cosmetologist": 2 },
  "90650": { "Cosmetologist": 1 },
  "17601": { "Cosmetologist": 21, "Barber": 7 },
  "01852": { "Cosmetologist": 2 },
  "66048": { "Cosmetologist": 158, "Barber": 2 },
  "46765": { "Cosmetologist": 2, "Barber": 1 },
  "85119": { "Cosmetologist": 5 },
  "96761": { "Cosmetologist": 4 },
  "90744": { "Cosmetologist": 1 },
  "92011": { "Cosmetologist": 3 },
  "72058": { "Cosmetologist": 2 },
  "82636": { "Cosmetologist": 4 },
  "88061": { "Cosmetologist": 3 },
  "92683": { "Cosmetologist": 27 },
  "80822": { "Cosmetologist": 1 },
  "85381": { "Cosmetologist": 2, "Barber": 17 },
  "78480": { "Cosmetologist": 1 },
  "80833": { "Cosmetologist": 3 },
  "10950": { "Cosmetologist": 5 },
  "48042": { "Cosmetologist": 406, "Barber": 11 },
  "91042": { "Cosmetologist": 2 },
  "98418": { "Cosmetologist": 3 },
  "80824": { "Cosmetologist": 4 },
  "96789": { "Cosmetologist": 4 },
  "49412": { "Cosmetologist": 102, "Barber": 1 },
  "47403": { "Cosmetologist": 6, "Barber": 5 },
  "80364": { "Cosmetologist": 1 },
  "08021": { "Cosmetologist": 231, "Barber": 52 },
  "73034": { "Cosmetologist": 4 },
  "50613": { "Cosmetologist": 122, "Barber": 22 },
  "89131": { "Cosmetologist": 13 },
  "20601": { "Cosmetologist": 4 },
  "28150": { "Cosmetologist": 189, "Barber": 5 },
  "28644": { "Cosmetologist": 9 },
  "59330": { "Cosmetologist": 1 },
  "66113": { "Cosmetologist": 4 },
  "89002": { "Cosmetologist": 7 },
  "56560": { "Cosmetologist": 2 },
  "79110": { "Cosmetologist": 143, "Barber": 10 },
  "92262": { "Cosmetologist": 7 },
  "80479": { "Cosmetologist": 1 },
  "53213": { "Cosmetologist": 2 },
  "85023": { "Cosmetologist": 4, "Barber": 55 },
  "85318": { "Cosmetologist": 1 },
  "45133": { "Cosmetologist": 1 },
  "59911": { "Cosmetologist": 1 },
  "59404": { "Cosmetologist": 2 },
  "20136": { "Cosmetologist": 4 },
  "46205": { "Cosmetologist": 2, "Barber": 25 },
  "92656": { "Cosmetologist": 4, "Barber": 2 },
  "62025": { "Cosmetologist": 38, "Barber": 3 },
  "54467": { "Cosmetologist": 1 },
  "55947": { "Cosmetologist": 1 },
  "80632": { "Cosmetologist": 5 },
  "19036": { "Cosmetologist": 7 },
  "81041": { "Cosmetologist": 5 },
  "85623": { "Cosmetologist": 1 },
  "13607": { "Cosmetologist": 1 },
  "60189": { "Cosmetologist": 3 },
  "85745": { "Cosmetologist": 4, "Barber": 8 },
  "75009": { "Cosmetologist": 185, "Barber": 6 },
  "06010": { "Cosmetologist": 1, "Barber": 2 },
  "92201": { "Cosmetologist": 2 },
  "67226": { "Cosmetologist": 129 },
  "77015": { "Cosmetologist": 332, "Barber": 49 },
  "29036": { "Cosmetologist": 8 },
  "84098": { "Cosmetologist": 4 },
  "87499": { "Cosmetologist": 1 },
  "29732": { "Cosmetologist": 131 },
  "37221": { "Cosmetologist": 8, "Barber": 18 },
  "76131": { "Cosmetologist": 724, "Barber": 30 },
  "85351": { "Cosmetologist": 13 },
  "94108": { "Cosmetologist": 1 },
  "76182": { "Cosmetologist": 305, "Barber": 14 },
  "82716": { "Cosmetologist": 3 },
  "84790": { "Cosmetologist": 13, "Barber": 1 },
  "90731": { "Cosmetologist": 2 },
  "81131": { "Cosmetologist": 3 },
  "75067": { "Cosmetologist": 608, "Barber": 41 },
  "55044": { "Cosmetologist": 9 },
  "87413": { "Cosmetologist": 3 },
  "85283": { "Cosmetologist": 5, "Barber": 21 },
  "20111": { "Cosmetologist": 8 },
  "66502": { "Cosmetologist": 217, "Barber": 1 },
  "47978": { "Cosmetologist": 2, "Barber": 1 },
  "77356": { "Cosmetologist": 266, "Barber": 14 },
  "80939": { "Cosmetologist": 4 },
  "83420": { "Cosmetologist": 1 },
  "67209": { "Cosmetologist": 103 },
  "85233": { "Cosmetologist": 13, "Barber": 19 },
  "86402": { "Cosmetologist": 1 },
  "63107": { "Cosmetologist": 36, "Barber": 11 },
  "02554": { "Cosmetologist": 1 },
  "60030": { "Cosmetologist": 3 },
  "82501": { "Cosmetologist": 2 },
  "80937": { "Cosmetologist": 6 },
  "80037": { "Cosmetologist": 1 },
  "92310": { "Cosmetologist": 3 },
  "92203": { "Cosmetologist": 2 },
  "80611": { "Cosmetologist": 2 },
  "91765": { "Cosmetologist": 2 },
  "78732": { "Cosmetologist": 73, "Barber": 2 },
  "37342": { "Cosmetologist": 1, "Barber": 2 },
  "75407": { "Cosmetologist": 286, "Barber": 19 },
  "68105": { "Cosmetologist": 2 },
  "22303": { "Cosmetologist": 4 },
  "90265": { "Cosmetologist": 2 },
  "81022": { "Cosmetologist": 5 },
  "57201": { "Cosmetologist": 2 },
  "98362": { "Cosmetologist": 3 },
  "30107": { "Cosmetologist": 4 },
  "45011": { "Cosmetologist": 13, "Barber": 36 },
  "03608": { "Cosmetologist": 1 },
  "86327": { "Cosmetologist": 3 },
  "59019": { "Cosmetologist": 1 },
  "80949": { "Cosmetologist": 2 },
  "97267": { "Cosmetologist": 1 },
  "85295": { "Cosmetologist": 7, "Barber": 26 },
  "85710": { "Cosmetologist": 3, "Barber": 4 },
  "07501": { "Cosmetologist": 100, "Barber": 78 },
  "74075": { "Cosmetologist": 2 },
  "79015": { "Cosmetologist": 122, "Barber": 13 },
  "98133": { "Cosmetologist": 2 },
  "92240": { "Cosmetologist": 3 },
  "76901": { "Cosmetologist": 282, "Barber": 20 },
  "80447": { "Cosmetologist": 6 },
  "92649": { "Cosmetologist": 1 },
  "67042": { "Cosmetologist": 78 },
  "83852": { "Cosmetologist": 1 },
  "78801": { "Cosmetologist": 158, "Barber": 10 },
  "60118": { "Cosmetologist": 3 },
  "54904": { "Cosmetologist": 6 },
  "85003": { "Cosmetologist": 2 },
  "94511": { "Cosmetologist": 2 },
  "84663": { "Cosmetologist": 3 },
  "68104": { "Cosmetologist": 7, "Barber": 1 },
  "28208": { "Cosmetologist": 223, "Barber": 7 },
  "91605": { "Cosmetologist": 2 },
  "69143": { "Cosmetologist": 4 },
  "75240": { "Cosmetologist": 127, "Barber": 13 },
  "66956": { "Cosmetologist": 8 },
  "76227": { "Cosmetologist": 551, "Barber": 41 },
  "78747": { "Cosmetologist": 210, "Barber": 23 },
  "77318": { "Cosmetologist": 153, "Barber": 10 },
  "76065": { "Cosmetologist": 412, "Barber": 34 },
  "68147": { "Cosmetologist": 1 },
  "78729": { "Cosmetologist": 225, "Barber": 15 },
  "98312": { "Cosmetologist": 8 },
  "11704": { "Cosmetologist": 4 },
  "81433": { "Cosmetologist": 1 },
  "80703": { "Cosmetologist": 1 },
  "69151": { "Cosmetologist": 1 },
  "60050": { "Cosmetologist": 3 },
  "89109": { "Cosmetologist": 2 },
  "97205": { "Cosmetologist": 3 },
  "90041": { "Cosmetologist": 2 },
  "28405": { "Cosmetologist": 312, "Barber": 1 },
  "29715": { "Cosmetologist": 159 },
  "57752": { "Cosmetologist": 1 },
  "97140": { "Cosmetologist": 1 },
  "66030": { "Cosmetologist": 161 },
  "44264": { "Cosmetologist": 1 },
  "37027": { "Cosmetologist": 10, "Barber": 14 },
  "97322": { "Cosmetologist": 2 },
  "78253": { "Cosmetologist": 587, "Barber": 49 },
  "81242": { "Cosmetologist": 5 },
  "39730": { "Cosmetologist": 1 },
  "28270": { "Cosmetologist": 192, "Barber": 1 },
  "07936": { "Cosmetologist": 111, "Barber": 46 },
  "89139": { "Cosmetologist": 11 },
  "69101": { "Cosmetologist": 7 },
  "66614": { "Cosmetologist": 167 },
  "21771": { "Cosmetologist": 2 },
  "19958": { "Cosmetologist": 7 },
  "50021": { "Cosmetologist": 124, "Barber": 3 },
  "99577": { "Cosmetologist": 6 },
  "05826": { "Cosmetologist": 1 },
  "42001": { "Cosmetologist": 6 },
  "89027": { "Cosmetologist": 3 },
  "78758": { "Cosmetologist": 327, "Barber": 16 },
  "73130": { "Cosmetologist": 2 },
  "28092": { "Cosmetologist": 300, "Barber": 1 },
  "74110": { "Cosmetologist": 1 },
  "06426": { "Cosmetologist": 1 },
  "80101": { "Cosmetologist": 1 },
  "59912": { "Cosmetologist": 2 },
  "77581": { "Cosmetologist": 485, "Barber": 28 },
  "58647": { "Cosmetologist": 1 },
  "80034": { "Cosmetologist": 5 },
  "76023": { "Cosmetologist": 61, "Barber": 1 },
  "09618": { "Cosmetologist": 1 },
  "75080": { "Cosmetologist": 264, "Barber": 25 },
  "64850": { "Cosmetologist": 133, "Barber": 4 },
  "37347": { "Cosmetologist": 3, "Barber": 2 },
  "80736": { "Cosmetologist": 4 },
  "58103": { "Cosmetologist": 3 },
  "72764": { "Cosmetologist": 9 },
  "45769": { "Cosmetologist": 1 },
  "98382": { "Cosmetologist": 4 },
  "86403": { "Cosmetologist": 6 },
  "80426": { "Cosmetologist": 1 },
  "74429": { "Cosmetologist": 1 },
  "46390": { "Cosmetologist": 1, "Barber": 1 },
  "98003": { "Cosmetologist": 3 },
  "77379": { "Cosmetologist": 828, "Barber": 36 },
  "95407": { "Cosmetologist": 2 },
  "48532": { "Cosmetologist": 132, "Barber": 21 },
  "15012": { "Cosmetologist": 9, "Barber": 3 },
  "81422": { "Cosmetologist": 1 },
  "82325": { "Cosmetologist": 2 },
  "84532": { "Cosmetologist": 7 },
  "48629": { "Cosmetologist": 56, "Barber": 2 },
  "12529": { "Cosmetologist": 1 },
  "85207": { "Cosmetologist": 6, "Barber": 17 },
  "81215": { "Cosmetologist": 5 },
  "87114": { "Cosmetologist": 7 },
  "74055": { "Cosmetologist": 8 },
  "82201": { "Cosmetologist": 3 },
  "02908": { "Cosmetologist": 1 },
  "37857": { "Cosmetologist": 4, "Barber": 9 },
  "54636": { "Cosmetologist": 1 },
  "58504": { "Cosmetologist": 3 },
  "48103": { "Cosmetologist": 165, "Barber": 9 },
  "04464": { "Cosmetologist": 1 },
  "91723": { "Cosmetologist": 1 },
  "82053": { "Cosmetologist": 2 },
  "04102": { "Cosmetologist": 1 },
  "82240": { "Cosmetologist": 4 },
  "77450": { "Cosmetologist": 482, "Barber": 24 },
  "85128": { "Cosmetologist": 3, "Barber": 10 },
  "78039": { "Cosmetologist": 17, "Barber": 2 },
  "53565": { "Cosmetologist": 3 },
  "67401": { "Cosmetologist": 389 },
  "92626": { "Cosmetologist": 3 },
  "59106": { "Cosmetologist": 1 },
  "75078": { "Cosmetologist": 285, "Barber": 10 },
  "60404": { "Cosmetologist": 7 },
  "80745": { "Cosmetologist": 1 },
  "77399": { "Cosmetologist": 6 },
  "28779": { "Cosmetologist": 120 },
  "64012": { "Cosmetologist": 134, "Barber": 10 },
  "80802": { "Cosmetologist": 1 },
  "91324": { "Cosmetologist": 2 },
  "49286": { "Cosmetologist": 118, "Barber": 3 },
  "51501": { "Cosmetologist": 98, "Barber": 2 },
  "59101": { "Cosmetologist": 5 },
  "43560": { "Cosmetologist": 22 },
  "18067": { "Cosmetologist": 16, "Barber": 2 },
  "92374": { "Cosmetologist": 1 },
  "85122": { "Cosmetologist": 6, "Barber": 1 },
  "72223": { "Cosmetologist": 8 },
  "82420": { "Cosmetologist": 1 },
  "84058": { "Cosmetologist": 1 },
  "21532": { "Cosmetologist": 2 },
  "89119": { "Cosmetologist": 7 },
  "67851": { "Cosmetologist": 20 },
  "81426": { "Cosmetologist": 1 },
  "74006": { "Cosmetologist": 2 },
  "20876": { "Cosmetologist": 4 },
  "92780": { "Cosmetologist": 1 },
  "99006": { "Cosmetologist": 2 },
  "65807": { "Cosmetologist": 377, "Barber": 49 },
  "78233": { "Cosmetologist": 490, "Barber": 45 },
  "22652": { "Cosmetologist": 1 },
  "85044": { "Cosmetologist": 5, "Barber": 11 },
  "89122": { "Cosmetologist": 9 },
  "75068": { "Cosmetologist": 655, "Barber": 54 },
  "55423": { "Cosmetologist": 1 },
  "84092": { "Cosmetologist": 4 },
  "99703": { "Cosmetologist": 4 },
  "85202": { "Cosmetologist": 8, "Barber": 20 },
  "45227": { "Cosmetologist": 2, "Barber": 1 },
  "94513": { "Cosmetologist": 2 },
  "80497": { "Cosmetologist": 3 },
  "52405": { "Cosmetologist": 121, "Barber": 7 },
  "75002": { "Cosmetologist": 665, "Barber": 30 },
  "89106": { "Cosmetologist": 2 },
  "95426": { "Cosmetologist": 1 },
  "68410": { "Cosmetologist": 5 },
  "89142": { "Cosmetologist": 3 },
  "27610": { "Cosmetologist": 504, "Barber": 9 },
  "81025": { "Cosmetologist": 3 },
  "65637": { "Cosmetologist": 12, "Barber": 1 },
  "59044": { "Cosmetologist": 4 },
  "30269": { "Cosmetologist": 15 },
  "83467": { "Cosmetologist": 1 },
  "88616": { "Cosmetologist": 1 },
  "27605": { "Cosmetologist": 29 },
  "64037": { "Cosmetologist": 43 },
  "73801": { "Cosmetologist": 4 },
  "85379": { "Cosmetologist": 7, "Barber": 26 },
  "51529": { "Cosmetologist": 7 },
  "69041": { "Cosmetologist": 1 },
  "98683": { "Cosmetologist": 3 },
  "67526": { "Cosmetologist": 22 },
  "46074": { "Cosmetologist": 14, "Barber": 10 },
  "84059": { "Cosmetologist": 4 },
  "85083": { "Cosmetologist": 7, "Barber": 1 },
  "27609": { "Cosmetologist": 236, "Barber": 2 },
  "60051": { "Cosmetologist": 8 },
  "82060": { "Cosmetologist": 1 },
  "92124": { "Cosmetologist": 2 },
  "05446": { "Cosmetologist": 2 },
  "23093": { "Cosmetologist": 2 },
  "53546": { "Cosmetologist": 3 },
  "60564": { "Cosmetologist": 6 },
  "37096": { "Cosmetologist": 2 },
  "52068": { "Cosmetologist": 38, "Barber": 1 },
  "83854": { "Cosmetologist": 3 },
  "51342": { "Cosmetologist": 4 },
  "28743": { "Cosmetologist": 16 },
  "89081": { "Cosmetologist": 5 },
  "76431": { "Cosmetologist": 41 },
  "73703": { "Cosmetologist": 5, "Barber": 1 },
  "93422": { "Cosmetologist": 3 },
  "79835": { "Cosmetologist": 77, "Barber": 7 },
  "66071": { "Cosmetologist": 101 },
  "29204": { "Cosmetologist": 5 },
  "60532": { "Cosmetologist": 2 },
  "63139": { "Cosmetologist": 140, "Barber": 6 },
  "96820": { "Cosmetologist": 1 },
  "07950": { "Cosmetologist": 75, "Barber": 28 },
  "93312": { "Cosmetologist": 4 },
  "22202": { "Cosmetologist": 7 },
  "98671": { "Cosmetologist": 2 },
  "12304": { "Cosmetologist": 3 },
  "98236": { "Cosmetologist": 2 },
  "80746": { "Cosmetologist": 1 },
  "28311": { "Cosmetologist": 257, "Barber": 6 },
  "21044": { "Cosmetologist": 4 },
  "70518": { "Cosmetologist": 1 },
  "98406": { "Cosmetologist": 2 },
  "73016": { "Cosmetologist": 1 },
  "80931": { "Cosmetologist": 2 },
  "88230": { "Cosmetologist": 1 },
  "46723": { "Cosmetologist": 1, "Barber": 4 },
  "76051": { "Cosmetologist": 304, "Barber": 15 },
  "71730": { "Cosmetologist": 1, "Barber": 1 },
  "57770": { "Cosmetologist": 1 },
  "60641": { "Cosmetologist": 5 },
  "92563": { "Cosmetologist": 9 },
  "85716": { "Cosmetologist": 1, "Barber": 4 },
  "61616": { "Cosmetologist": 2 },
  "78602": { "Cosmetologist": 242, "Barber": 20 },
  "26755": { "Cosmetologist": 1 },
  "81125": { "Cosmetologist": 13 },
  "78414": { "Cosmetologist": 418, "Barber": 52 },
  "99338": { "Cosmetologist": 1 },
  "92705": { "Cosmetologist": 2 },
  "57760": { "Cosmetologist": 1 },
  "05653": { "Cosmetologist": 1 },
  "70461": { "Cosmetologist": 3 },
  "59711": { "Cosmetologist": 2 },
  "97477": { "Cosmetologist": 1 },
  "93446": { "Cosmetologist": 3 },
  "81126": { "Cosmetologist": 1 },
  "94566": { "Cosmetologist": 2 },
  "97211": { "Cosmetologist": 3 },
  "67235": { "Cosmetologist": 129 },
  "75022": { "Cosmetologist": 143, "Barber": 6 },
  "63049": { "Cosmetologist": 139, "Barber": 4 },
  "65049": { "Cosmetologist": 71, "Barber": 2 },
  "57438": { "Cosmetologist": 1 },
  "68850": { "Cosmetologist": 1 },
  "22553": { "Cosmetologist": 4 },
  "98374": { "Cosmetologist": 3 },
  "74701": { "Cosmetologist": 1 },
  "77092": { "Cosmetologist": 146, "Barber": 20 },
  "80901": { "Cosmetologist": 2 },
  "78738": { "Cosmetologist": 149, "Barber": 2 },
  "55337": { "Cosmetologist": 9 },
  "82925": { "Cosmetologist": 1 },
  "37380": { "Cosmetologist": 1, "Barber": 2 },
  "29212": { "Cosmetologist": 12, "Barber": 1 },
  "40175": { "Cosmetologist": 2 },
  "37363": { "Cosmetologist": 14, "Barber": 10 },
  "60543": { "Cosmetologist": 6 },
  "46304": { "Cosmetologist": 4, "Barber": 16 },
  "43035": { "Cosmetologist": 9 },
  "85374": { "Cosmetologist": 10, "Barber": 28 },
  "98411": { "Cosmetologist": 1 },
  "74003": { "Cosmetologist": 2 },
  "80420": { "Cosmetologist": 7 },
  "59802": { "Cosmetologist": 2 },
  "96740": { "Cosmetologist": 9 },
  "68134": { "Cosmetologist": 1 },
  "29511": { "Cosmetologist": 2 },
  "86545": { "Cosmetologist": 1 },
  "73503": { "Cosmetologist": 4 },
  "81638": { "Cosmetologist": 1 },
  "20171": { "Cosmetologist": 5 },
  "76049": { "Cosmetologist": 260, "Barber": 10 },
  "83642": { "Cosmetologist": 6 },
  "09705": { "Cosmetologist": 2 },
  "29650": { "Cosmetologist": 13 },
  "95691": { "Cosmetologist": 3 },
  "74014": { "Cosmetologist": 7 },
  "87412": { "Cosmetologist": 1 },
  "85629": { "Cosmetologist": 6 },
  "77612": { "Cosmetologist": 80, "Barber": 2 },
  "78258": { "Cosmetologist": 367, "Barber": 13 },
  "92071": { "Cosmetologist": 4 },
  "90019": { "Cosmetologist": 2 },
  "73755": { "Cosmetologist": 1 },
  "75119": { "Cosmetologist": 195, "Barber": 29 },
  "75038": { "Cosmetologist": 178, "Barber": 17 },
  "60540": { "Cosmetologist": 6 },
  "64111": { "Cosmetologist": 89, "Barber": 9 },
  "80161": { "Cosmetologist": 3 },
  "85173": { "Cosmetologist": 1 },
  "78132": { "Cosmetologist": 273, "Barber": 3 },
  "68841": { "Cosmetologist": 1 },
  "68045": { "Cosmetologist": 1 },
  "46220": { "Cosmetologist": 1, "Barber": 23 },
  "54313": { "Cosmetologist": 12 },
  "44903": { "Cosmetologist": 3, "Barber": 6 },
  "81130": { "Cosmetologist": 3 },
  "76502": { "Cosmetologist": 416, "Barber": 13 },
  "59870": { "Cosmetologist": 2 },
  "07047": { "Cosmetologist": 280, "Barber": 82 },
  "38068": { "Cosmetologist": 1, "Barber": 7 },
  "92121": { "Cosmetologist": 1 },
  "66738": { "Cosmetologist": 4 },
  "06513": { "Cosmetologist": 1 },
  "79902": { "Cosmetologist": 70, "Barber": 7 },
  "70656": { "Cosmetologist": 1 },
  "76002": { "Cosmetologist": 942, "Barber": 41 },
  "29067": { "Cosmetologist": 10 },
  "67207": { "Cosmetologist": 184 },
  "85901": { "Cosmetologist": 3 },
  "90404": { "Cosmetologist": 2 },
  "27909": { "Cosmetologist": 232, "Barber": 1 },
  "82513": { "Cosmetologist": 1 },
  "89138": { "Cosmetologist": 3 },
  "80934": { "Cosmetologist": 3 },
  "29334": { "Cosmetologist": 10 },
  "81121": { "Cosmetologist": 1 },
  "92154": { "Cosmetologist": 3 },
  "67037": { "Cosmetologist": 215 },
  "14701": { "Cosmetologist": 1 },
  "78702": { "Cosmetologist": 164, "Barber": 28 },
  "69145": { "Cosmetologist": 1 },
  "28786": { "Cosmetologist": 145 },
  "64804": { "Cosmetologist": 253, "Barber": 8 },
  "80744": { "Cosmetologist": 4 },
  "01844": { "Cosmetologist": 1, "Barber": 2 },
  "19943": { "Cosmetologist": 3 },
  "56301": { "Cosmetologist": 3 },
  "59486": { "Cosmetologist": 1 },
  "83202": { "Cosmetologist": 2 },
  "78260": { "Cosmetologist": 313, "Barber": 14 },
  "82083": { "Cosmetologist": 1 },
  "70117": { "Cosmetologist": 2 },
  "64081": { "Cosmetologist": 130, "Barber": 8 },
  "73664": { "Cosmetologist": 1 },
  "66608": { "Cosmetologist": 28 },
  "69361": { "Cosmetologist": 4 },
  "83616": { "Cosmetologist": 2 },
  "80727": { "Cosmetologist": 3 },
  "47553": { "Cosmetologist": 1, "Barber": 2 },
  "66442": { "Cosmetologist": 26 },
  "94401": { "Cosmetologist": 2 },
  "94551": { "Cosmetologist": 3 },
  "89144": { "Cosmetologist": 4 },
  "83703": { "Cosmetologist": 3 },
  "95456": { "Cosmetologist": 1 },
  "83805": { "Cosmetologist": 2 },
  "76629": { "Cosmetologist": 16 },
  "77532": { "Cosmetologist": 223, "Barber": 22 },
  "84118": { "Cosmetologist": 3 },
  "57107": { "Cosmetologist": 1 },
  "77396": { "Cosmetologist": 443, "Barber": 49 },
  "77316": { "Cosmetologist": 234, "Barber": 9 },
  "92557": { "Cosmetologist": 2 },
  "69022": { "Cosmetologist": 2 },
  "53719": { "Cosmetologist": 4 },
  "86305": { "Cosmetologist": 6 },
  "49971": { "Cosmetologist": 3 },
  "53965": { "Cosmetologist": 2 },
  "82321": { "Cosmetologist": 1 },
  "62349": { "Cosmetologist": 1 },
  "60014": { "Cosmetologist": 7 },
  "27357": { "Cosmetologist": 53 },
  "49004": { "Cosmetologist": 112, "Barber": 5 },
  "92831": { "Cosmetologist": 2 },
  "80425": { "Cosmetologist": 2 },
  "83607": { "Cosmetologist": 3 },
  "48304": { "Cosmetologist": 73, "Barber": 2 },
  "47203": { "Cosmetologist": 3, "Barber": 5 },
  "47601": { "Cosmetologist": 1, "Barber": 7 },
  "29072": { "Cosmetologist": 17 },
  "74074": { "Cosmetologist": 3 },
  "43230": { "Cosmetologist": 9 },
  "87740": { "Cosmetologist": 7 },
  "55068": { "Cosmetologist": 2 },
  "91801": { "Cosmetologist": 3 },
  "86426": { "Cosmetologist": 3 },
  "98126": { "Cosmetologist": 4 },
  "96746": { "Cosmetologist": 6 },
  "43147": { "Cosmetologist": 7 },
  "64154": { "Cosmetologist": 65, "Barber": 2 },
  "89441": { "Cosmetologist": 1 },
  "91914": { "Cosmetologist": 2 },
  "63390": { "Cosmetologist": 92, "Barber": 5 },
  "92115": { "Cosmetologist": 9 },
  "85501": { "Cosmetologist": 2 },
  "73069": { "Cosmetologist": 1 },
  "97301": { "Cosmetologist": 3 },
  "90038": { "Cosmetologist": 6 },
  "69138": { "Cosmetologist": 2 },
  "80028": { "Cosmetologist": 1 },
  "98250": { "Cosmetologist": 1 },
  "66221": { "Cosmetologist": 112 },
  "75088": { "Cosmetologist": 280, "Barber": 29 },
  "03104": { "Cosmetologist": 1 },
  "11206": { "Cosmetologist": 7 },
  "44646": { "Cosmetologist": 3 },
  "87544": { "Cosmetologist": 2 },
  "66216": { "Cosmetologist": 136, "Barber": 1 },
  "86313": { "Cosmetologist": 1 },
  "28078": { "Cosmetologist": 379, "Barber": 3 },
  "19810": { "Cosmetologist": 2 },
  "66227": { "Cosmetologist": 43, "Barber": 1 },
  "70601": { "Cosmetologist": 3 },
  "80749": { "Cosmetologist": 2 },
  "95134": { "Cosmetologist": 3 },
  "70535": { "Cosmetologist": 2 },
  "51040": { "Cosmetologist": 12, "Barber": 1 },
  "97405": { "Cosmetologist": 4 },
  "80804": { "Cosmetologist": 2 },
  "68503": { "Cosmetologist": 1 },
  "46106": { "Cosmetologist": 5, "Barber": 4 },
  "81251": { "Cosmetologist": 2 },
  "97008": { "Cosmetologist": 2 },
  "89801": { "Cosmetologist": 2 },
  "68949": { "Cosmetologist": 1 },
  "90740": { "Cosmetologist": 3 },
  "84533": { "Cosmetologist": 1 },
  "55102": { "Cosmetologist": 2 },
  "45238": { "Cosmetologist": 3, "Barber": 6 },
  "53209": { "Cosmetologist": 8 },
  "97027": { "Cosmetologist": 2 },
  "15332": { "Cosmetologist": 3, "Barber": 1 },
  "88135": { "Cosmetologist": 1 },
  "20882": { "Cosmetologist": 3, "Barber": 4 },
  "95828": { "Cosmetologist": 5 },
  "83815": { "Cosmetologist": 3 },
  "85305": { "Cosmetologist": 4, "Barber": 20 },
  "74421": { "Cosmetologist": 1 },
  "79938": { "Cosmetologist": 689, "Barber": 78 },
  "15135": { "Cosmetologist": 2 },
  "75025": { "Cosmetologist": 345, "Barber": 14 },
  "38004": { "Cosmetologist": 1, "Barber": 9 },
  "65785": { "Cosmetologist": 26, "Barber": 1 },
  "93308": { "Cosmetologist": 3 },
  "80818": { "Cosmetologist": 2 },
  "73078": { "Cosmetologist": 2 },
  "94571": { "Cosmetologist": 2 },
  "98020": { "Cosmetologist": 3 },
  "55436": { "Cosmetologist": 1 },
  "63601": { "Cosmetologist": 119, "Barber": 2 },
  "93561": { "Cosmetologist": 1 },
  "53202": { "Cosmetologist": 2 },
  "23503": { "Cosmetologist": 8 },
  "82435": { "Cosmetologist": 1 },
  "60610": { "Cosmetologist": 5 },
  "22801": { "Cosmetologist": 3, "Barber": 2 },
  "44223": { "Cosmetologist": 1, "Barber": 6 },
  "99218": { "Cosmetologist": 3 },
  "84120": { "Cosmetologist": 3 },
  "88001": { "Cosmetologist": 2 },
  "61455": { "Cosmetologist": 5 },
  "12572": { "Cosmetologist": 1 },
  "53711": { "Cosmetologist": 6 },
  "95765": { "Cosmetologist": 4 },
  "54015": { "Cosmetologist": 2 },
  "10924": { "Cosmetologist": 6 },
  "86406": { "Cosmetologist": 4, "Barber": 1 },
  "11249": { "Cosmetologist": 3 },
  "51039": { "Cosmetologist": 20 },
  "65803": { "Cosmetologist": 200, "Barber": 21 },
  "50014": { "Cosmetologist": 49, "Barber": 8 },
  "79701": { "Cosmetologist": 200, "Barber": 17 },
  "94952": { "Cosmetologist": 2 },
  "81023": { "Cosmetologist": 3 },
  "95661": { "Cosmetologist": 4 },
  "93012": { "Cosmetologist": 2 },
  "85020": { "Cosmetologist": 8, "Barber": 26 },
  "74344": { "Cosmetologist": 12 },
  "70373": { "Cosmetologist": 1 },
  "94534": { "Cosmetologist": 1 },
  "97203": { "Cosmetologist": 1 },
  "78002": { "Cosmetologist": 55, "Barber": 7 },
  "02910": { "Cosmetologist": 1 },
  "71201": { "Cosmetologist": 1 },
  "66061": { "Cosmetologist": 362, "Barber": 4 },
  "64086": { "Cosmetologist": 132, "Barber": 10 },
  "94117": { "Cosmetologist": 4 },
  "66083": { "Cosmetologist": 92 },
  "67456": { "Cosmetologist": 31 },
  "53211": { "Cosmetologist": 3 },
  "57702": { "Cosmetologist": 2 },
  "59937": { "Cosmetologist": 1 },
  "73142": { "Cosmetologist": 5 },
  "85373": { "Cosmetologist": 4 },
  "97504": { "Cosmetologist": 3 },
  "22015": { "Cosmetologist": 5 },
  "48808": { "Cosmetologist": 48, "Barber": 4 },
  "87113": { "Cosmetologist": 3 },
  "37830": { "Cosmetologist": 4, "Barber": 20 },
  "76247": { "Cosmetologist": 199, "Barber": 6 },
  "75495": { "Cosmetologist": 90, "Barber": 5 },
  "75790": { "Cosmetologist": 34, "Barber": 4 },
  "87121": { "Cosmetologist": 4 },
  "81157": { "Cosmetologist": 2 },
  "51103": { "Cosmetologist": 45, "Barber": 2 },
  "98663": { "Cosmetologist": 2 },
  "47402": { "Cosmetologist": 2 },
  "68127": { "Cosmetologist": 4 },
  "74857": { "Cosmetologist": 1 },
  "99212": { "Cosmetologist": 1 },
  "47170": { "Cosmetologist": 1, "Barber": 5 },
  "53072": { "Cosmetologist": 3 },
  "83864": { "Cosmetologist": 2 },
  "29118": { "Cosmetologist": 5 },
  "46227": { "Cosmetologist": 9, "Barber": 25 },
  "81640": { "Cosmetologist": 2 },
  "85008": { "Cosmetologist": 4, "Barber": 50 },
  "98908": { "Cosmetologist": 1 },
  "49643": { "Cosmetologist": 63, "Barber": 2 },
  "59759": { "Cosmetologist": 1 },
  "77346": { "Cosmetologist": 616, "Barber": 63 },
  "13021": { "Cosmetologist": 7, "Barber": 1 },
  "48346": { "Cosmetologist": 196, "Barber": 3 },
  "68526": { "Cosmetologist": 1 },
  "85024": { "Cosmetologist": 3, "Barber": 21 },
  "62221": { "Cosmetologist": 21, "Barber": 4 },
  "91780": { "Cosmetologist": 3 },
  "57730": { "Cosmetologist": 1 },
  "76705": { "Cosmetologist": 159, "Barber": 23 },
  "21666": { "Cosmetologist": 1 },
  "76209": { "Cosmetologist": 197, "Barber": 20 },
  "58577": { "Cosmetologist": 1 },
  "48359": { "Cosmetologist": 64 },
  "42262": { "Cosmetologist": 4 },
  "60039": { "Cosmetologist": 1 },
  "60625": { "Cosmetologist": 4 },
  "45431": { "Cosmetologist": 1, "Barber": 10 },
  "85215": { "Cosmetologist": 4, "Barber": 6 },
  "94110": { "Cosmetologist": 4 },
  "90066": { "Cosmetologist": 7 },
  "55944": { "Cosmetologist": 2 },
  "81243": { "Cosmetologist": 1 },
  "46112": { "Cosmetologist": 7, "Barber": 17 },
  "65669": { "Cosmetologist": 20 },
  "74401": { "Cosmetologist": 2 },
  "78229": { "Cosmetologist": 148, "Barber": 19 },
  "80468": { "Cosmetologist": 1 },
  "80805": { "Cosmetologist": 3 },
  "70447": { "Cosmetologist": 4 },
  "96708": { "Cosmetologist": 1 },
  "52747": { "Cosmetologist": 16 },
  "93003": { "Cosmetologist": 2 },
  "75214": { "Cosmetologist": 170, "Barber": 15 },
  "89815": { "Cosmetologist": 3 },
  "48074": { "Cosmetologist": 81, "Barber": 3 },
  "90621": { "Cosmetologist": 1 },
  "13856": { "Cosmetologist": 2 },
  "85037": { "Cosmetologist": 2, "Barber": 8 },
  "39211": { "Cosmetologist": 2 },
  "96707": { "Cosmetologist": 5 },
  "93611": { "Cosmetologist": 6 },
  "23063": { "Cosmetologist": 2 },
  "85650": { "Cosmetologist": 4 },
  "98607": { "Cosmetologist": 5 },
  "87107": { "Cosmetologist": 3 },
  "47591": { "Cosmetologist": 2, "Barber": 7 },
  "15325": { "Cosmetologist": 2 },
  "95125": { "Cosmetologist": 4 },
  "98277": { "Cosmetologist": 10 },
  "80962": { "Cosmetologist": 5 },
  "93510": { "Cosmetologist": 1 },
  "93105": { "Cosmetologist": 2 },
  "46143": { "Cosmetologist": 12, "Barber": 27 },
  "95010": { "Cosmetologist": 1 },
  "75126": { "Cosmetologist": 588, "Barber": 85 },
  "85085": { "Cosmetologist": 5, "Barber": 11 },
  "80735": { "Cosmetologist": 2 },
  "27516": { "Cosmetologist": 139 },
  "96738": { "Cosmetologist": 3 },
  "46208": { "Cosmetologist": 3, "Barber": 30 },
  "99516": { "Cosmetologist": 3 },
  "85712": { "Cosmetologist": 3, "Barber": 2 },
  "80860": { "Cosmetologist": 2 },
  "78259": { "Cosmetologist": 321, "Barber": 10 },
  "35756": { "Cosmetologist": 6 },
  "97455": { "Cosmetologist": 1 },
  "28420": { "Cosmetologist": 24 },
  "97701": { "Cosmetologist": 7 },
  "64093": { "Cosmetologist": 126, "Barber": 7 },
  "75639": { "Cosmetologist": 11 },
  "89034": { "Cosmetologist": 2 },
  "01581": { "Cosmetologist": 2 },
  "87112": { "Cosmetologist": 5 },
  "84405": { "Cosmetologist": 2 },
  "79601": { "Cosmetologist": 100, "Barber": 10 },
  "20735": { "Cosmetologist": 5 },
  "28543": { "Cosmetologist": 31 },
  "62208": { "Cosmetologist": 20, "Barber": 4 },
  "77471": { "Cosmetologist": 274, "Barber": 54 },
  "78401": { "Cosmetologist": 16, "Barber": 4 },
  "85254": { "Cosmetologist": 8, "Barber": 22 },
  "85042": { "Cosmetologist": 4, "Barber": 74 },
  "06855": { "Cosmetologist": 1 },
  "37862": { "Cosmetologist": 5, "Barber": 7 },
  "78387": { "Cosmetologist": 42, "Barber": 15 },
  "37642": { "Cosmetologist": 5, "Barber": 9 },
  "72703": { "Cosmetologist": 3 },
  "20110": { "Cosmetologist": 2 },
  "76309": { "Cosmetologist": 109, "Barber": 9 },
  "98225": { "Cosmetologist": 3 },
  "67432": { "Cosmetologist": 42 },
  "15101": { "Cosmetologist": 11, "Barber": 1 },
  "83687": { "Cosmetologist": 4 },
  "91411": { "Cosmetologist": 3 },
  "75093": { "Cosmetologist": 336, "Barber": 18 },
  "55105": { "Cosmetologist": 1 },
  "68926": { "Cosmetologist": 1 },
  "93041": { "Cosmetologist": 4 },
  "18447": { "Cosmetologist": 8, "Barber": 2 },
  "96732": { "Cosmetologist": 3 },
  "85298": { "Cosmetologist": 12, "Barber": 8 },
  "93063": { "Cosmetologist": 4, "Barber": 1 },
  "02132": { "Cosmetologist": 1 },
  "56308": { "Cosmetologist": 3 },
  "46383": { "Cosmetologist": 4, "Barber": 12 },
  "80455": { "Cosmetologist": 2 },
  "64157": { "Cosmetologist": 153, "Barber": 5 },
  "80036": { "Cosmetologist": 2 },
  "92509": { "Cosmetologist": 2 },
  "90606": { "Cosmetologist": 1 },
  "87552": { "Cosmetologist": 1 },
  "64131": { "Cosmetologist": 90, "Barber": 16 },
  "81040": { "Cosmetologist": 1 },
  "81610": { "Cosmetologist": 2 },
  "90069": { "Cosmetologist": 6 },
  "21043": { "Cosmetologist": 2 },
  "77083": { "Cosmetologist": 2097, "Barber": 54 },
  "76033": { "Cosmetologist": 207, "Barber": 8 },
  "68118": { "Cosmetologist": 2 },
  "44094": { "Cosmetologist": 4 },
  "85225": { "Cosmetologist": 8, "Barber": 42 },
  "50036": { "Cosmetologist": 77, "Barber": 4 },
  "99669": { "Cosmetologist": 3 },
  "47630": { "Cosmetologist": 4, "Barber": 14 },
  "89113": { "Cosmetologist": 4 },
  "27407": { "Cosmetologist": 464, "Barber": 5 },
  "98198": { "Cosmetologist": 5 },
  "67880": { "Cosmetologist": 42 },
  "28546": { "Cosmetologist": 423, "Barber": 16 },
  "95603": { "Cosmetologist": 2 },
  "12561": { "Cosmetologist": 3 },
  "09128": { "Cosmetologist": 1 },
  "46290": { "Cosmetologist": 1 },
  "37205": { "Cosmetologist": 5, "Barber": 7 },
  "90501": { "Cosmetologist": 1 },
  "90021": { "Cosmetologist": 1 },
  "85748": { "Cosmetologist": 3 },
  "79040": { "Cosmetologist": 5 },
  "98801": { "Cosmetologist": 2 },
  "67114": { "Cosmetologist": 102 },
  "14737": { "Cosmetologist": 1 },
  "44320": { "Cosmetologist": 3, "Barber": 9 },
  "09046": { "Cosmetologist": 1 },
  "63379": { "Cosmetologist": 259, "Barber": 10 },
  "65738": { "Cosmetologist": 161, "Barber": 9 },
  "14850": { "Cosmetologist": 1 },
  "67665": { "Cosmetologist": 47 },
  "80750": { "Cosmetologist": 1 },
  "78628": { "Cosmetologist": 297, "Barber": 22 },
  "78114": { "Cosmetologist": 143, "Barber": 23 },
  "71223": { "Cosmetologist": 1 },
  "56171": { "Cosmetologist": 1 },
  "92391": { "Cosmetologist": 1 },
  "91730": { "Cosmetologist": 1, "Barber": 1 },
  "76116": { "Cosmetologist": 294, "Barber": 31 },
  "69147": { "Cosmetologist": 1 },
  "54981": { "Cosmetologist": 4 },
  "67701": { "Cosmetologist": 54 },
  "82202": { "Cosmetologist": 1 },
  "20895": { "Cosmetologist": 2 },
  "39507": { "Cosmetologist": 4 },
  "49107": { "Cosmetologist": 45 },
  "75051": { "Cosmetologist": 385, "Barber": 59 },
  "96822": { "Cosmetologist": 3 },
  "85327": { "Cosmetologist": 2 },
  "73134": { "Cosmetologist": 1 },
  "58203": { "Cosmetologist": 2 },
  "79606": { "Cosmetologist": 226, "Barber": 6 },
  "83714": { "Cosmetologist": 4, "Barber": 1 },
  "29642": { "Cosmetologist": 11 },
  "28444": { "Cosmetologist": 15, "Barber": 1 },
  "98354": { "Cosmetologist": 2 },
  "48146": { "Cosmetologist": 198, "Barber": 17 },
  "98042": { "Cosmetologist": 2 },
  "85641": { "Cosmetologist": 7 },
  "56757": { "Cosmetologist": 1 },
  "79401": { "Cosmetologist": 27, "Barber": 7 },
  "78728": { "Cosmetologist": 234, "Barber": 18 },
  "77541": { "Cosmetologist": 89, "Barber": 20 },
  "90046": { "Cosmetologist": 10 },
  "74011": { "Cosmetologist": 4 },
  "80291": { "Cosmetologist": 1 },
  "16803": { "Cosmetologist": 12, "Barber": 1 },
  "40324": { "Cosmetologist": 6 },
  "54630": { "Cosmetologist": 2 },
  "68528": { "Cosmetologist": 1, "Barber": 1 },
  "87417": { "Cosmetologist": 2 },
  "66441": { "Cosmetologist": 158 },
  "10541": { "Cosmetologist": 5 },
  "60005": { "Cosmetologist": 3 },
  "62959": { "Cosmetologist": 7, "Barber": 1 },
  "58701": { "Cosmetologist": 1 },
  "99501": { "Cosmetologist": 3 },
  "77345": { "Cosmetologist": 122, "Barber": 6 },
  "75147": { "Cosmetologist": 69, "Barber": 9 },
  "05655": { "Cosmetologist": 1 },
  "66543": { "Cosmetologist": 10 },
  "58854": { "Cosmetologist": 1 },
  "92602": { "Cosmetologist": 4 },
  "70471": { "Cosmetologist": 3 },
  "84779": { "Cosmetologist": 1 },
  "08080": { "Cosmetologist": 305, "Barber": 50 },
  "98528": { "Cosmetologist": 1 },
  "80025": { "Cosmetologist": 1 },
  "81029": { "Cosmetologist": 3 },
  "80288": { "Cosmetologist": 1 },
  "08517": { "Cosmetologist": 1 },
  "68336": { "Cosmetologist": 1 },
  "64080": { "Cosmetologist": 110, "Barber": 7 },
  "96797": { "Cosmetologist": 1 },
  "98499": { "Cosmetologist": 4 },
  "75056": { "Cosmetologist": 742, "Barber": 38 },
  "81081": { "Cosmetologist": 1 },
  "57104": { "Cosmetologist": 3 },
  "34216": { "Cosmetologist": 8 },
  "55375": { "Cosmetologist": 1 },
  "50208": { "Cosmetologist": 80, "Barber": 6 },
  "97404": { "Cosmetologist": 1 },
  "84770": { "Cosmetologist": 5 },
  "80862": { "Cosmetologist": 1 },
  "60541": { "Cosmetologist": 1 },
  "63143": { "Cosmetologist": 50, "Barber": 1 },
  "57717": { "Cosmetologist": 1 },
  "73529": { "Cosmetologist": 1 },
  "78666": { "Cosmetologist": 438, "Barber": 59 },
  "54913": { "Cosmetologist": 10 },
  "10996": { "Cosmetologist": 1 },
  "59840": { "Cosmetologist": 2 },
  "28214": { "Cosmetologist": 309, "Barber": 2 },
  "31905": { "Cosmetologist": 2 },
  "54304": { "Cosmetologist": 4 },
  "50513": { "Cosmetologist": 1 },
  "28227": { "Cosmetologist": 412, "Barber": 5 },
  "92637": { "Cosmetologist": 2 },
  "60950": { "Cosmetologist": 3 },
  "88242": { "Cosmetologist": 2 },
  "56545": { "Cosmetologist": 1 },
  "79762": { "Cosmetologist": 350, "Barber": 30 },
  "54660": { "Cosmetologist": 1 },
  "37086": { "Cosmetologist": 6, "Barber": 40 },
  "85755": { "Cosmetologist": 3 },
  "44090": { "Cosmetologist": 1 },
  "44024": { "Cosmetologist": 2 },
  "10930": { "Cosmetologist": 1 },
  "23168": { "Cosmetologist": 2 },
  "98228": { "Cosmetologist": 1 },
  "21770": { "Cosmetologist": 1 },
  "15801": { "Cosmetologist": 32, "Barber": 2 },
  "89820": { "Cosmetologist": 1 },
  "44224": { "Cosmetologist": 6 },
  "99611": { "Cosmetologist": 2 },
  "59701": { "Cosmetologist": 5 },
  "90405": { "Cosmetologist": 3 },
  "22150": { "Cosmetologist": 4 },
  "92065": { "Cosmetologist": 2 },
  "67748": { "Cosmetologist": 16 },
  "20112": { "Cosmetologist": 2 },
  "92503": { "Cosmetologist": 3 },
  "90249": { "Cosmetologist": 2 },
  "99559": { "Cosmetologist": 1 },
  "84115": { "Cosmetologist": 6 },
  "95993": { "Cosmetologist": 2 },
  "92532": { "Cosmetologist": 4 },
  "85260": { "Cosmetologist": 9, "Barber": 13 },
  "76043": { "Cosmetologist": 54, "Barber": 4 },
  "89110": { "Cosmetologist": 6 },
  "74063": { "Cosmetologist": 4 },
  "84128": { "Cosmetologist": 3 },
  "72802": { "Cosmetologist": 4 },
  "95032": { "Cosmetologist": 2 },
  "76856": { "Cosmetologist": 20 },
  "84065": { "Cosmetologist": 4 },
  "50266": { "Cosmetologist": 154, "Barber": 6 },
  "28374": { "Cosmetologist": 89 },
  "78541": { "Cosmetologist": 273, "Barber": 32 },
  "87031": { "Cosmetologist": 3 },
  "38255": { "Cosmetologist": 1 },
  "62423": { "Cosmetologist": 1 },
  "55060": { "Cosmetologist": 4 },
  "78244": { "Cosmetologist": 326, "Barber": 36 },
  "93455": { "Cosmetologist": 3 },
  "98660": { "Cosmetologist": 18 },
  "98115": { "Cosmetologist": 2 },
  "80131": { "Cosmetologist": 2 },
  "84005": { "Cosmetologist": 4 },
  "54701": { "Cosmetologist": 7 },
  "77385": { "Cosmetologist": 268, "Barber": 17 },
  "92562": { "Cosmetologist": 8 },
  "91607": { "Cosmetologist": 6 },
  "43235": { "Cosmetologist": 4, "Barber": 4 },
  "76543": { "Cosmetologist": 211, "Barber": 44 },
  "98383": { "Cosmetologist": 2 },
  "84074": { "Cosmetologist": 1 },
  "97439": { "Cosmetologist": 3 },
  "09470": { "Cosmetologist": 1 },
  "84010": { "Cosmetologist": 2 },
  "85286": { "Cosmetologist": 8, "Barber": 8 },
  "98058": { "Cosmetologist": 6 },
  "55987": { "Cosmetologist": 1 },
  "60115": { "Cosmetologist": 3 },
  "78669": { "Cosmetologist": 96, "Barber": 9 },
  "67502": { "Cosmetologist": 205 },
  "16316": { "Cosmetologist": 13 },
  "05602": { "Cosmetologist": 1 },
  "95762": { "Cosmetologist": 2 },
  "50313": { "Cosmetologist": 76, "Barber": 7 },
  "35810": { "Cosmetologist": 1 },
  "96745": { "Cosmetologist": 3 },
  "85310": { "Cosmetologist": 3, "Barber": 3 },
  "75076": { "Cosmetologist": 61, "Barber": 4 },
  "88311": { "Cosmetologist": 1 },
  "81320": { "Cosmetologist": 1 },
  "80834": { "Cosmetologist": 2 },
  "85355": { "Cosmetologist": 3 },
  "81654": { "Cosmetologist": 6 },
  "35565": { "Cosmetologist": 1 },
  "90028": { "Cosmetologist": 6 },
  "80747": { "Cosmetologist": 1 },
  "45372": { "Cosmetologist": 1 },
  "38351": { "Cosmetologist": 2, "Barber": 6 },
  "88435": { "Cosmetologist": 1 },
  "55409": { "Cosmetologist": 1 },
  "11358": { "Cosmetologist": 2 },
  "56096": { "Cosmetologist": 1 },
  "37072": { "Cosmetologist": 5, "Barber": 18 },
  "97444": { "Cosmetologist": 1 },
  "18966": { "Cosmetologist": 17, "Barber": 5 },
  "53215": { "Cosmetologist": 4 },
  "07039": { "Cosmetologist": 61, "Barber": 26 },
  "85297": { "Cosmetologist": 8, "Barber": 8 },
  "55731": { "Cosmetologist": 2 },
  "77375": { "Cosmetologist": 674, "Barber": 37 },
  "99141": { "Cosmetologist": 1 },
  "29482": { "Cosmetologist": 1 },
  "55021": { "Cosmetologist": 2 },
  "78213": { "Cosmetologist": 327, "Barber": 23 },
  "51301": { "Cosmetologist": 69, "Barber": 2 },
  "38138": { "Cosmetologist": 2, "Barber": 5 },
  "80448": { "Cosmetologist": 1 },
  "75457": { "Cosmetologist": 49, "Barber": 4 },
  "96020": { "Cosmetologist": 1 },
  "80652": { "Cosmetologist": 1 },
  "75204": { "Cosmetologist": 240, "Barber": 17 },
  "48843": { "Cosmetologist": 427, "Barber": 6 },
  "59758": { "Cosmetologist": 1 },
  "82718": { "Cosmetologist": 8 },
  "44056": { "Cosmetologist": 3 },
  "78757": { "Cosmetologist": 131, "Barber": 18 },
  "40014": { "Cosmetologist": 4 },
  "19606": { "Cosmetologist": 13, "Barber": 2 },
  "93449": { "Cosmetologist": 2 },
  "85296": { "Cosmetologist": 6, "Barber": 16 },
  "75035": { "Cosmetologist": 618, "Barber": 27 },
  "61462": { "Cosmetologist": 4 },
  "45371": { "Cosmetologist": 4 },
  "56528": { "Cosmetologist": 2 },
  "53185": { "Cosmetologist": 2 },
  "55082": { "Cosmetologist": 3 },
  "27517": { "Cosmetologist": 72 },
  "64113": { "Cosmetologist": 26 },
  "18353": { "Cosmetologist": 16, "Barber": 3 },
  "86320": { "Cosmetologist": 2 },
  "10025": { "Cosmetologist": 11 },
  "85027": { "Cosmetologist": 3, "Barber": 28 },
  "87557": { "Cosmetologist": 1 },
  "80469": { "Cosmetologist": 1 },
  "83713": { "Cosmetologist": 5 },
  "59828": { "Cosmetologist": 2 },
  "10005": { "Cosmetologist": 2 },
  "66218": { "Cosmetologist": 64, "Barber": 2 },
  "60525": { "Cosmetologist": 4 },
  "23192": { "Cosmetologist": 1 },
  "96310": { "Cosmetologist": 2 },
  "45230": { "Cosmetologist": 2, "Barber": 4 },
  "70769": { "Cosmetologist": 2 },
  "68028": { "Cosmetologist": 3 },
  "92014": { "Cosmetologist": 1 },
  "98122": { "Cosmetologist": 3 },
  "44484": { "Cosmetologist": 3, "Barber": 15 },
  "39564": { "Cosmetologist": 17 },
  "55124": { "Cosmetologist": 5 },
  "60707": { "Cosmetologist": 4 },
  "93923": { "Cosmetologist": 1 },
  "37643": { "Cosmetologist": 7, "Barber": 22 },
  "37880": { "Cosmetologist": 1, "Barber": 1 },
  "60045": { "Cosmetologist": 1 },
  "92264": { "Cosmetologist": 4 },
  "43085": { "Cosmetologist": 5 },
  "68130": { "Cosmetologist": 3 },
  "21093": { "Cosmetologist": 4 },
  "55024": { "Cosmetologist": 5 },
  "79119": { "Cosmetologist": 191, "Barber": 12 },
  "07840": { "Cosmetologist": 197, "Barber": 44 },
  "92058": { "Cosmetologist": 12 },
  "20186": { "Cosmetologist": 1 },
  "92882": { "Cosmetologist": 4 },
  "66043": { "Cosmetologist": 50 },
  "81430": { "Cosmetologist": 1 },
  "83434": { "Cosmetologist": 1 },
  "26181": { "Cosmetologist": 1 },
  "81656": { "Cosmetologist": 1 },
  "85749": { "Cosmetologist": 1, "Barber": 1 },
  "85737": { "Cosmetologist": 8, "Barber": 1 },
  "70454": { "Cosmetologist": 7 },
  "90020": { "Cosmetologist": 2 },
  "44470": { "Cosmetologist": 2 },
  "77053": { "Cosmetologist": 182, "Barber": 32 },
  "75757": { "Cosmetologist": 104, "Barber": 10 },
  "46237": { "Cosmetologist": 5, "Barber": 26 },
  "89005": { "Cosmetologist": 4 },
  "17036": { "Cosmetologist": 37, "Barber": 4 },
  "27560": { "Cosmetologist": 163, "Barber": 1 },
  "60618": { "Cosmetologist": 11 },
  "76126": { "Cosmetologist": 200, "Barber": 13 },
  "93036": { "Cosmetologist": 1 },
  "54940": { "Cosmetologist": 1 },
  "92545": { "Cosmetologist": 2 },
  "92069": { "Cosmetologist": 2 },
  "80478": { "Cosmetologist": 2 },
  "64854": { "Cosmetologist": 8, "Barber": 1 },
  "26508": { "Cosmetologist": 9 },
  "76108": { "Cosmetologist": 348, "Barber": 21 },
  "83353": { "Cosmetologist": 1 },
  "83445": { "Cosmetologist": 1 },
  "54139": { "Cosmetologist": 1 },
  "16870": { "Cosmetologist": 7, "Barber": 1 },
  "65624": { "Cosmetologist": 14, "Barber": 1 },
  "97209": { "Cosmetologist": 4 },
  "99022": { "Cosmetologist": 1 },
  "37341": { "Cosmetologist": 7, "Barber": 8 },
  "40475": { "Cosmetologist": 7 },
  "85382": { "Cosmetologist": 5, "Barber": 13 },
  "58102": { "Cosmetologist": 1 },
  "85018": { "Cosmetologist": 2, "Barber": 21 },
  "95008": { "Cosmetologist": 2 },
  "98387": { "Cosmetologist": 5 },
  "92620": { "Cosmetologist": 2 },
  "35758": { "Cosmetologist": 7 },
  "58032": { "Cosmetologist": 1 },
  "68521": { "Cosmetologist": 3 },
  "48380": { "Cosmetologist": 64, "Barber": 1 },
  "04461": { "Cosmetologist": 1 },
  "46996": { "Cosmetologist": 1 },
  "08876": { "Cosmetologist": 102, "Barber": 30 },
  "60402": { "Cosmetologist": 2 },
  "84164": { "Cosmetologist": 1 },
  "78025": { "Cosmetologist": 40, "Barber": 2 },
  "92673": { "Cosmetologist": 2 },
  "95123": { "Cosmetologist": 5 },
  "49345": { "Cosmetologist": 117 },
  "60448": { "Cosmetologist": 2 },
  "68510": { "Cosmetologist": 2 },
  "29483": { "Cosmetologist": 17 },
  "52333": { "Cosmetologist": 28, "Barber": 1 },
  "08401": { "Cosmetologist": 84, "Barber": 72 },
  "92027": { "Cosmetologist": 2 },
  "73135": { "Cosmetologist": 6 },
  "87123": { "Cosmetologist": 4 },
  "60607": { "Cosmetologist": 5 },
  "09011": { "Cosmetologist": 1 },
  "68064": { "Cosmetologist": 1 },
  "35205": { "Cosmetologist": 3 },
  "77007": { "Cosmetologist": 229, "Barber": 18 },
  "48096": { "Cosmetologist": 46 },
  "87540": { "Cosmetologist": 1 },
  "55804": { "Cosmetologist": 1 },
  "61021": { "Cosmetologist": 4 },
  "99208": { "Cosmetologist": 1 },
  "23602": { "Cosmetologist": 3 },
  "59715": { "Cosmetologist": 4 },
  "78245": { "Cosmetologist": 700, "Barber": 67 },
  "47172": { "Cosmetologist": 2, "Barber": 6 },
  "37188": { "Cosmetologist": 7, "Barber": 14 },
  "75189": { "Cosmetologist": 408, "Barber": 34 },
  "72761": { "Cosmetologist": 3 },
  "22042": { "Cosmetologist": 5 },
  "44405": { "Cosmetologist": 2 },
  "49617": { "Cosmetologist": 19, "Barber": 2 },
  "65020": { "Cosmetologist": 121, "Barber": 4 },
  "28277": { "Cosmetologist": 443, "Barber": 1 },
  "87529": { "Cosmetologist": 1 },
  "89032": { "Cosmetologist": 7 },
  "77802": { "Cosmetologist": 186, "Barber": 30 },
  "57220": { "Cosmetologist": 1 },
  "49445": { "Cosmetologist": 173, "Barber": 4 },
  "75065": { "Cosmetologist": 150, "Barber": 14 },
  "92627": { "Cosmetologist": 2 },
  "55043": { "Cosmetologist": 2 },
  "48390": { "Cosmetologist": 220, "Barber": 9 },
  "55417": { "Cosmetologist": 1 },
  "29693": { "Cosmetologist": 3 },
  "82520": { "Cosmetologist": 2 },
  "92236": { "Cosmetologist": 1 },
  "73071": { "Cosmetologist": 4 },
  "81405": { "Cosmetologist": 1 },
  "59824": { "Cosmetologist": 1 },
  "95843": { "Cosmetologist": 4 },
  "76001": { "Cosmetologist": 433, "Barber": 28 },
  "51038": { "Cosmetologist": 9 },
  "15102": { "Cosmetologist": 8, "Barber": 2 },
  "92110": { "Cosmetologist": 3 },
  "42211": { "Cosmetologist": 4 },
  "95453": { "Cosmetologist": 1 },
  "97146": { "Cosmetologist": 1 },
  "92101": { "Cosmetologist": 7 },
  "70517": { "Cosmetologist": 1 },
  "84023": { "Cosmetologist": 1 },
  "19335": { "Cosmetologist": 15, "Barber": 8 },
  "77539": { "Cosmetologist": 438, "Barber": 37 },
  "76248": { "Cosmetologist": 314, "Barber": 11 },
  "73717": { "Cosmetologist": 2 },
  "50263": { "Cosmetologist": 133, "Barber": 4 },
  "77020": { "Cosmetologist": 86, "Barber": 22 },
  "73169": { "Cosmetologist": 3 },
  "68504": { "Cosmetologist": 1 },
  "29910": { "Cosmetologist": 22 },
  "78247": { "Cosmetologist": 518, "Barber": 22 },
  "29944": { "Cosmetologist": 3 },
  "36535": { "Cosmetologist": 42 },
  "80436": { "Cosmetologist": 2 },
  "92109": { "Cosmetologist": 6 },
  "76302": { "Cosmetologist": 74, "Barber": 7 },
  "62864": { "Cosmetologist": 2 },
  "53227": { "Cosmetologist": 2 },
  "92116": { "Cosmetologist": 6 },
  "11105": { "Cosmetologist": 4 },
  "78003": { "Cosmetologist": 63, "Barber": 2 },
  "63119": { "Cosmetologist": 144, "Barber": 2 },
  "49071": { "Cosmetologist": 74, "Barber": 3 },
  "60942": { "Cosmetologist": 1 },
  "94606": { "Cosmetologist": 3 },
  "75460": { "Cosmetologist": 139, "Barber": 16 },
  "75103": { "Cosmetologist": 113, "Barber": 16 },
  "77904": { "Cosmetologist": 234, "Barber": 18 },
  "78063": { "Cosmetologist": 52, "Barber": 2 },
  "18045": { "Cosmetologist": 10, "Barber": 7 },
  "38016": { "Cosmetologist": 7, "Barber": 54 },
  "19087": { "Cosmetologist": 7, "Barber": 1 },
  "37807": { "Cosmetologist": 1, "Barber": 3 },
  "91761": { "Cosmetologist": 1 },
  "92692": { "Cosmetologist": 3 },
  "99737": { "Cosmetologist": 2 },
  "55934": { "Cosmetologist": 1 },
  "08081": { "Cosmetologist": 299, "Barber": 46 },
  "84737": { "Cosmetologist": 2 },
  "53590": { "Cosmetologist": 4 },
  "55038": { "Cosmetologist": 3 },
  "29907": { "Cosmetologist": 7 },
  "69341": { "Cosmetologist": 2 },
  "27525": { "Cosmetologist": 103, "Barber": 3 },
  "59920": { "Cosmetologist": 1 },
  "95829": { "Cosmetologist": 2 },
  "38320": { "Cosmetologist": 2, "Barber": 2 },
  "38141": { "Cosmetologist": 2, "Barber": 39 },
  "92782": { "Cosmetologist": 3 },
  "96790": { "Cosmetologist": 1 },
  "99353": { "Cosmetologist": 1 },
  "91303": { "Cosmetologist": 1 },
  "68701": { "Cosmetologist": 6 },
  "83647": { "Cosmetologist": 5 },
  "83835": { "Cosmetologist": 1 },
  "81058": { "Cosmetologist": 2 },
  "97446": { "Cosmetologist": 1 },
  "39466": { "Cosmetologist": 3 },
  "40218": { "Cosmetologist": 4 },
  "59820": { "Cosmetologist": 1 },
  "98074": { "Cosmetologist": 2 },
  "84535": { "Cosmetologist": 1 },
  "54603": { "Cosmetologist": 2 },
  "98507": { "Cosmetologist": 1 },
  "99801": { "Cosmetologist": 4 },
  "20755": { "Cosmetologist": 7 },
  "48906": { "Cosmetologist": 171, "Barber": 18 },
  "94610": { "Cosmetologist": 2 },
  "98023": { "Cosmetologist": 3 },
  "95742": { "Cosmetologist": 1 },
  "20784": { "Cosmetologist": 2, "Barber": 32 },
  "67410": { "Cosmetologist": 85 },
  "99201": { "Cosmetologist": 2 },
  "19951": { "Cosmetologist": 2 },
  "55379": { "Cosmetologist": 4 },
  "82834": { "Cosmetologist": 2 },
  "97034": { "Cosmetologist": 1 },
  "77063": { "Cosmetologist": 245, "Barber": 33 },
  "37918": { "Cosmetologist": 9, "Barber": 33 },
  "97009": { "Cosmetologist": 2 },
  "97086": { "Cosmetologist": 3 },
  "55403": { "Cosmetologist": 4 },
  "37209": { "Cosmetologist": 3, "Barber": 16 },
  "58703": { "Cosmetologist": 4 },
  "74501": { "Cosmetologist": 4 },
  "72404": { "Cosmetologist": 9 },
  "53075": { "Cosmetologist": 1 },
  "17313": { "Cosmetologist": 7, "Barber": 2 },
  "43221": { "Cosmetologist": 4, "Barber": 4 },
  "78654": { "Cosmetologist": 151, "Barber": 17 },
  "92648": { "Cosmetologist": 3 },
  "97103": { "Cosmetologist": 3 },
  "83816": { "Cosmetologist": 1 },
  "56549": { "Cosmetologist": 1 },
  "66085": { "Cosmetologist": 65 },
  "59079": { "Cosmetologist": 1 },
  "07901": { "Cosmetologist": 44, "Barber": 26 },
  "27405": { "Cosmetologist": 376, "Barber": 5 },
  "06611": { "Cosmetologist": 1 },
  "08640": { "Cosmetologist": 2, "Barber": 2 },
  "67561": { "Cosmetologist": 19 },
  "63501": { "Cosmetologist": 90, "Barber": 5 },
  "47130": { "Cosmetologist": 6, "Barber": 29 },
  "52654": { "Cosmetologist": 10 },
  "99301": { "Cosmetologist": 2 },
  "44507": { "Cosmetologist": 1, "Barber": 1 },
  "67735": { "Cosmetologist": 43 },
  "08869": { "Cosmetologist": 52, "Barber": 28 },
  "02052": { "Cosmetologist": 1 },
  "77441": { "Cosmetologist": 130, "Barber": 8 },
  "88132": { "Cosmetologist": 1 },
  "27107": { "Cosmetologist": 288 },
  "87567": { "Cosmetologist": 1 },
  "30218": { "Cosmetologist": 1 },
  "35901": { "Cosmetologist": 2 },
  "23221": { "Cosmetologist": 1 },
  "69045": { "Cosmetologist": 1 },
  "35761": { "Cosmetologist": 4 },
  "47331": { "Cosmetologist": 2, "Barber": 9 },
  "27502": { "Cosmetologist": 209 },
  "29307": { "Cosmetologist": 5 },
  "06085": { "Cosmetologist": 1 },
  "80483": { "Cosmetologist": 3 },
  "73507": { "Cosmetologist": 3 },
  "45503": { "Cosmetologist": 4, "Barber": 12 },
  "90125": { "Cosmetologist": 1 },
  "66524": { "Cosmetologist": 15 },
  "76179": { "Cosmetologist": 683, "Barber": 46 },
  "92345": { "Cosmetologist": 1 },
  "81148": { "Cosmetologist": 1 },
  "80144": { "Cosmetologist": 1 },
  "19127": { "Cosmetologist": 3 },
  "97702": { "Cosmetologist": 2 },
  "60061": { "Cosmetologist": 5 },
  "59718": { "Cosmetologist": 7 },
  "76028": { "Cosmetologist": 768, "Barber": 54 },
  "77327": { "Cosmetologist": 210, "Barber": 23 },
  "22405": { "Cosmetologist": 2 },
  "69001": { "Cosmetologist": 1 },
  "29073": { "Cosmetologist": 8 },
  "21009": { "Cosmetologist": 3, "Barber": 1 },
  "96929": { "Cosmetologist": 1 },
  "85308": { "Cosmetologist": 7, "Barber": 52 },
  "22407": { "Cosmetologist": 4 },
  "60047": { "Cosmetologist": 7 },
  "86334": { "Cosmetologist": 1 },
  "21409": { "Cosmetologist": 4, "Barber": 13 },
  "00803": { "Cosmetologist": 1 },
  "15216": { "Cosmetologist": 12, "Barber": 4 },
  "49503": { "Cosmetologist": 214, "Barber": 5 },
  "78028": { "Cosmetologist": 293, "Barber": 23 },
  "20152": { "Cosmetologist": 5 },
  "92160": { "Cosmetologist": 1 },
  "63640": { "Cosmetologist": 182, "Barber": 7 },
  "72616": { "Cosmetologist": 4 },
  "76020": { "Cosmetologist": 272, "Barber": 12 },
  "83672": { "Cosmetologist": 1 },
  "87517": { "Cosmetologist": 1 },
  "99223": { "Cosmetologist": 2 },
  "19057": { "Cosmetologist": 11 },
  "85392": { "Cosmetologist": 6, "Barber": 66 },
  "76264": { "Cosmetologist": 10, "Barber": 3 },
  "85624": { "Cosmetologist": 1 },
  "15215": { "Cosmetologist": 1, "Barber": 1 },
  "81335": { "Cosmetologist": 1 },
  "90638": { "Cosmetologist": 1 },
  "60643": { "Cosmetologist": 2 },
  "78752": { "Cosmetologist": 88, "Barber": 11 },
  "86314": { "Cosmetologist": 9 },
  "98516": { "Cosmetologist": 2 },
  "18704": { "Cosmetologist": 14, "Barber": 4 },
  "83651": { "Cosmetologist": 1 },
  "98332": { "Cosmetologist": 1 },
  "62946": { "Cosmetologist": 1 },
  "58755": { "Cosmetologist": 1 },
  "75474": { "Cosmetologist": 113, "Barber": 11 },
  "87501": { "Cosmetologist": 2 },
  "93727": { "Cosmetologist": 4 },
  "85017": { "Cosmetologist": 6, "Barber": 45 },
  "43081": { "Cosmetologist": 9, "Barber": 29 },
  "23836": { "Cosmetologist": 3 },
  "56303": { "Cosmetologist": 5 },
  "85234": { "Cosmetologist": 6, "Barber": 14 },
  "09173": { "Cosmetologist": 1 },
  "99216": { "Cosmetologist": 1 },
  "68025": { "Cosmetologist": 2 },
  "78255": { "Cosmetologist": 128, "Barber": 3 },
  "46260": { "Cosmetologist": 3, "Barber": 21 },
  "81222": { "Cosmetologist": 1 },
  "98303": { "Cosmetologist": 1 },
  "76234": { "Cosmetologist": 177, "Barber": 7 },
  "86405": { "Cosmetologist": 1 },
  "95121": { "Cosmetologist": 5 },
  "68132": { "Cosmetologist": 2 },
  "58503": { "Cosmetologist": 2 },
  "17032": { "Cosmetologist": 7, "Barber": 1 },
  "44622": { "Cosmetologist": 3 },
  "68508": { "Cosmetologist": 3 },
  "94553": { "Cosmetologist": 1 },
  "69163": { "Cosmetologist": 1 },
  "51351": { "Cosmetologist": 27, "Barber": 1 },
  "75072": { "Cosmetologist": 5 },
  "81059": { "Cosmetologist": 1 },
  "80588": { "Cosmetologist": 1 },
  "89011": { "Cosmetologist": 7, "Barber": 1 },
  "48071": { "Cosmetologist": 222, "Barber": 12 },
  "06804": { "Cosmetologist": 1 },
  "81327": { "Cosmetologist": 2 },
  "90712": { "Cosmetologist": 2 },
  "06335": { "Cosmetologist": 1 },
  "67855": { "Cosmetologist": 9 },
  "97223": { "Cosmetologist": 3 },
  "18960": { "Cosmetologist": 4, "Barber": 2 },
  "38139": { "Cosmetologist": 3, "Barber": 1 },
  "86429": { "Cosmetologist": 4 },
  "77979": { "Cosmetologist": 97, "Barber": 9 },
  "78382": { "Cosmetologist": 132, "Barber": 18 },
  "77077": { "Cosmetologist": 382, "Barber": 45 },
  "55443": { "Cosmetologist": 4, "Barber": 1 },
  "11418": { "Cosmetologist": 8 },
  "23324": { "Cosmetologist": 4 },
  "68022": { "Cosmetologist": 6 },
  "80757": { "Cosmetologist": 1 },
  "82443": { "Cosmetologist": 1 },
  "82414": { "Cosmetologist": 2 },
  "72936": { "Cosmetologist": 3 },
  "96793": { "Cosmetologist": 3 },
  "99163": { "Cosmetologist": 2 },
  "92843": { "Cosmetologist": 4 },
  "29625": { "Cosmetologist": 6 },
  "95376": { "Cosmetologist": 3 },
  "92117": { "Cosmetologist": 3 },
  "03585": { "Cosmetologist": 1 },
  "17331": { "Cosmetologist": 27, "Barber": 9 },
  "60901": { "Cosmetologist": 1 },
  "52246": { "Cosmetologist": 41, "Barber": 3 },
  "17033": { "Cosmetologist": 7, "Barber": 2 },
  "68128": { "Cosmetologist": 6 },
  "20901": { "Cosmetologist": 5, "Barber": 36 },
  "78880": { "Cosmetologist": 3 },
  "46350": { "Cosmetologist": 13, "Barber": 21 },
  "94583": { "Cosmetologist": 1 },
  "62271": { "Cosmetologist": 1 },
  "51360": { "Cosmetologist": 49, "Barber": 1 },
  "68341": { "Cosmetologist": 1 },
  "21658": { "Cosmetologist": 2 },
  "66967": { "Cosmetologist": 21 },
  "27587": { "Cosmetologist": 455, "Barber": 5 },
  "49751": { "Cosmetologist": 19 },
  "90274": { "Cosmetologist": 2 },
  "85282": { "Cosmetologist": 4, "Barber": 33 },
  "69345": { "Cosmetologist": 1 },
  "70791": { "Cosmetologist": 3 },
  "20147": { "Cosmetologist": 12 },
  "90706": { "Cosmetologist": 1 },
  "02885": { "Cosmetologist": 1 },
  "74563": { "Cosmetologist": 1 },
  "85547": { "Cosmetologist": 1 },
  "82223": { "Cosmetologist": 1 },
  "65658": { "Cosmetologist": 5, "Barber": 2 },
  "95688": { "Cosmetologist": 2 },
  "79423": { "Cosmetologist": 420, "Barber": 44 },
  "20872": { "Cosmetologist": 1, "Barber": 1 },
  "81057": { "Cosmetologist": 1 },
  "65355": { "Cosmetologist": 72, "Barber": 3 },
  "62258": { "Cosmetologist": 8 },
  "77320": { "Cosmetologist": 162, "Barber": 12 },
  "44287": { "Cosmetologist": 1 },
  "97007": { "Cosmetologist": 1 },
  "66220": { "Cosmetologist": 35 },
  "75150": { "Cosmetologist": 493, "Barber": 73 },
  "06903": { "Cosmetologist": 1 },
  "38672": { "Cosmetologist": 5 },
  "96150": { "Cosmetologist": 1 },
  "56655": { "Cosmetologist": 1 },
  "92860": { "Cosmetologist": 2 },
  "94801": { "Cosmetologist": 1 },
  "89445": { "Cosmetologist": 1 },
  "85365": { "Cosmetologist": 5, "Barber": 16 },
  "96779": { "Cosmetologist": 1 },
  "81636": { "Cosmetologist": 1 },
  "44102": { "Cosmetologist": 5, "Barber": 4 },
  "83262": { "Cosmetologist": 1 },
  "22602": { "Cosmetologist": 3 },
  "98926": { "Cosmetologist": 2 },
  "63026": { "Cosmetologist": 303, "Barber": 10 },
  "59755": { "Cosmetologist": 1 },
  "85021": { "Cosmetologist": 5, "Barber": 41 },
  "82210": { "Cosmetologist": 1 },
  "80754": { "Cosmetologist": 1 },
  "16644": { "Cosmetologist": 1, "Barber": 1 },
  "02021": { "Cosmetologist": 1 },
  "77040": { "Cosmetologist": 531, "Barber": 34 },
  "78249": { "Cosmetologist": 443, "Barber": 28 },
  "60526": { "Cosmetologist": 1 },
  "73401": { "Cosmetologist": 3 },
  "84528": { "Cosmetologist": 1 },
  "08050": { "Cosmetologist": 197, "Barber": 38 },
  "84119": { "Cosmetologist": 2 },
  "84664": { "Cosmetologist": 1 },
  "28211": { "Cosmetologist": 146, "Barber": 1 },
  "65708": { "Cosmetologist": 75, "Barber": 3 },
  "83333": { "Cosmetologist": 1 },
  "98125": { "Cosmetologist": 3 },
  "84081": { "Cosmetologist": 4 },
  "43558": { "Cosmetologist": 5 },
  "66209": { "Cosmetologist": 66, "Barber": 1 },
  "65101": { "Cosmetologist": 153, "Barber": 9 },
  "83406": { "Cosmetologist": 1 },
  "22630": { "Cosmetologist": 4 },
  "78250": { "Cosmetologist": 377, "Barber": 43 },
  "79761": { "Cosmetologist": 174, "Barber": 33 },
  "35242": { "Cosmetologist": 13 },
  "49644": { "Cosmetologist": 14, "Barber": 1 },
  "94131": { "Cosmetologist": 1 },
  "89086": { "Cosmetologist": 1 },
  "95961": { "Cosmetologist": 2 },
  "28390": { "Cosmetologist": 134, "Barber": 1 },
  "72034": { "Cosmetologist": 7 },
  "46052": { "Cosmetologist": 1, "Barber": 13 },
  "37604": { "Cosmetologist": 5, "Barber": 18 },
  "02762": { "Cosmetologist": 1 },
  "81030": { "Cosmetologist": 1 },
  "92263": { "Cosmetologist": 1 },
  "55113": { "Cosmetologist": 2 },
  "71105": { "Cosmetologist": 2 },
  "38002": { "Cosmetologist": 6, "Barber": 22 },
  "92407": { "Cosmetologist": 2 },
  "02536": { "Cosmetologist": 1 },
  "67208": { "Cosmetologist": 105 },
  "67003": { "Cosmetologist": 18 },
  "66713": { "Cosmetologist": 33 },
  "67005": { "Cosmetologist": 101 },
  "66720": { "Cosmetologist": 53, "Barber": 1 },
  "66067": { "Cosmetologist": 102, "Barber": 1 },
  "67215": { "Cosmetologist": 67 },
  "66938": { "Cosmetologist": 6 },
  "67357": { "Cosmetologist": 59 },
  "66213": { "Cosmetologist": 146 },
  "66607": { "Cosmetologist": 27, "Barber": 1 },
  "66735": { "Cosmetologist": 4 },
  "66509": { "Cosmetologist": 8 },
  "67108": { "Cosmetologist": 19 },
  "67572": { "Cosmetologist": 3 },
  "66438": { "Cosmetologist": 2 },
  "67530": { "Cosmetologist": 133 },
  "66538": { "Cosmetologist": 26 },
  "66743": { "Cosmetologist": 32 },
  "67467": { "Cosmetologist": 24 },
  "66046": { "Cosmetologist": 119 },
  "66025": { "Cosmetologist": 57 },
  "67659": { "Cosmetologist": 3 },
  "67740": { "Cosmetologist": 11 },
  "66203": { "Cosmetologist": 132, "Barber": 1 },
  "66842": { "Cosmetologist": 3 },
  "67550": { "Cosmetologist": 39 },
  "66866": { "Cosmetologist": 10 },
  "67854": { "Cosmetologist": 12 },
  "66212": { "Cosmetologist": 235 },
  "67206": { "Cosmetologist": 96 },
  "66414": { "Cosmetologist": 13 },
  "67052": { "Cosmetologist": 73 },
  "66012": { "Cosmetologist": 72, "Barber": 1 },
  "66701": { "Cosmetologist": 71 },
  "67218": { "Cosmetologist": 116 },
  "67341": { "Cosmetologist": 3 },
  "67056": { "Cosmetologist": 15 },
  "67202": { "Cosmetologist": 18 },
  "67070": { "Cosmetologist": 11 },
  "67204": { "Cosmetologist": 128 },
  "66103": { "Cosmetologist": 59, "Barber": 3 },
  "66211": { "Cosmetologist": 13 },
  "67205": { "Cosmetologist": 145 },
  "67001": { "Cosmetologist": 14 },
  "67901": { "Cosmetologist": 142 },
  "67459": { "Cosmetologist": 2 },
  "66047": { "Cosmetologist": 94 },
  "66214": { "Cosmetologist": 66, "Barber": 1 },
  "66611": { "Cosmetologist": 48 },
  "67152": { "Cosmetologist": 45 },
  "67211": { "Cosmetologist": 90 },
  "67217": { "Cosmetologist": 151 },
  "66512": { "Cosmetologist": 22 },
  "66210": { "Cosmetologist": 99, "Barber": 1 },
  "67002": { "Cosmetologist": 112 },
  "67045": { "Cosmetologist": 18 },
  "67101": { "Cosmetologist": 43 },
  "67144": { "Cosmetologist": 16 },
  "67156": { "Cosmetologist": 63 },
  "67642": { "Cosmetologist": 19 },
  "67010": { "Cosmetologist": 76 },
  "67060": { "Cosmetologist": 96 },
  "66424": { "Cosmetologist": 4 },
  "67133": { "Cosmetologist": 52 },
  "66604": { "Cosmetologist": 112 },
  "67751": { "Cosmetologist": 2 },
  "66839": { "Cosmetologist": 19 },
  "66111": { "Cosmetologist": 45 },
  "67544": { "Cosmetologist": 26 },
  "67732": { "Cosmetologist": 2 },
  "67147": { "Cosmetologist": 70 },
  "67026": { "Cosmetologist": 55 },
  "66092": { "Cosmetologist": 46 },
  "66402": { "Cosmetologist": 17 },
  "67216": { "Cosmetologist": 85 },
  "67054": { "Cosmetologist": 5 },
  "66615": { "Cosmetologist": 21 },
  "66861": { "Cosmetologist": 21 },
  "66503": { "Cosmetologist": 83 },
  "66547": { "Cosmetologist": 75 },
  "66202": { "Cosmetologist": 105, "Barber": 1 },
  "67067": { "Cosmetologist": 10 },
  "66749": { "Cosmetologist": 45 },
  "66531": { "Cosmetologist": 18 },
  "66617": { "Cosmetologist": 70 },
  "67220": { "Cosmetologist": 80 },
  "66095": { "Cosmetologist": 6 },
  "66856": { "Cosmetologist": 8 },
  "67460": { "Cosmetologist": 98 },
  "67625": { "Cosmetologist": 3 },
  "66104": { "Cosmetologist": 92, "Barber": 7 },
  "66008": { "Cosmetologist": 6 },
  "66013": { "Cosmetologist": 15 },
  "66006": { "Cosmetologist": 61, "Barber": 1 },
  "66204": { "Cosmetologist": 144, "Barber": 2 },
  "67669": { "Cosmetologist": 19 },
  "66007": { "Cosmetologist": 79 },
  "66087": { "Cosmetologist": 17 },
  "67584": { "Cosmetologist": 3 },
  "66217": { "Cosmetologist": 19 },
  "66044": { "Cosmetologist": 101 },
  "66027": { "Cosmetologist": 8 },
  "67672": { "Cosmetologist": 19 },
  "67030": { "Cosmetologist": 44 },
  "67219": { "Cosmetologist": 49 },
  "66857": { "Cosmetologist": 8 },
  "66535": { "Cosmetologist": 24 },
  "66002": { "Cosmetologist": 68 },
  "66534": { "Cosmetologist": 20 },
  "67554": { "Cosmetologist": 19 },
  "66112": { "Cosmetologist": 65, "Barber": 1 },
  "66605": { "Cosmetologist": 101 },
  "66109": { "Cosmetologist": 176, "Barber": 5 },
  "66205": { "Cosmetologist": 68, "Barber": 4 },
  "66409": { "Cosmetologist": 29 },
  "67124": { "Cosmetologist": 37 },
  "67621": { "Cosmetologist": 1 },
  "66716": { "Cosmetologist": 4 },
  "67301": { "Cosmetologist": 73 },
  "67120": { "Cosmetologist": 9 },
  "67730": { "Cosmetologist": 11 },
  "66939": { "Cosmetologist": 7 },
  "66616": { "Cosmetologist": 32 },
  "67118": { "Cosmetologist": 3 },
  "66056": { "Cosmetologist": 21 },
  "66226": { "Cosmetologist": 82, "Barber": 1 },
  "67031": { "Cosmetologist": 19 },
  "67337": { "Cosmetologist": 35 },
  "67073": { "Cosmetologist": 1 },
  "66606": { "Cosmetologist": 48 },
  "67422": { "Cosmetologist": 16 },
  "67039": { "Cosmetologist": 15 },
  "67451": { "Cosmetologist": 4 },
  "66618": { "Cosmetologist": 64 },
  "67839": { "Cosmetologist": 20 },
  "67017": { "Cosmetologist": 14 },
  "67210": { "Cosmetologist": 36 },
  "66852": { "Cosmetologist": 5 },
  "66845": { "Cosmetologist": 5 },
  "66418": { "Cosmetologist": 4 },
  "66021": { "Cosmetologist": 23 },
  "67050": { "Cosmetologist": 13 },
  "66449": { "Cosmetologist": 8 },
  "67110": { "Cosmetologist": 62 },
  "66436": { "Cosmetologist": 37 },
  "67583": { "Cosmetologist": 6 },
  "67436": { "Cosmetologist": 6 },
  "66539": { "Cosmetologist": 22 },
  "67058": { "Cosmetologist": 8 },
  "67035": { "Cosmetologist": 6 },
  "66024": { "Cosmetologist": 6, "Barber": 1 },
  "66224": { "Cosmetologist": 57 },
  "66219": { "Cosmetologist": 62, "Barber": 1 },
  "66439": { "Cosmetologist": 10 },
  "66757": { "Cosmetologist": 19 },
  "67522": { "Cosmetologist": 13 },
  "67505": { "Cosmetologist": 20 },
  "66733": { "Cosmetologist": 8 },
  "67560": { "Cosmetologist": 22 },
  "67437": { "Cosmetologist": 7 },
  "66101": { "Cosmetologist": 45, "Barber": 1 },
  "66064": { "Cosmetologist": 46 },
  "66080": { "Cosmetologist": 9 },
  "67473": { "Cosmetologist": 11 },
  "66076": { "Cosmetologist": 13 },
  "66868": { "Cosmetologist": 3 },
  "66018": { "Cosmetologist": 46 },
  "67104": { "Cosmetologist": 18 },
  "67576": { "Cosmetologist": 11 },
  "67135": { "Cosmetologist": 18 },
  "67023": { "Cosmetologist": 1 },
  "67068": { "Cosmetologist": 29 },
  "67850": { "Cosmetologist": 2 },
  "66554": { "Cosmetologist": 4 },
  "66206": { "Cosmetologist": 31 },
  "66736": { "Cosmetologist": 21 },
  "67731": { "Cosmetologist": 9 },
  "66033": { "Cosmetologist": 9 },
  "66777": { "Cosmetologist": 3 },
  "66533": { "Cosmetologist": 11 },
  "66073": { "Cosmetologist": 23 },
  "67514": { "Cosmetologist": 8 },
  "66763": { "Cosmetologist": 37 },
  "67119": { "Cosmetologist": 8 },
  "67661": { "Cosmetologist": 24 },
  "66968": { "Cosmetologist": 17 },
  "67864": { "Cosmetologist": 14 },
  "67020": { "Cosmetologist": 12 },
  "66771": { "Cosmetologist": 13 },
  "67013": { "Cosmetologist": 21 },
  "67579": { "Cosmetologist": 29 },
  "67654": { "Cosmetologist": 24 },
  "67556": { "Cosmetologist": 6 },
  "67062": { "Cosmetologist": 20 },
  "66549": { "Cosmetologist": 12 },
  "66619": { "Cosmetologist": 10 },
  "66517": { "Cosmetologist": 13 },
  "67758": { "Cosmetologist": 14 },
  "67107": { "Cosmetologist": 25 },
  "67228": { "Cosmetologist": 16 },
  "66070": { "Cosmetologist": 25 },
  "66930": { "Cosmetologist": 1 },
  "67146": { "Cosmetologist": 16 },
  "67863": { "Cosmetologist": 3 },
  "67570": { "Cosmetologist": 14 },
  "67335": { "Cosmetologist": 10 },
  "67764": { "Cosmetologist": 4 },
  "66945": { "Cosmetologist": 6 },
  "66434": { "Cosmetologist": 21 },
  "66859": { "Cosmetologist": 3 },
  "67849": { "Cosmetologist": 5 },
  "67651": { "Cosmetologist": 1 },
  "66833": { "Cosmetologist": 2 },
  "66854": { "Cosmetologist": 5 },
  "66781": { "Cosmetologist": 9 },
  "66751": { "Cosmetologist": 7 },
  "66041": { "Cosmetologist": 5 },
  "67860": { "Cosmetologist": 19 },
  "67439": { "Cosmetologist": 14 },
  "67480": { "Cosmetologist": 17 },
  "66937": { "Cosmetologist": 4 },
  "67546": { "Cosmetologist": 20 },
  "66428": { "Cosmetologist": 1 },
  "66717": { "Cosmetologist": 5 },
  "66050": { "Cosmetologist": 10 },
  "67543": { "Cosmetologist": 9 },
  "66835": { "Cosmetologist": 4 },
  "66079": { "Cosmetologist": 6 },
  "66748": { "Cosmetologist": 13 },
  "67481": { "Cosmetologist": 3 },
  "66010": { "Cosmetologist": 4 },
  "66849": { "Cosmetologist": 3 },
  "66032": { "Cosmetologist": 23 },
  "67838": { "Cosmetologist": 6 },
  "67112": { "Cosmetologist": 1 },
  "66770": { "Cosmetologist": 14 },
  "66542": { "Cosmetologist": 23 },
  "67441": { "Cosmetologist": 12 },
  "67151": { "Cosmetologist": 1 },
  "66755": { "Cosmetologist": 6 },
  "67525": { "Cosmetologist": 6 },
  "67547": { "Cosmetologist": 18 },
  "67428": { "Cosmetologist": 9 },
  "67333": { "Cosmetologist": 12 },
  "66846": { "Cosmetologist": 18 },
  "66523": { "Cosmetologist": 23 },
  "67951": { "Cosmetologist": 28 },
  "66090": { "Cosmetologist": 13 },
  "67025": { "Cosmetologist": 31 },
  "66725": { "Cosmetologist": 39 },
  "66054": { "Cosmetologist": 19 },
  "67520": { "Cosmetologist": 2 },
  "67127": { "Cosmetologist": 8 },
  "67876": { "Cosmetologist": 8 },
  "66016": { "Cosmetologist": 6 },
  "67214": { "Cosmetologist": 54 },
  "67557": { "Cosmetologist": 4 },
  "67757": { "Cosmetologist": 3 },
  "67444": { "Cosmetologist": 4 },
  "66712": { "Cosmetologist": 14 },
  "67149": { "Cosmetologist": 11 },
  "67074": { "Cosmetologist": 11 },
  "66710": { "Cosmetologist": 3 },
  "66603": { "Cosmetologist": 7 },
  "67431": { "Cosmetologist": 13 },
  "66782": { "Cosmetologist": 1 },
  "67351": { "Cosmetologist": 2 },
  "67663": { "Cosmetologist": 31 },
  "67671": { "Cosmetologist": 11 },
  "67738": { "Cosmetologist": 6 },
  "66042": { "Cosmetologist": 8 },
  "66960": { "Cosmetologist": 1 },
  "66020": { "Cosmetologist": 15 },
  "66853": { "Cosmetologist": 2 },
  "67835": { "Cosmetologist": 25 },
  "66612": { "Cosmetologist": 4 },
  "66783": { "Cosmetologist": 21 },
  "66075": { "Cosmetologist": 12 },
  "66864": { "Cosmetologist": 2 },
  "67844": { "Cosmetologist": 11 },
  "67029": { "Cosmetologist": 9 },
  "66053": { "Cosmetologist": 60, "Barber": 1 },
  "66088": { "Cosmetologist": 20 },
  "67004": { "Cosmetologist": 2 },
  "66051": { "Cosmetologist": 2 },
  "67427": { "Cosmetologist": 2 },
  "67361": { "Cosmetologist": 9 },
  "67466": { "Cosmetologist": 4 },
  "66756": { "Cosmetologist": 8 },
  "67447": { "Cosmetologist": 5 },
  "67455": { "Cosmetologist": 10 },
  "67548": { "Cosmetologist": 16 },
  "67336": { "Cosmetologist": 5 },
  "67545": { "Cosmetologist": 1 },
  "67330": { "Cosmetologist": 10 },
  "67657": { "Cosmetologist": 1 },
  "66440": { "Cosmetologist": 13 },
  "66953": { "Cosmetologist": 4 },
  "67332": { "Cosmetologist": 2 },
  "67016": { "Cosmetologist": 8 },
  "67646": { "Cosmetologist": 5 },
  "67737": { "Cosmetologist": 6 },
  "66419": { "Cosmetologist": 3 },
  "67159": { "Cosmetologist": 2 },
  "66507": { "Cosmetologist": 7 },
  "66952": { "Cosmetologist": 4 },
  "67575": { "Cosmetologist": 5 },
  "67356": { "Cosmetologist": 17 },
  "67423": { "Cosmetologist": 3 },
  "67567": { "Cosmetologist": 2 },
  "66932": { "Cosmetologist": 1 },
  "67449": { "Cosmetologist": 17 },
  "67950": { "Cosmetologist": 10 },
  "66779": { "Cosmetologist": 3 },
  "66078": { "Cosmetologist": 6 },
  "67745": { "Cosmetologist": 1 },
  "66425": { "Cosmetologist": 5 },
  "66548": { "Cosmetologist": 3 },
  "67841": { "Cosmetologist": 5 },
  "66724": { "Cosmetologist": 5 },
  "67753": { "Cosmetologist": 6 },
  "67747": { "Cosmetologist": 2 },
  "66066": { "Cosmetologist": 21 },
  "67643": { "Cosmetologist": 1 },
  "66780": { "Cosmetologist": 4 },
  "67632": { "Cosmetologist": 3 },
  "67342": { "Cosmetologist": 6 },
  "67870": { "Cosmetologist": 8 },
  "67063": { "Cosmetologist": 22 },
  "67905": { "Cosmetologist": 5 },
  "67859": { "Cosmetologist": 1 },
  "66935": { "Cosmetologist": 13 },
  "66432": { "Cosmetologist": 2 },
  "66023": { "Cosmetologist": 6 },
  "67138": { "Cosmetologist": 4 },
  "67490": { "Cosmetologist": 8 },
  "67450": { "Cosmetologist": 4 },
  "66958": { "Cosmetologist": 2 },
  "67564": { "Cosmetologist": 2 },
  "66516": { "Cosmetologist": 2 },
  "67457": { "Cosmetologist": 7 },
  "66035": { "Cosmetologist": 15 },
  "67882": { "Cosmetologist": 6 },
  "67831": { "Cosmetologist": 10 },
  "66734": { "Cosmetologist": 2 },
  "67484": { "Cosmetologist": 3 },
  "67869": { "Cosmetologist": 9 },
  "66546": { "Cosmetologist": 6 },
  "66416": { "Cosmetologist": 2 },
  "67232": { "Cosmetologist": 1 },
  "67524": { "Cosmetologist": 5 },
  "67635": { "Cosmetologist": 3 },
  "66551": { "Cosmetologist": 1 },
  "66753": { "Cosmetologist": 8 },
  "66776": { "Cosmetologist": 9 },
  "66417": { "Cosmetologist": 5 },
  "66544": { "Cosmetologist": 3 },
  "66508": { "Cosmetologist": 24 },
  "67448": { "Cosmetologist": 9 },
  "67867": { "Cosmetologist": 7 },
  "67650": { "Cosmetologist": 1 },
  "67464": { "Cosmetologist": 2 },
  "67622": { "Cosmetologist": 3 },
  "67443": { "Cosmetologist": 10 },
  "67483": { "Cosmetologist": 2 },
  "67223": { "Cosmetologist": 4 },
  "66966": { "Cosmetologist": 6 },
  "67227": { "Cosmetologist": 5 },
  "66834": { "Cosmetologist": 9 },
  "66072": { "Cosmetologist": 10 },
  "66427": { "Cosmetologist": 12 },
  "66711": { "Cosmetologist": 6 },
  "67458": { "Cosmetologist": 2 },
  "66015": { "Cosmetologist": 3 },
  "67529": { "Cosmetologist": 2 },
  "66843": { "Cosmetologist": 3 },
  "67649": { "Cosmetologist": 2 },
  "67038": { "Cosmetologist": 3 },
  "66052": { "Cosmetologist": 12 },
  "66739": { "Cosmetologist": 39 },
  "67103": { "Cosmetologist": 3 },
  "66406": { "Cosmetologist": 3 },
  "66285": { "Cosmetologist": 3 },
  "67349": { "Cosmetologist": 10 },
  "67834": { "Cosmetologist": 7 },
  "66423": { "Cosmetologist": 4 },
  "66431": { "Cosmetologist": 3 },
  "67041": { "Cosmetologist": 2 },
  "66039": { "Cosmetologist": 3 },
  "67445": { "Cosmetologist": 1 },
  "67137": { "Cosmetologist": 5 },
  "66865": { "Cosmetologist": 4 },
  "67442": { "Cosmetologist": 2 },
  "67363": { "Cosmetologist": 3 },
  "67523": { "Cosmetologist": 1 },
  "66413": { "Cosmetologist": 8 },
  "67626": { "Cosmetologist": 1 },
  "66773": { "Cosmetologist": 5 },
  "67022": { "Cosmetologist": 9 },
  "67009": { "Cosmetologist": 2 },
  "66282": { "Cosmetologist": 1 },
  "67566": { "Cosmetologist": 2 },
  "66105": { "Cosmetologist": 6, "Barber": 1 },
  "67123": { "Cosmetologist": 5 },
  "66060": { "Cosmetologist": 7 },
  "66520": { "Cosmetologist": 6 },
  "67518": { "Cosmetologist": 2 },
  "66840": { "Cosmetologist": 3 },
  "67653": { "Cosmetologist": 3 },
  "67051": { "Cosmetologist": 3 },
  "67733": { "Cosmetologist": 1 },
  "67340": { "Cosmetologist": 3 },
  "67425": { "Cosmetologist": 2 },
  "66940": { "Cosmetologist": 2 },
  "67749": { "Cosmetologist": 10 },
  "67638": { "Cosmetologist": 2 },
  "67552": { "Cosmetologist": 9 },
  "67468": { "Cosmetologist": 3 },
  "66871": { "Cosmetologist": 11 },
  "66869": { "Cosmetologist": 3 },
  "66514": { "Cosmetologist": 9 },
  "66451": { "Cosmetologist": 12 },
  "67008": { "Cosmetologist": 2 },
  "67510": { "Cosmetologist": 2 },
  "67344": { "Cosmetologist": 3 },
  "67061": { "Cosmetologist": 1 },
  "67865": { "Cosmetologist": 4 },
  "66058": { "Cosmetologist": 2 },
  "67511": { "Cosmetologist": 1 },
  "67752": { "Cosmetologist": 6 },
  "66754": { "Cosmetologist": 2 },
  "67416": { "Cosmetologist": 10 },
  "67154": { "Cosmetologist": 10 },
  "67066": { "Cosmetologist": 1 },
  "66093": { "Cosmetologist": 2 },
  "66408": { "Cosmetologist": 4 },
  "67132": { "Cosmetologist": 2 },
  "66741": { "Cosmetologist": 1 },
  "66536": { "Cosmetologist": 14 },
  "66403": { "Cosmetologist": 5 },
  "66521": { "Cosmetologist": 6 },
  "66870": { "Cosmetologist": 1 },
  "66942": { "Cosmetologist": 3 },
  "66415": { "Cosmetologist": 6 },
  "67019": { "Cosmetologist": 5 },
  "67629": { "Cosmetologist": 1 },
  "67053": { "Cosmetologist": 2 },
  "67065": { "Cosmetologist": 1 },
  "66943": { "Cosmetologist": 2 },
  "67640": { "Cosmetologist": 7 },
  "67417": { "Cosmetologist": 2 },
  "67504": { "Cosmetologist": 3 },
  "66552": { "Cosmetologist": 3 },
  "67853": { "Cosmetologist": 5 },
  "66026": { "Cosmetologist": 4 },
  "67355": { "Cosmetologist": 3 },
  "66872": { "Cosmetologist": 2 },
  "67106": { "Cosmetologist": 4 },
  "67470": { "Cosmetologist": 2 },
  "66510": { "Cosmetologist": 4 },
  "66014": { "Cosmetologist": 3 },
  "66541": { "Cosmetologist": 2 },
  "67059": { "Cosmetologist": 4 },
  "67475": { "Cosmetologist": 2 },
  "66411": { "Cosmetologist": 6 },
  "67563": { "Cosmetologist": 1 },
  "67140": { "Cosmetologist": 3 },
  "67047": { "Cosmetologist": 4 },
  "67430": { "Cosmetologist": 4 },
  "66769": { "Cosmetologist": 3 },
  "66040": { "Cosmetologist": 17 },
  "67673": { "Cosmetologist": 1 },
  "67354": { "Cosmetologist": 2 },
  "67664": { "Cosmetologist": 3 },
  "67952": { "Cosmetologist": 3 },
  "66742": { "Cosmetologist": 3 },
  "66528": { "Cosmetologist": 2 },
  "66526": { "Cosmetologist": 5 },
  "67639": { "Cosmetologist": 2 },
  "66873": { "Cosmetologist": 3 },
  "67741": { "Cosmetologist": 1 },
  "67131": { "Cosmetologist": 1 },
  "66522": { "Cosmetologist": 2 },
  "66532": { "Cosmetologist": 4 },
  "67674": { "Cosmetologist": 1 },
  "67111": { "Cosmetologist": 2 },
  "67743": { "Cosmetologist": 2 },
  "67512": { "Cosmetologist": 1 },
  "66941": { "Cosmetologist": 1 },
  "66404": { "Cosmetologist": 4 },
  "67353": { "Cosmetologist": 4 },
  "67233": { "Cosmetologist": 1 },
  "66422": { "Cosmetologist": 1 },
  "67631": { "Cosmetologist": 4 },
  "67761": { "Cosmetologist": 1 },
  "66772": { "Cosmetologist": 2 },
  "67491": { "Cosmetologist": 3 },
  "66851": { "Cosmetologist": 3 },
  "67277": { "Cosmetologist": 3 },
  "66412": { "Cosmetologist": 1 },
  "67578": { "Cosmetologist": 6 },
  "66537": { "Cosmetologist": 3 },
  "67024": { "Cosmetologist": 3 },
  "67634": { "Cosmetologist": 1 },
  "67487": { "Cosmetologist": 4 },
  "66962": { "Cosmetologist": 3 },
  "67574": { "Cosmetologist": 2 },
  "67648": { "Cosmetologist": 4 },
  "66601": { "Cosmetologist": 2 },
  "67736": { "Cosmetologist": 1 },
  "66407": { "Cosmetologist": 3 },
  "66097": { "Cosmetologist": 7, "Barber": 1 },
  "67364": { "Cosmetologist": 1 },
  "67667": { "Cosmetologist": 3 },
  "67628": { "Cosmetologist": 1 },
  "67492": { "Cosmetologist": 1 },
  "66063": { "Cosmetologist": 2 },
  "66767": { "Cosmetologist": 4 },
  "67953": { "Cosmetologist": 1 },
  "67868": { "Cosmetologist": 2 },
  "67623": { "Cosmetologist": 2 },
  "66505": { "Cosmetologist": 2 },
  "67446": { "Cosmetologist": 2 },
  "67762": { "Cosmetologist": 2 },
  "66758": { "Cosmetologist": 2 },
  "67581": { "Cosmetologist": 2 },
  "67134": { "Cosmetologist": 2 },
  "67842": { "Cosmetologist": 1 },
  "67307": { "Cosmetologist": 1 },
  "66518": { "Cosmetologist": 1 },
  "60062": { "Cosmetologist": 8 },
  "73173": { "Cosmetologist": 1 },
  "66761": { "Cosmetologist": 3 },
  "67645": { "Cosmetologist": 2 },
  "67565": { "Cosmetologist": 2 },
  "66017": { "Cosmetologist": 1 },
  "57548": { "Cosmetologist": 1 },
  "66963": { "Cosmetologist": 1 },
  "67954": { "Cosmetologist": 2 },
  "67072": { "Cosmetologist": 1 },
  "67485": { "Cosmetologist": 2 },
  "66740": { "Cosmetologist": 1 },
  "66094": { "Cosmetologist": 2 },
  "66858": { "Cosmetologist": 1 },
  "66949": { "Cosmetologist": 3 },
  "66286": { "Cosmetologist": 1 },
  "66540": { "Cosmetologist": 1 },
  "66760": { "Cosmetologist": 1 },
  "67568": { "Cosmetologist": 1 },
  "66201": { "Cosmetologist": 1 },
  "66675": { "Cosmetologist": 1 },
  "67352": { "Cosmetologist": 2 },
  "67454": { "Cosmetologist": 2 },
  "66850": { "Cosmetologist": 2 },
  "67553": { "Cosmetologist": 1 },
  "67656": { "Cosmetologist": 1 },
  "66091": { "Cosmetologist": 1 },
  "66944": { "Cosmetologist": 2 },
  "67278": { "Cosmetologist": 1 },
  "66225": { "Cosmetologist": 1 },
  "66951": { "Cosmetologist": 1 },
  "66964": { "Cosmetologist": 1 },
  "67837": { "Cosmetologist": 1 },
  "67585": { "Cosmetologist": 1 },
  "66948": { "Cosmetologist": 1 },
  "66550": { "Cosmetologist": 1 },
  "43223": { "Barber": 3, "Cosmetologist": 1 },
  "43228": { "Barber": 5, "Cosmetologist": 5 },
  "43224": { "Barber": 6, "Cosmetologist": 2 },
  "43203": { "Barber": 2, "Cosmetologist": 2 },
  "43204": { "Barber": 3, "Cosmetologist": 6 },
  "43205": { "Barber": 3, "Cosmetologist": 1 },
  "43202": { "Barber": 2 },
  "43212": { "Barber": 1 },
  "43201": { "Barber": 1 },
  "43211": { "Barber": 3, "Cosmetologist": 2 },
  "43232": { "Barber": 7, "Cosmetologist": 2 },
  "43240": { "Barber": 2 },
  "43215": { "Barber": 1, "Cosmetologist": 51 },
  "43209": { "Barber": 2, "Cosmetologist": 7 },
  "43214": { "Barber": 2, "Cosmetologist": 2 },
  "43219": { "Barber": 5, "Cosmetologist": 6 },
  "43231": { "Barber": 1, "Cosmetologist": 1 },
  "43222": { "Barber": 1 },
  "43229": { "Barber": 5, "Cosmetologist": 2 },
  "43220": { "Barber": 2, "Cosmetologist": 1 },
  "44109": { "Barber": 8, "Cosmetologist": 2 },
  "44105": { "Barber": 5, "Cosmetologist": 1 },
  "44121": { "Barber": 23 },
  "44108": { "Barber": 4, "Cosmetologist": 2 },
  "44128": { "Barber": 16 },
  "44125": { "Barber": 1, "Cosmetologist": 2 },
  "44103": { "Barber": 4, "Cosmetologist": 2 },
  "44123": { "Barber": 13 },
  "44110": { "Barber": 4, "Cosmetologist": 1 },
  "44120": { "Barber": 3, "Cosmetologist": 1 },
  "44104": { "Barber": 2, "Cosmetologist": 1 },
  "44127": { "Barber": 1 },
  "44115": { "Barber": 1, "Cosmetologist": 1 },
  "44106": { "Barber": 4 },
  "44137": { "Barber": 1, "Cosmetologist": 6 },
  "44119": { "Barber": 10 },
  "44144": { "Barber": 1, "Cosmetologist": 3 },
  "44130": { "Barber": 24, "Cosmetologist": 4 },
  "44126": { "Barber": 1 },
  "44114": { "Barber": 2, "Cosmetologist": 1 },
  "45237": { "Barber": 1 },
  "45232": { "Barber": 3, "Cosmetologist": 1 },
  "45204": { "Barber": 2 },
  "45244": { "Barber": 1, "Cosmetologist": 4 },
  "45239": { "Barber": 6, "Cosmetologist": 3 },
  "45251": { "Barber": 4, "Cosmetologist": 2 },
  "45208": { "Barber": 1, "Cosmetologist": 1 },
  "45224": { "Barber": 4, "Cosmetologist": 2 },
  "45206": { "Barber": 1, "Cosmetologist": 2 },
  "45212": { "Barber": 2, "Cosmetologist": 2 },
  "45205": { "Barber": 1, "Cosmetologist": 1 },
  "45202": { "Barber": 1, "Cosmetologist": 4 },
  "45242": { "Barber": 1, "Cosmetologist": 3 },
  "45247": { "Barber": 1 },
  "45218": { "Barber": 1 },
  "45214": { "Barber": 1 },
  "45241": { "Barber": 2, "Cosmetologist": 3 },
  "45246": { "Barber": 2, "Cosmetologist": 24 },
  "45209": { "Barber": 1 },
  "45207": { "Barber": 1, "Cosmetologist": 1 },
  "45236": { "Barber": 1, "Cosmetologist": 2 },
  "45219": { "Barber": 2, "Cosmetologist": 1 },
  "45223": { "Barber": 1 },
  "45255": { "Barber": 1 },
  "43615": { "Barber": 13, "Cosmetologist": 14 },
  "43613": { "Barber": 8, "Cosmetologist": 22 },
  "43609": { "Barber": 6, "Cosmetologist": 2 },
  "43605": { "Barber": 5, "Cosmetologist": 6 },
  "43610": { "Barber": 4, "Cosmetologist": 2 },
  "43606": { "Barber": 4, "Cosmetologist": 7 },
  "43611": { "Barber": 4, "Cosmetologist": 15 },
  "43607": { "Barber": 4, "Cosmetologist": 2 },
  "43620": { "Barber": 1, "Cosmetologist": 2 },
  "43614": { "Barber": 4, "Cosmetologist": 3 },
  "43608": { "Barber": 5, "Cosmetologist": 2 },
  "43616": { "Barber": 1, "Cosmetologist": 3 },
  "43617": { "Barber": 3, "Cosmetologist": 5 },
  "44319": { "Barber": 4, "Cosmetologist": 1 },
  "44307": { "Barber": 4, "Cosmetologist": 1 },
  "44301": { "Barber": 5, "Cosmetologist": 1 },
  "44312": { "Barber": 6, "Cosmetologist": 2 },
  "44305": { "Barber": 6 },
  "44310": { "Barber": 5, "Cosmetologist": 1 },
  "44308": { "Barber": 1 },
  "44314": { "Barber": 1, "Cosmetologist": 1 },
  "45419": { "Barber": 4, "Cosmetologist": 1 },
  "45406": { "Barber": 8, "Cosmetologist": 3 },
  "45420": { "Barber": 14, "Cosmetologist": 1 },
  "45415": { "Barber": 1, "Cosmetologist": 2 },
  "45410": { "Barber": 2 },
  "45402": { "Barber": 5 },
  "45459": { "Barber": 1, "Cosmetologist": 2 },
  "45417": { "Barber": 5 },
  "45449": { "Barber": 1, "Cosmetologist": 2 },
  "45429": { "Barber": 8, "Cosmetologist": 3 },
  "45403": { "Barber": 2, "Cosmetologist": 1 },
  "45408": { "Barber": 1 },
  "45426": { "Barber": 1, "Cosmetologist": 4 },
  "45416": { "Barber": 1 },
  "45458": { "Barber": 1, "Cosmetologist": 8 },
  "45439": { "Barber": 3, "Cosmetologist": 2 },
  "44134": { "Barber": 27, "Cosmetologist": 4 },
  "44129": { "Barber": 22, "Cosmetologist": 3 },
  "44704": { "Barber": 3 },
  "44708": { "Barber": 8, "Cosmetologist": 3 },
  "44718": { "Barber": 6, "Cosmetologist": 1 },
  "44709": { "Barber": 12 },
  "44720": { "Barber": 9, "Cosmetologist": 5 },
  "44703": { "Barber": 2 },
  "44721": { "Barber": 5 },
  "44705": { "Barber": 5 },
  "44714": { "Barber": 3 },
  "44707": { "Barber": 3 },
  "44730": { "Barber": 2 },
  "44710": { "Barber": 1 },
  "44055": { "Barber": 19 },
  "44053": { "Barber": 23, "Cosmetologist": 4 },
  "44052": { "Barber": 14, "Cosmetologist": 4 },
  "45013": { "Barber": 30, "Cosmetologist": 2 },
  "45015": { "Barber": 6 },
  "44506": { "Barber": 2 },
  "44512": { "Barber": 14, "Cosmetologist": 8 },
  "44515": { "Barber": 4, "Cosmetologist": 3 },
  "44505": { "Barber": 10, "Cosmetologist": 1 },
  "44511": { "Barber": 12, "Cosmetologist": 4 },
  "44509": { "Barber": 4, "Cosmetologist": 1 },
  "44504": { "Barber": 1 },
  "44510": { "Barber": 1 },
  "45504": { "Barber": 10 },
  "45506": { "Barber": 13 },
  "45505": { "Barber": 11 },
  "45502": { "Barber": 2 },
  "43925": { "Barber": 1 },
  "44443": { "Barber": 2 },
  "44035": { "Barber": 28, "Cosmetologist": 3 },
  "44221": { "Barber": 13, "Cosmetologist": 1 },
  "43812": { "Barber": 1, "Cosmetologist": 2 },
  "44107": { "Barber": 26, "Cosmetologist": 5 },
  "45042": { "Barber": 19, "Cosmetologist": 4 },
  "44442": { "Barber": 5, "Cosmetologist": 1 },
  "43055": { "Barber": 28, "Cosmetologist": 4 },
  "43056": { "Barber": 2, "Cosmetologist": 1 },
  "43058": { "Barber": 1 },
  "44117": { "Barber": 9, "Cosmetologist": 1 },
  "44132": { "Barber": 7, "Cosmetologist": 1 },
  "44118": { "Barber": 12, "Cosmetologist": 2 },
  "44112": { "Barber": 2 },
  "44143": { "Barber": 1, "Cosmetologist": 5 },
  "43017": { "Barber": 10, "Cosmetologist": 6 },
  "43016": { "Barber": 12, "Cosmetologist": 19 },
  "44906": { "Barber": 5, "Cosmetologist": 4 },
  "44902": { "Barber": 9 },
  "44904": { "Barber": 4, "Cosmetologist": 1 },
  "44901": { "Barber": 12 },
  "44905": { "Barber": 2, "Cosmetologist": 1 },
  "43358": { "Barber": 1 },
  "44907": { "Barber": 2, "Cosmetologist": 2 },
  "44857": { "Barber": 1, "Cosmetologist": 2 },
  "44095": { "Barber": 1, "Cosmetologist": 4 },
  "45432": { "Barber": 2, "Cosmetologist": 1 },
  "45434": { "Barber": 1, "Cosmetologist": 2 },
  "44136": { "Barber": 23, "Cosmetologist": 4 },
  "44149": { "Barber": 13, "Cosmetologist": 3 },
  "45014": { "Barber": 23, "Cosmetologist": 5 },
  "45018": { "Barber": 1 },
  "43015": { "Barber": 25, "Cosmetologist": 5 },
  "43130": { "Barber": 24, "Cosmetologist": 1 },
  "45840": { "Barber": 13, "Cosmetologist": 4 },
  "43068": { "Barber": 69, "Cosmetologist": 6 },
  "44483": { "Barber": 8, "Cosmetologist": 4 },
  "44485": { "Barber": 12 },
  "44481": { "Barber": 3, "Cosmetologist": 1 },
  "43082": { "Barber": 19, "Cosmetologist": 5 },
  "43086": { "Barber": 1 },
  "43021": { "Barber": 1, "Cosmetologist": 1 },
  "45802": { "Barber": 1 },
  "45801": { "Barber": 14 },
  "45807": { "Barber": 4 },
  "45806": { "Barber": 2, "Cosmetologist": 3 },
  "45805": { "Barber": 5 },
  "45804": { "Barber": 2 },
  "43301": { "Barber": 2 },
  "43302": { "Barber": 29, "Cosmetologist": 3 },
  "21207": { "Barber": 39, "Cosmetologist": 4 },
  "21223": { "Barber": 21, "Cosmetologist": 2 },
  "21212": { "Barber": 22, "Cosmetologist": 2 },
  "20677": { "Barber": 1, "Cosmetologist": 1 },
  "21205": { "Barber": 9 },
  "21244": { "Barber": 11 },
  "21217": { "Barber": 20, "Cosmetologist": 2 },
  "21215": { "Barber": 54, "Cosmetologist": 1 },
  "21214": { "Barber": 17, "Cosmetologist": 2 },
  "21206": { "Barber": 46, "Cosmetologist": 6 },
  "21216": { "Barber": 26 },
  "21222": { "Barber": 34, "Cosmetologist": 2 },
  "21227": { "Barber": 21, "Cosmetologist": 3 },
  "21213": { "Barber": 22, "Cosmetologist": 1 },
  "21218": { "Barber": 33 },
  "21234": { "Barber": 38, "Cosmetologist": 3 },
  "21211": { "Barber": 14, "Cosmetologist": 1 },
  "21221": { "Barber": 21, "Cosmetologist": 2 },
  "21236": { "Barber": 13, "Cosmetologist": 2 },
  "21231": { "Barber": 8, "Cosmetologist": 1 },
  "21224": { "Barber": 34, "Cosmetologist": 2 },
  "21230": { "Barber": 19 },
  "21228": { "Barber": 7, "Cosmetologist": 4 },
  "21239": { "Barber": 32, "Cosmetologist": 1 },
  "21226": { "Barber": 4 },
  "21133": { "Barber": 1, "Cosmetologist": 6 },
  "21208": { "Barber": 7 },
  "21201": { "Barber": 3, "Cosmetologist": 2 },
  "21282": { "Barber": 1 },
  "21202": { "Barber": 16, "Cosmetologist": 1 },
  "21203": { "Barber": 2 },
  "21209": { "Barber": 7, "Cosmetologist": 3 },
  "20740": { "Barber": 18, "Cosmetologist": 1 },
  "21128": { "Barber": 2, "Cosmetologist": 3 },
  "21117": { "Barber": 1, "Cosmetologist": 1 },
  "21136": { "Barber": 1, "Cosmetologist": 4 },
  "21041": { "Barber": 1 },
  "21219": { "Barber": 1 },
  "20851": { "Barber": 13 },
  "20850": { "Barber": 14, "Cosmetologist": 1 },
  "20853": { "Barber": 28 },
  "20855": { "Barber": 3, "Cosmetologist": 2 },
  "20852": { "Barber": 7, "Cosmetologist": 18 },
  "20849": { "Barber": 1 },
  "21703": { "Barber": 23, "Cosmetologist": 5 },
  "20174": { "Barber": 1 },
  "21702": { "Barber": 25, "Cosmetologist": 5 },
  "21705": { "Barber": 1 },
  "21704": { "Barber": 3, "Cosmetologist": 3 },
  "21403": { "Barber": 24, "Cosmetologist": 4 },
  "20878": { "Barber": 23, "Cosmetologist": 9 },
  "20879": { "Barber": 25, "Cosmetologist": 2 },
  "20874": { "Barber": 1, "Cosmetologist": 11 },
  "20741": { "Barber": 2 },
  "21804": { "Barber": 19, "Cosmetologist": 2 },
  "21801": { "Barber": 9, "Cosmetologist": 3 },
  "21802": { "Barber": 1 },
  "21742": { "Barber": 19, "Cosmetologist": 3 },
  "20707": { "Barber": 46, "Cosmetologist": 4 },
  "20708": { "Barber": 25, "Cosmetologist": 6 },
  "20723": { "Barber": 26, "Cosmetologist": 5 },
  "20724": { "Barber": 20, "Cosmetologist": 4 },
  "19956": { "Barber": 2, "Cosmetologist": 3 },
  "20706": { "Barber": 1, "Cosmetologist": 4 },
  "20785": { "Barber": 15, "Cosmetologist": 3 },
  "20770": { "Barber": 23, "Cosmetologist": 1 },
  "20715": { "Barber": 21, "Cosmetologist": 2 },
  "20716": { "Barber": 31, "Cosmetologist": 2 },
  "20720": { "Barber": 21, "Cosmetologist": 3 },
  "20718": { "Barber": 2 },
  "20902": { "Barber": 28, "Cosmetologist": 2 },
  "20906": { "Barber": 63, "Cosmetologist": 3 },
  "20905": { "Barber": 19, "Cosmetologist": 2 },
  "20903": { "Barber": 23, "Cosmetologist": 2 },
  "20918": { "Barber": 1 },
  "21502": { "Barber": 4, "Cosmetologist": 2 },
  "20783": { "Barber": 29, "Cosmetologist": 1 },
  "20781": { "Barber": 9, "Cosmetologist": 1 },
  "20782": { "Barber": 22, "Cosmetologist": 2 },
  "21157": { "Barber": 15, "Cosmetologist": 2 },
  "21158": { "Barber": 8, "Cosmetologist": 1 },
  "21001": { "Barber": 25, "Cosmetologist": 2 },
  "21005": { "Barber": 2 },
  "20886": { "Barber": 13, "Cosmetologist": 3 },
  "21078": { "Barber": 14, "Cosmetologist": 1 },
  "20714": { "Barber": 1, "Cosmetologist": 1 },
  "46118": { "Barber": 2 },
  "46323": { "Barber": 22 },
  "47368": { "Barber": 1 },
  "46240": { "Barber": 10, "Cosmetologist": 3 },
  "46203": { "Barber": 19, "Cosmetologist": 5 },
  "46506": { "Barber": 1, "Cosmetologist": 2 },
  "46403": { "Barber": 15 },
  "46113": { "Barber": 11, "Cosmetologist": 1 },
  "47879": { "Barber": 2 },
  "46228": { "Barber": 22, "Cosmetologist": 2 },
  "46517": { "Barber": 4, "Cosmetologist": 5 },
  "46222": { "Barber": 33, "Cosmetologist": 1 },
  "46322": { "Barber": 12 },
  "46016": { "Barber": 13 },
  "46219": { "Barber": 27 },
  "46256": { "Barber": 16, "Cosmetologist": 2 },
  "46319": { "Barber": 14 },
  "47404": { "Barber": 9 },
  "46554": { "Barber": 4 },
  "46214": { "Barber": 26, "Cosmetologist": 4 },
  "46375": { "Barber": 15, "Cosmetologist": 3 },
  "46135": { "Barber": 7 },
  "46516": { "Barber": 9, "Cosmetologist": 2 },
  "46038": { "Barber": 13, "Cosmetologist": 8 },
  "46341": { "Barber": 5, "Cosmetologist": 1 },
  "46936": { "Barber": 1, "Cosmetologist": 1 },
  "46224": { "Barber": 33, "Cosmetologist": 4 },
  "47446": { "Barber": 4 },
  "46268": { "Barber": 36, "Cosmetologist": 4 },
  "47977": { "Barber": 2 },
  "47557": { "Barber": 1 },
  "46011": { "Barber": 15, "Cosmetologist": 1 },
  "46278": { "Barber": 7, "Cosmetologist": 1 },
  "46037": { "Barber": 16, "Cosmetologist": 6 },
  "46168": { "Barber": 18, "Cosmetologist": 3 },
  "46142": { "Barber": 18, "Cosmetologist": 4 },
  "47260": { "Barber": 3 },
  "46411": { "Barber": 1 },
  "46410": { "Barber": 65, "Cosmetologist": 6 },
  "47448": { "Barber": 1, "Cosmetologist": 3 },
  "46404": { "Barber": 29, "Cosmetologist": 1 },
  "46368": { "Barber": 20, "Cosmetologist": 5 },
  "46221": { "Barber": 5, "Cosmetologist": 1 },
  "46226": { "Barber": 57, "Cosmetologist": 4 },
  "46160": { "Barber": 2, "Cosmetologist": 1 },
  "46405": { "Barber": 10, "Cosmetologist": 1 },
  "46234": { "Barber": 23, "Cosmetologist": 1 },
  "47421": { "Barber": 15 },
  "47424": { "Barber": 6 },
  "46218": { "Barber": 46, "Cosmetologist": 2 },
  "46714": { "Barber": 4, "Cosmetologist": 3 },
  "46928": { "Barber": 4 },
  "47250": { "Barber": 13 },
  "46064": { "Barber": 12, "Cosmetologist": 1 },
  "46408": { "Barber": 24, "Cosmetologist": 1 },
  "47546": { "Barber": 7, "Cosmetologist": 2 },
  "46816": { "Barber": 20, "Cosmetologist": 1 },
  "46123": { "Barber": 20, "Cosmetologist": 8 },
  "46202": { "Barber": 15 },
  "47920": { "Barber": 3 },
  "46069": { "Barber": 3, "Cosmetologist": 2 },
  "47150": { "Barber": 15, "Cosmetologist": 7 },
  "47112": { "Barber": 8, "Cosmetologist": 2 },
  "46324": { "Barber": 13, "Cosmetologist": 1 },
  "47124": { "Barber": 4, "Cosmetologist": 1 },
  "46346": { "Barber": 2 },
  "47802": { "Barber": 7, "Cosmetologist": 4 },
  "47358": { "Barber": 1 },
  "46531": { "Barber": 2 },
  "46774": { "Barber": 10, "Cosmetologist": 1 },
  "47949": { "Barber": 2 },
  "47620": { "Barber": 5 },
  "46250": { "Barber": 8, "Cosmetologist": 4 },
  "46615": { "Barber": 2, "Cosmetologist": 3 },
  "46254": { "Barber": 55, "Cosmetologist": 2 },
  "46201": { "Barber": 22, "Cosmetologist": 2 },
  "47462": { "Barber": 1 },
  "46806": { "Barber": 32, "Cosmetologist": 1 },
  "47933": { "Barber": 9, "Cosmetologist": 2 },
  "46725": { "Barber": 6, "Cosmetologist": 2 },
  "47167": { "Barber": 4, "Cosmetologist": 1 },
  "47527": { "Barber": 1 },
  "47283": { "Barber": 2 },
  "46814": { "Barber": 3, "Cosmetologist": 4 },
  "46975": { "Barber": 6 },
  "47452": { "Barber": 4 },
  "46036": { "Barber": 3, "Cosmetologist": 1 },
  "47373": { "Barber": 3 },
  "47140": { "Barber": 2 },
  "47923": { "Barber": 3, "Cosmetologist": 1 },
  "47120": { "Barber": 1 },
  "47177": { "Barber": 1 },
  "46173": { "Barber": 5, "Cosmetologist": 2 },
  "46077": { "Barber": 8, "Cosmetologist": 5 },
  "46406": { "Barber": 9 },
  "47460": { "Barber": 4, "Cosmetologist": 1 },
  "46032": { "Barber": 16, "Cosmetologist": 8 },
  "46310": { "Barber": 5, "Cosmetologist": 1 },
  "46759": { "Barber": 1 },
  "47710": { "Barber": 6 },
  "46071": { "Barber": 1 },
  "47303": { "Barber": 10, "Cosmetologist": 1 },
  "46711": { "Barber": 4, "Cosmetologist": 1 },
  "47304": { "Barber": 13, "Cosmetologist": 1 },
  "46808": { "Barber": 10 },
  "46923": { "Barber": 4 },
  "46327": { "Barber": 5 },
  "46528": { "Barber": 6, "Cosmetologist": 5 },
  "46184": { "Barber": 7, "Cosmetologist": 1 },
  "46371": { "Barber": 1 },
  "47838": { "Barber": 4 },
  "46040": { "Barber": 5, "Cosmetologist": 1 },
  "46161": { "Barber": 1, "Cosmetologist": 1 },
  "47804": { "Barber": 4, "Cosmetologist": 2 },
  "46385": { "Barber": 7, "Cosmetologist": 9 },
  "47712": { "Barber": 6, "Cosmetologist": 1 },
  "46321": { "Barber": 7, "Cosmetologist": 3 },
  "46407": { "Barber": 15 },
  "46241": { "Barber": 8, "Cosmetologist": 2 },
  "47401": { "Barber": 8, "Cosmetologist": 9 },
  "46235": { "Barber": 29, "Cosmetologist": 5 },
  "46311": { "Barber": 8 },
  "47117": { "Barber": 2 },
  "47359": { "Barber": 1 },
  "46783": { "Barber": 6, "Cosmetologist": 1 },
  "47338": { "Barber": 3 },
  "46259": { "Barber": 6 },
  "47579": { "Barber": 1, "Cosmetologist": 1 },
  "46151": { "Barber": 14, "Cosmetologist": 1 },
  "46204": { "Barber": 5, "Cosmetologist": 3 },
  "47340": { "Barber": 2, "Cosmetologist": 1 },
  "47025": { "Barber": 3, "Cosmetologist": 3 },
  "46947": { "Barber": 8, "Cosmetologist": 2 },
  "46542": { "Barber": 1 },
  "46550": { "Barber": 2, "Cosmetologist": 3 },
  "47720": { "Barber": 7, "Cosmetologist": 2 },
  "46409": { "Barber": 7, "Cosmetologist": 1 },
  "46392": { "Barber": 2 },
  "46701": { "Barber": 2, "Cosmetologist": 1 },
  "47274": { "Barber": 5, "Cosmetologist": 4 },
  "46356": { "Barber": 7, "Cosmetologist": 1 },
  "46920": { "Barber": 2 },
  "47362": { "Barber": 18, "Cosmetologist": 1 },
  "46795": { "Barber": 1, "Cosmetologist": 1 },
  "47660": { "Barber": 3 },
  "46809": { "Barber": 4, "Cosmetologist": 1 },
  "46158": { "Barber": 12, "Cosmetologist": 1 },
  "47356": { "Barber": 3, "Cosmetologist": 1 },
  "46978": { "Barber": 3 },
  "46902": { "Barber": 17, "Cosmetologist": 5 },
  "47670": { "Barber": 3 },
  "47834": { "Barber": 4 },
  "46394": { "Barber": 3, "Cosmetologist": 1 },
  "46805": { "Barber": 15, "Cosmetologist": 2 },
  "46628": { "Barber": 14, "Cosmetologist": 7 },
  "46107": { "Barber": 7 },
  "46619": { "Barber": 12, "Cosmetologist": 2 },
  "46347": { "Barber": 3 },
  "46980": { "Barber": 1 },
  "46804": { "Barber": 10, "Cosmetologist": 3 },
  "46803": { "Barber": 7 },
  "46835": { "Barber": 15, "Cosmetologist": 4 },
  "46507": { "Barber": 2, "Cosmetologist": 2 },
  "47715": { "Barber": 12, "Cosmetologist": 4 },
  "47122": { "Barber": 3, "Cosmetologist": 1 },
  "47396": { "Barber": 1 },
  "47385": { "Barber": 2 },
  "46225": { "Barber": 3 },
  "47725": { "Barber": 5, "Cosmetologist": 1 },
  "46818": { "Barber": 10, "Cosmetologist": 2 },
  "46544": { "Barber": 9, "Cosmetologist": 7 },
  "46176": { "Barber": 8, "Cosmetologist": 1 },
  "47665": { "Barber": 2 },
  "47932": { "Barber": 1 },
  "47436": { "Barber": 1 },
  "46511": { "Barber": 3 },
  "47320": { "Barber": 1 },
  "46280": { "Barber": 3, "Cosmetologist": 1 },
  "46186": { "Barber": 2 },
  "47993": { "Barber": 2 },
  "46167": { "Barber": 5, "Cosmetologist": 1 },
  "46992": { "Barber": 8, "Cosmetologist": 1 },
  "46030": { "Barber": 2 },
  "46994": { "Barber": 6 },
  "46001": { "Barber": 2, "Cosmetologist": 1 },
  "47334": { "Barber": 1 },
  "46524": { "Barber": 1 },
  "46962": { "Barber": 3, "Cosmetologist": 1 },
  "46580": { "Barber": 7 },
  "46582": { "Barber": 4 },
  "46797": { "Barber": 1 },
  "46532": { "Barber": 1 },
  "47119": { "Barber": 4 },
  "46750": { "Barber": 3 },
  "47032": { "Barber": 2 },
  "46231": { "Barber": 12, "Cosmetologist": 1 },
  "47220": { "Barber": 2, "Cosmetologist": 1 },
  "47454": { "Barber": 3 },
  "46933": { "Barber": 4 },
  "47129": { "Barber": 9, "Cosmetologist": 1 },
  "46055": { "Barber": 13, "Cosmetologist": 1 },
  "46312": { "Barber": 10, "Cosmetologist": 1 },
  "46033": { "Barber": 4, "Cosmetologist": 8 },
  "47805": { "Barber": 3 },
  "47429": { "Barber": 3 },
  "46122": { "Barber": 7 },
  "47352": { "Barber": 1 },
  "46637": { "Barber": 5, "Cosmetologist": 7 },
  "46987": { "Barber": 1, "Cosmetologist": 1 },
  "46776": { "Barber": 4, "Cosmetologist": 5 },
  "46601": { "Barber": 2, "Cosmetologist": 2 },
  "47022": { "Barber": 2 },
  "46126": { "Barber": 3 },
  "46217": { "Barber": 5, "Cosmetologist": 5 },
  "46034": { "Barber": 3 },
  "46303": { "Barber": 6, "Cosmetologist": 1 },
  "46130": { "Barber": 2 },
  "46617": { "Barber": 3, "Cosmetologist": 1 },
  "47360": { "Barber": 1 },
  "47141": { "Barber": 1 },
  "47904": { "Barber": 1, "Cosmetologist": 2 },
  "46366": { "Barber": 1 },
  "47957": { "Barber": 1 },
  "46952": { "Barber": 9, "Cosmetologist": 1 },
  "46012": { "Barber": 6 },
  "46932": { "Barber": 2 },
  "47024": { "Barber": 1 },
  "47143": { "Barber": 1 },
  "47613": { "Barber": 1, "Cosmetologist": 1 },
  "47390": { "Barber": 1 },
  "46970": { "Barber": 8, "Cosmetologist": 1 },
  "46374": { "Barber": 1 },
  "47166": { "Barber": 1 },
  "47336": { "Barber": 2 },
  "46121": { "Barber": 3, "Cosmetologist": 2 },
  "46530": { "Barber": 6, "Cosmetologist": 16 },
  "46172": { "Barber": 1 },
  "47842": { "Barber": 1 },
  "46635": { "Barber": 2, "Cosmetologist": 1 },
  "46738": { "Barber": 3, "Cosmetologist": 1 },
  "46526": { "Barber": 10, "Cosmetologist": 7 },
  "47264": { "Barber": 1 },
  "46534": { "Barber": 2 },
  "46706": { "Barber": 5, "Cosmetologist": 3 },
  "46773": { "Barber": 1 },
  "47330": { "Barber": 1 },
  "46565": { "Barber": 1, "Cosmetologist": 1 },
  "47586": { "Barber": 2 },
  "46614": { "Barber": 3, "Cosmetologist": 10 },
  "46914": { "Barber": 2 },
  "47427": { "Barber": 1 },
  "46755": { "Barber": 3, "Cosmetologist": 2 },
  "46552": { "Barber": 2, "Cosmetologist": 5 },
  "47106": { "Barber": 4 },
  "47944": { "Barber": 2 },
  "47532": { "Barber": 2 },
  "47138": { "Barber": 2 },
  "46574": { "Barber": 2, "Cosmetologist": 1 },
  "46148": { "Barber": 3 },
  "46320": { "Barber": 11 },
  "46013": { "Barber": 5 },
  "47118": { "Barber": 2 },
  "47394": { "Barber": 3 },
  "46767": { "Barber": 2 },
  "47243": { "Barber": 1 },
  "46501": { "Barber": 2 },
  "47951": { "Barber": 2 },
  "47371": { "Barber": 1, "Cosmetologist": 1 },
  "47501": { "Barber": 3 },
  "46147": { "Barber": 4 },
  "46391": { "Barber": 2, "Cosmetologist": 1 },
  "47708": { "Barber": 4 },
  "47525": { "Barber": 1 },
  "46075": { "Barber": 1, "Cosmetologist": 2 },
  "47235": { "Barber": 2 },
  "46807": { "Barber": 10, "Cosmetologist": 3 },
  "46072": { "Barber": 3, "Cosmetologist": 1 },
  "47921": { "Barber": 1 },
  "47966": { "Barber": 1 },
  "46068": { "Barber": 1 },
  "46737": { "Barber": 1, "Cosmetologist": 3 },
  "47550": { "Barber": 1 },
  "46613": { "Barber": 2, "Cosmetologist": 1 },
  "46951": { "Barber": 1 },
  "46382": { "Barber": 4 },
  "42303": { "Barber": 1, "Cosmetologist": 6 },
  "47901": { "Barber": 1 },
  "47803": { "Barber": 2, "Cosmetologist": 2 },
  "46180": { "Barber": 2 },
  "47240": { "Barber": 2, "Cosmetologist": 1 },
  "47102": { "Barber": 2 },
  "46910": { "Barber": 1 },
  "46567": { "Barber": 2, "Cosmetologist": 1 },
  "47648": { "Barber": 1 },
  "46340": { "Barber": 1 },
  "46561": { "Barber": 4, "Cosmetologist": 3 },
  "47018": { "Barber": 1 },
  "47136": { "Barber": 1 },
  "46065": { "Barber": 1 },
  "47229": { "Barber": 1 },
  "47244": { "Barber": 1 },
  "47847": { "Barber": 1 },
  "47857": { "Barber": 1 },
  "47041": { "Barber": 1 },
  "46216": { "Barber": 1 },
  "46051": { "Barber": 2 },
  "47346": { "Barber": 1, "Cosmetologist": 1 },
  "47327": { "Barber": 2, "Cosmetologist": 1 },
  "47610": { "Barber": 2 },
  "47968": { "Barber": 1 },
  "46802": { "Barber": 2, "Cosmetologist": 2 },
  "46748": { "Barber": 1 },
  "47520": { "Barber": 1 },
  "46402": { "Barber": 8 },
  "46120": { "Barber": 5 },
  "46731": { "Barber": 1 },
  "46733": { "Barber": 3, "Cosmetologist": 2 },
  "46793": { "Barber": 2 },
  "46164": { "Barber": 3 },
  "47345": { "Barber": 1 },
  "46149": { "Barber": 1 },
  "46989": { "Barber": 1 },
  "47265": { "Barber": 2, "Cosmetologist": 1 },
  "46349": { "Barber": 1, "Cosmetologist": 1 },
  "47959": { "Barber": 2 },
  "46070": { "Barber": 2 },
  "47948": { "Barber": 1 },
  "47542": { "Barber": 4, "Cosmetologist": 1 },
  "47012": { "Barber": 2 },
  "47125": { "Barber": 1 },
  "47408": { "Barber": 3, "Cosmetologist": 1 },
  "47469": { "Barber": 2 },
  "46770": { "Barber": 2 },
  "46740": { "Barber": 2, "Cosmetologist": 1 },
  "47431": { "Barber": 2 },
  "47383": { "Barber": 1 },
  "46115": { "Barber": 1 },
  "47807": { "Barber": 1 },
  "47438": { "Barber": 2, "Cosmetologist": 1 },
  "46171": { "Barber": 1 },
  "47115": { "Barber": 1 },
  "47164": { "Barber": 2 },
  "46899": { "Barber": 1 },
  "46979": { "Barber": 4 },
  "46044": { "Barber": 2 },
  "47850": { "Barber": 1, "Cosmetologist": 1 },
  "46867": { "Barber": 1 },
  "47471": { "Barber": 1, "Cosmetologist": 1 },
  "46157": { "Barber": 1 },
  "47232": { "Barber": 1 },
  "47882": { "Barber": 1, "Cosmetologist": 1 },
  "47380": { "Barber": 1 },
  "46784": { "Barber": 1 },
  "47165": { "Barber": 1 },
  "47470": { "Barber": 2 },
  "47855": { "Barber": 1 },
  "47407": { "Barber": 1 },
  "47872": { "Barber": 2, "Cosmetologist": 1 },
  "47353": { "Barber": 1 },
  "46761": { "Barber": 1, "Cosmetologist": 3 },
  "46562": { "Barber": 1, "Cosmetologist": 1 },
  "47432": { "Barber": 1, "Cosmetologist": 1 },
  "47272": { "Barber": 1 },
  "47654": { "Barber": 1 },
  "46938": { "Barber": 1 },
  "47040": { "Barber": 2 },
  "47635": { "Barber": 2 },
  "47459": { "Barber": 2 },
  "47468": { "Barber": 1 },
  "46743": { "Barber": 1, "Cosmetologist": 1 },
  "46056": { "Barber": 1 },
  "47145": { "Barber": 1, "Cosmetologist": 1 },
  "47649": { "Barber": 1 },
  "47885": { "Barber": 1 },
  "47273": { "Barber": 1 },
  "47581": { "Barber": 1 },
  "46181": { "Barber": 1 },
  "46746": { "Barber": 1, "Cosmetologist": 3 },
  "46913": { "Barber": 1 },
  "47388": { "Barber": 1 },
  "46129": { "Barber": 1 },
  "46384": { "Barber": 1 },
  "47223": { "Barber": 1 },
  "46058": { "Barber": 1 },
  "46165": { "Barber": 1 },
  "47348": { "Barber": 1 },
  "46049": { "Barber": 1 },
  "46982": { "Barber": 1 },
  "85012": { "Barber": 2, "Cosmetologist": 2 },
  "85009": { "Barber": 4, "Cosmetologist": 1 },
  "85353": { "Barber": 65 },
  "85301": { "Barber": 74, "Cosmetologist": 3 },
  "85307": { "Barber": 1, "Cosmetologist": 2 },
  "85312": { "Barber": 1 },
  "85364": { "Barber": 62, "Cosmetologist": 3 },
  "85367": { "Barber": 7, "Cosmetologist": 3 },
  "85139": { "Barber": 13, "Cosmetologist": 2 },
  "85653": { "Barber": 11, "Cosmetologist": 3 },
  "85658": { "Barber": 3, "Cosmetologist": 7 },
  "85706": { "Barber": 13, "Cosmetologist": 4 },
  "85756": { "Barber": 6, "Cosmetologist": 4 },
  "85711": { "Barber": 3, "Cosmetologist": 2 },
  "85741": { "Barber": 4, "Cosmetologist": 2 },
  "85705": { "Barber": 7, "Cosmetologist": 2 },
  "85739": { "Barber": 1, "Cosmetologist": 4 },
  "85743": { "Barber": 2, "Cosmetologist": 5 },
  "85714": { "Barber": 4, "Cosmetologist": 3 },
  "85757": { "Barber": 3, "Cosmetologist": 1 },
  "85752": { "Barber": 1 },
  "85747": { "Barber": 2, "Cosmetologist": 4 },
  "85339": { "Barber": 72, "Cosmetologist": 3 },
  "85354": { "Barber": 1 },
  "85390": { "Barber": 1 },
  "85350": { "Barber": 1, "Cosmetologist": 1 },
  "85378": { "Barber": 9 },
  "85344": { "Barber": 8, "Cosmetologist": 1 },
  "85281": { "Barber": 33, "Cosmetologist": 10 },
  "85284": { "Barber": 12, "Cosmetologist": 2 },
  "95249": { "Barber": 1 },
  "85249": { "Barber": 6, "Cosmetologist": 2 },
  "85299": { "Barber": 1 },
  "85250": { "Barber": 5, "Cosmetologist": 2 },
  "85259": { "Barber": 3, "Cosmetologist": 4 },
  "85262": { "Barber": 1, "Cosmetologist": 1 },
  "85266": { "Barber": 1, "Cosmetologist": 1 },
  "85022": { "Barber": 64, "Cosmetologist": 2 },
  "85031": { "Barber": 29 },
  "85306": { "Barber": 22, "Cosmetologist": 1 },
  "85302": { "Barber": 52 },
  "85303": { "Barber": 48, "Cosmetologist": 3 },
  "85361": { "Barber": 2 },
  "85257": { "Barber": 16, "Cosmetologist": 7 },
  "85340": { "Barber": 18, "Cosmetologist": 7 },
  "85323": { "Barber": 62, "Cosmetologist": 3 },
  "85377": { "Barber": 1 },
  "85224": { "Barber": 32, "Cosmetologist": 7 },
  "85304": { "Barber": 19, "Cosmetologist": 2 },
  "85203": { "Barber": 30, "Cosmetologist": 3 },
  "85028": { "Barber": 7, "Cosmetologist": 2 },
  "85033": { "Barber": 91, "Cosmetologist": 4 },
  "85034": { "Barber": 10 },
  "85035": { "Barber": 65, "Cosmetologist": 1 },
  "85040": { "Barber": 58, "Cosmetologist": 1 },
  "85043": { "Barber": 51, "Cosmetologist": 1 },
  "85048": { "Barber": 14, "Cosmetologist": 7 },
  "85054": { "Barber": 7, "Cosmetologist": 1 },
  "63556": { "Barber": 1, "Cosmetologist": 19 },
  "63366": { "Barber": 17, "Cosmetologist": 443 },
  "65583": { "Barber": 9, "Cosmetologist": 75 },
  "64123": { "Barber": 6, "Cosmetologist": 48 },
  "65631": { "Barber": 3, "Cosmetologist": 43 },
  "63052": { "Barber": 7, "Cosmetologist": 262 },
  "63118": { "Barber": 13, "Cosmetologist": 88 },
  "64110": { "Barber": 17, "Cosmetologist": 61 },
  "64060": { "Barber": 9, "Cosmetologist": 111 },
  "63137": { "Barber": 19, "Cosmetologist": 98 },
  "63136": { "Barber": 60, "Cosmetologist": 225 },
  "64136": { "Barber": 3, "Cosmetologist": 23 },
  "65041": { "Barber": 3, "Cosmetologist": 35 },
  "63089": { "Barber": 3, "Cosmetologist": 39 },
  "63303": { "Barber": 21, "Cosmetologist": 290 },
  "65611": { "Barber": 2, "Cosmetologist": 11 },
  "62035": { "Barber": 1, "Cosmetologist": 15 },
  "63080": { "Barber": 4, "Cosmetologist": 77 },
  "64134": { "Barber": 29, "Cosmetologist": 95 },
  "72715": { "Barber": 2, "Cosmetologist": 8 },
  "63628": { "Barber": 6, "Cosmetologist": 103 },
  "63115": { "Barber": 23, "Cosmetologist": 72 },
  "63112": { "Barber": 15, "Cosmetologist": 57 },
  "64132": { "Barber": 22, "Cosmetologist": 57 },
  "63129": { "Barber": 16, "Cosmetologist": 465 },
  "63114": { "Barber": 16, "Cosmetologist": 181 },
  "63034": { "Barber": 21, "Cosmetologist": 132 },
  "63385": { "Barber": 13, "Cosmetologist": 473 },
  "65066": { "Barber": 3, "Cosmetologist": 38 },
  "65793": { "Barber": 4, "Cosmetologist": 25 },
  "65065": { "Barber": 4, "Cosmetologist": 58 },
  "60098": { "Barber": 1 },
  "65804": { "Barber": 37, "Cosmetologist": 280 },
  "64151": { "Barber": 17, "Cosmetologist": 148 },
  "64118": { "Barber": 22, "Cosmetologist": 265 },
  "63755": { "Barber": 8, "Cosmetologist": 237 },
  "64070": { "Barber": 2, "Cosmetologist": 32 },
  "65261": { "Barber": 1, "Cosmetologist": 7 },
  "63122": { "Barber": 7, "Cosmetologist": 142 },
  "63084": { "Barber": 4, "Cosmetologist": 122 },
  "63123": { "Barber": 15, "Cosmetologist": 395 },
  "63135": { "Barber": 24, "Cosmetologist": 107 },
  "64465": { "Barber": 3, "Cosmetologist": 29 },
  "64744": { "Barber": 1, "Cosmetologist": 48 },
  "64424": { "Barber": 3, "Cosmetologist": 24 },
  "63133": { "Barber": 11, "Cosmetologist": 26 },
  "64124": { "Barber": 7, "Cosmetologist": 39 },
  "64011": { "Barber": 2, "Cosmetologist": 22 },
  "65663": { "Barber": 1, "Cosmetologist": 16 },
  "64015": { "Barber": 16, "Cosmetologist": 241 },
  "64130": { "Barber": 28, "Cosmetologist": 87 },
  "65653": { "Barber": 2, "Cosmetologist": 36 },
  "65536": { "Barber": 17, "Cosmetologist": 198 },
  "65742": { "Barber": 9, "Cosmetologist": 98 },
  "63301": { "Barber": 24, "Cosmetologist": 343 },
  "63863": { "Barber": 3, "Cosmetologist": 21 },
  "64138": { "Barber": 25, "Cosmetologist": 137 },
  "65580": { "Barber": 1, "Cosmetologist": 6 },
  "65740": { "Barber": 4, "Cosmetologist": 13 },
  "64029": { "Barber": 10, "Cosmetologist": 146 },
  "63020": { "Barber": 10, "Cosmetologist": 149 },
  "65757": { "Barber": 4, "Cosmetologist": 50 },
  "65731": { "Barber": 1 },
  "64129": { "Barber": 5, "Cosmetologist": 34 },
  "72631": { "Barber": 1, "Cosmetologist": 1 },
  "63332": { "Barber": 1, "Cosmetologist": 12 },
  "63111": { "Barber": 10, "Cosmetologist": 79 },
  "64064": { "Barber": 5, "Cosmetologist": 137 },
  "63126": { "Barber": 4, "Cosmetologist": 100 },
  "63146": { "Barber": 15, "Cosmetologist": 147 },
  "63775": { "Barber": 4, "Cosmetologist": 155 },
  "64133": { "Barber": 28, "Cosmetologist": 220 },
  "63147": { "Barber": 8, "Cosmetologist": 57 },
  "63901": { "Barber": 16, "Cosmetologist": 215 },
  "63077": { "Barber": 2, "Cosmetologist": 80 },
  "64119": { "Barber": 10, "Cosmetologist": 216 },
  "65626": { "Barber": 1, "Cosmetologist": 10 },
  "64068": { "Barber": 6, "Cosmetologist": 218 },
  "65203": { "Barber": 7, "Cosmetologist": 249 },
  "65810": { "Barber": 16, "Cosmetologist": 154 },
  "65809": { "Barber": 4, "Cosmetologist": 58 },
  "62207": { "Barber": 1, "Cosmetologist": 1 },
  "63074": { "Barber": 16, "Cosmetologist": 74 },
  "63116": { "Barber": 16, "Cosmetologist": 235 },
  "64504": { "Barber": 3, "Cosmetologist": 43 },
  "72601": { "Barber": 2, "Cosmetologist": 10 },
  "84060": { "Barber": 1 },
  "65625": { "Barber": 6, "Cosmetologist": 52 },
  "63128": { "Barber": 10, "Cosmetologist": 216 },
  "63130": { "Barber": 19, "Cosmetologist": 128 },
  "63664": { "Barber": 3, "Cosmetologist": 53 },
  "64153": { "Barber": 2, "Cosmetologist": 23 },
  "65616": { "Barber": 17, "Cosmetologist": 132 },
  "65706": { "Barber": 8, "Cosmetologist": 111 },
  "64659": { "Barber": 1, "Cosmetologist": 8 },
  "65401": { "Barber": 11, "Cosmetologist": 136 },
  "64114": { "Barber": 11, "Cosmetologist": 82 },
  "52240": { "Barber": 10, "Cosmetologist": 88 },
  "63042": { "Barber": 19, "Cosmetologist": 122 },
  "65026": { "Barber": 4, "Cosmetologist": 70 },
  "63069": { "Barber": 3, "Cosmetologist": 89 },
  "65542": { "Barber": 5, "Cosmetologist": 21 },
  "64471": { "Barber": 1, "Cosmetologist": 3 },
  "63109": { "Barber": 11, "Cosmetologist": 191 },
  "65648": { "Barber": 3, "Cosmetologist": 50 },
  "65079": { "Barber": 2, "Cosmetologist": 34 },
  "65233": { "Barber": 4, "Cosmetologist": 65 },
  "64137": { "Barber": 8, "Cosmetologist": 33 },
  "64030": { "Barber": 22, "Cosmetologist": 131 },
  "65802": { "Barber": 30, "Cosmetologist": 255 },
  "63121": { "Barber": 29, "Cosmetologist": 127 },
  "63104": { "Barber": 18, "Cosmetologist": 88 },
  "62964": { "Barber": 1, "Cosmetologist": 1 },
  "64040": { "Barber": 6, "Cosmetologist": 42 },
  "63028": { "Barber": 7, "Cosmetologist": 246 },
  "65721": { "Barber": 18, "Cosmetologist": 231 },
  "65711": { "Barber": 4, "Cosmetologist": 62 },
  "64748": { "Barber": 6, "Cosmetologist": 12 },
  "62363": { "Barber": 1, "Cosmetologist": 3 },
  "63461": { "Barber": 2, "Cosmetologist": 35 },
  "65534": { "Barber": 1, "Cosmetologist": 9 },
  "64836": { "Barber": 1, "Cosmetologist": 167 },
  "65622": { "Barber": 4, "Cosmetologist": 38 },
  "63343": { "Barber": 3, "Cosmetologist": 15 },
  "63131": { "Barber": 2, "Cosmetologist": 57 },
  "63353": { "Barber": 2, "Cosmetologist": 23 },
  "65633": { "Barber": 1, "Cosmetologist": 37 },
  "63132": { "Barber": 9, "Cosmetologist": 72 },
  "63101": { "Barber": 4, "Cosmetologist": 15 },
  "64468": { "Barber": 3, "Cosmetologist": 43 },
  "65689": { "Barber": 2, "Cosmetologist": 21 },
  "65350": { "Barber": 1, "Cosmetologist": 12 },
  "63857": { "Barber": 6, "Cosmetologist": 73 },
  "63106": { "Barber": 9, "Cosmetologist": 23 },
  "65781": { "Barber": 10, "Cosmetologist": 61 },
  "63031": { "Barber": 41, "Cosmetologist": 299 },
  "65686": { "Barber": 4, "Cosmetologist": 26 },
  "64109": { "Barber": 9, "Cosmetologist": 48 },
  "64683": { "Barber": 4, "Cosmetologist": 55 },
  "64801": { "Barber": 10, "Cosmetologist": 192 },
  "63801": { "Barber": 11, "Cosmetologist": 121 },
  "64762": { "Barber": 1, "Cosmetologist": 13 },
  "63701": { "Barber": 11, "Cosmetologist": 244 },
  "63771": { "Barber": 2, "Cosmetologist": 22 },
  "65605": { "Barber": 4, "Cosmetologist": 84 },
  "65459": { "Barber": 3, "Cosmetologist": 50 },
  "64679": { "Barber": 2, "Cosmetologist": 5 },
  "64053": { "Barber": 1, "Cosmetologist": 16 },
  "65556": { "Barber": 3, "Cosmetologist": 40 },
  "65770": { "Barber": 3, "Cosmetologist": 34 },
  "64834": { "Barber": 2, "Cosmetologist": 78 },
  "64034": { "Barber": 3, "Cosmetologist": 65 },
  "64108": { "Barber": 11, "Cosmetologist": 42 },
  "63113": { "Barber": 15, "Cosmetologist": 54 },
  "63017": { "Barber": 7, "Cosmetologist": 146 },
  "75209": { "Barber": 12, "Cosmetologist": 106 },
  "64112": { "Barber": 3, "Cosmetologist": 21 },
  "65251": { "Barber": 10, "Cosmetologist": 102 },
  "65018": { "Barber": 3, "Cosmetologist": 44 },
  "63138": { "Barber": 18, "Cosmetologist": 105 },
  "90043": { "Barber": 1 },
  "65552": { "Barber": 1, "Cosmetologist": 10 },
  "65775": { "Barber": 10, "Cosmetologist": 148 },
  "64128": { "Barber": 7, "Cosmetologist": 36 },
  "65604": { "Barber": 4, "Cosmetologist": 16 },
  "63670": { "Barber": 3, "Cosmetologist": 71 },
  "65240": { "Barber": 3, "Cosmetologist": 65 },
  "64020": { "Barber": 1, "Cosmetologist": 25 },
  "63021": { "Barber": 8, "Cosmetologist": 273 },
  "63957": { "Barber": 2, "Cosmetologist": 32 },
  "63537": { "Barber": 1, "Cosmetologist": 15 },
  "62034": { "Barber": 3, "Cosmetologist": 13 },
  "64831": { "Barber": 1, "Cosmetologist": 30 },
  "63401": { "Barber": 1, "Cosmetologist": 98 },
  "65037": { "Barber": 1, "Cosmetologist": 30 },
  "63703": { "Barber": 6, "Cosmetologist": 32 },
  "62067": { "Barber": 2, "Cosmetologist": 4 },
  "64106": { "Barber": 9, "Cosmetologist": 35 },
  "65263": { "Barber": 1, "Cosmetologist": 6 },
  "65672": { "Barber": 4, "Cosmetologist": 39 },
  "64506": { "Barber": 7, "Cosmetologist": 129 },
  "64058": { "Barber": 2, "Cosmetologist": 43 },
  "63558": { "Barber": 1, "Cosmetologist": 9 },
  "64063": { "Barber": 8, "Cosmetologist": 159 },
  "62040": { "Barber": 4, "Cosmetologist": 56 },
  "76017": { "Barber": 47, "Cosmetologist": 543 },
  "65608": { "Barber": 3, "Cosmetologist": 55 },
  "65324": { "Barber": 1, "Cosmetologist": 15 },
  "63044": { "Barber": 7, "Cosmetologist": 62 },
  "65606": { "Barber": 1, "Cosmetologist": 17 },
  "63090": { "Barber": 14, "Cosmetologist": 186 },
  "64701": { "Barber": 4, "Cosmetologist": 91 },
  "63048": { "Barber": 1, "Cosmetologist": 31 },
  "63341": { "Barber": 2, "Cosmetologist": 35 },
  "64429": { "Barber": 5, "Cosmetologist": 52 },
  "63350": { "Barber": 1, "Cosmetologist": 4 },
  "64061": { "Barber": 1, "Cosmetologist": 27 },
  "65452": { "Barber": 4, "Cosmetologist": 28 },
  "65039": { "Barber": 1, "Cosmetologist": 20 },
  "64050": { "Barber": 13, "Cosmetologist": 99 },
  "84636": { "Barber": 1 },
  "64057": { "Barber": 6, "Cosmetologist": 112 },
  "63103": { "Barber": 8, "Cosmetologist": 35 },
  "64446": { "Barber": 2, "Cosmetologist": 8 },
  "63555": { "Barber": 2, "Cosmetologist": 15 },
  "65559": { "Barber": 4, "Cosmetologist": 66 },
  "63437": { "Barber": 1, "Cosmetologist": 11 },
  "65453": { "Barber": 2, "Cosmetologist": 47 },
  "64149": { "Barber": 1 },
  "63837": { "Barber": 2, "Cosmetologist": 6 },
  "65473": { "Barber": 2, "Cosmetologist": 12 },
  "62203": { "Barber": 1, "Cosmetologist": 2 },
  "65340": { "Barber": 4, "Cosmetologist": 72 },
  "63348": { "Barber": 3, "Cosmetologist": 65 },
  "72719": { "Barber": 2, "Cosmetologist": 5 },
  "63037": { "Barber": 1, "Cosmetologist": 24 },
  "65567": { "Barber": 1, "Cosmetologist": 15 },
  "63764": { "Barber": 4, "Cosmetologist": 31 },
  "63784": { "Barber": 1 },
  "65619": { "Barber": 4, "Cosmetologist": 67 },
  "63339": { "Barber": 1, "Cosmetologist": 4 },
  "65265": { "Barber": 9, "Cosmetologist": 91 },
  "65084": { "Barber": 5, "Cosmetologist": 39 },
  "62226": { "Barber": 4, "Cosmetologist": 25 },
  "63334": { "Barber": 5, "Cosmetologist": 42 },
  "65483": { "Barber": 3, "Cosmetologist": 27 },
  "63967": { "Barber": 2, "Cosmetologist": 13 },
  "65014": { "Barber": 1, "Cosmetologist": 13 },
  "64085": { "Barber": 2, "Cosmetologist": 79 },
  "64767": { "Barber": 1, "Cosmetologist": 5 },
  "63010": { "Barber": 5, "Cosmetologist": 300 },
  "63019": { "Barber": 4, "Cosmetologist": 39 },
  "28079": { "Barber": 3, "Cosmetologist": 301 },
  "63830": { "Barber": 2, "Cosmetologist": 15 },
  "64117": { "Barber": 5, "Cosmetologist": 67 },
  "63141": { "Barber": 2, "Cosmetologist": 82 },
  "64726": { "Barber": 1, "Cosmetologist": 3 },
  "63758": { "Barber": 1, "Cosmetologist": 4 },
  "52544": { "Barber": 6, "Cosmetologist": 18 },
  "64028": { "Barber": 1, "Cosmetologist": 1 },
  "64062": { "Barber": 1, "Cosmetologist": 58 },
  "65078": { "Barber": 4, "Cosmetologist": 20 },
  "64844": { "Barber": 2, "Cosmetologist": 20 },
  "63345": { "Barber": 1, "Cosmetologist": 3 },
  "65661": { "Barber": 4, "Cosmetologist": 13 },
  "64735": { "Barber": 3, "Cosmetologist": 98 },
  "65270": { "Barber": 6, "Cosmetologist": 97 },
  "64048": { "Barber": 1, "Cosmetologist": 39 },
  "65712": { "Barber": 2, "Cosmetologist": 55 },
  "63117": { "Barber": 2, "Cosmetologist": 43 },
  "65641": { "Barber": 1, "Cosmetologist": 8 },
  "62090": { "Barber": 1, "Cosmetologist": 1 },
  "63769": { "Barber": 3, "Cosmetologist": 8 },
  "64155": { "Barber": 5, "Cosmetologist": 175 },
  "65348": { "Barber": 1, "Cosmetologist": 10 },
  "63381": { "Barber": 1, "Cosmetologist": 4 },
  "63120": { "Barber": 7, "Cosmetologist": 33 },
  "63533": { "Barber": 1, "Cosmetologist": 3 },
  "63933": { "Barber": 2, "Cosmetologist": 29 },
  "63654": { "Barber": 1, "Cosmetologist": 4 },
  "89115": { "Barber": 1, "Cosmetologist": 8 },
  "63110": { "Barber": 6, "Cosmetologist": 81 },
  "63960": { "Barber": 1, "Cosmetologist": 19 },
  "64724": { "Barber": 3, "Cosmetologist": 13 },
  "64601": { "Barber": 4, "Cosmetologist": 93 },
  "65486": { "Barber": 1, "Cosmetologist": 23 },
  "65081": { "Barber": 1, "Cosmetologist": 20 },
  "65790": { "Barber": 1, "Cosmetologist": 8 },
  "64783": { "Barber": 1, "Cosmetologist": 4 },
  "85341": { "Barber": 1 },
  "72450": { "Barber": 1, "Cosmetologist": 2 },
  "65674": { "Barber": 3, "Cosmetologist": 8 },
  "50677": { "Barber": 7, "Cosmetologist": 49 },
  "65047": { "Barber": 1, "Cosmetologist": 9 },
  "64870": { "Barber": 6, "Cosmetologist": 154 },
  "63023": { "Barber": 1, "Cosmetologist": 37 },
  "63552": { "Barber": 3, "Cosmetologist": 38 },
  "63752": { "Barber": 1 },
  "65737": { "Barber": 2, "Cosmetologist": 47 },
  "65557": { "Barber": 2 },
  "63445": { "Barber": 1, "Cosmetologist": 19 },
  "64126": { "Barber": 3, "Cosmetologist": 7 },
  "65667": { "Barber": 2, "Cosmetologist": 16 },
  "65681": { "Barber": 1, "Cosmetologist": 16 },
  "63336": { "Barber": 1, "Cosmetologist": 4 },
  "89701": { "Barber": 1 },
  "63650": { "Barber": 2, "Cosmetologist": 26 },
  "64481": { "Barber": 1, "Cosmetologist": 6 },
  "65610": { "Barber": 2, "Cosmetologist": 40 },
  "64101": { "Barber": 1, "Cosmetologist": 2 },
  "77095": { "Barber": 41, "Cosmetologist": 830 },
  "63869": { "Barber": 2, "Cosmetologist": 18 },
  "65024": { "Barber": 1, "Cosmetologist": 5 },
  "64507": { "Barber": 2, "Cosmetologist": 81 },
  "62236": { "Barber": 2, "Cosmetologist": 57 },
  "63383": { "Barber": 6, "Cosmetologist": 121 },
  "72044": { "Barber": 1 },
  "65787": { "Barber": 1, "Cosmetologist": 13 },
  "63060": { "Barber": 2, "Cosmetologist": 14 },
  "65734": { "Barber": 1, "Cosmetologist": 23 },
  "65441": { "Barber": 1, "Cosmetologist": 19 },
  "64075": { "Barber": 3, "Cosmetologist": 103 },
  "65255": { "Barber": 3, "Cosmetologist": 31 },
  "64633": { "Barber": 1, "Cosmetologist": 35 },
  "65011": { "Barber": 1, "Cosmetologist": 6 },
  "62062": { "Barber": 1, "Cosmetologist": 9 },
  "63834": { "Barber": 1, "Cosmetologist": 19 },
  "65591": { "Barber": 1, "Cosmetologist": 14 },
  "63043": { "Barber": 7, "Cosmetologist": 129 },
  "36551": { "Barber": 1, "Cosmetologist": 5 },
  "62237": { "Barber": 1 },
  "71909": { "Barber": 1, "Cosmetologist": 2 },
  "63088": { "Barber": 1, "Cosmetologist": 62 },
  "64864": { "Barber": 1, "Cosmetologist": 4 },
  "64485": { "Barber": 1, "Cosmetologist": 50 },
  "75050": { "Barber": 44, "Cosmetologist": 302 },
  "63134": { "Barber": 6, "Cosmetologist": 65 },
  "64649": { "Barber": 1, "Cosmetologist": 9 },
  "65336": { "Barber": 2, "Cosmetologist": 36 },
  "65360": { "Barber": 1, "Cosmetologist": 32 },
  "65724": { "Barber": 1, "Cosmetologist": 7 },
  "63866": { "Barber": 1 },
  "65444": { "Barber": 1, "Cosmetologist": 4 },
  "63964": { "Barber": 1, "Cosmetologist": 3 },
  "64102": { "Barber": 1, "Cosmetologist": 4 },
  "63032": { "Barber": 2, "Cosmetologist": 4 },
  "63459": { "Barber": 2, "Cosmetologist": 24 },
  "65052": { "Barber": 2, "Cosmetologist": 21 },
  "64747": { "Barber": 1, "Cosmetologist": 25 },
  "75440": { "Barber": 3, "Cosmetologist": 55 },
  "64105": { "Barber": 1, "Cosmetologist": 19 },
  "65248": { "Barber": 1, "Cosmetologist": 18 },
  "64489": { "Barber": 1, "Cosmetologist": 13 },
  "93458": { "Barber": 1 },
  "63873": { "Barber": 3, "Cosmetologist": 32 },
  "24401": { "Barber": 1, "Cosmetologist": 6 },
  "62205": { "Barber": 1, "Cosmetologist": 3 },
  "65746": { "Barber": 2, "Cosmetologist": 42 },
  "63702": { "Barber": 1, "Cosmetologist": 2 },
  "62084": { "Barber": 1, "Cosmetologist": 1 },
  "64505": { "Barber": 2, "Cosmetologist": 67 },
  "64156": { "Barber": 1, "Cosmetologist": 50 },
  "26505": { "Barber": 2, "Cosmetologist": 2 },
  "64670": { "Barber": 1, "Cosmetologist": 6 },
  "64759": { "Barber": 5, "Cosmetologist": 46 },
  "63462": { "Barber": 1, "Cosmetologist": 15 },
  "63624": { "Barber": 1, "Cosmetologist": 22 },
  "63845": { "Barber": 2, "Cosmetologist": 40 },
  "63645": { "Barber": 4, "Cosmetologist": 77 },
  "63841": { "Barber": 1, "Cosmetologist": 87 },
  "65043": { "Barber": 2, "Cosmetologist": 52 },
  "62012": { "Barber": 1, "Cosmetologist": 8 },
  "64725": { "Barber": 1, "Cosmetologist": 13 },
  "64051": { "Barber": 1, "Cosmetologist": 3 },
  "63012": { "Barber": 4, "Cosmetologist": 94 },
  "61761": { "Barber": 1, "Cosmetologist": 9 },
  "63730": { "Barber": 1, "Cosmetologist": 28 },
  "62948": { "Barber": 1, "Cosmetologist": 2 },
  "64098": { "Barber": 2, "Cosmetologist": 19 },
  "65205": { "Barber": 2, "Cosmetologist": 5 },
  "63961": { "Barber": 1, "Cosmetologist": 10 },
  "64107": { "Barber": 1 },
  "64802": { "Barber": 1, "Cosmetologist": 3 },
  "64067": { "Barber": 3, "Cosmetologist": 40 },
  "64720": { "Barber": 2, "Cosmetologist": 23 },
  "62246": { "Barber": 1, "Cosmetologist": 3 },
  "65662": { "Barber": 1, "Cosmetologist": 9 },
  "63362": { "Barber": 3, "Cosmetologist": 89 },
  "65771": { "Barber": 1, "Cosmetologist": 6 },
  "65791": { "Barber": 1, "Cosmetologist": 29 },
  "62095": { "Barber": 1, "Cosmetologist": 7 },
  "63431": { "Barber": 1, "Cosmetologist": 1 },
  "65722": { "Barber": 1, "Cosmetologist": 15 },
  "64401": { "Barber": 1, "Cosmetologist": 6 },
  "63851": { "Barber": 1, "Cosmetologist": 10 },
  "63357": { "Barber": 1, "Cosmetologist": 46 },
  "89121": { "Barber": 1, "Cosmetologist": 5 },
  "62060": { "Barber": 1, "Cosmetologist": 1 },
  "64673": { "Barber": 3, "Cosmetologist": 15 },
  "64865": { "Barber": 2, "Cosmetologist": 47 },
  "63039": { "Barber": 1, "Cosmetologist": 5 },
  "65281": { "Barber": 2, "Cosmetologist": 19 },
  "65772": { "Barber": 1, "Cosmetologist": 7 },
  "65254": { "Barber": 1, "Cosmetologist": 11 },
  "62249": { "Barber": 1, "Cosmetologist": 7 },
  "63016": { "Barber": 1, "Cosmetologist": 69 },
  "64772": { "Barber": 3, "Cosmetologist": 80 },
  "65456": { "Barber": 1, "Cosmetologist": 4 },
  "65051": { "Barber": 1, "Cosmetologist": 28 },
  "65564": { "Barber": 1 },
  "63655": { "Barber": 1, "Cosmetologist": 7 },
  "65337": { "Barber": 2, "Cosmetologist": 11 },
  "64730": { "Barber": 3, "Cosmetologist": 58 },
  "50009": { "Barber": 6, "Cosmetologist": 107 },
  "64016": { "Barber": 1, "Cosmetologist": 38 },
  "64776": { "Barber": 1, "Cosmetologist": 17 },
  "63302": { "Barber": 1, "Cosmetologist": 3 },
  "65565": { "Barber": 1, "Cosmetologist": 18 },
  "64443": { "Barber": 2, "Cosmetologist": 5 },
  "63072": { "Barber": 1, "Cosmetologist": 27 },
  "64501": { "Barber": 1, "Cosmetologist": 38 },
  "63565": { "Barber": 1, "Cosmetologist": 27 },
  "65259": { "Barber": 1, "Cosmetologist": 19 },
  "65652": { "Barber": 1, "Cosmetologist": 15 },
  "63660": { "Barber": 1, "Cosmetologist": 15 },
  "65334": { "Barber": 1, "Cosmetologist": 7 },
  "65682": { "Barber": 1, "Cosmetologist": 13 },
  "72662": { "Barber": 1, "Cosmetologist": 10 },
  "64763": { "Barber": 1, "Cosmetologist": 7 },
  "72422": { "Barber": 1, "Cosmetologist": 5 },
  "40031": { "Barber": 1, "Cosmetologist": 4 },
  "72632": { "Barber": 1, "Cosmetologist": 3 },
  "63105": { "Barber": 2, "Cosmetologist": 100 },
  "63751": { "Barber": 1, "Cosmetologist": 6 },
  "65257": { "Barber": 1, "Cosmetologist": 8 },
  "63446": { "Barber": 1, "Cosmetologist": 2 },
  "77021": { "Barber": 43, "Cosmetologist": 108 },
  "65338": { "Barber": 1, "Cosmetologist": 14 },
  "63070": { "Barber": 1, "Cosmetologist": 55 },
  "63351": { "Barber": 1, "Cosmetologist": 18 },
  "64017": { "Barber": 1, "Cosmetologist": 2 },
  "65239": { "Barber": 1, "Cosmetologist": 6 },
  "64647": { "Barber": 1 },
  "63068": { "Barber": 1, "Cosmetologist": 37 },
  "64456": { "Barber": 1, "Cosmetologist": 5 },
  "65449": { "Barber": 1, "Cosmetologist": 2 },
  "62239": { "Barber": 1, "Cosmetologist": 12 },
  "63704": { "Barber": 1 },
  "65231": { "Barber": 1, "Cosmetologist": 18 },
  "65692": { "Barber": 1, "Cosmetologist": 4 },
  "65747": { "Barber": 1, "Cosmetologist": 19 },
  "63050": { "Barber": 2, "Cosmetologist": 143 },
  "64145": { "Barber": 2, "Cosmetologist": 19 },
  "65555": { "Barber": 1, "Cosmetologist": 6 },
  "63036": { "Barber": 1, "Cosmetologist": 2 },
  "63630": { "Barber": 1, "Cosmetologist": 22 },
  "63547": { "Barber": 1 },
  "62301": { "Barber": 1, "Cosmetologist": 10 },
  "65279": { "Barber": 1, "Cosmetologist": 17 },
  "63124": { "Barber": 1, "Cosmetologist": 27 },
  "27521": { "Barber": 1, "Cosmetologist": 52 },
  "64054": { "Barber": 2, "Cosmetologist": 18 },
  "65402": { "Barber": 1, "Cosmetologist": 10 },
  "63940": { "Barber": 1, "Cosmetologist": 7 },
  "72712": { "Barber": 1, "Cosmetologist": 10 },
  "65801": { "Barber": 1, "Cosmetologist": 8 },
  "63013": { "Barber": 1, "Cosmetologist": 11 },
  "64658": { "Barber": 2, "Cosmetologist": 43 },
  "92336": { "Barber": 1, "Cosmetologist": 1 },
  "61555": { "Barber": 1 },
  "63087": { "Barber": 1, "Cosmetologist": 2 },
  "84066": { "Barber": 1 },
  "64622": { "Barber": 2, "Cosmetologist": 3 },
  "65649": { "Barber": 1, "Cosmetologist": 7 },
  "65283": { "Barber": 1, "Cosmetologist": 5 },
  "64689": { "Barber": 1, "Cosmetologist": 2 },
  "64755": { "Barber": 1, "Cosmetologist": 22 },
  "65723": { "Barber": 1, "Cosmetologist": 28 },
  "65789": { "Barber": 1, "Cosmetologist": 8 },
  "62966": { "Barber": 1, "Cosmetologist": 1 },
  "91321": { "Barber": 1 },
  "62010": { "Barber": 1, "Cosmetologist": 13 },
  "64078": { "Barber": 1, "Cosmetologist": 57 },
  "38663": { "Barber": 1 },
  "29486": { "Barber": 1, "Cosmetologist": 17 },
  "68110": { "Barber": 1, "Cosmetologist": 1 },
  "64427": { "Barber": 1, "Cosmetologist": 5 },
  "65620": { "Barber": 1, "Cosmetologist": 5 },
  "64503": { "Barber": 2, "Cosmetologist": 59 },
  "64470": { "Barber": 1, "Cosmetologist": 17 },
  "65013": { "Barber": 1, "Cosmetologist": 19 },
  "62269": { "Barber": 1, "Cosmetologist": 31 },
  "65754": { "Barber": 1, "Cosmetologist": 13 },
  "63740": { "Barber": 1, "Cosmetologist": 25 },
  "65082": { "Barber": 1, "Cosmetologist": 10 },
  "75052": { "Barber": 128, "Cosmetologist": 1886 },
  "77386": { "Barber": 36, "Cosmetologist": 545 },
  "63369": { "Cosmetologist": 19 },
  "65786": { "Cosmetologist": 13 },
  "62298": { "Cosmetologist": 39 },
  "63622": { "Cosmetologist": 11 },
  "64640": { "Cosmetologist": 29 },
  "63732": { "Cosmetologist": 12 },
  "64479": { "Cosmetologist": 9 },
  "74021": { "Cosmetologist": 5 },
  "63377": { "Cosmetologist": 39 },
  "64671": { "Cosmetologist": 18 },
  "64856": { "Cosmetologist": 7 },
  "64454": { "Cosmetologist": 20 },
  "63638": { "Cosmetologist": 22 },
  "55420": { "Cosmetologist": 2 },
  "64158": { "Cosmetologist": 40 },
  "64866": { "Cosmetologist": 10 },
  "64139": { "Cosmetologist": 16 },
  "65705": { "Cosmetologist": 29 },
  "64743": { "Cosmetologist": 4 },
  "68046": { "Cosmetologist": 3 },
  "96717": { "Cosmetologist": 1 },
  "65627": { "Cosmetologist": 1 },
  "28037": { "Cosmetologist": 173, "Barber": 2 },
  "64784": { "Cosmetologist": 13 },
  "63005": { "Cosmetologist": 55 },
  "65501": { "Cosmetologist": 3 },
  "62052": { "Cosmetologist": 7 },
  "63382": { "Cosmetologist": 24 },
  "64494": { "Cosmetologist": 8 },
  "64490": { "Cosmetologist": 20 },
  "64076": { "Cosmetologist": 65 },
  "65085": { "Cosmetologist": 21 },
  "63862": { "Cosmetologist": 10 },
  "72554": { "Cosmetologist": 13 },
  "62471": { "Cosmetologist": 3 },
  "63673": { "Cosmetologist": 17 },
  "29566": { "Cosmetologist": 51 },
  "64097": { "Cosmetologist": 8 },
  "65466": { "Cosmetologist": 12 },
  "63056": { "Cosmetologist": 11 },
  "65236": { "Cosmetologist": 10 },
  "62872": { "Cosmetologist": 1 },
  "64491": { "Cosmetologist": 8 },
  "27944": { "Cosmetologist": 67 },
  "64463": { "Cosmetologist": 13 },
  "63389": { "Cosmetologist": 60 },
  "65753": { "Cosmetologist": 35 },
  "64001": { "Cosmetologist": 5 },
  "64734": { "Cosmetologist": 15 },
  "63747": { "Cosmetologist": 6 },
  "63347": { "Cosmetologist": 18 },
  "64035": { "Cosmetologist": 8 },
  "63386": { "Cosmetologist": 6 },
  "95337": { "Cosmetologist": 1 },
  "65673": { "Cosmetologist": 5 },
  "64858": { "Cosmetologist": 2 },
  "63879": { "Cosmetologist": 5 },
  "65655": { "Cosmetologist": 15 },
  "63557": { "Cosmetologist": 4 },
  "65243": { "Cosmetologist": 8 },
  "65074": { "Cosmetologist": 23 },
  "65588": { "Cosmetologist": 9 },
  "62223": { "Cosmetologist": 11 },
  "65063": { "Cosmetologist": 20 },
  "68122": { "Cosmetologist": 4 },
  "63780": { "Cosmetologist": 33 },
  "64077": { "Cosmetologist": 9 },
  "64862": { "Cosmetologist": 16 },
  "64190": { "Cosmetologist": 2 },
  "63014": { "Cosmetologist": 6 },
  "64746": { "Cosmetologist": 9 },
  "74848": { "Cosmetologist": 1 },
  "65632": { "Cosmetologist": 16 },
  "84036": { "Cosmetologist": 2 },
  "63440": { "Cosmetologist": 1 },
  "64442": { "Cosmetologist": 11 },
  "64855": { "Cosmetologist": 26 },
  "84121": { "Cosmetologist": 2 },
  "28451": { "Cosmetologist": 273, "Barber": 2 },
  "63935": { "Cosmetologist": 33 },
  "64428": { "Cosmetologist": 5 },
  "65720": { "Cosmetologist": 8 },
  "93292": { "Cosmetologist": 3 },
  "62074": { "Cosmetologist": 1 },
  "10028": { "Cosmetologist": 6 },
  "30755": { "Cosmetologist": 3 },
  "85348": { "Cosmetologist": 1 },
  "65774": { "Cosmetologist": 11 },
  "74112": { "Cosmetologist": 3 },
  "63352": { "Cosmetologist": 8 },
  "63825": { "Cosmetologist": 34 },
  "63822": { "Cosmetologist": 14 },
  "63071": { "Cosmetologist": 3 },
  "77304": { "Cosmetologist": 365, "Barber": 21 },
  "62285": { "Cosmetologist": 6 },
  "64643": { "Cosmetologist": 6 },
  "65668": { "Cosmetologist": 10 },
  "92270": { "Cosmetologist": 5 },
  "64803": { "Cosmetologist": 7 },
  "64439": { "Cosmetologist": 12 },
  "65535": { "Cosmetologist": 7 },
  "62244": { "Cosmetologist": 2 },
  "36205": { "Cosmetologist": 1 },
  "65769": { "Cosmetologist": 23 },
  "63040": { "Cosmetologist": 58 },
  "63739": { "Cosmetologist": 6 },
  "63944": { "Cosmetologist": 7 },
  "77064": { "Cosmetologist": 701, "Barber": 30 },
  "65285": { "Cosmetologist": 4 },
  "64874": { "Cosmetologist": 3 },
  "64867": { "Cosmetologist": 5 },
  "64477": { "Cosmetologist": 20 },
  "73086": { "Cosmetologist": 1 },
  "62281": { "Cosmetologist": 2 },
  "29803": { "Cosmetologist": 8 },
  "62263": { "Cosmetologist": 3 },
  "65035": { "Cosmetologist": 19 },
  "65733": { "Cosmetologist": 4 },
  "62278": { "Cosmetologist": 4 },
  "65679": { "Cosmetologist": 16 },
  "65779": { "Cosmetologist": 11 },
  "85220": { "Cosmetologist": 1 },
  "63541": { "Cosmetologist": 1 },
  "63127": { "Cosmetologist": 30 },
  "64644": { "Cosmetologist": 19 },
  "65640": { "Cosmetologist": 4 },
  "63767": { "Cosmetologist": 5 },
  "65246": { "Cosmetologist": 2 },
  "63038": { "Cosmetologist": 36 },
  "65323": { "Cosmetologist": 4 },
  "64473": { "Cosmetologist": 17 },
  "64492": { "Cosmetologist": 20 },
  "72727": { "Cosmetologist": 1 },
  "63827": { "Cosmetologist": 3 },
  "64084": { "Cosmetologist": 10 },
  "95567": { "Cosmetologist": 1 },
  "62926": { "Cosmetologist": 2 },
  "63953": { "Cosmetologist": 6 },
  "63653": { "Cosmetologist": 11 },
  "62220": { "Cosmetologist": 19 },
  "62294": { "Cosmetologist": 17 },
  "65582": { "Cosmetologist": 13 },
  "65275": { "Cosmetologist": 19 },
  "98223": { "Cosmetologist": 3 },
  "62061": { "Cosmetologist": 2 },
  "63015": { "Cosmetologist": 13 },
  "72653": { "Cosmetologist": 10 },
  "63532": { "Cosmetologist": 10 },
  "63457": { "Cosmetologist": 1 },
  "62254": { "Cosmetologist": 4 },
  "64482": { "Cosmetologist": 13 },
  "50220": { "Cosmetologist": 29 },
  "63091": { "Cosmetologist": 18 },
  "60617": { "Cosmetologist": 6 },
  "62201": { "Cosmetologist": 1 },
  "64843": { "Cosmetologist": 15 },
  "63736": { "Cosmetologist": 30 },
  "14534": { "Cosmetologist": 2 },
  "65438": { "Cosmetologist": 14 },
  "63061": { "Cosmetologist": 4 },
  "75254": { "Cosmetologist": 162, "Barber": 24 },
  "65264": { "Cosmetologist": 6 },
  "76504": { "Cosmetologist": 151, "Barber": 23 },
  "65710": { "Cosmetologist": 20 },
  "65321": { "Cosmetologist": 5 },
  "63443": { "Cosmetologist": 2 },
  "63627": { "Cosmetologist": 21 },
  "65646": { "Cosmetologist": 10 },
  "63456": { "Cosmetologist": 24 },
  "63469": { "Cosmetologist": 10 },
  "64769": { "Cosmetologist": 2 },
  "65305": { "Cosmetologist": 7 },
  "63361": { "Cosmetologist": 29 },
  "64088": { "Cosmetologist": 10 },
  "64780": { "Cosmetologist": 4 },
  "60021": { "Cosmetologist": 1 },
  "64859": { "Cosmetologist": 8 },
  "42101": { "Cosmetologist": 11 },
  "44643": { "Cosmetologist": 1 },
  "63549": { "Cosmetologist": 19 },
  "64664": { "Cosmetologist": 5 },
  "63821": { "Cosmetologist": 3 },
  "64166": { "Cosmetologist": 2 },
  "57252": { "Cosmetologist": 1 },
  "65571": { "Cosmetologist": 6 },
  "63876": { "Cosmetologist": 12 },
  "62082": { "Cosmetologist": 1 },
  "65752": { "Cosmetologist": 5 },
  "65284": { "Cosmetologist": 19 },
  "63373": { "Cosmetologist": 4 },
  "64861": { "Cosmetologist": 6 },
  "64638": { "Cosmetologist": 4 },
  "62232": { "Cosmetologist": 8 },
  "43110": { "Cosmetologist": 9 },
  "62233": { "Cosmetologist": 8 },
  "63546": { "Cosmetologist": 12 },
  "65612": { "Cosmetologist": 5 },
  "65756": { "Cosmetologist": 4 },
  "65023": { "Cosmetologist": 11 },
  "63742": { "Cosmetologist": 1 },
  "65276": { "Cosmetologist": 15 },
  "65325": { "Cosmetologist": 16 },
  "64682": { "Cosmetologist": 4 },
  "65601": { "Cosmetologist": 3 },
  "65016": { "Cosmetologist": 9 },
  "63454": { "Cosmetologist": 5 },
  "72544": { "Cosmetologist": 1 },
  "63770": { "Cosmetologist": 1 },
  "76133": { "Cosmetologist": 335, "Barber": 51 },
  "92120": { "Cosmetologist": 3 },
  "63966": { "Cosmetologist": 16 },
  "38066": { "Cosmetologist": 1, "Barber": 1 },
  "75092": { "Cosmetologist": 199, "Barber": 12 },
  "77550": { "Cosmetologist": 121, "Barber": 18 },
  "50449": { "Cosmetologist": 5 },
  "63621": { "Cosmetologist": 10 },
  "64770": { "Cosmetologist": 5 },
  "62215": { "Cosmetologist": 2 },
  "37179": { "Cosmetologist": 11, "Barber": 8 },
  "62014": { "Cosmetologist": 5 },
  "61231": { "Cosmetologist": 8 },
  "64437": { "Cosmetologist": 3 },
  "70131": { "Cosmetologist": 2 },
  "63534": { "Cosmetologist": 3 },
  "65326": { "Cosmetologist": 9 },
  "65054": { "Cosmetologist": 6 },
  "64469": { "Cosmetologist": 13 },
  "64651": { "Cosmetologist": 6 },
  "37370": { "Cosmetologist": 2, "Barber": 1 },
  "63626": { "Cosmetologist": 5 },
  "72364": { "Cosmetologist": 2 },
  "63620": { "Cosmetologist": 5 },
  "65250": { "Cosmetologist": 3 },
  "47922": { "Cosmetologist": 1 },
  "63636": { "Cosmetologist": 3 },
  "65725": { "Cosmetologist": 14 },
  "64771": { "Cosmetologist": 5 },
  "65067": { "Cosmetologist": 2 },
  "63629": { "Cosmetologist": 5 },
  "62206": { "Cosmetologist": 14 },
  "61073": { "Cosmetologist": 4 },
  "65680": { "Cosmetologist": 4 },
  "63344": { "Cosmetologist": 12 },
  "65590": { "Cosmetologist": 10 },
  "38305": { "Cosmetologist": 9, "Barber": 31 },
  "65462": { "Cosmetologist": 8 },
  "63877": { "Cosmetologist": 16 },
  "65080": { "Cosmetologist": 5 },
  "63623": { "Cosmetologist": 4 },
  "63760": { "Cosmetologist": 3 },
  "74301": { "Cosmetologist": 2 },
  "65287": { "Cosmetologist": 3 },
  "64436": { "Cosmetologist": 5 },
  "63448": { "Cosmetologist": 2 },
  "62260": { "Cosmetologist": 8 },
  "63932": { "Cosmetologist": 12 },
  "65676": { "Cosmetologist": 1 },
  "65541": { "Cosmetologist": 1 },
  "65230": { "Cosmetologist": 4 },
  "64125": { "Cosmetologist": 6 },
  "65256": { "Cosmetologist": 14 },
  "64448": { "Cosmetologist": 5 },
  "65614": { "Cosmetologist": 8 },
  "64637": { "Cosmetologist": 4 },
  "65707": { "Cosmetologist": 17 },
  "37135": { "Cosmetologist": 3, "Barber": 7 },
  "63939": { "Cosmetologist": 9 },
  "76544": { "Cosmetologist": 56, "Barber": 3 },
  "63648": { "Cosmetologist": 12 },
  "77469": { "Cosmetologist": 485, "Barber": 59 },
  "90048": { "Cosmetologist": 7 },
  "64146": { "Cosmetologist": 7 },
  "53536": { "Cosmetologist": 1 },
  "75454": { "Cosmetologist": 164, "Barber": 10 },
  "65647": { "Cosmetologist": 11 },
  "61115": { "Cosmetologist": 5 },
  "57719": { "Cosmetologist": 8 },
  "60190": { "Cosmetologist": 1 },
  "63561": { "Cosmetologist": 5 },
  "65660": { "Cosmetologist": 1 },
  "65764": { "Cosmetologist": 7 },
  "38120": { "Cosmetologist": 2, "Barber": 3 },
  "63937": { "Cosmetologist": 17 },
  "63349": { "Cosmetologist": 11 },
  "65463": { "Cosmetologist": 5 },
  "63943": { "Cosmetologist": 8 },
  "64840": { "Cosmetologist": 21 },
  "28570": { "Cosmetologist": 128, "Barber": 1 },
  "64790": { "Cosmetologist": 3 },
  "63471": { "Cosmetologist": 2 },
  "63965": { "Cosmetologist": 18 },
  "63829": { "Cosmetologist": 2 },
  "64486": { "Cosmetologist": 2 },
  "62906": { "Cosmetologist": 3 },
  "64752": { "Cosmetologist": 7 },
  "78577": { "Cosmetologist": 441, "Barber": 72 },
  "64487": { "Cosmetologist": 4 },
  "21075": { "Cosmetologist": 1 },
  "63452": { "Cosmetologist": 2 },
  "79121": { "Cosmetologist": 50, "Barber": 4 },
  "63750": { "Cosmetologist": 1 },
  "73501": { "Cosmetologist": 3 },
  "72756": { "Cosmetologist": 6 },
  "64635": { "Cosmetologist": 3 },
  "64013": { "Cosmetologist": 3 },
  "63472": { "Cosmetologist": 1 },
  "63748": { "Cosmetologist": 5 },
  "65543": { "Cosmetologist": 2 },
  "92260": { "Cosmetologist": 1 },
  "75218": { "Cosmetologist": 160, "Barber": 18 },
  "64019": { "Cosmetologist": 13 },
  "75243": { "Cosmetologist": 419, "Barber": 49 },
  "65717": { "Cosmetologist": 11 },
  "63945": { "Cosmetologist": 11 },
  "64841": { "Cosmetologist": 6 },
  "72461": { "Cosmetologist": 3 },
  "65657": { "Cosmetologist": 4 },
  "63053": { "Cosmetologist": 2 },
  "72713": { "Cosmetologist": 4 },
  "55430": { "Cosmetologist": 2 },
  "63846": { "Cosmetologist": 12 },
  "63166": { "Cosmetologist": 1 },
  "62293": { "Cosmetologist": 2 },
  "63979": { "Cosmetologist": 1 },
  "64636": { "Cosmetologist": 3 },
  "48842": { "Cosmetologist": 130, "Barber": 9 },
  "65058": { "Cosmetologist": 5 },
  "63359": { "Cosmetologist": 13 },
  "64756": { "Cosmetologist": 2 },
  "63766": { "Cosmetologist": 17 },
  "65332": { "Cosmetologist": 8 },
  "27571": { "Cosmetologist": 52, "Barber": 1 },
  "74859": { "Cosmetologist": 1 },
  "64668": { "Cosmetologist": 14 },
  "72734": { "Cosmetologist": 2 },
  "62264": { "Cosmetologist": 2 },
  "65761": { "Cosmetologist": 5 },
  "76549": { "Cosmetologist": 375, "Barber": 93 },
  "63785": { "Cosmetologist": 9 },
  "65470": { "Cosmetologist": 6 },
  "64657": { "Cosmetologist": 1 },
  "64835": { "Cosmetologist": 13 },
  "37760": { "Cosmetologist": 3, "Barber": 5 },
  "59301": { "Cosmetologist": 1 },
  "84045": { "Cosmetologist": 2 },
  "63675": { "Cosmetologist": 2 },
  "51637": { "Cosmetologist": 2 },
  "63388": { "Cosmetologist": 3 },
  "65688": { "Cosmetologist": 2 },
  "65644": { "Cosmetologist": 14 },
  "39532": { "Cosmetologist": 18 },
  "63380": { "Cosmetologist": 4 },
  "55975": { "Cosmetologist": 1 },
  "76301": { "Cosmetologist": 84, "Barber": 10 },
  "64660": { "Cosmetologist": 5 },
  "65767": { "Cosmetologist": 17 },
  "72714": { "Cosmetologist": 11 },
  "62225": { "Cosmetologist": 7 },
  "62355": { "Cosmetologist": 1 },
  "64653": { "Cosmetologist": 5 },
  "65274": { "Cosmetologist": 11 },
  "28312": { "Cosmetologist": 147, "Barber": 6 },
  "65713": { "Cosmetologist": 10 },
  "91320": { "Cosmetologist": 1 },
  "92122": { "Cosmetologist": 3 },
  "64848": { "Cosmetologist": 6 },
  "45036": { "Cosmetologist": 5 },
  "62960": { "Cosmetologist": 2 },
  "65040": { "Cosmetologist": 11 },
  "65745": { "Cosmetologist": 8 },
  "64449": { "Cosmetologist": 2 },
  "99520": { "Cosmetologist": 1 },
  "89133": { "Cosmetologist": 2 },
  "64071": { "Cosmetologist": 5 },
  "92128": { "Cosmetologist": 2 },
  "63365": { "Cosmetologist": 1 },
  "64022": { "Cosmetologist": 3 },
  "65075": { "Cosmetologist": 3 },
  "65664": { "Cosmetologist": 1 },
  "27534": { "Cosmetologist": 236 },
  "64422": { "Cosmetologist": 3 },
  "78238": { "Cosmetologist": 210, "Barber": 21 },
  "65032": { "Cosmetologist": 9 },
  "52585": { "Cosmetologist": 8, "Barber": 1 },
  "63867": { "Cosmetologist": 2 },
  "62016": { "Cosmetologist": 1 },
  "64741": { "Cosmetologist": 3 },
  "65232": { "Cosmetologist": 2 },
  "64148": { "Cosmetologist": 1 },
  "63468": { "Cosmetologist": 15 },
  "64430": { "Cosmetologist": 7 },
  "65349": { "Cosmetologist": 15 },
  "63335": { "Cosmetologist": 1 },
  "63781": { "Cosmetologist": 7 },
  "65046": { "Cosmetologist": 4 },
  "63902": { "Cosmetologist": 3 },
  "63435": { "Cosmetologist": 8 },
  "36561": { "Cosmetologist": 2 },
  "97523": { "Cosmetologist": 1 },
  "64742": { "Cosmetologist": 9 },
  "39705": { "Cosmetologist": 4 },
  "64161": { "Cosmetologist": 1 },
  "65345": { "Cosmetologist": 4 },
  "64483": { "Cosmetologist": 6 },
  "91601": { "Cosmetologist": 6 },
  "62448": { "Cosmetologist": 1 },
  "60154": { "Cosmetologist": 2 },
  "83422": { "Cosmetologist": 1 },
  "77611": { "Cosmetologist": 117, "Barber": 3 },
  "37756": { "Cosmetologist": 1 },
  "64631": { "Cosmetologist": 9 },
  "64624": { "Cosmetologist": 12 },
  "53142": { "Cosmetologist": 4 },
  "36548": { "Cosmetologist": 1 },
  "62988": { "Cosmetologist": 4 },
  "65351": { "Cosmetologist": 16 },
  "62024": { "Cosmetologist": 7 },
  "12989": { "Cosmetologist": 1 },
  "86323": { "Cosmetologist": 5 },
  "62277": { "Cosmetologist": 2 },
  "74079": { "Cosmetologist": 1 },
  "63662": { "Cosmetologist": 7 },
  "64842": { "Cosmetologist": 7 },
  "65732": { "Cosmetologist": 5 },
  "65635": { "Cosmetologist": 4 },
  "75063": { "Cosmetologist": 175, "Barber": 14 },
  "64402": { "Cosmetologist": 7 },
  "63363": { "Cosmetologist": 13 },
  "64672": { "Cosmetologist": 2 },
  "64728": { "Cosmetologist": 5 },
  "64738": { "Cosmetologist": 6 },
  "64441": { "Cosmetologist": 1 },
  "63441": { "Cosmetologist": 6 },
  "74104": { "Cosmetologist": 1 },
  "64620": { "Cosmetologist": 4 },
  "95051": { "Cosmetologist": 3 },
  "50072": { "Cosmetologist": 9 },
  "63637": { "Cosmetologist": 3 },
  "64715": { "Cosmetologist": 1 },
  "72454": { "Cosmetologist": 2 },
  "63364": { "Cosmetologist": 3 },
  "64739": { "Cosmetologist": 8 },
  "65354": { "Cosmetologist": 4 },
  "39560": { "Cosmetologist": 8 },
  "64778": { "Cosmetologist": 3 },
  "73064": { "Cosmetologist": 3, "Barber": 1 },
  "64847": { "Cosmetologist": 1 },
  "62467": { "Cosmetologist": 1 },
  "77584": { "Cosmetologist": 705, "Barber": 66 },
  "64440": { "Cosmetologist": 3 },
  "82729": { "Cosmetologist": 2 },
  "95757": { "Cosmetologist": 5 },
  "20744": { "Cosmetologist": 4 },
  "62411": { "Cosmetologist": 1 },
  "63085": { "Cosmetologist": 1 },
  "63548": { "Cosmetologist": 12 },
  "52537": { "Cosmetologist": 32 },
  "64779": { "Cosmetologist": 14 },
  "89521": { "Cosmetologist": 9 },
  "72012": { "Cosmetologist": 2 },
  "62243": { "Cosmetologist": 5 },
  "74434": { "Cosmetologist": 2 },
  "72583": { "Cosmetologist": 1 },
  "64642": { "Cosmetologist": 2 },
  "64164": { "Cosmetologist": 4 },
  "63530": { "Cosmetologist": 4 },
  "64457": { "Cosmetologist": 1 },
  "64830": { "Cosmetologist": 7 },
  "89143": { "Cosmetologist": 1 },
  "64304": { "Cosmetologist": 1 },
  "37412": { "Cosmetologist": 3, "Barber": 15 },
  "65083": { "Cosmetologist": 1 },
  "63545": { "Cosmetologist": 5 },
  "65017": { "Cosmetologist": 8 },
  "75104": { "Cosmetologist": 446, "Barber": 98 },
  "64143": { "Cosmetologist": 1 },
  "63156": { "Cosmetologist": 2 },
  "36105": { "Cosmetologist": 1 },
  "64873": { "Cosmetologist": 6 },
  "65258": { "Cosmetologist": 4 },
  "65068": { "Cosmetologist": 8 },
  "72116": { "Cosmetologist": 3, "Barber": 1 },
  "65638": { "Cosmetologist": 1 },
  "62691": { "Cosmetologist": 1 },
  "65550": { "Cosmetologist": 15 },
  "76522": { "Cosmetologist": 252, "Barber": 46 },
  "37408": { "Cosmetologist": 1, "Barber": 2 },
  "65617": { "Cosmetologist": 6 },
  "65102": { "Cosmetologist": 6 },
  "54656": { "Cosmetologist": 1 },
  "37861": { "Cosmetologist": 4, "Barber": 2 },
  "50138": { "Cosmetologist": 56, "Barber": 5 },
  "64630": { "Cosmetologist": 1 },
  "64832": { "Cosmetologist": 4 },
  "64484": { "Cosmetologist": 3 },
  "64661": { "Cosmetologist": 5 },
  "75023": { "Cosmetologist": 376, "Barber": 29 },
  "65053": { "Cosmetologist": 11 },
  "74345": { "Cosmetologist": 2 },
  "74354": { "Cosmetologist": 13 },
  "96297": { "Cosmetologist": 1 },
  "65755": { "Cosmetologist": 1 },
  "72576": { "Cosmetologist": 2 },
  "63936": { "Cosmetologist": 3 },
  "74339": { "Cosmetologist": 2 },
  "27616": { "Cosmetologist": 551, "Barber": 5 },
  "37087": { "Cosmetologist": 8, "Barber": 44 },
  "70031": { "Cosmetologist": 1 },
  "63544": { "Cosmetologist": 6 },
  "68331": { "Cosmetologist": 1 },
  "77546": { "Cosmetologist": 464, "Barber": 17 },
  "75438": { "Cosmetologist": 8 },
  "64455": { "Cosmetologist": 3 },
  "65777": { "Cosmetologist": 4 },
  "64686": { "Cosmetologist": 1 },
  "35674": { "Cosmetologist": 1 },
  "53092": { "Cosmetologist": 4 },
  "11234": { "Cosmetologist": 6 },
  "64652": { "Cosmetologist": 2 },
  "37066": { "Cosmetologist": 11, "Barber": 24 },
  "50833": { "Cosmetologist": 18, "Barber": 1 },
  "65061": { "Cosmetologist": 3 },
  "73150": { "Cosmetologist": 1 },
  "96734": { "Cosmetologist": 16 },
  "63848": { "Cosmetologist": 4 },
  "61701": { "Cosmetologist": 4 },
  "65788": { "Cosmetologist": 4 },
  "65110": { "Cosmetologist": 1 },
  "94941": { "Cosmetologist": 3 },
  "38024": { "Cosmetologist": 9, "Barber": 3 },
  "19151": { "Cosmetologist": 2 },
  "93117": { "Cosmetologist": 2 },
  "62969": { "Cosmetologist": 2 },
  "44632": { "Cosmetologist": 1 },
  "51105": { "Cosmetologist": 26, "Barber": 1 },
  "18017": { "Cosmetologist": 15, "Barber": 5 },
  "43146": { "Cosmetologist": 1 },
  "62618": { "Cosmetologist": 1 },
  "64623": { "Cosmetologist": 3 },
  "17552": { "Cosmetologist": 11 },
  "65062": { "Cosmetologist": 1 },
  "38107": { "Cosmetologist": 2, "Barber": 23 },
  "77836": { "Cosmetologist": 75, "Barber": 6 },
  "74363": { "Cosmetologist": 3 },
  "63874": { "Cosmetologist": 3 },
  "65814": { "Cosmetologist": 3 },
  "36542": { "Cosmetologist": 11 },
  "95823": { "Cosmetologist": 6 },
  "63782": { "Cosmetologist": 1 },
  "64788": { "Cosmetologist": 15 },
  "64674": { "Cosmetologist": 2 },
  "98204": { "Cosmetologist": 2 },
  "63436": { "Cosmetologist": 9 },
  "63849": { "Cosmetologist": 1 },
  "64502": { "Cosmetologist": 1 },
  "73020": { "Cosmetologist": 1 },
  "62305": { "Cosmetologist": 8 },
  "63531": { "Cosmetologist": 4 },
  "38701": { "Cosmetologist": 4 },
  "50324": { "Cosmetologist": 26 },
  "65247": { "Cosmetologist": 3 },
  "53118": { "Cosmetologist": 1 },
  "94619": { "Cosmetologist": 2 },
  "37052": { "Cosmetologist": 2 },
  "37129": { "Cosmetologist": 7, "Barber": 98 },
  "64641": { "Cosmetologist": 3 },
  "61068": { "Cosmetologist": 3 },
  "60963": { "Cosmetologist": 1 },
  "93221": { "Cosmetologist": 1 },
  "65001": { "Cosmetologist": 2 },
  "63633": { "Cosmetologist": 3 },
  "35540": { "Cosmetologist": 1 },
  "63055": { "Cosmetologist": 21 },
  "98682": { "Cosmetologist": 6 },
  "65629": { "Cosmetologist": 7 },
  "90247": { "Cosmetologist": 3 },
  "63447": { "Cosmetologist": 1 },
  "79107": { "Cosmetologist": 266, "Barber": 21 },
  "93908": { "Cosmetologist": 1 },
  "62049": { "Cosmetologist": 4 },
  "42104": { "Cosmetologist": 6 },
  "57064": { "Cosmetologist": 3 },
  "68516": { "Cosmetologist": 2 },
  "28726": { "Cosmetologist": 20 },
  "64667": { "Cosmetologist": 3 },
  "65347": { "Cosmetologist": 4 },
  "52778": { "Cosmetologist": 28 },
  "30648": { "Cosmetologist": 3 },
  "61938": { "Cosmetologist": 1 },
  "39056": { "Cosmetologist": 16 },
  "62037": { "Cosmetologist": 2 },
  "62274": { "Cosmetologist": 2 },
  "63631": { "Cosmetologist": 5 },
  "73010": { "Cosmetologist": 2 },
  "65327": { "Cosmetologist": 1 },
  "64474": { "Cosmetologist": 4 },
  "65262": { "Cosmetologist": 6 },
  "93534": { "Cosmetologist": 3 },
  "64074": { "Cosmetologist": 5 },
  "62217": { "Cosmetologist": 1 },
  "62343": { "Cosmetologist": 1 },
  "65059": { "Cosmetologist": 8 },
  "92029": { "Cosmetologist": 3 },
  "64421": { "Cosmetologist": 3 },
  "70126": { "Cosmetologist": 2 },
  "50140": { "Cosmetologist": 10 },
  "72032": { "Cosmetologist": 4 },
  "73772": { "Cosmetologist": 1 },
  "70726": { "Cosmetologist": 5 },
  "72315": { "Cosmetologist": 6 },
  "62080": { "Cosmetologist": 1 },
  "64096": { "Cosmetologist": 7 },
  "88310": { "Cosmetologist": 5 },
  "63934": { "Cosmetologist": 2 },
  "64073": { "Cosmetologist": 1 },
  "99502": { "Cosmetologist": 2 },
  "68505": { "Cosmetologist": 1 },
  "38344": { "Cosmetologist": 2, "Barber": 2 },
  "51632": { "Cosmetologist": 26, "Barber": 1 },
  "38242": { "Cosmetologist": 2, "Barber": 6 },
  "77406": { "Cosmetologist": 470, "Barber": 30 },
  "63563": { "Cosmetologist": 2 },
  "64480": { "Cosmetologist": 2 },
  "65339": { "Cosmetologist": 3 },
  "37206": { "Cosmetologist": 4, "Barber": 21 },
  "75605": { "Cosmetologist": 336, "Barber": 15 },
  "90620": { "Cosmetologist": 1 },
  "72455": { "Cosmetologist": 4 },
  "83843": { "Cosmetologist": 1 },
  "62295": { "Cosmetologist": 3 },
  "62560": { "Cosmetologist": 2 },
  "53545": { "Cosmetologist": 2 },
  "50595": { "Cosmetologist": 30, "Barber": 3 },
  "65280": { "Cosmetologist": 1 },
  "52501": { "Cosmetologist": 75, "Barber": 1 },
  "65302": { "Cosmetologist": 3 },
  "64650": { "Cosmetologist": 7 },
  "63030": { "Cosmetologist": 1 },
  "65603": { "Cosmetologist": 1 },
  "65322": { "Cosmetologist": 3 },
  "74343": { "Cosmetologist": 3 },
  "62242": { "Cosmetologist": 2 },
  "63956": { "Cosmetologist": 5 },
  "65069": { "Cosmetologist": 4 },
  "62454": { "Cosmetologist": 1 },
  "65278": { "Cosmetologist": 1 },
  "64761": { "Cosmetologist": 8 },
  "72638": { "Cosmetologist": 5 },
  "37217": { "Cosmetologist": 8, "Barber": 27 },
  "65739": { "Cosmetologist": 2 },
  "68355": { "Cosmetologist": 2 },
  "26847": { "Cosmetologist": 1 },
  "60126": { "Cosmetologist": 5 },
  "64069": { "Cosmetologist": 2 },
  "63870": { "Cosmetologist": 4 },
  "68352": { "Cosmetologist": 1 },
  "61920": { "Cosmetologist": 2 },
  "64655": { "Cosmetologist": 2 },
  "22737": { "Cosmetologist": 4 },
  "78230": { "Cosmetologist": 273, "Barber": 22 },
  "63384": { "Cosmetologist": 9 },
  "53406": { "Cosmetologist": 4 },
  "64750": { "Cosmetologist": 5 },
  "84087": { "Cosmetologist": 1 },
  "62957": { "Cosmetologist": 7 },
  "63177": { "Cosmetologist": 3 },
  "39470": { "Cosmetologist": 1 },
  "93527": { "Cosmetologist": 1 },
  "65076": { "Cosmetologist": 3 },
  "13815": { "Cosmetologist": 1 },
  "39540": { "Cosmetologist": 8 },
  "64863": { "Cosmetologist": 7 },
  "35749": { "Cosmetologist": 5 },
  "99336": { "Cosmetologist": 4 },
  "65025": { "Cosmetologist": 4 },
  "52001": { "Cosmetologist": 214, "Barber": 2 },
  "63743": { "Cosmetologist": 2 },
  "72470": { "Cosmetologist": 1 },
  "77418": { "Cosmetologist": 63, "Barber": 6 },
  "54601": { "Cosmetologist": 2 },
  "38316": { "Cosmetologist": 1, "Barber": 1 },
  "95045": { "Cosmetologist": 1 },
  "78586": { "Cosmetologist": 278, "Barber": 73 },
  "72745": { "Cosmetologist": 1 },
  "65572": { "Cosmetologist": 1 },
  "68116": { "Cosmetologist": 10 },
  "96818": { "Cosmetologist": 11 },
  "63656": { "Cosmetologist": 2 },
  "74108": { "Cosmetologist": 1 },
  "63955": { "Cosmetologist": 4 },
  "38673": { "Cosmetologist": 1 },
  "65566": { "Cosmetologist": 3 },
  "40591": { "Cosmetologist": 1 },
  "62046": { "Cosmetologist": 1 },
  "92126": { "Cosmetologist": 5 },
  "63453": { "Cosmetologist": 4 },
  "65457": { "Cosmetologist": 1 },
  "14615": { "Cosmetologist": 1 },
  "63102": { "Cosmetologist": 5 },
  "62265": { "Cosmetologist": 6 },
  "28698": { "Cosmetologist": 16 },
  "75686": { "Cosmetologist": 80, "Barber": 10 },
  "37146": { "Cosmetologist": 2, "Barber": 4 },
  "72560": { "Cosmetologist": 1 },
  "97415": { "Cosmetologist": 2 },
  "64648": { "Cosmetologist": 5 },
  "31822": { "Cosmetologist": 1 },
  "63833": { "Cosmetologist": 2 },
  "65446": { "Cosmetologist": 2 },
  "73601": { "Cosmetologist": 3 },
  "65759": { "Cosmetologist": 6 },
  "63076": { "Cosmetologist": 1 },
  "90732": { "Cosmetologist": 3 },
  "62801": { "Cosmetologist": 4 },
  "62088": { "Cosmetologist": 3 },
  "63852": { "Cosmetologist": 2 },
  "74035": { "Cosmetologist": 1 },
  "64645": { "Cosmetologist": 2 },
  "10002": { "Cosmetologist": 10 },
  "40023": { "Cosmetologist": 1 },
  "63826": { "Cosmetologist": 1 },
  "63954": { "Cosmetologist": 7 },
  "64781": { "Cosmetologist": 1 },
  "63663": { "Cosmetologist": 3 },
  "74158": { "Cosmetologist": 1 },
  "60626": { "Cosmetologist": 3 },
  "63078": { "Cosmetologist": 1 },
  "62360": { "Cosmetologist": 1 },
  "96319": { "Cosmetologist": 2 },
  "65586": { "Cosmetologist": 1 },
  "76010": { "Cosmetologist": 537, "Barber": 44 },
  "50022": { "Cosmetologist": 32 },
  "55438": { "Cosmetologist": 2 },
  "61727": { "Cosmetologist": 2 },
  "65443": { "Cosmetologist": 5 },
  "77025": { "Cosmetologist": 106, "Barber": 16 },
  "42086": { "Cosmetologist": 1 },
  "75208": { "Cosmetologist": 193, "Barber": 34 },
  "38079": { "Cosmetologist": 1, "Barber": 8 },
  "29582": { "Cosmetologist": 28 },
  "37208": { "Cosmetologist": 4, "Barber": 29 },
  "72023": { "Cosmetologist": 12 },
  "78664": { "Cosmetologist": 474, "Barber": 34 },
  "45050": { "Cosmetologist": 1 },
  "65436": { "Cosmetologist": 1 },
  "93035": { "Cosmetologist": 4 },
  "72740": { "Cosmetologist": 2 },
  "52332": { "Cosmetologist": 9, "Barber": 1 },
  "98606": { "Cosmetologist": 1 },
  "27526": { "Cosmetologist": 342, "Barber": 4 },
  "90804": { "Cosmetologist": 4 },
  "64497": { "Cosmetologist": 2 },
  "20190": { "Cosmetologist": 4 },
  "72476": { "Cosmetologist": 2 },
  "49854": { "Cosmetologist": 32, "Barber": 1 },
  "72117": { "Cosmetologist": 2 },
  "39194": { "Cosmetologist": 1 },
  "70605": { "Cosmetologist": 2 },
  "28460": { "Cosmetologist": 94 },
  "55419": { "Cosmetologist": 4 },
  "63073": { "Cosmetologist": 3 },
  "65440": { "Cosmetologist": 2 },
  "50428": { "Cosmetologist": 54 },
  "78261": { "Cosmetologist": 297, "Barber": 9 },
  "72078": { "Cosmetologist": 1 },
  "65715": { "Cosmetologist": 1 },
  "65685": { "Cosmetologist": 4 },
  "65615": { "Cosmetologist": 1 },
  "63463": { "Cosmetologist": 4 },
  "77864": { "Cosmetologist": 49, "Barber": 4 },
  "63450": { "Cosmetologist": 3 },
  "75418": { "Cosmetologist": 72, "Barber": 8 },
  "20002": { "Cosmetologist": 3 },
  "62321": { "Cosmetologist": 5 },
  "38606": { "Cosmetologist": 2 },
  "62563": { "Cosmetologist": 2 },
  "62330": { "Cosmetologist": 5 },
  "37617": { "Cosmetologist": 5, "Barber": 5 },
  "63735": { "Cosmetologist": 2 },
  "33307": { "Cosmetologist": 10 },
  "36547": { "Cosmetologist": 2 },
  "76177": { "Cosmetologist": 309, "Barber": 11 },
  "60416": { "Cosmetologist": 2 },
  "48173": { "Cosmetologist": 115, "Barber": 3 },
  "73546": { "Cosmetologist": 1 },
  "63022": { "Cosmetologist": 2 },
  "64839": { "Cosmetologist": 1 },
  "65064": { "Cosmetologist": 3 },
  "29730": { "Cosmetologist": 104 },
  "62352": { "Cosmetologist": 1 },
  "63823": { "Cosmetologist": 2 },
  "65329": { "Cosmetologist": 3 },
  "62056": { "Cosmetologist": 2 },
  "62914": { "Cosmetologist": 5 },
  "92057": { "Cosmetologist": 8 },
  "76085": { "Cosmetologist": 111, "Barber": 11 },
  "64493": { "Cosmetologist": 3 },
  "65034": { "Cosmetologist": 1 },
  "64814": { "Cosmetologist": 1 },
  "75010": { "Cosmetologist": 473, "Barber": 14 },
  "65333": { "Cosmetologist": 3 },
  "96273": { "Cosmetologist": 3 },
  "19966": { "Cosmetologist": 2 },
  "98466": { "Cosmetologist": 1 },
  "42408": { "Cosmetologist": 2 },
  "83347": { "Cosmetologist": 1 },
  "62644": { "Cosmetologist": 1 },
  "63787": { "Cosmetologist": 5 },
  "55265": { "Cosmetologist": 1 },
  "70001": { "Cosmetologist": 6 },
  "65727": { "Cosmetologist": 2 },
  "47567": { "Cosmetologist": 1 },
  "63941": { "Cosmetologist": 2 },
  "62058": { "Cosmetologist": 2 },
  "65817": { "Cosmetologist": 1 },
  "73162": { "Cosmetologist": 3 },
  "99688": { "Cosmetologist": 1 },
  "76088": { "Cosmetologist": 137, "Barber": 9 },
  "42087": { "Cosmetologist": 1 },
  "64722": { "Cosmetologist": 2 },
  "41017": { "Cosmetologist": 5 },
  "32853": { "Cosmetologist": 6 },
  "63536": { "Cosmetologist": 3 },
  "85625": { "Cosmetologist": 1 },
  "64165": { "Cosmetologist": 2 },
  "50707": { "Cosmetologist": 29, "Barber": 9 },
  "63474": { "Cosmetologist": 2 },
  "64444": { "Cosmetologist": 4 },
  "49265": { "Cosmetologist": 43, "Barber": 2 },
  "71280": { "Cosmetologist": 1 },
  "65077": { "Cosmetologist": 4 },
  "64066": { "Cosmetologist": 1 },
  "62942": { "Cosmetologist": 1 },
  "76011": { "Cosmetologist": 161, "Barber": 25 },
  "19901": { "Cosmetologist": 4 },
  "65570": { "Cosmetologist": 5 },
  "64681": { "Cosmetologist": 3 },
  "63855": { "Cosmetologist": 1 },
  "76513": { "Cosmetologist": 234, "Barber": 23 },
  "40142": { "Cosmetologist": 1 },
  "42029": { "Cosmetologist": 2 },
  "65434": { "Cosmetologist": 1 },
  "63951": { "Cosmetologist": 3 },
  "55405": { "Cosmetologist": 1 },
  "12563": { "Cosmetologist": 4 },
  "38030": { "Cosmetologist": 1 },
  "64625": { "Cosmetologist": 4 },
  "64970": { "Cosmetologist": 1 },
  "84669": { "Cosmetologist": 1 },
  "53530": { "Cosmetologist": 1 },
  "65029": { "Cosmetologist": 1 },
  "72611": { "Cosmetologist": 1 },
  "61615": { "Cosmetologist": 2, "Barber": 1 },
  "64646": { "Cosmetologist": 1 },
  "61401": { "Cosmetologist": 8 },
  "22655": { "Cosmetologist": 4 },
  "63395": { "Cosmetologist": 1 },
  "78629": { "Cosmetologist": 79, "Barber": 6 },
  "62626": { "Cosmetologist": 1 },
  "65609": { "Cosmetologist": 5 },
  "91739": { "Cosmetologist": 2 },
  "96130": { "Cosmetologist": 1 },
  "36081": { "Cosmetologist": 5 },
  "77493": { "Cosmetologist": 593, "Barber": 56 },
  "76053": { "Cosmetologist": 245, "Barber": 18 },
  "71459": { "Cosmetologist": 3 },
  "61114": { "Cosmetologist": 2 },
  "64632": { "Cosmetologist": 4 },
  "10469": { "Cosmetologist": 4 },
  "65762": { "Cosmetologist": 1 },
  "39402": { "Cosmetologist": 13 },
  "72204": { "Cosmetologist": 2 },
  "72774": { "Cosmetologist": 2 },
  "77833": { "Cosmetologist": 221, "Barber": 11 },
  "97062": { "Cosmetologist": 1 },
  "94612": { "Cosmetologist": 1 },
  "65330": { "Cosmetologist": 4 },
  "93291": { "Cosmetologist": 2 },
  "58048": { "Cosmetologist": 1 },
  "64120": { "Cosmetologist": 2 },
  "72634": { "Cosmetologist": 2 },
  "65808": { "Cosmetologist": 3 },
  "65634": { "Cosmetologist": 2 },
  "64688": { "Cosmetologist": 3 },
  "51631": { "Cosmetologist": 1 },
  "44017": { "Cosmetologist": 2 },
  "65882": { "Cosmetologist": 1 },
  "50226": { "Cosmetologist": 39, "Barber": 1 },
  "37922": { "Cosmetologist": 10, "Barber": 9 },
  "50023": { "Cosmetologist": 210, "Barber": 11 },
  "62069": { "Cosmetologist": 1 },
  "74571": { "Cosmetologist": 1 },
  "72401": { "Cosmetologist": 2 },
  "72405": { "Cosmetologist": 3 },
  "13035": { "Cosmetologist": 2 },
  "61008": { "Cosmetologist": 3 },
  "78754": { "Cosmetologist": 559, "Barber": 16 },
  "72947": { "Cosmetologist": 1 },
  "17566": { "Cosmetologist": 4, "Barber": 2 },
  "73036": { "Cosmetologist": 2 },
  "97304": { "Cosmetologist": 3 },
  "77488": { "Cosmetologist": 101, "Barber": 11 },
  "76018": { "Cosmetologist": 754, "Barber": 34 },
  "64167": { "Cosmetologist": 3 },
  "64426": { "Cosmetologist": 2 },
  "65090": { "Cosmetologist": 1 },
  "31829": { "Cosmetologist": 1 },
  "64431": { "Cosmetologist": 2 },
  "78413": { "Cosmetologist": 233, "Barber": 43 },
  "48035": { "Cosmetologist": 253, "Barber": 12 },
  "49920": { "Cosmetologist": 24, "Barber": 1 },
  "75202": { "Cosmetologist": 21, "Barber": 2 },
  "56001": { "Cosmetologist": 6 },
  "63880": { "Cosmetologist": 2 },
  "53220": { "Cosmetologist": 9 },
  "53226": { "Cosmetologist": 1 },
  "40515": { "Cosmetologist": 7 },
  "77094": { "Cosmetologist": 36, "Barber": 4 },
  "64684": { "Cosmetologist": 1 },
  "42452": { "Cosmetologist": 1 },
  "64423": { "Cosmetologist": 3 },
  "19805": { "Cosmetologist": 4 },
  "92131": { "Cosmetologist": 1 },
  "77057": { "Cosmetologist": 287, "Barber": 33 },
  "64163": { "Cosmetologist": 7 },
  "62558": { "Cosmetologist": 1 },
  "98408": { "Cosmetologist": 2 },
  "62998": { "Cosmetologist": 1 },
  "70810": { "Cosmetologist": 1 },
  "75154": { "Cosmetologist": 448, "Barber": 82 },
  "60104": { "Cosmetologist": 2 },
  "40577": { "Cosmetologist": 1 },
  "64115": { "Cosmetologist": 1 },
  "60502": { "Cosmetologist": 1 },
  "55106": { "Cosmetologist": 1 },
  "72660": { "Cosmetologist": 2 },
  "95901": { "Cosmetologist": 1 },
  "68138": { "Cosmetologist": 1 },
  "78102": { "Cosmetologist": 121, "Barber": 23 },
  "74016": { "Cosmetologist": 1 },
  "62059": { "Cosmetologist": 1 },
  "29316": { "Cosmetologist": 21 },
  "73662": { "Cosmetologist": 1 },
  "62022": { "Cosmetologist": 1 },
  "19044": { "Cosmetologist": 5, "Barber": 2 },
  "94590": { "Cosmetologist": 2 },
  "62990": { "Cosmetologist": 2 },
  "85120": { "Cosmetologist": 2 },
  "72635": { "Cosmetologist": 2 },
  "49048": { "Cosmetologist": 137, "Barber": 10 },
  "63860": { "Cosmetologist": 1 },
  "63942": { "Cosmetologist": 1 },
  "10003": { "Cosmetologist": 5 },
  "64740": { "Cosmetologist": 6 },
  "63438": { "Cosmetologist": 3 },
  "64018": { "Cosmetologist": 6 },
  "42071": { "Cosmetologist": 2 },
  "68621": { "Cosmetologist": 1 },
  "93245": { "Cosmetologist": 1 },
  "74010": { "Cosmetologist": 1 },
  "50124": { "Cosmetologist": 19 },
  "53548": { "Cosmetologist": 1 },
  "38450": { "Cosmetologist": 1, "Barber": 1 },
  "90723": { "Cosmetologist": 1 },
  "83858": { "Cosmetologist": 3 },
  "29621": { "Cosmetologist": 16 },
  "72916": { "Cosmetologist": 2 },
  "63779": { "Cosmetologist": 2 },
  "72823": { "Cosmetologist": 1 },
  "76036": { "Cosmetologist": 354, "Barber": 33 },
  "23833": { "Cosmetologist": 1 },
  "62018": { "Cosmetologist": 2 },
  "63566": { "Cosmetologist": 1 },
  "50320": { "Cosmetologist": 73, "Barber": 7 },
  "72209": { "Cosmetologist": 1 },
  "64445": { "Cosmetologist": 1 },
  "18360": { "Cosmetologist": 34, "Barber": 12 },
  "65768": { "Cosmetologist": 2 },
  "86005": { "Cosmetologist": 2 },
  "72417": { "Cosmetologist": 3 },
  "68136": { "Cosmetologist": 6 },
  "74346": { "Cosmetologist": 1 },
  "95991": { "Cosmetologist": 2 },
  "27511": { "Cosmetologist": 191, "Barber": 2 },
  "72753": { "Cosmetologist": 1 },
  "62097": { "Cosmetologist": 1 },
  "75236": { "Cosmetologist": 135, "Barber": 20 },
  "62347": { "Cosmetologist": 1 },
  "44039": { "Cosmetologist": 2 },
  "16051": { "Cosmetologist": 6, "Barber": 1 },
  "13904": { "Cosmetologist": 1 },
  "92030": { "Cosmetologist": 1 },
  "76233": { "Cosmetologist": 31, "Barber": 2 },
  "75115": { "Cosmetologist": 511, "Barber": 92 },
  "73023": { "Cosmetologist": 1 },
  "63460": { "Cosmetologist": 2 },
  "42301": { "Cosmetologist": 3 },
  "62894": { "Cosmetologist": 1 },
  "65650": { "Cosmetologist": 3 },
  "19734": { "Cosmetologist": 5 },
  "96744": { "Cosmetologist": 3 },
  "90129": { "Cosmetologist": 1 },
  "22310": { "Cosmetologist": 5 },
  "56444": { "Cosmetologist": 1 },
  "63824": { "Cosmetologist": 2 },
  "18159": { "Cosmetologist": 1 },
  "96001": { "Cosmetologist": 2 },
  "88240": { "Cosmetologist": 2 },
  "95973": { "Cosmetologist": 1 },
  "59405": { "Cosmetologist": 3 },
  "62694": { "Cosmetologist": 1 },
  "65464": { "Cosmetologist": 2 },
  "64434": { "Cosmetologist": 1 },
  "37058": { "Cosmetologist": 3, "Barber": 6 },
  "29150": { "Cosmetologist": 13 },
  "73044": { "Cosmetologist": 2 },
  "38122": { "Cosmetologist": 1, "Barber": 14 },
  "62859": { "Cosmetologist": 2 },
  "75016": { "Cosmetologist": 2 },
  "37210": { "Cosmetologist": 3, "Barber": 10 },
  "92840": { "Cosmetologist": 6 },
  "72416": { "Cosmetologist": 1 },
  "28348": { "Cosmetologist": 291, "Barber": 6 },
  "63333": { "Cosmetologist": 3 },
  "27958": { "Cosmetologist": 42 },
  "64141": { "Cosmetologist": 4 },
  "98587": { "Cosmetologist": 1 },
  "72396": { "Cosmetologist": 4 },
  "89179": { "Cosmetologist": 8 },
  "60473": { "Cosmetologist": 1 },
  "37921": { "Cosmetologist": 2, "Barber": 27 },
  "90010": { "Cosmetologist": 1 },
  "75703": { "Cosmetologist": 384, "Barber": 37 },
  "74115": { "Cosmetologist": 1 },
  "28906": { "Cosmetologist": 120 },
  "75032": { "Cosmetologist": 280, "Barber": 19 },
  "62320": { "Cosmetologist": 2 },
  "37043": { "Cosmetologist": 19, "Barber": 34 },
  "71901": { "Cosmetologist": 4 },
  "61601": { "Cosmetologist": 1 },
  "51566": { "Cosmetologist": 25, "Barber": 1 },
  "73120": { "Cosmetologist": 3 },
  "74602": { "Cosmetologist": 1 },
  "27376": { "Cosmetologist": 63 },
  "64451": { "Cosmetologist": 1 },
  "54650": { "Cosmetologist": 3 },
  "22311": { "Cosmetologist": 1 },
  "77381": { "Cosmetologist": 143, "Barber": 8 },
  "95982": { "Cosmetologist": 1 },
  "87714": { "Cosmetologist": 1 },
  "68164": { "Cosmetologist": 6, "Barber": 1 },
  "65286": { "Cosmetologist": 1 },
  "98394": { "Cosmetologist": 1 },
  "55972": { "Cosmetologist": 1 },
  "62231": { "Cosmetologist": 1 },
  "55428": { "Cosmetologist": 4 },
  "63868": { "Cosmetologist": 2 },
  "62901": { "Cosmetologist": 2 },
  "84093": { "Cosmetologist": 2 },
  "98370": { "Cosmetologist": 1 },
  "63539": { "Cosmetologist": 1 },
  "98296": { "Cosmetologist": 1 },
  "37932": { "Cosmetologist": 6, "Barber": 11 },
  "28110": { "Cosmetologist": 389, "Barber": 2 },
  "83442": { "Cosmetologist": 2 },
  "65015": { "Cosmetologist": 2 },
  "60137": { "Cosmetologist": 3 },
  "92277": { "Cosmetologist": 7 },
  "62863": { "Cosmetologist": 1 },
  "62884": { "Cosmetologist": 1 },
  "98109": { "Cosmetologist": 1 },
  "92844": { "Cosmetologist": 13 },
  "75401": { "Cosmetologist": 114, "Barber": 13 },
  "72651": { "Cosmetologist": 2 },
  "55101": { "Cosmetologist": 1 },
  "78640": { "Cosmetologist": 553, "Barber": 58 },
  "65282": { "Cosmetologist": 1 },
  "77055": { "Cosmetologist": 215, "Barber": 12 },
  "56601": { "Cosmetologist": 2, "Barber": 1 },
  "63538": { "Cosmetologist": 1 },
  "46048": { "Cosmetologist": 1 },
  "73110": { "Cosmetologist": 3 },
  "48124": { "Cosmetologist": 206, "Barber": 19 },
  "63330": { "Cosmetologist": 2 },
  "98532": { "Cosmetologist": 1 },
  "63744": { "Cosmetologist": 3 },
  "63151": { "Cosmetologist": 1 },
  "64433": { "Cosmetologist": 1 },
  "65744": { "Cosmetologist": 1 },
  "57301": { "Cosmetologist": 1 },
  "27295": { "Cosmetologist": 238, "Barber": 1 },
  "63625": { "Cosmetologist": 1 },
  "98664": { "Cosmetologist": 2 },
  "65783": { "Cosmetologist": 2 },
  "72316": { "Cosmetologist": 1 },
  "85239": { "Cosmetologist": 1 },
  "63828": { "Cosmetologist": 1 },
  "70123": { "Cosmetologist": 3 },
  "75048": { "Cosmetologist": 623, "Barber": 15 },
  "23009": { "Cosmetologist": 1 },
  "20657": { "Cosmetologist": 2 },
  "40213": { "Cosmetologist": 1 },
  "71047": { "Cosmetologist": 2 },
  "62952": { "Cosmetologist": 1 },
  "72946": { "Cosmetologist": 1 },
  "34673": { "Cosmetologist": 2 },
  "60177": { "Cosmetologist": 2 },
  "72956": { "Cosmetologist": 2 },
  "72722": { "Cosmetologist": 1 },
  "78041": { "Cosmetologist": 285, "Barber": 18 },
  "76137": { "Cosmetologist": 850, "Barber": 47 },
  "63219": { "Cosmetologist": 1 },
  "55301": { "Cosmetologist": 1 },
  "19711": { "Cosmetologist": 3 },
  "43551": { "Cosmetologist": 16 },
  "53105": { "Cosmetologist": 1 },
  "96950": { "Cosmetologist": 1 },
  "72031": { "Cosmetologist": 1 },
  "42003": { "Cosmetologist": 2 },
  "90680": { "Cosmetologist": 2, "Barber": 1 },
  "65237": { "Cosmetologist": 3 },
  "62312": { "Cosmetologist": 1 },
  "75075": { "Cosmetologist": 282, "Barber": 29 },
  "61571": { "Cosmetologist": 4 },
  "38237": { "Cosmetologist": 2, "Barber": 5 },
  "91762": { "Cosmetologist": 1 },
  "92672": { "Cosmetologist": 2 },
  "78723": { "Cosmetologist": 147, "Barber": 23 },
  "96367": { "Cosmetologist": 4 },
  "42261": { "Cosmetologist": 1 },
  "65944": { "Cosmetologist": 1 },
  "50210": { "Cosmetologist": 5, "Barber": 2 },
  "66249": { "Cosmetologist": 1 },
  "75028": { "Cosmetologist": 303, "Barber": 15 },
  "45304": { "Cosmetologist": 1 },
  "62650": { "Cosmetologist": 1 },
  "95401": { "Cosmetologist": 2 },
  "64656": { "Cosmetologist": 2 },
  "65702": { "Cosmetologist": 2 },
  "37615": { "Cosmetologist": 2, "Barber": 9 },
  "89044": { "Cosmetologist": 4 },
  "37160": { "Cosmetologist": 3, "Barber": 39 },
  "75042": { "Cosmetologist": 1118, "Barber": 35 },
  "76048": { "Cosmetologist": 227, "Barber": 9 },
  "38343": { "Cosmetologist": 1, "Barber": 11 },
  "64458": { "Cosmetologist": 1 },
  "23220": { "Cosmetologist": 4 },
  "62297": { "Cosmetologist": 1 },
  "65335": { "Cosmetologist": 2 },
  "62250": { "Cosmetologist": 1 },
  "20003": { "Cosmetologist": 4 },
  "20009": { "Cosmetologist": 5 },
  "38614": { "Cosmetologist": 2 },
  "60091": { "Cosmetologist": 1 },
  "53024": { "Cosmetologist": 3 },
  "38187": { "Cosmetologist": 2 },
  "50232": { "Cosmetologist": 7 },
  "97220": { "Cosmetologist": 1 },
  "29152": { "Cosmetologist": 4 },
  "53154": { "Cosmetologist": 3 },
  "72039": { "Cosmetologist": 1 },
  "29926": { "Cosmetologist": 15 },
  "62985": { "Cosmetologist": 1 },
  "93444": { "Cosmetologist": 1 },
  "90068": { "Cosmetologist": 6 },
  "63041": { "Cosmetologist": 3 },
  "54880": { "Cosmetologist": 1 },
  "96003": { "Cosmetologist": 4 },
  "75041": { "Cosmetologist": 290, "Barber": 34 },
  "98059": { "Cosmetologist": 1 },
  "42376": { "Cosmetologist": 1 },
  "65050": { "Cosmetologist": 2 },
  "85352": { "Cosmetologist": 1 },
  "65730": { "Cosmetologist": 1 },
  "36832": { "Cosmetologist": 5 },
  "72456": { "Cosmetologist": 1 },
  "64021": { "Cosmetologist": 3 },
  "39145": { "Cosmetologist": 1 },
  "62869": { "Cosmetologist": 1 },
  "27607": { "Cosmetologist": 61, "Barber": 1 },
  "64453": { "Cosmetologist": 1 },
  "65630": { "Cosmetologist": 2 },
  "29501": { "Cosmetologist": 17 },
  "64036": { "Cosmetologist": 2 },
  "93021": { "Cosmetologist": 4 },
  "92841": { "Cosmetologist": 4 },
  "90254": { "Cosmetologist": 1 },
  "64514": { "Cosmetologist": 1 },
  "57025": { "Cosmetologist": 1 },
  "85622": { "Cosmetologist": 2 },
  "61520": { "Cosmetologist": 1 },
  "73538": { "Cosmetologist": 1 },
  "22306": { "Cosmetologist": 2 },
  "84037": { "Cosmetologist": 1 },
  "38059": { "Cosmetologist": 2, "Barber": 1 },
  "52626": { "Cosmetologist": 8 },
  "98275": { "Cosmetologist": 3 },
  "90013": { "Cosmetologist": 1 },
  "15143": { "Cosmetologist": 4, "Barber": 1 },
  "63774": { "Cosmetologist": 1 },
  "60565": { "Cosmetologist": 6 },
  "38328": { "Cosmetologist": 1 },
  "11213": { "Cosmetologist": 8 },
  "65735": { "Cosmetologist": 1 },
  "60103": { "Cosmetologist": 3 },
  "35811": { "Cosmetologist": 11 },
  "68342": { "Cosmetologist": 1 },
  "72360": { "Cosmetologist": 2 },
  "63543": { "Cosmetologist": 1 },
  "64676": { "Cosmetologist": 1 },
  "89061": { "Cosmetologist": 2 },
  "23111": { "Cosmetologist": 3 },
  "64090": { "Cosmetologist": 1 },
  "22810": { "Cosmetologist": 1 },
  "84738": { "Cosmetologist": 4 },
  "74105": { "Cosmetologist": 6 },
  "63652": { "Cosmetologist": 1 },
  "75039": { "Cosmetologist": 123, "Barber": 5 },
  "91205": { "Cosmetologist": 1 },
  "63432": { "Cosmetologist": 2 },
  "64476": { "Cosmetologist": 2 },
  "64833": { "Cosmetologist": 1 },
  "65293": { "Cosmetologist": 1 },
  "39573": { "Cosmetologist": 2 },
  "62230": { "Cosmetologist": 1 },
  "63140": { "Cosmetologist": 1 },
  "35739": { "Cosmetologist": 3 },
  "64723": { "Cosmetologist": 1 },
  "33957": { "Cosmetologist": 26 },
  "39565": { "Cosmetologist": 4 },
  "18707": { "Cosmetologist": 4, "Barber": 3 },
  "42066": { "Cosmetologist": 2 },
  "75149": { "Cosmetologist": 389, "Barber": 77 },
  "49660": { "Cosmetologist": 81 },
  "76226": { "Cosmetologist": 289, "Barber": 6 },
  "74743": { "Cosmetologist": 1 },
  "35816": { "Cosmetologist": 2 },
  "75087": { "Cosmetologist": 335, "Barber": 26 },
  "62253": { "Cosmetologist": 1 },
  "75234": { "Cosmetologist": 291, "Barber": 18 },
  "71602": { "Cosmetologist": 4 },
  "38558": { "Cosmetologist": 8 },
  "61554": { "Cosmetologist": 5, "Barber": 1 },
  "84648": { "Cosmetologist": 1 },
  "74361": { "Cosmetologist": 2 },
  "70706": { "Cosmetologist": 4 },
  "65344": { "Cosmetologist": 1 },
  "78753": { "Cosmetologist": 580, "Barber": 27 },
  "75013": { "Cosmetologist": 406, "Barber": 17 },
  "37381": { "Cosmetologist": 6, "Barber": 1 },
  "65479": { "Cosmetologist": 2 },
  "64432": { "Cosmetologist": 1 },
  "65260": { "Cosmetologist": 1 },
  "64188": { "Cosmetologist": 1 },
  "72732": { "Cosmetologist": 1 },
  "50315": { "Cosmetologist": 139, "Barber": 23 },
  "48083": { "Cosmetologist": 141, "Barber": 8 },
  "61832": { "Cosmetologist": 3 },
  "52317": { "Cosmetologist": 91, "Barber": 4 },
  "62920": { "Cosmetologist": 1 },
  "21153": { "Cosmetologist": 1 },
  "76437": { "Cosmetologist": 36, "Barber": 2 },
  "23234": { "Cosmetologist": 4 },
  "62808": { "Cosmetologist": 1 },
  "27530": { "Cosmetologist": 218, "Barber": 2 },
  "64475": { "Cosmetologist": 2 },
  "28025": { "Cosmetologist": 435 },
  "77080": { "Cosmetologist": 245, "Barber": 22 },
  "75036": { "Cosmetologist": 6 },
  "76121": { "Cosmetologist": 1 },
  "62912": { "Cosmetologist": 1 },
  "62832": { "Cosmetologist": 2 },
  "49098": { "Cosmetologist": 48 },
  "12589": { "Cosmetologist": 4 },
  "56751": { "Cosmetologist": 1 },
  "72704": { "Cosmetologist": 5 },
  "19056": { "Cosmetologist": 8 },
  "72153": { "Cosmetologist": 1 },
  "24382": { "Cosmetologist": 6 },
  "75252": { "Cosmetologist": 177, "Barber": 13 },
  "77073": { "Cosmetologist": 311, "Barber": 52 },
  "63840": { "Cosmetologist": 1 },
  "61614": { "Cosmetologist": 4 },
  "63164": { "Cosmetologist": 1 },
  "48473": { "Cosmetologist": 234, "Barber": 21 },
  "63560": { "Cosmetologist": 1 },
  "28540": { "Cosmetologist": 453, "Barber": 2 },
  "57049": { "Cosmetologist": 17 },
  "21921": { "Cosmetologist": 3 },
  "54449": { "Cosmetologist": 3 },
  "92311": { "Cosmetologist": 1 },
  "73122": { "Cosmetologist": 1 },
  "77377": { "Cosmetologist": 422, "Barber": 17 },
  "62526": { "Cosmetologist": 3 },
  "15129": { "Cosmetologist": 5, "Barber": 1 },
  "68339": { "Cosmetologist": 1 },
  "88087": { "Cosmetologist": 1 },
  "78861": { "Cosmetologist": 57, "Barber": 8 },
  "62257": { "Cosmetologist": 1 },
  "75693": { "Cosmetologist": 60, "Barber": 2 },
  "64806": { "Cosmetologist": 1 },
  "63142": { "Cosmetologist": 1 },
  "78216": { "Cosmetologist": 295, "Barber": 14 },
  "63567": { "Cosmetologist": 1 },
  "60438": { "Cosmetologist": 2 },
  "72542": { "Cosmetologist": 1 },
  "23606": { "Cosmetologist": 7 },
  "66560": { "Cosmetologist": 1 },
  "64857": { "Cosmetologist": 1 },
  "65726": { "Cosmetologist": 1 },
  "89130": { "Cosmetologist": 6 },
  "60185": { "Cosmetologist": 1 },
  "37853": { "Cosmetologist": 2, "Barber": 2 },
  "71950": { "Cosmetologist": 1 },
  "90241": { "Cosmetologist": 1 },
  "70118": { "Cosmetologist": 6 },
  "62521": { "Cosmetologist": 2 },
  "74959": { "Cosmetologist": 1 },
  "45005": { "Cosmetologist": 5 },
  "98104": { "Cosmetologist": 2 },
  "91406": { "Cosmetologist": 3 },
  "88254": { "Cosmetologist": 1 },
  "92618": { "Cosmetologist": 7 },
  "56258": { "Cosmetologist": 1 },
  "77954": { "Cosmetologist": 53, "Barber": 8 },
  "35226": { "Cosmetologist": 2 },
  "77306": { "Cosmetologist": 94, "Barber": 7 },
  "44406": { "Cosmetologist": 3 },
  "55325": { "Cosmetologist": 1 },
  "76180": { "Cosmetologist": 365, "Barber": 28 },
  "83261": { "Cosmetologist": 1 },
  "62970": { "Cosmetologist": 1 },
  "95131": { "Cosmetologist": 4 },
  "98642": { "Cosmetologist": 1 },
  "52235": { "Cosmetologist": 12, "Barber": 1 },
  "48214": { "Cosmetologist": 57, "Barber": 10 },
  "72120": { "Cosmetologist": 5 },
  "58646": { "Cosmetologist": 1 },
  "84741": { "Cosmetologist": 1 },
  "88337": { "Cosmetologist": 1 },
  "37748": { "Cosmetologist": 3, "Barber": 6 },
  "36340": { "Cosmetologist": 1 },
  "62874": { "Cosmetologist": 1 },
  "45069": { "Cosmetologist": 10 },
  "73025": { "Cosmetologist": 2 },
  "94605": { "Cosmetologist": 3 },
  "27103": { "Cosmetologist": 273, "Barber": 6 },
  "37091": { "Cosmetologist": 6, "Barber": 27 },
  "74072": { "Cosmetologist": 1 },
  "92342": { "Cosmetologist": 1 },
  "91913": { "Cosmetologist": 3 },
  "84067": { "Cosmetologist": 4 },
  "63433": { "Cosmetologist": 1 },
  "35447": { "Cosmetologist": 1 },
  "94116": { "Cosmetologist": 1 },
  "12901": { "Cosmetologist": 3 },
  "64496": { "Cosmetologist": 1 },
  "97006": { "Cosmetologist": 3 },
  "65442": { "Cosmetologist": 1 },
  "11415": { "Cosmetologist": 4 },
  "65765": { "Cosmetologist": 1 },
  "97068": { "Cosmetologist": 2 },
  "48642": { "Cosmetologist": 185, "Barber": 1 },
  "62241": { "Cosmetologist": 1 },
  "64677": { "Cosmetologist": 1 },
  "63145": { "Cosmetologist": 1 },
  "70525": { "Cosmetologist": 1 },
  "60370": { "Cosmetologist": 1 },
  "39345": { "Cosmetologist": 2 },
  "53074": { "Cosmetologist": 3 },
  "72210": { "Cosmetologist": 2 },
  "52591": { "Cosmetologist": 18 },
  "55347": { "Cosmetologist": 2 },
  "50060": { "Cosmetologist": 9 },
  "60148": { "Cosmetologist": 5 },
  "60439": { "Cosmetologist": 1 },
  "61846": { "Cosmetologist": 2 },
  "72768": { "Cosmetologist": 1 },
  "77835": { "Cosmetologist": 23, "Barber": 2 },
  "73075": { "Cosmetologist": 1 },
  "45039": { "Cosmetologist": 9 },
  "97210": { "Cosmetologist": 2 },
  "64438": { "Cosmetologist": 1 },
  "22079": { "Cosmetologist": 5 },
  "92037": { "Cosmetologist": 4 },
  "27377": { "Cosmetologist": 78, "Barber": 1 },
  "28462": { "Cosmetologist": 82 },
  "29728": { "Cosmetologist": 36 },
  "28658": { "Cosmetologist": 165 },
  "28785": { "Cosmetologist": 45 },
  "27596": { "Cosmetologist": 123, "Barber": 1 },
  "28358": { "Cosmetologist": 207, "Barber": 10 },
  "28209": { "Cosmetologist": 123 },
  "27317": { "Cosmetologist": 121 },
  "27540": { "Cosmetologist": 206, "Barber": 2 },
  "27703": { "Cosmetologist": 415, "Barber": 4 },
  "28273": { "Cosmetologist": 454, "Barber": 1 },
  "27265": { "Cosmetologist": 363, "Barber": 4 },
  "28721": { "Cosmetologist": 89 },
  "28337": { "Cosmetologist": 55, "Barber": 1 },
  "27260": { "Cosmetologist": 143, "Barber": 3 },
  "27127": { "Cosmetologist": 268, "Barber": 6 },
  "27712": { "Cosmetologist": 101, "Barber": 1 },
  "27520": { "Cosmetologist": 350, "Barber": 2 },
  "28387": { "Cosmetologist": 98, "Barber": 1 },
  "28401": { "Cosmetologist": 209, "Barber": 4 },
  "28778": { "Cosmetologist": 45 },
  "27406": { "Cosmetologist": 487, "Barber": 6 },
  "36362": { "Cosmetologist": 1 },
  "28753": { "Cosmetologist": 62 },
  "27921": { "Cosmetologist": 36 },
  "27537": { "Cosmetologist": 117, "Barber": 1 },
  "28086": { "Cosmetologist": 185 },
  "28138": { "Cosmetologist": 90, "Barber": 1 },
  "27893": { "Cosmetologist": 192, "Barber": 2 },
  "28515": { "Cosmetologist": 16, "Barber": 1 },
  "28278": { "Cosmetologist": 229 },
  "27707": { "Cosmetologist": 247, "Barber": 2 },
  "27601": { "Cosmetologist": 58 },
  "27713": { "Cosmetologist": 301, "Barber": 3 },
  "19104": { "Cosmetologist": 2 },
  "28104": { "Cosmetologist": 187, "Barber": 2 },
  "29479": { "Cosmetologist": 2 },
  "27615": { "Cosmetologist": 349, "Barber": 1 },
  "28613": { "Cosmetologist": 167, "Barber": 2 },
  "28716": { "Cosmetologist": 119, "Barber": 1 },
  "28139": { "Cosmetologist": 101 },
  "28602": { "Cosmetologist": 193, "Barber": 2 },
  "28557": { "Cosmetologist": 127 },
  "30015": { "Cosmetologist": 1 },
  "28056": { "Cosmetologist": 243, "Barber": 1 },
  "28152": { "Cosmetologist": 148, "Barber": 2 },
  "28107": { "Cosmetologist": 68 },
  "27514": { "Cosmetologist": 66, "Barber": 1 },
  "28612": { "Cosmetologist": 67, "Barber": 2 },
  "28081": { "Cosmetologist": 197, "Barber": 1 },
  "28146": { "Cosmetologist": 212, "Barber": 1 },
  "28147": { "Cosmetologist": 165 },
  "28164": { "Cosmetologist": 115 },
  "27892": { "Cosmetologist": 97 },
  "28080": { "Cosmetologist": 56 },
  "28677": { "Cosmetologist": 244, "Barber": 3 },
  "27705": { "Cosmetologist": 191, "Barber": 2 },
  "29349": { "Cosmetologist": 18 },
  "28467": { "Cosmetologist": 63 },
  "27409": { "Cosmetologist": 111, "Barber": 2 },
  "23669": { "Cosmetologist": 4 },
  "28217": { "Cosmetologist": 207, "Barber": 2 },
  "29720": { "Cosmetologist": 114 },
  "27292": { "Cosmetologist": 209, "Barber": 3 },
  "27852": { "Cosmetologist": 9 },
  "28650": { "Cosmetologist": 100 },
  "28124": { "Cosmetologist": 57 },
  "27106": { "Cosmetologist": 191, "Barber": 2 },
  "28054": { "Cosmetologist": 269, "Barber": 2 },
  "28052": { "Cosmetologist": 192, "Barber": 1 },
  "27282": { "Cosmetologist": 138, "Barber": 1 },
  "77573": { "Cosmetologist": 705, "Barber": 32 },
  "28144": { "Cosmetologist": 126 },
  "14882": { "Cosmetologist": 1 },
  "28625": { "Cosmetologist": 253, "Barber": 2 },
  "28635": { "Cosmetologist": 17 },
  "27045": { "Cosmetologist": 65 },
  "28697": { "Cosmetologist": 110 },
  "27858": { "Cosmetologist": 288, "Barber": 5 },
  "28453": { "Cosmetologist": 23, "Barber": 1 },
  "28112": { "Cosmetologist": 195, "Barber": 1 },
  "28166": { "Cosmetologist": 90, "Barber": 1 },
  "27810": { "Cosmetologist": 22 },
  "28204": { "Cosmetologist": 67, "Barber": 1 },
  "27834": { "Cosmetologist": 294, "Barber": 7 },
  "28504": { "Cosmetologist": 132, "Barber": 1 },
  "27518": { "Cosmetologist": 158 },
  "27577": { "Cosmetologist": 154 },
  "27312": { "Cosmetologist": 108 },
  "27614": { "Cosmetologist": 140 },
  "10509": { "Cosmetologist": 5 },
  "28480": { "Cosmetologist": 12 },
  "27504": { "Cosmetologist": 158, "Barber": 1 },
  "28590": { "Cosmetologist": 218, "Barber": 3 },
  "27203": { "Cosmetologist": 137 },
  "28307": { "Cosmetologist": 45 },
  "28212": { "Cosmetologist": 267, "Barber": 4 },
  "28379": { "Cosmetologist": 120, "Barber": 3 },
  "27330": { "Cosmetologist": 256, "Barber": 2 },
  "27101": { "Cosmetologist": 117, "Barber": 2 },
  "28205": { "Cosmetologist": 406, "Barber": 2 },
  "27284": { "Cosmetologist": 399, "Barber": 1 },
  "28508": { "Cosmetologist": 11 },
  "27501": { "Cosmetologist": 166, "Barber": 1 },
  "27205": { "Cosmetologist": 223 },
  "28816": { "Cosmetologist": 4 },
  "28604": { "Cosmetologist": 49 },
  "28012": { "Cosmetologist": 169, "Barber": 3 },
  "28712": { "Cosmetologist": 89 },
  "28323": { "Cosmetologist": 24 },
  "29020": { "Cosmetologist": 3 },
  "23322": { "Cosmetologist": 15 },
  "28610": { "Cosmetologist": 63, "Barber": 1 },
  "27012": { "Cosmetologist": 186, "Barber": 2 },
  "28611": { "Cosmetologist": 4 },
  "28034": { "Cosmetologist": 132, "Barber": 5 },
  "28334": { "Cosmetologist": 176, "Barber": 4 },
  "27830": { "Cosmetologist": 23, "Barber": 1 },
  "27249": { "Cosmetologist": 94, "Barber": 2 },
  "22785": { "Cosmetologist": 1 },
  "28073": { "Cosmetologist": 36 },
  "27020": { "Cosmetologist": 34 },
  "27021": { "Cosmetologist": 138, "Barber": 1 },
  "28352": { "Cosmetologist": 103, "Barber": 1 },
  "28645": { "Cosmetologist": 243, "Barber": 3 },
  "27023": { "Cosmetologist": 69 },
  "27546": { "Cosmetologist": 139, "Barber": 19 },
  "28097": { "Cosmetologist": 77 },
  "27954": { "Cosmetologist": 34 },
  "28752": { "Cosmetologist": 153, "Barber": 1 },
  "28364": { "Cosmetologist": 58, "Barber": 2 },
  "28544": { "Cosmetologist": 41, "Barber": 1 },
  "29576": { "Cosmetologist": 23 },
  "28659": { "Cosmetologist": 136 },
  "28469": { "Cosmetologist": 56 },
  "27315": { "Cosmetologist": 7 },
  "28301": { "Cosmetologist": 104, "Barber": 5 },
  "28377": { "Cosmetologist": 59, "Barber": 1 },
  "28771": { "Cosmetologist": 37 },
  "27803": { "Cosmetologist": 95, "Barber": 2 },
  "28457": { "Cosmetologist": 69 },
  "28382": { "Cosmetologist": 50 },
  "28145": { "Cosmetologist": 5 },
  "27332": { "Cosmetologist": 197, "Barber": 7 },
  "27576": { "Cosmetologist": 110 },
  "28687": { "Cosmetologist": 7 },
  "27884": { "Cosmetologist": 10 },
  "13207": { "Cosmetologist": 2 },
  "27360": { "Cosmetologist": 272, "Barber": 1 },
  "28395": { "Cosmetologist": 20 },
  "27052": { "Cosmetologist": 57 },
  "27896": { "Cosmetologist": 144, "Barber": 3 },
  "27104": { "Cosmetologist": 145, "Barber": 2 },
  "27055": { "Cosmetologist": 84, "Barber": 1 },
  "27597": { "Cosmetologist": 169, "Barber": 1 },
  "27429": { "Cosmetologist": 1 },
  "28501": { "Cosmetologist": 92 },
  "15236": { "Cosmetologist": 9, "Barber": 3 },
  "28120": { "Cosmetologist": 163 },
  "28023": { "Cosmetologist": 140 },
  "28742": { "Cosmetologist": 19 },
  "27551": { "Cosmetologist": 14 },
  "28340": { "Cosmetologist": 49, "Barber": 3 },
  "29718": { "Cosmetologist": 15 },
  "27523": { "Cosmetologist": 66 },
  "75216": { "Cosmetologist": 252, "Barber": 102 },
  "27513": { "Cosmetologist": 201, "Barber": 1 },
  "27235": { "Cosmetologist": 26 },
  "27408": { "Cosmetologist": 158, "Barber": 2 },
  "28601": { "Cosmetologist": 369, "Barber": 5 },
  "37601": { "Cosmetologist": 9, "Barber": 25 },
  "28083": { "Cosmetologist": 206 },
  "27545": { "Cosmetologist": 247 },
  "27025": { "Cosmetologist": 60 },
  "27856": { "Cosmetologist": 116, "Barber": 1 },
  "27569": { "Cosmetologist": 55 },
  "27581": { "Cosmetologist": 35 },
  "28168": { "Cosmetologist": 80 },
  "28574": { "Cosmetologist": 135, "Barber": 3 },
  "27410": { "Cosmetologist": 255, "Barber": 2 },
  "28224": { "Cosmetologist": 4 },
  "27006": { "Cosmetologist": 95 },
  "23223": { "Cosmetologist": 6 },
  "29568": { "Cosmetologist": 20 },
  "27263": { "Cosmetologist": 190, "Barber": 3 },
  "27105": { "Cosmetologist": 191, "Barber": 4 },
  "27253": { "Cosmetologist": 248, "Barber": 1 },
  "28365": { "Cosmetologist": 86, "Barber": 1 },
  "27229": { "Cosmetologist": 21 },
  "27886": { "Cosmetologist": 95, "Barber": 3 },
  "27215": { "Cosmetologist": 310, "Barber": 2 },
  "27591": { "Cosmetologist": 155 },
  "27358": { "Cosmetologist": 92 },
  "27217": { "Cosmetologist": 238, "Barber": 1 },
  "27370": { "Cosmetologist": 111 },
  "27704": { "Cosmetologist": 256, "Barber": 4 },
  "28339": { "Cosmetologist": 75 },
  "28792": { "Cosmetologist": 239, "Barber": 3 },
  "27298": { "Cosmetologist": 64 },
  "27313": { "Cosmetologist": 58 },
  "28328": { "Cosmetologist": 174, "Barber": 7 },
  "28472": { "Cosmetologist": 131, "Barber": 3 },
  "28226": { "Cosmetologist": 238, "Barber": 1 },
  "28705": { "Cosmetologist": 35 },
  "27617": { "Cosmetologist": 121, "Barber": 1 },
  "28207": { "Cosmetologist": 26 },
  "28412": { "Cosmetologist": 319, "Barber": 1 },
  "28174": { "Cosmetologist": 56 },
  "27539": { "Cosmetologist": 95, "Barber": 1 },
  "27051": { "Cosmetologist": 62, "Barber": 1 },
  "27054": { "Cosmetologist": 20 },
  "24014": { "Cosmetologist": 4 },
  "28429": { "Cosmetologist": 68 },
  "27019": { "Cosmetologist": 30 },
  "27350": { "Cosmetologist": 49 },
  "28203": { "Cosmetologist": 117 },
  "28562": { "Cosmetologist": 253, "Barber": 6 },
  "27592": { "Cosmetologist": 114 },
  "29707": { "Cosmetologist": 125 },
  "60611": { "Cosmetologist": 17 },
  "27542": { "Cosmetologist": 56 },
  "11215": { "Cosmetologist": 5 },
  "28443": { "Cosmetologist": 174 },
  "27889": { "Cosmetologist": 177, "Barber": 4 },
  "28711": { "Cosmetologist": 56 },
  "27541": { "Cosmetologist": 27 },
  "28675": { "Cosmetologist": 27, "Barber": 2 },
  "28461": { "Cosmetologist": 141, "Barber": 2 },
  "28001": { "Cosmetologist": 195, "Barber": 1 },
  "27207": { "Cosmetologist": 28 },
  "12590": { "Cosmetologist": 6 },
  "28386": { "Cosmetologist": 38 },
  "29527": { "Cosmetologist": 17 },
  "28329": { "Cosmetologist": 11 },
  "24112": { "Cosmetologist": 18 },
  "27310": { "Cosmetologist": 42, "Barber": 1 },
  "28751": { "Cosmetologist": 26 },
  "27311": { "Cosmetologist": 11 },
  "28262": { "Cosmetologist": 354, "Barber": 12 },
  "27536": { "Cosmetologist": 86 },
  "28021": { "Cosmetologist": 117, "Barber": 1 },
  "28732": { "Cosmetologist": 123 },
  "27401": { "Cosmetologist": 117, "Barber": 1 },
  "28075": { "Cosmetologist": 143, "Barber": 2 },
  "27544": { "Cosmetologist": 31 },
  "53216": { "Cosmetologist": 2 },
  "48239": { "Cosmetologist": 207, "Barber": 40 },
  "24019": { "Cosmetologist": 10 },
  "28673": { "Cosmetologist": 56 },
  "28394": { "Cosmetologist": 31 },
  "28016": { "Cosmetologist": 89, "Barber": 1 },
  "28371": { "Cosmetologist": 38, "Barber": 2 },
  "28135": { "Cosmetologist": 33, "Barber": 1 },
  "28787": { "Cosmetologist": 169, "Barber": 3 },
  "19803": { "Cosmetologist": 5 },
  "28430": { "Cosmetologist": 12 },
  "27606": { "Cosmetologist": 186, "Barber": 2 },
  "28411": { "Cosmetologist": 302, "Barber": 1 },
  "27574": { "Cosmetologist": 75, "Barber": 2 },
  "98597": { "Cosmetologist": 3 },
  "28513": { "Cosmetologist": 78 },
  "28009": { "Cosmetologist": 9 },
  "28018": { "Cosmetologist": 33 },
  "28713": { "Cosmetologist": 46 },
  "28425": { "Cosmetologist": 56 },
  "28436": { "Cosmetologist": 24 },
  "28526": { "Cosmetologist": 17 },
  "27288": { "Cosmetologist": 137, "Barber": 5 },
  "28731": { "Cosmetologist": 53 },
  "29506": { "Cosmetologist": 6 },
  "27524": { "Cosmetologist": 83 },
  "28345": { "Cosmetologist": 50 },
  "28739": { "Cosmetologist": 121 },
  "28637": { "Cosmetologist": 14 },
  "27278": { "Cosmetologist": 120, "Barber": 1 },
  "90065": { "Cosmetologist": 2 },
  "27301": { "Cosmetologist": 87, "Barber": 1 },
  "27302": { "Cosmetologist": 220, "Barber": 1 },
  "27557": { "Cosmetologist": 52 },
  "28114": { "Cosmetologist": 45 },
  "28129": { "Cosmetologist": 60 },
  "68133": { "Cosmetologist": 2 },
  "28373": { "Cosmetologist": 24 },
  "27962": { "Cosmetologist": 40, "Barber": 1 },
  "28376": { "Cosmetologist": 287, "Barber": 9 },
  "27870": { "Cosmetologist": 163, "Barber": 2 },
  "27801": { "Cosmetologist": 104, "Barber": 1 },
  "28772": { "Cosmetologist": 13 },
  "27349": { "Cosmetologist": 39 },
  "27356": { "Cosmetologist": 24 },
  "29575": { "Cosmetologist": 14 },
  "28690": { "Cosmetologist": 62 },
  "25209": { "Cosmetologist": 1 },
  "27379": { "Cosmetologist": 21 },
  "29745": { "Cosmetologist": 75 },
  "28525": { "Cosmetologist": 20 },
  "27936": { "Cosmetologist": 4 },
  "28638": { "Cosmetologist": 91, "Barber": 2 },
  "28117": { "Cosmetologist": 351, "Barber": 1 },
  "28766": { "Cosmetologist": 14 },
  "28463": { "Cosmetologist": 45 },
  "27208": { "Cosmetologist": 13 },
  "28036": { "Cosmetologist": 75, "Barber": 1 },
  "28729": { "Cosmetologist": 24 },
  "28551": { "Cosmetologist": 74, "Barber": 2 },
  "28768": { "Cosmetologist": 43 },
  "40165": { "Cosmetologist": 3 },
  "28202": { "Cosmetologist": 79 },
  "28088": { "Cosmetologist": 26 },
  "27283": { "Cosmetologist": 33 },
  "27325": { "Cosmetologist": 36 },
  "27341": { "Cosmetologist": 40 },
  "27116": { "Cosmetologist": 3 },
  "23842": { "Cosmetologist": 1 },
  "28366": { "Cosmetologist": 48 },
  "27214": { "Cosmetologist": 76, "Barber": 1 },
  "28127": { "Cosmetologist": 45 },
  "28128": { "Cosmetologist": 39, "Barber": 2 },
  "28163": { "Cosmetologist": 49 },
  "28315": { "Cosmetologist": 112 },
  "27041": { "Cosmetologist": 60 },
  "28449": { "Cosmetologist": 9 },
  "28333": { "Cosmetologist": 53, "Barber": 2 },
  "27702": { "Cosmetologist": 6 },
  "27403": { "Cosmetologist": 99, "Barber": 1 },
  "27262": { "Cosmetologist": 129, "Barber": 3 },
  "27563": { "Cosmetologist": 26 },
  "27589": { "Cosmetologist": 30, "Barber": 1 },
  "28573": { "Cosmetologist": 14 },
  "28344": { "Cosmetologist": 26 },
  "28532": { "Cosmetologist": 163, "Barber": 6 },
  "27258": { "Cosmetologist": 47, "Barber": 2 },
  "90008": { "Cosmetologist": 3 },
  "94544": { "Cosmetologist": 2 },
  "27701": { "Cosmetologist": 117, "Barber": 1 },
  "28578": { "Cosmetologist": 37 },
  "28580": { "Cosmetologist": 61, "Barber": 2 },
  "29681": { "Cosmetologist": 24 },
  "27953": { "Cosmetologist": 7 },
  "27942": { "Cosmetologist": 6 },
  "27949": { "Cosmetologist": 53, "Barber": 1 },
  "28617": { "Cosmetologist": 9 },
  "27882": { "Cosmetologist": 41 },
  "43031": { "Cosmetologist": 2 },
  "27316": { "Cosmetologist": 40 },
  "28167": { "Cosmetologist": 10 },
  "28041": { "Cosmetologist": 7 },
  "27027": { "Cosmetologist": 17 },
  "45387": { "Cosmetologist": 1 },
  "94901": { "Cosmetologist": 4 },
  "28804": { "Cosmetologist": 109 },
  "29588": { "Cosmetologist": 23 },
  "27326": { "Cosmetologist": 23 },
  "27910": { "Cosmetologist": 67 },
  "28901": { "Cosmetologist": 39 },
  "28805": { "Cosmetologist": 83, "Barber": 1 },
  "27503": { "Cosmetologist": 25 },
  "28709": { "Cosmetologist": 17 },
  "28606": { "Cosmetologist": 14 },
  "28327": { "Cosmetologist": 99, "Barber": 1 },
  "28206": { "Cosmetologist": 85, "Barber": 4 },
  "28017": { "Cosmetologist": 5 },
  "28621": { "Cosmetologist": 64, "Barber": 1 },
  "28439": { "Cosmetologist": 8 },
  "27832": { "Cosmetologist": 13 },
  "76099": { "Cosmetologist": 1 },
  "37763": { "Cosmetologist": 2, "Barber": 3 },
  "28502": { "Cosmetologist": 2 },
  "28746": { "Cosmetologist": 12 },
  "28450": { "Cosmetologist": 15 },
  "29569": { "Cosmetologist": 15 },
  "48858": { "Cosmetologist": 233, "Barber": 16 },
  "23803": { "Cosmetologist": 4 },
  "27863": { "Cosmetologist": 72 },
  "77365": { "Cosmetologist": 386, "Barber": 23 },
  "27572": { "Cosmetologist": 40 },
  "28689": { "Cosmetologist": 11 },
  "28694": { "Cosmetologist": 45 },
  "28403": { "Cosmetologist": 301, "Barber": 3 },
  "28040": { "Cosmetologist": 43 },
  "78610": { "Cosmetologist": 378, "Barber": 37 },
  "28271": { "Cosmetologist": 2 },
  "27244": { "Cosmetologist": 63 },
  "27864": { "Cosmetologist": 27 },
  "28585": { "Cosmetologist": 17, "Barber": 2 },
  "35984": { "Cosmetologist": 1 },
  "28470": { "Cosmetologist": 80 },
  "27950": { "Cosmetologist": 4 },
  "27013": { "Cosmetologist": 52 },
  "28714": { "Cosmetologist": 104, "Barber": 1 },
  "28634": { "Cosmetologist": 24 },
  "27209": { "Cosmetologist": 25 },
  "28642": { "Cosmetologist": 32 },
  "29853": { "Cosmetologist": 2 },
  "28134": { "Cosmetologist": 91 },
  "27018": { "Cosmetologist": 49, "Barber": 1 },
  "12569": { "Cosmetologist": 1 },
  "22554": { "Cosmetologist": 6 },
  "27030": { "Cosmetologist": 225, "Barber": 2 },
  "95037": { "Cosmetologist": 2 },
  "28741": { "Cosmetologist": 27 },
  "16335": { "Cosmetologist": 42, "Barber": 2 },
  "27573": { "Cosmetologist": 82, "Barber": 1 },
  "49945": { "Cosmetologist": 16 },
  "28445": { "Cosmetologist": 76 },
  "20772": { "Cosmetologist": 10 },
  "27040": { "Cosmetologist": 68 },
  "70003": { "Cosmetologist": 2 },
  "28479": { "Cosmetologist": 37 },
  "27522": { "Cosmetologist": 88, "Barber": 1 },
  "28607": { "Cosmetologist": 123 },
  "28422": { "Cosmetologist": 57 },
  "27932": { "Cosmetologist": 49 },
  "28560": { "Cosmetologist": 178, "Barber": 2 },
  "27878": { "Cosmetologist": 9 },
  "29180": { "Cosmetologist": 5 },
  "29536": { "Cosmetologist": 4, "Barber": 1 },
  "28692": { "Cosmetologist": 23 },
  "37683": { "Cosmetologist": 5, "Barber": 5 },
  "27874": { "Cosmetologist": 10 },
  "11237": { "Cosmetologist": 6 },
  "27846": { "Cosmetologist": 16 },
  "28510": { "Cosmetologist": 8 },
  "27823": { "Cosmetologist": 27, "Barber": 1 },
  "27839": { "Cosmetologist": 12 },
  "19046": { "Cosmetologist": 4, "Barber": 1 },
  "27510": { "Cosmetologist": 40 },
  "29341": { "Cosmetologist": 16 },
  "28516": { "Cosmetologist": 63 },
  "27805": { "Cosmetologist": 14, "Barber": 1 },
  "27847": { "Cosmetologist": 5 },
  "27890": { "Cosmetologist": 5 },
  "90815": { "Cosmetologist": 1 },
  "28466": { "Cosmetologist": 57, "Barber": 1 },
  "29644": { "Cosmetologist": 5 },
  "28002": { "Cosmetologist": 4 },
  "98409": { "Cosmetologist": 2 },
  "26326": { "Cosmetologist": 1 },
  "27986": { "Cosmetologist": 6 },
  "28669": { "Cosmetologist": 23 },
  "29563": { "Cosmetologist": 3 },
  "27935": { "Cosmetologist": 10 },
  "27865": { "Cosmetologist": 17 },
  "27983": { "Cosmetologist": 25 },
  "27929": { "Cosmetologist": 11 },
  "27007": { "Cosmetologist": 9 },
  "27419": { "Cosmetologist": 8 },
  "28101": { "Cosmetologist": 2 },
  "77489": { "Cosmetologist": 286, "Barber": 90 },
  "36527": { "Cosmetologist": 11 },
  "27017": { "Cosmetologist": 49, "Barber": 1 },
  "27822": { "Cosmetologist": 55 },
  "28438": { "Cosmetologist": 8 },
  "28572": { "Cosmetologist": 51, "Barber": 1 },
  "28609": { "Cosmetologist": 49, "Barber": 1 },
  "27050": { "Cosmetologist": 23 },
  "11228": { "Cosmetologist": 2 },
  "28763": { "Cosmetologist": 15 },
  "29203": { "Cosmetologist": 14 },
  "29706": { "Cosmetologist": 14 },
  "37726": { "Cosmetologist": 2 },
  "27248": { "Cosmetologist": 20 },
  "28103": { "Cosmetologist": 85 },
  "28759": { "Cosmetologist": 48 },
  "28657": { "Cosmetologist": 62 },
  "27361": { "Cosmetologist": 4 },
  "28318": { "Cosmetologist": 26 },
  "28391": { "Cosmetologist": 41, "Barber": 2 },
  "28399": { "Cosmetologist": 11 },
  "71291": { "Cosmetologist": 3 },
  "12209": { "Cosmetologist": 2 },
  "27565": { "Cosmetologist": 153, "Barber": 1 },
  "28678": { "Cosmetologist": 24 },
  "70607": { "Cosmetologist": 1 },
  "28458": { "Cosmetologist": 28 },
  "10309": { "Cosmetologist": 4 },
  "27371": { "Cosmetologist": 52 },
  "15132": { "Cosmetologist": 2, "Barber": 2 },
  "28676": { "Cosmetologist": 22 },
  "28672": { "Cosmetologist": 2 },
  "28582": { "Cosmetologist": 10 },
  "28762": { "Cosmetologist": 37 },
  "27820": { "Cosmetologist": 15 },
  "28452": { "Cosmetologist": 4 },
  "45714": { "Cosmetologist": 1 },
  "40743": { "Cosmetologist": 1 },
  "28160": { "Cosmetologist": 19 },
  "22309": { "Cosmetologist": 3 },
  "28119": { "Cosmetologist": 13 },
  "94102": { "Cosmetologist": 1 },
  "28790": { "Cosmetologist": 22 },
  "28454": { "Cosmetologist": 13 },
  "20206": { "Cosmetologist": 1 },
  "28730": { "Cosmetologist": 54 },
  "23701": { "Cosmetologist": 6 },
  "28701": { "Cosmetologist": 33 },
  "28813": { "Cosmetologist": 4 },
  "29512": { "Cosmetologist": 15 },
  "27817": { "Cosmetologist": 39 },
  "41014": { "Cosmetologist": 1 },
  "29714": { "Cosmetologist": 6 },
  "27850": { "Cosmetologist": 30 },
  "28359": { "Cosmetologist": 13 },
  "29108": { "Cosmetologist": 2 },
  "36477": { "Cosmetologist": 2 },
  "23456": { "Cosmetologist": 22 },
  "28170": { "Cosmetologist": 71, "Barber": 2 },
  "29641": { "Cosmetologist": 1 },
  "28465": { "Cosmetologist": 53, "Barber": 1 },
  "27011": { "Cosmetologist": 30 },
  "92630": { "Cosmetologist": 5 },
  "11238": { "Cosmetologist": 3 },
  "27829": { "Cosmetologist": 8 },
  "28455": { "Cosmetologist": 8, "Barber": 1 },
  "28133": { "Cosmetologist": 16, "Barber": 1 },
  "27568": { "Cosmetologist": 9 },
  "28383": { "Cosmetologist": 27, "Barber": 4 },
  "28683": { "Cosmetologist": 20 },
  "27216": { "Cosmetologist": 5 },
  "28547": { "Cosmetologist": 32 },
  "28521": { "Cosmetologist": 13 },
  "28033": { "Cosmetologist": 21 },
  "28643": { "Cosmetologist": 17 },
  "28615": { "Cosmetologist": 6 },
  "28693": { "Cosmetologist": 6 },
  "37148": { "Cosmetologist": 4, "Barber": 15 },
  "23703": { "Cosmetologist": 10 },
  "24740": { "Cosmetologist": 3 },
  "27787": { "Cosmetologist": 1 },
  "27965": { "Cosmetologist": 4 },
  "28518": { "Cosmetologist": 56 },
  "28098": { "Cosmetologist": 27 },
  "23238": { "Cosmetologist": 3 },
  "28108": { "Cosmetologist": 8 },
  "21842": { "Cosmetologist": 5 },
  "16053": { "Cosmetologist": 8 },
  "94803": { "Cosmetologist": 1 },
  "28464": { "Cosmetologist": 25 },
  "28398": { "Cosmetologist": 39, "Barber": 4 },
  "60651": { "Cosmetologist": 2 },
  "28586": { "Cosmetologist": 36, "Barber": 2 },
  "27224": { "Cosmetologist": 1 },
  "94541": { "Cosmetologist": 3 },
  "20616": { "Cosmetologist": 2 },
  "30091": { "Cosmetologist": 1 },
  "28137": { "Cosmetologist": 24, "Barber": 1 },
  "28416": { "Cosmetologist": 1 },
  "27851": { "Cosmetologist": 34 },
  "36533": { "Cosmetologist": 2 },
  "27973": { "Cosmetologist": 7 },
  "96701": { "Cosmetologist": 2 },
  "28663": { "Cosmetologist": 6 },
  "28335": { "Cosmetologist": 2 },
  "28385": { "Cosmetologist": 18 },
  "28369": { "Cosmetologist": 13 },
  "28629": { "Cosmetologist": 2 },
  "28631": { "Cosmetologist": 2 },
  "74855": { "Cosmetologist": 1 },
  "27855": { "Cosmetologist": 16 },
  "28671": { "Cosmetologist": 6 },
  "28660": { "Cosmetologist": 17 },
  "96301": { "Cosmetologist": 1 },
  "29611": { "Cosmetologist": 13 },
  "27515": { "Cosmetologist": 3 },
  "28229": { "Cosmetologist": 4 },
  "30525": { "Cosmetologist": 7 },
  "92223": { "Cosmetologist": 2 },
  "28723": { "Cosmetologist": 24 },
  "28529": { "Cosmetologist": 20 },
  "28530": { "Cosmetologist": 41, "Barber": 1 },
  "41049": { "Cosmetologist": 1 },
  "27948": { "Cosmetologist": 77 },
  "28360": { "Cosmetologist": 58, "Barber": 4 },
  "27883": { "Cosmetologist": 25, "Barber": 2 },
  "28681": { "Cosmetologist": 180, "Barber": 1 },
  "19090": { "Cosmetologist": 1, "Barber": 4 },
  "91106": { "Cosmetologist": 2 },
  "27873": { "Cosmetologist": 6 },
  "45225": { "Cosmetologist": 2 },
  "37721": { "Cosmetologist": 2, "Barber": 8 },
  "28740": { "Cosmetologist": 12 },
  "24503": { "Cosmetologist": 5 },
  "27608": { "Cosmetologist": 27 },
  "28575": { "Cosmetologist": 3 },
  "27344": { "Cosmetologist": 68, "Barber": 1 },
  "28555": { "Cosmetologist": 36, "Barber": 1 },
  "29572": { "Cosmetologist": 19 },
  "28423": { "Cosmetologist": 14 },
  "28432": { "Cosmetologist": 17, "Barber": 1 },
  "28320": { "Cosmetologist": 41 },
  "28256": { "Cosmetologist": 5 },
  "28053": { "Cosmetologist": 5 },
  "27252": { "Cosmetologist": 15, "Barber": 1 },
  "27285": { "Cosmetologist": 5 },
  "27845": { "Cosmetologist": 14 },
  "98581": { "Cosmetologist": 1 },
  "77090": { "Cosmetologist": 237, "Barber": 49 },
  "28761": { "Cosmetologist": 54 },
  "28651": { "Cosmetologist": 27 },
  "27970": { "Cosmetologist": 11 },
  "22025": { "Cosmetologist": 3 },
  "28341": { "Cosmetologist": 20 },
  "28528": { "Cosmetologist": 5 },
  "28754": { "Cosmetologist": 53, "Barber": 1 },
  "12831": { "Cosmetologist": 4 },
  "28072": { "Cosmetologist": 3 },
  "27925": { "Cosmetologist": 25 },
  "27891": { "Cosmetologist": 30 },
  "27869": { "Cosmetologist": 5 },
  "27562": { "Cosmetologist": 17 },
  "28523": { "Cosmetologist": 14 },
  "27806": { "Cosmetologist": 8 },
  "27620": { "Cosmetologist": 11 },
  "33621": { "Cosmetologist": 14 },
  "28384": { "Cosmetologist": 68 },
  "18069": { "Cosmetologist": 6, "Barber": 2 },
  "28791": { "Cosmetologist": 79 },
  "28032": { "Cosmetologist": 29 },
  "28728": { "Cosmetologist": 9 },
  "27583": { "Cosmetologist": 52 },
  "27048": { "Cosmetologist": 50 },
  "20748": { "Cosmetologist": 2 },
  "28512": { "Cosmetologist": 13 },
  "27825": { "Cosmetologist": 1 },
  "23430": { "Cosmetologist": 3 },
  "27811": { "Cosmetologist": 2 },
  "17543": { "Cosmetologist": 13, "Barber": 1 },
  "29323": { "Cosmetologist": 9 },
  "28151": { "Cosmetologist": 7 },
  "28777": { "Cosmetologist": 50, "Barber": 1 },
  "28456": { "Cosmetologist": 22 },
  "27239": { "Cosmetologist": 62 },
  "29302": { "Cosmetologist": 11 },
  "28905": { "Cosmetologist": 22 },
  "29703": { "Cosmetologist": 1 },
  "27506": { "Cosmetologist": 4 },
  "27528": { "Cosmetologist": 5 },
  "28357": { "Cosmetologist": 14 },
  "28006": { "Cosmetologist": 10 },
  "28090": { "Cosmetologist": 58, "Barber": 1 },
  "24701": { "Cosmetologist": 6 },
  "28744": { "Cosmetologist": 8 },
  "10026": { "Cosmetologist": 2 },
  "27043": { "Cosmetologist": 43, "Barber": 1 },
  "48877": { "Cosmetologist": 18, "Barber": 2 },
  "27374": { "Cosmetologist": 3 },
  "78015": { "Cosmetologist": 134 },
  "27299": { "Cosmetologist": 31 },
  "17225": { "Cosmetologist": 13 },
  "16510": { "Cosmetologist": 9 },
  "28623": { "Cosmetologist": 6 },
  "11222": { "Cosmetologist": 6 },
  "23224": { "Cosmetologist": 4 },
  "17702": { "Cosmetologist": 13, "Barber": 2 },
  "96778": { "Cosmetologist": 1 },
  "15223": { "Cosmetologist": 1, "Barber": 3 },
  "27924": { "Cosmetologist": 13 },
  "99623": { "Cosmetologist": 1 },
  "29211": { "Cosmetologist": 3 },
  "28782": { "Cosmetologist": 33 },
  "28459": { "Cosmetologist": 10 },
  "19406": { "Cosmetologist": 6, "Barber": 1 },
  "27828": { "Cosmetologist": 49 },
  "28587": { "Cosmetologist": 2 },
  "42044": { "Cosmetologist": 1 },
  "28649": { "Cosmetologist": 5 },
  "27816": { "Cosmetologist": 12 },
  "24351": { "Cosmetologist": 3 },
  "15219": { "Cosmetologist": 4, "Barber": 2 },
  "20024": { "Cosmetologist": 4 },
  "27871": { "Cosmetologist": 22 },
  "27009": { "Cosmetologist": 22 },
  "24715": { "Cosmetologist": 1 },
  "37743": { "Cosmetologist": 3, "Barber": 13 },
  "27837": { "Cosmetologist": 41 },
  "29061": { "Cosmetologist": 3 },
  "70508": { "Cosmetologist": 4 },
  "27559": { "Cosmetologist": 16 },
  "28125": { "Cosmetologist": 43 },
  "27306": { "Cosmetologist": 34 },
  "27818": { "Cosmetologist": 1 },
  "27650": { "Cosmetologist": 2 },
  "20271": { "Cosmetologist": 1 },
  "55324": { "Cosmetologist": 1 },
  "37640": { "Cosmetologist": 3, "Barber": 3 },
  "28343": { "Cosmetologist": 7, "Barber": 2 },
  "28372": { "Cosmetologist": 65, "Barber": 4 },
  "28662": { "Cosmetologist": 3 },
  "28665": { "Cosmetologist": 17 },
  "28325": { "Cosmetologist": 3 },
  "28349": { "Cosmetologist": 22 },
  "29356": { "Cosmetologist": 14 },
  "28393": { "Cosmetologist": 10 },
  "24015": { "Cosmetologist": 6 },
  "29601": { "Cosmetologist": 3 },
  "20134": { "Cosmetologist": 1 },
  "91932": { "Cosmetologist": 2 },
  "37602": { "Cosmetologist": 1, "Barber": 1 },
  "31626": { "Cosmetologist": 2 },
  "29303": { "Cosmetologist": 8 },
  "28388": { "Cosmetologist": 4 },
  "28627": { "Cosmetologist": 6 },
  "29505": { "Cosmetologist": 5 },
  "28719": { "Cosmetologist": 32 },
  "28302": { "Cosmetologist": 2 },
  "28282": { "Cosmetologist": 1 },
  "24348": { "Cosmetologist": 2 },
  "28654": { "Cosmetologist": 16 },
  "92688": { "Cosmetologist": 2 },
  "28685": { "Cosmetologist": 9 },
  "29690": { "Cosmetologist": 13 },
  "11101": { "Cosmetologist": 5 },
  "24153": { "Cosmetologist": 3 },
  "28725": { "Cosmetologist": 15 },
  "14625": { "Cosmetologist": 1 },
  "27880": { "Cosmetologist": 12 },
  "27619": { "Cosmetologist": 3 },
  "29451": { "Cosmetologist": 2 },
  "27939": { "Cosmetologist": 19 },
  "29702": { "Cosmetologist": 18 },
  "28159": { "Cosmetologist": 11 },
  "36093": { "Cosmetologist": 1 },
  "29646": { "Cosmetologist": 4 },
  "28434": { "Cosmetologist": 5 },
  "14150": { "Cosmetologist": 6 },
  "29819": { "Cosmetologist": 2 },
  "29058": { "Cosmetologist": 7 },
  "28392": { "Cosmetologist": 11 },
  "24541": { "Cosmetologist": 14, "Barber": 1 },
  "22031": { "Cosmetologist": 3 },
  "28691": { "Cosmetologist": 2 },
  "28603": { "Cosmetologist": 8 },
  "36092": { "Cosmetologist": 1 },
  "29546": { "Cosmetologist": 2 },
  "28468": { "Cosmetologist": 33 },
  "13090": { "Cosmetologist": 9 },
  "13045": { "Cosmetologist": 4 },
  "27611": { "Cosmetologist": 2 },
  "27233": { "Cosmetologist": 31 },
  "28684": { "Cosmetologist": 5 },
  "29440": { "Cosmetologist": 5 },
  "28640": { "Cosmetologist": 33 },
  "27959": { "Cosmetologist": 10 },
  "15466": { "Cosmetologist": 2, "Barber": 1 },
  "27717": { "Cosmetologist": 9 },
  "27937": { "Cosmetologist": 9 },
  "24059": { "Cosmetologist": 1 },
  "23112": { "Cosmetologist": 11 },
  "27857": { "Cosmetologist": 7 },
  "28071": { "Cosmetologist": 22 },
  "43713": { "Cosmetologist": 1 },
  "13408": { "Cosmetologist": 1 },
  "28519": { "Cosmetologist": 7 },
  "28745": { "Cosmetologist": 4 },
  "27046": { "Cosmetologist": 18 },
  "28738": { "Cosmetologist": 4 },
  "27957": { "Cosmetologist": 4 },
  "37184": { "Cosmetologist": 1, "Barber": 8 },
  "20148": { "Cosmetologist": 7 },
  "27509": { "Cosmetologist": 25 },
  "11203": { "Cosmetologist": 3 },
  "96376": { "Cosmetologist": 2 },
  "27876": { "Cosmetologist": 6 },
  "29385": { "Cosmetologist": 4 },
  "28478": { "Cosmetologist": 30 },
  "27355": { "Cosmetologist": 13 },
  "19720": { "Cosmetologist": 4 },
  "12601": { "Cosmetologist": 4 },
  "27807": { "Cosmetologist": 35, "Barber": 1 },
  "28026": { "Cosmetologist": 3 },
  "53403": { "Cosmetologist": 3 },
  "28757": { "Cosmetologist": 1 },
  "70128": { "Cosmetologist": 2 },
  "22701": { "Cosmetologist": 5 },
  "19021": { "Cosmetologist": 7, "Barber": 2 },
  "27323": { "Cosmetologist": 4 },
  "23970": { "Cosmetologist": 4 },
  "29671": { "Cosmetologist": 2 },
  "29420": { "Cosmetologist": 6 },
  "28619": { "Cosmetologist": 15 },
  "27438": { "Cosmetologist": 4 },
  "28641": { "Cosmetologist": 3 },
  "28775": { "Cosmetologist": 4 },
  "28363": { "Cosmetologist": 6 },
  "28527": { "Cosmetologist": 7 },
  "24340": { "Cosmetologist": 1 },
  "59804": { "Cosmetologist": 1 },
  "23702": { "Cosmetologist": 3 },
  "29729": { "Cosmetologist": 3 },
  "25039": { "Cosmetologist": 1 },
  "27714": { "Cosmetologist": 1 },
  "55371": { "Cosmetologist": 1 },
  "29342": { "Cosmetologist": 2 },
  "29340": { "Cosmetologist": 5 },
  "28089": { "Cosmetologist": 3 },
  "28756": { "Cosmetologist": 21 },
  "27281": { "Cosmetologist": 9 },
  "60544": { "Cosmetologist": 6 },
  "38019": { "Cosmetologist": 1, "Barber": 8 },
  "30072": { "Cosmetologist": 3 },
  "29210": { "Cosmetologist": 25 },
  "20749": { "Cosmetologist": 1 },
  "29704": { "Cosmetologist": 7 },
  "78735": { "Cosmetologist": 107, "Barber": 9 },
  "24588": { "Cosmetologist": 1 },
  "27343": { "Cosmetologist": 4 },
  "24592": { "Cosmetologist": 4 },
  "29170": { "Cosmetologist": 5 },
  "28624": { "Cosmetologist": 9 },
  "29570": { "Cosmetologist": 6 },
  "11217": { "Cosmetologist": 2 },
  "27922": { "Cosmetologist": 6, "Barber": 1 },
  "28396": { "Cosmetologist": 22 },
  "28338": { "Cosmetologist": 15 },
  "30105": { "Cosmetologist": 1 },
  "27808": { "Cosmetologist": 9 },
  "27966": { "Cosmetologist": 10 },
  "29223": { "Cosmetologist": 16 },
  "22485": { "Cosmetologist": 4 },
  "37664": { "Cosmetologist": 4, "Barber": 8 },
  "24352": { "Cosmetologist": 1 },
  "28351": { "Cosmetologist": 21 },
  "29712": { "Cosmetologist": 12 },
  "28428": { "Cosmetologist": 43 },
  "78840": { "Cosmetologist": 200, "Barber": 16 },
  "93725": { "Cosmetologist": 1 },
  "28332": { "Cosmetologist": 6 },
  "28628": { "Cosmetologist": 2 },
  "28433": { "Cosmetologist": 25 },
  "29565": { "Cosmetologist": 1 },
  "11216": { "Cosmetologist": 6 },
  "27860": { "Cosmetologist": 10 },
  "27926": { "Cosmetologist": 2 },
  "23807": { "Cosmetologist": 1 },
  "28356": { "Cosmetologist": 46 },
  "85194": { "Cosmetologist": 1 },
  "28305": { "Cosmetologist": 47 },
  "29055": { "Cosmetologist": 2 },
  "92056": { "Cosmetologist": 2 },
  "24589": { "Cosmetologist": 1 },
  "27053": { "Cosmetologist": 15 },
  "27114": { "Cosmetologist": 5 },
  "77511": { "Cosmetologist": 306, "Barber": 25 },
  "94517": { "Cosmetologist": 1 },
  "28724": { "Cosmetologist": 7 },
  "24551": { "Cosmetologist": 7 },
  "77016": { "Cosmetologist": 170, "Barber": 73 },
  "32235": { "Cosmetologist": 5 },
  "17043": { "Cosmetologist": 9, "Barber": 4 },
  "20653": { "Cosmetologist": 5 },
  "29657": { "Cosmetologist": 2 },
  "98038": { "Cosmetologist": 1 },
  "37804": { "Cosmetologist": 10, "Barber": 6 },
  "94601": { "Cosmetologist": 1 },
  "60074": { "Cosmetologist": 3 },
  "28136": { "Cosmetologist": 3 },
  "23230": { "Cosmetologist": 4 },
  "24017": { "Cosmetologist": 5 },
  "92410": { "Cosmetologist": 1 },
  "29687": { "Cosmetologist": 14 },
  "27888": { "Cosmetologist": 9, "Barber": 1 },
  "23890": { "Cosmetologist": 1 },
  "29717": { "Cosmetologist": 1 },
  "27894": { "Cosmetologist": 2 },
  "28605": { "Cosmetologist": 16 },
  "27809": { "Cosmetologist": 24 },
  "28331": { "Cosmetologist": 1 },
  "27843": { "Cosmetologist": 2 },
  "37687": { "Cosmetologist": 3, "Barber": 3 },
  "37938": { "Cosmetologist": 5, "Barber": 13 },
  "28773": { "Cosmetologist": 13 },
  "70130": { "Cosmetologist": 3 },
  "29455": { "Cosmetologist": 7 },
  "28774": { "Cosmetologist": 6 },
  "13203": { "Cosmetologist": 2 },
  "23452": { "Cosmetologist": 8 },
  "29526": { "Cosmetologist": 21 },
  "27623": { "Cosmetologist": 3 },
  "27016": { "Cosmetologist": 9 },
  "27022": { "Cosmetologist": 15 },
  "28310": { "Cosmetologist": 4 },
  "95070": { "Cosmetologist": 1 },
  "27813": { "Cosmetologist": 6 },
  "29585": { "Cosmetologist": 6 },
  "23868": { "Cosmetologist": 2 },
  "11520": { "Cosmetologist": 3 },
  "27770": { "Cosmetologist": 1 },
  "29418": { "Cosmetologist": 6 },
  "28896": { "Cosmetologist": 1 },
  "27976": { "Cosmetologist": 16 },
  "91941": { "Cosmetologist": 2, "Barber": 1 },
  "28007": { "Cosmetologist": 7, "Barber": 1 },
  "28091": { "Cosmetologist": 14, "Barber": 1 },
  "23663": { "Cosmetologist": 3 },
  "28682": { "Cosmetologist": 11 },
  "27812": { "Cosmetologist": 11 },
  "16127": { "Cosmetologist": 8, "Barber": 3 },
  "27257": { "Cosmetologist": 1 },
  "27293": { "Cosmetologist": 3 },
  "90291": { "Cosmetologist": 1 },
  "24053": { "Cosmetologist": 11 },
  "74169": { "Cosmetologist": 1 },
  "27915": { "Cosmetologist": 5 },
  "28431": { "Cosmetologist": 35 },
  "28441": { "Cosmetologist": 16 },
  "93940": { "Cosmetologist": 5 },
  "27636": { "Cosmetologist": 2 },
  "24148": { "Cosmetologist": 4 },
  "27946": { "Cosmetologist": 5 },
  "27980": { "Cosmetologist": 9 },
  "29153": { "Cosmetologist": 6 },
  "28702": { "Cosmetologist": 3 },
  "27885": { "Cosmetologist": 7 },
  "28594": { "Cosmetologist": 19 },
  "37407": { "Cosmetologist": 2, "Barber": 1 },
  "29597": { "Cosmetologist": 1 },
  "28380": { "Cosmetologist": 3, "Barber": 1 },
  "28622": { "Cosmetologist": 7 },
  "28077": { "Cosmetologist": 5 },
  "11554": { "Cosmetologist": 2 },
  "77034": { "Cosmetologist": 403, "Barber": 21 },
  "28169": { "Cosmetologist": 2 },
  "72029": { "Cosmetologist": 1 },
  "12518": { "Cosmetologist": 1 },
  "22060": { "Cosmetologist": 3 },
  "23123": { "Cosmetologist": 1 },
  "28368": { "Cosmetologist": 4 },
  "11783": { "Cosmetologist": 2 },
  "12528": { "Cosmetologist": 1 },
  "37110": { "Cosmetologist": 2, "Barber": 31 },
  "27533": { "Cosmetologist": 2 },
  "28554": { "Cosmetologist": 1, "Barber": 1 },
  "28266": { "Cosmetologist": 3 },
  "70072": { "Cosmetologist": 9 },
  "16601": { "Cosmetologist": 19, "Barber": 7 },
  "10928": { "Cosmetologist": 1 },
  "28556": { "Cosmetologist": 6 },
  "91311": { "Cosmetologist": 1 },
  "22152": { "Cosmetologist": 3 },
  "27014": { "Cosmetologist": 9 },
  "28583": { "Cosmetologist": 2 },
  "98057": { "Cosmetologist": 1 },
  "29070": { "Cosmetologist": 4 },
  "29741": { "Cosmetologist": 5 },
  "29520": { "Cosmetologist": 7 },
  "41539": { "Cosmetologist": 1 },
  "11214": { "Cosmetologist": 8 },
  "14032": { "Cosmetologist": 2 },
  "39428": { "Cosmetologist": 3 },
  "24540": { "Cosmetologist": 23 },
  "27974": { "Cosmetologist": 9 },
  "27049": { "Cosmetologist": 4 },
  "13051": { "Cosmetologist": 1 },
  "27943": { "Cosmetologist": 3 },
  "11727": { "Cosmetologist": 1 },
  "27508": { "Cosmetologist": 11 },
  "27505": { "Cosmetologist": 44 },
  "58702": { "Cosmetologist": 1 },
  "12582": { "Cosmetologist": 1 },
  "24102": { "Cosmetologist": 3 },
  "24171": { "Cosmetologist": 9 },
  "28664": { "Cosmetologist": 1 },
  "28670": { "Cosmetologist": 9 },
  "13032": { "Cosmetologist": 1 },
  "98375": { "Cosmetologist": 2 },
  "70805": { "Cosmetologist": 2 },
  "91602": { "Cosmetologist": 4 },
  "28531": { "Cosmetologist": 11 },
  "78744": { "Cosmetologist": 328, "Barber": 47 },
  "54303": { "Cosmetologist": 7 },
  "29532": { "Cosmetologist": 8 },
  "27512": { "Cosmetologist": 11 },
  "37317": { "Cosmetologist": 4 },
  "28783": { "Cosmetologist": 4 },
  "27588": { "Cosmetologist": 5 },
  "21122": { "Cosmetologist": 6 },
  "29670": { "Cosmetologist": 5 },
  "23185": { "Cosmetologist": 5 },
  "14450": { "Cosmetologist": 6 },
  "61821": { "Cosmetologist": 2 },
  "44142": { "Cosmetologist": 1 },
  "28106": { "Cosmetologist": 6 },
  "28618": { "Cosmetologist": 15 },
  "28421": { "Cosmetologist": 12 },
  "48191": { "Cosmetologist": 32, "Barber": 3 },
  "27757": { "Cosmetologist": 1 },
  "19148": { "Cosmetologist": 11 },
  "56007": { "Cosmetologist": 3 },
  "37658": { "Cosmetologist": 2, "Barber": 2 },
  "24630": { "Cosmetologist": 1 },
  "29636": { "Cosmetologist": 1 },
  "27814": { "Cosmetologist": 5 },
  "27928": { "Cosmetologist": 6 },
  "28616": { "Cosmetologist": 5 },
  "27411": { "Cosmetologist": 1 },
  "23847": { "Cosmetologist": 8 },
  "23857": { "Cosmetologist": 2 },
  "44805": { "Cosmetologist": 1 },
  "96386": { "Cosmetologist": 2 },
  "24317": { "Cosmetologist": 13 },
  "27866": { "Cosmetologist": 1 },
  "10963": { "Cosmetologist": 1 },
  "24859": { "Cosmetologist": 1 },
  "26408": { "Cosmetologist": 1 },
  "13069": { "Cosmetologist": 1 },
  "76086": { "Cosmetologist": 251, "Barber": 12 },
  "28793": { "Cosmetologist": 4 },
  "48170": { "Cosmetologist": 288, "Barber": 16 },
  "29649": { "Cosmetologist": 2 },
  "28347": { "Cosmetologist": 5 },
  "60620": { "Cosmetologist": 1 },
  "45631": { "Cosmetologist": 2 },
  "27047": { "Cosmetologist": 10 },
  "28735": { "Cosmetologist": 3 },
  "27331": { "Cosmetologist": 8 },
  "29669": { "Cosmetologist": 3 },
  "27675": { "Cosmetologist": 5 },
  "76063": { "Cosmetologist": 842, "Barber": 65 },
  "27624": { "Cosmetologist": 2 },
  "44683": { "Cosmetologist": 1 },
  "24520": { "Cosmetologist": 4 },
  "21402": { "Cosmetologist": 2 },
  "30537": { "Cosmetologist": 5 },
  "28788": { "Cosmetologist": 5 },
  "27237": { "Cosmetologist": 1 },
  "30705": { "Cosmetologist": 4 },
  "17007": { "Cosmetologist": 2, "Barber": 1 },
  "28070": { "Cosmetologist": 8 },
  "50651": { "Cosmetologist": 16, "Barber": 9 },
  "20747": { "Cosmetologist": 5 },
  "13126": { "Cosmetologist": 6 },
  "28902": { "Cosmetologist": 7 },
  "28020": { "Cosmetologist": 11 },
  "12414": { "Cosmetologist": 1 },
  "27231": { "Cosmetologist": 12 },
  "21769": { "Cosmetologist": 2 },
  "27842": { "Cosmetologist": 4 },
  "27507": { "Cosmetologist": 5 },
  "28042": { "Cosmetologist": 5 },
  "28736": { "Cosmetologist": 6 },
  "27658": { "Cosmetologist": 3 },
  "71361": { "Cosmetologist": 1 },
  "23434": { "Cosmetologist": 6 },
  "27404": { "Cosmetologist": 4 },
  "37931": { "Cosmetologist": 4, "Barber": 22 },
  "84095": { "Cosmetologist": 2 },
  "10473": { "Cosmetologist": 3 },
  "11205": { "Cosmetologist": 9 },
  "10035": { "Cosmetologist": 6, "Barber": 1 },
  "23831": { "Cosmetologist": 8 },
  "70005": { "Cosmetologist": 2 },
  "10560": { "Cosmetologist": 2 },
  "13760": { "Cosmetologist": 3 },
  "28448": { "Cosmetologist": 6 },
  "28330": { "Cosmetologist": 1 },
  "55418": { "Cosmetologist": 1 },
  "20856": { "Cosmetologist": 1 },
  "27291": { "Cosmetologist": 9 },
  "58501": { "Cosmetologist": 1 },
  "23139": { "Cosmetologist": 4 },
  "28402": { "Cosmetologist": 3 },
  "27831": { "Cosmetologist": 10 },
  "24526": { "Cosmetologist": 1 },
  "97215": { "Cosmetologist": 2 },
  "18055": { "Cosmetologist": 3 },
  "28680": { "Cosmetologist": 6 },
  "37745": { "Cosmetologist": 6, "Barber": 11 },
  "10977": { "Cosmetologist": 3 },
  "27941": { "Cosmetologist": 6 },
  "59808": { "Cosmetologist": 2 },
  "27212": { "Cosmetologist": 9 },
  "39110": { "Cosmetologist": 7 },
  "44440": { "Cosmetologist": 2 },
  "79413": { "Cosmetologist": 138, "Barber": 24 },
  "27167": { "Cosmetologist": 1 },
  "30458": { "Cosmetologist": 5 },
  "27979": { "Cosmetologist": 4 },
  "48033": { "Cosmetologist": 74, "Barber": 9 },
  "29169": { "Cosmetologist": 3 },
  "17104": { "Cosmetologist": 12, "Barber": 8 },
  "35216": { "Cosmetologist": 5 },
  "45067": { "Cosmetologist": 3 },
  "35022": { "Cosmetologist": 5 },
  "37716": { "Cosmetologist": 1, "Barber": 8 },
  "70455": { "Cosmetologist": 1 },
  "68601": { "Cosmetologist": 3 },
  "24091": { "Cosmetologist": 2 },
  "29696": { "Cosmetologist": 2 },
  "12307": { "Cosmetologist": 3 },
  "58652": { "Cosmetologist": 1 },
  "24151": { "Cosmetologist": 1 },
  "39501": { "Cosmetologist": 5 },
  "23518": { "Cosmetologist": 4 },
  "11694": { "Cosmetologist": 2 },
  "28909": { "Cosmetologist": 5 },
  "14052": { "Cosmetologist": 5 },
  "11714": { "Cosmetologist": 1 },
  "77447": { "Cosmetologist": 185, "Barber": 12 },
  "18436": { "Cosmetologist": 20, "Barber": 5 },
  "28747": { "Cosmetologist": 14 },
  "27956": { "Cosmetologist": 1 },
  "27554": { "Cosmetologist": 1 },
  "23451": { "Cosmetologist": 7 },
  "48185": { "Cosmetologist": 323, "Barber": 19 },
  "12585": { "Cosmetologist": 1 },
  "27981": { "Cosmetologist": 10 },
  "29555": { "Cosmetologist": 1 },
  "38751": { "Cosmetologist": 1 },
  "26501": { "Cosmetologist": 1 },
  "92703": { "Cosmetologist": 7 },
  "48314": { "Cosmetologist": 208, "Barber": 17 },
  "53214": { "Cosmetologist": 8 },
  "99337": { "Cosmetologist": 1 },
  "10463": { "Cosmetologist": 3 },
  "28511": { "Cosmetologist": 4 },
  "28579": { "Cosmetologist": 7 },
  "28435": { "Cosmetologist": 11, "Barber": 1 },
  "29306": { "Cosmetologist": 5 },
  "12056": { "Cosmetologist": 2 },
  "28225": { "Cosmetologist": 1 },
  "23325": { "Cosmetologist": 4 },
  "29221": { "Cosmetologist": 1 },
  "25825": { "Cosmetologist": 1 },
  "28524": { "Cosmetologist": 3 },
  "17315": { "Cosmetologist": 16, "Barber": 5 },
  "23901": { "Cosmetologist": 1 },
  "29716": { "Cosmetologist": 2 },
  "27417": { "Cosmetologist": 2 },
  "23944": { "Cosmetologist": 2 },
  "73121": { "Cosmetologist": 1 },
  "14607": { "Cosmetologist": 1 },
  "22172": { "Cosmetologist": 2 },
  "27897": { "Cosmetologist": 2 },
  "37692": { "Cosmetologist": 4, "Barber": 2 },
  "23229": { "Cosmetologist": 3 },
  "24801": { "Cosmetologist": 1 },
  "28406": { "Cosmetologist": 4 },
  "20607": { "Cosmetologist": 2 },
  "70458": { "Cosmetologist": 1 },
  "29376": { "Cosmetologist": 3 },
  "23116": { "Cosmetologist": 1 },
  "91343": { "Cosmetologist": 3 },
  "40361": { "Cosmetologist": 2 },
  "11354": { "Cosmetologist": 6 },
  "29439": { "Cosmetologist": 1 },
  "38501": { "Cosmetologist": 7, "Barber": 67 },
  "12019": { "Cosmetologist": 3 },
  "10552": { "Cosmetologist": 3 },
  "75462": { "Cosmetologist": 106, "Barber": 7 },
  "99016": { "Cosmetologist": 1 },
  "44281": { "Cosmetologist": 5 },
  "48073": { "Cosmetologist": 214, "Barber": 6 },
  "12533": { "Cosmetologist": 4 },
  "15090": { "Cosmetologist": 3 },
  "21074": { "Cosmetologist": 2 },
  "29273": { "Cosmetologist": 1 },
  "15853": { "Cosmetologist": 24 },
  "27342": { "Cosmetologist": 2 },
  "85363": { "Cosmetologist": 2 },
  "89053": { "Cosmetologist": 1 },
  "11949": { "Cosmetologist": 5 },
  "28667": { "Cosmetologist": 4 },
  "35127": { "Cosmetologist": 1 },
  "30259": { "Cosmetologist": 2 },
  "38314": { "Cosmetologist": 1 },
  "36323": { "Cosmetologist": 2 },
  "60503": { "Cosmetologist": 1 },
  "21639": { "Cosmetologist": 1 },
  "53219": { "Cosmetologist": 1 },
  "11365": { "Cosmetologist": 8 },
  "17102": { "Cosmetologist": 3, "Barber": 2 },
  "24084": { "Cosmetologist": 2 },
  "44203": { "Cosmetologist": 3 },
  "27242": { "Cosmetologist": 13, "Barber": 1 },
  "28367": { "Cosmetologist": 1 },
  "29664": { "Cosmetologist": 1 },
  "60010": { "Cosmetologist": 5 },
  "20841": { "Cosmetologist": 4 },
  "23113": { "Cosmetologist": 17 },
  "28038": { "Cosmetologist": 5 },
  "48423": { "Cosmetologist": 265, "Barber": 22 },
  "27844": { "Cosmetologist": 17 },
  "37620": { "Cosmetologist": 8, "Barber": 13 },
  "28039": { "Cosmetologist": 6 },
  "11713": { "Cosmetologist": 3 },
  "29015": { "Cosmetologist": 1 },
  "23837": { "Cosmetologist": 2 },
  "19106": { "Cosmetologist": 2 },
  "28375": { "Cosmetologist": 4 },
  "29127": { "Cosmetologist": 1 },
  "27553": { "Cosmetologist": 13, "Barber": 1 },
  "27204": { "Cosmetologist": 3 },
  "19120": { "Cosmetologist": 17 },
  "28442": { "Cosmetologist": 10 },
  "19007": { "Cosmetologist": 8, "Barber": 2 },
  "23826": { "Cosmetologist": 1 },
  "37049": { "Cosmetologist": 3, "Barber": 3 },
  "27261": { "Cosmetologist": 2 },
  "24550": { "Cosmetologist": 2 },
  "24350": { "Cosmetologist": 1 },
  "45040": { "Cosmetologist": 12 },
  "27761": { "Cosmetologist": 1 },
  "38117": { "Cosmetologist": 2, "Barber": 8 },
  "16915": { "Cosmetologist": 23 },
  "45103": { "Cosmetologist": 4 },
  "49548": { "Cosmetologist": 213, "Barber": 4 },
  "53132": { "Cosmetologist": 12 },
  "28978": { "Cosmetologist": 1 },
  "23321": { "Cosmetologist": 10 },
  "28082": { "Cosmetologist": 4 },
  "23310": { "Cosmetologist": 1 },
  "27715": { "Cosmetologist": 3 },
  "27026": { "Cosmetologist": 1 },
  "26105": { "Cosmetologist": 4 },
  "70037": { "Cosmetologist": 4 },
  "27256": { "Cosmetologist": 1 },
  "29627": { "Cosmetologist": 4 },
  "91024": { "Cosmetologist": 1 },
  "10538": { "Cosmetologist": 2 },
  "28656": { "Cosmetologist": 1 },
  "92225": { "Cosmetologist": 2 },
  "48382": { "Cosmetologist": 199, "Barber": 4 },
  "38337": { "Cosmetologist": 1 },
  "24054": { "Cosmetologist": 4 },
  "88317": { "Cosmetologist": 1 },
  "29759": { "Cosmetologist": 1 },
  "20646": { "Cosmetologist": 1 },
  "29726": { "Cosmetologist": 2 },
  "37051": { "Cosmetologist": 1, "Barber": 1 },
  "37203": { "Cosmetologist": 5, "Barber": 10 },
  "27555": { "Cosmetologist": 4 },
  "28707": { "Cosmetologist": 2 },
  "59036": { "Cosmetologist": 1 },
  "73003": { "Cosmetologist": 3 },
  "24954": { "Cosmetologist": 1 },
  "91711": { "Cosmetologist": 3 },
  "12543": { "Cosmetologist": 1 },
  "27938": { "Cosmetologist": 2 },
  "27916": { "Cosmetologist": 7 },
  "11236": { "Cosmetologist": 2 },
  "28561": { "Cosmetologist": 4 },
  "14305": { "Cosmetologist": 2 },
  "20604": { "Cosmetologist": 1 },
  "18229": { "Cosmetologist": 17, "Barber": 1 },
  "29640": { "Cosmetologist": 2 },
  "30545": { "Cosmetologist": 3 },
  "27920": { "Cosmetologist": 11 },
  "28353": { "Cosmetologist": 6 },
  "29605": { "Cosmetologist": 6 },
  "37415": { "Cosmetologist": 6, "Barber": 5 },
  "19403": { "Cosmetologist": 12, "Barber": 5 },
  "29584": { "Cosmetologist": 1 },
  "15017": { "Cosmetologist": 6, "Barber": 3 },
  "39042": { "Cosmetologist": 5 },
  "12550": { "Cosmetologist": 2 },
  "23927": { "Cosmetologist": 3 },
  "13733": { "Cosmetologist": 3 },
  "26062": { "Cosmetologist": 2 },
  "96377": { "Cosmetologist": 3 },
  "37777": { "Cosmetologist": 2, "Barber": 2 },
  "32978": { "Cosmetologist": 5 },
  "30662": { "Cosmetologist": 1 },
  "24363": { "Cosmetologist": 2 },
  "52722": { "Cosmetologist": 132, "Barber": 2 },
  "23005": { "Cosmetologist": 1 },
  "11510": { "Cosmetologist": 3 },
  "22408": { "Cosmetologist": 4 },
  "18837": { "Cosmetologist": 11 },
  "23366": { "Cosmetologist": 1 },
  "20789": { "Cosmetologist": 1 },
  "92211": { "Cosmetologist": 1 },
  "20842": { "Cosmetologist": 1 },
  "51056": { "Cosmetologist": 3 },
  "17061": { "Cosmetologist": 6 },
  "29384": { "Cosmetologist": 1 },
  "11102": { "Cosmetologist": 6 },
  "21620": { "Cosmetologist": 1 },
  "28853": { "Cosmetologist": 1 },
  "27117": { "Cosmetologist": 2 },
  "20743": { "Cosmetologist": 4 },
  "21629": { "Cosmetologist": 3 },
  "48161": { "Cosmetologist": 193, "Barber": 5 },
  "28571": { "Cosmetologist": 16 },
  "39503": { "Cosmetologist": 11 },
  "28019": { "Cosmetologist": 2 },
  "10940": { "Cosmetologist": 5 },
  "35020": { "Cosmetologist": 1 },
  "19146": { "Cosmetologist": 6 },
  "24986": { "Cosmetologist": 1 },
  "92040": { "Cosmetologist": 4 },
  "15235": { "Cosmetologist": 13, "Barber": 7 },
  "29477": { "Cosmetologist": 2 },
  "43324": { "Cosmetologist": 1 },
  "27833": { "Cosmetologist": 4 },
  "21784": { "Cosmetologist": 1 },
  "53170": { "Cosmetologist": 2 },
  "76082": { "Cosmetologist": 206, "Barber": 7 },
  "95492": { "Cosmetologist": 1 },
  "24609": { "Cosmetologist": 2 },
  "37660": { "Cosmetologist": 3, "Barber": 13 },
  "28093": { "Cosmetologist": 2 },
  "28550": { "Cosmetologist": 1 },
  "37663": { "Cosmetologist": 2, "Barber": 6 },
  "27923": { "Cosmetologist": 3 },
  "27102": { "Cosmetologist": 4 },
  "85080": { "Cosmetologist": 1 },
  "25550": { "Cosmetologist": 1 },
  "31329": { "Cosmetologist": 3 },
  "27824": { "Cosmetologist": 1 },
  "23707": { "Cosmetologist": 2 },
  "11207": { "Cosmetologist": 3 },
  "29063": { "Cosmetologist": 10 },
  "90032": { "Cosmetologist": 1 },
  "19140": { "Cosmetologist": 2, "Barber": 2 },
  "38028": { "Cosmetologist": 3, "Barber": 5 },
  "29528": { "Cosmetologist": 1 },
  "75125": { "Cosmetologist": 39, "Barber": 4 },
  "27314": { "Cosmetologist": 5 },
  "18040": { "Cosmetologist": 5, "Barber": 2 },
  "27875": { "Cosmetologist": 2 },
  "28407": { "Cosmetologist": 1 },
  "28408": { "Cosmetologist": 3 },
  "37725": { "Cosmetologist": 9, "Barber": 5 },
  "22827": { "Cosmetologist": 1 },
  "46777": { "Cosmetologist": 1 },
  "61801": { "Cosmetologist": 2 },
  "28111": { "Cosmetologist": 5 },
  "10460": { "Cosmetologist": 3 },
  "44846": { "Cosmetologist": 1 },
  "29301": { "Cosmetologist": 7 },
  "15906": { "Cosmetologist": 7, "Barber": 2 },
  "11743": { "Cosmetologist": 3 },
  "14727": { "Cosmetologist": 3 },
  "22832": { "Cosmetologist": 1 },
  "20121": { "Cosmetologist": 3 },
  "07002": { "Cosmetologist": 220, "Barber": 154 },
  "73521": { "Cosmetologist": 6 },
  "27826": { "Cosmetologist": 3 },
  "29583": { "Cosmetologist": 3, "Barber": 1 },
  "60018": { "Cosmetologist": 2, "Barber": 1 },
  "95648": { "Cosmetologist": 3 },
  "29205": { "Cosmetologist": 4 },
  "43140": { "Cosmetologist": 2 },
  "31301": { "Cosmetologist": 1 },
  "44254": { "Cosmetologist": 1 },
  "24076": { "Cosmetologist": 2 },
  "37877": { "Cosmetologist": 2, "Barber": 5 },
  "14810": { "Cosmetologist": 1 },
  "75226": { "Cosmetologist": 43, "Barber": 8 },
  "29574": { "Cosmetologist": 7 },
  "20166": { "Cosmetologist": 6 },
  "27840": { "Cosmetologist": 4 },
  "29635": { "Cosmetologist": 1 },
  "92220": { "Cosmetologist": 1 },
  "27459": { "Cosmetologist": 1 },
  "10024": { "Cosmetologist": 6 },
  "14218": { "Cosmetologist": 1 },
  "40701": { "Cosmetologist": 4 },
  "29727": { "Cosmetologist": 6 },
  "60505": { "Cosmetologist": 2 },
  "17003": { "Cosmetologist": 10, "Barber": 1 },
  "36606": { "Cosmetologist": 3 },
  "43912": { "Cosmetologist": 1 },
  "27362": { "Cosmetologist": 2 },
  "29731": { "Cosmetologist": 1 },
  "26405": { "Cosmetologist": 1 },
  "31082": { "Cosmetologist": 1 },
  "27241": { "Cosmetologist": 1 },
  "40059": { "Cosmetologist": 3 },
  "29278": { "Cosmetologist": 1 },
  "61531": { "Cosmetologist": 1 },
  "23605": { "Cosmetologist": 4 },
  "23228": { "Cosmetologist": 1 },
  "27289": { "Cosmetologist": 2 },
  "17050": { "Cosmetologist": 11, "Barber": 24 },
  "28509": { "Cosmetologist": 4 },
  "20685": { "Cosmetologist": 3 },
  "23320": { "Cosmetologist": 16 },
  "16323": { "Cosmetologist": 16, "Barber": 1 },
  "27351": { "Cosmetologist": 3 },
  "29325": { "Cosmetologist": 2 },
  "28626": { "Cosmetologist": 15 },
  "28815": { "Cosmetologist": 3 },
  "92592": { "Cosmetologist": 7 },
  "28541": { "Cosmetologist": 5, "Barber": 1 },
  "29742": { "Cosmetologist": 5 },
  "48336": { "Cosmetologist": 170, "Barber": 12 },
  "92139": { "Cosmetologist": 4 },
  "20746": { "Cosmetologist": 3 },
  "27906": { "Cosmetologist": 3 },
  "31220": { "Cosmetologist": 6 },
  "27629": { "Cosmetologist": 4 },
  "14624": { "Cosmetologist": 4 },
  "24549": { "Cosmetologist": 1 },
  "29083": { "Cosmetologist": 1 },
  "77030": { "Cosmetologist": 19 },
  "58278": { "Cosmetologist": 1 },
  "87059": { "Cosmetologist": 2 },
  "17053": { "Cosmetologist": 13, "Barber": 2 },
  "77388": { "Cosmetologist": 665, "Barber": 35 },
  "89103": { "Cosmetologist": 6 },
  "49507": { "Cosmetologist": 162, "Barber": 9 },
  "39648": { "Cosmetologist": 2 },
  "84104": { "Cosmetologist": 1 },
  "24502": { "Cosmetologist": 14 },
  "29206": { "Cosmetologist": 3 },
  "29014": { "Cosmetologist": 1 },
  "18064": { "Cosmetologist": 8, "Barber": 1 },
  "21114": { "Cosmetologist": 3 },
  "28221": { "Cosmetologist": 3 },
  "24055": { "Cosmetologist": 6 },
  "24201": { "Cosmetologist": 3 },
  "90044": { "Cosmetologist": 1 },
  "12477": { "Cosmetologist": 1 },
  "24523": { "Cosmetologist": 2 },
  "29044": { "Cosmetologist": 3 },
  "54241": { "Cosmetologist": 2 },
  "24293": { "Cosmetologist": 1 },
  "10019": { "Cosmetologist": 4 },
  "28661": { "Cosmetologist": 1 },
  "29672": { "Cosmetologist": 4 },
  "35776": { "Cosmetologist": 1 },
  "70739": { "Cosmetologist": 1 },
  "28653": { "Cosmetologist": 1 },
  "25177": { "Cosmetologist": 2 },
  "17701": { "Cosmetologist": 34, "Barber": 5 },
  "29654": { "Cosmetologist": 2 },
  "53172": { "Cosmetologist": 3 },
  "23704": { "Cosmetologist": 2 },
  "24501": { "Cosmetologist": 4 },
  "28503": { "Cosmetologist": 4 },
  "23919": { "Cosmetologist": 2 },
  "23314": { "Cosmetologist": 1 },
  "23806": { "Cosmetologist": 2 },
  "29544": { "Cosmetologist": 3 },
  "28553": { "Cosmetologist": 3 },
  "27868": { "Cosmetologist": 2 },
  "28297": { "Cosmetologist": 1 },
  "24089": { "Cosmetologist": 1 },
  "78653": { "Cosmetologist": 287, "Barber": 27 },
  "38135": { "Cosmetologist": 2, "Barber": 26 },
  "11782": { "Cosmetologist": 2 },
  "48205": { "Cosmetologist": 80, "Barber": 16 },
  "27879": { "Cosmetologist": 3 },
  "11550": { "Cosmetologist": 1 },
  "27024": { "Cosmetologist": 6 },
  "27305": { "Cosmetologist": 5 },
  "19124": { "Cosmetologist": 9, "Barber": 2 },
  "43777": { "Cosmetologist": 1 },
  "60619": { "Cosmetologist": 1 },
  "92507": { "Cosmetologist": 1 },
  "22963": { "Cosmetologist": 3 },
  "28355": { "Cosmetologist": 4 },
  "24078": { "Cosmetologist": 5 },
  "27819": { "Cosmetologist": 2 },
  "68847": { "Cosmetologist": 1 },
  "48152": { "Cosmetologist": 237, "Barber": 10 },
  "60453": { "Cosmetologist": 7 },
  "21061": { "Cosmetologist": 4 },
  "52302": { "Cosmetologist": 190, "Barber": 22 },
  "37343": { "Cosmetologist": 9, "Barber": 22 },
  "28218": { "Cosmetologist": 1 },
  "29596": { "Cosmetologist": 1 },
  "89523": { "Cosmetologist": 4 },
  "35077": { "Cosmetologist": 1 },
  "36064": { "Cosmetologist": 4 },
  "29591": { "Cosmetologist": 1 },
  "94931": { "Cosmetologist": 1 },
  "75060": { "Cosmetologist": 393, "Barber": 35 },
  "96915": { "Cosmetologist": 4 },
  "28538": { "Cosmetologist": 14 },
  "36856": { "Cosmetologist": 1 },
  "29369": { "Cosmetologist": 1 },
  "95073": { "Cosmetologist": 2 },
  "29464": { "Cosmetologist": 8 },
  "17859": { "Cosmetologist": 9, "Barber": 1 },
  "38348": { "Cosmetologist": 1 },
  "15632": { "Cosmetologist": 5 },
  "37060": { "Cosmetologist": 1, "Barber": 4 },
  "29142": { "Cosmetologist": 1 },
  "45365": { "Cosmetologist": 3 },
  "12198": { "Cosmetologist": 1 },
  "22401": { "Cosmetologist": 3 },
  "76058": { "Cosmetologist": 187, "Barber": 8 },
  "24651": { "Cosmetologist": 1 },
  "23487": { "Cosmetologist": 1 },
  "10185": { "Cosmetologist": 1 },
  "18944": { "Cosmetologist": 7 },
  "23323": { "Cosmetologist": 9 },
  "27420": { "Cosmetologist": 1 },
  "55963": { "Cosmetologist": 1 },
  "70056": { "Cosmetologist": 4 },
  "53147": { "Cosmetologist": 3 },
  "28336": { "Cosmetologist": 1 },
  "17042": { "Cosmetologist": 31, "Barber": 13 },
  "43103": { "Cosmetologist": 2, "Barber": 1 },
  "47724": { "Cosmetologist": 1 },
  "60471": { "Cosmetologist": 1 },
  "27084": { "Cosmetologist": 1 },
  "35630": { "Cosmetologist": 7 },
  "59104": { "Cosmetologist": 1 },
  "96107": { "Cosmetologist": 1 },
  "37215": { "Cosmetologist": 3, "Barber": 5 },
  "22901": { "Cosmetologist": 4 },
  "96338": { "Cosmetologist": 1 },
  "10579": { "Cosmetologist": 1 },
  "21727": { "Cosmetologist": 1 },
  "29560": { "Cosmetologist": 4 },
  "49024": { "Cosmetologist": 223, "Barber": 3 },
  "30568": { "Cosmetologist": 2 },
  "75417": { "Cosmetologist": 17 },
  "29743": { "Cosmetologist": 3 },
  "37187": { "Cosmetologist": 1, "Barber": 3 },
  "43019": { "Cosmetologist": 2 },
  "95842": { "Cosmetologist": 3 },
  "84101": { "Cosmetologist": 1 },
  "11239": { "Cosmetologist": 1 },
  "28822": { "Cosmetologist": 1 },
  "44878": { "Cosmetologist": 1 },
  "36776": { "Cosmetologist": 1 },
  "25661": { "Cosmetologist": 1 },
  "27927": { "Cosmetologist": 2 },
  "28520": { "Cosmetologist": 1 },
  "13208": { "Cosmetologist": 11 },
  "42134": { "Cosmetologist": 1 },
  "23662": { "Cosmetologist": 1 },
  "27835": { "Cosmetologist": 2 },
  "20832": { "Cosmetologist": 3 },
  "24082": { "Cosmetologist": 1 },
  "13104": { "Cosmetologist": 3 },
  "48213": { "Cosmetologist": 45, "Barber": 9 },
  "28608": { "Cosmetologist": 1 },
  "23604": { "Cosmetologist": 2 },
  "27078": { "Cosmetologist": 1 },
  "27630": { "Cosmetologist": 1 },
  "24333": { "Cosmetologist": 13 },
  "35763": { "Cosmetologist": 7 },
  "39520": { "Cosmetologist": 1 },
  "39530": { "Cosmetologist": 10 },
  "48420": { "Cosmetologist": 201, "Barber": 17 },
  "37771": { "Cosmetologist": 2, "Barber": 8 },
  "98506": { "Cosmetologist": 2 },
  "68144": { "Cosmetologist": 3 },
  "26101": { "Cosmetologist": 4 },
  "95377": { "Cosmetologist": 5 },
  "72921": { "Cosmetologist": 2 },
  "28094": { "Cosmetologist": 1 },
  "31503": { "Cosmetologist": 10 },
  "27709": { "Cosmetologist": 2 },
  "27115": { "Cosmetologist": 2 },
  "98041": { "Cosmetologist": 1 },
  "60178": { "Cosmetologist": 2 },
  "98264": { "Cosmetologist": 1 },
  "91505": { "Cosmetologist": 1 },
  "98166": { "Cosmetologist": 1 },
  "28235": { "Cosmetologist": 2 },
  "31408": { "Cosmetologist": 1 },
  "27416": { "Cosmetologist": 2 },
  "28247": { "Cosmetologist": 2 },
  "23071": { "Cosmetologist": 1 },
  "49006": { "Cosmetologist": 87, "Barber": 10 },
  "19401": { "Cosmetologist": 5, "Barber": 17 },
  "77632": { "Cosmetologist": 148, "Barber": 5 },
  "90403": { "Cosmetologist": 4 },
  "24605": { "Cosmetologist": 2 },
  "11379": { "Cosmetologist": 8 },
  "29835": { "Cosmetologist": 2 },
  "29321": { "Cosmetologist": 2 },
  "24343": { "Cosmetologist": 3 },
  "50070": { "Cosmetologist": 11, "Barber": 2 },
  "60068": { "Cosmetologist": 3 },
  "29901": { "Cosmetologist": 1 },
  "11693": { "Cosmetologist": 3 },
  "28029": { "Cosmetologist": 1 },
  "28233": { "Cosmetologist": 1 },
  "29054": { "Cosmetologist": 3 },
  "27415": { "Cosmetologist": 3 },
  "42431": { "Cosmetologist": 2 },
  "77578": { "Cosmetologist": 243, "Barber": 20 },
  "23502": { "Cosmetologist": 8 },
  "29372": { "Cosmetologist": 2 },
  "27862": { "Cosmetologist": 2 },
  "24161": { "Cosmetologist": 1 },
  "95404": { "Cosmetologist": 2 },
  "54166": { "Cosmetologist": 2 },
  "28156": { "Cosmetologist": 1 },
  "60076": { "Cosmetologist": 4 },
  "97394": { "Cosmetologist": 1 },
  "72002": { "Cosmetologist": 2 },
  "29412": { "Cosmetologist": 7 },
  "70814": { "Cosmetologist": 1 },
  "49017": { "Cosmetologist": 127, "Barber": 11 },
  "24527": { "Cosmetologist": 4 },
  "45002": { "Cosmetologist": 1 },
  "29209": { "Cosmetologist": 7 },
  "27821": { "Cosmetologist": 1 },
  "79934": { "Cosmetologist": 190, "Barber": 18 },
  "27907": { "Cosmetologist": 1 },
  "45118": { "Cosmetologist": 1 },
  "71033": { "Cosmetologist": 1 },
  "29832": { "Cosmetologist": 1 },
  "97501": { "Cosmetologist": 2 },
  "27627": { "Cosmetologist": 1 },
  "36274": { "Cosmetologist": 4 },
  "14610": { "Cosmetologist": 1 },
  "31420": { "Cosmetologist": 1 },
  "13148": { "Cosmetologist": 1 },
  "20717": { "Cosmetologist": 1 },
  "27867": { "Cosmetologist": 1 },
  "27661": { "Cosmetologist": 1 },
  "70445": { "Cosmetologist": 2 },
  "10454": { "Cosmetologist": 1 },
  "10998": { "Cosmetologist": 2 },
  "16743": { "Cosmetologist": 12 },
  "27042": { "Cosmetologist": 4 },
  "24016": { "Cosmetologist": 1 },
  "96816": { "Cosmetologist": 4 },
  "37618": { "Cosmetologist": 2, "Barber": 6 },
  "21037": { "Cosmetologist": 1 },
  "91316": { "Cosmetologist": 4 },
  "37681": { "Cosmetologist": 1, "Barber": 2 },
  "30576": { "Cosmetologist": 2 },
  "73115": { "Cosmetologist": 3 },
  "22656": { "Cosmetologist": 2 },
  "28426": { "Cosmetologist": 1 },
  "25813": { "Cosmetologist": 2 },
  "29154": { "Cosmetologist": 6 },
  "19145": { "Cosmetologist": 16 },
  "10603": { "Cosmetologist": 1 },
  "27269": { "Cosmetologist": 1 },
  "23225": { "Cosmetologist": 3 },
  "98106": { "Cosmetologist": 5 },
  "31635": { "Cosmetologist": 1 },
  "41085": { "Cosmetologist": 1 },
  "29709": { "Cosmetologist": 7 },
  "28776": { "Cosmetologist": 5 },
  "29330": { "Cosmetologist": 4 },
  "48722": { "Cosmetologist": 27, "Barber": 1 },
  "15227": { "Cosmetologist": 12, "Barber": 2 },
  "18902": { "Cosmetologist": 6 },
  "27872": { "Cosmetologist": 1 },
  "60646": { "Cosmetologist": 8 },
  "37641": { "Cosmetologist": 3, "Barber": 4 },
  "55352": { "Cosmetologist": 2 },
  "92010": { "Cosmetologist": 2 },
  "19129": { "Cosmetologist": 3 },
  "13031": { "Cosmetologist": 1 },
  "95631": { "Cosmetologist": 1 },
  "42366": { "Cosmetologist": 1 },
  "49779": { "Cosmetologist": 33, "Barber": 1 },
  "93657": { "Cosmetologist": 1 },
  "13790": { "Cosmetologist": 3 },
  "91206": { "Cosmetologist": 1 },
  "40769": { "Cosmetologist": 2 },
  "11104": { "Cosmetologist": 4 },
  "28666": { "Cosmetologist": 4 },
  "28679": { "Cosmetologist": 4 },
  "22026": { "Cosmetologist": 1 },
  "12110": { "Cosmetologist": 4 },
  "37077": { "Cosmetologist": 3, "Barber": 1 },
  "27895": { "Cosmetologist": 2 },
  "19904": { "Cosmetologist": 3 },
  "22181": { "Cosmetologist": 1 },
  "15601": { "Cosmetologist": 19, "Barber": 3 },
  "78232": { "Cosmetologist": 270, "Barber": 26 },
  "32513": { "Cosmetologist": 3 },
  "92807": { "Cosmetologist": 2 },
  "12972": { "Cosmetologist": 2 },
  "28903": { "Cosmetologist": 1 },
  "28414": { "Cosmetologist": 1 },
  "21085": { "Cosmetologist": 2 },
  "22312": { "Cosmetologist": 1 },
  "13905": { "Cosmetologist": 3 },
  "12047": { "Cosmetologist": 4 },
  "91733": { "Cosmetologist": 3 },
  "75040": { "Cosmetologist": 1640, "Barber": 48 },
  "38901": { "Cosmetologist": 2 },
  "20875": { "Cosmetologist": 1 },
  "48442": { "Cosmetologist": 192, "Barber": 12 },
  "48144": { "Cosmetologist": 46, "Barber": 3 },
  "13298": { "Cosmetologist": 1 },
  "77382": { "Cosmetologist": 124, "Barber": 4 },
  "12885": { "Cosmetologist": 1 },
  "29620": { "Cosmetologist": 1 },
  "17501": { "Cosmetologist": 1 },
  "27919": { "Cosmetologist": 5 },
  "22044": { "Cosmetologist": 1 },
  "78550": { "Cosmetologist": 292, "Barber": 66 },
  "95838": { "Cosmetologist": 1 },
  "68502": { "Cosmetologist": 4 },
  "73446": { "Cosmetologist": 1 },
  "98101": { "Cosmetologist": 4 },
  "24211": { "Cosmetologist": 2 },
  "37153": { "Cosmetologist": 3, "Barber": 8 },
  "38703": { "Cosmetologist": 1 },
  "70523": { "Cosmetologist": 1 },
  "60601": { "Cosmetologist": 3, "Barber": 1 },
  "37813": { "Cosmetologist": 3, "Barber": 11 },
  "89512": { "Cosmetologist": 1 },
  "28549": { "Cosmetologist": 1 },
  "61265": { "Cosmetologist": 61 },
  "19149": { "Cosmetologist": 13 },
  "24426": { "Cosmetologist": 1 },
  "20705": { "Cosmetologist": 3 },
  "29658": { "Cosmetologist": 1 },
  "20018": { "Cosmetologist": 2 },
  "91770": { "Cosmetologist": 2 },
  "98407": { "Cosmetologist": 1 },
  "60554": { "Cosmetologist": 3 },
  "22213": { "Cosmetologist": 1 },
  "29388": { "Cosmetologist": 7 },
  "48174": { "Cosmetologist": 189, "Barber": 13 },
  "15241": { "Cosmetologist": 5, "Barber": 1 },
  "43004": { "Cosmetologist": 6 },
  "28055": { "Cosmetologist": 1 },
  "95811": { "Cosmetologist": 1 },
  "23665": { "Cosmetologist": 3 },
  "30516": { "Cosmetologist": 1 },
  "71832": { "Cosmetologist": 1 },
  "27015": { "Cosmetologist": 1 },
  "27968": { "Cosmetologist": 2 },
  "23304": { "Cosmetologist": 1 },
  "12979": { "Cosmetologist": 1 },
  "38655": { "Cosmetologist": 3 },
  "98107": { "Cosmetologist": 2 },
  "24141": { "Cosmetologist": 4 },
  "28000": { "Cosmetologist": 1 },
  "29411": { "Cosmetologist": 1 },
  "23356": { "Cosmetologist": 3 },
  "26330": { "Cosmetologist": 2 },
  "87701": { "Cosmetologist": 2 },
  "11372": { "Cosmetologist": 10 },
  "21014": { "Cosmetologist": 4 },
  "29102": { "Cosmetologist": 3 },
  "12541": { "Cosmetologist": 1 },
  "44870": { "Cosmetologist": 9 },
  "29370": { "Cosmetologist": 1 },
  "77043": { "Cosmetologist": 196, "Barber": 14 },
  "75081": { "Cosmetologist": 426, "Barber": 30 },
  "44217": { "Cosmetologist": 2 },
  "60653": { "Cosmetologist": 1 },
  "50056": { "Cosmetologist": 9 },
  "12035": { "Cosmetologist": 1 },
  "20011": { "Cosmetologist": 3 },
  "78073": { "Cosmetologist": 60, "Barber": 5 },
  "49091": { "Cosmetologist": 100, "Barber": 3 },
  "77049": { "Cosmetologist": 284, "Barber": 27 },
  "71270": { "Cosmetologist": 6 },
  "29456": { "Cosmetologist": 12 },
  "95954": { "Cosmetologist": 1 },
  "14217": { "Cosmetologist": 2 },
  "11226": { "Cosmetologist": 7 },
  "36352": { "Cosmetologist": 1 },
  "29525": { "Cosmetologist": 3 },
  "28883": { "Cosmetologist": 1 },
  "35124": { "Cosmetologist": 5 },
  "62561": { "Cosmetologist": 1 },
  "18020": { "Cosmetologist": 3, "Barber": 7 },
  "37404": { "Cosmetologist": 3, "Barber": 7 },
  "43054": { "Cosmetologist": 4 },
  "24543": { "Cosmetologist": 1 },
  "79908": { "Cosmetologist": 11 },
  "19027": { "Cosmetologist": 2, "Barber": 3 },
  "58631": { "Cosmetologist": 1 },
  "29829": { "Cosmetologist": 4 },
  "40215": { "Cosmetologist": 1 },
  "27552": { "Cosmetologist": 2 },
  "75089": { "Cosmetologist": 577, "Barber": 32 },
  "90004": { "Cosmetologist": 3 },
  "29721": { "Cosmetologist": 1 },
  "78683": { "Cosmetologist": 1 },
  "29653": { "Cosmetologist": 1 },
  "10704": { "Cosmetologist": 4 },
  "60468": { "Cosmetologist": 3 },
  "94568": { "Cosmetologist": 1 },
  "23920": { "Cosmetologist": 3 },
  "21776": { "Cosmetologist": 1 },
  "36106": { "Cosmetologist": 5 },
  "35209": { "Cosmetologist": 3 },
  "44883": { "Cosmetologist": 2 },
  "20866": { "Cosmetologist": 1 },
  "28051": { "Cosmetologist": 1 },
  "49304": { "Cosmetologist": 27, "Barber": 1 },
  "24142": { "Cosmetologist": 1 },
  "14068": { "Cosmetologist": 1 },
  "15226": { "Cosmetologist": 3, "Barber": 2 },
  "23024": { "Cosmetologist": 2 },
  "19526": { "Cosmetologist": 6 },
  "16105": { "Cosmetologist": 10, "Barber": 2 },
  "29078": { "Cosmetologist": 4 },
  "10032": { "Cosmetologist": 5 },
  "20763": { "Cosmetologist": 1 },
  "35073": { "Cosmetologist": 1 },
  "31071": { "Cosmetologist": 1 },
  "30156": { "Cosmetologist": 1 },
  "27964": { "Cosmetologist": 6 },
  "29230": { "Cosmetologist": 1 },
  "22206": { "Cosmetologist": 3 },
  "28309": { "Cosmetologist": 1 },
  "19375": { "Cosmetologist": 1 },
  "86001": { "Cosmetologist": 1 },
  "40009": { "Cosmetologist": 1 },
  "23510": { "Cosmetologist": 4 },
  "47433": { "Cosmetologist": 1 },
  "44446": { "Cosmetologist": 3 },
  "23231": { "Cosmetologist": 3 },
  "55412": { "Cosmetologist": 1 },
  "90301": { "Cosmetologist": 1 },
  "98093": { "Cosmetologist": 1 },
  "28718": { "Cosmetologist": 2 },
  "38106": { "Cosmetologist": 1, "Barber": 31 },
  "27454": { "Cosmetologist": 1 },
  "28770": { "Cosmetologist": 1 },
  "28668": { "Cosmetologist": 2 },
  "24360": { "Cosmetologist": 1 },
  "29379": { "Cosmetologist": 3 },
  "11758": { "Cosmetologist": 5 },
  "25749": { "Cosmetologist": 1 },
  "24517": { "Cosmetologist": 2 },
  "25419": { "Cosmetologist": 1 },
  "23150": { "Cosmetologist": 1 },
  "44077": { "Cosmetologist": 5 },
  "53103": { "Cosmetologist": 1 },
  "48880": { "Cosmetologist": 44, "Barber": 4 },
  "60033": { "Cosmetologist": 2 },
  "12508": { "Cosmetologist": 1 },
  "27402": { "Cosmetologist": 3 },
  "94022": { "Cosmetologist": 1 },
  "48650": { "Cosmetologist": 64, "Barber": 2 },
  "23141": { "Cosmetologist": 3 },
  "19121": { "Cosmetologist": 3 },
  "15210": { "Cosmetologist": 6, "Barber": 3 },
  "23915": { "Cosmetologist": 1 },
  "36041": { "Cosmetologist": 1 },
  "44460": { "Cosmetologist": 2 },
  "53186": { "Cosmetologist": 4 },
  "27917": { "Cosmetologist": 4 },
  "37010": { "Cosmetologist": 1, "Barber": 3 },
  "29053": { "Cosmetologist": 4 },
  "88011": { "Cosmetologist": 7 },
  "37757": { "Cosmetologist": 1, "Barber": 2 },
  "48236": { "Cosmetologist": 174, "Barber": 4 },
  "94928": { "Cosmetologist": 2 },
  "60645": { "Cosmetologist": 3 },
  "38312": { "Cosmetologist": 1 },
  "24134": { "Cosmetologist": 1 },
  "37686": { "Cosmetologist": 4, "Barber": 6 },
  "59070": { "Cosmetologist": 1 },
  "49221": { "Cosmetologist": 198, "Barber": 9 },
  "27985": { "Cosmetologist": 1 },
  "28222": { "Cosmetologist": 1 },
  "10980": { "Cosmetologist": 4 },
  "29105": { "Cosmetologist": 1 },
  "13619": { "Cosmetologist": 3 },
  "60649": { "Cosmetologist": 2 },
  "95624": { "Cosmetologist": 4 },
  "28737": { "Cosmetologist": 1 },
  "29101": { "Cosmetologist": 1 },
  "27947": { "Cosmetologist": 3 },
  "25045": { "Cosmetologist": 2 },
  "56048": { "Cosmetologist": 1 },
  "21146": { "Cosmetologist": 3 },
  "24378": { "Cosmetologist": 2 },
  "45056": { "Cosmetologist": 2 },
  "61876": { "Cosmetologist": 1 },
  "27849": { "Cosmetologist": 2 },
  "49120": { "Cosmetologist": 153, "Barber": 11 },
  "60135": { "Cosmetologist": 1 },
  "11419": { "Cosmetologist": 3 },
  "44669": { "Cosmetologist": 1 },
  "22942": { "Cosmetologist": 1 },
  "20794": { "Cosmetologist": 1 },
  "11369": { "Cosmetologist": 2 },
  "24012": { "Cosmetologist": 4 },
  "20016": { "Cosmetologist": 3 },
  "27802": { "Cosmetologist": 4 },
  "25817": { "Cosmetologist": 1 },
  "12866": { "Cosmetologist": 9 },
  "55356": { "Cosmetologist": 1 },
  "37312": { "Cosmetologist": 9, "Barber": 10 },
  "60134": { "Cosmetologist": 7 },
  "27618": { "Cosmetologist": 1 },
  "15642": { "Cosmetologist": 26, "Barber": 2 },
  "73554": { "Cosmetologist": 1 },
  "14886": { "Cosmetologist": 1 },
  "71801": { "Cosmetologist": 1 },
  "91367": { "Cosmetologist": 5 },
  "13637": { "Cosmetologist": 1 },
  "12577": { "Cosmetologist": 1 },
  "14750": { "Cosmetologist": 2 },
  "12208": { "Cosmetologist": 2 },
  "71301": { "Cosmetologist": 2 },
  "79118": { "Cosmetologist": 257, "Barber": 21 },
  "29624": { "Cosmetologist": 2 },
  "22003": { "Cosmetologist": 5 },
  "70815": { "Cosmetologist": 3 },
  "19933": { "Cosmetologist": 2 },
  "11229": { "Cosmetologist": 14 },
  "28162": { "Cosmetologist": 1 },
  "60659": { "Cosmetologist": 4 },
  "21030": { "Cosmetologist": 4 },
  "21045": { "Cosmetologist": 4 },
  "28028": { "Cosmetologist": 1 },
  "21875": { "Cosmetologist": 2 },
  "49829": { "Cosmetologist": 150, "Barber": 7 },
  "37067": { "Cosmetologist": 13, "Barber": 11 },
  "27497": { "Cosmetologist": 1 },
  "24614": { "Cosmetologist": 1 },
  "29550": { "Cosmetologist": 4 },
  "49423": { "Cosmetologist": 315, "Barber": 9 },
  "77067": { "Cosmetologist": 245, "Barber": 33 },
  "77017": { "Cosmetologist": 310, "Barber": 27 },
  "77065": { "Cosmetologist": 386, "Barber": 29 },
  "37923": { "Cosmetologist": 8, "Barber": 10 },
  "30553": { "Cosmetologist": 2 },
  "92655": { "Cosmetologist": 1 },
  "53225": { "Cosmetologist": 2 },
  "27110": { "Cosmetologist": 1 },
  "29429": { "Cosmetologist": 2 },
  "73106": { "Cosmetologist": 2 },
  "39208": { "Cosmetologist": 2 },
  "21850": { "Cosmetologist": 1 },
  "14608": { "Cosmetologist": 2 },
  "78218": { "Cosmetologist": 383, "Barber": 33 },
  "11784": { "Cosmetologist": 6 },
  "36575": { "Cosmetologist": 5 },
  "37876": { "Cosmetologist": 13, "Barber": 8 },
  "35405": { "Cosmetologist": 6 },
  "61802": { "Cosmetologist": 1 },
  "39180": { "Cosmetologist": 7 },
  "70094": { "Cosmetologist": 7 },
  "48091": { "Cosmetologist": 125, "Barber": 10 },
  "48092": { "Cosmetologist": 209, "Barber": 13 },
  "10601": { "Cosmetologist": 4 },
  "28404": { "Cosmetologist": 2 },
  "19809": { "Cosmetologist": 1 },
  "24811": { "Cosmetologist": 1 },
  "18337": { "Cosmetologist": 65, "Barber": 5 },
  "28232": { "Cosmetologist": 1 },
  "22508": { "Cosmetologist": 4 },
  "99515": { "Cosmetologist": 6 },
  "77075": { "Cosmetologist": 765, "Barber": 41 },
  "30452": { "Cosmetologist": 1 },
  "78222": { "Cosmetologist": 173, "Barber": 21 },
  "77656": { "Cosmetologist": 91, "Barber": 7 },
  "59714": { "Cosmetologist": 3 },
  "99705": { "Cosmetologist": 4 },
  "12159": { "Cosmetologist": 1 },
  "11726": { "Cosmetologist": 5 },
  "27602": { "Cosmetologist": 1 },
  "29019": { "Cosmetologist": 1 },
  "14530": { "Cosmetologist": 2 },
  "37821": { "Cosmetologist": 2, "Barber": 6 },
  "12180": { "Cosmetologist": 2 },
  "45120": { "Cosmetologist": 1 },
  "98404": { "Cosmetologist": 1 },
  "77303": { "Cosmetologist": 148, "Barber": 9 },
  "75704": { "Cosmetologist": 61, "Barber": 11 },
  "10011": { "Cosmetologist": 13 },
  "21912": { "Cosmetologist": 1 },
  "49418": { "Cosmetologist": 316, "Barber": 6 },
  "29676": { "Cosmetologist": 3 },
  "28727": { "Cosmetologist": 1, "Barber": 1 },
  "35206": { "Cosmetologist": 1 },
  "28272": { "Cosmetologist": 1 },
  "24901": { "Cosmetologist": 1 },
  "53151": { "Cosmetologist": 4 },
  "29304": { "Cosmetologist": 2 },
  "95988": { "Cosmetologist": 1 },
  "19115": { "Cosmetologist": 7 },
  "16441": { "Cosmetologist": 3, "Barber": 3 },
  "78240": { "Cosmetologist": 335, "Barber": 28 },
  "60506": { "Cosmetologist": 4 },
  "38671": { "Cosmetologist": 5 },
  "12010": { "Cosmetologist": 2 },
  "15217": { "Cosmetologist": 4 },
  "48044": { "Cosmetologist": 627, "Barber": 23 },
  "28802": { "Cosmetologist": 1 },
  "11220": { "Cosmetologist": 4 },
  "77251": { "Cosmetologist": 1 },
  "29405": { "Cosmetologist": 4 },
  "26037": { "Cosmetologist": 1 },
  "42166": { "Cosmetologist": 1 },
  "10040": { "Cosmetologist": 5 },
  "12134": { "Cosmetologist": 1 },
  "11735": { "Cosmetologist": 1 },
  "29827": { "Cosmetologist": 1 },
  "60411": { "Cosmetologist": 7 },
  "24558": { "Cosmetologist": 3 },
  "37069": { "Cosmetologist": 4, "Barber": 3 },
  "14619": { "Cosmetologist": 3 },
  "53805": { "Cosmetologist": 1 },
  "10990": { "Cosmetologist": 3 },
  "27594": { "Cosmetologist": 1 },
  "11716": { "Cosmetologist": 2 },
  "14047": { "Cosmetologist": 2 },
  "99206": { "Cosmetologist": 2 },
  "28581": { "Cosmetologist": 1 },
  "18103": { "Cosmetologist": 23, "Barber": 31 },
  "11201": { "Cosmetologist": 2 },
  "95472": { "Cosmetologist": 1 },
  "77338": { "Cosmetologist": 317, "Barber": 52 },
  "15237": { "Cosmetologist": 7, "Barber": 1 },
  "23117": { "Cosmetologist": 2 },
  "22551": { "Cosmetologist": 1 },
  "29164": { "Cosmetologist": 1 },
  "28646": { "Cosmetologist": 5 },
  "54911": { "Cosmetologist": 2 },
  "20713": { "Cosmetologist": 1 },
  "19940": { "Cosmetologist": 1 },
  "23235": { "Cosmetologist": 3 },
  "20151": { "Cosmetologist": 1 },
  "13041": { "Cosmetologist": 3 },
  "20678": { "Cosmetologist": 3 },
  "24739": { "Cosmetologist": 2 },
  "13205": { "Cosmetologist": 1 },
  "35053": { "Cosmetologist": 1 },
  "37864": { "Cosmetologist": 4 },
  "19950": { "Cosmetologist": 1 },
  "95062": { "Cosmetologist": 2 },
  "15220": { "Cosmetologist": 4, "Barber": 2 },
  "24073": { "Cosmetologist": 3 },
  "28907": { "Cosmetologist": 1 },
  "24064": { "Cosmetologist": 1 },
  "24330": { "Cosmetologist": 1 },
  "71601": { "Cosmetologist": 2 },
  "98456": { "Cosmetologist": 1 },
  "10543": { "Cosmetologist": 3 },
  "48193": { "Cosmetologist": 124, "Barber": 7 },
  "55117": { "Cosmetologist": 3 },
  "29540": { "Cosmetologist": 2 },
  "77834": { "Cosmetologist": 1 },
  "15206": { "Cosmetologist": 5, "Barber": 2 },
  "24381": { "Cosmetologist": 2 },
  "15122": { "Cosmetologist": 6, "Barber": 2 },
  "90504": { "Cosmetologist": 2 },
  "61844": { "Cosmetologist": 1 },
  "17111": { "Cosmetologist": 30, "Barber": 5 },
  "14738": { "Cosmetologist": 1 },
  "35801": { "Cosmetologist": 1 },
  "10604": { "Cosmetologist": 2 },
  "24210": { "Cosmetologist": 3 },
  "54301": { "Cosmetologist": 3 },
  "37873": { "Cosmetologist": 1, "Barber": 2 },
  "55433": { "Cosmetologist": 3 },
  "24557": { "Cosmetologist": 1 },
  "37665": { "Cosmetologist": 2, "Barber": 5 },
  "15224": { "Cosmetologist": 4, "Barber": 1 },
  "77523": { "Cosmetologist": 220, "Barber": 6 },
  "29365": { "Cosmetologist": 4, "Barber": 1 },
  "75244": { "Cosmetologist": 112, "Barber": 9 },
  "55705": { "Cosmetologist": 2 },
  "15239": { "Cosmetologist": 5, "Barber": 2 },
  "28749": { "Cosmetologist": 1 },
  "27564": { "Cosmetologist": 1 },
  "38821": { "Cosmetologist": 1 },
  "10566": { "Cosmetologist": 4 },
  "44131": { "Cosmetologist": 2 },
  "10301": { "Cosmetologist": 2 },
  "95209": { "Cosmetologist": 2 },
  "41224": { "Cosmetologist": 1 },
  "71655": { "Cosmetologist": 1 },
  "10512": { "Cosmetologist": 2 },
  "78945": { "Cosmetologist": 93, "Barber": 6 },
  "18466": { "Cosmetologist": 15, "Barber": 3 },
  "40223": { "Cosmetologist": 5 },
  "21143": { "Cosmetologist": 1 },
  "28517": { "Cosmetologist": 1 },
  "37887": { "Cosmetologist": 1, "Barber": 2 },
  "97525": { "Cosmetologist": 1 },
  "91506": { "Cosmetologist": 2 },
  "48111": { "Cosmetologist": 286, "Barber": 14 },
  "44012": { "Cosmetologist": 3 },
  "37216": { "Cosmetologist": 4, "Barber": 22 },
  "14867": { "Cosmetologist": 1 },
  "45342": { "Cosmetologist": 6 },
  "29069": { "Cosmetologist": 2 },
  "43311": { "Cosmetologist": 1 },
  "19382": { "Cosmetologist": 11, "Barber": 3 },
  "28074": { "Cosmetologist": 1 },
  "76634": { "Cosmetologist": 37, "Barber": 4 },
  "28880": { "Cosmetologist": 1 },
  "78236": { "Cosmetologist": 7 },
  "29364": { "Cosmetologist": 1 },
  "48180": { "Cosmetologist": 366, "Barber": 17 },
  "27730": { "Cosmetologist": 1 },
  "18072": { "Cosmetologist": 4, "Barber": 4 },
  "25909": { "Cosmetologist": 1 },
  "78734": { "Cosmetologist": 157, "Barber": 14 },
  "13044": { "Cosmetologist": 1 },
  "27722": { "Cosmetologist": 1 },
  "12487": { "Cosmetologist": 1 },
  "88210": { "Cosmetologist": 1 },
  "44067": { "Cosmetologist": 1 },
  "27003": { "Cosmetologist": 1 },
  "28878": { "Cosmetologist": 1 },
  "28076": { "Cosmetologist": 2 },
  "37849": { "Cosmetologist": 2, "Barber": 14 },
  "71129": { "Cosmetologist": 1 },
  "17015": { "Cosmetologist": 6, "Barber": 1 },
  "98363": { "Cosmetologist": 3 },
  "48135": { "Cosmetologist": 190, "Barber": 8 },
  "94044": { "Cosmetologist": 2 },
  "37022": { "Cosmetologist": 1 },
  "11013": { "Cosmetologist": 1 },
  "18446": { "Cosmetologist": 18 },
  "36854": { "Cosmetologist": 1 },
  "27586": { "Cosmetologist": 1 },
  "90012": { "Cosmetologist": 2 },
  "15071": { "Cosmetologist": 3 },
  "12303": { "Cosmetologist": 1 },
  "90018": { "Cosmetologist": 2 },
  "84103": { "Cosmetologist": 1 },
  "12725": { "Cosmetologist": 1 },
  "54720": { "Cosmetologist": 1 },
  "10710": { "Cosmetologist": 3 },
  "94109": { "Cosmetologist": 6 },
  "18013": { "Cosmetologist": 3, "Barber": 2 },
  "11364": { "Cosmetologist": 2 },
  "12087": { "Cosmetologist": 1 },
  "10013": { "Cosmetologist": 2 },
  "90808": { "Cosmetologist": 2 },
  "60073": { "Cosmetologist": 3 },
  "98686": { "Cosmetologist": 1 },
  "25727": { "Cosmetologist": 1 },
  "22923": { "Cosmetologist": 1 },
  "58204": { "Cosmetologist": 2 },
  "29128": { "Cosmetologist": 1 },
  "39717": { "Cosmetologist": 1 },
  "15531": { "Cosmetologist": 4 },
  "19076": { "Cosmetologist": 3 },
  "10312": { "Cosmetologist": 5 },
  "27582": { "Cosmetologist": 2 },
  "14454": { "Cosmetologist": 2 },
  "98372": { "Cosmetologist": 3 },
  "10549": { "Cosmetologist": 5 },
  "14456": { "Cosmetologist": 2 },
  "36301": { "Cosmetologist": 11 },
  "19605": { "Cosmetologist": 11, "Barber": 6 },
  "49087": { "Cosmetologist": 67, "Barber": 3 },
  "53094": { "Cosmetologist": 1 },
  "33163": { "Cosmetologist": 4 },
  "27570": { "Cosmetologist": 1 },
  "11010": { "Cosmetologist": 3 },
  "92286": { "Cosmetologist": 1 },
  "48093": { "Cosmetologist": 175, "Barber": 11 },
  "30377": { "Cosmetologist": 1 },
  "29554": { "Cosmetologist": 2 },
  "48625": { "Cosmetologist": 62, "Barber": 5 },
  "27532": { "Cosmetologist": 3 },
  "27202": { "Cosmetologist": 1 },
  "22969": { "Cosmetologist": 1 },
  "43316": { "Cosmetologist": 1 },
  "13411": { "Cosmetologist": 1 },
  "84102": { "Cosmetologist": 2 },
  "16602": { "Cosmetologist": 16, "Barber": 5 },
  "95821": { "Cosmetologist": 2 },
  "23899": { "Cosmetologist": 1 },
  "19348": { "Cosmetologist": 5, "Barber": 9 },
  "13403": { "Cosmetologist": 3 },
  "13027": { "Cosmetologist": 4 },
  "11357": { "Cosmetologist": 5 },
  "35133": { "Cosmetologist": 1 },
  "23851": { "Cosmetologist": 4 },
  "98121": { "Cosmetologist": 3 },
  "28647": { "Cosmetologist": 1 },
  "75069": { "Cosmetologist": 272, "Barber": 22 },
  "13606": { "Cosmetologist": 1 },
  "17402": { "Cosmetologist": 22, "Barber": 7 },
  "68111": { "Cosmetologist": 4 },
  "84105": { "Cosmetologist": 1 },
  "29148": { "Cosmetologist": 3 },
  "98592": { "Cosmetologist": 1 },
  "29571": { "Cosmetologist": 1 },
  "29697": { "Cosmetologist": 2 },
  "26347": { "Cosmetologist": 1 },
  "78745": { "Cosmetologist": 490, "Barber": 52 },
  "99508": { "Cosmetologist": 5 },
  "29201": { "Cosmetologist": 2 },
  "28893": { "Cosmetologist": 1 },
  "20613": { "Cosmetologist": 1 },
  "91942": { "Cosmetologist": 5 },
  "23882": { "Cosmetologist": 1 },
  "19460": { "Cosmetologist": 13, "Barber": 7 },
  "23219": { "Cosmetologist": 1 },
  "31714": { "Cosmetologist": 2 },
  "14609": { "Cosmetologist": 2 },
  "84651": { "Cosmetologist": 1 },
  "86351": { "Cosmetologist": 3 },
  "27729": { "Cosmetologist": 1 },
  "29920": { "Cosmetologist": 2 },
  "21163": { "Cosmetologist": 1 },
  "85613": { "Cosmetologist": 2 },
  "19702": { "Cosmetologist": 5 },
  "21012": { "Cosmetologist": 1 },
  "37015": { "Cosmetologist": 3, "Barber": 13 },
  "59719": { "Cosmetologist": 1 },
  "25309": { "Cosmetologist": 2 },
  "75246": { "Cosmetologist": 20, "Barber": 3 },
  "79932": { "Cosmetologist": 207, "Barber": 4 },
  "27827": { "Cosmetologist": 1 },
  "28758": { "Cosmetologist": 2 },
  "32925": { "Cosmetologist": 7 },
  "19143": { "Cosmetologist": 3 },
  "29744": { "Cosmetologist": 2 },
  "53593": { "Cosmetologist": 5 },
  "24184": { "Cosmetologist": 1 },
  "60467": { "Cosmetologist": 1 },
  "41858": { "Cosmetologist": 1 },
  "23693": { "Cosmetologist": 5 },
  "50312": { "Cosmetologist": 57, "Barber": 7 },
  "11373": { "Cosmetologist": 8 },
  "75651": { "Cosmetologist": 19 },
  "76014": { "Cosmetologist": 733, "Barber": 40 },
  "91030": { "Cosmetologist": 2 },
  "50703": { "Cosmetologist": 50, "Barber": 21 },
  "11231": { "Cosmetologist": 4 },
  "27225": { "Cosmetologist": 1 },
  "28268": { "Cosmetologist": 1 },
  "15824": { "Cosmetologist": 14, "Barber": 1 },
  "10462": { "Cosmetologist": 3 },
  "16947": { "Cosmetologist": 16, "Barber": 1 },
  "76132": { "Cosmetologist": 161, "Barber": 9 },
  "29545": { "Cosmetologist": 1 },
  "12523": { "Cosmetologist": 2 },
  "10567": { "Cosmetologist": 1 },
  "48341": { "Cosmetologist": 105, "Barber": 18 },
  "75965": { "Cosmetologist": 120, "Barber": 7 },
  "61547": { "Cosmetologist": 1 },
  "15120": { "Cosmetologist": 6, "Barber": 1 },
  "23696": { "Cosmetologist": 1 },
  "75432": { "Cosmetologist": 18 },
  "10960": { "Cosmetologist": 3 },
  "55416": { "Cosmetologist": 5 },
  "24368": { "Cosmetologist": 1 },
  "28799": { "Cosmetologist": 1 },
  "54403": { "Cosmetologist": 2 },
  "36522": { "Cosmetologist": 2 },
  "28537": { "Cosmetologist": 1 },
  "48827": { "Cosmetologist": 119, "Barber": 11 },
  "27201": { "Cosmetologist": 1 },
  "28765": { "Cosmetologist": 1 },
  "48235": { "Cosmetologist": 148, "Barber": 34 },
  "45385": { "Cosmetologist": 5 },
  "93024": { "Cosmetologist": 1 },
  "37576": { "Cosmetologist": 1 },
  "73026": { "Cosmetologist": 2 },
  "49349": { "Cosmetologist": 31 },
  "24522": { "Cosmetologist": 1 },
  "39301": { "Cosmetologist": 4 },
  "84029": { "Cosmetologist": 1 },
  "19025": { "Cosmetologist": 2 },
  "60462": { "Cosmetologist": 4 },
  "37322": { "Cosmetologist": 2, "Barber": 1 },
  "29945": { "Cosmetologist": 1 },
  "28564": { "Cosmetologist": 1 },
  "44021": { "Cosmetologist": 1 },
  "22314": { "Cosmetologist": 2 },
  "68305": { "Cosmetologist": 1 },
  "18301": { "Cosmetologist": 16, "Barber": 11 },
  "55903": { "Cosmetologist": 1 },
  "28447": { "Cosmetologist": 1 },
  "15201": { "Cosmetologist": 5, "Barber": 1 },
  "29678": { "Cosmetologist": 2, "Barber": 1 },
  "12503": { "Cosmetologist": 1 },
  "43136": { "Cosmetologist": 1 },
  "78256": { "Cosmetologist": 76, "Barber": 2 },
  "24598": { "Cosmetologist": 3 },
  "11722": { "Cosmetologist": 2 },
  "25113": { "Cosmetologist": 1 },
  "37219": { "Cosmetologist": 1 },
  "15204": { "Cosmetologist": 5, "Barber": 1 },
  "40006": { "Cosmetologist": 1 },
  "44820": { "Cosmetologist": 1 },
  "14813": { "Cosmetologist": 1 },
  "28100": { "Cosmetologist": 1 },
  "20189": { "Cosmetologist": 2 },
  "23508": { "Cosmetologist": 1 },
  "10036": { "Cosmetologist": 5 },
  "19147": { "Cosmetologist": 2, "Barber": 1 },
  "17512": { "Cosmetologist": 8, "Barber": 3 },
  "19111": { "Cosmetologist": 15 },
  "48430": { "Cosmetologist": 391, "Barber": 20 },
  "11208": { "Cosmetologist": 1 },
  "91722": { "Cosmetologist": 1 },
  "29114": { "Cosmetologist": 1 },
  "58704": { "Cosmetologist": 2 },
  "24266": { "Cosmetologist": 1 },
  "25722": { "Cosmetologist": 1 },
  "44824": { "Cosmetologist": 1 },
  "77086": { "Cosmetologist": 459, "Barber": 23 },
  "19703": { "Cosmetologist": 2 },
  "29335": { "Cosmetologist": 1 },
  "37127": { "Cosmetologist": 4, "Barber": 41 },
  "93955": { "Cosmetologist": 4 },
  "20912": { "Cosmetologist": 1 },
  "37705": { "Cosmetologist": 3, "Barber": 2 },
  "14466": { "Cosmetologist": 1 },
  "10007": { "Cosmetologist": 1 },
  "21613": { "Cosmetologist": 1 },
  "48911": { "Cosmetologist": 224, "Barber": 25 },
  "49316": { "Cosmetologist": 232, "Barber": 2 },
  "75431": { "Cosmetologist": 17 },
  "21047": { "Cosmetologist": 2 },
  "20636": { "Cosmetologist": 3 },
  "25526": { "Cosmetologist": 7 },
  "38125": { "Cosmetologist": 2, "Barber": 67 },
  "48458": { "Cosmetologist": 122, "Barber": 7 },
  "13413": { "Cosmetologist": 1 },
  "46373": { "Cosmetologist": 4 },
  "99217": { "Cosmetologist": 3 },
  "77380": { "Cosmetologist": 198, "Barber": 11 },
  "10992": { "Cosmetologist": 3 },
  "25971": { "Cosmetologist": 1 },
  "27543": { "Cosmetologist": 1 },
  "49085": { "Cosmetologist": 160, "Barber": 5 },
  "11978": { "Cosmetologist": 1 },
  "55404": { "Cosmetologist": 2 },
  "36360": { "Cosmetologist": 4 },
  "94705": { "Cosmetologist": 1 },
  "37912": { "Cosmetologist": 5, "Barber": 20 },
  "20001": { "Cosmetologist": 4 },
  "14903": { "Cosmetologist": 2 },
  "76112": { "Cosmetologist": 305, "Barber": 56 },
  "38017": { "Cosmetologist": 2, "Barber": 19 },
  "25425": { "Cosmetologist": 2 },
  "17078": { "Cosmetologist": 29, "Barber": 10 },
  "39183": { "Cosmetologist": 1 },
  "28814": { "Cosmetologist": 1 },
  "96016": { "Cosmetologist": 1 },
  "91304": { "Cosmetologist": 3 },
  "53707": { "Cosmetologist": 1 },
  "27303": { "Cosmetologist": 1 },
  "11939": { "Cosmetologist": 1 },
  "20032": { "Cosmetologist": 2 },
  "15001": { "Cosmetologist": 17, "Barber": 5 },
  "29645": { "Cosmetologist": 1 },
  "56377": { "Cosmetologist": 3 },
  "12401": { "Cosmetologist": 4 },
  "14621": { "Cosmetologist": 4 },
  "35235": { "Cosmetologist": 4 },
  "10461": { "Cosmetologist": 4 },
  "25844": { "Cosmetologist": 1 },
  "37354": { "Cosmetologist": 4, "Barber": 7 },
  "73527": { "Cosmetologist": 2 },
  "44691": { "Cosmetologist": 6 },
  "94062": { "Cosmetologist": 1 },
  "24706": { "Cosmetologist": 1 },
  "27172": { "Cosmetologist": 1 },
  "41522": { "Cosmetologist": 2 },
  "19122": { "Cosmetologist": 2 },
  "23661": { "Cosmetologist": 2 },
  "45054": { "Cosmetologist": 1 },
  "78704": { "Cosmetologist": 269, "Barber": 25 },
  "13669": { "Cosmetologist": 4 },
  "39465": { "Cosmetologist": 1 },
  "29012": { "Cosmetologist": 1 },
  "17257": { "Cosmetologist": 11, "Barber": 2 },
  "96311": { "Cosmetologist": 1 },
  "27622": { "Cosmetologist": 1 },
  "24069": { "Cosmetologist": 1 },
  "76557": { "Cosmetologist": 27, "Barber": 4 },
  "99352": { "Cosmetologist": 1 },
  "96721": { "Cosmetologist": 1 },
  "98631": { "Cosmetologist": 1 },
  "91786": { "Cosmetologist": 2 },
  "90232": { "Cosmetologist": 1 },
  "60477": { "Cosmetologist": 4 },
  "17820": { "Cosmetologist": 7, "Barber": 1 },
  "28220": { "Cosmetologist": 1 },
  "97124": { "Cosmetologist": 1 },
  "10455": { "Cosmetologist": 2 },
  "55065": { "Cosmetologist": 1 },
  "50131": { "Cosmetologist": 125, "Barber": 4 },
  "91901": { "Cosmetologist": 2 },
  "39571": { "Cosmetologist": 5 },
  "27853": { "Cosmetologist": 1 },
  "43420": { "Cosmetologist": 1 },
  "41001": { "Cosmetologist": 1 },
  "19128": { "Cosmetologist": 3 },
  "95219": { "Cosmetologist": 1 },
  "38852": { "Cosmetologist": 2 },
  "23513": { "Cosmetologist": 5 },
  "19018": { "Cosmetologist": 10, "Barber": 2 },
  "94080": { "Cosmetologist": 3 },
  "56623": { "Cosmetologist": 1 },
  "31099": { "Cosmetologist": 2 },
  "11746": { "Cosmetologist": 6 },
  "48224": { "Cosmetologist": 108, "Barber": 21 },
  "19026": { "Cosmetologist": 12, "Barber": 1 },
  "24556": { "Cosmetologist": 2 },
  "29079": { "Cosmetologist": 1 },
  "55411": { "Cosmetologist": 1 },
  "36107": { "Cosmetologist": 2 },
  "23453": { "Cosmetologist": 3 },
  "10701": { "Cosmetologist": 5 },
  "43154": { "Cosmetologist": 1 },
  "29018": { "Cosmetologist": 2 },
  "28280": { "Cosmetologist": 1 },
  "92256": { "Cosmetologist": 1 },
  "34101": { "Cosmetologist": 18 },
  "41240": { "Cosmetologist": 2 },
  "23222": { "Cosmetologist": 1 },
  "48089": { "Cosmetologist": 120, "Barber": 7 },
  "75801": { "Cosmetologist": 117, "Barber": 12 },
  "31708": { "Cosmetologist": 1 },
  "37917": { "Cosmetologist": 2, "Barber": 23 },
  "29488": { "Cosmetologist": 1 },
  "29961": { "Cosmetologist": 1 },
  "37691": { "Cosmetologist": 2 },
  "21102": { "Cosmetologist": 2 },
  "11219": { "Cosmetologist": 1 },
  "53143": { "Cosmetologist": 1 },
  "14604": { "Cosmetologist": 1 },
  "21090": { "Cosmetologist": 2 },
  "11003": { "Cosmetologist": 7 },
  "40108": { "Cosmetologist": 1 },
  "96015": { "Cosmetologist": 1 },
  "28755": { "Cosmetologist": 2 },
  "28652": { "Cosmetologist": 1 },
  "24165": { "Cosmetologist": 1 },
  "83455": { "Cosmetologist": 2 },
  "92284": { "Cosmetologist": 2 },
  "44667": { "Cosmetologist": 1 },
  "77042": { "Cosmetologist": 211, "Barber": 26 },
  "90302": { "Cosmetologist": 3 },
  "11572": { "Cosmetologist": 7 },
  "27815": { "Barber": 1 },
  "27413": { "Barber": 1 },
  "28477": { "Barber": 1 },
  "17316": { "Barber": 3, "Cosmetologist": 10 },
  "17325": { "Barber": 5, "Cosmetologist": 33 },
  "17350": { "Barber": 6, "Cosmetologist": 19 },
  "17304": { "Barber": 3, "Cosmetologist": 1 },
  "17307": { "Barber": 1, "Cosmetologist": 6 },
  "17372": { "Barber": 2, "Cosmetologist": 5 },
  "17340": { "Barber": 3, "Cosmetologist": 8 },
  "15110": { "Barber": 1 },
  "15139": { "Barber": 2, "Cosmetologist": 5 },
  "15205": { "Barber": 1, "Cosmetologist": 6 },
  "15108": { "Barber": 5, "Cosmetologist": 11 },
  "15084": { "Barber": 2, "Cosmetologist": 4 },
  "15202": { "Barber": 2, "Cosmetologist": 7 },
  "15137": { "Barber": 3, "Cosmetologist": 2 },
  "15214": { "Barber": 2, "Cosmetologist": 3 },
  "16046": { "Barber": 1, "Cosmetologist": 13 },
  "15218": { "Barber": 4, "Cosmetologist": 2 },
  "15136": { "Barber": 3, "Cosmetologist": 4 },
  "15044": { "Barber": 2, "Cosmetologist": 4 },
  "15146": { "Barber": 2, "Cosmetologist": 5 },
  "15229": { "Barber": 1, "Cosmetologist": 5 },
  "15025": { "Barber": 1, "Cosmetologist": 1 },
  "15147": { "Barber": 2, "Cosmetologist": 7 },
  "15213": { "Barber": 1 },
  "15208": { "Barber": 1, "Cosmetologist": 1 },
  "15212": { "Barber": 2, "Cosmetologist": 3 },
  "15057": { "Barber": 2, "Cosmetologist": 2 },
  "15209": { "Barber": 1, "Cosmetologist": 2 },
  "15222": { "Barber": 1, "Cosmetologist": 2 },
  "15211": { "Barber": 2, "Cosmetologist": 3 },
  "15131": { "Barber": 1, "Cosmetologist": 3 },
  "15024": { "Barber": 1 },
  "15145": { "Barber": 1, "Cosmetologist": 2 },
  "16222": { "Barber": 1, "Cosmetologist": 1 },
  "16226": { "Barber": 4, "Cosmetologist": 1 },
  "16049": { "Barber": 2, "Cosmetologist": 8 },
  "16201": { "Barber": 1, "Cosmetologist": 9 },
  "15690": { "Barber": 3, "Cosmetologist": 3 },
  "16262": { "Barber": 1, "Cosmetologist": 4 },
  "15074": { "Barber": 3, "Cosmetologist": 3 },
  "16115": { "Barber": 1, "Cosmetologist": 2 },
  "15061": { "Barber": 5, "Cosmetologist": 3 },
  "15026": { "Barber": 1, "Cosmetologist": 1 },
  "15066": { "Barber": 2, "Cosmetologist": 3 },
  "16117": { "Barber": 4, "Cosmetologist": 18 },
  "15059": { "Barber": 1, "Cosmetologist": 2 },
  "15005": { "Barber": 4, "Cosmetologist": 4 },
  "15050": { "Barber": 1, "Cosmetologist": 1 },
  "15009": { "Barber": 2, "Cosmetologist": 6 },
  "15010": { "Barber": 3, "Cosmetologist": 8 },
  "15042": { "Barber": 1, "Cosmetologist": 2 },
  "17602": { "Barber": 20, "Cosmetologist": 10 },
  "15533": { "Barber": 1, "Cosmetologist": 5 },
  "15522": { "Barber": 2, "Cosmetologist": 35 },
  "15537": { "Barber": 1, "Cosmetologist": 16 },
  "16664": { "Barber": 1, "Cosmetologist": 11 },
  "19601": { "Barber": 20, "Cosmetologist": 5 },
  "19604": { "Barber": 17, "Cosmetologist": 5 },
  "19607": { "Barber": 7, "Cosmetologist": 9 },
  "19610": { "Barber": 2, "Cosmetologist": 4 },
  "19602": { "Barber": 8, "Cosmetologist": 5 },
  "19530": { "Barber": 2, "Cosmetologist": 7 },
  "19562": { "Barber": 1 },
  "19609": { "Barber": 1, "Cosmetologist": 4 },
  "19551": { "Barber": 1, "Cosmetologist": 1 },
  "19611": { "Barber": 3, "Cosmetologist": 8 },
  "16648": { "Barber": 3, "Cosmetologist": 9 },
  "16617": { "Barber": 3 },
  "16686": { "Barber": 5, "Cosmetologist": 7 },
  "16635": { "Barber": 3, "Cosmetologist": 7 },
  "16637": { "Barber": 1, "Cosmetologist": 1 },
  "18840": { "Barber": 5, "Cosmetologist": 18 },
  "17724": { "Barber": 1, "Cosmetologist": 13 },
  "18850": { "Barber": 1, "Cosmetologist": 8 },
  "18810": { "Barber": 1, "Cosmetologist": 9 },
  "19020": { "Barber": 8, "Cosmetologist": 20 },
  "19053": { "Barber": 8, "Cosmetologist": 40 },
  "18917": { "Barber": 1, "Cosmetologist": 1 },
  "18951": { "Barber": 3, "Cosmetologist": 16 },
  "18925": { "Barber": 1, "Cosmetologist": 2 },
  "18914": { "Barber": 5, "Cosmetologist": 7 },
  "19067": { "Barber": 2, "Cosmetologist": 10 },
  "19040": { "Barber": 7, "Cosmetologist": 8 },
  "18073": { "Barber": 3, "Cosmetologist": 1 },
  "18954": { "Barber": 1, "Cosmetologist": 3 },
  "18923": { "Barber": 1, "Cosmetologist": 2 },
  "16002": { "Barber": 2, "Cosmetologist": 13 },
  "16001": { "Barber": 6, "Cosmetologist": 31 },
  "16023": { "Barber": 1, "Cosmetologist": 2 },
  "16057": { "Barber": 2, "Cosmetologist": 5 },
  "16066": { "Barber": 2, "Cosmetologist": 15 },
  "16059": { "Barber": 1, "Cosmetologist": 12 },
  "16025": { "Barber": 1, "Cosmetologist": 2 },
  "16640": { "Barber": 1 },
  "15901": { "Barber": 4, "Cosmetologist": 2 },
  "15902": { "Barber": 3, "Cosmetologist": 9 },
  "16639": { "Barber": 1, "Cosmetologist": 3 },
  "16630": { "Barber": 2, "Cosmetologist": 2 },
  "16641": { "Barber": 1, "Cosmetologist": 5 },
  "15940": { "Barber": 1, "Cosmetologist": 1 },
  "15921": { "Barber": 1 },
  "15931": { "Barber": 2, "Cosmetologist": 1 },
  "15737": { "Barber": 1 },
  "15946": { "Barber": 1, "Cosmetologist": 3 },
  "18235": { "Barber": 3, "Cosmetologist": 36 },
  "18071": { "Barber": 3, "Cosmetologist": 24 },
  "18232": { "Barber": 2 },
  "18210": { "Barber": 3, "Cosmetologist": 13 },
  "18244": { "Barber": 1, "Cosmetologist": 1 },
  "18253": { "Barber": 1 },
  "16823": { "Barber": 4, "Cosmetologist": 32 },
  "16874": { "Barber": 1, "Cosmetologist": 2 },
  "16877": { "Barber": 1, "Cosmetologist": 4 },
  "16866": { "Barber": 3, "Cosmetologist": 11 },
  "16829": { "Barber": 1, "Cosmetologist": 1 },
  "16875": { "Barber": 1, "Cosmetologist": 3 },
  "16844": { "Barber": 1, "Cosmetologist": 3 },
  "16865": { "Barber": 1, "Cosmetologist": 2 },
  "19475": { "Barber": 2, "Cosmetologist": 4 },
  "19301": { "Barber": 1, "Cosmetologist": 2 },
  "19320": { "Barber": 21, "Cosmetologist": 20 },
  "19380": { "Barber": 5, "Cosmetologist": 13 },
  "19365": { "Barber": 1, "Cosmetologist": 3 },
  "19390": { "Barber": 4, "Cosmetologist": 6 },
  "19350": { "Barber": 1, "Cosmetologist": 1 },
  "19363": { "Barber": 5, "Cosmetologist": 2 },
  "19341": { "Barber": 2, "Cosmetologist": 5 },
  "19355": { "Barber": 3, "Cosmetologist": 7 },
  "19425": { "Barber": 2, "Cosmetologist": 2 },
  "19318": { "Barber": 1 },
  "19312": { "Barber": 1, "Cosmetologist": 1 },
  "19540": { "Barber": 1, "Cosmetologist": 6 },
  "19372": { "Barber": 1 },
  "16214": { "Barber": 1, "Cosmetologist": 10 },
  "16830": { "Barber": 2, "Cosmetologist": 7 },
  "16680": { "Barber": 1 },
  "16627": { "Barber": 1, "Cosmetologist": 3 },
  "16661": { "Barber": 1 },
  "16833": { "Barber": 1, "Cosmetologist": 1 },
  "16651": { "Barber": 1, "Cosmetologist": 3 },
  "16822": { "Barber": 1, "Cosmetologist": 9 },
  "17745": { "Barber": 2, "Cosmetologist": 58 },
  "17815": { "Barber": 3, "Cosmetologist": 45 },
  "18603": { "Barber": 1, "Cosmetologist": 30 },
  "17846": { "Barber": 1, "Cosmetologist": 14 },
  "16354": { "Barber": 1, "Cosmetologist": 11 },
  "19135": { "Barber": 1, "Cosmetologist": 3 },
  "16438": { "Barber": 1, "Cosmetologist": 9 },
  "17055": { "Barber": 19, "Cosmetologist": 14 },
  "17025": { "Barber": 7, "Cosmetologist": 9 },
  "17070": { "Barber": 10, "Cosmetologist": 4 },
  "17011": { "Barber": 14, "Cosmetologist": 9 },
  "17109": { "Barber": 6, "Cosmetologist": 19 },
  "17931": { "Barber": 3 },
  "17093": { "Barber": 2 },
  "17022": { "Barber": 4, "Cosmetologist": 11 },
  "17112": { "Barber": 7, "Cosmetologist": 34 },
  "17103": { "Barber": 7, "Cosmetologist": 4 },
  "17110": { "Barber": 4, "Cosmetologist": 18 },
  "17113": { "Barber": 3, "Cosmetologist": 8 },
  "17048": { "Barber": 1, "Cosmetologist": 2 },
  "19023": { "Barber": 4, "Cosmetologist": 4 },
  "19079": { "Barber": 3, "Cosmetologist": 3 },
  "19083": { "Barber": 2, "Cosmetologist": 13 },
  "19050": { "Barber": 6, "Cosmetologist": 5 },
  "19311": { "Barber": 1, "Cosmetologist": 1 },
  "19082": { "Barber": 3, "Cosmetologist": 12 },
  "19013": { "Barber": 7, "Cosmetologist": 6 },
  "19014": { "Barber": 1, "Cosmetologist": 4 },
  "19064": { "Barber": 1, "Cosmetologist": 6 },
  "19063": { "Barber": 1, "Cosmetologist": 8 },
  "19070": { "Barber": 1, "Cosmetologist": 4 },
  "19078": { "Barber": 1, "Cosmetologist": 2 },
  "19016": { "Barber": 1 },
  "19061": { "Barber": 1, "Cosmetologist": 4 },
  "16503": { "Barber": 2, "Cosmetologist": 1 },
  "16412": { "Barber": 1, "Cosmetologist": 1 },
  "16505": { "Barber": 1, "Cosmetologist": 4 },
  "16508": { "Barber": 1, "Cosmetologist": 1 },
  "16509": { "Barber": 1, "Cosmetologist": 8 },
  "16401": { "Barber": 1 },
  "16511": { "Barber": 1, "Cosmetologist": 1 },
  "16504": { "Barber": 1, "Cosmetologist": 1 },
  "15401": { "Barber": 12, "Cosmetologist": 13 },
  "15461": { "Barber": 1, "Cosmetologist": 2 },
  "15473": { "Barber": 2 },
  "15431": { "Barber": 1, "Cosmetologist": 1 },
  "15425": { "Barber": 6, "Cosmetologist": 12 },
  "15417": { "Barber": 2, "Cosmetologist": 6 },
  "15445": { "Barber": 1, "Cosmetologist": 1 },
  "15478": { "Barber": 3, "Cosmetologist": 1 },
  "15451": { "Barber": 1 },
  "15436": { "Barber": 1, "Cosmetologist": 1 },
  "15415": { "Barber": 1 },
  "15488": { "Barber": 1 },
  "15464": { "Barber": 1 },
  "15467": { "Barber": 1 },
  "17201": { "Barber": 13, "Cosmetologist": 25 },
  "17222": { "Barber": 4, "Cosmetologist": 10 },
  "17268": { "Barber": 5, "Cosmetologist": 9 },
  "17202": { "Barber": 4, "Cosmetologist": 20 },
  "17246": { "Barber": 1 },
  "17236": { "Barber": 3, "Cosmetologist": 4 },
  "17244": { "Barber": 1, "Cosmetologist": 1 },
  "17233": { "Barber": 2, "Cosmetologist": 28 },
  "15370": { "Barber": 3, "Cosmetologist": 30 },
  "15315": { "Barber": 1, "Cosmetologist": 2 },
  "16652": { "Barber": 6, "Cosmetologist": 34 },
  "17060": { "Barber": 1 },
  "16611": { "Barber": 1, "Cosmetologist": 5 },
  "17213": { "Barber": 1 },
  "17243": { "Barber": 1, "Cosmetologist": 3 },
  "15681": { "Barber": 3, "Cosmetologist": 3 },
  "15944": { "Barber": 1, "Cosmetologist": 1 },
  "15748": { "Barber": 1, "Cosmetologist": 4 },
  "15904": { "Barber": 1, "Cosmetologist": 6 },
  "15767": { "Barber": 4, "Cosmetologist": 22 },
  "15851": { "Barber": 1, "Cosmetologist": 19 },
  "15825": { "Barber": 2, "Cosmetologist": 18 },
  "15865": { "Barber": 1, "Cosmetologist": 3 },
  "17059": { "Barber": 1, "Cosmetologist": 28 },
  "17094": { "Barber": 1, "Cosmetologist": 16 },
  "18641": { "Barber": 6 },
  "18509": { "Barber": 3, "Cosmetologist": 14 },
  "18434": { "Barber": 2, "Cosmetologist": 6 },
  "18505": { "Barber": 4, "Cosmetologist": 16 },
  "18411": { "Barber": 5, "Cosmetologist": 19 },
  "18407": { "Barber": 4, "Cosmetologist": 13 },
  "18512": { "Barber": 2, "Cosmetologist": 7 },
  "18510": { "Barber": 3, "Cosmetologist": 9 },
  "18433": { "Barber": 1, "Cosmetologist": 8 },
  "18444": { "Barber": 5, "Cosmetologist": 13 },
  "18519": { "Barber": 1, "Cosmetologist": 4 },
  "18508": { "Barber": 3, "Cosmetologist": 13 },
  "18414": { "Barber": 3, "Cosmetologist": 3 },
  "18504": { "Barber": 1, "Cosmetologist": 15 },
  "18403": { "Barber": 2, "Cosmetologist": 13 },
  "17545": { "Barber": 3, "Cosmetologist": 8 },
  "17522": { "Barber": 1, "Cosmetologist": 9 },
  "17562": { "Barber": 1, "Cosmetologist": 1 },
  "17554": { "Barber": 2, "Cosmetologist": 2 },
  "17604": { "Barber": 1 },
  "17540": { "Barber": 3, "Cosmetologist": 3 },
  "17550": { "Barber": 1 },
  "17516": { "Barber": 1, "Cosmetologist": 2 },
  "17557": { "Barber": 1, "Cosmetologist": 2 },
  "17551": { "Barber": 1, "Cosmetologist": 1 },
  "17547": { "Barber": 3, "Cosmetologist": 4 },
  "16101": { "Barber": 5, "Cosmetologist": 15 },
  "16141": { "Barber": 1, "Cosmetologist": 1 },
  "16102": { "Barber": 1, "Cosmetologist": 3 },
  "17046": { "Barber": 15, "Cosmetologist": 34 },
  "17077": { "Barber": 1, "Cosmetologist": 1 },
  "17073": { "Barber": 1, "Cosmetologist": 2 },
  "17038": { "Barber": 1, "Cosmetologist": 5 },
  "17087": { "Barber": 1, "Cosmetologist": 1 },
  "18109": { "Barber": 19, "Cosmetologist": 7 },
  "18105": { "Barber": 1 },
  "18049": { "Barber": 1, "Cosmetologist": 10 },
  "18062": { "Barber": 3, "Cosmetologist": 7 },
  "18106": { "Barber": 1, "Cosmetologist": 2 },
  "18018": { "Barber": 12, "Cosmetologist": 27 },
  "18031": { "Barber": 3, "Cosmetologist": 2 },
  "18101": { "Barber": 5, "Cosmetologist": 1 },
  "18052": { "Barber": 3, "Cosmetologist": 14 },
  "18080": { "Barber": 1, "Cosmetologist": 4 },
  "18032": { "Barber": 1, "Cosmetologist": 1 },
  "18705": { "Barber": 6, "Cosmetologist": 8 },
  "18202": { "Barber": 4, "Cosmetologist": 2 },
  "18612": { "Barber": 3, "Cosmetologist": 3 },
  "18634": { "Barber": 1, "Cosmetologist": 5 },
  "18219": { "Barber": 1 },
  "18643": { "Barber": 1, "Cosmetologist": 4 },
  "18651": { "Barber": 1, "Cosmetologist": 2 },
  "18706": { "Barber": 4, "Cosmetologist": 7 },
  "18661": { "Barber": 1, "Cosmetologist": 5 },
  "18642": { "Barber": 1 },
  "17948": { "Barber": 2, "Cosmetologist": 2 },
  "18709": { "Barber": 1, "Cosmetologist": 2 },
  "17740": { "Barber": 1, "Cosmetologist": 10 },
  "17754": { "Barber": 7, "Cosmetologist": 11 },
  "17728": { "Barber": 1, "Cosmetologist": 4 },
  "17737": { "Barber": 1, "Cosmetologist": 14 },
  "17752": { "Barber": 1, "Cosmetologist": 6 },
  "16701": { "Barber": 2, "Cosmetologist": 38 },
  "16137": { "Barber": 2, "Cosmetologist": 5 },
  "16150": { "Barber": 3, "Cosmetologist": 2 },
  "16125": { "Barber": 3, "Cosmetologist": 6 },
  "16121": { "Barber": 3, "Cosmetologist": 2 },
  "16146": { "Barber": 1 },
  "19134": { "Barber": 1, "Cosmetologist": 1 },
  "17044": { "Barber": 4, "Cosmetologist": 31 },
  "17099": { "Barber": 1, "Cosmetologist": 4 },
  "18355": { "Barber": 2, "Cosmetologist": 1 },
  "18610": { "Barber": 2, "Cosmetologist": 5 },
  "18302": { "Barber": 6, "Cosmetologist": 7 },
  "18326": { "Barber": 1, "Cosmetologist": 2 },
  "18334": { "Barber": 2, "Cosmetologist": 3 },
  "18322": { "Barber": 1, "Cosmetologist": 1 },
  "18424": { "Barber": 2, "Cosmetologist": 11 },
  "18372": { "Barber": 1, "Cosmetologist": 3 },
  "19446": { "Barber": 11, "Cosmetologist": 4 },
  "18969": { "Barber": 3, "Cosmetologist": 3 },
  "19525": { "Barber": 2, "Cosmetologist": 4 },
  "19454": { "Barber": 3, "Cosmetologist": 4 },
  "18964": { "Barber": 5, "Cosmetologist": 4 },
  "19038": { "Barber": 5, "Cosmetologist": 4 },
  "19010": { "Barber": 1 },
  "19426": { "Barber": 7, "Cosmetologist": 3 },
  "19012": { "Barber": 2 },
  "19001": { "Barber": 4, "Cosmetologist": 1 },
  "19438": { "Barber": 6, "Cosmetologist": 4 },
  "19422": { "Barber": 2, "Cosmetologist": 2 },
  "19468": { "Barber": 4, "Cosmetologist": 4 },
  "18041": { "Barber": 1, "Cosmetologist": 2 },
  "19002": { "Barber": 1, "Cosmetologist": 4 },
  "19041": { "Barber": 2 },
  "19095": { "Barber": 2 },
  "19464": { "Barber": 8, "Cosmetologist": 6 },
  "19428": { "Barber": 3, "Cosmetologist": 5 },
  "19004": { "Barber": 1 },
  "19444": { "Barber": 1 },
  "18076": { "Barber": 3 },
  "18054": { "Barber": 1 },
  "19075": { "Barber": 2, "Cosmetologist": 1 },
  "19405": { "Barber": 1, "Cosmetologist": 1 },
  "19003": { "Barber": 1 },
  "19465": { "Barber": 1, "Cosmetologist": 4 },
  "19440": { "Barber": 3, "Cosmetologist": 1 },
  "19006": { "Barber": 3, "Cosmetologist": 5 },
  "17821": { "Barber": 2, "Cosmetologist": 77 },
  "18042": { "Barber": 27, "Cosmetologist": 15 },
  "18088": { "Barber": 3, "Cosmetologist": 4 },
  "18014": { "Barber": 2, "Cosmetologist": 4 },
  "18038": { "Barber": 1 },
  "17834": { "Barber": 3 },
  "17866": { "Barber": 3, "Cosmetologist": 19 },
  "17830": { "Barber": 2, "Cosmetologist": 1 },
  "17777": { "Barber": 2, "Cosmetologist": 8 },
  "17857": { "Barber": 2, "Cosmetologist": 8 },
  "17881": { "Barber": 1, "Cosmetologist": 2 },
  "17772": { "Barber": 2, "Cosmetologist": 1 },
  "17872": { "Barber": 2, "Cosmetologist": 12 },
  "17851": { "Barber": 2, "Cosmetologist": 5 },
  "17847": { "Barber": 3, "Cosmetologist": 16 },
  "17876": { "Barber": 1, "Cosmetologist": 8 },
  "17074": { "Barber": 3, "Cosmetologist": 19 },
  "17006": { "Barber": 1, "Cosmetologist": 2 },
  "17068": { "Barber": 2, "Cosmetologist": 17 },
  "17045": { "Barber": 1, "Cosmetologist": 6 },
  "17023": { "Barber": 1, "Cosmetologist": 2 },
  "15068": { "Barber": 1, "Cosmetologist": 11 },
  "19138": { "Barber": 1, "Cosmetologist": 6 },
  "18324": { "Barber": 2, "Cosmetologist": 31 },
  "18426": { "Barber": 1, "Cosmetologist": 16 },
  "18428": { "Barber": 2, "Cosmetologist": 32 },
  "18336": { "Barber": 1, "Cosmetologist": 11 },
  "18644": { "Barber": 1, "Cosmetologist": 3 },
  "18464": { "Barber": 1, "Cosmetologist": 7 },
  "16746": { "Barber": 1, "Cosmetologist": 3 },
  "18252": { "Barber": 3, "Cosmetologist": 2 },
  "17976": { "Barber": 3, "Cosmetologist": 5 },
  "17921": { "Barber": 2, "Cosmetologist": 5 },
  "17954": { "Barber": 2, "Cosmetologist": 1 },
  "17964": { "Barber": 1 },
  "18237": { "Barber": 1, "Cosmetologist": 4 },
  "17922": { "Barber": 1, "Cosmetologist": 2 },
  "17963": { "Barber": 1, "Cosmetologist": 4 },
  "17972": { "Barber": 1, "Cosmetologist": 7 },
  "17938": { "Barber": 1, "Cosmetologist": 1 },
  "17970": { "Barber": 1, "Cosmetologist": 2 },
  "17951": { "Barber": 2 },
  "17985": { "Barber": 1 },
  "17936": { "Barber": 1 },
  "17932": { "Barber": 1 },
  "18214": { "Barber": 1, "Cosmetologist": 3 },
  "17980": { "Barber": 1, "Cosmetologist": 4 },
  "17812": { "Barber": 1, "Cosmetologist": 3 },
  "17827": { "Barber": 1, "Cosmetologist": 4 },
  "17813": { "Barber": 1, "Cosmetologist": 5 },
  "15928": { "Barber": 1, "Cosmetologist": 2 },
  "15530": { "Barber": 1, "Cosmetologist": 2 },
  "15558": { "Barber": 1, "Cosmetologist": 2 },
  "15555": { "Barber": 1 },
  "15510": { "Barber": 1 },
  "17339": { "Barber": 3, "Cosmetologist": 2 },
  "15963": { "Barber": 1, "Cosmetologist": 8 },
  "15501": { "Barber": 1, "Cosmetologist": 6 },
  "18614": { "Barber": 1, "Cosmetologist": 11 },
  "18626": { "Barber": 1, "Cosmetologist": 3 },
  "18834": { "Barber": 1, "Cosmetologist": 9 },
  "18801": { "Barber": 1, "Cosmetologist": 23 },
  "18847": { "Barber": 1, "Cosmetologist": 6 },
  "16928": { "Barber": 2, "Cosmetologist": 1 },
  "16933": { "Barber": 1, "Cosmetologist": 18 },
  "17837": { "Barber": 2, "Cosmetologist": 28 },
  "17845": { "Barber": 1, "Cosmetologist": 9 },
  "17856": { "Barber": 1, "Cosmetologist": 7 },
  "16346": { "Barber": 1, "Cosmetologist": 4 },
  "16374": { "Barber": 1, "Cosmetologist": 1 },
  "16365": { "Barber": 1, "Cosmetologist": 46 },
  "15317": { "Barber": 9, "Cosmetologist": 13 },
  "15301": { "Barber": 3, "Cosmetologist": 8 },
  "15477": { "Barber": 1, "Cosmetologist": 1 },
  "15367": { "Barber": 1, "Cosmetologist": 5 },
  "15314": { "Barber": 1, "Cosmetologist": 1 },
  "15432": { "Barber": 1 },
  "15033": { "Barber": 2, "Cosmetologist": 1 },
  "15063": { "Barber": 1, "Cosmetologist": 3 },
  "15330": { "Barber": 1, "Cosmetologist": 1 },
  "15342": { "Barber": 1, "Cosmetologist": 1 },
  "15021": { "Barber": 1 },
  "15320": { "Barber": 1, "Cosmetologist": 14 },
  "15419": { "Barber": 1 },
  "18445": { "Barber": 1, "Cosmetologist": 3 },
  "18427": { "Barber": 1, "Cosmetologist": 4 },
  "18453": { "Barber": 1, "Cosmetologist": 1 },
  "18405": { "Barber": 1, "Cosmetologist": 12 },
  "15692": { "Barber": 1 },
  "15644": { "Barber": 1, "Cosmetologist": 2 },
  "15697": { "Barber": 1, "Cosmetologist": 2 },
  "18657": { "Barber": 1, "Cosmetologist": 54 },
  "17401": { "Barber": 6, "Cosmetologist": 5 },
  "17406": { "Barber": 5, "Cosmetologist": 12 },
  "17408": { "Barber": 8, "Cosmetologist": 10 },
  "17403": { "Barber": 13, "Cosmetologist": 12 },
  "17347": { "Barber": 1, "Cosmetologist": 5 },
  "17366": { "Barber": 2, "Cosmetologist": 2 },
  "17368": { "Barber": 1, "Cosmetologist": 2 },
  "17362": { "Barber": 4, "Cosmetologist": 8 },
  "17370": { "Barber": 2, "Cosmetologist": 3 },
  "17356": { "Barber": 3, "Cosmetologist": 10 },
  "17319": { "Barber": 3, "Cosmetologist": 3 },
  "17019": { "Barber": 2, "Cosmetologist": 10 },
  "17361": { "Barber": 1 },
  "17363": { "Barber": 1, "Cosmetologist": 1 },
  "17364": { "Barber": 1, "Cosmetologist": 2 },
  "17322": { "Barber": 1, "Cosmetologist": 6 },
  "17324": { "Cosmetologist": 6 },
  "17353": { "Cosmetologist": 6 },
  "17301": { "Cosmetologist": 2 },
  "17303": { "Cosmetologist": 1 },
  "17344": { "Cosmetologist": 5 },
  "17241": { "Cosmetologist": 5 },
  "17320": { "Cosmetologist": 3 },
  "15126": { "Cosmetologist": 1 },
  "15106": { "Cosmetologist": 2 },
  "15234": { "Cosmetologist": 2 },
  "15031": { "Cosmetologist": 1 },
  "15140": { "Cosmetologist": 1 },
  "15243": { "Cosmetologist": 3 },
  "15116": { "Cosmetologist": 7 },
  "15133": { "Cosmetologist": 1 },
  "16259": { "Cosmetologist": 5 },
  "15736": { "Cosmetologist": 1 },
  "16229": { "Cosmetologist": 3 },
  "16238": { "Cosmetologist": 2 },
  "15144": { "Cosmetologist": 1 },
  "15052": { "Cosmetologist": 2 },
  "16123": { "Cosmetologist": 2 },
  "16678": { "Cosmetologist": 6 },
  "16670": { "Cosmetologist": 3 },
  "16655": { "Cosmetologist": 9 },
  "15554": { "Cosmetologist": 7 },
  "16667": { "Cosmetologist": 6 },
  "15550": { "Cosmetologist": 5 },
  "16679": { "Cosmetologist": 3 },
  "15545": { "Cosmetologist": 4 },
  "16625": { "Cosmetologist": 10 },
  "15559": { "Cosmetologist": 7 },
  "16695": { "Cosmetologist": 2 },
  "15521": { "Cosmetologist": 7 },
  "16650": { "Cosmetologist": 6 },
  "15534": { "Cosmetologist": 2 },
  "15539": { "Cosmetologist": 1 },
  "16633": { "Cosmetologist": 1 },
  "19555": { "Cosmetologist": 4 },
  "19510": { "Cosmetologist": 3 },
  "19533": { "Cosmetologist": 2 },
  "19512": { "Cosmetologist": 6 },
  "19504": { "Cosmetologist": 3 },
  "19522": { "Cosmetologist": 3 },
  "19560": { "Cosmetologist": 4 },
  "19565": { "Cosmetologist": 3 },
  "19608": { "Cosmetologist": 7 },
  "19518": { "Cosmetologist": 7 },
  "19550": { "Cosmetologist": 1 },
  "19506": { "Cosmetologist": 5 },
  "19539": { "Cosmetologist": 2 },
  "19543": { "Cosmetologist": 1 },
  "19567": { "Cosmetologist": 2 },
  "19508": { "Cosmetologist": 4 },
  "19541": { "Cosmetologist": 2 },
  "19529": { "Cosmetologist": 2 },
  "19612": { "Cosmetologist": 1 },
  "19547": { "Cosmetologist": 1 },
  "16693": { "Cosmetologist": 4 },
  "16662": { "Cosmetologist": 3 },
  "16673": { "Cosmetologist": 1 },
  "16914": { "Cosmetologist": 8 },
  "18854": { "Cosmetologist": 3 },
  "18829": { "Cosmetologist": 1 },
  "16926": { "Cosmetologist": 3 },
  "18833": { "Cosmetologist": 6 },
  "18848": { "Cosmetologist": 18 },
  "18853": { "Cosmetologist": 20 },
  "18851": { "Cosmetologist": 6 },
  "16925": { "Cosmetologist": 6 },
  "18846": { "Cosmetologist": 4 },
  "18845": { "Cosmetologist": 1 },
  "18832": { "Cosmetologist": 4 },
  "18831": { "Cosmetologist": 3 },
  "19055": { "Cosmetologist": 9 },
  "18901": { "Cosmetologist": 15 },
  "19054": { "Cosmetologist": 8 },
  "18940": { "Cosmetologist": 11 },
  "18942": { "Cosmetologist": 3 },
  "18930": { "Cosmetologist": 1 },
  "18938": { "Cosmetologist": 5 },
  "18977": { "Cosmetologist": 1 },
  "18962": { "Cosmetologist": 1 },
  "18077": { "Cosmetologist": 2 },
  "18929": { "Cosmetologist": 2 },
  "18947": { "Cosmetologist": 1 },
  "16056": { "Cosmetologist": 2 },
  "16034": { "Cosmetologist": 3 },
  "16055": { "Cosmetologist": 11 },
  "15065": { "Cosmetologist": 1 },
  "16038": { "Cosmetologist": 2 },
  "16033": { "Cosmetologist": 9 },
  "16063": { "Cosmetologist": 4 },
  "16041": { "Cosmetologist": 2 },
  "16061": { "Cosmetologist": 3 },
  "16052": { "Cosmetologist": 1 },
  "16020": { "Cosmetologist": 3 },
  "16024": { "Cosmetologist": 1 },
  "16037": { "Cosmetologist": 2 },
  "16029": { "Cosmetologist": 2 },
  "15905": { "Cosmetologist": 15 },
  "15722": { "Cosmetologist": 4 },
  "16646": { "Cosmetologist": 1 },
  "15714": { "Cosmetologist": 3 },
  "15762": { "Cosmetologist": 4 },
  "16636": { "Cosmetologist": 1 },
  "15927": { "Cosmetologist": 1 },
  "15909": { "Cosmetologist": 1 },
  "15956": { "Cosmetologist": 2 },
  "15938": { "Cosmetologist": 3 },
  "15943": { "Cosmetologist": 1 },
  "15958": { "Cosmetologist": 1 },
  "16613": { "Cosmetologist": 1 },
  "15955": { "Cosmetologist": 1 },
  "16668": { "Cosmetologist": 1 },
  "15834": { "Cosmetologist": 23 },
  "17842": { "Cosmetologist": 43 },
  "16720": { "Cosmetologist": 3 },
  "18250": { "Cosmetologist": 4 },
  "18255": { "Cosmetologist": 6 },
  "18230": { "Cosmetologist": 1 },
  "18240": { "Cosmetologist": 6 },
  "16854": { "Cosmetologist": 1 },
  "15014": { "Cosmetologist": 1 },
  "16856": { "Cosmetologist": 1 },
  "16853": { "Cosmetologist": 2 },
  "16841": { "Cosmetologist": 6 },
  "16828": { "Cosmetologist": 6 },
  "16862": { "Cosmetologist": 1 },
  "16852": { "Cosmetologist": 2 },
  "16827": { "Cosmetologist": 2 },
  "16868": { "Cosmetologist": 3 },
  "16820": { "Cosmetologist": 1 },
  "16872": { "Cosmetologist": 1 },
  "19344": { "Cosmetologist": 4 },
  "19317": { "Cosmetologist": 4 },
  "19352": { "Cosmetologist": 6 },
  "19362": { "Cosmetologist": 3 },
  "19333": { "Cosmetologist": 3 },
  "19343": { "Cosmetologist": 5 },
  "19374": { "Cosmetologist": 1 },
  "17509": { "Cosmetologist": 1 },
  "19369": { "Cosmetologist": 1 },
  "19073": { "Cosmetologist": 2 },
  "19132": { "Cosmetologist": 1 },
  "19520": { "Cosmetologist": 3 },
  "16255": { "Cosmetologist": 5 },
  "16254": { "Cosmetologist": 5 },
  "16235": { "Cosmetologist": 5 },
  "16242": { "Cosmetologist": 13 },
  "16248": { "Cosmetologist": 9 },
  "16230": { "Cosmetologist": 3 },
  "16028": { "Cosmetologist": 3 },
  "16232": { "Cosmetologist": 7 },
  "16233": { "Cosmetologist": 2 },
  "16258": { "Cosmetologist": 2 },
  "16364": { "Cosmetologist": 3 },
  "16022": { "Cosmetologist": 1 },
  "16326": { "Cosmetologist": 2 },
  "16217": { "Cosmetologist": 2 },
  "16224": { "Cosmetologist": 3 },
  "16240": { "Cosmetologist": 5 },
  "16373": { "Cosmetologist": 4 },
  "16054": { "Cosmetologist": 3 },
  "15864": { "Cosmetologist": 5 },
  "16666": { "Cosmetologist": 2 },
  "16656": { "Cosmetologist": 1 },
  "16863": { "Cosmetologist": 1 },
  "16858": { "Cosmetologist": 7 },
  "15757": { "Cosmetologist": 1 },
  "16878": { "Cosmetologist": 2 },
  "15866": { "Cosmetologist": 1 },
  "15856": { "Cosmetologist": 2 },
  "16881": { "Cosmetologist": 1 },
  "16847": { "Cosmetologist": 1 },
  "16843": { "Cosmetologist": 1 },
  "15849": { "Cosmetologist": 1 },
  "17751": { "Cosmetologist": 42 },
  "17721": { "Cosmetologist": 5 },
  "17747": { "Cosmetologist": 16 },
  "17764": { "Cosmetologist": 1 },
  "17760": { "Cosmetologist": 1 },
  "18631": { "Cosmetologist": 1 },
  "17814": { "Cosmetologist": 7 },
  "17878": { "Cosmetologist": 2 },
  "18660": { "Cosmetologist": 3 },
  "16433": { "Cosmetologist": 5 },
  "16327": { "Cosmetologist": 4 },
  "16134": { "Cosmetologist": 4 },
  "16360": { "Cosmetologist": 2 },
  "16314": { "Cosmetologist": 10 },
  "16404": { "Cosmetologist": 5 },
  "16424": { "Cosmetologist": 1 },
  "16403": { "Cosmetologist": 13 },
  "16110": { "Cosmetologist": 1 },
  "16435": { "Cosmetologist": 1 },
  "16434": { "Cosmetologist": 3 },
  "16406": { "Cosmetologist": 1 },
  "17018": { "Cosmetologist": 5 },
  "17057": { "Cosmetologist": 22 },
  "17005": { "Cosmetologist": 1 },
  "17983": { "Cosmetologist": 2 },
  "17034": { "Cosmetologist": 3 },
  "17101": { "Cosmetologist": 1 },
  "17098": { "Cosmetologist": 3 },
  "19086": { "Cosmetologist": 3 },
  "19015": { "Cosmetologist": 6 },
  "19008": { "Cosmetologist": 7 },
  "19029": { "Cosmetologist": 3 },
  "19342": { "Cosmetologist": 4 },
  "19094": { "Cosmetologist": 2 },
  "19033": { "Cosmetologist": 1 },
  "19022": { "Cosmetologist": 1 },
  "19081": { "Cosmetologist": 2 },
  "15857": { "Cosmetologist": 48 },
  "15868": { "Cosmetologist": 6 },
  "15846": { "Cosmetologist": 16 },
  "15845": { "Cosmetologist": 18 },
  "15841": { "Cosmetologist": 2 },
  "15823": { "Cosmetologist": 2 },
  "15870": { "Cosmetologist": 4 },
  "15831": { "Cosmetologist": 1 },
  "16417": { "Cosmetologist": 1 },
  "16407": { "Cosmetologist": 7 },
  "16426": { "Cosmetologist": 1 },
  "16410": { "Cosmetologist": 1 },
  "16415": { "Cosmetologist": 1 },
  "16423": { "Cosmetologist": 1 },
  "15430": { "Cosmetologist": 1 },
  "15456": { "Cosmetologist": 1 },
  "15463": { "Cosmetologist": 1 },
  "15438": { "Cosmetologist": 2 },
  "15468": { "Cosmetologist": 3 },
  "15469": { "Cosmetologist": 2 },
  "15428": { "Cosmetologist": 1 },
  "15437": { "Cosmetologist": 3 },
  "15442": { "Cosmetologist": 1 },
  "15440": { "Cosmetologist": 1 },
  "15037": { "Cosmetologist": 2 },
  "15458": { "Cosmetologist": 1 },
  "15459": { "Cosmetologist": 1 },
  "15486": { "Cosmetologist": 2 },
  "15062": { "Cosmetologist": 5 },
  "16239": { "Cosmetologist": 5 },
  "16321": { "Cosmetologist": 2 },
  "16353": { "Cosmetologist": 7 },
  "16341": { "Cosmetologist": 8 },
  "17235": { "Cosmetologist": 2 },
  "17224": { "Cosmetologist": 1 },
  "17262": { "Cosmetologist": 1 },
  "17228": { "Cosmetologist": 9 },
  "17238": { "Cosmetologist": 8 },
  "16689": { "Cosmetologist": 1 },
  "17229": { "Cosmetologist": 6 },
  "17267": { "Cosmetologist": 5 },
  "15536": { "Cosmetologist": 2 },
  "17264": { "Cosmetologist": 9 },
  "16691": { "Cosmetologist": 3 },
  "17212": { "Cosmetologist": 3 },
  "17215": { "Cosmetologist": 4 },
  "17223": { "Cosmetologist": 1 },
  "15346": { "Cosmetologist": 1 },
  "15364": { "Cosmetologist": 2 },
  "15344": { "Cosmetologist": 3 },
  "15624": { "Cosmetologist": 1 },
  "15338": { "Cosmetologist": 3 },
  "15349": { "Cosmetologist": 5 },
  "15322": { "Cosmetologist": 2 },
  "15341": { "Cosmetologist": 2 },
  "15380": { "Cosmetologist": 1 },
  "15359": { "Cosmetologist": 1 },
  "15337": { "Cosmetologist": 1 },
  "15334": { "Cosmetologist": 1 },
  "15357": { "Cosmetologist": 5 },
  "17066": { "Cosmetologist": 11 },
  "16669": { "Cosmetologist": 4 },
  "16657": { "Cosmetologist": 7 },
  "17052": { "Cosmetologist": 4 },
  "17255": { "Cosmetologist": 4 },
  "16623": { "Cosmetologist": 2 },
  "16634": { "Cosmetologist": 1 },
  "16683": { "Cosmetologist": 3 },
  "17239": { "Cosmetologist": 2 },
  "16647": { "Cosmetologist": 2 },
  "17249": { "Cosmetologist": 1 },
  "17260": { "Cosmetologist": 1 },
  "17253": { "Cosmetologist": 1 },
  "15771": { "Cosmetologist": 1 },
  "15774": { "Cosmetologist": 2 },
  "15732": { "Cosmetologist": 1 },
  "15725": { "Cosmetologist": 2 },
  "15759": { "Cosmetologist": 4 },
  "15746": { "Cosmetologist": 1 },
  "15728": { "Cosmetologist": 3 },
  "15729": { "Cosmetologist": 1 },
  "16246": { "Cosmetologist": 1 },
  "15747": { "Cosmetologist": 1 },
  "15783": { "Cosmetologist": 1 },
  "15724": { "Cosmetologist": 1 },
  "16211": { "Cosmetologist": 1 },
  "15840": { "Cosmetologist": 7 },
  "15715": { "Cosmetologist": 3 },
  "15730": { "Cosmetologist": 1 },
  "15784": { "Cosmetologist": 3 },
  "15733": { "Cosmetologist": 1 },
  "16838": { "Cosmetologist": 1 },
  "15711": { "Cosmetologist": 1 },
  "15860": { "Cosmetologist": 1 },
  "15829": { "Cosmetologist": 2 },
  "17058": { "Cosmetologist": 3 },
  "17086": { "Cosmetologist": 14 },
  "17049": { "Cosmetologist": 21 },
  "17082": { "Cosmetologist": 15 },
  "17035": { "Cosmetologist": 3 },
  "17014": { "Cosmetologist": 2 },
  "17853": { "Cosmetologist": 4 },
  "17021": { "Cosmetologist": 2 },
  "18518": { "Cosmetologist": 5 },
  "18452": { "Cosmetologist": 5 },
  "18517": { "Cosmetologist": 9 },
  "18507": { "Cosmetologist": 4 },
  "17517": { "Cosmetologist": 7 },
  "17502": { "Cosmetologist": 5 },
  "17563": { "Cosmetologist": 1 },
  "17584": { "Cosmetologist": 3 },
  "17555": { "Cosmetologist": 2 },
  "17519": { "Cosmetologist": 1 },
  "17527": { "Cosmetologist": 1 },
  "17569": { "Cosmetologist": 2 },
  "17507": { "Cosmetologist": 1 },
  "17579": { "Cosmetologist": 4 },
  "17560": { "Cosmetologist": 4 },
  "17529": { "Cosmetologist": 1 },
  "17520": { "Cosmetologist": 1 },
  "17532": { "Cosmetologist": 1 },
  "17572": { "Cosmetologist": 1 },
  "16156": { "Cosmetologist": 3 },
  "16112": { "Cosmetologist": 4 },
  "16120": { "Cosmetologist": 2 },
  "15075": { "Cosmetologist": 1 },
  "16143": { "Cosmetologist": 2 },
  "16160": { "Cosmetologist": 1 },
  "17067": { "Cosmetologist": 16 },
  "17010": { "Cosmetologist": 1 },
  "17088": { "Cosmetologist": 1 },
  "17028": { "Cosmetologist": 2 },
  "17064": { "Cosmetologist": 1 },
  "17026": { "Cosmetologist": 2 },
  "18070": { "Cosmetologist": 1 },
  "18037": { "Cosmetologist": 4 },
  "18036": { "Cosmetologist": 5 },
  "18034": { "Cosmetologist": 3 },
  "18068": { "Cosmetologist": 1 },
  "18059": { "Cosmetologist": 1 },
  "18078": { "Cosmetologist": 2 },
  "18053": { "Cosmetologist": 1 },
  "18066": { "Cosmetologist": 1 },
  "18635": { "Cosmetologist": 3 },
  "18618": { "Cosmetologist": 2 },
  "18621": { "Cosmetologist": 3 },
  "18708": { "Cosmetologist": 4 },
  "18216": { "Cosmetologist": 1 },
  "18224": { "Cosmetologist": 2 },
  "18222": { "Cosmetologist": 2 },
  "18421": { "Cosmetologist": 26 },
  "18656": { "Cosmetologist": 1 },
  "18256": { "Cosmetologist": 1 },
  "18249": { "Cosmetologist": 2 },
  "17744": { "Cosmetologist": 2 },
  "18655": { "Cosmetologist": 1 },
  "17731": { "Cosmetologist": 1 },
  "17771": { "Cosmetologist": 2 },
  "17756": { "Cosmetologist": 7 },
  "17774": { "Cosmetologist": 1 },
  "16749": { "Cosmetologist": 13 },
  "16731": { "Cosmetologist": 8 },
  "16735": { "Cosmetologist": 22 },
  "16750": { "Cosmetologist": 1 },
  "16740": { "Cosmetologist": 1 },
  "16745": { "Cosmetologist": 1 },
  "16729": { "Cosmetologist": 3 },
  "16733": { "Cosmetologist": 2 },
  "16726": { "Cosmetologist": 1 },
  "16738": { "Cosmetologist": 2 },
  "16727": { "Cosmetologist": 1 },
  "16154": { "Cosmetologist": 1 },
  "16428": { "Cosmetologist": 3 },
  "16159": { "Cosmetologist": 3 },
  "16113": { "Cosmetologist": 1 },
  "15112": { "Cosmetologist": 1 },
  "17841": { "Cosmetologist": 6 },
  "17063": { "Cosmetologist": 4 },
  "17004": { "Cosmetologist": 8 },
  "17084": { "Cosmetologist": 5 },
  "17051": { "Cosmetologist": 7 },
  "17002": { "Cosmetologist": 1 },
  "17009": { "Cosmetologist": 2 },
  "17054": { "Cosmetologist": 1 },
  "17075": { "Cosmetologist": 1 },
  "18058": { "Cosmetologist": 16 },
  "18332": { "Cosmetologist": 4 },
  "18344": { "Cosmetologist": 7 },
  "18330": { "Cosmetologist": 9 },
  "18335": { "Cosmetologist": 2 },
  "18350": { "Cosmetologist": 4 },
  "18321": { "Cosmetologist": 4 },
  "18347": { "Cosmetologist": 4 },
  "19642": { "Cosmetologist": 2 },
  "18354": { "Cosmetologist": 1 },
  "18346": { "Cosmetologist": 1 },
  "18370": { "Cosmetologist": 1 },
  "18333": { "Cosmetologist": 1 },
  "18327": { "Cosmetologist": 3 },
  "17868": { "Cosmetologist": 2 },
  "17884": { "Cosmetologist": 1 },
  "19107": { "Cosmetologist": 4 },
  "18091": { "Cosmetologist": 3 },
  "17860": { "Cosmetologist": 4 },
  "17017": { "Cosmetologist": 4 },
  "17824": { "Cosmetologist": 6 },
  "17870": { "Cosmetologist": 49 },
  "17840": { "Cosmetologist": 2 },
  "17823": { "Cosmetologist": 1 },
  "17836": { "Cosmetologist": 3 },
  "17832": { "Cosmetologist": 2 },
  "17024": { "Cosmetologist": 4 },
  "17062": { "Cosmetologist": 7 },
  "17040": { "Cosmetologist": 3 },
  "17090": { "Cosmetologist": 14 },
  "17069": { "Cosmetologist": 1 },
  "17047": { "Cosmetologist": 6 },
  "17037": { "Cosmetologist": 1 },
  "19114": { "Cosmetologist": 5 },
  "19131": { "Cosmetologist": 6 },
  "19139": { "Cosmetologist": 3 },
  "19150": { "Cosmetologist": 2 },
  "19142": { "Cosmetologist": 4 },
  "19152": { "Cosmetologist": 3 },
  "19141": { "Cosmetologist": 2 },
  "19137": { "Cosmetologist": 1 },
  "19154": { "Cosmetologist": 2 },
  "19123": { "Cosmetologist": 2 },
  "19119": { "Cosmetologist": 1 },
  "19153": { "Cosmetologist": 3 },
  "18435": { "Cosmetologist": 5 },
  "18328": { "Cosmetologist": 19 },
  "18451": { "Cosmetologist": 2 },
  "18425": { "Cosmetologist": 8 },
  "17538": { "Cosmetologist": 2 },
  "18457": { "Cosmetologist": 1 },
  "16948": { "Cosmetologist": 12 },
  "16923": { "Cosmetologist": 6 },
  "16748": { "Cosmetologist": 14 },
  "16922": { "Cosmetologist": 7 },
  "16927": { "Cosmetologist": 2 },
  "16950": { "Cosmetologist": 10 },
  "17935": { "Cosmetologist": 1 },
  "17961": { "Cosmetologist": 3 },
  "17923": { "Cosmetologist": 1 },
  "17965": { "Cosmetologist": 1 },
  "18248": { "Cosmetologist": 2 },
  "17960": { "Cosmetologist": 3 },
  "17957": { "Cosmetologist": 1 },
  "18241": { "Cosmetologist": 1 },
  "19549": { "Cosmetologist": 1 },
  "17030": { "Cosmetologist": 2 },
  "17967": { "Cosmetologist": 1 },
  "17981": { "Cosmetologist": 2 },
  "17953": { "Cosmetologist": 2 },
  "18211": { "Cosmetologist": 1 },
  "17929": { "Cosmetologist": 2 },
  "17952": { "Cosmetologist": 1 },
  "17933": { "Cosmetologist": 1 },
  "17833": { "Cosmetologist": 1 },
  "17864": { "Cosmetologist": 3 },
  "17831": { "Cosmetologist": 1 },
  "17862": { "Cosmetologist": 2 },
  "17844": { "Cosmetologist": 32 },
  "15541": { "Cosmetologist": 3 },
  "15935": { "Cosmetologist": 1 },
  "15540": { "Cosmetologist": 2 },
  "15936": { "Cosmetologist": 1 },
  "15552": { "Cosmetologist": 2 },
  "15926": { "Cosmetologist": 2 },
  "15557": { "Cosmetologist": 1 },
  "15411": { "Cosmetologist": 1 },
  "15942": { "Cosmetologist": 2 },
  "18632": { "Cosmetologist": 1 },
  "17758": { "Cosmetologist": 3 },
  "18616": { "Cosmetologist": 3 },
  "18628": { "Cosmetologist": 1 },
  "18826": { "Cosmetologist": 5 },
  "18470": { "Cosmetologist": 6 },
  "18822": { "Cosmetologist": 9 },
  "18812": { "Cosmetologist": 4 },
  "18413": { "Cosmetologist": 1 },
  "18821": { "Cosmetologist": 5 },
  "18441": { "Cosmetologist": 1 },
  "18630": { "Cosmetologist": 9 },
  "18844": { "Cosmetologist": 5 },
  "18830": { "Cosmetologist": 1 },
  "18465": { "Cosmetologist": 2 },
  "18824": { "Cosmetologist": 4 },
  "18813": { "Cosmetologist": 1 },
  "16912": { "Cosmetologist": 6 },
  "16938": { "Cosmetologist": 2 },
  "16929": { "Cosmetologist": 6 },
  "16943": { "Cosmetologist": 1 },
  "16946": { "Cosmetologist": 5 },
  "16936": { "Cosmetologist": 4 },
  "16917": { "Cosmetologist": 3 },
  "16935": { "Cosmetologist": 2 },
  "16911": { "Cosmetologist": 1 },
  "16920": { "Cosmetologist": 2 },
  "17765": { "Cosmetologist": 2 },
  "16921": { "Cosmetologist": 1 },
  "16930": { "Cosmetologist": 3 },
  "16932": { "Cosmetologist": 4 },
  "15207": { "Cosmetologist": 1 },
  "17886": { "Cosmetologist": 5 },
  "17889": { "Cosmetologist": 8 },
  "17855": { "Cosmetologist": 3 },
  "17810": { "Cosmetologist": 2 },
  "17887": { "Cosmetologist": 1 },
  "16362": { "Cosmetologist": 2 },
  "16342": { "Cosmetologist": 1 },
  "16343": { "Cosmetologist": 1 },
  "16319": { "Cosmetologist": 3 },
  "16317": { "Cosmetologist": 3 },
  "16372": { "Cosmetologist": 1 },
  "16344": { "Cosmetologist": 1 },
  "16371": { "Cosmetologist": 7 },
  "16340": { "Cosmetologist": 7 },
  "16345": { "Cosmetologist": 10 },
  "16420": { "Cosmetologist": 2 },
  "16436": { "Cosmetologist": 3 },
  "16350": { "Cosmetologist": 5 },
  "16347": { "Cosmetologist": 6 },
  "16313": { "Cosmetologist": 1 },
  "16351": { "Cosmetologist": 1 },
  "15323": { "Cosmetologist": 1 },
  "15321": { "Cosmetologist": 2 },
  "15333": { "Cosmetologist": 1 },
  "16036": { "Cosmetologist": 1 },
  "15067": { "Cosmetologist": 1 },
  "15311": { "Cosmetologist": 2 },
  "15022": { "Cosmetologist": 5 },
  "15203": { "Cosmetologist": 1 },
  "15312": { "Cosmetologist": 1 },
  "15483": { "Cosmetologist": 1 },
  "15427": { "Cosmetologist": 1 },
  "15423": { "Cosmetologist": 1 },
  "18431": { "Cosmetologist": 41 },
  "18472": { "Cosmetologist": 13 },
  "18463": { "Cosmetologist": 1 },
  "18459": { "Cosmetologist": 2 },
  "18461": { "Cosmetologist": 2 },
  "18438": { "Cosmetologist": 3 },
  "18325": { "Cosmetologist": 2 },
  "18417": { "Cosmetologist": 2 },
  "18456": { "Cosmetologist": 1 },
  "18439": { "Cosmetologist": 1 },
  "15658": { "Cosmetologist": 6 },
  "15620": { "Cosmetologist": 2 },
  "15087": { "Cosmetologist": 1 },
  "15613": { "Cosmetologist": 6 },
  "15683": { "Cosmetologist": 3 },
  "15083": { "Cosmetologist": 2 },
  "15670": { "Cosmetologist": 4 },
  "15148": { "Cosmetologist": 1 },
  "15089": { "Cosmetologist": 3 },
  "15610": { "Cosmetologist": 2 },
  "15666": { "Cosmetologist": 9 },
  "15051": { "Cosmetologist": 1 },
  "15618": { "Cosmetologist": 1 },
  "15085": { "Cosmetologist": 4 },
  "15639": { "Cosmetologist": 2 },
  "15627": { "Cosmetologist": 2 },
  "15698": { "Cosmetologist": 1 },
  "15923": { "Cosmetologist": 2 },
  "15619": { "Cosmetologist": 1 },
  "15684": { "Cosmetologist": 1 },
  "18419": { "Cosmetologist": 19 },
  "18636": { "Cosmetologist": 10 },
  "18623": { "Cosmetologist": 6 },
  "18629": { "Cosmetologist": 6 },
  "18615": { "Cosmetologist": 4 },
  "18625": { "Cosmetologist": 4 },
  "17329": { "Cosmetologist": 1 },
  "17345": { "Cosmetologist": 4 },
  "17355": { "Cosmetologist": 1 },
  "17314": { "Cosmetologist": 1 },
  "17349": { "Cosmetologist": 3 },
  "17327": { "Cosmetologist": 3 },
  "15676": { "Cosmetologist": 1 },
  "17360": { "Cosmetologist": 4 },
  "17317": { "Cosmetologist": 1 },
  "17365": { "Cosmetologist": 1 },
  "52245": { "Cosmetologist": 56, "Barber": 2 },
  "52732": { "Cosmetologist": 96, "Barber": 1 },
  "50535": { "Cosmetologist": 8 },
  "50248": { "Cosmetologist": 24 },
  "52632": { "Cosmetologist": 45, "Barber": 1 },
  "50201": { "Cosmetologist": 38, "Barber": 4 },
  "50003": { "Cosmetologist": 52, "Barber": 2 },
  "61454": { "Cosmetologist": 3 },
  "61469": { "Cosmetologist": 2 },
  "56187": { "Cosmetologist": 1 },
  "52172": { "Cosmetologist": 29 },
  "51537": { "Cosmetologist": 30, "Barber": 1 },
  "50158": { "Cosmetologist": 98, "Barber": 6 },
  "50658": { "Cosmetologist": 7, "Barber": 2 },
  "50265": { "Cosmetologist": 134, "Barber": 8 },
  "61275": { "Cosmetologist": 10, "Barber": 1 },
  "50501": { "Cosmetologist": 114 },
  "52144": { "Cosmetologist": 11, "Barber": 1 },
  "62379": { "Cosmetologist": 3 },
  "52627": { "Cosmetologist": 60, "Barber": 1 },
  "50560": { "Cosmetologist": 2 },
  "61273": { "Cosmetologist": 4 },
  "50309": { "Cosmetologist": 48, "Barber": 11 },
  "52748": { "Cosmetologist": 37, "Barber": 3 },
  "50439": { "Cosmetologist": 1 },
  "51559": { "Cosmetologist": 7, "Barber": 1 },
  "50249": { "Cosmetologist": 6 },
  "51019": { "Cosmetologist": 5 },
  "50632": { "Cosmetologist": 6 },
  "50025": { "Cosmetologist": 18, "Barber": 1 },
  "68106": { "Cosmetologist": 2 },
  "60706": { "Cosmetologist": 2 },
  "50321": { "Cosmetologist": 30, "Barber": 2 },
  "51463": { "Cosmetologist": 7 },
  "51037": { "Cosmetologist": 2 },
  "50156": { "Cosmetologist": 20, "Barber": 4 },
  "52739": { "Cosmetologist": 6 },
  "51012": { "Cosmetologist": 28, "Barber": 1 },
  "50854": { "Cosmetologist": 15, "Barber": 1 },
  "50168": { "Cosmetologist": 3 },
  "50028": { "Cosmetologist": 8 },
  "51354": { "Cosmetologist": 6 },
  "52232": { "Cosmetologist": 4 },
  "51546": { "Cosmetologist": 13, "Barber": 1 },
  "52324": { "Cosmetologist": 19, "Barber": 2 },
  "50047": { "Cosmetologist": 24, "Barber": 2 },
  "52213": { "Cosmetologist": 29, "Barber": 2 },
  "52645": { "Cosmetologist": 22 },
  "50325": { "Cosmetologist": 77, "Barber": 2 },
  "52214": { "Cosmetologist": 9, "Barber": 1 },
  "51035": { "Cosmetologist": 11, "Barber": 1 },
  "52404": { "Cosmetologist": 168, "Barber": 18 },
  "52571": { "Cosmetologist": 7 },
  "50250": { "Cosmetologist": 13 },
  "50125": { "Cosmetologist": 90, "Barber": 3 },
  "52233": { "Cosmetologist": 42, "Barber": 7 },
  "52755": { "Cosmetologist": 18, "Barber": 1 },
  "50401": { "Cosmetologist": 136, "Barber": 1 },
  "50166": { "Cosmetologist": 9 },
  "50112": { "Cosmetologist": 34, "Barber": 7 },
  "51034": { "Cosmetologist": 10, "Barber": 1 },
  "50484": { "Cosmetologist": 2 },
  "52653": { "Cosmetologist": 16 },
  "52136": { "Cosmetologist": 31 },
  "52316": { "Cosmetologist": 4, "Barber": 1 },
  "50111": { "Cosmetologist": 103 },
  "52248": { "Cosmetologist": 10 },
  "52060": { "Cosmetologist": 36, "Barber": 2 },
  "52327": { "Cosmetologist": 14, "Barber": 2 },
  "52101": { "Cosmetologist": 48 },
  "52577": { "Cosmetologist": 69, "Barber": 2 },
  "51250": { "Cosmetologist": 45, "Barber": 1 },
  "50568": { "Cosmetologist": 6 },
  "52361": { "Cosmetologist": 19 },
  "50801": { "Cosmetologist": 35, "Barber": 3 },
  "50035": { "Cosmetologist": 49 },
  "50130": { "Cosmetologist": 9 },
  "52338": { "Cosmetologist": 14, "Barber": 1 },
  "53812": { "Cosmetologist": 3 },
  "50662": { "Cosmetologist": 34, "Barber": 6 },
  "50171": { "Cosmetologist": 21, "Barber": 1 },
  "50228": { "Cosmetologist": 8, "Barber": 1 },
  "52553": { "Cosmetologist": 9 },
  "50601": { "Cosmetologist": 8 },
  "50423": { "Cosmetologist": 19, "Barber": 1 },
  "51109": { "Cosmetologist": 6 },
  "50616": { "Cosmetologist": 29, "Barber": 6 },
  "52761": { "Cosmetologist": 93, "Barber": 7 },
  "52323": { "Cosmetologist": 4 },
  "51579": { "Cosmetologist": 8 },
  "52625": { "Cosmetologist": 15, "Barber": 1 },
  "51027": { "Cosmetologist": 9 },
  "52002": { "Cosmetologist": 108, "Barber": 1 },
  "51240": { "Cosmetologist": 11 },
  "52403": { "Cosmetologist": 85, "Barber": 14 },
  "52202": { "Cosmetologist": 11 },
  "52656": { "Cosmetologist": 16 },
  "50139": { "Cosmetologist": 7 },
  "52411": { "Cosmetologist": 35, "Barber": 1 },
  "51445": { "Cosmetologist": 11 },
  "50476": { "Cosmetologist": 3, "Barber": 1 },
  "50669": { "Cosmetologist": 11, "Barber": 2 },
  "50676": { "Cosmetologist": 11 },
  "50627": { "Cosmetologist": 20 },
  "50536": { "Cosmetologist": 25 },
  "50327": { "Cosmetologist": 57, "Barber": 3 },
  "52003": { "Cosmetologist": 84 },
  "50548": { "Cosmetologist": 24 },
  "52211": { "Cosmetologist": 17 },
  "50126": { "Cosmetologist": 23, "Barber": 1 },
  "50247": { "Cosmetologist": 16, "Barber": 1 },
  "52595": { "Cosmetologist": 2 },
  "52040": { "Cosmetologist": 35, "Barber": 2 },
  "50659": { "Cosmetologist": 28, "Barber": 2 },
  "52542": { "Cosmetologist": 4 },
  "52726": { "Cosmetologist": 22 },
  "52742": { "Cosmetologist": 52, "Barber": 2 },
  "51239": { "Cosmetologist": 27 },
  "51433": { "Cosmetologist": 4 },
  "52031": { "Cosmetologist": 31 },
  "50106": { "Cosmetologist": 4 },
  "51358": { "Cosmetologist": 8 },
  "50598": { "Cosmetologist": 5 },
  "50660": { "Cosmetologist": 5 },
  "53577": { "Cosmetologist": 1 },
  "52401": { "Cosmetologist": 11 },
  "50666": { "Cosmetologist": 5, "Barber": 1 },
  "50251": { "Cosmetologist": 7, "Barber": 1 },
  "51241": { "Cosmetologist": 5 },
  "50167": { "Cosmetologist": 1 },
  "50225": { "Cosmetologist": 13, "Barber": 4 },
  "52328": { "Cosmetologist": 16 },
  "50575": { "Cosmetologist": 3 },
  "50323": { "Cosmetologist": 57, "Barber": 2 },
  "50830": { "Cosmetologist": 11, "Barber": 1 },
  "52342": { "Cosmetologist": 10, "Barber": 2 },
  "50122": { "Cosmetologist": 4, "Barber": 1 },
  "52641": { "Cosmetologist": 56, "Barber": 3 },
  "50040": { "Cosmetologist": 1 },
  "51237": { "Cosmetologist": 10 },
  "52310": { "Cosmetologist": 37, "Barber": 2 },
  "53807": { "Cosmetologist": 5 },
  "50836": { "Cosmetologist": 3 },
  "50655": { "Cosmetologist": 4 },
  "52658": { "Cosmetologist": 6 },
  "52151": { "Cosmetologist": 7 },
  "52228": { "Cosmetologist": 23, "Barber": 1 },
  "51249": { "Cosmetologist": 17 },
  "50438": { "Cosmetologist": 19 },
  "62354": { "Cosmetologist": 3 },
  "50143": { "Cosmetologist": 3 },
  "52210": { "Cosmetologist": 6 },
  "52216": { "Cosmetologist": 12 },
  "50421": { "Cosmetologist": 20 },
  "50638": { "Cosmetologist": 17, "Barber": 3 },
  "50115": { "Cosmetologist": 12 },
  "50459": { "Cosmetologist": 13, "Barber": 1 },
  "50278": { "Cosmetologist": 5 },
  "61270": { "Cosmetologist": 6 },
  "52776": { "Cosmetologist": 27 },
  "50020": { "Cosmetologist": 10 },
  "52046": { "Cosmetologist": 24 },
  "50257": { "Cosmetologist": 4 },
  "50142": { "Cosmetologist": 5 },
  "51338": { "Cosmetologist": 5 },
  "52326": { "Cosmetologist": 3, "Barber": 2 },
  "50002": { "Cosmetologist": 15 },
  "51002": { "Cosmetologist": 17 },
  "50680": { "Cosmetologist": 6 },
  "52362": { "Cosmetologist": 5 },
  "50702": { "Cosmetologist": 54, "Barber": 31 },
  "61241": { "Cosmetologist": 9 },
  "50624": { "Cosmetologist": 9 },
  "57034": { "Cosmetologist": 2 },
  "51014": { "Cosmetologist": 1 },
  "51046": { "Cosmetologist": 10 },
  "51334": { "Cosmetologist": 36 },
  "52156": { "Cosmetologist": 3 },
  "50675": { "Cosmetologist": 16, "Barber": 4 },
  "50533": { "Cosmetologist": 12 },
  "52065": { "Cosmetologist": 5 },
  "50440": { "Cosmetologist": 3 },
  "50161": { "Cosmetologist": 11 },
  "50310": { "Cosmetologist": 110, "Barber": 12 },
  "50129": { "Cosmetologist": 21 },
  "52227": { "Cosmetologist": 11, "Barber": 2 },
  "51030": { "Cosmetologist": 8 },
  "52249": { "Cosmetologist": 9 },
  "52073": { "Cosmetologist": 14 },
  "52644": { "Cosmetologist": 4 },
  "51201": { "Cosmetologist": 27 },
  "61252": { "Cosmetologist": 14 },
  "52043": { "Cosmetologist": 7, "Barber": 1 },
  "52074": { "Cosmetologist": 2 },
  "51026": { "Cosmetologist": 6 },
  "55954": { "Cosmetologist": 2 },
  "52353": { "Cosmetologist": 44, "Barber": 3 },
  "52772": { "Cosmetologist": 20, "Barber": 2 },
  "51023": { "Cosmetologist": 11 },
  "52161": { "Cosmetologist": 10 },
  "52220": { "Cosmetologist": 3 },
  "61025": { "Cosmetologist": 22 },
  "52146": { "Cosmetologist": 3 },
  "50650": { "Cosmetologist": 3 },
  "50648": { "Cosmetologist": 11, "Barber": 7 },
  "63465": { "Cosmetologist": 2 },
  "52334": { "Cosmetologist": 6 },
  "50841": { "Cosmetologist": 16 },
  "50144": { "Cosmetologist": 12, "Barber": 2 },
  "52760": { "Cosmetologist": 5 },
  "50074": { "Cosmetologist": 1 },
  "55965": { "Cosmetologist": 1 },
  "52142": { "Cosmetologist": 4 },
  "52133": { "Cosmetologist": 3 },
  "54751": { "Cosmetologist": 2 },
  "51247": { "Cosmetologist": 24, "Barber": 1 },
  "68731": { "Cosmetologist": 8 },
  "61437": { "Cosmetologist": 3 },
  "52171": { "Cosmetologist": 1 },
  "51025": { "Cosmetologist": 10 },
  "52057": { "Cosmetologist": 40, "Barber": 5 },
  "61087": { "Cosmetologist": 2 },
  "68784": { "Cosmetologist": 1 },
  "50049": { "Cosmetologist": 31, "Barber": 1 },
  "52038": { "Cosmetologist": 5 },
  "52052": { "Cosmetologist": 11 },
  "51455": { "Cosmetologist": 13 },
  "52314": { "Cosmetologist": 21, "Barber": 1 },
  "52531": { "Cosmetologist": 24, "Barber": 1 },
  "56320": { "Cosmetologist": 1 },
  "52032": { "Cosmetologist": 15 },
  "52349": { "Cosmetologist": 36, "Barber": 7 },
  "51653": { "Cosmetologist": 12, "Barber": 2 },
  "52768": { "Cosmetologist": 11, "Barber": 1 },
  "50522": { "Cosmetologist": 6 },
  "52637": { "Cosmetologist": 16, "Barber": 1 },
  "52237": { "Cosmetologist": 12 },
  "51442": { "Cosmetologist": 31, "Barber": 1 },
  "52045": { "Cosmetologist": 22, "Barber": 1 },
  "52041": { "Cosmetologist": 11 },
  "50213": { "Cosmetologist": 23, "Barber": 1 },
  "50461": { "Cosmetologist": 21, "Barber": 5 },
  "50604": { "Cosmetologist": 10 },
  "52339": { "Cosmetologist": 17, "Barber": 1 },
  "52039": { "Cosmetologist": 8 },
  "51016": { "Cosmetologist": 3, "Barber": 1 },
  "50653": { "Cosmetologist": 2 },
  "51031": { "Cosmetologist": 67, "Barber": 1 },
  "50665": { "Cosmetologist": 8 },
  "50621": { "Cosmetologist": 6 },
  "92359": { "Cosmetologist": 1 },
  "51449": { "Cosmetologist": 10, "Barber": 1 },
  "52037": { "Cosmetologist": 5, "Barber": 2 },
  "52556": { "Cosmetologist": 27 },
  "51024": { "Cosmetologist": 10, "Barber": 1 },
  "51248": { "Cosmetologist": 6 },
  "51454": { "Cosmetologist": 7 },
  "52079": { "Cosmetologist": 5 },
  "68152": { "Cosmetologist": 1 },
  "50647": { "Cosmetologist": 7 },
  "61442": { "Cosmetologist": 1 },
  "50853": { "Cosmetologist": 3 },
  "52050": { "Cosmetologist": 1 },
  "50466": { "Cosmetologist": 6 },
  "50583": { "Cosmetologist": 13 },
  "51548": { "Cosmetologist": 1 },
  "50046": { "Cosmetologist": 10 },
  "50622": { "Cosmetologist": 12, "Barber": 2 },
  "52330": { "Cosmetologist": 6 },
  "50230": { "Cosmetologist": 2 },
  "50424": { "Cosmetologist": 8 },
  "56027": { "Cosmetologist": 1 },
  "52175": { "Cosmetologist": 16 },
  "52223": { "Cosmetologist": 6 },
  "52640": { "Cosmetologist": 8 },
  "50219": { "Cosmetologist": 51, "Barber": 4 },
  "52347": { "Cosmetologist": 9 },
  "51556": { "Cosmetologist": 6 },
  "50311": { "Cosmetologist": 38, "Barber": 5 },
  "50229": { "Cosmetologist": 3 },
  "52160": { "Cosmetologist": 2 },
  "61467": { "Cosmetologist": 1 },
  "50273": { "Cosmetologist": 40, "Barber": 2 },
  "52209": { "Cosmetologist": 10, "Barber": 1 },
  "52203": { "Cosmetologist": 6, "Barber": 1 },
  "50027": { "Cosmetologist": 1 },
  "61264": { "Cosmetologist": 13 },
  "52655": { "Cosmetologist": 21, "Barber": 2 },
  "52624": { "Cosmetologist": 3 },
  "50146": { "Cosmetologist": 2, "Barber": 1 },
  "50212": { "Cosmetologist": 16 },
  "51533": { "Cosmetologist": 6 },
  "50170": { "Cosmetologist": 13, "Barber": 2 },
  "50644": { "Cosmetologist": 44, "Barber": 6 },
  "50562": { "Cosmetologist": 3 },
  "51022": { "Cosmetologist": 6 },
  "50173": { "Cosmetologist": 3 },
  "52753": { "Cosmetologist": 18 },
  "52247": { "Cosmetologist": 21 },
  "52218": { "Cosmetologist": 10 },
  "51510": { "Cosmetologist": 3 },
  "55077": { "Cosmetologist": 2 },
  "50641": { "Cosmetologist": 5, "Barber": 1 },
  "52802": { "Cosmetologist": 16, "Barber": 6 },
  "51443": { "Cosmetologist": 9 },
  "52159": { "Cosmetologist": 14 },
  "50483": { "Cosmetologist": 8 },
  "51054": { "Cosmetologist": 25 },
  "52337": { "Cosmetologist": 6, "Barber": 1 },
  "61425": { "Cosmetologist": 1 },
  "50670": { "Cosmetologist": 8, "Barber": 1 },
  "52305": { "Cosmetologist": 5 },
  "60429": { "Cosmetologist": 3 },
  "50626": { "Cosmetologist": 4, "Barber": 1 },
  "51562": { "Cosmetologist": 5 },
  "51467": { "Cosmetologist": 5 },
  "51041": { "Cosmetologist": 34 },
  "50673": { "Cosmetologist": 3 },
  "51020": { "Cosmetologist": 5 },
  "51577": { "Cosmetologist": 7, "Barber": 1 },
  "51544": { "Cosmetologist": 4 },
  "50436": { "Cosmetologist": 24 },
  "51234": { "Cosmetologist": 5, "Barber": 1 },
  "53818": { "Cosmetologist": 5 },
  "51347": { "Cosmetologist": 6 },
  "51238": { "Cosmetologist": 6 },
  "35957": { "Cosmetologist": 1 },
  "51243": { "Cosmetologist": 6 },
  "56455": { "Cosmetologist": 1 },
  "51521": { "Cosmetologist": 9, "Barber": 1 },
  "52035": { "Cosmetologist": 4 },
  "51055": { "Cosmetologist": 7 },
  "50244": { "Cosmetologist": 13 },
  "52550": { "Cosmetologist": 2 },
  "52752": { "Cosmetologist": 4 },
  "50629": { "Cosmetologist": 7, "Barber": 2 },
  "50169": { "Cosmetologist": 16, "Barber": 1 },
  "50578": { "Cosmetologist": 6 },
  "52659": { "Cosmetologist": 6 },
  "50460": { "Cosmetologist": 2 },
  "52583": { "Cosmetologist": 3 },
  "52253": { "Cosmetologist": 13 },
  "52773": { "Cosmetologist": 14, "Barber": 1 },
  "50609": { "Cosmetologist": 2 },
  "52586": { "Cosmetologist": 3 },
  "51006": { "Cosmetologist": 6 },
  "61282": { "Cosmetologist": 11 },
  "52738": { "Cosmetologist": 8 },
  "50539": { "Cosmetologist": 3 },
  "52033": { "Cosmetologist": 22, "Barber": 1 },
  "52301": { "Cosmetologist": 24 },
  "51573": { "Cosmetologist": 8 },
  "52554": { "Cosmetologist": 5 },
  "55406": { "Cosmetologist": 1 },
  "52140": { "Cosmetologist": 2 },
  "08015": { "Cosmetologist": 92, "Barber": 14 },
  "52619": { "Cosmetologist": 8 },
  "50259": { "Cosmetologist": 1 },
  "57038": { "Cosmetologist": 5 },
  "52147": { "Cosmetologist": 12 },
  "50840": { "Cosmetologist": 6 },
  "50846": { "Cosmetologist": 6 },
  "52201": { "Cosmetologist": 9 },
  "54625": { "Cosmetologist": 1 },
  "51560": { "Cosmetologist": 3 },
  "52730": { "Cosmetologist": 31, "Barber": 1 },
  "50216": { "Cosmetologist": 15, "Barber": 1 },
  "50599": { "Cosmetologist": 3 },
  "50654": { "Cosmetologist": 2 },
  "52340": { "Cosmetologist": 21, "Barber": 1 },
  "50581": { "Cosmetologist": 2 },
  "51246": { "Cosmetologist": 19 },
  "51346": { "Cosmetologist": 17, "Barber": 1 },
  "51532": { "Cosmetologist": 6 },
  "52312": { "Cosmetologist": 2 },
  "52623": { "Cosmetologist": 12 },
  "50619": { "Cosmetologist": 12, "Barber": 1 },
  "61240": { "Cosmetologist": 5 },
  "56036": { "Cosmetologist": 1 },
  "52069": { "Cosmetologist": 12 },
  "51440": { "Cosmetologist": 4 },
  "52206": { "Cosmetologist": 9, "Barber": 1 },
  "50478": { "Cosmetologist": 2 },
  "51050": { "Cosmetologist": 18 },
  "52215": { "Cosmetologist": 1 },
  "50565": { "Cosmetologist": 2 },
  "50050": { "Cosmetologist": 4 },
  "50472": { "Cosmetologist": 4 },
  "50032": { "Cosmetologist": 2 },
  "52132": { "Cosmetologist": 4, "Barber": 1 },
  "74131": { "Cosmetologist": 1 },
  "52241": { "Cosmetologist": 65, "Barber": 4 },
  "50073": { "Cosmetologist": 11, "Barber": 1 },
  "51052": { "Cosmetologist": 5, "Barber": 2 },
  "56150": { "Cosmetologist": 5 },
  "50118": { "Cosmetologist": 4 },
  "53821": { "Cosmetologist": 3 },
  "61281": { "Cosmetologist": 8 },
  "52549": { "Cosmetologist": 3, "Barber": 1 },
  "51357": { "Cosmetologist": 4 },
  "52535": { "Cosmetologist": 6 },
  "50631": { "Cosmetologist": 2 },
  "50554": { "Cosmetologist": 11 },
  "50062": { "Cosmetologist": 2 },
  "50108": { "Cosmetologist": 2 },
  "50585": { "Cosmetologist": 6 },
  "61244": { "Cosmetologist": 14 },
  "50133": { "Cosmetologist": 3 },
  "50236": { "Cosmetologist": 7 },
  "52639": { "Cosmetologist": 6 },
  "50276": { "Cosmetologist": 15 },
  "54986": { "Cosmetologist": 1 },
  "50468": { "Cosmetologist": 6 },
  "50261": { "Cosmetologist": 10 },
  "52225": { "Cosmetologist": 2 },
  "62341": { "Cosmetologist": 6 },
  "50849": { "Cosmetologist": 17 },
  "56143": { "Cosmetologist": 7 },
  "50268": { "Cosmetologist": 4 },
  "55922": { "Cosmetologist": 1 },
  "37048": { "Cosmetologist": 3, "Barber": 2 },
  "50233": { "Cosmetologist": 15 },
  "51555": { "Cosmetologist": 12, "Barber": 2 },
  "50514": { "Cosmetologist": 3 },
  "50682": { "Cosmetologist": 8, "Barber": 1 },
  "60124": { "Cosmetologist": 5 },
  "52536": { "Cosmetologist": 8 },
  "50054": { "Cosmetologist": 10 },
  "50574": { "Cosmetologist": 10, "Barber": 1 },
  "51062": { "Cosmetologist": 4 },
  "50477": { "Cosmetologist": 3 },
  "50134": { "Cosmetologist": 4 },
  "50061": { "Cosmetologist": 6, "Barber": 1 },
  "20141": { "Cosmetologist": 2 },
  "50222": { "Cosmetologist": 4 },
  "50510": { "Cosmetologist": 5 },
  "50069": { "Cosmetologist": 5 },
  "51466": { "Cosmetologist": 6 },
  "51047": { "Cosmetologist": 1 },
  "78537": { "Cosmetologist": 312, "Barber": 78 },
  "50519": { "Cosmetologist": 1 },
  "51578": { "Cosmetologist": 1 },
  "50174": { "Cosmetologist": 3 },
  "68008": { "Cosmetologist": 5 },
  "50153": { "Cosmetologist": 3 },
  "52329": { "Cosmetologist": 3, "Barber": 1 },
  "51028": { "Cosmetologist": 15 },
  "50531": { "Cosmetologist": 2 },
  "50102": { "Cosmetologist": 1 },
  "50667": { "Cosmetologist": 3, "Barber": 2 },
  "52345": { "Cosmetologist": 4, "Barber": 2 },
  "52774": { "Cosmetologist": 4 },
  "52751": { "Cosmetologist": 3 },
  "50652": { "Cosmetologist": 1 },
  "50207": { "Cosmetologist": 13 },
  "50107": { "Cosmetologist": 4 },
  "50105": { "Cosmetologist": 8 },
  "52746": { "Cosmetologist": 6 },
  "50026": { "Cosmetologist": 3 },
  "51242": { "Cosmetologist": 1 },
  "52580": { "Cosmetologist": 2 },
  "48823": { "Cosmetologist": 162, "Barber": 20 },
  "68776": { "Cosmetologist": 26, "Barber": 1 },
  "50005": { "Cosmetologist": 5 },
  "50540": { "Cosmetologist": 2 },
  "68743": { "Cosmetologist": 6 },
  "50704": { "Cosmetologist": 2 },
  "50518": { "Cosmetologist": 1 },
  "52336": { "Cosmetologist": 13 },
  "52162": { "Cosmetologist": 7 },
  "50109": { "Cosmetologist": 20, "Barber": 1 },
  "51001": { "Cosmetologist": 8, "Barber": 1 },
  "51530": { "Cosmetologist": 6 },
  "51652": { "Cosmetologist": 6 },
  "57069": { "Cosmetologist": 5 },
  "53808": { "Cosmetologist": 5 },
  "51650": { "Cosmetologist": 1 },
  "52166": { "Cosmetologist": 1, "Barber": 1 },
  "50038": { "Cosmetologist": 1 },
  "61075": { "Cosmetologist": 2 },
  "52224": { "Cosmetologist": 7, "Barber": 2 },
  "50450": { "Cosmetologist": 7 },
  "50132": { "Cosmetologist": 3 },
  "61279": { "Cosmetologist": 2 },
  "53586": { "Cosmetologist": 9 },
  "51448": { "Cosmetologist": 4 },
  "51649": { "Cosmetologist": 3 },
  "51053": { "Cosmetologist": 5 },
  "50044": { "Cosmetologist": 4, "Barber": 1 },
  "52054": { "Cosmetologist": 7 },
  "50674": { "Cosmetologist": 10, "Barber": 2 },
  "50543": { "Cosmetologist": 6, "Barber": 1 },
  "68741": { "Cosmetologist": 2 },
  "52078": { "Cosmetologist": 3 },
  "50007": { "Cosmetologist": 4 },
  "50664": { "Cosmetologist": 1 },
  "52154": { "Cosmetologist": 6 },
  "50001": { "Cosmetologist": 4, "Barber": 1 },
  "50591": { "Cosmetologist": 2 },
  "61262": { "Cosmetologist": 2 },
  "52801": { "Cosmetologist": 5 },
  "98011": { "Cosmetologist": 3 },
  "55128": { "Cosmetologist": 2 },
  "50452": { "Cosmetologist": 5 },
  "51553": { "Cosmetologist": 4 },
  "51005": { "Cosmetologist": 13 },
  "62681": { "Cosmetologist": 1 },
  "52164": { "Cosmetologist": 4 },
  "51570": { "Cosmetologist": 4 },
  "61036": { "Cosmetologist": 9 },
  "50605": { "Cosmetologist": 2 },
  "50243": { "Cosmetologist": 3 },
  "52318": { "Cosmetologist": 5, "Barber": 1 },
  "61272": { "Cosmetologist": 2 },
  "50538": { "Cosmetologist": 5 },
  "51531": { "Cosmetologist": 4 },
  "51004": { "Cosmetologist": 3, "Barber": 1 },
  "61047": { "Cosmetologist": 1 },
  "50843": { "Cosmetologist": 2 },
  "53575": { "Cosmetologist": 1 },
  "52165": { "Cosmetologist": 8 },
  "52322": { "Cosmetologist": 13 },
  "50164": { "Cosmetologist": 6 },
  "50238": { "Cosmetologist": 5 },
  "52756": { "Cosmetologist": 17, "Barber": 3 },
  "61238": { "Cosmetologist": 2 },
  "50075": { "Cosmetologist": 5, "Barber": 1 },
  "50456": { "Cosmetologist": 10 },
  "61258": { "Cosmetologist": 1 },
  "50154": { "Cosmetologist": 3 },
  "51235": { "Cosmetologist": 10 },
  "53803": { "Cosmetologist": 3 },
  "56073": { "Cosmetologist": 1 },
  "50120": { "Cosmetologist": 3 },
  "68038": { "Cosmetologist": 2 },
  "50516": { "Cosmetologist": 3 },
  "52222": { "Cosmetologist": 2 },
  "50628": { "Cosmetologist": 4 },
  "53820": { "Cosmetologist": 7 },
  "50135": { "Cosmetologist": 6 },
  "50076": { "Cosmetologist": 5 },
  "50864": { "Cosmetologist": 13 },
  "68137": { "Cosmetologist": 4 },
  "50525": { "Cosmetologist": 14 },
  "61611": { "Cosmetologist": 3 },
  "52530": { "Cosmetologist": 2 },
  "51601": { "Cosmetologist": 16 },
  "61465": { "Cosmetologist": 3 },
  "51430": { "Cosmetologist": 6 },
  "68135": { "Cosmetologist": 2 },
  "61259": { "Cosmetologist": 5 },
  "61460": { "Cosmetologist": 1 },
  "50006": { "Cosmetologist": 10 },
  "51343": { "Cosmetologist": 1 },
  "50458": { "Cosmetologist": 4, "Barber": 1 },
  "50529": { "Cosmetologist": 4 },
  "68107": { "Cosmetologist": 3 },
  "50258": { "Cosmetologist": 4 },
  "50151": { "Cosmetologist": 3 },
  "68770": { "Cosmetologist": 7 },
  "56051": { "Cosmetologist": 1 },
  "52320": { "Cosmetologist": 4 },
  "52563": { "Cosmetologist": 12 },
  "61037": { "Cosmetologist": 1 },
  "52808": { "Cosmetologist": 2 },
  "52255": { "Cosmetologist": 6 },
  "52042": { "Cosmetologist": 9, "Barber": 1 },
  "56158": { "Cosmetologist": 1 },
  "50162": { "Cosmetologist": 7 },
  "51230": { "Cosmetologist": 4 },
  "52561": { "Cosmetologist": 6 },
  "61250": { "Cosmetologist": 11 },
  "53566": { "Cosmetologist": 4 },
  "50597": { "Cosmetologist": 7 },
  "61490": { "Cosmetologist": 2 },
  "52721": { "Cosmetologist": 3 },
  "51526": { "Cosmetologist": 1 },
  "50042": { "Cosmetologist": 1 },
  "52720": { "Cosmetologist": 6 },
  "52352": { "Cosmetologist": 5 },
  "52244": { "Cosmetologist": 3 },
  "50642": { "Cosmetologist": 6 },
  "51010": { "Cosmetologist": 3 },
  "50569": { "Cosmetologist": 2 },
  "36507": { "Cosmetologist": 3 },
  "52229": { "Cosmetologist": 3 },
  "52758": { "Cosmetologist": 2 },
  "52321": { "Cosmetologist": 5 },
  "50602": { "Cosmetologist": 8, "Barber": 2 },
  "56065": { "Cosmetologist": 1 },
  "51541": { "Cosmetologist": 2 },
  "50435": { "Cosmetologist": 4 },
  "52155": { "Cosmetologist": 5, "Barber": 2 },
  "50594": { "Cosmetologist": 1 },
  "52351": { "Cosmetologist": 5 },
  "51646": { "Cosmetologist": 3 },
  "50858": { "Cosmetologist": 4 },
  "54141": { "Cosmetologist": 2 },
  "75205": { "Cosmetologist": 74, "Barber": 5 },
  "50469": { "Cosmetologist": 5 },
  "50625": { "Cosmetologist": 5, "Barber": 1 },
  "30678": { "Cosmetologist": 2 },
  "52406": { "Cosmetologist": 1 },
  "52356": { "Cosmetologist": 14, "Barber": 1 },
  "89141": { "Cosmetologist": 10 },
  "50563": { "Cosmetologist": 12 },
  "60041": { "Cosmetologist": 1 },
  "52070": { "Cosmetologist": 4 },
  "50149": { "Cosmetologist": 1, "Barber": 1 },
  "50542": { "Cosmetologist": 3 },
  "52650": { "Cosmetologist": 3 },
  "68048": { "Cosmetologist": 4 },
  "68102": { "Cosmetologist": 2 },
  "50271": { "Cosmetologist": 3 },
  "52307": { "Cosmetologist": 1 },
  "50242": { "Cosmetologist": 1 },
  "97321": { "Cosmetologist": 2 },
  "51058": { "Cosmetologist": 5 },
  "50430": { "Cosmetologist": 2 },
  "51636": { "Cosmetologist": 2 },
  "61232": { "Cosmetologist": 3 },
  "68114": { "Cosmetologist": 4 },
  "50523": { "Cosmetologist": 2 },
  "43040": { "Cosmetologist": 4 },
  "78596": { "Cosmetologist": 192, "Barber": 128 },
  "61284": { "Cosmetologist": 4 },
  "50571": { "Cosmetologist": 2 },
  "50634": { "Cosmetologist": 7, "Barber": 2 },
  "51528": { "Cosmetologist": 5 },
  "52630": { "Cosmetologist": 2 },
  "56138": { "Cosmetologist": 1 },
  "50055": { "Cosmetologist": 5 },
  "50237": { "Cosmetologist": 11 },
  "51535": { "Cosmetologist": 11 },
  "50029": { "Cosmetologist": 1, "Barber": 2 },
  "51638": { "Cosmetologist": 6 },
  "60188": { "Cosmetologist": 2 },
  "50558": { "Cosmetologist": 4 },
  "50103": { "Cosmetologist": 3 },
  "50136": { "Cosmetologist": 6 },
  "52766": { "Cosmetologist": 5 },
  "50008": { "Cosmetologist": 3 },
  "50071": { "Cosmetologist": 4 },
  "51232": { "Cosmetologist": 7 },
  "51576": { "Cosmetologist": 13 },
  "51003": { "Cosmetologist": 12 },
  "61625": { "Cosmetologist": 1 },
  "61235": { "Cosmetologist": 3 },
  "50160": { "Cosmetologist": 2 },
  "52651": { "Cosmetologist": 4 },
  "61285": { "Cosmetologist": 3 },
  "61051": { "Cosmetologist": 1 },
  "50275": { "Cosmetologist": 3 },
  "68979": { "Cosmetologist": 1 },
  "52573": { "Cosmetologist": 1 },
  "71303": { "Cosmetologist": 1 },
  "53554": { "Cosmetologist": 1 },
  "51564": { "Cosmetologist": 4 },
  "52306": { "Cosmetologist": 12, "Barber": 1 },
  "57029": { "Cosmetologist": 2 },
  "52767": { "Cosmetologist": 2 },
  "51640": { "Cosmetologist": 5, "Barber": 1 },
  "51551": { "Cosmetologist": 4 },
  "61483": { "Cosmetologist": 1 },
  "51231": { "Cosmetologist": 2 },
  "78703": { "Cosmetologist": 66, "Barber": 4 },
  "50141": { "Cosmetologist": 1 },
  "50157": { "Cosmetologist": 5 },
  "51060": { "Cosmetologist": 5, "Barber": 1 },
  "50117": { "Cosmetologist": 1 },
  "61256": { "Cosmetologist": 3 },
  "50672": { "Cosmetologist": 2 },
  "52769": { "Cosmetologist": 6 },
  "50475": { "Cosmetologist": 7 },
  "52135": { "Cosmetologist": 4, "Barber": 1 },
  "51639": { "Cosmetologist": 4 },
  "50607": { "Cosmetologist": 1 },
  "50643": { "Cosmetologist": 14, "Barber": 2 },
  "51036": { "Cosmetologist": 4 },
  "50590": { "Cosmetologist": 6 },
  "51450": { "Cosmetologist": 8 },
  "52728": { "Cosmetologist": 2 },
  "52540": { "Cosmetologist": 2, "Barber": 1 },
  "50517": { "Cosmetologist": 4 },
  "50606": { "Cosmetologist": 5 },
  "36618": { "Cosmetologist": 1 },
  "50448": { "Cosmetologist": 1 },
  "52047": { "Cosmetologist": 1 },
  "51444": { "Cosmetologist": 5 },
  "52254": { "Cosmetologist": 2 },
  "85541": { "Cosmetologist": 5 },
  "51007": { "Cosmetologist": 2 },
  "61254": { "Cosmetologist": 11 },
  "51436": { "Cosmetologist": 12 },
  "52341": { "Cosmetologist": 6 },
  "50532": { "Cosmetologist": 4 },
  "52359": { "Cosmetologist": 2 },
  "55435": { "Cosmetologist": 1 },
  "52315": { "Cosmetologist": 8 },
  "52076": { "Cosmetologist": 8 },
  "50447": { "Cosmetologist": 8 },
  "50063": { "Cosmetologist": 7, "Barber": 1 },
  "50851": { "Cosmetologist": 4 },
  "68446": { "Cosmetologist": 1 },
  "51458": { "Cosmetologist": 13 },
  "50471": { "Cosmetologist": 1 },
  "50455": { "Cosmetologist": 2 },
  "55902": { "Cosmetologist": 3 },
  "55604": { "Cosmetologist": 1 },
  "51245": { "Cosmetologist": 7 },
  "50579": { "Cosmetologist": 6 },
  "52346": { "Cosmetologist": 3 },
  "52727": { "Cosmetologist": 3 },
  "50633": { "Cosmetologist": 1 },
  "61480": { "Cosmetologist": 3 },
  "52141": { "Cosmetologist": 3 },
  "50611": { "Cosmetologist": 2, "Barber": 1 },
  "50451": { "Cosmetologist": 4 },
  "61420": { "Cosmetologist": 2 },
  "37174": { "Cosmetologist": 11, "Barber": 23 },
  "52638": { "Cosmetologist": 3 },
  "52757": { "Cosmetologist": 3 },
  "50528": { "Cosmetologist": 1 },
  "93427": { "Cosmetologist": 1 },
  "50453": { "Cosmetologist": 3 },
  "50582": { "Cosmetologist": 1 },
  "50848": { "Cosmetologist": 1 },
  "52584": { "Cosmetologist": 1 },
  "92081": { "Cosmetologist": 1 },
  "68108": { "Cosmetologist": 1 },
  "51565": { "Cosmetologist": 3 },
  "50635": { "Cosmetologist": 3, "Barber": 1 },
  "50039": { "Cosmetologist": 4 },
  "56031": { "Cosmetologist": 3 },
  "50482": { "Cosmetologist": 10 },
  "63430": { "Cosmetologist": 1 },
  "51543": { "Cosmetologist": 3 },
  "51366": { "Cosmetologist": 2 },
  "50457": { "Cosmetologist": 2 },
  "50657": { "Cosmetologist": 2 },
  "52649": { "Cosmetologist": 4 },
  "53073": { "Cosmetologist": 3 },
  "77359": { "Cosmetologist": 7 },
  "98640": { "Cosmetologist": 2 },
  "89423": { "Cosmetologist": 1 },
  "52777": { "Cosmetologist": 8, "Barber": 3 },
  "52335": { "Cosmetologist": 2, "Barber": 1 },
  "52053": { "Cosmetologist": 5 },
  "52574": { "Cosmetologist": 3 },
  "50524": { "Cosmetologist": 2 },
  "50446": { "Cosmetologist": 2 },
  "51333": { "Cosmetologist": 3 },
  "76707": { "Cosmetologist": 80, "Barber": 17 },
  "53811": { "Cosmetologist": 8 },
  "52252": { "Cosmetologist": 1 },
  "61230": { "Cosmetologist": 3 },
  "52221": { "Cosmetologist": 2 },
  "68007": { "Cosmetologist": 1 },
  "52565": { "Cosmetologist": 5 },
  "76657": { "Cosmetologist": 63, "Barber": 3 },
  "50058": { "Cosmetologist": 11, "Barber": 1 },
  "50636": { "Cosmetologist": 8, "Barber": 1 },
  "52168": { "Cosmetologist": 1 },
  "68517": { "Cosmetologist": 1 },
  "52594": { "Cosmetologist": 1 },
  "50206": { "Cosmetologist": 2 },
  "52647": { "Cosmetologist": 3 },
  "78552": { "Cosmetologist": 277, "Barber": 43 },
  "50274": { "Cosmetologist": 3 },
  "51645": { "Cosmetologist": 2 },
  "51525": { "Cosmetologist": 4 },
  "52576": { "Cosmetologist": 1, "Barber": 1 },
  "84070": { "Cosmetologist": 1 },
  "60480": { "Cosmetologist": 1 },
  "51431": { "Cosmetologist": 2, "Barber": 1 },
  "52313": { "Cosmetologist": 1 },
  "50645": { "Cosmetologist": 4 },
  "61413": { "Cosmetologist": 1 },
  "51465": { "Cosmetologist": 2 },
  "25403": { "Cosmetologist": 3 },
  "50402": { "Cosmetologist": 1 },
  "51063": { "Cosmetologist": 5 },
  "50479": { "Cosmetologist": 5 },
  "50051": { "Cosmetologist": 2 },
  "51461": { "Cosmetologist": 1 },
  "51101": { "Cosmetologist": 3, "Barber": 1 },
  "50557": { "Cosmetologist": 2 },
  "51033": { "Cosmetologist": 1 },
  "52072": { "Cosmetologist": 1 },
  "70775": { "Cosmetologist": 2 },
  "52754": { "Cosmetologist": 5 },
  "50123": { "Cosmetologist": 2 },
  "52533": { "Cosmetologist": 2 },
  "43026": { "Cosmetologist": 7 },
  "50837": { "Cosmetologist": 1 },
  "50464": { "Cosmetologist": 3 },
  "50432": { "Cosmetologist": 1 },
  "52325": { "Cosmetologist": 6 },
  "61257": { "Cosmetologist": 1 },
  "56136": { "Cosmetologist": 1 },
  "50223": { "Cosmetologist": 2 },
  "51345": { "Cosmetologist": 1 },
  "50612": { "Cosmetologist": 1 },
  "23517": { "Cosmetologist": 1 },
  "50434": { "Cosmetologist": 1 },
  "52642": { "Cosmetologist": 1 },
  "68023": { "Cosmetologist": 1 },
  "50546": { "Cosmetologist": 2 },
  "77028": { "Cosmetologist": 96, "Barber": 28 },
  "51355": { "Cosmetologist": 4 },
  "51350": { "Cosmetologist": 2 },
  "50148": { "Cosmetologist": 2 },
  "22032": { "Cosmetologist": 2 },
  "54658": { "Cosmetologist": 1 },
  "60125": { "Cosmetologist": 1 },
  "52071": { "Cosmetologist": 1 },
  "93635": { "Cosmetologist": 1 },
  "50630": { "Cosmetologist": 5 },
  "50566": { "Cosmetologist": 5 },
  "57035": { "Cosmetologist": 1 },
  "51549": { "Cosmetologist": 2 },
  "50863": { "Cosmetologist": 1 },
  "50845": { "Cosmetologist": 3 },
  "50561": { "Cosmetologist": 2 },
  "51558": { "Cosmetologist": 2 },
  "52555": { "Cosmetologist": 1 },
  "68524": { "Cosmetologist": 2 },
  "54624": { "Cosmetologist": 1 },
  "50603": { "Cosmetologist": 3 },
  "52207": { "Cosmetologist": 2 },
  "52236": { "Cosmetologist": 6 },
  "52805": { "Cosmetologist": 1 },
  "50163": { "Cosmetologist": 1 },
  "70129": { "Cosmetologist": 3 },
  "50859": { "Cosmetologist": 3 },
  "50264": { "Cosmetologist": 1 },
  "38315": { "Cosmetologist": 1 },
  "50576": { "Cosmetologist": 1, "Barber": 1 },
  "52543": { "Cosmetologist": 3, "Barber": 1 },
  "60077": { "Cosmetologist": 4 },
  "61236": { "Cosmetologist": 1 },
  "52309": { "Cosmetologist": 1 },
  "52408": { "Cosmetologist": 2 },
  "51364": { "Cosmetologist": 4 },
  "51439": { "Cosmetologist": 3 },
  "38310": { "Cosmetologist": 2, "Barber": 1 },
  "54956": { "Cosmetologist": 4 },
  "50256": { "Cosmetologist": 3 },
  "50147": { "Cosmetologist": 2 },
  "52572": { "Cosmetologist": 8 },
  "50155": { "Cosmetologist": 1 },
  "51446": { "Cosmetologist": 3 },
  "52809": { "Cosmetologist": 1 },
  "79801": { "Cosmetologist": 1 },
  "52749": { "Cosmetologist": 4 },
  "61074": { "Cosmetologist": 1 },
  "95122": { "Cosmetologist": 2 },
  "52590": { "Cosmetologist": 4 },
  "52534": { "Cosmetologist": 1 },
  "55976": { "Cosmetologist": 1 },
  "52157": { "Cosmetologist": 4 },
  "54665": { "Cosmetologist": 1 },
  "99166": { "Cosmetologist": 1 },
  "53129": { "Cosmetologist": 5 },
  "50048": { "Cosmetologist": 4 },
  "52729": { "Cosmetologist": 5 },
  "52212": { "Cosmetologist": 2 },
  "50116": { "Cosmetologist": 1 },
  "50541": { "Cosmetologist": 3, "Barber": 1 },
  "52745": { "Cosmetologist": 1 },
  "51045": { "Cosmetologist": 1 },
  "51061": { "Cosmetologist": 1 },
  "50515": { "Cosmetologist": 1 },
  "52217": { "Cosmetologist": 2 },
  "52560": { "Cosmetologist": 1 },
  "57532": { "Cosmetologist": 1 },
  "83661": { "Cosmetologist": 2 },
  "51340": { "Cosmetologist": 2 },
  "61486": { "Cosmetologist": 1 },
  "52257": { "Cosmetologist": 3, "Barber": 1 },
  "52569": { "Cosmetologist": 1 },
  "50066": { "Cosmetologist": 1 },
  "51365": { "Cosmetologist": 1 },
  "50530": { "Cosmetologist": 1, "Barber": 1 },
  "52701": { "Cosmetologist": 1 },
  "54889": { "Cosmetologist": 1 },
  "52064": { "Cosmetologist": 4 },
  "60517": { "Cosmetologist": 12 },
  "50862": { "Cosmetologist": 1 },
  "52030": { "Cosmetologist": 2 },
  "50277": { "Cosmetologist": 1 },
  "68733": { "Cosmetologist": 2 },
  "52631": { "Cosmetologist": 1 },
  "50544": { "Cosmetologist": 2 },
  "61414": { "Cosmetologist": 1 },
  "52737": { "Cosmetologist": 2 },
  "50119": { "Cosmetologist": 1, "Barber": 1 },
  "52733": { "Cosmetologist": 1 },
  "51523": { "Cosmetologist": 2 },
  "50034": { "Cosmetologist": 2 },
  "50128": { "Cosmetologist": 1 },
  "68757": { "Cosmetologist": 3 },
  "50217": { "Cosmetologist": 3 },
  "51557": { "Cosmetologist": 4 },
  "50577": { "Cosmetologist": 2 },
  "50246": { "Cosmetologist": 2 },
  "50234": { "Cosmetologist": 1 },
  "50551": { "Cosmetologist": 1 },
  "51331": { "Cosmetologist": 9 },
  "56129": { "Cosmetologist": 1 },
  "50467": { "Cosmetologist": 2 },
  "52049": { "Cosmetologist": 3 },
  "53532": { "Cosmetologist": 2 },
  "68710": { "Cosmetologist": 1 },
  "61278": { "Cosmetologist": 1 },
  "50041": { "Cosmetologist": 1 },
  "61242": { "Cosmetologist": 1 },
  "55104": { "Cosmetologist": 5 },
  "34674": { "Cosmetologist": 3 },
  "52567": { "Cosmetologist": 2, "Barber": 1 },
  "60504": { "Cosmetologist": 4 },
  "53806": { "Cosmetologist": 1 },
  "53713": { "Cosmetologist": 2 },
  "71111": { "Cosmetologist": 4 },
  "52308": { "Cosmetologist": 1 },
  "52750": { "Cosmetologist": 1 },
  "53541": { "Cosmetologist": 1 },
  "52169": { "Cosmetologist": 1 },
  "53504": { "Cosmetologist": 1 },
  "71429": { "Cosmetologist": 1 },
  "51462": { "Cosmetologist": 4 },
  "55936": { "Cosmetologist": 2 },
  "61071": { "Cosmetologist": 3 },
  "55373": { "Cosmetologist": 1 },
  "50214": { "Cosmetologist": 1 },
  "68061": { "Cosmetologist": 2 },
  "51527": { "Cosmetologist": 1 },
  "61276": { "Cosmetologist": 1 },
  "50319": { "Cosmetologist": 1 },
  "50681": { "Cosmetologist": 1, "Barber": 2 },
  "61081": { "Cosmetologist": 3, "Barber": 1 },
  "57039": { "Cosmetologist": 2 },
  "68785": { "Cosmetologist": 1 },
  "55103": { "Cosmetologist": 1 },
  "22153": { "Cosmetologist": 1 },
  "52621": { "Cosmetologist": 3 },
  "48009": { "Cosmetologist": 119, "Barber": 5 },
  "53813": { "Cosmetologist": 2 },
  "76367": { "Cosmetologist": 76 },
  "50567": { "Cosmetologist": 1 },
  "52620": { "Cosmetologist": 1 },
  "52759": { "Cosmetologist": 1 },
  "29403": { "Cosmetologist": 2 },
  "50559": { "Cosmetologist": 2 },
  "61330": { "Cosmetologist": 1 },
  "52765": { "Cosmetologist": 3 },
  "53090": { "Cosmetologist": 1 },
  "68818": { "Cosmetologist": 1 },
  "55313": { "Cosmetologist": 3 },
  "56442": { "Cosmetologist": 2 },
  "85288": { "Cosmetologist": 1 },
  "50570": { "Cosmetologist": 1 },
  "53825": { "Cosmetologist": 1 },
  "56101": { "Cosmetologist": 1 },
  "51452": { "Cosmetologist": 2 },
  "63182": { "Cosmetologist": 1 },
  "89501": { "Cosmetologist": 1 },
  "20640": { "Cosmetologist": 1 },
  "52231": { "Cosmetologist": 1 },
  "65484": { "Cosmetologist": 1 },
  "52660": { "Cosmetologist": 2, "Barber": 1 },
  "68047": { "Cosmetologist": 1 },
  "92883": { "Cosmetologist": 1 },
  "70788": { "Cosmetologist": 1 },
  "40245": { "Cosmetologist": 7 },
  "61054": { "Cosmetologist": 2 },
  "51441": { "Cosmetologist": 2 },
  "51545": { "Cosmetologist": 1 },
  "52593": { "Cosmetologist": 1 },
  "50420": { "Cosmetologist": 1 },
  "50255": { "Cosmetologist": 1 },
  "51102": { "Cosmetologist": 1 },
  "57106": { "Cosmetologist": 1 },
  "53560": { "Cosmetologist": 1 },
  "61263": { "Cosmetologist": 1 },
  "51647": { "Cosmetologist": 1 },
  "50231": { "Cosmetologist": 1 },
  "60084": { "Cosmetologist": 3 },
  "61356": { "Cosmetologist": 1 },
  "50239": { "Cosmetologist": 1 },
  "84117": { "Cosmetologist": 1 },
  "50480": { "Cosmetologist": 1 },
  "98052": { "Cosmetologist": 2 },
  "56072": { "Cosmetologist": 1 },
  "51520": { "Cosmetologist": 1 },
  "50668": { "Cosmetologist": 1 },
  "52251": { "Cosmetologist": 1 },
  "77586": { "Cosmetologist": 171, "Barber": 8 },
  "52066": { "Cosmetologist": 1 },
  "58012": { "Cosmetologist": 1 },
  "48350": { "Cosmetologist": 70, "Barber": 4 },
  "50065": { "Cosmetologist": 1 },
  "61251": { "Cosmetologist": 1 },
  "61449": { "Cosmetologist": 1 },
  "51542": { "Cosmetologist": 1, "Barber": 1 },
  "52075": { "Cosmetologist": 1 },
  "50592": { "Cosmetologist": 1 },
  "57551": { "Barber": 1 },
  "50152": { "Barber": 1 },
  "68791": { "Barber": 1 },
  "61277": { "Barber": 1 },
  "50305": { "Barber": 2 },
  "37037": { "Barber": 15 },
  "37076": { "Barber": 29, "Cosmetologist": 5 },
  "37115": { "Barber": 44, "Cosmetologist": 1 },
  "37332": { "Barber": 2, "Cosmetologist": 1 },
  "38104": { "Barber": 10, "Cosmetologist": 2 },
  "38128": { "Barber": 63 },
  "38506": { "Barber": 57, "Cosmetologist": 4 },
  "37328": { "Barber": 1 },
  "37190": { "Barber": 17, "Cosmetologist": 1 },
  "38118": { "Barber": 30 },
  "38006": { "Barber": 2 },
  "37218": { "Barber": 26, "Cosmetologist": 3 },
  "38556": { "Barber": 21 },
  "38114": { "Barber": 30 },
  "38301": { "Barber": 31, "Cosmetologist": 1 },
  "38111": { "Barber": 29 },
  "37034": { "Barber": 7, "Cosmetologist": 2 },
  "38012": { "Barber": 21 },
  "37924": { "Barber": 10, "Cosmetologist": 1 },
  "38116": { "Barber": 61 },
  "38225": { "Barber": 2 },
  "37180": { "Barber": 6 },
  "38115": { "Barber": 36, "Cosmetologist": 1 },
  "37073": { "Barber": 12, "Cosmetologist": 1 },
  "38105": { "Barber": 8 },
  "37166": { "Barber": 32, "Cosmetologist": 4 },
  "38548": { "Barber": 3, "Cosmetologist": 1 },
  "38358": { "Barber": 10, "Cosmetologist": 4 },
  "38233": { "Barber": 1 },
  "38060": { "Barber": 13, "Cosmetologist": 3 },
  "37149": { "Barber": 7 },
  "38372": { "Barber": 5 },
  "37138": { "Barber": 19, "Cosmetologist": 5 },
  "37920": { "Barber": 20 },
  "38581": { "Barber": 7 },
  "37355": { "Barber": 21, "Cosmetologist": 2 },
  "38109": { "Barber": 60 },
  "37914": { "Barber": 13, "Cosmetologist": 2 },
  "37079": { "Barber": 4 },
  "38541": { "Barber": 6 },
  "37411": { "Barber": 17 },
  "37321": { "Barber": 4 },
  "38572": { "Barber": 23, "Cosmetologist": 4 },
  "37189": { "Barber": 8, "Cosmetologist": 1 },
  "38456": { "Barber": 1 },
  "37766": { "Barber": 7, "Cosmetologist": 1 },
  "37854": { "Barber": 6 },
  "37881": { "Barber": 1 },
  "38103": { "Barber": 13, "Cosmetologist": 1 },
  "37388": { "Barber": 18, "Cosmetologist": 1 },
  "37656": { "Barber": 7 },
  "37950": { "Barber": 3 },
  "37871": { "Barber": 5 },
  "38261": { "Barber": 5, "Cosmetologist": 1 },
  "37774": { "Barber": 11, "Cosmetologist": 8 },
  "38468": { "Barber": 3 },
  "37090": { "Barber": 17, "Cosmetologist": 1 },
  "38363": { "Barber": 2 },
  "38554": { "Barber": 2, "Cosmetologist": 1 },
  "38574": { "Barber": 11, "Cosmetologist": 3 },
  "38119": { "Barber": 18 },
  "37036": { "Barber": 1 },
  "38088": { "Barber": 4 },
  "38008": { "Barber": 10 },
  "37055": { "Barber": 15, "Cosmetologist": 2 },
  "38478": { "Barber": 5, "Cosmetologist": 2 },
  "37330": { "Barber": 7, "Cosmetologist": 1 },
  "38464": { "Barber": 7, "Cosmetologist": 5 },
  "38474": { "Barber": 3 },
  "37890": { "Barber": 3 },
  "38371": { "Barber": 1 },
  "38127": { "Barber": 46, "Cosmetologist": 1 },
  "37398": { "Barber": 8, "Cosmetologist": 4 },
  "37367": { "Barber": 12, "Cosmetologist": 1 },
  "38578": { "Barber": 1 },
  "37405": { "Barber": 6 },
  "37191": { "Barber": 2, "Cosmetologist": 1 },
  "37806": { "Barber": 2, "Cosmetologist": 1 },
  "37050": { "Barber": 1 },
  "37764": { "Barber": 4 },
  "38058": { "Barber": 4 },
  "37909": { "Barber": 5, "Cosmetologist": 1 },
  "38544": { "Barber": 18 },
  "37083": { "Barber": 6, "Cosmetologist": 1 },
  "38126": { "Barber": 5 },
  "37772": { "Barber": 6, "Cosmetologist": 2 },
  "37885": { "Barber": 3, "Cosmetologist": 2 },
  "37841": { "Barber": 5 },
  "38112": { "Barber": 10, "Cosmetologist": 1 },
  "37690": { "Barber": 2 },
  "37302": { "Barber": 3 },
  "37011": { "Barber": 4 },
  "37031": { "Barber": 5, "Cosmetologist": 1 },
  "38485": { "Barber": 1 },
  "37085": { "Barber": 17, "Cosmetologist": 2 },
  "38562": { "Barber": 3, "Cosmetologist": 1 },
  "37308": { "Barber": 1 },
  "37755": { "Barber": 2 },
  "37754": { "Barber": 8 },
  "37080": { "Barber": 5, "Cosmetologist": 1 },
  "38040": { "Barber": 4 },
  "37118": { "Barber": 4 },
  "37061": { "Barber": 3 },
  "38053": { "Barber": 13, "Cosmetologist": 2 },
  "37879": { "Barber": 5, "Cosmetologist": 2 },
  "38340": { "Barber": 3 },
  "37373": { "Barber": 2, "Cosmetologist": 1 },
  "38368": { "Barber": 1 },
  "37348": { "Barber": 1 },
  "37874": { "Barber": 5, "Cosmetologist": 2 },
  "38063": { "Barber": 10, "Cosmetologist": 1 },
  "37046": { "Barber": 4 },
  "37902": { "Barber": 1 },
  "37338": { "Barber": 1 },
  "37353": { "Barber": 1 },
  "37846": { "Barber": 3 },
  "37406": { "Barber": 10 },
  "38557": { "Barber": 3, "Cosmetologist": 1 },
  "38041": { "Barber": 3, "Cosmetologist": 1 },
  "37843": { "Barber": 1 },
  "37012": { "Barber": 5 },
  "37323": { "Barber": 5, "Cosmetologist": 4 },
  "37357": { "Barber": 5 },
  "38049": { "Barber": 4 },
  "37032": { "Barber": 2, "Cosmetologist": 1 },
  "38483": { "Barber": 2 },
  "37377": { "Barber": 3, "Cosmetologist": 2 },
  "37204": { "Barber": 7, "Cosmetologist": 4 },
  "37694": { "Barber": 1 },
  "37101": { "Barber": 2 },
  "37605": { "Barber": 1 },
  "38552": { "Barber": 2 },
  "37212": { "Barber": 3 },
  "37403": { "Barber": 2, "Cosmetologist": 1 },
  "37820": { "Barber": 5, "Cosmetologist": 1 },
  "37023": { "Barber": 1 },
  "37723": { "Barber": 3 },
  "37186": { "Barber": 8 },
  "37737": { "Barber": 1, "Cosmetologist": 2 },
  "38257": { "Barber": 1, "Cosmetologist": 2 },
  "37860": { "Barber": 2, "Cosmetologist": 3 },
  "37891": { "Barber": 1 },
  "37033": { "Barber": 3 },
  "38585": { "Barber": 4, "Cosmetologist": 1 },
  "38201": { "Barber": 5, "Cosmetologist": 2 },
  "38221": { "Barber": 2 },
  "37379": { "Barber": 7, "Cosmetologist": 2 },
  "37020": { "Barber": 9, "Cosmetologist": 1 },
  "37082": { "Barber": 3 },
  "38565": { "Barber": 2, "Cosmetologist": 1 },
  "37645": { "Barber": 2, "Cosmetologist": 1 },
  "37727": { "Barber": 2, "Cosmetologist": 1 },
  "37826": { "Barber": 2, "Cosmetologist": 2 },
  "37074": { "Barber": 3, "Cosmetologist": 2 },
  "38251": { "Barber": 1 },
  "37397": { "Barber": 3, "Cosmetologist": 1 },
  "37744": { "Barber": 1 },
  "38308": { "Barber": 1 },
  "37016": { "Barber": 3 },
  "37026": { "Barber": 2 },
  "38487": { "Barber": 3 },
  "38549": { "Barber": 5 },
  "37030": { "Barber": 5, "Cosmetologist": 1 },
  "37770": { "Barber": 1 },
  "37029": { "Barber": 5 },
  "38463": { "Barber": 2 },
  "38469": { "Barber": 2 },
  "37410": { "Barber": 4 },
  "38355": { "Barber": 4 },
  "37311": { "Barber": 5, "Cosmetologist": 1 },
  "37869": { "Barber": 1 },
  "37701": { "Barber": 4, "Cosmetologist": 2 },
  "37711": { "Barber": 1, "Cosmetologist": 2 },
  "37888": { "Barber": 2 },
  "37762": { "Barber": 3, "Cosmetologist": 1 },
  "37360": { "Barber": 1, "Cosmetologist": 1 },
  "37742": { "Barber": 2, "Cosmetologist": 1 },
  "38402": { "Barber": 1 },
  "37396": { "Barber": 1 },
  "38580": { "Barber": 2 },
  "38504": { "Barber": 5, "Cosmetologist": 1 },
  "37387": { "Barber": 2 },
  "38256": { "Barber": 2 },
  "38232": { "Barber": 1 },
  "38011": { "Barber": 2 },
  "38181": { "Barber": 2 },
  "37650": { "Barber": 3 },
  "38362": { "Barber": 3 },
  "37752": { "Barber": 1, "Cosmetologist": 2 },
  "38582": { "Barber": 1 },
  "38391": { "Barber": 1 },
  "37419": { "Barber": 1, "Cosmetologist": 1 },
  "38220": { "Barber": 1 },
  "38559": { "Barber": 1 },
  "38367": { "Barber": 1 },
  "38014": { "Barber": 1 },
  "37133": { "Barber": 2 },
  "37731": { "Barber": 1 },
  "38543": { "Barber": 3 },
  "38587": { "Barber": 4 },
  "37095": { "Barber": 2 },
  "37840": { "Barber": 3, "Cosmetologist": 1 },
  "37097": { "Barber": 2 },
  "37870": { "Barber": 2 },
  "38330": { "Barber": 2 },
  "38057": { "Barber": 2 },
  "38175": { "Barber": 2 },
  "38379": { "Barber": 1 },
  "37025": { "Barber": 3 },
  "37143": { "Barber": 1, "Cosmetologist": 1 },
  "38222": { "Barber": 1 },
  "37047": { "Barber": 2 },
  "37339": { "Barber": 2 },
  "37366": { "Barber": 1 },
  "38563": { "Barber": 2 },
  "37185": { "Barber": 5, "Cosmetologist": 3 },
  "37939": { "Barber": 2 },
  "37709": { "Barber": 2 },
  "37852": { "Barber": 1 },
  "38329": { "Barber": 1, "Cosmetologist": 1 },
  "37329": { "Barber": 5, "Cosmetologist": 2 },
  "37059": { "Barber": 1 },
  "37872": { "Barber": 2 },
  "37198": { "Barber": 1 },
  "37028": { "Barber": 1 },
  "38573": { "Barber": 2 },
  "38579": { "Barber": 1 },
  "37916": { "Barber": 1 },
  "37018": { "Barber": 1 },
  "37769": { "Barber": 3 },
  "38190": { "Barber": 2 },
  "37181": { "Barber": 2 },
  "37116": { "Barber": 1 },
  "38547": { "Barber": 3 },
  "38370": { "Barber": 1 },
  "37134": { "Barber": 1 },
  "38260": { "Barber": 1 },
  "38173": { "Barber": 1 },
  "37829": { "Barber": 2, "Cosmetologist": 1 },
  "37325": { "Barber": 1 },
  "37337": { "Barber": 3 },
  "37352": { "Barber": 2 },
  "38039": { "Barber": 1 },
  "38101": { "Barber": 1 },
  "37150": { "Barber": 2 },
  "37183": { "Barber": 2 },
  "37334": { "Barber": 2 },
  "37738": { "Barber": 1, "Cosmetologist": 1 },
  "37305": { "Barber": 1 },
  "37688": { "Barber": 1 },
  "37142": { "Barber": 1, "Cosmetologist": 1 },
  "37229": { "Barber": 1 },
  "37327": { "Barber": 3, "Cosmetologist": 1 },
  "37422": { "Barber": 1 },
  "37335": { "Barber": 3 },
  "38075": { "Barber": 1 },
  "37228": { "Barber": 1, "Cosmetologist": 1 },
  "38551": { "Barber": 2 },
  "38001": { "Barber": 1 },
  "37162": { "Barber": 1 },
  "38462": { "Barber": 3, "Cosmetologist": 1 },
  "37810": { "Barber": 1 },
  "38326": { "Barber": 1, "Cosmetologist": 1 },
  "37385": { "Barber": 2, "Cosmetologist": 1 },
  "38502": { "Barber": 1 },
  "37714": { "Barber": 2 },
  "37831": { "Barber": 1 },
  "38069": { "Barber": 1 },
  "38037": { "Barber": 1 },
  "38375": { "Barber": 1 },
  "38382": { "Barber": 1, "Cosmetologist": 1 },
  "37340": { "Barber": 1 },
  "37144": { "Barber": 1 },
  "38321": { "Barber": 2 },
  "37409": { "Barber": 1 },
  "37351": { "Barber": 1 },
  "37717": { "Barber": 1 },
  "37811": { "Barber": 1, "Cosmetologist": 1 },
  "38052": { "Barber": 1 },
  "38374": { "Barber": 1 },
  "38168": { "Barber": 1 },
  "08360": { "Barber": 102, "Cosmetologist": 197 },
  "08066": { "Barber": 18, "Cosmetologist": 32 },
  "07065": { "Barber": 64, "Cosmetologist": 125 },
  "07208": { "Barber": 40, "Cosmetologist": 130 },
  "07305": { "Barber": 100, "Cosmetologist": 161 },
  "08070": { "Barber": 18, "Cosmetologist": 46 },
  "08105": { "Barber": 46, "Cosmetologist": 52 },
  "08902": { "Barber": 52, "Cosmetologist": 158 },
  "07450": { "Barber": 10, "Cosmetologist": 45 },
  "07503": { "Barber": 58, "Cosmetologist": 59 },
  "07032": { "Barber": 92, "Cosmetologist": 195 },
  "07662": { "Barber": 18, "Cosmetologist": 39 },
  "07011": { "Barber": 90, "Cosmetologist": 210 },
  "08638": { "Barber": 72, "Cosmetologist": 77 },
  "07205": { "Barber": 68, "Cosmetologist": 91 },
  "07112": { "Barber": 70, "Cosmetologist": 34 },
  "07042": { "Barber": 60, "Cosmetologist": 61 },
  "07103": { "Barber": 80, "Cosmetologist": 78 },
  "07074": { "Barber": 16, "Cosmetologist": 18 },
  "08648": { "Barber": 42, "Cosmetologist": 98 },
  "07052": { "Barber": 66, "Cosmetologist": 158 },
  "08807": { "Barber": 30, "Cosmetologist": 170 },
  "07060": { "Barber": 104, "Cosmetologist": 165 },
  "07424": { "Barber": 88, "Cosmetologist": 185 },
  "07111": { "Barber": 106, "Cosmetologist": 131 },
  "07067": { "Barber": 40, "Cosmetologist": 106 },
  "08096": { "Barber": 54, "Cosmetologist": 280 },
  "01915": { "Barber": 12 },
  "07302": { "Barber": 26, "Cosmetologist": 50 },
  "03329": { "Barber": 2 },
  "03264": { "Barber": 4 },
  "07724": { "Barber": 32, "Cosmetologist": 130 },
  "07104": { "Barber": 100, "Cosmetologist": 160 },
  "07027": { "Barber": 12, "Cosmetologist": 30 },
  "08751": { "Barber": 14, "Cosmetologist": 28 },
  "07010": { "Barber": 42, "Cosmetologist": 152 },
  "07108": { "Barber": 56, "Cosmetologist": 48 },
  "07643": { "Barber": 20, "Cosmetologist": 104 },
  "08234": { "Barber": 50, "Cosmetologist": 259 },
  "07869": { "Barber": 24, "Cosmetologist": 98 },
  "07087": { "Barber": 98, "Cosmetologist": 257 },
  "07644": { "Barber": 88, "Cosmetologist": 132 },
  "01911": { "Barber": 20 },
  "07470": { "Barber": 96, "Cosmetologist": 315 },
  "09278": { "Barber": 2 },
  "08812": { "Barber": 18, "Cosmetologist": 95 },
  "07630": { "Barber": 6, "Cosmetologist": 35 },
  "07306": { "Barber": 56, "Cosmetologist": 122 },
  "07034": { "Barber": 18, "Cosmetologist": 64 },
  "03341": { "Barber": 6 },
  "08830": { "Barber": 20, "Cosmetologist": 65 },
  "08901": { "Barber": 44, "Cosmetologist": 102 },
  "08107": { "Barber": 24, "Cosmetologist": 55 },
  "03332": { "Barber": 10 },
  "07093": { "Barber": 94, "Cosmetologist": 264 },
  "07960": { "Barber": 42, "Cosmetologist": 110 },
  "07029": { "Barber": 30, "Cosmetologist": 50 },
  "07022": { "Barber": 22, "Cosmetologist": 68 },
  "07105": { "Barber": 64, "Cosmetologist": 170 },
  "07090": { "Barber": 28, "Cosmetologist": 52 },
  "08078": { "Barber": 24, "Cosmetologist": 62 },
  "08094": { "Barber": 46, "Cosmetologist": 265 },
  "08102": { "Barber": 12, "Cosmetologist": 5 },
  "08879": { "Barber": 38, "Cosmetologist": 118 },
  "08628": { "Barber": 30, "Cosmetologist": 48 },
  "08052": { "Barber": 26, "Cosmetologist": 106 },
  "03460": { "Barber": 2 },
  "07018": { "Barber": 60, "Cosmetologist": 79 },
  "08863": { "Barber": 26, "Cosmetologist": 67 },
  "03215": { "Barber": 2 },
  "08002": { "Barber": 36, "Cosmetologist": 123 },
  "07107": { "Barber": 56, "Cosmetologist": 143 },
  "07762": { "Barber": 6, "Cosmetologist": 26 },
  "08069": { "Barber": 38, "Cosmetologist": 44 },
  "01830": { "Barber": 10 },
  "08618": { "Barber": 124, "Cosmetologist": 119 },
  "08701": { "Barber": 72, "Cosmetologist": 98 },
  "08104": { "Barber": 46, "Cosmetologist": 38 },
  "07666": { "Barber": 26, "Cosmetologist": 132 },
  "03211": { "Barber": 6 },
  "01996": { "Barber": 6 },
  "01914": { "Barber": 56 },
  "08035": { "Barber": 10, "Cosmetologist": 40 },
  "03474": { "Barber": 6 },
  "07201": { "Barber": 54, "Cosmetologist": 100 },
  "08859": { "Barber": 22, "Cosmetologist": 126 },
  "07024": { "Barber": 34, "Cosmetologist": 261 },
  "07202": { "Barber": 68, "Cosmetologist": 176 },
  "08742": { "Barber": 42, "Cosmetologist": 159 },
  "08232": { "Barber": 36, "Cosmetologist": 63 },
  "08757": { "Barber": 164, "Cosmetologist": 195 },
  "08722": { "Barber": 16, "Cosmetologist": 101 },
  "07114": { "Barber": 20, "Cosmetologist": 25 },
  "08203": { "Barber": 30, "Cosmetologist": 53 },
  "01137": { "Barber": 10 },
  "01143": { "Barber": 4 },
  "07417": { "Barber": 12, "Cosmetologist": 50 },
  "07092": { "Barber": 12, "Cosmetologist": 23 },
  "07076": { "Barber": 42, "Cosmetologist": 101 },
  "07866": { "Barber": 30, "Cosmetologist": 139 },
  "08861": { "Barber": 88, "Cosmetologist": 234 },
  "07720": { "Barber": 10, "Cosmetologist": 25 },
  "08012": { "Barber": 48, "Cosmetologist": 260 },
  "07026": { "Barber": 60, "Cosmetologist": 163 },
  "07940": { "Barber": 20, "Cosmetologist": 34 },
  "07003": { "Barber": 108, "Cosmetologist": 188 },
  "08240": { "Barber": 4, "Cosmetologist": 2 },
  "07509": { "Barber": 6, "Cosmetologist": 1 },
  "08302": { "Barber": 64, "Cosmetologist": 160 },
  "07030": { "Barber": 66, "Cosmetologist": 66 },
  "08033": { "Barber": 18, "Cosmetologist": 36 },
  "07504": { "Barber": 24, "Cosmetologist": 56 },
  "07661": { "Barber": 8, "Cosmetologist": 48 },
  "08054": { "Barber": 38, "Cosmetologist": 169 },
  "08820": { "Barber": 44, "Cosmetologist": 77 },
  "07055": { "Barber": 84, "Cosmetologist": 175 },
  "08724": { "Barber": 54, "Cosmetologist": 335 },
  "07660": { "Barber": 18, "Cosmetologist": 68 },
  "07203": { "Barber": 52, "Cosmetologist": 99 },
  "07206": { "Barber": 52, "Cosmetologist": 76 },
  "08619": { "Barber": 72, "Cosmetologist": 201 },
  "08091": { "Barber": 12, "Cosmetologist": 32 },
  "07080": { "Barber": 46, "Cosmetologist": 131 },
  "08103": { "Barber": 24, "Cosmetologist": 15 },
  "03466": { "Barber": 20 },
  "07405": { "Barber": 18, "Cosmetologist": 101 },
  "07071": { "Barber": 38, "Cosmetologist": 160 },
  "08848": { "Barber": 8, "Cosmetologist": 64 },
  "07012": { "Barber": 20, "Cosmetologist": 56 },
  "01900": { "Barber": 12 },
  "01046": { "Barber": 12 },
  "07740": { "Barber": 44, "Cosmetologist": 116 },
  "07514": { "Barber": 62, "Cosmetologist": 66 },
  "07058": { "Barber": 20, "Cosmetologist": 30 },
  "07606": { "Barber": 16, "Cosmetologist": 14 },
  "08505": { "Barber": 22, "Cosmetologist": 114 },
  "07730": { "Barber": 22, "Cosmetologist": 122 },
  "08758": { "Barber": 8, "Cosmetologist": 42 },
  "07410": { "Barber": 42, "Cosmetologist": 152 },
  "07834": { "Barber": 18, "Cosmetologist": 88 },
  "08087": { "Barber": 44, "Cosmetologist": 165 },
  "03346": { "Barber": 16 },
  "07756": { "Barber": 8, "Cosmetologist": 8 },
  "07701": { "Barber": 56, "Cosmetologist": 114 },
  "03312": { "Barber": 12 },
  "08110": { "Barber": 28, "Cosmetologist": 62 },
  "07421": { "Barber": 10, "Cosmetologist": 41 },
  "08527": { "Barber": 54, "Cosmetologist": 358 },
  "08873": { "Barber": 64, "Cosmetologist": 204 },
  "08009": { "Barber": 11, "Cosmetologist": 87 },
  "08046": { "Barber": 46, "Cosmetologist": 183 },
  "08221": { "Barber": 16, "Cosmetologist": 29 },
  "07506": { "Barber": 48, "Cosmetologist": 134 },
  "08332": { "Barber": 46, "Cosmetologist": 116 },
  "07734": { "Barber": 16, "Cosmetologist": 78 },
  "07083": { "Barber": 104, "Cosmetologist": 365 },
  "08251": { "Barber": 22, "Cosmetologist": 54 },
  "08837": { "Barber": 37, "Cosmetologist": 54 },
  "09110": { "Barber": 2 },
  "08049": { "Barber": 20, "Cosmetologist": 48 },
  "07017": { "Barber": 62, "Cosmetologist": 108 },
  "07106": { "Barber": 68, "Cosmetologist": 62 },
  "07628": { "Barber": 26, "Cosmetologist": 93 },
  "08344": { "Barber": 18, "Cosmetologist": 43 },
  "08801": { "Barber": 4, "Cosmetologist": 22 },
  "08810": { "Barber": 12, "Cosmetologist": 22 },
  "07856": { "Barber": 6, "Cosmetologist": 32 },
  "07016": { "Barber": 34, "Cosmetologist": 95 },
  "07304": { "Barber": 74, "Cosmetologist": 125 },
  "01801": { "Barber": 10, "Cosmetologist": 1 },
  "07728": { "Barber": 62, "Cosmetologist": 308 },
  "08053": { "Barber": 50, "Cosmetologist": 240 },
  "07095": { "Barber": 36, "Cosmetologist": 104 },
  "08629": { "Barber": 32, "Cosmetologist": 37 },
  "07013": { "Barber": 72, "Cosmetologist": 160 },
  "07512": { "Barber": 52, "Cosmetologist": 92 },
  "07801": { "Barber": 50, "Cosmetologist": 149 },
  "02930": { "Barber": 2 },
  "07043": { "Barber": 8, "Cosmetologist": 8 },
  "08037": { "Barber": 60, "Cosmetologist": 141 },
  "03316": { "Barber": 14 },
  "03302": { "Barber": 14 },
  "08205": { "Barber": 26, "Cosmetologist": 167 },
  "08611": { "Barber": 52, "Cosmetologist": 47 },
  "07439": { "Barber": 2, "Cosmetologist": 13 },
  "01913": { "Barber": 16 },
  "03414": { "Barber": 4 },
  "08822": { "Barber": 16, "Cosmetologist": 170 },
  "07871": { "Barber": 12, "Cosmetologist": 101 },
  "08204": { "Barber": 26, "Cosmetologist": 77 },
  "06413": { "Barber": 2 },
  "03461": { "Barber": 2 },
  "08029": { "Barber": 28, "Cosmetologist": 49 },
  "02952": { "Barber": 2 },
  "08045": { "Barber": 10, "Cosmetologist": 8 },
  "08215": { "Barber": 34, "Cosmetologist": 64 },
  "08731": { "Barber": 34, "Cosmetologist": 196 },
  "08030": { "Barber": 22, "Cosmetologist": 57 },
  "07924": { "Barber": 8, "Cosmetologist": 27 },
  "07059": { "Barber": 16, "Cosmetologist": 76 },
  "08911": { "Barber": 4 },
  "07739": { "Barber": 10, "Cosmetologist": 13 },
  "01096": { "Barber": 8 },
  "03281": { "Barber": 6 },
  "08844": { "Barber": 18, "Cosmetologist": 201 },
  "01804": { "Barber": 28 },
  "07407": { "Barber": 56, "Cosmetologist": 141 },
  "08904": { "Barber": 14, "Cosmetologist": 43 },
  "08031": { "Barber": 34, "Cosmetologist": 73 },
  "08109": { "Barber": 46, "Cosmetologist": 121 },
  "07072": { "Barber": 12, "Cosmetologist": 46 },
  "08759": { "Barber": 98, "Cosmetologist": 143 },
  "07050": { "Barber": 58, "Cosmetologist": 104 },
  "03296": { "Barber": 6 },
  "07009": { "Barber": 36, "Cosmetologist": 78 },
  "07419": { "Barber": 10, "Cosmetologist": 86 },
  "07036": { "Barber": 118, "Cosmetologist": 210 },
  "08854": { "Barber": 50, "Cosmetologist": 215 },
  "07031": { "Barber": 26, "Cosmetologist": 92 },
  "07719": { "Barber": 32, "Cosmetologist": 108 },
  "07307": { "Barber": 38, "Cosmetologist": 117 },
  "07006": { "Barber": 34, "Cosmetologist": 141 },
  "07109": { "Barber": 88, "Cosmetologist": 289 },
  "07920": { "Barber": 14, "Cosmetologist": 46 },
  "08872": { "Barber": 24, "Cosmetologist": 97 },
  "08755": { "Barber": 28, "Cosmetologist": 142 },
  "07033": { "Barber": 26, "Cosmetologist": 108 },
  "08097": { "Barber": 10, "Cosmetologist": 30 },
  "08083": { "Barber": 20, "Cosmetologist": 67 },
  "07511": { "Barber": 4 },
  "07839": { "Barber": 4 },
  "07885": { "Barber": 12, "Cosmetologist": 90 },
  "08857": { "Barber": 30, "Cosmetologist": 281 },
  "07461": { "Barber": 12, "Cosmetologist": 172 },
  "07082": { "Barber": 8, "Cosmetologist": 25 },
  "08028": { "Barber": 68, "Cosmetologist": 99 },
  "07712": { "Barber": 70, "Cosmetologist": 195 },
  "08805": { "Barber": 26, "Cosmetologist": 66 },
  "07073": { "Barber": 16, "Cosmetologist": 64 },
  "09547": { "Barber": 2 },
  "01030": { "Barber": 18 },
  "07650": { "Barber": 14, "Cosmetologist": 465 },
  "07502": { "Barber": 46, "Cosmetologist": 63 },
  "07035": { "Barber": 14, "Cosmetologist": 71 },
  "03370": { "Barber": 14 },
  "07028": { "Barber": 6, "Cosmetologist": 9 },
  "08753": { "Barber": 138, "Cosmetologist": 626 },
  "07603": { "Barber": 6, "Cosmetologist": 45 },
  "03495": { "Barber": 8 },
  "08075": { "Barber": 64, "Cosmetologist": 146 },
  "08865": { "Barber": 42, "Cosmetologist": 146 },
  "02001": { "Barber": 2 },
  "07930": { "Barber": 10, "Cosmetologist": 29 },
  "08010": { "Barber": 22, "Cosmetologist": 57 },
  "07110": { "Barber": 110, "Cosmetologist": 162 },
  "03361": { "Barber": 14 },
  "09283": { "Barber": 2 },
  "01905": { "Barber": 26 },
  "08540": { "Barber": 12, "Cosmetologist": 67 },
  "03390": { "Barber": 12 },
  "07621": { "Barber": 32, "Cosmetologist": 86 },
  "07934": { "Barber": 4, "Cosmetologist": 5 },
  "08106": { "Barber": 22, "Cosmetologist": 62 },
  "07465": { "Barber": 14, "Cosmetologist": 37 },
  "08108": { "Barber": 36, "Cosmetologist": 59 },
  "03220": { "Barber": 2 },
  "08098": { "Barber": 14, "Cosmetologist": 47 },
  "08690": { "Barber": 48, "Cosmetologist": 171 },
  "07066": { "Barber": 22, "Cosmetologist": 98 },
  "07649": { "Barber": 10, "Cosmetologist": 19 },
  "03413": { "Barber": 8 },
  "08051": { "Barber": 22, "Cosmetologist": 98 },
  "08361": { "Barber": 22, "Cosmetologist": 109 },
  "01896": { "Barber": 4 },
  "07849": { "Barber": 4, "Cosmetologist": 49 },
  "07733": { "Barber": 12, "Cosmetologist": 49 },
  "07008": { "Barber": 58, "Cosmetologist": 84 },
  "02756": { "Barber": 6 },
  "08610": { "Barber": 78, "Cosmetologist": 200 },
  "07508": { "Barber": 64, "Cosmetologist": 153 },
  "07607": { "Barber": 16, "Cosmetologist": 61 },
  "07711": { "Barber": 2, "Cosmetologist": 7 },
  "02455": { "Barber": 2 },
  "08750": { "Barber": 6, "Cosmetologist": 10 },
  "07077": { "Barber": 6, "Cosmetologist": 17 },
  "07075": { "Barber": 20, "Cosmetologist": 56 },
  "08835": { "Barber": 22, "Cosmetologist": 63 },
  "01835": { "Barber": 4 },
  "07094": { "Barber": 30, "Cosmetologist": 68 },
  "07702": { "Barber": 4, "Cosmetologist": 13 },
  "08406": { "Barber": 30, "Cosmetologist": 36 },
  "08735": { "Barber": 4, "Cosmetologist": 14 },
  "08867": { "Barber": 4, "Cosmetologist": 20 },
  "03349": { "Barber": 6 },
  "07857": { "Barber": 20, "Cosmetologist": 27 },
  "08691": { "Barber": 28, "Cosmetologist": 75 },
  "07524": { "Barber": 28, "Cosmetologist": 45 },
  "08057": { "Barber": 16, "Cosmetologist": 52 },
  "01894": { "Barber": 10 },
  "07980": { "Barber": 8, "Cosmetologist": 12 },
  "03290": { "Barber": 4 },
  "07004": { "Barber": 12, "Cosmetologist": 90 },
  "08016": { "Barber": 52, "Cosmetologist": 150 },
  "07646": { "Barber": 20, "Cosmetologist": 81 },
  "08886": { "Barber": 10, "Cosmetologist": 30 },
  "07014": { "Barber": 8, "Cosmetologist": 21 },
  "07081": { "Barber": 40, "Cosmetologist": 78 },
  "07663": { "Barber": 24, "Cosmetologist": 119 },
  "07481": { "Barber": 18, "Cosmetologist": 45 },
  "07757": { "Barber": 4, "Cosmetologist": 28 },
  "03301": { "Barber": 18 },
  "07605": { "Barber": 6, "Cosmetologist": 124 },
  "07731": { "Barber": 34, "Cosmetologist": 315 },
  "08027": { "Barber": 6, "Cosmetologist": 43 },
  "03314": { "Barber": 12 },
  "01944": { "Barber": 2 },
  "03031": { "Barber": 2 },
  "07981": { "Barber": 22, "Cosmetologist": 59 },
  "08023": { "Barber": 2, "Cosmetologist": 2 },
  "07062": { "Barber": 34, "Cosmetologist": 57 },
  "08318": { "Barber": 22, "Cosmetologist": 81 },
  "08518": { "Barber": 16, "Cosmetologist": 28 },
  "07102": { "Barber": 36, "Cosmetologist": 33 },
  "08502": { "Barber": 4, "Cosmetologist": 21 },
  "07735": { "Barber": 34, "Cosmetologist": 148 },
  "08528": { "Barber": 6, "Cosmetologist": 1 },
  "03347": { "Barber": 2 },
  "01093": { "Barber": 2 },
  "03300": { "Barber": 6 },
  "01136": { "Barber": 14 },
  "08055": { "Barber": 16, "Cosmetologist": 93 },
  "07063": { "Barber": 12, "Cosmetologist": 53 },
  "07652": { "Barber": 34, "Cosmetologist": 180 },
  "07753": { "Barber": 66, "Cosmetologist": 204 },
  "08875": { "Barber": 2, "Cosmetologist": 4 },
  "07843": { "Barber": 18, "Cosmetologist": 98 },
  "07480": { "Barber": 12, "Cosmetologist": 92 },
  "08542": { "Barber": 4, "Cosmetologist": 11 },
  "03270": { "Barber": 4 },
  "03318": { "Barber": 8 },
  "03317": { "Barber": 10 },
  "01906": { "Barber": 28 },
  "02360": { "Barber": 2, "Cosmetologist": 1 },
  "08059": { "Barber": 14, "Cosmetologist": 41 },
  "07758": { "Barber": 14, "Cosmetologist": 35 },
  "07876": { "Barber": 14, "Cosmetologist": 75 },
  "08043": { "Barber": 14, "Cosmetologist": 118 },
  "07624": { "Barber": 8, "Cosmetologist": 34 },
  "07860": { "Barber": 24, "Cosmetologist": 149 },
  "01870": { "Barber": 14 },
  "07436": { "Barber": 12, "Cosmetologist": 64 },
  "03008": { "Barber": 2 },
  "08534": { "Barber": 4, "Cosmetologist": 34 },
  "08402": { "Barber": 16, "Cosmetologist": 22 },
  "01846": { "Barber": 6 },
  "07054": { "Barber": 32, "Cosmetologist": 127 },
  "07604": { "Barber": 16, "Cosmetologist": 64 },
  "08840": { "Barber": 14, "Cosmetologist": 56 },
  "08063": { "Barber": 10, "Cosmetologist": 28 },
  "08403": { "Barber": 4, "Cosmetologist": 1 },
  "02917": { "Barber": 2 },
  "07631": { "Barber": 24, "Cosmetologist": 104 },
  "04107": { "Barber": 2 },
  "07452": { "Barber": 6, "Cosmetologist": 23 },
  "07513": { "Barber": 16, "Cosmetologist": 50 },
  "07601": { "Barber": 80, "Cosmetologist": 205 },
  "08226": { "Barber": 18, "Cosmetologist": 26 },
  "08034": { "Barber": 16, "Cosmetologist": 89 },
  "02854": { "Barber": 4 },
  "08723": { "Barber": 54, "Cosmetologist": 282 },
  "03498": { "Barber": 8 },
  "08533": { "Barber": 10, "Cosmetologist": 53 },
  "03303": { "Barber": 4 },
  "08609": { "Barber": 38, "Cosmetologist": 28 },
  "03444": { "Barber": 2 },
  "07438": { "Barber": 12, "Cosmetologist": 80 },
  "02452": { "Barber": 4 },
  "07751": { "Barber": 14, "Cosmetologist": 104 },
  "08817": { "Barber": 52, "Cosmetologist": 190 },
  "07005": { "Barber": 22, "Cosmetologist": 75 },
  "08090": { "Barber": 6, "Cosmetologist": 69 },
  "03857": { "Barber": 2, "Cosmetologist": 1 },
  "03029": { "Barber": 2 },
  "08809": { "Barber": 10, "Cosmetologist": 32 },
  "08244": { "Barber": 14, "Cosmetologist": 61 },
  "03661": { "Barber": 2 },
  "08003": { "Barber": 24, "Cosmetologist": 108 },
  "03348": { "Barber": 6 },
  "07760": { "Barber": 8, "Cosmetologist": 17 },
  "07463": { "Barber": 16, "Cosmetologist": 52 },
  "08535": { "Barber": 6, "Cosmetologist": 30 },
  "08882": { "Barber": 26, "Cosmetologist": 76 },
  "07755": { "Barber": 18, "Cosmetologist": 32 },
  "08829": { "Barber": 10, "Cosmetologist": 22 },
  "03478": { "Barber": 6 },
  "03307": { "Barber": 6 },
  "08349": { "Barber": 6, "Cosmetologist": 10 },
  "02850": { "Barber": 2 },
  "08831": { "Barber": 36, "Cosmetologist": 230 },
  "09263": { "Barber": 2 },
  "08260": { "Barber": 30, "Cosmetologist": 50 },
  "07648": { "Barber": 4, "Cosmetologist": 23 },
  "07974": { "Barber": 14, "Cosmetologist": 29 },
  "07522": { "Barber": 26, "Cosmetologist": 72 },
  "03212": { "Barber": 8 },
  "03381": { "Barber": 4 },
  "08322": { "Barber": 12, "Cosmetologist": 78 },
  "07430": { "Barber": 14, "Cosmetologist": 92 },
  "08887": { "Barber": 2, "Cosmetologist": 6 },
  "07044": { "Barber": 26, "Cosmetologist": 74 },
  "05150": { "Barber": 2 },
  "08889": { "Barber": 4, "Cosmetologist": 52 },
  "01796": { "Barber": 2 },
  "07847": { "Barber": 10, "Cosmetologist": 12 },
  "08512": { "Barber": 4, "Cosmetologist": 38 },
  "07836": { "Barber": 6, "Cosmetologist": 81 },
  "01195": { "Barber": 2 },
  "03398": { "Barber": 6 },
  "08330": { "Barber": 44, "Cosmetologist": 133 },
  "08404": { "Barber": 4, "Cosmetologist": 2 },
  "03465": { "Barber": 12 },
  "08312": { "Barber": 10, "Cosmetologist": 48 },
  "08551": { "Barber": 8, "Cosmetologist": 20 },
  "03560": { "Barber": 2 },
  "01910": { "Barber": 2 },
  "03428": { "Barber": 2 },
  "08736": { "Barber": 6, "Cosmetologist": 54 },
  "07726": { "Barber": 36, "Cosmetologist": 234 },
  "03271": { "Barber": 2 },
  "01993": { "Barber": 4 },
  "08210": { "Barber": 20, "Cosmetologist": 100 },
  "08084": { "Barber": 10, "Cosmetologist": 35 },
  "08910": { "Barber": 4 },
  "01908": { "Barber": 2 },
  "03423": { "Barber": 10 },
  "03626": { "Barber": 2 },
  "08079": { "Barber": 28, "Cosmetologist": 37 },
  "08020": { "Barber": 2, "Cosmetologist": 23 },
  "06457": { "Barber": 2 },
  "02838": { "Barber": 2 },
  "07432": { "Barber": 8, "Cosmetologist": 44 },
  "01895": { "Barber": 4 },
  "08310": { "Barber": 4, "Cosmetologist": 4 },
  "08620": { "Barber": 22, "Cosmetologist": 118 },
  "08022": { "Barber": 10, "Cosmetologist": 35 },
  "07040": { "Barber": 24, "Cosmetologist": 34 },
  "01956": { "Barber": 2 },
  "08077": { "Barber": 20, "Cosmetologist": 74 },
  "08558": { "Barber": 4, "Cosmetologist": 10 },
  "07852": { "Barber": 12, "Cosmetologist": 26 },
  "02851": { "Barber": 4 },
  "07657": { "Barber": 14, "Cosmetologist": 199 },
  "08554": { "Barber": 10, "Cosmetologist": 18 },
  "08828": { "Barber": 4, "Cosmetologist": 20 },
  "02192": { "Barber": 2 },
  "01513": { "Barber": 2 },
  "02315": { "Barber": 2 },
  "07832": { "Barber": 8, "Cosmetologist": 21 },
  "07041": { "Barber": 12, "Cosmetologist": 9 },
  "01822": { "Barber": 4 },
  "03669": { "Barber": 2 },
  "01003": { "Barber": 6 },
  "08721": { "Barber": 30, "Cosmetologist": 181 },
  "03410": { "Barber": 6 },
  "08201": { "Barber": 12, "Cosmetologist": 46 },
  "08048": { "Barber": 6, "Cosmetologist": 61 },
  "03262": { "Barber": 2 },
  "03313": { "Barber": 16 },
  "08846": { "Barber": 34, "Cosmetologist": 100 },
  "03292": { "Barber": 4 },
  "07704": { "Barber": 6, "Cosmetologist": 9 },
  "07001": { "Barber": 14, "Cosmetologist": 62 },
  "07821": { "Barber": 8, "Cosmetologist": 39 },
  "08823": { "Barber": 4, "Cosmetologist": 42 },
  "03319": { "Barber": 6 },
  "02753": { "Barber": 2 },
  "07746": { "Barber": 4, "Cosmetologist": 67 },
  "08903": { "Barber": 6, "Cosmetologist": 1 },
  "02121": { "Barber": 2 },
  "08071": { "Barber": 12, "Cosmetologist": 83 },
  "01120": { "Barber": 4 },
  "03141": { "Barber": 2 },
  "08230": { "Barber": 6, "Cosmetologist": 37 },
  "03468": { "Barber": 6 },
  "08740": { "Barber": 4, "Cosmetologist": 9 },
  "09800": { "Barber": 2 },
  "02751": { "Barber": 2 },
  "03004": { "Barber": 4 },
  "01000": { "Barber": 4 },
  "03282": { "Barber": 6 },
  "03708": { "Barber": 4 },
  "07423": { "Barber": 6, "Cosmetologist": 10 },
  "08088": { "Barber": 46, "Cosmetologist": 107 },
  "07626": { "Barber": 8, "Cosmetologist": 32 },
  "03213": { "Barber": 8 },
  "01802": { "Barber": 4 },
  "07640": { "Barber": 4, "Cosmetologist": 9 },
  "02983": { "Barber": 2 },
  "08060": { "Barber": 42, "Cosmetologist": 126 },
  "01907": { "Barber": 2 },
  "01203": { "Barber": 2 },
  "08530": { "Barber": 12, "Cosmetologist": 19 },
  "07922": { "Barber": 12, "Cosmetologist": 35 },
  "07928": { "Barber": 8, "Cosmetologist": 18 },
  "07446": { "Barber": 22, "Cosmetologist": 43 },
  "02840": { "Barber": 2, "Cosmetologist": 1 },
  "08026": { "Barber": 4, "Cosmetologist": 18 },
  "07416": { "Barber": 10, "Cosmetologist": 37 },
  "03376": { "Barber": 6 },
  "01705": { "Barber": 4 },
  "08085": { "Barber": 12, "Cosmetologist": 113 },
  "08036": { "Barber": 6, "Cosmetologist": 24 },
  "02822": { "Barber": 2 },
  "03716": { "Barber": 2 },
  "02770": { "Barber": 4 },
  "07882": { "Barber": 8, "Cosmetologist": 99 },
  "02837": { "Barber": 2 },
  "08213": { "Barber": 2, "Cosmetologist": 1 },
  "02956": { "Barber": 4, "Cosmetologist": 1 },
  "01832": { "Barber": 8 },
  "01090": { "Barber": 2 },
  "08350": { "Barber": 4, "Cosmetologist": 6 },
  "07440": { "Barber": 12, "Cosmetologist": 36 },
  "07086": { "Barber": 8, "Cosmetologist": 40 },
  "03274": { "Barber": 2 },
  "03375": { "Barber": 4 },
  "08352": { "Barber": 2, "Cosmetologist": 3 },
  "01960": { "Barber": 2 },
  "01001": { "Barber": 2 },
  "07641": { "Barber": 4, "Cosmetologist": 6 },
  "02855": { "Barber": 4 },
  "03244": { "Barber": 2 },
  "07676": { "Barber": 4, "Cosmetologist": 44 },
  "07945": { "Barber": 6, "Cosmetologist": 18 },
  "08732": { "Barber": 2, "Cosmetologist": 9 },
  "08223": { "Barber": 2, "Cosmetologist": 21 },
  "07933": { "Barber": 10, "Cosmetologist": 11 },
  "08089": { "Barber": 8, "Cosmetologist": 28 },
  "07023": { "Barber": 18, "Cosmetologist": 22 },
  "07932": { "Barber": 16, "Cosmetologist": 57 },
  "07420": { "Barber": 4, "Cosmetologist": 35 },
  "08320": { "Barber": 2 },
  "03395": { "Barber": 2 },
  "08562": { "Barber": 8, "Cosmetologist": 20 },
  "03499": { "Barber": 6 },
  "07737": { "Barber": 4, "Cosmetologist": 39 },
  "07826": { "Barber": 4, "Cosmetologist": 39 },
  "01094": { "Barber": 4 },
  "03345": { "Barber": 2 },
  "08065": { "Barber": 8, "Cosmetologist": 34 },
  "07019": { "Barber": 10 },
  "08526": { "Barber": 6 },
  "01045": { "Barber": 4 },
  "08571": { "Barber": 2 },
  "08007": { "Barber": 16, "Cosmetologist": 36 },
  "03411": { "Barber": 10 },
  "07828": { "Barber": 10, "Cosmetologist": 85 },
  "09025": { "Barber": 2 },
  "09211": { "Barber": 2 },
  "08004": { "Barber": 14, "Cosmetologist": 75 },
  "08862": { "Barber": 4, "Cosmetologist": 3 },
  "01754": { "Barber": 2 },
  "03306": { "Barber": 14 },
  "02965": { "Barber": 2 },
  "03784": { "Barber": 2 },
  "08730": { "Barber": 4, "Cosmetologist": 25 },
  "01912": { "Barber": 14 },
  "01902": { "Barber": 6 },
  "03217": { "Barber": 6 },
  "07721": { "Barber": 10, "Cosmetologist": 29 },
  "06902": { "Barber": 2 },
  "08341": { "Barber": 8, "Cosmetologist": 10 },
  "02120": { "Barber": 2 },
  "01904": { "Barber": 8 },
  "07403": { "Barber": 6, "Cosmetologist": 43 },
  "08086": { "Barber": 4, "Cosmetologist": 61 },
  "01970": { "Barber": 6 },
  "08550": { "Barber": 4, "Cosmetologist": 26 },
  "01740": { "Barber": 2 },
  "07070": { "Barber": 10, "Cosmetologist": 98 },
  "08093": { "Barber": 4, "Cosmetologist": 58 },
  "08912": { "Barber": 4 },
  "03385": { "Barber": 2 },
  "03475": { "Barber": 2 },
  "07401": { "Barber": 2, "Cosmetologist": 28 },
  "07627": { "Barber": 2, "Cosmetologist": 11 },
  "03001": { "Barber": 2 },
  "04817": { "Barber": 2 },
  "07079": { "Barber": 8, "Cosmetologist": 25 },
  "03207": { "Barber": 4 },
  "08850": { "Barber": 12, "Cosmetologist": 52 },
  "04322": { "Barber": 2 },
  "08324": { "Barber": 2, "Cosmetologist": 1 },
  "09267": { "Barber": 4 },
  "02100": { "Barber": 2 },
  "08792": { "Barber": 2 },
  "01157": { "Barber": 2 },
  "07727": { "Barber": 6, "Cosmetologist": 44 },
  "07738": { "Barber": 8, "Cosmetologist": 23 },
  "03330": { "Barber": 4 },
  "08501": { "Barber": 4, "Cosmetologist": 40 },
  "08068": { "Barber": 10, "Cosmetologist": 29 },
  "01756": { "Barber": 2 },
  "08734": { "Barber": 6, "Cosmetologist": 78 },
  "01285": { "Barber": 2 },
  "07717": { "Barber": 8, "Cosmetologist": 11 },
  "01851": { "Barber": 2 },
  "02962": { "Barber": 2 },
  "08270": { "Barber": 12, "Cosmetologist": 56 },
  "02322": { "Barber": 2 },
  "01897": { "Barber": 6 },
  "02877": { "Barber": 4 },
  "08752": { "Barber": 14, "Cosmetologist": 7 },
  "01600": { "Barber": 2 },
  "01936": { "Barber": 4 },
  "03243": { "Barber": 2 },
  "02729": { "Barber": 2 },
  "01110": { "Barber": 6 },
  "07020": { "Barber": 6, "Cosmetologist": 50 },
  "08560": { "Barber": 6, "Cosmetologist": 10 },
  "01787": { "Barber": 2 },
  "03257": { "Barber": 2 },
  "02805": { "Barber": 2 },
  "03222": { "Barber": 2 },
  "08008": { "Barber": 6, "Cosmetologist": 12 },
  "07722": { "Barber": 4, "Cosmetologist": 37 },
  "01135": { "Barber": 2 },
  "09722": { "Barber": 2 },
  "01810": { "Barber": 2 },
  "01980": { "Barber": 8 },
  "08833": { "Barber": 6, "Cosmetologist": 35 },
  "06111": { "Barber": 2 },
  "02835": { "Barber": 2 },
  "05544": { "Barber": 4 },
  "08062": { "Barber": 6, "Cosmetologist": 90 },
  "07207": { "Barber": 8, "Cosmetologist": 3 },
  "03331": { "Barber": 6 },
  "02757": { "Barber": 2 },
  "02803": { "Barber": 2 },
  "02831": { "Barber": 4 },
  "02761": { "Barber": 4 },
  "08520": { "Barber": 16, "Cosmetologist": 141 },
  "02758": { "Barber": 2 },
  "03297": { "Barber": 4 },
  "02960": { "Barber": 2 },
  "02788": { "Barber": 2 },
  "03713": { "Barber": 2 },
  "08906": { "Barber": 2 },
  "01972": { "Barber": 2 },
  "07057": { "Barber": 8, "Cosmetologist": 63 },
  "03391": { "Barber": 10 },
  "03359": { "Barber": 4 },
  "03033": { "Barber": 2 },
  "03063": { "Barber": 2 },
  "07921": { "Barber": 6, "Cosmetologist": 33 },
  "02833": { "Barber": 4 },
  "01997": { "Barber": 4 },
  "07460": { "Barber": 2, "Cosmetologist": 16 },
  "02802": { "Barber": 2 },
  "03045": { "Barber": 2 },
  "02948": { "Barber": 4 },
  "07874": { "Barber": 8, "Cosmetologist": 55 },
  "08826": { "Barber": 4, "Cosmetologist": 32 },
  "07645": { "Barber": 6, "Cosmetologist": 27 },
  "09970": { "Barber": 2 },
  "07078": { "Barber": 4, "Cosmetologist": 10 },
  "08536": { "Barber": 2, "Cosmetologist": 27 },
  "07841": { "Barber": 2 },
  "01488": { "Barber": 2 },
  "03276": { "Barber": 4 },
  "01803": { "Barber": 2 },
  "08328": { "Barber": 2, "Cosmetologist": 6 },
  "03653": { "Barber": 2 },
  "08625": { "Barber": 2 },
  "01901": { "Barber": 8 },
  "07101": { "Barber": 6, "Cosmetologist": 9 },
  "03397": { "Barber": 2 },
  "01938": { "Barber": 4 },
  "07462": { "Barber": 2, "Cosmetologist": 55 },
  "07850": { "Barber": 2, "Cosmetologist": 57 },
  "01051": { "Barber": 2 },
  "02752": { "Barber": 4 },
  "08001": { "Barber": 4, "Cosmetologist": 7 },
  "07863": { "Barber": 4, "Cosmetologist": 35 },
  "01080": { "Barber": 2 },
  "03343": { "Barber": 14 },
  "09354": { "Barber": 2 },
  "02445": { "Barber": 2 },
  "07827": { "Barber": 4, "Cosmetologist": 35 },
  "02957": { "Barber": 6 },
  "03388": { "Barber": 6 },
  "07864": { "Barber": 2 },
  "07803": { "Barber": 4, "Cosmetologist": 25 },
  "05250": { "Barber": 2 },
  "01121": { "Barber": 6 },
  "07752": { "Barber": 4, "Cosmetologist": 1 },
  "08067": { "Barber": 2, "Cosmetologist": 9 },
  "03429": { "Barber": 2 },
  "07906": { "Barber": 2 },
  "02810": { "Barber": 2 },
  "07716": { "Barber": 4, "Cosmetologist": 27 },
  "02074": { "Barber": 4 },
  "03100": { "Barber": 2 },
  "08242": { "Barber": 4, "Cosmetologist": 19 },
  "01833": { "Barber": 2 },
  "07543": { "Barber": 2 },
  "03351": { "Barber": 2 },
  "07802": { "Barber": 2, "Cosmetologist": 5 },
  "03469": { "Barber": 2 },
  "01694": { "Barber": 2 },
  "07764": { "Barber": 10, "Cosmetologist": 37 },
  "08553": { "Barber": 2 },
  "07457": { "Barber": 2, "Cosmetologist": 20 },
  "07825": { "Barber": 6, "Cosmetologist": 71 },
  "03030": { "Barber": 2 },
  "01506": { "Barber": 2 },
  "01170": { "Barber": 2 },
  "08250": { "Barber": 2, "Cosmetologist": 1 },
  "03420": { "Barber": 4 },
  "07642": { "Barber": 6, "Cosmetologist": 44 },
  "08525": { "Barber": 10, "Cosmetologist": 16 },
  "09120": { "Barber": 2 },
  "07732": { "Barber": 6, "Cosmetologist": 26 },
  "08311": { "Barber": 2, "Cosmetologist": 3 },
  "08563": { "Barber": 2 },
  "03277": { "Barber": 2 },
  "07620": { "Barber": 2, "Cosmetologist": 2 },
  "08918": { "Barber": 2 },
  "05155": { "Barber": 2 },
  "02103": { "Barber": 2 },
  "01683": { "Barber": 2 },
  "03333": { "Barber": 2 },
  "06152": { "Barber": 2 },
  "03221": { "Barber": 2 },
  "04834": { "Barber": 2 },
  "03283": { "Barber": 2 },
  "01097": { "Barber": 4 },
  "08346": { "Barber": 4, "Cosmetologist": 3 },
  "03360": { "Barber": 6 },
  "01903": { "Barber": 6 },
  "02990": { "Barber": 2 },
  "08741": { "Barber": 2, "Cosmetologist": 13 },
  "03342": { "Barber": 4 },
  "08852": { "Barber": 8, "Cosmetologist": 57 },
  "03295": { "Barber": 6 },
  "07931": { "Barber": 2, "Cosmetologist": 4 },
  "08825": { "Barber": 6, "Cosmetologist": 29 },
  "08733": { "Barber": 6, "Cosmetologist": 16 },
  "08219": { "Barber": 2 },
  "01823": { "Barber": 2 },
  "04572": { "Barber": 2 },
  "08631": { "Barber": 2 },
  "01893": { "Barber": 2 },
  "07505": { "Barber": 4, "Cosmetologist": 13 },
  "07061": { "Barber": 2, "Cosmetologist": 4 },
  "06231": { "Barber": 2 },
  "06047": { "Barber": 2 },
  "03634": { "Barber": 2 },
  "03364": { "Barber": 2 },
  "02785": { "Barber": 2 },
  "01981": { "Barber": 2 },
  "02830": { "Barber": 2 },
  "08804": { "Barber": 2, "Cosmetologist": 22 },
  "07656": { "Barber": 10, "Cosmetologist": 39 },
  "03386": { "Barber": 2 },
  "09950": { "Barber": 2 },
  "03371": { "Barber": 2 },
  "07064": { "Barber": 18, "Cosmetologist": 18 },
  "08538": { "Barber": 2 },
  "08515": { "Barber": 2, "Cosmetologist": 31 },
  "03399": { "Barber": 4 },
  "09208": { "Barber": 2 },
  "01847": { "Barber": 2 },
  "08884": { "Barber": 6, "Cosmetologist": 57 },
  "08824": { "Barber": 6, "Cosmetologist": 39 },
  "02072": { "Barber": 2, "Cosmetologist": 1 },
  "09233": { "Barber": 2 },
  "01092": { "Barber": 2 },
  "02280": { "Barber": 2 },
  "07718": { "Barber": 2, "Cosmetologist": 42 },
  "08521": { "Barber": 2 },
  "07872": { "Barber": 2 },
  "03449": { "Barber": 2 },
  "03785": { "Barber": 2 },
  "07670": { "Barber": 2, "Cosmetologist": 27 },
  "08606": { "Barber": 2 },
  "06013": { "Barber": 2 },
  "03356": { "Barber": 2 },
  "08607": { "Barber": 4, "Cosmetologist": 5 },
  "01047": { "Barber": 2 },
  "09542": { "Barber": 2 },
  "04843": { "Barber": 2 },
  "03225": { "Barber": 2 },
  "08836": { "Barber": 4, "Cosmetologist": 19 },
  "08343": { "Barber": 2, "Cosmetologist": 26 },
  "03305": { "Barber": 2 },
  "03340": { "Barber": 4 },
  "03272": { "Barber": 2 },
  "07750": { "Barber": 4, "Cosmetologist": 11 },
  "08072": { "Barber": 2, "Cosmetologist": 2 },
  "03293": { "Barber": 2 },
  "03786": { "Barber": 2 },
  "03269": { "Barber": 2 },
  "01122": { "Barber": 6 },
  "08853": { "Barber": 4, "Cosmetologist": 26 },
  "07706": { "Barber": 2 },
  "02754": { "Barber": 2 },
  "08608": { "Barber": 4, "Cosmetologist": 3 },
  "09165": { "Barber": 2 },
  "01031": { "Barber": 6, "Cosmetologist": 1 },
  "08573": { "Barber": 2 },
  "02873": { "Barber": 2 },
  "08056": { "Barber": 2, "Cosmetologist": 31 },
  "09562": { "Barber": 2 },
  "07458": { "Barber": 10, "Cosmetologist": 24 },
  "08243": { "Barber": 2, "Cosmetologist": 6 },
  "01123": { "Barber": 6 },
  "03476": { "Barber": 2 },
  "09968": { "Barber": 2 },
  "01940": { "Barber": 2 },
  "03315": { "Barber": 2 },
  "08513": { "Barber": 2 },
  "02726": { "Barber": 2 },
  "03393": { "Barber": 2 },
  "01071": { "Barber": 2 },
  "01752": { "Barber": 2 },
  "07096": { "Barber": 2, "Cosmetologist": 1 },
  "08880": { "Barber": 4, "Cosmetologist": 32 },
  "08362": { "Barber": 2 },
  "07068": { "Barber": 4, "Cosmetologist": 37 },
  "09220": { "Barber": 2 },
  "03448": { "Barber": 2 },
  "03446": { "Barber": 2 },
  "03934": { "Barber": 2 },
  "03280": { "Barber": 4 },
  "01502": { "Barber": 2 },
  "09206": { "Barber": 2 },
  "07088": { "Barber": 6, "Cosmetologist": 25 },
  "01156": { "Barber": 2 },
  "02450": { "Barber": 2 },
  "09802": { "Barber": 2 },
  "01770": { "Barber": 2 },
  "07444": { "Barber": 4, "Cosmetologist": 58 },
  "07015": { "Barber": 2, "Cosmetologist": 3 },
  "03012": { "Barber": 2 },
  "02240": { "Barber": 2 },
  "01942": { "Barber": 2 },
  "02219": { "Barber": 2 },
  "09212": { "Barber": 2 },
  "01054": { "Barber": 2 },
  "09006": { "Barber": 2 },
  "08802": { "Barber": 2, "Cosmetologist": 18 },
  "03389": { "Barber": 2 },
  "03471": { "Barber": 2 },
  "03424": { "Barber": 2 },
  "02303": { "Barber": 2 },
  "02982": { "Barber": 2 },
  "08092": { "Barber": 2, "Cosmetologist": 30 },
  "03521": { "Barber": 2 },
  "01834": { "Barber": 2 },
  "08326": { "Barber": 2, "Cosmetologist": 13 },
  "03140": { "Barber": 2 },
  "08592": { "Barber": 2 },
  "03067": { "Barber": 2 },
  "06314": { "Barber": 2 },
  "07196": { "Barber": 2 },
  "01807": { "Barber": 2 },
  "01099": { "Barber": 4 },
  "09560": { "Barber": 2 },
  "01882": { "Barber": 2 },
  "01806": { "Barber": 4 },
  "01138": { "Barber": 2, "Cosmetologist": 1 },
  "01995": { "Barber": 4 },
  "02783": { "Barber": 2 },
  "03377": { "Barber": 2 },
  "02174": { "Barber": 2 },
  "03275": { "Barber": 2 },
  "07069": { "Barber": 2, "Cosmetologist": 31 },
  "08832": { "Barber": 2, "Cosmetologist": 10 },
  "04469": { "Barber": 2 },
  "01159": { "Barber": 2 },
  "03294": { "Barber": 2 },
  "09264": { "Barber": 2 },
  "02204": { "Barber": 2 },
  "03956": { "Barber": 2 },
  "03427": { "Barber": 2 },
  "07051": { "Barber": 2, "Cosmetologist": 1 },
  "09178": { "Barber": 2 },
  "07303": { "Barber": 2, "Cosmetologist": 1 },
  "03230": { "Barber": 2 },
  "03304": { "Barber": 4 },
  "07865": { "Barber": 2, "Cosmetologist": 17 },
  "01098": { "Barber": 2 },
  "05401": { "Barber": 2 },
  "02905": { "Barber": 2 },
  "02760": { "Barber": 2 },
  "09452": { "Barber": 2 },
  "09237": { "Barber": 2 },
  "03009": { "Barber": 2 },
  "03780": { "Barber": 2 },
  "07428": { "Barber": 2, "Cosmetologist": 1 },
  "03422": { "Barber": 2 },
  "03344": { "Barber": 4 },
  "01891": { "Barber": 2 },
  "06853": { "Barber": 2 },
  "01085": { "Barber": 2, "Cosmetologist": 1 },
  "03630": { "Barber": 2 },
  "01909": { "Barber": 2 },
  "07045": { "Barber": 2, "Cosmetologist": 52 },
  "03130": { "Barber": 2 },
  "01808": { "Barber": 2 },
  "03268": { "Barber": 2 },
  "03010": { "Barber": 2 },
  "08329": { "Cosmetologist": 2 },
  "07647": { "Cosmetologist": 30 },
  "07838": { "Cosmetologist": 31 },
  "08510": { "Cosmetologist": 34 },
  "07946": { "Cosmetologist": 12 },
  "08061": { "Cosmetologist": 20 },
  "07418": { "Cosmetologist": 15 },
  "08514": { "Cosmetologist": 38 },
  "08511": { "Cosmetologist": 10 },
  "08317": { "Cosmetologist": 5 },
  "07422": { "Cosmetologist": 50 },
  "07927": { "Cosmetologist": 20 },
  "08641": { "Cosmetologist": 3 },
  "08019": { "Cosmetologist": 11 },
  "08032": { "Cosmetologist": 4 },
  "07830": { "Cosmetologist": 20 },
  "08316": { "Cosmetologist": 3 },
  "08014": { "Cosmetologist": 7 },
  "08038": { "Cosmetologist": 3 },
  "08602": { "Cosmetologist": 3 },
  "08808": { "Cosmetologist": 1 },
  "08827": { "Cosmetologist": 32 },
  "08039": { "Cosmetologist": 3 },
  "08319": { "Cosmetologist": 11 },
  "08555": { "Cosmetologist": 4 },
  "08858": { "Cosmetologist": 2 },
  "07822": { "Cosmetologist": 6 },
  "08821": { "Cosmetologist": 3 },
  "07427": { "Cosmetologist": 1 },
  "07310": { "Cosmetologist": 6 },
  "08601": { "Cosmetologist": 2 },
  "08314": { "Cosmetologist": 3 },
  "07848": { "Cosmetologist": 35 },
  "08224": { "Cosmetologist": 5 },
  "08241": { "Cosmetologist": 7 },
  "07435": { "Cosmetologist": 18 },
  "07021": { "Cosmetologist": 5 },
  "07842": { "Cosmetologist": 1 },
  "08327": { "Cosmetologist": 5 },
  "07677": { "Cosmetologist": 7 },
  "07308": { "Cosmetologist": 1 },
  "07474": { "Cosmetologist": 3 },
  "07935": { "Cosmetologist": 2 },
  "07632": { "Cosmetologist": 13 },
  "08231": { "Cosmetologist": 2 },
  "07653": { "Cosmetologist": 1 },
  "08340": { "Cosmetologist": 4 },
  "07311": { "Cosmetologist": 1 },
  "08247": { "Cosmetologist": 3 },
  "08885": { "Cosmetologist": 1 },
  "07608": { "Cosmetologist": 1 },
  "08818": { "Cosmetologist": 2 },
  "07451": { "Cosmetologist": 1 },
  "07878": { "Cosmetologist": 3 },
  "08041": { "Cosmetologist": 5 },
  "08323": { "Cosmetologist": 1 },
  "08252": { "Cosmetologist": 1 },
  "08315": { "Cosmetologist": 1 },
  "07904": { "Cosmetologist": 1 },
  "07977": { "Cosmetologist": 3 },
  "07880": { "Cosmetologist": 2 },
  "08348": { "Cosmetologist": 1 },
  "08738": { "Cosmetologist": 1 },
  "08006": { "Cosmetologist": 2 },
  "07710": { "Cosmetologist": 1 },
  "08353": { "Cosmetologist": 1 },
  "08754": { "Cosmetologist": 2 },
  "08561": { "Cosmetologist": 2 },
  "07926": { "Cosmetologist": 1 },
  "07875": { "Cosmetologist": 2 },
  "07844": { "Cosmetologist": 2 },
  "08720": { "Cosmetologist": 2 },
  "07046": { "Cosmetologist": 3 },
  "08101": { "Cosmetologist": 1 },
  "07723": { "Cosmetologist": 1 },
  "08220": { "Cosmetologist": 1 },
  "07976": { "Cosmetologist": 1 },
  "07765": { "Cosmetologist": 1 },
  "08248": { "Cosmetologist": 1 },
  "08202": { "Cosmetologist": 1 },
  "07978": { "Cosmetologist": 1 },
  "08650": { "Cosmetologist": 1 },
  "07610": { "Cosmetologist": 1 },
  "08321": { "Cosmetologist": 1 },
  "07877": { "Cosmetologist": 1 },
  "08855": { "Cosmetologist": 1 },
  "07335": { "Cosmetologist": 1 },
  "07961": { "Cosmetologist": 1 },
  "02468": { "Cosmetologist": 1 },
  "07970": { "Cosmetologist": 1 },
  "08507": { "Cosmetologist": 1 },
  "75001": { "Barber": 8, "Cosmetologist": 140 },
  "75006": { "Barber": 51, "Cosmetologist": 1092 },
  "75007": { "Barber": 27, "Cosmetologist": 812 },
  "75021": { "Barber": 3, "Cosmetologist": 57 },
  "75024": { "Barber": 17, "Cosmetologist": 331 },
  "75043": { "Barber": 67, "Cosmetologist": 572 },
  "75054": { "Barber": 15, "Cosmetologist": 213 },
  "75057": { "Barber": 14, "Cosmetologist": 152 },
  "75058": { "Barber": 1, "Cosmetologist": 29 },
  "75061": { "Barber": 35, "Cosmetologist": 331 },
  "75077": { "Barber": 22, "Cosmetologist": 345 },
  "75082": { "Barber": 10, "Cosmetologist": 288 },
  "75090": { "Barber": 17, "Cosmetologist": 142 },
  "75094": { "Barber": 8, "Cosmetologist": 364 },
  "75109": { "Barber": 1, "Cosmetologist": 29 },
  "75110": { "Barber": 21, "Cosmetologist": 174 },
  "75114": { "Barber": 11, "Cosmetologist": 63 },
  "75116": { "Barber": 37, "Cosmetologist": 169 },
  "75124": { "Barber": 6, "Cosmetologist": 41 },
  "75134": { "Barber": 35, "Cosmetologist": 162 },
  "75135": { "Barber": 12, "Cosmetologist": 76 },
  "75137": { "Barber": 35, "Cosmetologist": 169 },
  "75140": { "Barber": 3, "Cosmetologist": 44 },
  "75141": { "Barber": 8, "Cosmetologist": 21 },
  "75142": { "Barber": 16, "Cosmetologist": 149 },
  "75144": { "Barber": 2, "Cosmetologist": 16 },
  "75146": { "Barber": 47, "Cosmetologist": 178 },
  "75152": { "Barber": 3, "Cosmetologist": 47 },
  "75153": { "Barber": 2, "Cosmetologist": 6 },
  "75155": { "Barber": 5, "Cosmetologist": 13 },
  "75156": { "Barber": 13, "Cosmetologist": 99 },
  "75159": { "Barber": 23, "Cosmetologist": 127 },
  "75160": { "Barber": 35, "Cosmetologist": 170 },
  "75161": { "Barber": 4, "Cosmetologist": 32 },
  "75163": { "Barber": 2, "Cosmetologist": 9 },
  "75166": { "Barber": 4, "Cosmetologist": 63 },
  "75169": { "Barber": 8, "Cosmetologist": 110 },
  "75172": { "Barber": 6, "Cosmetologist": 18 },
  "75173": { "Barber": 10, "Cosmetologist": 63 },
  "75180": { "Barber": 26, "Cosmetologist": 164 },
  "75181": { "Barber": 41, "Cosmetologist": 194 },
  "75182": { "Barber": 2, "Cosmetologist": 51 },
  "75201": { "Barber": 16, "Cosmetologist": 122 },
  "75203": { "Barber": 14, "Cosmetologist": 55 },
  "75210": { "Barber": 15, "Cosmetologist": 20 },
  "75211": { "Barber": 80, "Cosmetologist": 370 },
  "75212": { "Barber": 39, "Cosmetologist": 193 },
  "75215": { "Barber": 41, "Cosmetologist": 109 },
  "75220": { "Barber": 14, "Cosmetologist": 130 },
  "75223": { "Barber": 11, "Cosmetologist": 58 },
  "75224": { "Barber": 50, "Cosmetologist": 186 },
  "75225": { "Barber": 8, "Cosmetologist": 75 },
  "75227": { "Barber": 75, "Cosmetologist": 339 },
  "75228": { "Barber": 58, "Cosmetologist": 404 },
  "75229": { "Barber": 16, "Cosmetologist": 153 },
  "75230": { "Barber": 14, "Cosmetologist": 135 },
  "75232": { "Barber": 57, "Cosmetologist": 205 },
  "75233": { "Barber": 23, "Cosmetologist": 87 },
  "75237": { "Barber": 36, "Cosmetologist": 95 },
  "75238": { "Barber": 16, "Cosmetologist": 138 },
  "75241": { "Barber": 61, "Cosmetologist": 180 },
  "75249": { "Barber": 14, "Cosmetologist": 161 },
  "75251": { "Barber": 2, "Cosmetologist": 36 },
  "75253": { "Barber": 20, "Cosmetologist": 122 },
  "75410": { "Barber": 2, "Cosmetologist": 27 },
  "75416": { "Barber": 2, "Cosmetologist": 20 },
  "75422": { "Barber": 3, "Cosmetologist": 24 },
  "75428": { "Barber": 6, "Cosmetologist": 44 },
  "75442": { "Barber": 4, "Cosmetologist": 71 },
  "75452": { "Barber": 5, "Cosmetologist": 37 },
  "75453": { "Barber": 2, "Cosmetologist": 22 },
  "75455": { "Barber": 12, "Cosmetologist": 193 },
  "75482": { "Barber": 15, "Cosmetologist": 147 },
  "75491": { "Barber": 7, "Cosmetologist": 45 },
  "75494": { "Barber": 6, "Cosmetologist": 92 },
  "75501": { "Barber": 25, "Cosmetologist": 206 },
  "75503": { "Barber": 13, "Cosmetologist": 241 },
  "75551": { "Barber": 11, "Cosmetologist": 64 },
  "75559": { "Barber": 3, "Cosmetologist": 27 },
  "75561": { "Barber": 4, "Cosmetologist": 31 },
  "75563": { "Barber": 3, "Cosmetologist": 17 },
  "75567": { "Barber": 1, "Cosmetologist": 25 },
  "75568": { "Barber": 2, "Cosmetologist": 13 },
  "75569": { "Barber": 4, "Cosmetologist": 33 },
  "75570": { "Barber": 4, "Cosmetologist": 46 },
  "75574": { "Barber": 2, "Cosmetologist": 11 },
  "75601": { "Barber": 16, "Cosmetologist": 107 },
  "75602": { "Barber": 36, "Cosmetologist": 87 },
  "75603": { "Barber": 1, "Cosmetologist": 34 },
  "75604": { "Barber": 18, "Cosmetologist": 214 },
  "75633": { "Barber": 9, "Cosmetologist": 110 },
  "75638": { "Barber": 1, "Cosmetologist": 30 },
  "75640": { "Barber": 4, "Cosmetologist": 46 },
  "75644": { "Barber": 7, "Cosmetologist": 85 },
  "75645": { "Barber": 2, "Cosmetologist": 61 },
  "75647": { "Barber": 7, "Cosmetologist": 86 },
  "75652": { "Barber": 8, "Cosmetologist": 72 },
  "75654": { "Barber": 14, "Cosmetologist": 94 },
  "75656": { "Barber": 3, "Cosmetologist": 30 },
  "75657": { "Barber": 6, "Cosmetologist": 42 },
  "75662": { "Barber": 23, "Cosmetologist": 171 },
  "75669": { "Barber": 2 },
  "75670": { "Barber": 14, "Cosmetologist": 84 },
  "75672": { "Barber": 6, "Cosmetologist": 100 },
  "75681": { "Barber": 6, "Cosmetologist": 16 },
  "75684": { "Barber": 6, "Cosmetologist": 34 },
  "75691": { "Barber": 2, "Cosmetologist": 29 },
  "75701": { "Barber": 31, "Cosmetologist": 254 },
  "75702": { "Barber": 32, "Cosmetologist": 149 },
  "75705": { "Barber": 4, "Cosmetologist": 13 },
  "75706": { "Barber": 9, "Cosmetologist": 70 },
  "75707": { "Barber": 5, "Cosmetologist": 130 },
  "75708": { "Barber": 7, "Cosmetologist": 43 },
  "75709": { "Barber": 3, "Cosmetologist": 39 },
  "75750": { "Barber": 2, "Cosmetologist": 25 },
  "75751": { "Barber": 15, "Cosmetologist": 138 },
  "75752": { "Barber": 3, "Cosmetologist": 48 },
  "75754": { "Barber": 2, "Cosmetologist": 49 },
  "75756": { "Barber": 2, "Cosmetologist": 26 },
  "75758": { "Barber": 5, "Cosmetologist": 80 },
  "75760": { "Barber": 3, "Cosmetologist": 17 },
  "75762": { "Barber": 9, "Cosmetologist": 118 },
  "75763": { "Barber": 4, "Cosmetologist": 36 },
  "75765": { "Barber": 2, "Cosmetologist": 38 },
  "75766": { "Barber": 23, "Cosmetologist": 123 },
  "75778": { "Barber": 3, "Cosmetologist": 21 },
  "75783": { "Barber": 7, "Cosmetologist": 50 },
  "75785": { "Barber": 7, "Cosmetologist": 46 },
  "75789": { "Barber": 2, "Cosmetologist": 46 },
  "75791": { "Barber": 10, "Cosmetologist": 117 },
  "75792": { "Barber": 3, "Cosmetologist": 24 },
  "75803": { "Barber": 6, "Cosmetologist": 87 },
  "75833": { "Barber": 4, "Cosmetologist": 28 },
  "75839": { "Barber": 2, "Cosmetologist": 32 },
  "75840": { "Barber": 9, "Cosmetologist": 48 },
  "75844": { "Barber": 4, "Cosmetologist": 33 },
  "75855": { "Barber": 2, "Cosmetologist": 15 },
  "75860": { "Barber": 3, "Cosmetologist": 46 },
  "75862": { "Barber": 7, "Cosmetologist": 38 },
  "75901": { "Barber": 21, "Cosmetologist": 169 },
  "75904": { "Barber": 26, "Cosmetologist": 244 },
  "75935": { "Barber": 12, "Cosmetologist": 104 },
  "75941": { "Barber": 9, "Cosmetologist": 47 },
  "75946": { "Barber": 4, "Cosmetologist": 23 },
  "75948": { "Barber": 1, "Cosmetologist": 52 },
  "75949": { "Barber": 1, "Cosmetologist": 55 },
  "75951": { "Barber": 3, "Cosmetologist": 93 },
  "75954": { "Barber": 2, "Cosmetologist": 31 },
  "75956": { "Barber": 3, "Cosmetologist": 50 },
  "75961": { "Barber": 15, "Cosmetologist": 80 },
  "75964": { "Barber": 25, "Cosmetologist": 105 },
  "75972": { "Barber": 5, "Cosmetologist": 33 },
  "75973": { "Barber": 2, "Cosmetologist": 14 },
  "75974": { "Barber": 1, "Cosmetologist": 25 },
  "75979": { "Barber": 4, "Cosmetologist": 62 },
  "75980": { "Barber": 2, "Cosmetologist": 20 },
  "76008": { "Barber": 9, "Cosmetologist": 152 },
  "76009": { "Barber": 13, "Cosmetologist": 149 },
  "76015": { "Barber": 12, "Cosmetologist": 178 },
  "76022": { "Barber": 12, "Cosmetologist": 126 },
  "76031": { "Barber": 6, "Cosmetologist": 124 },
  "76034": { "Barber": 4, "Cosmetologist": 156 },
  "76035": { "Barber": 3, "Cosmetologist": 14 },
  "76044": { "Barber": 5, "Cosmetologist": 91 },
  "76054": { "Barber": 11, "Cosmetologist": 96 },
  "76055": { "Barber": 3, "Cosmetologist": 19 },
  "76059": { "Barber": 2, "Cosmetologist": 28 },
  "76060": { "Barber": 6, "Cosmetologist": 89 },
  "76066": { "Barber": 2, "Cosmetologist": 47 },
  "76067": { "Barber": 8, "Cosmetologist": 131 },
  "76071": { "Barber": 2, "Cosmetologist": 26 },
  "76084": { "Barber": 8, "Cosmetologist": 110 },
  "76102": { "Barber": 6, "Cosmetologist": 61 },
  "76103": { "Barber": 5, "Cosmetologist": 107 },
  "76104": { "Barber": 20, "Cosmetologist": 139 },
  "76105": { "Barber": 23, "Cosmetologist": 137 },
  "76106": { "Barber": 31, "Cosmetologist": 188 },
  "76109": { "Barber": 4, "Cosmetologist": 98 },
  "76110": { "Barber": 17, "Cosmetologist": 174 },
  "76111": { "Barber": 14, "Cosmetologist": 177 },
  "76114": { "Barber": 23, "Cosmetologist": 176 },
  "76115": { "Barber": 11, "Cosmetologist": 110 },
  "76117": { "Barber": 19, "Cosmetologist": 338 },
  "76118": { "Barber": 9, "Cosmetologist": 215 },
  "76119": { "Barber": 58, "Cosmetologist": 277 },
  "76123": { "Barber": 41, "Cosmetologist": 441 },
  "76134": { "Barber": 26, "Cosmetologist": 251 },
  "76140": { "Barber": 47, "Cosmetologist": 288 },
  "76148": { "Barber": 20, "Cosmetologist": 270 },
  "76155": { "Barber": 9, "Cosmetologist": 28 },
  "76164": { "Barber": 12, "Cosmetologist": 92 },
  "76201": { "Barber": 12, "Cosmetologist": 144 },
  "76207": { "Barber": 6, "Cosmetologist": 114 },
  "76208": { "Barber": 14, "Cosmetologist": 224 },
  "76240": { "Barber": 12, "Cosmetologist": 217 },
  "76245": { "Barber": 2, "Cosmetologist": 14 },
  "76249": { "Barber": 7, "Cosmetologist": 113 },
  "76255": { "Barber": 4, "Cosmetologist": 27 },
  "76258": { "Barber": 1, "Cosmetologist": 67 },
  "76266": { "Barber": 9, "Cosmetologist": 177 },
  "76271": { "Barber": 1, "Cosmetologist": 24 },
  "76272": { "Barber": 2, "Cosmetologist": 37 },
  "76273": { "Barber": 3, "Cosmetologist": 74 },
  "76306": { "Barber": 9, "Cosmetologist": 88 },
  "76308": { "Barber": 8, "Cosmetologist": 162 },
  "76351": { "Barber": 2, "Cosmetologist": 14 },
  "76354": { "Barber": 6, "Cosmetologist": 56 },
  "76371": { "Barber": 1, "Cosmetologist": 6 },
  "76374": { "Barber": 2, "Cosmetologist": 23 },
  "76380": { "Barber": 5, "Cosmetologist": 18 },
  "76389": { "Barber": 1, "Cosmetologist": 13 },
  "76426": { "Barber": 13, "Cosmetologist": 81 },
  "76442": { "Barber": 2, "Cosmetologist": 39 },
  "76448": { "Barber": 2, "Cosmetologist": 40 },
  "76450": { "Barber": 5, "Cosmetologist": 116 },
  "76454": { "Barber": 3, "Cosmetologist": 11 },
  "76458": { "Barber": 4, "Cosmetologist": 27 },
  "76487": { "Barber": 3, "Cosmetologist": 27 },
  "76501": { "Barber": 8, "Cosmetologist": 91 },
  "76511": { "Barber": 2, "Cosmetologist": 10 },
  "76520": { "Barber": 5, "Cosmetologist": 29 },
  "76524": { "Barber": 2, "Cosmetologist": 18 },
  "76527": { "Barber": 5, "Cosmetologist": 30 },
  "76528": { "Barber": 6, "Cosmetologist": 108 },
  "76530": { "Barber": 4, "Cosmetologist": 14 },
  "76531": { "Barber": 3, "Cosmetologist": 26 },
  "76537": { "Barber": 7, "Cosmetologist": 136 },
  "76539": { "Barber": 3, "Cosmetologist": 51 },
  "76550": { "Barber": 11, "Cosmetologist": 93 },
  "76559": { "Barber": 6, "Cosmetologist": 44 },
  "76567": { "Barber": 6, "Cosmetologist": 58 },
  "76571": { "Barber": 6, "Cosmetologist": 72 },
  "76574": { "Barber": 17, "Cosmetologist": 141 },
  "76577": { "Barber": 7, "Cosmetologist": 25 },
  "76633": { "Barber": 3, "Cosmetologist": 36 },
  "76640": { "Barber": 2, "Cosmetologist": 20 },
  "76642": { "Barber": 1, "Cosmetologist": 36 },
  "76643": { "Barber": 16, "Cosmetologist": 157 },
  "76645": { "Barber": 11, "Cosmetologist": 57 },
  "76655": { "Barber": 7, "Cosmetologist": 124 },
  "76661": { "Barber": 2, "Cosmetologist": 30 },
  "76665": { "Barber": 1, "Cosmetologist": 16 },
  "76667": { "Barber": 12, "Cosmetologist": 43 },
  "76671": { "Barber": 2, "Cosmetologist": 8 },
  "76679": { "Barber": 2, "Cosmetologist": 11 },
  "76682": { "Barber": 2, "Cosmetologist": 16 },
  "76689": { "Barber": 2, "Cosmetologist": 30 },
  "76691": { "Barber": 2, "Cosmetologist": 41 },
  "76692": { "Barber": 4, "Cosmetologist": 74 },
  "76693": { "Barber": 2, "Cosmetologist": 9 },
  "76701": { "Barber": 2, "Cosmetologist": 10 },
  "76704": { "Barber": 14, "Cosmetologist": 31 },
  "76708": { "Barber": 24, "Cosmetologist": 188 },
  "76710": { "Barber": 23, "Cosmetologist": 175 },
  "76711": { "Barber": 20, "Cosmetologist": 62 },
  "76801": { "Barber": 12, "Cosmetologist": 168 },
  "76821": { "Barber": 2, "Cosmetologist": 25 },
  "76825": { "Barber": 3, "Cosmetologist": 37 },
  "76834": { "Barber": 2, "Cosmetologist": 38 },
  "76844": { "Barber": 4, "Cosmetologist": 15 },
  "76849": { "Barber": 3, "Cosmetologist": 20 },
  "76877": { "Barber": 1, "Cosmetologist": 16 },
  "76903": { "Barber": 25, "Cosmetologist": 196 },
  "76904": { "Barber": 26, "Cosmetologist": 313 },
  "76905": { "Barber": 6, "Cosmetologist": 70 },
  "76950": { "Barber": 2, "Cosmetologist": 18 },
  "77002": { "Barber": 10, "Cosmetologist": 60 },
  "77003": { "Barber": 13, "Cosmetologist": 53 },
  "77004": { "Barber": 33, "Cosmetologist": 134 },
  "77005": { "Barber": 3, "Cosmetologist": 47 },
  "77006": { "Barber": 12, "Cosmetologist": 149 },
  "77008": { "Barber": 17, "Cosmetologist": 223 },
  "77009": { "Barber": 26, "Cosmetologist": 161 },
  "77011": { "Barber": 12, "Cosmetologist": 71 },
  "77012": { "Barber": 23, "Cosmetologist": 92 },
  "77013": { "Barber": 11, "Cosmetologist": 78 },
  "77014": { "Barber": 34, "Cosmetologist": 446 },
  "77018": { "Barber": 15, "Cosmetologist": 142 },
  "77022": { "Barber": 35, "Cosmetologist": 136 },
  "77024": { "Barber": 6, "Cosmetologist": 128 },
  "77026": { "Barber": 26, "Cosmetologist": 89 },
  "77027": { "Barber": 7, "Cosmetologist": 143 },
  "77029": { "Barber": 12, "Cosmetologist": 112 },
  "77031": { "Barber": 10, "Cosmetologist": 147 },
  "77032": { "Barber": 13, "Cosmetologist": 51 },
  "77033": { "Barber": 43, "Cosmetologist": 141 },
  "77035": { "Barber": 20, "Cosmetologist": 145 },
  "77037": { "Barber": 16, "Cosmetologist": 141 },
  "77038": { "Barber": 33, "Cosmetologist": 407 },
  "77039": { "Barber": 24, "Cosmetologist": 132 },
  "77041": { "Barber": 19, "Cosmetologist": 468 },
  "77045": { "Barber": 42, "Cosmetologist": 182 },
  "77047": { "Barber": 56, "Cosmetologist": 253 },
  "77048": { "Barber": 40, "Cosmetologist": 150 },
  "77050": { "Barber": 6, "Cosmetologist": 24 },
  "77051": { "Barber": 29, "Cosmetologist": 105 },
  "77054": { "Barber": 14, "Cosmetologist": 74 },
  "77056": { "Barber": 12, "Cosmetologist": 152 },
  "77058": { "Barber": 10, "Cosmetologist": 122 },
  "77059": { "Barber": 5, "Cosmetologist": 94 },
  "77060": { "Barber": 30, "Cosmetologist": 152 },
  "77061": { "Barber": 18, "Cosmetologist": 235 },
  "77062": { "Barber": 10, "Cosmetologist": 155 },
  "77066": { "Barber": 40, "Cosmetologist": 654 },
  "77068": { "Barber": 18, "Cosmetologist": 161 },
  "77069": { "Barber": 21, "Cosmetologist": 156 },
  "77071": { "Barber": 27, "Cosmetologist": 202 },
  "77074": { "Barber": 18, "Cosmetologist": 197 },
  "77076": { "Barber": 31, "Cosmetologist": 147 },
  "77078": { "Barber": 15, "Cosmetologist": 80 },
  "77082": { "Barber": 50, "Cosmetologist": 687 },
  "77085": { "Barber": 10, "Cosmetologist": 143 },
  "77087": { "Barber": 52, "Cosmetologist": 228 },
  "77088": { "Barber": 89, "Cosmetologist": 453 },
  "77091": { "Barber": 39, "Cosmetologist": 140 },
  "77093": { "Barber": 55, "Cosmetologist": 244 },
  "77096": { "Barber": 18, "Cosmetologist": 159 },
  "77098": { "Barber": 11, "Cosmetologist": 110 },
  "77301": { "Barber": 22, "Cosmetologist": 247 },
  "77302": { "Barber": 14, "Cosmetologist": 162 },
  "77328": { "Barber": 8, "Cosmetologist": 97 },
  "77331": { "Barber": 1, "Cosmetologist": 39 },
  "77335": { "Barber": 3, "Cosmetologist": 9 },
  "77336": { "Barber": 1, "Cosmetologist": 110 },
  "77340": { "Barber": 12, "Cosmetologist": 126 },
  "77351": { "Barber": 18, "Cosmetologist": 146 },
  "77355": { "Barber": 9, "Cosmetologist": 212 },
  "77357": { "Barber": 20, "Cosmetologist": 276 },
  "77358": { "Barber": 4, "Cosmetologist": 36 },
  "77362": { "Barber": 2, "Cosmetologist": 56 },
  "77363": { "Barber": 3, "Cosmetologist": 31 },
  "77364": { "Barber": 2, "Cosmetologist": 12 },
  "77371": { "Barber": 2, "Cosmetologist": 34 },
  "77372": { "Barber": 6, "Cosmetologist": 102 },
  "77378": { "Barber": 13, "Cosmetologist": 133 },
  "77384": { "Barber": 15, "Cosmetologist": 213 },
  "77389": { "Barber": 18, "Cosmetologist": 301 },
  "77414": { "Barber": 22, "Cosmetologist": 132 },
  "77420": { "Barber": 2, "Cosmetologist": 13 },
  "77422": { "Barber": 10, "Cosmetologist": 78 },
  "77426": { "Barber": 3, "Cosmetologist": 18 },
  "77434": { "Barber": 3, "Cosmetologist": 24 },
  "77435": { "Barber": 3, "Cosmetologist": 23 },
  "77437": { "Barber": 9, "Cosmetologist": 124 },
  "77445": { "Barber": 8, "Cosmetologist": 77 },
  "77461": { "Barber": 6, "Cosmetologist": 59 },
  "77465": { "Barber": 6, "Cosmetologist": 72 },
  "77474": { "Barber": 7, "Cosmetologist": 68 },
  "77477": { "Barber": 31, "Cosmetologist": 286 },
  "77478": { "Barber": 3, "Cosmetologist": 335 },
  "77480": { "Barber": 3, "Cosmetologist": 41 },
  "77482": { "Barber": 1, "Cosmetologist": 15 },
  "77484": { "Barber": 6, "Cosmetologist": 93 },
  "77485": { "Barber": 5, "Cosmetologist": 24 },
  "77486": { "Barber": 5, "Cosmetologist": 46 },
  "77502": { "Barber": 24, "Cosmetologist": 263 },
  "77503": { "Barber": 8, "Cosmetologist": 183 },
  "77504": { "Barber": 12, "Cosmetologist": 230 },
  "77505": { "Barber": 12, "Cosmetologist": 264 },
  "77506": { "Barber": 18, "Cosmetologist": 177 },
  "77510": { "Barber": 4, "Cosmetologist": 86 },
  "77515": { "Barber": 26, "Cosmetologist": 232 },
  "77517": { "Barber": 3, "Cosmetologist": 44 },
  "77518": { "Barber": 8, "Cosmetologist": 97 },
  "77520": { "Barber": 21, "Cosmetologist": 188 },
  "77530": { "Barber": 19, "Cosmetologist": 202 },
  "77531": { "Barber": 5, "Cosmetologist": 123 },
  "77534": { "Barber": 2, "Cosmetologist": 25 },
  "77536": { "Barber": 16, "Cosmetologist": 278 },
  "77545": { "Barber": 53, "Cosmetologist": 199 },
  "77547": { "Barber": 11, "Cosmetologist": 53 },
  "77551": { "Barber": 11, "Cosmetologist": 117 },
  "77562": { "Barber": 6, "Cosmetologist": 52 },
  "77563": { "Barber": 3, "Cosmetologist": 70 },
  "77565": { "Barber": 1, "Cosmetologist": 70 },
  "77568": { "Barber": 18, "Cosmetologist": 169 },
  "77571": { "Barber": 27, "Cosmetologist": 282 },
  "77575": { "Barber": 8, "Cosmetologist": 89 },
  "77583": { "Barber": 47, "Cosmetologist": 299 },
  "77587": { "Barber": 6, "Cosmetologist": 106 },
  "77590": { "Barber": 22, "Cosmetologist": 210 },
  "77591": { "Barber": 25, "Cosmetologist": 135 },
  "77619": { "Barber": 9, "Cosmetologist": 152 },
  "77625": { "Barber": 6, "Cosmetologist": 80 },
  "77627": { "Barber": 10, "Cosmetologist": 194 },
  "77630": { "Barber": 11, "Cosmetologist": 214 },
  "77642": { "Barber": 27, "Cosmetologist": 327 },
  "77651": { "Barber": 4, "Cosmetologist": 117 },
  "77657": { "Barber": 5, "Cosmetologist": 204 },
  "77659": { "Barber": 4, "Cosmetologist": 37 },
  "77662": { "Barber": 5, "Cosmetologist": 234 },
  "77665": { "Barber": 3, "Cosmetologist": 54 },
  "77701": { "Barber": 14, "Cosmetologist": 63 },
  "77703": { "Barber": 14, "Cosmetologist": 61 },
  "77705": { "Barber": 21, "Cosmetologist": 141 },
  "77706": { "Barber": 15, "Cosmetologist": 232 },
  "77707": { "Barber": 14, "Cosmetologist": 130 },
  "77708": { "Barber": 16, "Cosmetologist": 77 },
  "77713": { "Barber": 8, "Cosmetologist": 164 },
  "77801": { "Barber": 12, "Cosmetologist": 64 },
  "77803": { "Barber": 39, "Cosmetologist": 246 },
  "77807": { "Barber": 5, "Cosmetologist": 89 },
  "77808": { "Barber": 6, "Cosmetologist": 138 },
  "77831": { "Barber": 2, "Cosmetologist": 13 },
  "77837": { "Barber": 2, "Cosmetologist": 2 },
  "77856": { "Barber": 2, "Cosmetologist": 36 },
  "77859": { "Barber": 5, "Cosmetologist": 35 },
  "77879": { "Barber": 5, "Cosmetologist": 38 },
  "77905": { "Barber": 7, "Cosmetologist": 83 },
  "77957": { "Barber": 4, "Cosmetologist": 60 },
  "77963": { "Barber": 5, "Cosmetologist": 25 },
  "77964": { "Barber": 2, "Cosmetologist": 58 },
  "77995": { "Barber": 3, "Cosmetologist": 49 },
  "78005": { "Barber": 2, "Cosmetologist": 6 },
  "78009": { "Barber": 2, "Cosmetologist": 85 },
  "78010": { "Barber": 1, "Cosmetologist": 30 },
  "78013": { "Barber": 2, "Cosmetologist": 39 },
  "78016": { "Barber": 7, "Cosmetologist": 58 },
  "78022": { "Barber": 1, "Cosmetologist": 31 },
  "78023": { "Barber": 19, "Cosmetologist": 217 },
  "78026": { "Barber": 5, "Cosmetologist": 36 },
  "78040": { "Barber": 12, "Cosmetologist": 160 },
  "78045": { "Barber": 15, "Cosmetologist": 394 },
  "78046": { "Barber": 24, "Cosmetologist": 405 },
  "78052": { "Barber": 5, "Cosmetologist": 60 },
  "78059": { "Barber": 4, "Cosmetologist": 34 },
  "78061": { "Barber": 9, "Cosmetologist": 41 },
  "78064": { "Barber": 6, "Cosmetologist": 126 },
  "78065": { "Barber": 9, "Cosmetologist": 89 },
  "78069": { "Barber": 2, "Cosmetologist": 38 },
  "78070": { "Barber": 9, "Cosmetologist": 157 },
  "78071": { "Barber": 5, "Cosmetologist": 14 },
  "78076": { "Barber": 9, "Cosmetologist": 74 },
  "78101": { "Barber": 6, "Cosmetologist": 59 },
  "78112": { "Barber": 5, "Cosmetologist": 54 },
  "78119": { "Barber": 3, "Cosmetologist": 35 },
  "78121": { "Barber": 5, "Cosmetologist": 128 },
  "78124": { "Barber": 2, "Cosmetologist": 50 },
  "78140": { "Barber": 2, "Cosmetologist": 13 },
  "78148": { "Barber": 15, "Cosmetologist": 197 },
  "78151": { "Barber": 2, "Cosmetologist": 8 },
  "78155": { "Barber": 30, "Cosmetologist": 318 },
  "78160": { "Barber": 1, "Cosmetologist": 29 },
  "78161": { "Barber": 2, "Cosmetologist": 7 },
  "78164": { "Barber": 2, "Cosmetologist": 23 },
  "78202": { "Barber": 6, "Cosmetologist": 46 },
  "78203": { "Barber": 5, "Cosmetologist": 33 },
  "78204": { "Barber": 3, "Cosmetologist": 67 },
  "78205": { "Barber": 2, "Cosmetologist": 22 },
  "78207": { "Barber": 30, "Cosmetologist": 209 },
  "78208": { "Barber": 7, "Cosmetologist": 21 },
  "78210": { "Barber": 19, "Cosmetologist": 184 },
  "78211": { "Barber": 21, "Cosmetologist": 178 },
  "78212": { "Barber": 18, "Cosmetologist": 149 },
  "78214": { "Barber": 19, "Cosmetologist": 151 },
  "78219": { "Barber": 17, "Cosmetologist": 98 },
  "78220": { "Barber": 23, "Cosmetologist": 129 },
  "78221": { "Barber": 31, "Cosmetologist": 268 },
  "78224": { "Barber": 14, "Cosmetologist": 131 },
  "78225": { "Barber": 6, "Cosmetologist": 69 },
  "78226": { "Barber": 1, "Cosmetologist": 35 },
  "78228": { "Barber": 44, "Cosmetologist": 350 },
  "78231": { "Barber": 6, "Cosmetologist": 65 },
  "78237": { "Barber": 19, "Cosmetologist": 181 },
  "78239": { "Barber": 26, "Cosmetologist": 268 },
  "78242": { "Barber": 20, "Cosmetologist": 197 },
  "78248": { "Barber": 3, "Cosmetologist": 78 },
  "78252": { "Barber": 16, "Cosmetologist": 132 },
  "78257": { "Barber": 5, "Cosmetologist": 84 },
  "78263": { "Barber": 7, "Cosmetologist": 33 },
  "78264": { "Barber": 7, "Cosmetologist": 76 },
  "78266": { "Barber": 3, "Cosmetologist": 91 },
  "78332": { "Barber": 28, "Cosmetologist": 136 },
  "78336": { "Barber": 8, "Cosmetologist": 71 },
  "78355": { "Barber": 6, "Cosmetologist": 34 },
  "78362": { "Barber": 13, "Cosmetologist": 41 },
  "78363": { "Barber": 21, "Cosmetologist": 146 },
  "78368": { "Barber": 5, "Cosmetologist": 38 },
  "78370": { "Barber": 8, "Cosmetologist": 30 },
  "78372": { "Barber": 3, "Cosmetologist": 42 },
  "78373": { "Barber": 4, "Cosmetologist": 23 },
  "78375": { "Barber": 2, "Cosmetologist": 12 },
  "78377": { "Barber": 6, "Cosmetologist": 18 },
  "78380": { "Barber": 30, "Cosmetologist": 132 },
  "78384": { "Barber": 4, "Cosmetologist": 33 },
  "78390": { "Barber": 6, "Cosmetologist": 16 },
  "78393": { "Barber": 2, "Cosmetologist": 16 },
  "78402": { "Barber": 2, "Cosmetologist": 3 },
  "78404": { "Barber": 18, "Cosmetologist": 64 },
  "78405": { "Barber": 13, "Cosmetologist": 55 },
  "78408": { "Barber": 11, "Cosmetologist": 37 },
  "78409": { "Barber": 2, "Cosmetologist": 9 },
  "78410": { "Barber": 38, "Cosmetologist": 197 },
  "78411": { "Barber": 34, "Cosmetologist": 179 },
  "78412": { "Barber": 43, "Cosmetologist": 201 },
  "78415": { "Barber": 66, "Cosmetologist": 233 },
  "78416": { "Barber": 21, "Cosmetologist": 63 },
  "78417": { "Barber": 9, "Cosmetologist": 25 },
  "78501": { "Barber": 50, "Cosmetologist": 463 },
  "78503": { "Barber": 13, "Cosmetologist": 143 },
  "78504": { "Barber": 42, "Cosmetologist": 464 },
  "78516": { "Barber": 49, "Cosmetologist": 242 },
  "78520": { "Barber": 40, "Cosmetologist": 348 },
  "78521": { "Barber": 50, "Cosmetologist": 495 },
  "78526": { "Barber": 21, "Cosmetologist": 327 },
  "78538": { "Barber": 18, "Cosmetologist": 54 },
  "78539": { "Barber": 32, "Cosmetologist": 240 },
  "78542": { "Barber": 98, "Cosmetologist": 539 },
  "78557": { "Barber": 8, "Cosmetologist": 90 },
  "78559": { "Barber": 23, "Cosmetologist": 41 },
  "78560": { "Barber": 3, "Cosmetologist": 39 },
  "78566": { "Barber": 20, "Cosmetologist": 146 },
  "78569": { "Barber": 2, "Cosmetologist": 12 },
  "78570": { "Barber": 75, "Cosmetologist": 141 },
  "78572": { "Barber": 62, "Cosmetologist": 606 },
  "78573": { "Barber": 47, "Cosmetologist": 356 },
  "78574": { "Barber": 57, "Cosmetologist": 523 },
  "78575": { "Barber": 5, "Cosmetologist": 20 },
  "78576": { "Barber": 11, "Cosmetologist": 108 },
  "78578": { "Barber": 7, "Cosmetologist": 50 },
  "78580": { "Barber": 8, "Cosmetologist": 38 },
  "78582": { "Barber": 54, "Cosmetologist": 271 },
  "78583": { "Barber": 7, "Cosmetologist": 31 },
  "78584": { "Barber": 9, "Cosmetologist": 84 },
  "78589": { "Barber": 55, "Cosmetologist": 242 },
  "78593": { "Barber": 1, "Cosmetologist": 25 },
  "78595": { "Barber": 4, "Cosmetologist": 38 },
  "78606": { "Barber": 1, "Cosmetologist": 53 },
  "78612": { "Barber": 10, "Cosmetologist": 99 },
  "78615": { "Barber": 1, "Cosmetologist": 14 },
  "78616": { "Barber": 5, "Cosmetologist": 63 },
  "78617": { "Barber": 21, "Cosmetologist": 188 },
  "78620": { "Barber": 9, "Cosmetologist": 117 },
  "78621": { "Barber": 26, "Cosmetologist": 160 },
  "78624": { "Barber": 8, "Cosmetologist": 177 },
  "78626": { "Barber": 32, "Cosmetologist": 404 },
  "78639": { "Barber": 5, "Cosmetologist": 57 },
  "78645": { "Barber": 6, "Cosmetologist": 129 },
  "78648": { "Barber": 4, "Cosmetologist": 56 },
  "78650": { "Barber": 2, "Cosmetologist": 8 },
  "78652": { "Barber": 2, "Cosmetologist": 49 },
  "78657": { "Barber": 3, "Cosmetologist": 38 },
  "78659": { "Barber": 2, "Cosmetologist": 22 },
  "78662": { "Barber": 2, "Cosmetologist": 16 },
  "78701": { "Barber": 3, "Cosmetologist": 38 },
  "78717": { "Barber": 8, "Cosmetologist": 241 },
  "78721": { "Barber": 9, "Cosmetologist": 50 },
  "78726": { "Barber": 2, "Cosmetologist": 94 },
  "78730": { "Barber": 2, "Cosmetologist": 52 },
  "78731": { "Barber": 9, "Cosmetologist": 120 },
  "78736": { "Barber": 5, "Cosmetologist": 70 },
  "78739": { "Barber": 1, "Cosmetologist": 95 },
  "78746": { "Barber": 5, "Cosmetologist": 86 },
  "78749": { "Barber": 8, "Cosmetologist": 203 },
  "78827": { "Barber": 3, "Cosmetologist": 1 },
  "78834": { "Barber": 5, "Cosmetologist": 28 },
  "78839": { "Barber": 3, "Cosmetologist": 41 },
  "78852": { "Barber": 9, "Cosmetologist": 160 },
  "78934": { "Barber": 9, "Cosmetologist": 43 },
  "78940": { "Barber": 2, "Cosmetologist": 18 },
  "78942": { "Barber": 7, "Cosmetologist": 46 },
  "78946": { "Barber": 2, "Cosmetologist": 7 },
  "78947": { "Barber": 3, "Cosmetologist": 30 },
  "78950": { "Barber": 3, "Cosmetologist": 9 },
  "78956": { "Barber": 5, "Cosmetologist": 42 },
  "78957": { "Barber": 7, "Cosmetologist": 50 },
  "78962": { "Barber": 4, "Cosmetologist": 34 },
  "79007": { "Barber": 1, "Cosmetologist": 65 },
  "79009": { "Barber": 2, "Cosmetologist": 3 },
  "79014": { "Barber": 2, "Cosmetologist": 25 },
  "79019": { "Barber": 2, "Cosmetologist": 12 },
  "79022": { "Barber": 2, "Cosmetologist": 47 },
  "79027": { "Barber": 2, "Cosmetologist": 16 },
  "79029": { "Barber": 12, "Cosmetologist": 86 },
  "79035": { "Barber": 3, "Cosmetologist": 12 },
  "79036": { "Barber": 5, "Cosmetologist": 26 },
  "79041": { "Barber": 2, "Cosmetologist": 13 },
  "79045": { "Barber": 8, "Cosmetologist": 148 },
  "79057": { "Barber": 1, "Cosmetologist": 8 },
  "79064": { "Barber": 2, "Cosmetologist": 15 },
  "79065": { "Barber": 6, "Cosmetologist": 126 },
  "79068": { "Barber": 3, "Cosmetologist": 13 },
  "79070": { "Barber": 5, "Cosmetologist": 83 },
  "79072": { "Barber": 23, "Cosmetologist": 200 },
  "79083": { "Barber": 2, "Cosmetologist": 15 },
  "79088": { "Barber": 2, "Cosmetologist": 29 },
  "79095": { "Barber": 2, "Cosmetologist": 16 },
  "79096": { "Barber": 2, "Cosmetologist": 18 },
  "79098": { "Barber": 3, "Cosmetologist": 3 },
  "79102": { "Barber": 4, "Cosmetologist": 60 },
  "79103": { "Barber": 8, "Cosmetologist": 85 },
  "79104": { "Barber": 5, "Cosmetologist": 41 },
  "79108": { "Barber": 5, "Cosmetologist": 74 },
  "79124": { "Barber": 8, "Cosmetologist": 64 },
  "79201": { "Barber": 3, "Cosmetologist": 45 },
  "79226": { "Barber": 3, "Cosmetologist": 20 },
  "79235": { "Barber": 3, "Cosmetologist": 11 },
  "79241": { "Barber": 2, "Cosmetologist": 15 },
  "79257": { "Barber": 2, "Cosmetologist": 7 },
  "79316": { "Barber": 11, "Cosmetologist": 57 },
  "79322": { "Barber": 1, "Cosmetologist": 7 },
  "79323": { "Barber": 3, "Cosmetologist": 34 },
  "79329": { "Barber": 5, "Cosmetologist": 20 },
  "79331": { "Barber": 11, "Cosmetologist": 59 },
  "79336": { "Barber": 6, "Cosmetologist": 158 },
  "79339": { "Barber": 13, "Cosmetologist": 29 },
  "79347": { "Barber": 7, "Cosmetologist": 28 },
  "79356": { "Barber": 4, "Cosmetologist": 28 },
  "79358": { "Barber": 2, "Cosmetologist": 18 },
  "79359": { "Barber": 3, "Cosmetologist": 6 },
  "79363": { "Barber": 6, "Cosmetologist": 48 },
  "79364": { "Barber": 5, "Cosmetologist": 73 },
  "79367": { "Barber": 2, "Cosmetologist": 8 },
  "79373": { "Barber": 4, "Cosmetologist": 20 },
  "79382": { "Barber": 7, "Cosmetologist": 61 },
  "79403": { "Barber": 18, "Cosmetologist": 74 },
  "79404": { "Barber": 14, "Cosmetologist": 64 },
  "79407": { "Barber": 24, "Cosmetologist": 203 },
  "79410": { "Barber": 9, "Cosmetologist": 47 },
  "79411": { "Barber": 8, "Cosmetologist": 36 },
  "79412": { "Barber": 11, "Cosmetologist": 104 },
  "79415": { "Barber": 16, "Cosmetologist": 95 },
  "79501": { "Barber": 3, "Cosmetologist": 19 },
  "79510": { "Barber": 3, "Cosmetologist": 53 },
  "79520": { "Barber": 2, "Cosmetologist": 6 },
  "79521": { "Barber": 1, "Cosmetologist": 20 },
  "79536": { "Barber": 2, "Cosmetologist": 30 },
  "79549": { "Barber": 7, "Cosmetologist": 99 },
  "79553": { "Barber": 2, "Cosmetologist": 10 },
  "79556": { "Barber": 9, "Cosmetologist": 58 },
  "79562": { "Barber": 1, "Cosmetologist": 33 },
  "79567": { "Barber": 1, "Cosmetologist": 17 },
  "79603": { "Barber": 25, "Cosmetologist": 162 },
  "79714": { "Barber": 10, "Cosmetologist": 131 },
  "79731": { "Barber": 1, "Cosmetologist": 29 },
  "79735": { "Barber": 5, "Cosmetologist": 60 },
  "79752": { "Barber": 2, "Cosmetologist": 4 },
  "79756": { "Barber": 3, "Cosmetologist": 57 },
  "79763": { "Barber": 17, "Cosmetologist": 204 },
  "79766": { "Barber": 4, "Cosmetologist": 45 },
  "79772": { "Barber": 14, "Cosmetologist": 39 },
  "79821": { "Barber": 4, "Cosmetologist": 29 },
  "79830": { "Barber": 2, "Cosmetologist": 27 },
  "79836": { "Barber": 4, "Cosmetologist": 64 },
  "79843": { "Barber": 1, "Cosmetologist": 7 },
  "79849": { "Barber": 6, "Cosmetologist": 79 },
  "79901": { "Barber": 4, "Cosmetologist": 26 },
  "79905": { "Barber": 15, "Cosmetologist": 78 },
  "79907": { "Barber": 14, "Cosmetologist": 297 },
  "79911": { "Barber": 3, "Cosmetologist": 54 },
  "79915": { "Barber": 13, "Cosmetologist": 187 },
  "79925": { "Barber": 15, "Cosmetologist": 271 },
  "79927": { "Barber": 15, "Cosmetologist": 298 },
  "79928": { "Barber": 48, "Cosmetologist": 576 },
  "79930": { "Barber": 14, "Cosmetologist": 107 },
  "79935": { "Barber": 8, "Cosmetologist": 110 },
  "75102": { "Cosmetologist": 11 },
  "75117": { "Cosmetologist": 32 },
  "75127": { "Cosmetologist": 5 },
  "75148": { "Cosmetologist": 29 },
  "75158": { "Cosmetologist": 37 },
  "75247": { "Cosmetologist": 8 },
  "75411": { "Cosmetologist": 3 },
  "75412": { "Cosmetologist": 4 },
  "75414": { "Cosmetologist": 24 },
  "75420": { "Cosmetologist": 13 },
  "75421": { "Cosmetologist": 3 },
  "75423": { "Cosmetologist": 16 },
  "75424": { "Cosmetologist": 51 },
  "75426": { "Cosmetologist": 14 },
  "75433": { "Cosmetologist": 17 },
  "75435": { "Cosmetologist": 7 },
  "75436": { "Cosmetologist": 7 },
  "75437": { "Cosmetologist": 6 },
  "75439": { "Cosmetologist": 4 },
  "75446": { "Cosmetologist": 19 },
  "75447": { "Cosmetologist": 9 },
  "75448": { "Cosmetologist": 2 },
  "75451": { "Cosmetologist": 8 },
  "75459": { "Cosmetologist": 39 },
  "75468": { "Cosmetologist": 7 },
  "75471": { "Cosmetologist": 8 },
  "75472": { "Cosmetologist": 29 },
  "75473": { "Cosmetologist": 25 },
  "75476": { "Cosmetologist": 9 },
  "75477": { "Cosmetologist": 4 },
  "75478": { "Cosmetologist": 8 },
  "75479": { "Cosmetologist": 14 },
  "75480": { "Cosmetologist": 6 },
  "75481": { "Cosmetologist": 2 },
  "75486": { "Cosmetologist": 12 },
  "75487": { "Cosmetologist": 9 },
  "75488": { "Cosmetologist": 7 },
  "75490": { "Cosmetologist": 24 },
  "75492": { "Cosmetologist": 4 },
  "75496": { "Cosmetologist": 18 },
  "75497": { "Cosmetologist": 24 },
  "75550": { "Cosmetologist": 3 },
  "75554": { "Cosmetologist": 10 },
  "75555": { "Cosmetologist": 5 },
  "75556": { "Cosmetologist": 10 },
  "75558": { "Cosmetologist": 12 },
  "75560": { "Cosmetologist": 4 },
  "75566": { "Cosmetologist": 4 },
  "75571": { "Cosmetologist": 12 },
  "75572": { "Cosmetologist": 33 },
  "75630": { "Cosmetologist": 17 },
  "75631": { "Cosmetologist": 31 },
  "75643": { "Cosmetologist": 7 },
  "75661": { "Cosmetologist": 11 },
  "75667": { "Cosmetologist": 6 },
  "75668": { "Cosmetologist": 5 },
  "75683": { "Cosmetologist": 31 },
  "75692": { "Cosmetologist": 16 },
  "75755": { "Cosmetologist": 40 },
  "75770": { "Cosmetologist": 20 },
  "75784": { "Cosmetologist": 2 },
  "75831": { "Cosmetologist": 40 },
  "75838": { "Cosmetologist": 4 },
  "75845": { "Cosmetologist": 20 },
  "75846": { "Cosmetologist": 18 },
  "75847": { "Cosmetologist": 8 },
  "75850": { "Cosmetologist": 6 },
  "75851": { "Cosmetologist": 10 },
  "75852": { "Cosmetologist": 9 },
  "75853": { "Cosmetologist": 16 },
  "75856": { "Cosmetologist": 2 },
  "75859": { "Cosmetologist": 12 },
  "75861": { "Cosmetologist": 4 },
  "75925": { "Cosmetologist": 11 },
  "75926": { "Cosmetologist": 6 },
  "75928": { "Cosmetologist": 5 },
  "75929": { "Cosmetologist": 7 },
  "75930": { "Cosmetologist": 15 },
  "75931": { "Cosmetologist": 17 },
  "75932": { "Cosmetologist": 7 },
  "75933": { "Cosmetologist": 4 },
  "75936": { "Cosmetologist": 4 },
  "75937": { "Cosmetologist": 8 },
  "75938": { "Cosmetologist": 18 },
  "75939": { "Cosmetologist": 28 },
  "75943": { "Cosmetologist": 15 },
  "75944": { "Cosmetologist": 11 },
  "75959": { "Cosmetologist": 4 },
  "75960": { "Cosmetologist": 10 },
  "75966": { "Cosmetologist": 24 },
  "75968": { "Cosmetologist": 14 },
  "75969": { "Cosmetologist": 27 },
  "75975": { "Cosmetologist": 27 },
  "75977": { "Cosmetologist": 2 },
  "76041": { "Cosmetologist": 1 },
  "76050": { "Cosmetologist": 58 },
  "76061": { "Cosmetologist": 2 },
  "76064": { "Cosmetologist": 16 },
  "76070": { "Cosmetologist": 3 },
  "76073": { "Cosmetologist": 70 },
  "76077": { "Cosmetologist": 4 },
  "76078": { "Cosmetologist": 70 },
  "76093": { "Cosmetologist": 20 },
  "76225": { "Cosmetologist": 30 },
  "76228": { "Cosmetologist": 5 },
  "76238": { "Cosmetologist": 7 },
  "76239": { "Cosmetologist": 7 },
  "76250": { "Cosmetologist": 12 },
  "76251": { "Cosmetologist": 4 },
  "76252": { "Cosmetologist": 20 },
  "76265": { "Cosmetologist": 13 },
  "76270": { "Cosmetologist": 13 },
  "76305": { "Cosmetologist": 33 },
  "76311": { "Cosmetologist": 7 },
  "76357": { "Cosmetologist": 7 },
  "76360": { "Cosmetologist": 5 },
  "76365": { "Cosmetologist": 32 },
  "76366": { "Cosmetologist": 10 },
  "76372": { "Cosmetologist": 4 },
  "76377": { "Cosmetologist": 4 },
  "76427": { "Cosmetologist": 3 },
  "76430": { "Cosmetologist": 13 },
  "76432": { "Cosmetologist": 6 },
  "76433": { "Cosmetologist": 16 },
  "76435": { "Cosmetologist": 4 },
  "76443": { "Cosmetologist": 17 },
  "76444": { "Cosmetologist": 22 },
  "76445": { "Cosmetologist": 3 },
  "76446": { "Cosmetologist": 46 },
  "76449": { "Cosmetologist": 16 },
  "76453": { "Cosmetologist": 11 },
  "76455": { "Cosmetologist": 4 },
  "76457": { "Cosmetologist": 25 },
  "76460": { "Cosmetologist": 2 },
  "76462": { "Cosmetologist": 36 },
  "76463": { "Cosmetologist": 5 },
  "76464": { "Cosmetologist": 2 },
  "76470": { "Cosmetologist": 11 },
  "76471": { "Cosmetologist": 15 },
  "76472": { "Cosmetologist": 22 },
  "76475": { "Cosmetologist": 5 },
  "76476": { "Cosmetologist": 29 },
  "76483": { "Cosmetologist": 6 },
  "76484": { "Cosmetologist": 14 },
  "76486": { "Cosmetologist": 6 },
  "76491": { "Cosmetologist": 2 },
  "76518": { "Cosmetologist": 9 },
  "76519": { "Cosmetologist": 3 },
  "76525": { "Cosmetologist": 4 },
  "76534": { "Cosmetologist": 17 },
  "76538": { "Cosmetologist": 5 },
  "76554": { "Cosmetologist": 14 },
  "76556": { "Cosmetologist": 9 },
  "76561": { "Cosmetologist": 4 },
  "76566": { "Cosmetologist": 2 },
  "76569": { "Cosmetologist": 19 },
  "76570": { "Cosmetologist": 10 },
  "76578": { "Cosmetologist": 15 },
  "76579": { "Cosmetologist": 42 },
  "76621": { "Cosmetologist": 13 },
  "76622": { "Cosmetologist": 8 },
  "76624": { "Cosmetologist": 16 },
  "76626": { "Cosmetologist": 17 },
  "76627": { "Cosmetologist": 12 },
  "76630": { "Cosmetologist": 4 },
  "76631": { "Cosmetologist": 5 },
  "76632": { "Cosmetologist": 11 },
  "76635": { "Cosmetologist": 3 },
  "76636": { "Cosmetologist": 10 },
  "76638": { "Cosmetologist": 29 },
  "76639": { "Cosmetologist": 8 },
  "76641": { "Cosmetologist": 14 },
  "76648": { "Cosmetologist": 11 },
  "76649": { "Cosmetologist": 5 },
  "76651": { "Cosmetologist": 23 },
  "76652": { "Cosmetologist": 5 },
  "76653": { "Cosmetologist": 8 },
  "76656": { "Cosmetologist": 25 },
  "76660": { "Cosmetologist": 3 },
  "76664": { "Cosmetologist": 12 },
  "76670": { "Cosmetologist": 8 },
  "76673": { "Cosmetologist": 3 },
  "76676": { "Cosmetologist": 2 },
  "76681": { "Cosmetologist": 3 },
  "76687": { "Cosmetologist": 7 },
  "76690": { "Cosmetologist": 9 },
  "76802": { "Cosmetologist": 52 },
  "76823": { "Cosmetologist": 18 },
  "76827": { "Cosmetologist": 3 },
  "76832": { "Cosmetologist": 2 },
  "76837": { "Cosmetologist": 5 },
  "76852": { "Cosmetologist": 2 },
  "76853": { "Cosmetologist": 10 },
  "76857": { "Cosmetologist": 8 },
  "76859": { "Cosmetologist": 11 },
  "76861": { "Cosmetologist": 8 },
  "76864": { "Cosmetologist": 7 },
  "76866": { "Cosmetologist": 2 },
  "76871": { "Cosmetologist": 4 },
  "76872": { "Cosmetologist": 7 },
  "76875": { "Cosmetologist": 6 },
  "76878": { "Cosmetologist": 8 },
  "76890": { "Cosmetologist": 12 },
  "76932": { "Cosmetologist": 11 },
  "76933": { "Cosmetologist": 8 },
  "76934": { "Cosmetologist": 4 },
  "76935": { "Cosmetologist": 17 },
  "76936": { "Cosmetologist": 8 },
  "76937": { "Cosmetologist": 1 },
  "76940": { "Cosmetologist": 2 },
  "76941": { "Cosmetologist": 7 },
  "76943": { "Cosmetologist": 13 },
  "76945": { "Cosmetologist": 8 },
  "76951": { "Cosmetologist": 5 },
  "76957": { "Cosmetologist": 4 },
  "77046": { "Cosmetologist": 17 },
  "77360": { "Cosmetologist": 33 },
  "77417": { "Cosmetologist": 17 },
  "77430": { "Cosmetologist": 9 },
  "77442": { "Cosmetologist": 7 },
  "77444": { "Cosmetologist": 5 },
  "77455": { "Cosmetologist": 11 },
  "77514": { "Cosmetologist": 40 },
  "77519": { "Cosmetologist": 7 },
  "77538": { "Cosmetologist": 9 },
  "77560": { "Cosmetologist": 13 },
  "77564": { "Cosmetologist": 8 },
  "77577": { "Cosmetologist": 5 },
  "77585": { "Cosmetologist": 3 },
  "77597": { "Cosmetologist": 2 },
  "77616": { "Cosmetologist": 11 },
  "77622": { "Cosmetologist": 5 },
  "77624": { "Cosmetologist": 2 },
  "77660": { "Cosmetologist": 5 },
  "77664": { "Cosmetologist": 29 },
  "77702": { "Cosmetologist": 17 },
  "77830": { "Cosmetologist": 26 },
  "77853": { "Cosmetologist": 5 },
  "77857": { "Cosmetologist": 10 },
  "77861": { "Cosmetologist": 21 },
  "77865": { "Cosmetologist": 17 },
  "77871": { "Cosmetologist": 23 },
  "77872": { "Cosmetologist": 18 },
  "77873": { "Cosmetologist": 14 },
  "77880": { "Cosmetologist": 14 },
  "77962": { "Cosmetologist": 19 },
  "77968": { "Cosmetologist": 15 },
  "77971": { "Cosmetologist": 1 },
  "77974": { "Cosmetologist": 3 },
  "77975": { "Cosmetologist": 8 },
  "77983": { "Cosmetologist": 12 },
  "77984": { "Cosmetologist": 31 },
  "77990": { "Cosmetologist": 4 },
  "78011": { "Cosmetologist": 4 },
  "78012": { "Cosmetologist": 2 },
  "78014": { "Cosmetologist": 14 },
  "78017": { "Cosmetologist": 16 },
  "78024": { "Cosmetologist": 4 },
  "78027": { "Cosmetologist": 2 },
  "78055": { "Cosmetologist": 12 },
  "78056": { "Cosmetologist": 12 },
  "78057": { "Cosmetologist": 7 },
  "78058": { "Cosmetologist": 8 },
  "78066": { "Cosmetologist": 2 },
  "78072": { "Cosmetologist": 4 },
  "78113": { "Cosmetologist": 7 },
  "78116": { "Cosmetologist": 2 },
  "78118": { "Cosmetologist": 18 },
  "78123": { "Cosmetologist": 17 },
  "78141": { "Cosmetologist": 5 },
  "78152": { "Cosmetologist": 32 },
  "78159": { "Cosmetologist": 4 },
  "78215": { "Cosmetologist": 18 },
  "78235": { "Cosmetologist": 9 },
  "78330": { "Cosmetologist": 8 },
  "78340": { "Cosmetologist": 2 },
  "78343": { "Cosmetologist": 27 },
  "78344": { "Cosmetologist": 3 },
  "78357": { "Cosmetologist": 14 },
  "78361": { "Cosmetologist": 20 },
  "78376": { "Cosmetologist": 1 },
  "78379": { "Cosmetologist": 5 },
  "78383": { "Cosmetologist": 11 },
  "78389": { "Cosmetologist": 13 },
  "78406": { "Cosmetologist": 5 },
  "78407": { "Cosmetologist": 5 },
  "78563": { "Cosmetologist": 3 },
  "78591": { "Cosmetologist": 2 },
  "78597": { "Cosmetologist": 14 },
  "78598": { "Cosmetologist": 2 },
  "78605": { "Cosmetologist": 47 },
  "78608": { "Cosmetologist": 7 },
  "78609": { "Cosmetologist": 12 },
  "78614": { "Cosmetologist": 2 },
  "78618": { "Cosmetologist": 3 },
  "78619": { "Cosmetologist": 28 },
  "78623": { "Cosmetologist": 13 },
  "78631": { "Cosmetologist": 13 },
  "78632": { "Cosmetologist": 9 },
  "78636": { "Cosmetologist": 26 },
  "78638": { "Cosmetologist": 22 },
  "78655": { "Cosmetologist": 23 },
  "78656": { "Cosmetologist": 13 },
  "78663": { "Cosmetologist": 4 },
  "78671": { "Cosmetologist": 5 },
  "78672": { "Cosmetologist": 4 },
  "78719": { "Cosmetologist": 12 },
  "78733": { "Cosmetologist": 29 },
  "78742": { "Cosmetologist": 4 },
  "78829": { "Cosmetologist": 4 },
  "78830": { "Cosmetologist": 2 },
  "78832": { "Cosmetologist": 7 },
  "78833": { "Cosmetologist": 5 },
  "78838": { "Cosmetologist": 2 },
  "78850": { "Cosmetologist": 5 },
  "78870": { "Cosmetologist": 6 },
  "78873": { "Cosmetologist": 12 },
  "78877": { "Cosmetologist": 3 },
  "78881": { "Cosmetologist": 15 },
  "78886": { "Cosmetologist": 3 },
  "78932": { "Cosmetologist": 4 },
  "78933": { "Cosmetologist": 4 },
  "78935": { "Cosmetologist": 3 },
  "78941": { "Cosmetologist": 24 },
  "78944": { "Cosmetologist": 4 },
  "78948": { "Cosmetologist": 6 },
  "78949": { "Cosmetologist": 2 },
  "78953": { "Cosmetologist": 7 },
  "78954": { "Cosmetologist": 9 },
  "78959": { "Cosmetologist": 7 },
  "78963": { "Cosmetologist": 6 },
  "79005": { "Cosmetologist": 11 },
  "79010": { "Cosmetologist": 2 },
  "79011": { "Cosmetologist": 4 },
  "79012": { "Cosmetologist": 4 },
  "79013": { "Cosmetologist": 10 },
  "79024": { "Cosmetologist": 1 },
  "79031": { "Cosmetologist": 3 },
  "79034": { "Cosmetologist": 7 },
  "79039": { "Cosmetologist": 6 },
  "79042": { "Cosmetologist": 4 },
  "79043": { "Cosmetologist": 4 },
  "79044": { "Cosmetologist": 1 },
  "79046": { "Cosmetologist": 2 },
  "79052": { "Cosmetologist": 10 },
  "79059": { "Cosmetologist": 5 },
  "79061": { "Cosmetologist": 5 },
  "79063": { "Cosmetologist": 5 },
  "79079": { "Cosmetologist": 14 },
  "79080": { "Cosmetologist": 2 },
  "79081": { "Cosmetologist": 18 },
  "79082": { "Cosmetologist": 1 },
  "79084": { "Cosmetologist": 9 },
  "79087": { "Cosmetologist": 2 },
  "79092": { "Cosmetologist": 11 },
  "79097": { "Cosmetologist": 12 },
  "79111": { "Cosmetologist": 11 },
  "79225": { "Cosmetologist": 4 },
  "79227": { "Cosmetologist": 7 },
  "79244": { "Cosmetologist": 3 },
  "79245": { "Cosmetologist": 14 },
  "79248": { "Cosmetologist": 6 },
  "79250": { "Cosmetologist": 8 },
  "79252": { "Cosmetologist": 26 },
  "79255": { "Cosmetologist": 2 },
  "79261": { "Cosmetologist": 3 },
  "79311": { "Cosmetologist": 17 },
  "79312": { "Cosmetologist": 5 },
  "79313": { "Cosmetologist": 8 },
  "79325": { "Cosmetologist": 5 },
  "79343": { "Cosmetologist": 4 },
  "79345": { "Cosmetologist": 8 },
  "79346": { "Cosmetologist": 14 },
  "79350": { "Cosmetologist": 7 },
  "79351": { "Cosmetologist": 9 },
  "79355": { "Cosmetologist": 9 },
  "79357": { "Cosmetologist": 10 },
  "79366": { "Cosmetologist": 7 },
  "79370": { "Cosmetologist": 9 },
  "79371": { "Cosmetologist": 7 },
  "79372": { "Cosmetologist": 7 },
  "79379": { "Cosmetologist": 4 },
  "79381": { "Cosmetologist": 10 },
  "79502": { "Cosmetologist": 7 },
  "79504": { "Cosmetologist": 14 },
  "79506": { "Cosmetologist": 3 },
  "79508": { "Cosmetologist": 13 },
  "79511": { "Cosmetologist": 10 },
  "79512": { "Cosmetologist": 29 },
  "79525": { "Cosmetologist": 17 },
  "79526": { "Cosmetologist": 3 },
  "79527": { "Cosmetologist": 2 },
  "79528": { "Cosmetologist": 2 },
  "79529": { "Cosmetologist": 8 },
  "79530": { "Cosmetologist": 2 },
  "79532": { "Cosmetologist": 2 },
  "79533": { "Cosmetologist": 3 },
  "79541": { "Cosmetologist": 7 },
  "79543": { "Cosmetologist": 8 },
  "79544": { "Cosmetologist": 5 },
  "79545": { "Cosmetologist": 6 },
  "79546": { "Cosmetologist": 9 },
  "79547": { "Cosmetologist": 2 },
  "79561": { "Cosmetologist": 3 },
  "79563": { "Cosmetologist": 6 },
  "79565": { "Cosmetologist": 1 },
  "79607": { "Cosmetologist": 9 },
  "79713": { "Cosmetologist": 2 },
  "79734": { "Cosmetologist": 6 },
  "79738": { "Cosmetologist": 2 },
  "79739": { "Cosmetologist": 5 },
  "79741": { "Cosmetologist": 2 },
  "79745": { "Cosmetologist": 37 },
  "79782": { "Cosmetologist": 23 },
  "79839": { "Cosmetologist": 3 },
  "79852": { "Cosmetologist": 6 },
  "79906": { "Cosmetologist": 8 },
  "79922": { "Cosmetologist": 37 },
  "22503": { "Cosmetologist": 1 },
  "40423": { "Cosmetologist": 2 },
  "29360": { "Cosmetologist": 1 },
  "33051": { "Cosmetologist": 4 },
  "33736": { "Cosmetologist": 2 },
  "33158": { "Cosmetologist": 23 },
  "60089": { "Cosmetologist": 4 },
  "33475": { "Cosmetologist": 6 },
  "55708": { "Cosmetologist": 1 },
  "33074": { "Cosmetologist": 3 },
  "75026": { "Cosmetologist": 1 },
  "33329": { "Cosmetologist": 5 },
  "23507": { "Cosmetologist": 1 },
  "54005": { "Cosmetologist": 1 },
  "37689": { "Cosmetologist": 1 },
  "10983": { "Cosmetologist": 1 },
  "34140": { "Cosmetologist": 2 },
  "60410": { "Cosmetologist": 1 },
  "54216": { "Cosmetologist": 1 },
  "32116": { "Cosmetologist": 4 },
  "48188": { "Cosmetologist": 229, "Barber": 10 },
  "32912": { "Cosmetologist": 4 },
  "85321": { "Cosmetologist": 1 },
  "48036": { "Cosmetologist": 169, "Barber": 8 },
  "33359": { "Cosmetologist": 3 },
  "70460": { "Cosmetologist": 2 },
  "39859": { "Cosmetologist": 3 },
  "88211": { "Cosmetologist": 1 },
  "32426": { "Cosmetologist": 3 },
  "33628": { "Cosmetologist": 2 },
  "35806": { "Cosmetologist": 3 },
  "32957": { "Cosmetologist": 5 },
  "34739": { "Cosmetologist": 7 },
  "30177": { "Cosmetologist": 2 },
  "34682": { "Cosmetologist": 6 },
  "93103": { "Cosmetologist": 1 },
  "94545": { "Cosmetologist": 3 },
  "21076": { "Cosmetologist": 4 },
  "32744": { "Cosmetologist": 39 },
  "38611": { "Cosmetologist": 2 },
  "34642": { "Cosmetologist": 3 },
  "89166": { "Cosmetologist": 4 },
  "53095": { "Cosmetologist": 2 },
  "33900": { "Cosmetologist": 1 },
  "32654": { "Cosmetologist": 2 },
  "33524": { "Cosmetologist": 6 },
  "14437": { "Cosmetologist": 1 },
  "60700": { "Cosmetologist": 1 },
  "86339": { "Cosmetologist": 1 },
  "34137": { "Cosmetologist": 1 },
  "33856": { "Cosmetologist": 3 },
  "34619": { "Cosmetologist": 1 },
  "34464": { "Cosmetologist": 5 },
  "32634": { "Cosmetologist": 7 },
  "32629": { "Cosmetologist": 1 },
  "49783": { "Cosmetologist": 85, "Barber": 3 },
  "25832": { "Cosmetologist": 1 },
  "32315": { "Cosmetologist": 10 },
  "34449": { "Cosmetologist": 13 },
  "48647": { "Cosmetologist": 17 },
  "32775": { "Cosmetologist": 2 },
  "34999": { "Cosmetologist": 2 },
  "33526": { "Cosmetologist": 11 },
  "35541": { "Cosmetologist": 1 },
  "33443": { "Cosmetologist": 9 },
  "32355": { "Cosmetologist": 2 },
  "32140": { "Cosmetologist": 12 },
  "92612": { "Cosmetologist": 4 },
  "34492": { "Cosmetologist": 8 },
  "33466": { "Cosmetologist": 7 },
  "32147": { "Cosmetologist": 5 },
  "33348": { "Cosmetologist": 1 },
  "33256": { "Cosmetologist": 5 },
  "10065": { "Cosmetologist": 7 },
  "33944": { "Cosmetologist": 6 },
  "40702": { "Cosmetologist": 1 },
  "99827": { "Cosmetologist": 1 },
  "33339": { "Cosmetologist": 5 },
  "33093": { "Cosmetologist": 8 },
  "42453": { "Cosmetologist": 1 },
  "32329": { "Cosmetologist": 1 },
  "37933": { "Cosmetologist": 1 },
  "71251": { "Cosmetologist": 1 },
  "33960": { "Cosmetologist": 14 },
  "33568": { "Cosmetologist": 12 },
  "34623": { "Cosmetologist": 1 },
  "12065": { "Cosmetologist": 5 },
  "33234": { "Cosmetologist": 5 },
  "33851": { "Cosmetologist": 8 },
  "33338": { "Cosmetologist": 4 },
  "14127": { "Cosmetologist": 2 },
  "33734": { "Cosmetologist": 6 },
  "62381": { "Cosmetologist": 1 },
  "13338": { "Cosmetologist": 1 },
  "39505": { "Cosmetologist": 1 },
  "34988": { "Cosmetologist": 10 },
  "26201": { "Cosmetologist": 3 },
  "33346": { "Cosmetologist": 5 },
  "34218": { "Cosmetologist": 2 },
  "36609": { "Cosmetologist": 6 },
  "81344": { "Cosmetologist": 1 },
  "36312": { "Cosmetologist": 2 },
  "32464": { "Cosmetologist": 18 },
  "33212": { "Cosmetologist": 1 },
  "31562": { "Cosmetologist": 5 },
  "34341": { "Cosmetologist": 1 },
  "21754": { "Cosmetologist": 2 },
  "11804": { "Cosmetologist": 1 },
  "33786": { "Cosmetologist": 10 },
  "32182": { "Cosmetologist": 3 },
  "35160": { "Cosmetologist": 4 },
  "32023": { "Cosmetologist": 1 },
  "35976": { "Cosmetologist": 1 },
  "33503": { "Cosmetologist": 6 },
  "36109": { "Cosmetologist": 2 },
  "34615": { "Cosmetologist": 5 },
  "60302": { "Cosmetologist": 1 },
  "34666": { "Cosmetologist": 1 },
  "33320": { "Cosmetologist": 8 },
  "23690": { "Cosmetologist": 1 },
  "32603": { "Cosmetologist": 12 },
  "22130": { "Cosmetologist": 1 },
  "35179": { "Cosmetologist": 1 },
  "32560": { "Cosmetologist": 2 },
  "34909": { "Cosmetologist": 1 },
  "23471": { "Cosmetologist": 1 },
  "34423": { "Cosmetologist": 5 },
  "34460": { "Cosmetologist": 3 },
  "32296": { "Cosmetologist": 1 },
  "92307": { "Cosmetologist": 1 },
  "37710": { "Cosmetologist": 1 },
  "62810": { "Cosmetologist": 1 },
  "39897": { "Cosmetologist": 4 },
  "33530": { "Cosmetologist": 4 },
  "21035": { "Cosmetologist": 1 },
  "99672": { "Cosmetologist": 1 },
  "62447": { "Cosmetologist": 1 },
  "40207": { "Cosmetologist": 7 },
  "97071": { "Cosmetologist": 2 },
  "98053": { "Cosmetologist": 3 },
  "32793": { "Cosmetologist": 8 },
  "30271": { "Cosmetologist": 1 },
  "33266": { "Cosmetologist": 3 },
  "32722": { "Cosmetologist": 2 },
  "30557": { "Cosmetologist": 1 },
  "13901": { "Cosmetologist": 3 },
  "14546": { "Cosmetologist": 1 },
  "34146": { "Cosmetologist": 8 },
  "33152": { "Cosmetologist": 8 },
  "22572": { "Cosmetologist": 1 },
  "59047": { "Cosmetologist": 2 },
  "30738": { "Cosmetologist": 1 },
  "10956": { "Cosmetologist": 7 },
  "33836": { "Cosmetologist": 10 },
  "38632": { "Cosmetologist": 5 },
  "43064": { "Cosmetologist": 2 },
  "30171": { "Cosmetologist": 1 },
  "33929": { "Cosmetologist": 7 },
  "60153": { "Cosmetologist": 2 },
  "34624": { "Cosmetologist": 1 },
  "92660": { "Cosmetologist": 4 },
  "33514": { "Cosmetologist": 23 },
  "32430": { "Cosmetologist": 8 },
  "58416": { "Cosmetologist": 1 },
  "92706": { "Cosmetologist": 4 },
  "48001": { "Cosmetologist": 118, "Barber": 2 },
  "36574": { "Cosmetologist": 6 },
  "32115": { "Cosmetologist": 6 },
  "48315": { "Cosmetologist": 342, "Barber": 21 },
  "34620": { "Cosmetologist": 1 },
  "93428": { "Cosmetologist": 1 },
  "10803": { "Cosmetologist": 1 },
  "22603": { "Cosmetologist": 2 },
  "74745": { "Cosmetologist": 3 },
  "48353": { "Cosmetologist": 58, "Barber": 3 },
  "33474": { "Cosmetologist": 8 },
  "33497": { "Cosmetologist": 5 },
  "36483": { "Cosmetologist": 1 },
  "97067": { "Cosmetologist": 1 },
  "32125": { "Cosmetologist": 5 },
  "42726": { "Cosmetologist": 2 },
  "36305": { "Cosmetologist": 14 },
  "20732": { "Cosmetologist": 3 },
  "34277": { "Cosmetologist": 14 },
  "32411": { "Cosmetologist": 9 },
  "93706": { "Cosmetologist": 2 },
  "35754": { "Cosmetologist": 1 },
  "37753": { "Cosmetologist": 1 },
  "49770": { "Cosmetologist": 120, "Barber": 2 },
  "11225": { "Cosmetologist": 3 },
  "32540": { "Cosmetologist": 6 },
  "32862": { "Cosmetologist": 2 },
  "29682": { "Cosmetologist": 1 },
  "70410": { "Cosmetologist": 1 },
  "03103": { "Cosmetologist": 1 },
  "34265": { "Cosmetologist": 8 },
  "12138": { "Cosmetologist": 1 },
  "92324": { "Cosmetologist": 3 },
  "40330": { "Cosmetologist": 3 },
  "92024": { "Cosmetologist": 1 },
  "40456": { "Cosmetologist": 1 },
  "10307": { "Cosmetologist": 2 },
  "32453": { "Cosmetologist": 1 },
  "98823": { "Cosmetologist": 1 },
  "40328": { "Cosmetologist": 1 },
  "32644": { "Cosmetologist": 6 },
  "61379": { "Cosmetologist": 1 },
  "33677": { "Cosmetologist": 8 },
  "34497": { "Cosmetologist": 1 },
  "53038": { "Cosmetologist": 1 },
  "32466": { "Cosmetologist": 33 },
  "35978": { "Cosmetologist": 1 },
  "36344": { "Cosmetologist": 3 },
  "89403": { "Cosmetologist": 1 },
  "95938": { "Cosmetologist": 1 },
  "42103": { "Cosmetologist": 2 },
  "97002": { "Cosmetologist": 1 },
  "33807": { "Cosmetologist": 4 },
  "40403": { "Cosmetologist": 2 },
  "31648": { "Cosmetologist": 1 },
  "32625": { "Cosmetologist": 13 },
  "37886": { "Cosmetologist": 1 },
  "32087": { "Cosmetologist": 33 },
  "32658": { "Cosmetologist": 2 },
  "85380": { "Cosmetologist": 1 },
  "22803": { "Cosmetologist": 1 },
  "36576": { "Cosmetologist": 3 },
  "18469": { "Cosmetologist": 1 },
  "32875": { "Cosmetologist": 1 },
  "31005": { "Cosmetologist": 4 },
  "11740": { "Cosmetologist": 1 },
  "30746": { "Cosmetologist": 1 },
  "31794": { "Cosmetologist": 4 },
  "32122": { "Cosmetologist": 1 },
  "14435": { "Cosmetologist": 1 },
  "40160": { "Cosmetologist": 2 },
  "36611": { "Cosmetologist": 2 },
  "78842": { "Cosmetologist": 2 },
  "13811": { "Cosmetologist": 1 },
  "34625": { "Cosmetologist": 1 },
  "22902": { "Cosmetologist": 1 },
  "41102": { "Cosmetologist": 1 },
  "12524": { "Cosmetologist": 2 },
  "54810": { "Cosmetologist": 1 },
  "33116": { "Cosmetologist": 11 },
  "54140": { "Cosmetologist": 1 },
  "35213": { "Cosmetologist": 1 },
  "98008": { "Cosmetologist": 1 },
  "11795": { "Cosmetologist": 3 },
  "92590": { "Cosmetologist": 1 },
  "72211": { "Cosmetologist": 4 },
  "36442": { "Cosmetologist": 3 },
  "60046": { "Cosmetologist": 2, "Barber": 1 },
  "49640": { "Cosmetologist": 12, "Barber": 1 },
  "38425": { "Cosmetologist": 1 },
  "42081": { "Cosmetologist": 3 },
  "14852": { "Cosmetologist": 2 },
  "87362": { "Cosmetologist": 1 },
  "93535": { "Cosmetologist": 1 },
  "11706": { "Cosmetologist": 3 },
  "39641": { "Cosmetologist": 1 },
  "33816": { "Cosmetologist": 1 },
  "76214": { "Cosmetologist": 1 },
  "12762": { "Cosmetologist": 1 },
  "48076": { "Cosmetologist": 181, "Barber": 17 },
  "38834": { "Cosmetologist": 3 },
  "86303": { "Cosmetologist": 3 },
  "14482": { "Cosmetologist": 1 },
  "43023": { "Cosmetologist": 1 },
  "35650": { "Cosmetologist": 1 },
  "97703": { "Cosmetologist": 1 },
  "42501": { "Cosmetologist": 1 },
  "31788": { "Cosmetologist": 2 },
  "11414": { "Cosmetologist": 3 },
  "36350": { "Cosmetologist": 3 },
  "10516": { "Cosmetologist": 1 },
  "30747": { "Cosmetologist": 1 },
  "33622": { "Cosmetologist": 6 },
  "35950": { "Cosmetologist": 1 },
  "34483": { "Cosmetologist": 4 },
  "48326": { "Cosmetologist": 142, "Barber": 12 },
  "32463": { "Cosmetologist": 1 },
  "35802": { "Cosmetologist": 3 },
  "94507": { "Cosmetologist": 1 },
  "53110": { "Cosmetologist": 1 },
  "12748": { "Cosmetologist": 1 },
  "33493": { "Cosmetologist": 13 },
  "89173": { "Cosmetologist": 2 },
  "62525": { "Cosmetologist": 1 },
  "48059": { "Cosmetologist": 124, "Barber": 4 },
  "30146": { "Cosmetologist": 1 },
  "33159": { "Cosmetologist": 2 },
  "37320": { "Cosmetologist": 1 },
  "49249": { "Cosmetologist": 30, "Barber": 1 },
  "32427": { "Cosmetologist": 9 },
  "49801": { "Cosmetologist": 75, "Barber": 3 },
  "33335": { "Cosmetologist": 4 },
  "39601": { "Cosmetologist": 4 },
  "32057": { "Cosmetologist": 2 },
  "35054": { "Cosmetologist": 2 },
  "59317": { "Cosmetologist": 1 },
  "33738": { "Cosmetologist": 2 },
  "34106": { "Cosmetologist": 22 },
  "80770": { "Cosmetologist": 1 },
  "34660": { "Cosmetologist": 18 },
  "33355": { "Cosmetologist": 1 },
  "32183": { "Cosmetologist": 5 },
  "33835": { "Cosmetologist": 6 },
  "89060": { "Cosmetologist": 1 },
  "22124": { "Cosmetologist": 1 },
  "49648": { "Cosmetologist": 17, "Barber": 1 },
  "60013": { "Cosmetologist": 2 },
  "42718": { "Cosmetologist": 3 },
  "31569": { "Cosmetologist": 9 },
  "21773": { "Cosmetologist": 1 },
  "32785": { "Cosmetologist": 1 },
  "23092": { "Cosmetologist": 1 },
  "34107": { "Cosmetologist": 1 },
  "34661": { "Cosmetologist": 4 },
  "40351": { "Cosmetologist": 1 },
  "34644": { "Cosmetologist": 2 },
  "10014": { "Cosmetologist": 1 },
  "37178": { "Cosmetologist": 1 },
  "70526": { "Cosmetologist": 1 },
  "61061": { "Cosmetologist": 1 },
  "12182": { "Cosmetologist": 1 },
  "39818": { "Cosmetologist": 1 },
  "14895": { "Cosmetologist": 1 },
  "96755": { "Cosmetologist": 1 },
  "37818": { "Cosmetologist": 1 },
  "92130": { "Cosmetologist": 2 },
  "48154": { "Cosmetologist": 287, "Barber": 15 },
  "33977": { "Cosmetologist": 1 },
  "92373": { "Cosmetologist": 1 },
  "92346": { "Cosmetologist": 1 },
  "49201": { "Cosmetologist": 286, "Barber": 9 },
  "96094": { "Cosmetologist": 1 },
  "34680": { "Cosmetologist": 2 },
  "39827": { "Cosmetologist": 3 },
  "25427": { "Cosmetologist": 3 },
  "60466": { "Cosmetologist": 1 },
  "33877": { "Cosmetologist": 5 },
  "42743": { "Cosmetologist": 2 },
  "74029": { "Cosmetologist": 1 },
  "54220": { "Cosmetologist": 2 },
  "06450": { "Cosmetologist": 1 },
  "31028": { "Cosmetologist": 8 },
  "32111": { "Cosmetologist": 1 },
  "23917": { "Cosmetologist": 2 },
  "40241": { "Cosmetologist": 3 },
  "33963": { "Cosmetologist": 2 },
  "59634": { "Cosmetologist": 1 },
  "34998": { "Cosmetologist": 1 },
  "61523": { "Cosmetologist": 1 },
  "18260": { "Cosmetologist": 1 },
  "11590": { "Cosmetologist": 3 },
  "95831": { "Cosmetologist": 2 },
  "40741": { "Cosmetologist": 2 },
  "34328": { "Cosmetologist": 1 },
  "32936": { "Cosmetologist": 6 },
  "33608": { "Cosmetologist": 2 },
  "32964": { "Cosmetologist": 3 },
  "13440": { "Cosmetologist": 2 },
  "26730": { "Cosmetologist": 1 },
  "56537": { "Cosmetologist": 1 },
  "19971": { "Cosmetologist": 2 },
  "10016": { "Cosmetologist": 5 },
  "32350": { "Cosmetologist": 6 },
  "32813": { "Cosmetologist": 1 },
  "49686": { "Cosmetologist": 193, "Barber": 2 },
  "12350": { "Cosmetologist": 1 },
  "32067": { "Cosmetologist": 7 },
  "35115": { "Cosmetologist": 1 },
  "44321": { "Cosmetologist": 1 },
  "33806": { "Cosmetologist": 3 },
  "82212": { "Cosmetologist": 1 },
  "45167": { "Cosmetologist": 1 },
  "32969": { "Cosmetologist": 5 },
  "42437": { "Cosmetologist": 1 },
  "44601": { "Cosmetologist": 4 },
  "49720": { "Cosmetologist": 68, "Barber": 1 },
  "10954": { "Cosmetologist": 3 },
  "11787": { "Cosmetologist": 2 },
  "11763": { "Cosmetologist": 3 },
  "48617": { "Cosmetologist": 49 },
  "60545": { "Cosmetologist": 2 },
  "11757": { "Cosmetologist": 6 },
  "30750": { "Cosmetologist": 1 },
  "60820": { "Cosmetologist": 1 },
  "44116": { "Cosmetologist": 2 },
  "33041": { "Cosmetologist": 7 },
  "34882": { "Cosmetologist": 2 },
  "32791": { "Cosmetologist": 7 },
  "69050": { "Cosmetologist": 2 },
  "36582": { "Cosmetologist": 3 },
  "11953": { "Cosmetologist": 1 },
  "53168": { "Cosmetologist": 2 },
  "35906": { "Cosmetologist": 1 },
  "10536": { "Cosmetologist": 1 },
  "85132": { "Cosmetologist": 8 },
  "33119": { "Cosmetologist": 3 },
  "59401": { "Cosmetologist": 3 },
  "13903": { "Cosmetologist": 2 },
  "97411": { "Cosmetologist": 1 },
  "49696": { "Cosmetologist": 77, "Barber": 1 },
  "70584": { "Cosmetologist": 1 },
  "98032": { "Cosmetologist": 1 },
  "43526": { "Cosmetologist": 3 },
  "32006": { "Cosmetologist": 6 },
  "28172": { "Cosmetologist": 1 },
  "33039": { "Cosmetologist": 2 },
  "31606": { "Cosmetologist": 3 },
  "48331": { "Cosmetologist": 106, "Barber": 5 },
  "40243": { "Cosmetologist": 2 },
  "60164": { "Cosmetologist": 1 },
  "33962": { "Cosmetologist": 1 },
  "32139": { "Cosmetologist": 2 },
  "33921": { "Cosmetologist": 3 },
  "14202": { "Cosmetologist": 1 },
  "97502": { "Cosmetologist": 2 },
  "60083": { "Cosmetologist": 1 },
  "34616": { "Cosmetologist": 1 },
  "91301": { "Cosmetologist": 1 },
  "25565": { "Cosmetologist": 1 },
  "32865": { "Cosmetologist": 2 },
  "36005": { "Cosmetologist": 1 },
  "55318": { "Cosmetologist": 10 },
  "55042": { "Cosmetologist": 6 },
  "33153": { "Cosmetologist": 3 },
  "33649": { "Cosmetologist": 1 },
  "35966": { "Cosmetologist": 1 },
  "16619": { "Cosmetologist": 1 },
  "34716": { "Cosmetologist": 2 },
  "03053": { "Cosmetologist": 1 },
  "98065": { "Cosmetologist": 1 },
  "14208": { "Cosmetologist": 1 },
  "29661": { "Cosmetologist": 3 },
  "34593": { "Cosmetologist": 1 },
  "14626": { "Cosmetologist": 4 },
  "14843": { "Cosmetologist": 1 },
  "34713": { "Cosmetologist": 2 },
  "11719": { "Cosmetologist": 1 },
  "13323": { "Cosmetologist": 1 },
  "11931": { "Cosmetologist": 1 },
  "11692": { "Cosmetologist": 1 },
  "13152": { "Cosmetologist": 1 },
  "10962": { "Cosmetologist": 1 },
  "11901": { "Cosmetologist": 7 },
  "33201": { "Cosmetologist": 1 },
  "13354": { "Cosmetologist": 2 },
  "10504": { "Cosmetologist": 4 },
  "11362": { "Cosmetologist": 1 },
  "10471": { "Cosmetologist": 2 },
  "32093": { "Cosmetologist": 1 },
  "96375": { "Cosmetologist": 2 },
  "96817": { "Cosmetologist": 2 },
  "14092": { "Cosmetologist": 1 },
  "11230": { "Cosmetologist": 7 },
  "11777": { "Cosmetologist": 1 },
  "13060": { "Cosmetologist": 1 },
  "14222": { "Cosmetologist": 2 },
  "34204": { "Cosmetologist": 3 },
  "87570": { "Cosmetologist": 1 },
  "11729": { "Cosmetologist": 3 },
  "11747": { "Cosmetologist": 2 },
  "11715": { "Cosmetologist": 2 },
  "26726": { "Cosmetologist": 1 },
  "12845": { "Cosmetologist": 2 },
  "33731": { "Cosmetologist": 1 },
  "11561": { "Cosmetologist": 4 },
  "72561": { "Cosmetologist": 1 },
  "14550": { "Cosmetologist": 1 },
  "10451": { "Cosmetologist": 1 },
  "31004": { "Cosmetologist": 1 },
  "11558": { "Cosmetologist": 1 },
  "96825": { "Cosmetologist": 1 },
  "99708": { "Cosmetologist": 1 },
  "32749": { "Cosmetologist": 1 },
  "92079": { "Cosmetologist": 1 },
  "68862": { "Cosmetologist": 1 },
  "33694": { "Cosmetologist": 6 },
  "20012": { "Cosmetologist": 1 },
  "34622": { "Cosmetologist": 1 },
  "32755": { "Cosmetologist": 1 },
  "98366": { "Cosmetologist": 1 },
  "77270": { "Cosmetologist": 1 },
  "32461": { "Cosmetologist": 34 },
  "49862": { "Cosmetologist": 19 },
  "34274": { "Cosmetologist": 6 },
  "22645": { "Cosmetologist": 1 },
  "36251": { "Cosmetologist": 2 },
  "33052": { "Cosmetologist": 1 },
  "35063": { "Cosmetologist": 1 },
  "18415": { "Cosmetologist": 2 },
  "30536": { "Cosmetologist": 5 },
  "45377": { "Cosmetologist": 2 },
  "97221": { "Cosmetologist": 3 },
  "23059": { "Cosmetologist": 9 },
  "31805": { "Cosmetologist": 1 },
  "13748": { "Cosmetologist": 1 },
  "31289": { "Cosmetologist": 1 },
  "20026": { "Cosmetologist": 1 },
  "32414": { "Cosmetologist": 2 },
  "34139": { "Cosmetologist": 8 },
  "32795": { "Cosmetologist": 8 },
  "32012": { "Cosmetologist": 1 },
  "37126": { "Cosmetologist": 1 },
  "30290": { "Cosmetologist": 4 },
  "33700": { "Cosmetologist": 2 },
  "19634": { "Cosmetologist": 1 },
  "36078": { "Cosmetologist": 2 },
  "20650": { "Cosmetologist": 1 },
  "30673": { "Cosmetologist": 2 },
  "23061": { "Cosmetologist": 4 },
  "32794": { "Cosmetologist": 4 },
  "34781": { "Cosmetologist": 1 },
  "30095": { "Cosmetologist": 2 },
  "32549": { "Cosmetologist": 7 },
  "35126": { "Cosmetologist": 2 },
  "32007": { "Cosmetologist": 1 },
  "55449": { "Cosmetologist": 5 },
  "61550": { "Cosmetologist": 1 },
  "73443": { "Cosmetologist": 1 },
  "82401": { "Cosmetologist": 1 },
  "32924": { "Cosmetologist": 3 },
  "13143": { "Cosmetologist": 1 },
  "30124": { "Cosmetologist": 1 },
  "33964": { "Cosmetologist": 1 },
  "31017": { "Cosmetologist": 1 },
  "36266": { "Cosmetologist": 1 },
  "24271": { "Cosmetologist": 2 },
  "33280": { "Cosmetologist": 3 },
  "35404": { "Cosmetologist": 1 },
  "54521": { "Cosmetologist": 1 },
  "42252": { "Cosmetologist": 1 },
  "33623": { "Cosmetologist": 2 },
  "36067": { "Cosmetologist": 1 },
  "32663": { "Cosmetologist": 2 },
  "45373": { "Cosmetologist": 5 },
  "44004": { "Cosmetologist": 3 },
  "45696": { "Cosmetologist": 1 },
  "45243": { "Cosmetologist": 1 },
  "43402": { "Cosmetologist": 3 },
  "33863": { "Cosmetologist": 1 },
  "14516": { "Cosmetologist": 1 },
  "30175": { "Cosmetologist": 2 },
  "31520": { "Cosmetologist": 9 },
  "43014": { "Cosmetologist": 1 },
  "96771": { "Cosmetologist": 3 },
  "32173": { "Cosmetologist": 6 },
  "98055": { "Cosmetologist": 2 },
  "12804": { "Cosmetologist": 3 },
  "36426": { "Cosmetologist": 15 },
  "33689": { "Cosmetologist": 7 },
  "48323": { "Cosmetologist": 109, "Barber": 3 },
  "60142": { "Cosmetologist": 4 },
  "34267": { "Cosmetologist": 4 },
  "61013": { "Cosmetologist": 1 },
  "62896": { "Cosmetologist": 1 },
  "49082": { "Cosmetologist": 25, "Barber": 2 },
  "45843": { "Cosmetologist": 1 },
  "00824": { "Cosmetologist": 1 },
  "61084": { "Cosmetologist": 1 },
  "33656": { "Cosmetologist": 1 },
  "48820": { "Cosmetologist": 160, "Barber": 16 },
  "48237": { "Cosmetologist": 205, "Barber": 14 },
  "13850": { "Cosmetologist": 1 },
  "34270": { "Cosmetologist": 4 },
  "60465": { "Cosmetologist": 1 },
  "48079": { "Cosmetologist": 149, "Barber": 2 },
  "60192": { "Cosmetologist": 1 },
  "48187": { "Cosmetologist": 283, "Barber": 13 },
  "44255": { "Cosmetologist": 2 },
  "36375": { "Cosmetologist": 1 },
  "48307": { "Cosmetologist": 277, "Barber": 10 },
  "48328": { "Cosmetologist": 202, "Barber": 15 },
  "43452": { "Cosmetologist": 3 },
  "36089": { "Cosmetologist": 1 },
  "48039": { "Cosmetologist": 54 },
  "49675": { "Cosmetologist": 8 },
  "49417": { "Cosmetologist": 258, "Barber": 5 },
  "42167": { "Cosmetologist": 1 },
  "48661": { "Cosmetologist": 95, "Barber": 4 },
  "48467": { "Cosmetologist": 16 },
  "35645": { "Cosmetologist": 1 },
  "23805": { "Cosmetologist": 4 },
  "91381": { "Cosmetologist": 1 },
  "48849": { "Cosmetologist": 41 },
  "48075": { "Cosmetologist": 123, "Barber": 14 },
  "39335": { "Cosmetologist": 1 },
  "48047": { "Cosmetologist": 437, "Barber": 17 },
  "92804": { "Cosmetologist": 1 },
  "36264": { "Cosmetologist": 1 },
  "48503": { "Cosmetologist": 97, "Barber": 19 },
  "49685": { "Cosmetologist": 151, "Barber": 5 },
  "40068": { "Cosmetologist": 1 },
  "33747": { "Cosmetologist": 6 },
  "36022": { "Cosmetologist": 1 },
  "59859": { "Cosmetologist": 3 },
  "40204": { "Cosmetologist": 3 },
  "48507": { "Cosmetologist": 203, "Barber": 26 },
  "35244": { "Cosmetologist": 7 },
  "70662": { "Cosmetologist": 1 },
  "70763": { "Cosmetologist": 1 },
  "26525": { "Cosmetologist": 1 },
  "94587": { "Cosmetologist": 1 },
  "37708": { "Cosmetologist": 2 },
  "32854": { "Cosmetologist": 3 },
  "29689": { "Cosmetologist": 2 },
  "74137": { "Cosmetologist": 4 },
  "70158": { "Cosmetologist": 1 },
  "48602": { "Cosmetologist": 115, "Barber": 11 },
  "69337": { "Cosmetologist": 1 },
  "38637": { "Cosmetologist": 3 },
  "74129": { "Cosmetologist": 3 },
  "74066": { "Cosmetologist": 1 },
  "37882": { "Cosmetologist": 2 },
  "33101": { "Cosmetologist": 9 },
  "54830": { "Cosmetologist": 1 },
  "95482": { "Cosmetologist": 2 },
  "79913": { "Cosmetologist": 1 },
  "32415": { "Cosmetologist": 2 },
  "14031": { "Cosmetologist": 2 },
  "32325": { "Cosmetologist": 6 },
  "92881": { "Cosmetologist": 1 },
  "32457": { "Cosmetologist": 6 },
  "48226": { "Cosmetologist": 15, "Barber": 2 },
  "33424": { "Cosmetologist": 6 },
  "12827": { "Cosmetologist": 1 },
  "36054": { "Cosmetologist": 1 },
  "95864": { "Cosmetologist": 2 },
  "97123": { "Cosmetologist": 3 },
  "61109": { "Cosmetologist": 5 },
  "24090": { "Cosmetologist": 1 },
  "88418": { "Cosmetologist": 1 },
  "94621": { "Cosmetologist": 1 },
  "33673": { "Cosmetologist": 2 },
  "32898": { "Cosmetologist": 1 },
  "30042": { "Cosmetologist": 1 },
  "98007": { "Cosmetologist": 5 },
  "33546": { "Cosmetologist": 1 },
  "30629": { "Cosmetologist": 1 },
  "89410": { "Cosmetologist": 1 },
  "43545": { "Cosmetologist": 1 },
  "91203": { "Cosmetologist": 2 },
  "31079": { "Cosmetologist": 1 },
  "71430": { "Cosmetologist": 1 },
  "33833": { "Cosmetologist": 3 },
  "95030": { "Cosmetologist": 1 },
  "70577": { "Cosmetologist": 1 },
  "32788": { "Cosmetologist": 1 },
  "11209": { "Cosmetologist": 5 },
  "53184": { "Cosmetologist": 1 },
  "90277": { "Cosmetologist": 2, "Barber": 1 },
  "33002": { "Cosmetologist": 13 },
  "92552": { "Cosmetologist": 1 },
  "33246": { "Cosmetologist": 1 },
  "33784": { "Cosmetologist": 3 },
  "70812": { "Cosmetologist": 2 },
  "60622": { "Cosmetologist": 8 },
  "60515": { "Cosmetologist": 4 },
  "60906": { "Cosmetologist": 1 },
  "32592": { "Cosmetologist": 1 },
  "68433": { "Cosmetologist": 1 },
  "48108": { "Cosmetologist": 114, "Barber": 9 },
  "33188": { "Cosmetologist": 1 },
  "33636": { "Cosmetologist": 1 },
  "29006": { "Cosmetologist": 2 },
  "92394": { "Cosmetologist": 1 },
  "48195": { "Cosmetologist": 233, "Barber": 14 },
  "29824": { "Cosmetologist": 1 },
  "34064": { "Cosmetologist": 1 },
  "70611": { "Cosmetologist": 1 },
  "32706": { "Cosmetologist": 2 },
  "30148": { "Cosmetologist": 1 },
  "60004": { "Cosmetologist": 3 },
  "32747": { "Cosmetologist": 8 },
  "97128": { "Cosmetologist": 1 },
  "91943": { "Cosmetologist": 1 },
  "30445": { "Cosmetologist": 2 },
  "37098": { "Cosmetologist": 2 },
  "72648": { "Cosmetologist": 1 },
  "85219": { "Cosmetologist": 1 },
  "21144": { "Cosmetologist": 4 },
  "53066": { "Cosmetologist": 4 },
  "32133": { "Cosmetologist": 3 },
  "31046": { "Cosmetologist": 1 },
  "32214": { "Cosmetologist": 3 },
  "70032": { "Cosmetologist": 1 },
  "98373": { "Cosmetologist": 5 },
  "97361": { "Cosmetologist": 1 },
  "53044": { "Cosmetologist": 1 },
  "05482": { "Cosmetologist": 1 },
  "53091": { "Cosmetologist": 2 },
  "10984": { "Cosmetologist": 3 },
  "39339": { "Cosmetologist": 3 },
  "97478": { "Cosmetologist": 2 },
  "55387": { "Cosmetologist": 1 },
  "48021": { "Cosmetologist": 186, "Barber": 16 },
  "21557": { "Cosmetologist": 1 },
  "70112": { "Cosmetologist": 1 },
  "83404": { "Cosmetologist": 1 },
  "29928": { "Cosmetologist": 2 },
  "22041": { "Cosmetologist": 1 },
  "96782": { "Cosmetologist": 2 },
  "48819": { "Cosmetologist": 25, "Barber": 1 },
  "87106": { "Cosmetologist": 1 },
  "60614": { "Cosmetologist": 4 },
  "39512": { "Cosmetologist": 1 },
  "34490": { "Cosmetologist": 2 },
  "71334": { "Cosmetologist": 1 },
  "33697": { "Cosmetologist": 2 },
  "48748": { "Cosmetologist": 12 },
  "60156": { "Cosmetologist": 1 },
  "57732": { "Cosmetologist": 1 },
  "12201": { "Cosmetologist": 1 },
  "33438": { "Cosmetologist": 7 },
  "21028": { "Cosmetologist": 1 },
  "42053": { "Cosmetologist": 1 },
  "40229": { "Cosmetologist": 3 },
  "49735": { "Cosmetologist": 135, "Barber": 3 },
  "46050": { "Cosmetologist": 1 },
  "39202": { "Cosmetologist": 1 },
  "72004": { "Cosmetologist": 1 },
  "12849": { "Cosmetologist": 1 },
  "49461": { "Cosmetologist": 71, "Barber": 1 },
  "32437": { "Cosmetologist": 3 },
  "71037": { "Cosmetologist": 1 },
  "54915": { "Cosmetologist": 5 },
  "44425": { "Cosmetologist": 1 },
  "31622": { "Cosmetologist": 1 },
  "71232": { "Cosmetologist": 1 },
  "33740": { "Cosmetologist": 1 },
  "93465": { "Cosmetologist": 1 },
  "12184": { "Cosmetologist": 1 },
  "48017": { "Cosmetologist": 107, "Barber": 4 },
  "10987": { "Cosmetologist": 6 },
  "47369": { "Cosmetologist": 1 },
  "40737": { "Cosmetologist": 1 },
  "98848": { "Cosmetologist": 2 },
  "42141": { "Cosmetologist": 1 },
  "36104": { "Cosmetologist": 4 },
  "30527": { "Cosmetologist": 1 },
  "92806": { "Cosmetologist": 4 },
  "88030": { "Cosmetologist": 2 },
  "95020": { "Cosmetologist": 1 },
  "14304": { "Cosmetologist": 2 },
  "33958": { "Cosmetologist": 2 },
  "55434": { "Cosmetologist": 4 },
  "20168": { "Cosmetologist": 1 },
  "31551": { "Cosmetologist": 1 },
  "20155": { "Cosmetologist": 3, "Barber": 1 },
  "73132": { "Cosmetologist": 2 },
  "32083": { "Cosmetologist": 9 },
  "60139": { "Cosmetologist": 6 },
  "44621": { "Cosmetologist": 1 },
  "96743": { "Cosmetologist": 2 },
  "61012": { "Cosmetologist": 1 },
  "10801": { "Cosmetologist": 2 },
  "45066": { "Cosmetologist": 2 },
  "73434": { "Cosmetologist": 1 },
  "55806": { "Cosmetologist": 1 },
  "92825": { "Cosmetologist": 1 },
  "34247": { "Cosmetologist": 1 },
  "95841": { "Cosmetologist": 1 },
  "98230": { "Cosmetologist": 1 },
  "40503": { "Cosmetologist": 3 },
  "14221": { "Cosmetologist": 3 },
  "45331": { "Cosmetologist": 4 },
  "44236": { "Cosmetologist": 12 },
  "35773": { "Cosmetologist": 2 },
  "11224": { "Cosmetologist": 3 },
  "94572": { "Cosmetologist": 2 },
  "97202": { "Cosmetologist": 2 },
  "44358": { "Cosmetologist": 1 },
  "92178": { "Cosmetologist": 1 },
  "32956": { "Cosmetologist": 4 },
  "14228": { "Cosmetologist": 2 },
  "92544": { "Cosmetologist": 1 },
  "35620": { "Cosmetologist": 1 },
  "35135": { "Cosmetologist": 1 },
  "20619": { "Cosmetologist": 4 },
  "61704": { "Cosmetologist": 5 },
  "33654": { "Cosmetologist": 1 },
  "33222": { "Cosmetologist": 5 },
  "89508": { "Cosmetologist": 1 },
  "55129": { "Cosmetologist": 5 },
  "70344": { "Cosmetologist": 1 },
  "10548": { "Cosmetologist": 1 },
  "60133": { "Cosmetologist": 2 },
  "96813": { "Cosmetologist": 2 },
  "60440": { "Cosmetologist": 93 },
  "11413": { "Cosmetologist": 1 },
  "20175": { "Cosmetologist": 2 },
  "98029": { "Cosmetologist": 3 },
  "11001": { "Cosmetologist": 1 },
  "10570": { "Cosmetologist": 1 },
  "20132": { "Cosmetologist": 1 },
  "90806": { "Cosmetologist": 2 },
  "39213": { "Cosmetologist": 1 },
  "36343": { "Cosmetologist": 2 },
  "61311": { "Cosmetologist": 1 },
  "60138": { "Cosmetologist": 1 },
  "90631": { "Cosmetologist": 1 },
  "29626": { "Cosmetologist": 3 },
  "49449": { "Cosmetologist": 18, "Barber": 1 },
  "55426": { "Cosmetologist": 1 },
  "35215": { "Cosmetologist": 2 },
  "35043": { "Cosmetologist": 4 },
  "61878": { "Cosmetologist": 1 },
  "33858": { "Cosmetologist": 4 },
  "11356": { "Cosmetologist": 3 },
  "11756": { "Cosmetologist": 6 },
  "45152": { "Cosmetologist": 4 },
  "47202": { "Cosmetologist": 1 },
  "20688": { "Cosmetologist": 1 },
  "48864": { "Cosmetologist": 70, "Barber": 4 },
  "14543": { "Cosmetologist": 1 },
  "34893": { "Cosmetologist": 3 },
  "33551": { "Cosmetologist": 1 },
  "12534": { "Cosmetologist": 1 },
  "25303": { "Cosmetologist": 2 },
  "91355": { "Cosmetologist": 2 },
  "11946": { "Cosmetologist": 3 },
  "60022": { "Cosmetologist": 1 },
  "61362": { "Cosmetologist": 1 },
  "12997": { "Cosmetologist": 1 },
  "40144": { "Cosmetologist": 1 },
  "42220": { "Cosmetologist": 1 },
  "97233": { "Cosmetologist": 2 },
  "24918": { "Cosmetologist": 1 },
  "21040": { "Cosmetologist": 4 },
  "35010": { "Cosmetologist": 3 },
  "11963": { "Cosmetologist": 1 },
  "43137": { "Cosmetologist": 1 },
  "94040": { "Cosmetologist": 1 },
  "94607": { "Cosmetologist": 1 },
  "33097": { "Cosmetologist": 3 },
  "13324": { "Cosmetologist": 1 },
  "91604": { "Cosmetologist": 2 },
  "49512": { "Cosmetologist": 114, "Barber": 2 },
  "87801": { "Cosmetologist": 1 },
  "77410": { "Cosmetologist": 1 },
  "98625": { "Cosmetologist": 1 },
  "44023": { "Cosmetologist": 3 },
  "12566": { "Cosmetologist": 1 },
  "48306": { "Cosmetologist": 134, "Barber": 3 },
  "60111": { "Cosmetologist": 1 },
  "95133": { "Cosmetologist": 1 },
  "11210": { "Cosmetologist": 3 },
  "32648": { "Cosmetologist": 4 },
  "22644": { "Cosmetologist": 1 },
  "39057": { "Cosmetologist": 1 },
  "95825": { "Cosmetologist": 1 },
  "49023": { "Cosmetologist": 4 },
  "48438": { "Cosmetologist": 64, "Barber": 6 },
  "26810": { "Cosmetologist": 1 },
  "96326": { "Cosmetologist": 3 },
  "30185": { "Cosmetologist": 2 },
  "22980": { "Cosmetologist": 2 },
  "53158": { "Cosmetologist": 2 },
  "60459": { "Cosmetologist": 3 },
  "55359": { "Cosmetologist": 1 },
  "71964": { "Cosmetologist": 1 },
  "12953": { "Cosmetologist": 2 },
  "57101": { "Cosmetologist": 1 },
  "11204": { "Cosmetologist": 2 },
  "35058": { "Cosmetologist": 2 },
  "32079": { "Cosmetologist": 1 },
  "33224": { "Cosmetologist": 1 },
  "36580": { "Cosmetologist": 3 },
  "35769": { "Cosmetologist": 3 },
  "33391": { "Cosmetologist": 1 },
  "98684": { "Cosmetologist": 3 },
  "29040": { "Cosmetologist": 2 },
  "98208": { "Cosmetologist": 2 },
  "32362": { "Cosmetologist": 3 },
  "60914": { "Cosmetologist": 2 },
  "20778": { "Cosmetologist": 1 },
  "20191": { "Cosmetologist": 3 },
  "34278": { "Cosmetologist": 1 },
  "61705": { "Cosmetologist": 2 },
  "32830": { "Cosmetologist": 7 },
  "29401": { "Cosmetologist": 2 },
  "26601": { "Cosmetologist": 1 },
  "30420": { "Cosmetologist": 2 },
  "22201": { "Cosmetologist": 4 },
  "60102": { "Cosmetologist": 3 },
  "32970": { "Cosmetologist": 1 },
  "13787": { "Cosmetologist": 1 },
  "32683": { "Cosmetologist": 1 },
  "34467": { "Cosmetologist": 1 },
  "54961": { "Cosmetologist": 2 },
  "68366": { "Cosmetologist": 1 },
  "48178": { "Cosmetologist": 262, "Barber": 7 },
  "40104": { "Cosmetologist": 1 },
  "11421": { "Cosmetologist": 3 },
  "94581": { "Cosmetologist": 1 },
  "35351": { "Cosmetologist": 1 },
  "34763": { "Cosmetologist": 2 },
  "14589": { "Cosmetologist": 1 },
  "21048": { "Cosmetologist": 1 },
  "32432": { "Cosmetologist": 2 },
  "93637": { "Cosmetologist": 2 },
  "74804": { "Cosmetologist": 1 },
  "92659": { "Cosmetologist": 1 },
  "94521": { "Cosmetologist": 2 },
  "84116": { "Cosmetologist": 4 },
  "97838": { "Cosmetologist": 1 },
  "92251": { "Cosmetologist": 3 },
  "35210": { "Cosmetologist": 3 },
  "49073": { "Cosmetologist": 33 },
  "49103": { "Cosmetologist": 54, "Barber": 1 },
  "14891": { "Cosmetologist": 1 },
  "42079": { "Cosmetologist": 1 },
  "93950": { "Cosmetologist": 1 },
  "14224": { "Cosmetologist": 4 },
  "14468": { "Cosmetologist": 1 },
  "32761": { "Cosmetologist": 3 },
  "99004": { "Cosmetologist": 1 },
  "60160": { "Cosmetologist": 3 },
  "48451": { "Cosmetologist": 179, "Barber": 11 },
  "95758": { "Cosmetologist": 2 },
  "92173": { "Cosmetologist": 3 },
  "25411": { "Cosmetologist": 2 },
  "55331": { "Cosmetologist": 2 },
  "32150": { "Cosmetologist": 1 },
  "98446": { "Cosmetologist": 1 },
  "43119": { "Cosmetologist": 2 },
  "90212": { "Cosmetologist": 2 },
  "22207": { "Cosmetologist": 2 },
  "35576": { "Cosmetologist": 1 },
  "20164": { "Cosmetologist": 2 },
  "44089": { "Cosmetologist": 3 },
  "45369": { "Cosmetologist": 2 },
  "54113": { "Cosmetologist": 1 },
  "35613": { "Cosmetologist": 3 },
  "43638": { "Cosmetologist": 1 },
  "95118": { "Cosmetologist": 2 },
  "22308": { "Cosmetologist": 3 },
  "32675": { "Cosmetologist": 1 },
  "19701": { "Cosmetologist": 4 },
  "99921": { "Cosmetologist": 1 },
  "39577": { "Cosmetologist": 1 },
  "33667": { "Cosmetologist": 3 },
  "72335": { "Cosmetologist": 4 },
  "32532": { "Cosmetologist": 1 },
  "48310": { "Cosmetologist": 323, "Barber": 53 },
  "32215": { "Cosmetologist": 2 },
  "11377": { "Cosmetologist": 3 },
  "22101": { "Cosmetologist": 1 },
  "98077": { "Cosmetologist": 1 },
  "33489": { "Cosmetologist": 1 },
  "93704": { "Cosmetologist": 1 },
  "48439": { "Cosmetologist": 424, "Barber": 31 },
  "43515": { "Cosmetologist": 5 },
  "11751": { "Cosmetologist": 2 },
  "90502": { "Cosmetologist": 1 },
  "34260": { "Cosmetologist": 1 },
  "44022": { "Cosmetologist": 3 },
  "31773": { "Cosmetologist": 2 },
  "40224": { "Cosmetologist": 1 },
  "14564": { "Cosmetologist": 2 },
  "42330": { "Cosmetologist": 1 },
  "39563": { "Cosmetologist": 2 },
  "60630": { "Cosmetologist": 4 },
  "35473": { "Cosmetologist": 4 },
  "41472": { "Cosmetologist": 1 },
  "98036": { "Cosmetologist": 2 },
  "34696": { "Cosmetologist": 2 },
  "98034": { "Cosmetologist": 5 },
  "73098": { "Cosmetologist": 1 },
  "99731": { "Cosmetologist": 1 },
  "93044": { "Cosmetologist": 1 },
  "90209": { "Cosmetologist": 1 },
  "70058": { "Cosmetologist": 3 },
  "26452": { "Cosmetologist": 2 },
  "42347": { "Cosmetologist": 1 },
  "11577": { "Cosmetologist": 3 },
  "32996": { "Cosmetologist": 1 },
  "95521": { "Cosmetologist": 1 },
  "28710": { "Cosmetologist": 1 },
  "42553": { "Cosmetologist": 1 },
  "33831": { "Cosmetologist": 2 },
  "70043": { "Cosmetologist": 2 },
  "39842": { "Cosmetologist": 1 },
  "60435": { "Cosmetologist": 3 },
  "11741": { "Cosmetologist": 3 },
  "12020": { "Cosmetologist": 6 },
  "30559": { "Cosmetologist": 1 },
  "45430": { "Cosmetologist": 1 },
  "34424": { "Cosmetologist": 2 },
  "96712": { "Cosmetologist": 1 },
  "48051": { "Cosmetologist": 179, "Barber": 7 },
  "55317": { "Cosmetologist": 5 },
  "43537": { "Cosmetologist": 13 },
  "58958": { "Cosmetologist": 1 },
  "48189": { "Cosmetologist": 101, "Barber": 3 },
  "60181": { "Cosmetologist": 5 },
  "39272": { "Cosmetologist": 2 },
  "33533": { "Cosmetologist": 1 },
  "68154": { "Cosmetologist": 1 },
  "11731": { "Cosmetologist": 2 },
  "19103": { "Cosmetologist": 3 },
  "38568": { "Cosmetologist": 2 },
  "20169": { "Cosmetologist": 3 },
  "40228": { "Cosmetologist": 3 },
  "48441": { "Cosmetologist": 23, "Barber": 1 },
  "34808": { "Cosmetologist": 1 },
  "14814": { "Cosmetologist": 1 },
  "33561": { "Cosmetologist": 1 },
  "33102": { "Cosmetologist": 2 },
  "19807": { "Cosmetologist": 2 },
  "48065": { "Cosmetologist": 134, "Barber": 2 },
  "70119": { "Cosmetologist": 4 },
  "11776": { "Cosmetologist": 5 },
  "96620": { "Cosmetologist": 1 },
  "94609": { "Cosmetologist": 1 },
  "36432": { "Cosmetologist": 2 },
  "92501": { "Cosmetologist": 2 },
  "60202": { "Cosmetologist": 1 },
  "33992": { "Cosmetologist": 2 },
  "20105": { "Cosmetologist": 4 },
  "10607": { "Cosmetologist": 1 },
  "35640": { "Cosmetologist": 1 },
  "36265": { "Cosmetologist": 1 },
  "92003": { "Cosmetologist": 1 },
  "35954": { "Cosmetologist": 1 },
  "12186": { "Cosmetologist": 2 },
  "43025": { "Cosmetologist": 1 },
  "98258": { "Cosmetologist": 3 },
  "20008": { "Cosmetologist": 1 },
  "34091": { "Cosmetologist": 1 },
  "73061": { "Cosmetologist": 1 },
  "71454": { "Cosmetologist": 1 },
  "94509": { "Cosmetologist": 2 },
  "60805": { "Cosmetologist": 1 },
  "95206": { "Cosmetologist": 1 },
  "73642": { "Cosmetologist": 1 },
  "25064": { "Cosmetologist": 5 },
  "24473": { "Cosmetologist": 1 },
  "92004": { "Cosmetologist": 1 },
  "92399": { "Cosmetologist": 1 },
  "95525": { "Cosmetologist": 2 },
  "32042": { "Cosmetologist": 2 },
  "14220": { "Cosmetologist": 2 },
  "20170": { "Cosmetologist": 2 },
  "36257": { "Cosmetologist": 1 },
  "60608": { "Cosmetologist": 5 },
  "98116": { "Cosmetologist": 2 },
  "49220": { "Cosmetologist": 19 },
  "25404": { "Cosmetologist": 2 },
  "53115": { "Cosmetologist": 1 },
  "60602": { "Cosmetologist": 1 },
  "48604": { "Cosmetologist": 56, "Barber": 1 },
  "11020": { "Cosmetologist": 1 },
  "32639": { "Cosmetologist": 1 },
  "32896": { "Cosmetologist": 1 },
  "38652": { "Cosmetologist": 1 },
  "62938": { "Cosmetologist": 1 },
  "60070": { "Cosmetologist": 1 },
  "96146": { "Cosmetologist": 1 },
  "34419": { "Cosmetologist": 1 },
  "33227": { "Cosmetologist": 1 },
  "98043": { "Cosmetologist": 1 },
  "31823": { "Cosmetologist": 1 },
  "91016": { "Cosmetologist": 1 },
  "34498": { "Cosmetologist": 2 },
  "39533": { "Cosmetologist": 1 },
  "53217": { "Cosmetologist": 6 },
  "33586": { "Cosmetologist": 3 },
  "31502": { "Cosmetologist": 1 },
  "35055": { "Cosmetologist": 4 },
  "30703": { "Cosmetologist": 2 },
  "90805": { "Cosmetologist": 2 },
  "48444": { "Cosmetologist": 89 },
  "68803": { "Cosmetologist": 2 },
  "31518": { "Cosmetologist": 1 },
  "34323": { "Cosmetologist": 1 },
  "45862": { "Cosmetologist": 1 },
  "96913": { "Cosmetologist": 3 },
  "49450": { "Cosmetologist": 13 },
  "48601": { "Cosmetologist": 117, "Barber": 21 },
  "41011": { "Cosmetologist": 3 },
  "48383": { "Cosmetologist": 103, "Barber": 2 },
  "55350": { "Cosmetologist": 1 },
  "31543": { "Cosmetologist": 2 },
  "43078": { "Cosmetologist": 1 },
  "36206": { "Cosmetologist": 2 },
  "89134": { "Cosmetologist": 3 },
  "44278": { "Cosmetologist": 2 },
  "11559": { "Cosmetologist": 2 },
  "36401": { "Cosmetologist": 2 },
  "49453": { "Cosmetologist": 21 },
  "35125": { "Cosmetologist": 4 },
  "53029": { "Cosmetologist": 2 },
  "19938": { "Cosmetologist": 2 },
  "33732": { "Cosmetologist": 1 },
  "11801": { "Cosmetologist": 1 },
  "24013": { "Cosmetologist": 1 },
  "77312": { "Cosmetologist": 1 },
  "44028": { "Cosmetologist": 1 },
  "10580": { "Cosmetologist": 1 },
  "11422": { "Cosmetologist": 1 },
  "92078": { "Cosmetologist": 2 },
  "33198": { "Cosmetologist": 1 },
  "95667": { "Cosmetologist": 1 },
  "32664": { "Cosmetologist": 1 },
  "54840": { "Cosmetologist": 1 },
  "23875": { "Cosmetologist": 1 },
  "31510": { "Cosmetologist": 1 },
  "60548": { "Cosmetologist": 1 },
  "49254": { "Cosmetologist": 26, "Barber": 3 },
  "49127": { "Cosmetologist": 69, "Barber": 1 },
  "40513": { "Cosmetologist": 7 },
  "32610": { "Cosmetologist": 1 },
  "11778": { "Cosmetologist": 1 },
  "11428": { "Cosmetologist": 2 },
  "14301": { "Cosmetologist": 1 },
  "48763": { "Cosmetologist": 35, "Barber": 1 },
  "96379": { "Cosmetologist": 2 },
  "14303": { "Cosmetologist": 2 },
  "32799": { "Cosmetologist": 1 },
  "39759": { "Cosmetologist": 3 },
  "90024": { "Cosmetologist": 3 },
  "32264": { "Cosmetologist": 1 },
  "44889": { "Cosmetologist": 2 },
  "42095": { "Cosmetologist": 1 },
  "49799": { "Cosmetologist": 17, "Barber": 1 },
  "44065": { "Cosmetologist": 1 },
  "97759": { "Cosmetologist": 1 },
  "32876": { "Cosmetologist": 1 },
  "72205": { "Cosmetologist": 2 },
  "44054": { "Cosmetologist": 3, "Barber": 1 },
  "22301": { "Cosmetologist": 1 },
  "33258": { "Cosmetologist": 2 },
  "49320": { "Cosmetologist": 1 },
  "48045": { "Cosmetologist": 250, "Barber": 8 },
  "91377": { "Cosmetologist": 1 },
  "91302": { "Cosmetologist": 1 },
  "84094": { "Cosmetologist": 1 },
  "49525": { "Cosmetologist": 238, "Barber": 1 },
  "49040": { "Cosmetologist": 14 },
  "21758": { "Cosmetologist": 1 },
  "44286": { "Cosmetologist": 1 },
  "14420": { "Cosmetologist": 2 },
  "36830": { "Cosmetologist": 10 },
  "37364": { "Cosmetologist": 1 },
  "75336": { "Cosmetologist": 1 },
  "49203": { "Cosmetologist": 248, "Barber": 14 },
  "10540": { "Cosmetologist": 1 },
  "48060": { "Cosmetologist": 290, "Barber": 5 },
  "90035": { "Cosmetologist": 1 },
  "20187": { "Cosmetologist": 1 },
  "95269": { "Cosmetologist": 1 },
  "55304": { "Cosmetologist": 4 },
  "26575": { "Cosmetologist": 1 },
  "99021": { "Cosmetologist": 1 },
  "31314": { "Cosmetologist": 1 },
  "95660": { "Cosmetologist": 2 },
  "33535": { "Cosmetologist": 1 },
  "97005": { "Cosmetologist": 2 },
  "48301": { "Cosmetologist": 54, "Barber": 3 },
  "12771": { "Cosmetologist": 2 },
  "48166": { "Cosmetologist": 79, "Barber": 1 },
  "53940": { "Cosmetologist": 1 },
  "14779": { "Cosmetologist": 1 },
  "23692": { "Cosmetologist": 1 },
  "24966": { "Cosmetologist": 1 },
  "44432": { "Cosmetologist": 1 },
  "11755": { "Cosmetologist": 2 },
  "11767": { "Cosmetologist": 1 },
  "32752": { "Cosmetologist": 5 },
  "90022": { "Cosmetologist": 2 },
  "32429": { "Cosmetologist": 1 },
  "43604": { "Cosmetologist": 1, "Barber": 1 },
  "91342": { "Cosmetologist": 3 },
  "14612": { "Cosmetologist": 1 },
  "33191": { "Cosmetologist": 3 },
  "02205": { "Cosmetologist": 1 },
  "41073": { "Cosmetologist": 1 },
  "43046": { "Cosmetologist": 1 },
  "10970": { "Cosmetologist": 1 },
  "33363": { "Cosmetologist": 1 },
  "43161": { "Cosmetologist": 1 },
  "33046": { "Cosmetologist": 1 },
  "45710": { "Cosmetologist": 1 },
  "40376": { "Cosmetologist": 1 },
  "29178": { "Cosmetologist": 1 },
  "71101": { "Cosmetologist": 1 },
  "62881": { "Cosmetologist": 1 },
  "35603": { "Cosmetologist": 3 },
  "74873": { "Cosmetologist": 1 },
  "53085": { "Cosmetologist": 1 },
  "60482": { "Cosmetologist": 1 },
  "41076": { "Cosmetologist": 1 },
  "36732": { "Cosmetologist": 2 },
  "35186": { "Cosmetologist": 1 },
  "36803": { "Cosmetologist": 1 },
  "71603": { "Cosmetologist": 1 },
  "92801": { "Cosmetologist": 2 },
  "39046": { "Cosmetologist": 2 },
  "95035": { "Cosmetologist": 3 },
  "98271": { "Cosmetologist": 2 },
  "44202": { "Cosmetologist": 3 },
  "60107": { "Cosmetologist": 3 },
  "90245": { "Cosmetologist": 1 },
  "20015": { "Cosmetologist": 1 },
  "60432": { "Cosmetologist": 1 },
  "34790": { "Cosmetologist": 1 },
  "70114": { "Cosmetologist": 2 },
  "35040": { "Cosmetologist": 1 },
  "24927": { "Cosmetologist": 1 },
  "70047": { "Cosmetologist": 1 },
  "48363": { "Cosmetologist": 51, "Barber": 1 },
  "94599": { "Cosmetologist": 1 },
  "60020": { "Cosmetologist": 2 },
  "48084": { "Cosmetologist": 56, "Barber": 4 },
  "33109": { "Cosmetologist": 2 },
  "32090": { "Cosmetologist": 1 },
  "41230": { "Cosmetologist": 1 },
  "96750": { "Cosmetologist": 3 },
  "60044": { "Cosmetologist": 2 },
  "36523": { "Cosmetologist": 1 },
  "11570": { "Cosmetologist": 2 },
  "14207": { "Cosmetologist": 3 },
  "72736": { "Cosmetologist": 1 },
  "60031": { "Cosmetologist": 6 },
  "48433": { "Cosmetologist": 221, "Barber": 15 },
  "85118": { "Cosmetologist": 3 },
  "92312": { "Cosmetologist": 1 },
  "40268": { "Cosmetologist": 1 },
  "89169": { "Cosmetologist": 4 },
  "74134": { "Cosmetologist": 4 },
  "49224": { "Cosmetologist": 55, "Barber": 6 },
  "73632": { "Cosmetologist": 1 },
  "20814": { "Cosmetologist": 2 },
  "40514": { "Cosmetologist": 4 },
  "10707": { "Cosmetologist": 1 },
  "30255": { "Cosmetologist": 1 },
  "42647": { "Cosmetologist": 2 },
  "32717": { "Cosmetologist": 2 },
  "34437": { "Cosmetologist": 1 },
  "45344": { "Cosmetologist": 2 },
  "55123": { "Cosmetologist": 2 },
  "32745": { "Cosmetologist": 1 },
  "90813": { "Cosmetologist": 2 },
  "60523": { "Cosmetologist": 4 },
  "11429": { "Cosmetologist": 1 },
  "53081": { "Cosmetologist": 5 },
  "30567": { "Cosmetologist": 1 },
  "98685": { "Cosmetologist": 2 },
  "14075": { "Cosmetologist": 1 },
  "35211": { "Cosmetologist": 1 },
  "89509": { "Cosmetologist": 1 },
  "55378": { "Cosmetologist": 4 },
  "12548": { "Cosmetologist": 1 },
  "39047": { "Cosmetologist": 9 },
  "49426": { "Cosmetologist": 511, "Barber": 4 },
  "26301": { "Cosmetologist": 1 },
  "33203": { "Cosmetologist": 1 },
  "54935": { "Cosmetologist": 3 },
  "23937": { "Cosmetologist": 1 },
  "12491": { "Cosmetologist": 1 },
  "53913": { "Cosmetologist": 2 },
  "98329": { "Cosmetologist": 1 },
  "48168": { "Cosmetologist": 81, "Barber": 7 },
  "43003": { "Cosmetologist": 1 },
  "49441": { "Cosmetologist": 263, "Barber": 10 },
  "96741": { "Cosmetologist": 2 },
  "40444": { "Cosmetologist": 2 },
  "44235": { "Cosmetologist": 1 },
  "17640": { "Cosmetologist": 1 },
  "90601": { "Cosmetologist": 1 },
  "47126": { "Cosmetologist": 1 },
  "44240": { "Cosmetologist": 2 },
  "14803": { "Cosmetologist": 1 },
  "36525": { "Cosmetologist": 4 },
  "30457": { "Cosmetologist": 1 },
  "21826": { "Cosmetologist": 2 },
  "45030": { "Cosmetologist": 1 },
  "89101": { "Cosmetologist": 4 },
  "40214": { "Cosmetologist": 17 },
  "10472": { "Cosmetologist": 1 },
  "89519": { "Cosmetologist": 1 },
  "14620": { "Cosmetologist": 1 },
  "53144": { "Cosmetologist": 1 },
  "49712": { "Cosmetologist": 65, "Barber": 1 },
  "30817": { "Cosmetologist": 1 },
  "40502": { "Cosmetologist": 2 },
  "48730": { "Cosmetologist": 35, "Barber": 4 },
  "70116": { "Cosmetologist": 2 },
  "25302": { "Cosmetologist": 1 },
  "44403": { "Cosmetologist": 1 },
  "17105": { "Cosmetologist": 2 },
  "91606": { "Cosmetologist": 4 },
  "22939": { "Cosmetologist": 2 },
  "33423": { "Cosmetologist": 2 },
  "48327": { "Cosmetologist": 184, "Barber": 8 },
  "24121": { "Cosmetologist": 1 },
  "48837": { "Cosmetologist": 156, "Barber": 18 },
  "90006": { "Cosmetologist": 1 },
  "48750": { "Cosmetologist": 59, "Barber": 1 },
  "89085": { "Cosmetologist": 3 },
  "33878": { "Cosmetologist": 1 },
  "13037": { "Cosmetologist": 1 },
  "55374": { "Cosmetologist": 1 },
  "98056": { "Cosmetologist": 1 },
  "62460": { "Cosmetologist": 1 },
  "43511": { "Cosmetologist": 2 },
  "29630": { "Cosmetologist": 2 },
  "93004": { "Cosmetologist": 1 },
  "44146": { "Cosmetologist": 4 },
  "97487": { "Cosmetologist": 1 },
  "95161": { "Cosmetologist": 1 },
  "32786": { "Cosmetologist": 2 },
  "84404": { "Cosmetologist": 3 },
  "21060": { "Cosmetologist": 1 },
  "10520": { "Cosmetologist": 1 },
  "30108": { "Cosmetologist": 1 },
  "91737": { "Cosmetologist": 2 },
  "35453": { "Cosmetologist": 2 },
  "38482": { "Cosmetologist": 1 },
  "42276": { "Cosmetologist": 1 },
  "22601": { "Cosmetologist": 2 },
  "44092": { "Cosmetologist": 1 },
  "87504": { "Cosmetologist": 1 },
  "36110": { "Cosmetologist": 1 },
  "60193": { "Cosmetologist": 3 },
  "21795": { "Cosmetologist": 1 },
  "60015": { "Cosmetologist": 8 },
  "12053": { "Cosmetologist": 1 },
  "38943": { "Cosmetologist": 1 },
  "34944": { "Cosmetologist": 1 },
  "32974": { "Cosmetologist": 1 },
  "59601": { "Cosmetologist": 2 },
  "44001": { "Cosmetologist": 3 },
  "26187": { "Cosmetologist": 1 },
  "28703": { "Cosmetologist": 1 },
  "90250": { "Cosmetologist": 2 },
  "35951": { "Cosmetologist": 1 },
  "36201": { "Cosmetologist": 3 },
  "14757": { "Cosmetologist": 1 },
  "36203": { "Cosmetologist": 8 },
  "91706": { "Cosmetologist": 1 },
  "95822": { "Cosmetologist": 1 },
  "94043": { "Cosmetologist": 1 },
  "60616": { "Cosmetologist": 1 },
  "45122": { "Cosmetologist": 1 },
  "71275": { "Cosmetologist": 1 },
  "36541": { "Cosmetologist": 3 },
  "37863": { "Cosmetologist": 1 },
  "97217": { "Cosmetologist": 2 },
  "95519": { "Cosmetologist": 1 },
  "36351": { "Cosmetologist": 1 },
  "36349": { "Cosmetologist": 1 },
  "25438": { "Cosmetologist": 2 },
  "29404": { "Cosmetologist": 2 },
  "36564": { "Cosmetologist": 2 },
  "32307": { "Cosmetologist": 1 },
  "88043": { "Cosmetologist": 1 },
  "23834": { "Cosmetologist": 1 },
  "70592": { "Cosmetologist": 2 },
  "38857": { "Cosmetologist": 1 },
  "71203": { "Cosmetologist": 1 },
  "98367": { "Cosmetologist": 3 },
  "62568": { "Cosmetologist": 1 },
  "70634": { "Cosmetologist": 2 },
  "10605": { "Cosmetologist": 3 },
  "55127": { "Cosmetologist": 1 },
  "38863": { "Cosmetologist": 1 },
  "14618": { "Cosmetologist": 1 },
  "40460": { "Cosmetologist": 1 },
  "87108": { "Cosmetologist": 2 },
  "33961": { "Cosmetologist": 1 },
  "39452": { "Cosmetologist": 2 },
  "61048": { "Cosmetologist": 1 },
  "56701": { "Cosmetologist": 2 },
  "60561": { "Cosmetologist": 1 },
  "60056": { "Cosmetologist": 2 },
  "16514": { "Cosmetologist": 1 },
  "33192": { "Cosmetologist": 1 },
  "92610": { "Cosmetologist": 2 },
  "20670": { "Cosmetologist": 1 },
  "54942": { "Cosmetologist": 1 },
  "48080": { "Cosmetologist": 235, "Barber": 6 },
  "32385": { "Cosmetologist": 2 },
  "61373": { "Cosmetologist": 1 },
  "39914": { "Cosmetologist": 1 },
  "49036": { "Cosmetologist": 109, "Barber": 10 },
  "33356": { "Cosmetologist": 1 },
  "95501": { "Cosmetologist": 1 },
  "12231": { "Cosmetologist": 1 },
  "12883": { "Cosmetologist": 1 },
  "29666": { "Cosmetologist": 1 },
  "11780": { "Cosmetologist": 1 },
  "91107": { "Cosmetologist": 1 },
  "40299": { "Cosmetologist": 7 },
  "49442": { "Cosmetologist": 180, "Barber": 6 },
  "44133": { "Cosmetologist": 6 },
  "34366": { "Cosmetologist": 1 },
  "20165": { "Cosmetologist": 1 },
  "11223": { "Cosmetologist": 2 },
  "20871": { "Cosmetologist": 2 },
  "48034": { "Cosmetologist": 75, "Barber": 5 },
  "55344": { "Cosmetologist": 2 },
  "32360": { "Cosmetologist": 1 },
  "48126": { "Cosmetologist": 125, "Barber": 50 },
  "25702": { "Cosmetologist": 2 },
  "10030": { "Cosmetologist": 2 },
  "49055": { "Cosmetologist": 30 },
  "60175": { "Cosmetologist": 2 },
  "43548": { "Cosmetologist": 1 },
  "37292": { "Cosmetologist": 1 },
  "41091": { "Cosmetologist": 5 },
  "41097": { "Cosmetologist": 1 },
  "55372": { "Cosmetologist": 1 },
  "93313": { "Cosmetologist": 1 },
  "56379": { "Cosmetologist": 1 },
  "21286": { "Cosmetologist": 3 },
  "72015": { "Cosmetologist": 1 },
  "92114": { "Cosmetologist": 1 },
  "48912": { "Cosmetologist": 93, "Barber": 14 },
  "44288": { "Cosmetologist": 1 },
  "72207": { "Cosmetologist": 1 },
  "95132": { "Cosmetologist": 3 },
  "11212": { "Cosmetologist": 2 },
  "11417": { "Cosmetologist": 1 },
  "68113": { "Cosmetologist": 1 },
  "11433": { "Cosmetologist": 1 },
  "34213": { "Cosmetologist": 1 },
  "96349": { "Cosmetologist": 4 },
  "12078": { "Cosmetologist": 1 },
  "85648": { "Cosmetologist": 1 },
  "14870": { "Cosmetologist": 2 },
  "24060": { "Cosmetologist": 1 },
  "35772": { "Cosmetologist": 1 },
  "54235": { "Cosmetologist": 2 },
  "74135": { "Cosmetologist": 1 },
  "34722": { "Cosmetologist": 1 },
  "11940": { "Cosmetologist": 1 },
  "95903": { "Cosmetologist": 1 },
  "85708": { "Cosmetologist": 2 },
  "74337": { "Cosmetologist": 1 },
  "32458": { "Cosmetologist": 1 },
  "10029": { "Cosmetologist": 3 },
  "24221": { "Cosmetologist": 1 },
  "47114": { "Cosmetologist": 1 },
  "11096": { "Cosmetologist": 1 },
  "22151": { "Cosmetologist": 3 },
  "70818": { "Cosmetologist": 1 },
  "20754": { "Cosmetologist": 1 },
  "23841": { "Cosmetologist": 1 },
  "74653": { "Cosmetologist": 1 },
  "23903": { "Cosmetologist": 1 },
  "35476": { "Cosmetologist": 2 },
  "92372": { "Cosmetologist": 1 },
  "91325": { "Cosmetologist": 2 },
  "45891": { "Cosmetologist": 1 },
  "89706": { "Cosmetologist": 1 },
  "95812": { "Cosmetologist": 1 },
  "38661": { "Cosmetologist": 1 },
  "14223": { "Cosmetologist": 3 },
  "23328": { "Cosmetologist": 2 },
  "49341": { "Cosmetologist": 351, "Barber": 8 },
  "23827": { "Cosmetologist": 1 },
  "32753": { "Cosmetologist": 1 },
  "71325": { "Cosmetologist": 1 },
  "91709": { "Cosmetologist": 3 },
  "18502": { "Cosmetologist": 1 },
  "48374": { "Cosmetologist": 63, "Barber": 1 },
  "97058": { "Cosmetologist": 1 },
  "39157": { "Cosmetologist": 1 },
  "11434": { "Cosmetologist": 2 },
  "60007": { "Cosmetologist": 2 },
  "57110": { "Cosmetologist": 2 },
  "33171": { "Cosmetologist": 3 },
  "99505": { "Cosmetologist": 2 },
  "40118": { "Cosmetologist": 3 },
  "68649": { "Cosmetologist": 1 },
  "98037": { "Cosmetologist": 1 },
  "23072": { "Cosmetologist": 2 },
  "21755": { "Cosmetologist": 2 },
  "10805": { "Cosmetologist": 2 },
  "99037": { "Cosmetologist": 2 },
  "70538": { "Cosmetologist": 1 },
  "91755": { "Cosmetologist": 4 },
  "96002": { "Cosmetologist": 1 },
  "14204": { "Cosmetologist": 1 },
  "92704": { "Cosmetologist": 1 },
  "44420": { "Cosmetologist": 1 },
  "74441": { "Cosmetologist": 1 },
  "13676": { "Cosmetologist": 2 },
  "49321": { "Cosmetologist": 152, "Barber": 2 },
  "97401": { "Cosmetologist": 3 },
  "22903": { "Cosmetologist": 1 },
  "29935": { "Cosmetologist": 1 },
  "62640": { "Cosmetologist": 1 },
  "97213": { "Cosmetologist": 1 },
  "35004": { "Cosmetologist": 1 },
  "31328": { "Cosmetologist": 2 },
  "11557": { "Cosmetologist": 1 },
  "33631": { "Cosmetologist": 1 },
  "39472": { "Cosmetologist": 1 },
  "60970": { "Cosmetologist": 1 },
  "49431": { "Cosmetologist": 108, "Barber": 4 },
  "40356": { "Cosmetologist": 5 },
  "54902": { "Cosmetologist": 1 },
  "48356": { "Cosmetologist": 101, "Barber": 3 },
  "39111": { "Cosmetologist": 1 },
  "74735": { "Cosmetologist": 1 },
  "99156": { "Cosmetologist": 1 },
  "33718": { "Cosmetologist": 3 },
  "91911": { "Cosmetologist": 2 },
  "54494": { "Cosmetologist": 2 },
  "53511": { "Cosmetologist": 4 },
  "32487": { "Cosmetologist": 1 },
  "48460": { "Cosmetologist": 23, "Barber": 2 },
  "70420": { "Cosmetologist": 1 },
  "48081": { "Cosmetologist": 227, "Barber": 10 },
  "91732": { "Cosmetologist": 3 },
  "14168": { "Cosmetologist": 1 },
  "99701": { "Cosmetologist": 3 },
  "72173": { "Cosmetologist": 1 },
  "09464": { "Cosmetologist": 1 },
  "91306": { "Cosmetologist": 5 },
  "44062": { "Cosmetologist": 1 },
  "45034": { "Cosmetologist": 5 },
  "91754": { "Cosmetologist": 3 },
  "10128": { "Cosmetologist": 5 },
  "34489": { "Cosmetologist": 2 },
  "45233": { "Cosmetologist": 1 },
  "34768": { "Cosmetologist": 1 },
  "60634": { "Cosmetologist": 2 },
  "43416": { "Cosmetologist": 1, "Barber": 1 },
  "48141": { "Cosmetologist": 116, "Barber": 16 },
  "20817": { "Cosmetologist": 2 },
  "60609": { "Cosmetologist": 1 },
  "12838": { "Cosmetologist": 1 },
  "14020": { "Cosmetologist": 1 },
  "34596": { "Cosmetologist": 1 },
  "54401": { "Cosmetologist": 1 },
  "55448": { "Cosmetologist": 2 },
  "61929": { "Cosmetologist": 1 },
  "40208": { "Cosmetologist": 2 },
  "29556": { "Cosmetologist": 1 },
  "32172": { "Cosmetologist": 1 },
  "34340": { "Cosmetologist": 1 },
  "40121": { "Cosmetologist": 2 },
  "91344": { "Cosmetologist": 4 },
  "35960": { "Cosmetologist": 1 },
  "12814": { "Cosmetologist": 1 },
  "30292": { "Cosmetologist": 2 },
  "31044": { "Cosmetologist": 1 },
  "26155": { "Cosmetologist": 1 },
  "35548": { "Cosmetologist": 1 },
  "32451": { "Cosmetologist": 1 },
  "40258": { "Cosmetologist": 1 },
  "49408": { "Cosmetologist": 52, "Barber": 2 },
  "13795": { "Cosmetologist": 1 },
  "13490": { "Cosmetologist": 1 },
  "38858": { "Cosmetologist": 1 },
  "95138": { "Cosmetologist": 1 },
  "98586": { "Cosmetologist": 1 },
  "31110": { "Cosmetologist": 1 },
  "12571": { "Cosmetologist": 1 },
  "34042": { "Cosmetologist": 1 },
  "68801": { "Cosmetologist": 1 },
  "55929": { "Cosmetologist": 1 },
  "54157": { "Cosmetologist": 7 },
  "97601": { "Cosmetologist": 1 },
  "72118": { "Cosmetologist": 1 },
  "19130": { "Cosmetologist": 1 },
  "20621": { "Cosmetologist": 1 },
  "57105": { "Cosmetologist": 2 },
  "93639": { "Cosmetologist": 1 },
  "89193": { "Cosmetologist": 1 },
  "34282": { "Cosmetologist": 2 },
  "95115": { "Cosmetologist": 1 },
  "34636": { "Cosmetologist": 1 },
  "49015": { "Cosmetologist": 160, "Barber": 11 },
  "49402": { "Cosmetologist": 10 },
  "96555": { "Cosmetologist": 2 },
  "19713": { "Cosmetologist": 2 },
  "30439": { "Cosmetologist": 1 },
  "14878": { "Cosmetologist": 1 },
  "12754": { "Cosmetologist": 3 },
  "60654": { "Cosmetologist": 4 },
  "59716": { "Cosmetologist": 2 },
  "35406": { "Cosmetologist": 1 },
  "98062": { "Cosmetologist": 1 },
  "86322": { "Cosmetologist": 1 },
  "25801": { "Cosmetologist": 2 },
  "96720": { "Cosmetologist": 1 },
  "73109": { "Cosmetologist": 1 },
  "35228": { "Cosmetologist": 1 },
  "22842": { "Cosmetologist": 1 },
  "10703": { "Cosmetologist": 2 },
  "14063": { "Cosmetologist": 4 },
  "98584": { "Cosmetologist": 1 },
  "22182": { "Cosmetologist": 2 },
  "89705": { "Cosmetologist": 1 },
  "36130": { "Cosmetologist": 6 },
  "91731": { "Cosmetologist": 2 },
  "31139": { "Cosmetologist": 1 },
  "92103": { "Cosmetologist": 1 },
  "45150": { "Cosmetologist": 2 },
  "36521": { "Cosmetologist": 1 },
  "44026": { "Cosmetologist": 1 },
  "36467": { "Cosmetologist": 2 },
  "23181": { "Cosmetologist": 1 },
  "48506": { "Cosmetologist": 140, "Barber": 10 },
  "10507": { "Cosmetologist": 2 },
  "95127": { "Cosmetologist": 3 },
  "49420": { "Cosmetologist": 45, "Barber": 2 },
  "32323": { "Cosmetologist": 1 },
  "25976": { "Cosmetologist": 1 },
  "92663": { "Cosmetologist": 1 },
  "13820": { "Cosmetologist": 2 },
  "42539": { "Cosmetologist": 1 },
  "95835": { "Cosmetologist": 1 },
  "93922": { "Cosmetologist": 1 },
  "34016": { "Cosmetologist": 1 },
  "84302": { "Cosmetologist": 1 },
  "93257": { "Cosmetologist": 1 },
  "61501": { "Cosmetologist": 1 },
  "55904": { "Cosmetologist": 2 },
  "49037": { "Cosmetologist": 87, "Barber": 11 },
  "12780": { "Cosmetologist": 1 },
  "30734": { "Cosmetologist": 1 },
  "25701": { "Cosmetologist": 2 },
  "48618": { "Cosmetologist": 45, "Barber": 3 },
  "95204": { "Cosmetologist": 1 },
  "95678": { "Cosmetologist": 2 },
  "84056": { "Cosmetologist": 2 },
  "43528": { "Cosmetologist": 4 },
  "49459": { "Cosmetologist": 9 },
  "94531": { "Cosmetologist": 1 },
  "35080": { "Cosmetologist": 2 },
  "32013": { "Cosmetologist": 1 },
  "32544": { "Cosmetologist": 5 },
  "98490": { "Cosmetologist": 1 },
  "12204": { "Cosmetologist": 1 },
  "35223": { "Cosmetologist": 24 },
  "31955": { "Cosmetologist": 2 },
  "93702": { "Cosmetologist": 1 },
  "39905": { "Cosmetologist": 1 },
  "19962": { "Cosmetologist": 1 },
  "12570": { "Cosmetologist": 1 },
  "10453": { "Cosmetologist": 1 },
  "70802": { "Cosmetologist": 1 },
  "22017": { "Cosmetologist": 1 },
  "30552": { "Cosmetologist": 1 },
  "14120": { "Cosmetologist": 1 },
  "46926": { "Cosmetologist": 1 },
  "36066": { "Cosmetologist": 5 },
  "92411": { "Cosmetologist": 1 },
  "94560": { "Cosmetologist": 1 },
  "44011": { "Cosmetologist": 2 },
  "32834": { "Cosmetologist": 2 },
  "40216": { "Cosmetologist": 2 },
  "48225": { "Cosmetologist": 79, "Barber": 3 },
  "60450": { "Cosmetologist": 1 },
  "33752": { "Cosmetologist": 1 },
  "31604": { "Cosmetologist": 1 },
  "47942": { "Cosmetologist": 1 },
  "39581": { "Cosmetologist": 2 },
  "43113": { "Cosmetologist": 2 },
  "33515": { "Cosmetologist": 3 },
  "99506": { "Cosmetologist": 2 },
  "33639": { "Cosmetologist": 1 },
  "32061": { "Cosmetologist": 2 },
  "74023": { "Cosmetologist": 1 },
  "48088": { "Cosmetologist": 165, "Barber": 12 },
  "36784": { "Cosmetologist": 1 },
  "32074": { "Cosmetologist": 1 },
  "36509": { "Cosmetologist": 1 },
  "21922": { "Cosmetologist": 1 },
  "38732": { "Cosmetologist": 1 },
  "12158": { "Cosmetologist": 1 },
  "21108": { "Cosmetologist": 2 },
  "21601": { "Cosmetologist": 2 },
  "91915": { "Cosmetologist": 2 },
  "38930": { "Cosmetologist": 4 },
  "38801": { "Cosmetologist": 2 },
  "92337": { "Cosmetologist": 2 },
  "32897": { "Cosmetologist": 1 },
  "44413": { "Cosmetologist": 1 },
  "09021": { "Cosmetologist": 1 },
  "40348": { "Cosmetologist": 1 },
  "40359": { "Cosmetologist": 1 },
  "93907": { "Cosmetologist": 1 },
  "22305": { "Cosmetologist": 1 },
  "32769": { "Cosmetologist": 1 },
  "33286": { "Cosmetologist": 1 },
  "90029": { "Cosmetologist": 3 },
  "48401": { "Cosmetologist": 12 },
  "34658": { "Cosmetologist": 1 },
  "39574": { "Cosmetologist": 1 },
  "61822": { "Cosmetologist": 2 },
  "99202": { "Cosmetologist": 1 },
  "24656": { "Cosmetologist": 1 },
  "48179": { "Cosmetologist": 28 },
  "22302": { "Cosmetologist": 2 },
  "60428": { "Cosmetologist": 1 },
  "09459": { "Cosmetologist": 1 },
  "42060": { "Cosmetologist": 1 },
  "36703": { "Cosmetologist": 1 },
  "30333": { "Cosmetologist": 1 },
  "14094": { "Cosmetologist": 1 },
  "34073": { "Cosmetologist": 1 },
  "21774": { "Cosmetologist": 1 },
  "12839": { "Cosmetologist": 3 },
  "33571": { "Cosmetologist": 2 },
  "35741": { "Cosmetologist": 1 },
  "30206": { "Cosmetologist": 1 },
  "13685": { "Cosmetologist": 1 },
  "24594": { "Cosmetologist": 1 },
  "31416": { "Cosmetologist": 1 },
  "33814": { "Cosmetologist": 2 },
  "56737": { "Cosmetologist": 1 },
  "20637": { "Cosmetologist": 1 },
  "90807": { "Cosmetologist": 1 },
  "92543": { "Cosmetologist": 2 },
  "10039": { "Cosmetologist": 2 },
  "48094": { "Cosmetologist": 224, "Barber": 10 },
  "35750": { "Cosmetologist": 2 },
  "35217": { "Cosmetologist": 1 },
  "32692": { "Cosmetologist": 1 },
  "36025": { "Cosmetologist": 1 },
  "49878": { "Cosmetologist": 24 },
  "49931": { "Cosmetologist": 21, "Barber": 2 },
  "35214": { "Cosmetologist": 2 },
  "37474": { "Cosmetologist": 1 },
  "39044": { "Cosmetologist": 1 },
  "35016": { "Cosmetologist": 1 },
  "11969": { "Cosmetologist": 2 },
  "33536": { "Cosmetologist": 1 },
  "12542": { "Cosmetologist": 1 },
  "40609": { "Cosmetologist": 1 },
  "48198": { "Cosmetologist": 192, "Barber": 19 },
  "45359": { "Cosmetologist": 1 },
  "60171": { "Cosmetologist": 2 },
  "35222": { "Cosmetologist": 2 },
  "60636": { "Cosmetologist": 1 },
  "33110": { "Cosmetologist": 1 },
  "94114": { "Cosmetologist": 1 },
  "22846": { "Cosmetologist": 1 },
  "14171": { "Cosmetologist": 1 },
  "23075": { "Cosmetologist": 1 },
  "11803": { "Cosmetologist": 1 },
  "32403": { "Cosmetologist": 1 },
  "44070": { "Cosmetologist": 4 },
  "33562": { "Cosmetologist": 1 },
  "19808": { "Cosmetologist": 1 },
  "90014": { "Cosmetologist": 1 },
  "33340": { "Cosmetologist": 1 },
  "48201": { "Cosmetologist": 24, "Barber": 4 },
  "44654": { "Cosmetologist": 1 },
  "40203": { "Cosmetologist": 1 },
  "91745": { "Cosmetologist": 1 },
  "45324": { "Cosmetologist": 1 },
  "96218": { "Cosmetologist": 1 },
  "97266": { "Cosmetologist": 2 },
  "45305": { "Cosmetologist": 1 },
  "95126": { "Cosmetologist": 1 },
  "59918": { "Cosmetologist": 1 },
  "14428": { "Cosmetologist": 1 },
  "93723": { "Cosmetologist": 2 },
  "43504": { "Cosmetologist": 1 },
  "35224": { "Cosmetologist": 1 },
  "90003": { "Cosmetologist": 1 },
  "20109": { "Cosmetologist": 1 },
  "13634": { "Cosmetologist": 1 },
  "11436": { "Cosmetologist": 1 },
  "89449": { "Cosmetologist": 1 },
  "34853": { "Cosmetologist": 2 },
  "49508": { "Cosmetologist": 271, "Barber": 5 },
  "35071": { "Cosmetologist": 1 },
  "60441": { "Cosmetologist": 5 },
  "83702": { "Cosmetologist": 1 },
  "83301": { "Cosmetologist": 2 },
  "77411": { "Cosmetologist": 1 },
  "94535": { "Cosmetologist": 2 },
  "31566": { "Cosmetologist": 1 },
  "55805": { "Cosmetologist": 3 },
  "14072": { "Cosmetologist": 2 },
  "14213": { "Cosmetologist": 2 },
  "85071": { "Cosmetologist": 1 },
  "98502": { "Cosmetologist": 2 },
  "32690": { "Cosmetologist": 1 },
  "11701": { "Cosmetologist": 1 },
  "48457": { "Cosmetologist": 73, "Barber": 3 },
  "49464": { "Cosmetologist": 315, "Barber": 3 },
  "39305": { "Cosmetologist": 1 },
  "34200": { "Cosmetologist": 1 },
  "33668": { "Cosmetologist": 3 },
  "79053": { "Cosmetologist": 1 },
  "35633": { "Cosmetologist": 1 },
  "45669": { "Cosmetologist": 1 },
  "93250": { "Cosmetologist": 1 },
  "72301": { "Cosmetologist": 1 },
  "00923": { "Cosmetologist": 1 },
  "10530": { "Cosmetologist": 1 },
  "35150": { "Cosmetologist": 1 },
  "29918": { "Cosmetologist": 1 },
  "25313": { "Cosmetologist": 1 },
  "38132": { "Cosmetologist": 1 },
  "60481": { "Cosmetologist": 1 },
  "68029": { "Cosmetologist": 1 },
  "34456": { "Cosmetologist": 1 },
  "42717": { "Cosmetologist": 1 },
  "43065": { "Cosmetologist": 2, "Barber": 1 },
  "32184": { "Cosmetologist": 1 },
  "36617": { "Cosmetologist": 1 },
  "34707": { "Cosmetologist": 1 },
  "41701": { "Cosmetologist": 1 },
  "33049": { "Cosmetologist": 1 },
  "68901": { "Cosmetologist": 1 },
  "40422": { "Cosmetologist": 2 },
  "89429": { "Cosmetologist": 1 },
  "33754": { "Cosmetologist": 1 },
  "37424": { "Cosmetologist": 4 },
  "73127": { "Cosmetologist": 1 },
  "34321": { "Cosmetologist": 1 },
  "35759": { "Cosmetologist": 1 },
  "82836": { "Cosmetologist": 1 },
  "71027": { "Cosmetologist": 1 },
  "96348": { "Cosmetologist": 1 },
  "33371": { "Cosmetologist": 2 },
  "36544": { "Cosmetologist": 4 },
  "03062": { "Cosmetologist": 1 },
  "33362": { "Cosmetologist": 1 },
  "44836": { "Cosmetologist": 1 },
  "98031": { "Cosmetologist": 1 },
  "35570": { "Cosmetologist": 2 },
  "44138": { "Cosmetologist": 1 },
  "74132": { "Cosmetologist": 1 },
  "34961": { "Cosmetologist": 1 },
  "96826": { "Cosmetologist": 1 },
  "54914": { "Cosmetologist": 8 },
  "70448": { "Cosmetologist": 1 },
  "19968": { "Cosmetologist": 1 },
  "61853": { "Cosmetologist": 1 },
  "92113": { "Cosmetologist": 2 },
  "32838": { "Cosmetologist": 2 },
  "70041": { "Cosmetologist": 1 },
  "53718": { "Cosmetologist": 2 },
  "32406": { "Cosmetologist": 1 },
  "91390": { "Cosmetologist": 1 },
  "34824": { "Cosmetologist": 1 },
  "98045": { "Cosmetologist": 1 },
  "72543": { "Cosmetologist": 1 },
  "92025": { "Cosmetologist": 2 },
  "39773": { "Cosmetologist": 1 },
  "10459": { "Cosmetologist": 3 },
  "43764": { "Cosmetologist": 1 },
  "53563": { "Cosmetologist": 1 },
  "91776": { "Cosmetologist": 1 },
  "44178": { "Cosmetologist": 1 },
  "32815": { "Cosmetologist": 2 },
  "38866": { "Cosmetologist": 2 },
  "84114": { "Cosmetologist": 1 },
  "33151": { "Cosmetologist": 1 },
  "90802": { "Cosmetologist": 1 },
  "62786": { "Cosmetologist": 1 },
  "54949": { "Cosmetologist": 1 },
  "93933": { "Cosmetologist": 1 },
  "40391": { "Cosmetologist": 3 },
  "11980": { "Cosmetologist": 1 },
  "94103": { "Cosmetologist": 2 },
  "34180": { "Cosmetologist": 1 },
  "23566": { "Cosmetologist": 1 },
  "84044": { "Cosmetologist": 1 },
  "97527": { "Cosmetologist": 2 },
  "34525": { "Cosmetologist": 1 },
  "94565": { "Cosmetologist": 1 },
  "31208": { "Cosmetologist": 1 },
  "34735": { "Cosmetologist": 1 },
  "70817": { "Cosmetologist": 2 },
  "33699": { "Cosmetologist": 1 },
  "98239": { "Cosmetologist": 1 },
  "36083": { "Cosmetologist": 1 },
  "82523": { "Cosmetologist": 1 },
  "44147": { "Cosmetologist": 2 },
  "93314": { "Cosmetologist": 1 },
  "94577": { "Cosmetologist": 2 },
  "19931": { "Cosmetologist": 2 },
  "10314": { "Cosmetologist": 2 },
  "78018": { "Cosmetologist": 1 },
  "94030": { "Cosmetologist": 1 },
  "14623": { "Cosmetologist": 3 },
  "95120": { "Cosmetologist": 1 },
  "40601": { "Cosmetologist": 5 },
  "39352": { "Cosmetologist": 1 },
  "48043": { "Cosmetologist": 110, "Barber": 11 },
  "60527": { "Cosmetologist": 3 },
  "60425": { "Cosmetologist": 1 },
  "48169": { "Cosmetologist": 191, "Barber": 12 },
  "39881": { "Cosmetologist": 1 },
  "32467": { "Cosmetologist": 1 },
  "49202": { "Cosmetologist": 95, "Barber": 10 },
  "22815": { "Cosmetologist": 1 },
  "33824": { "Cosmetologist": 1 },
  "33630": { "Cosmetologist": 1 },
  "49444": { "Cosmetologist": 160, "Barber": 6 },
  "26038": { "Cosmetologist": 1 },
  "12125": { "Cosmetologist": 1 },
  "33769": { "Cosmetologist": 1 },
  "18936": { "Cosmetologist": 1 },
  "40119": { "Cosmetologist": 1 },
  "42020": { "Cosmetologist": 1 },
  "91724": { "Cosmetologist": 1 },
  "75711": { "Cosmetologist": 1 },
  "34693": { "Cosmetologist": 1 },
  "12305": { "Cosmetologist": 2 },
  "21120": { "Cosmetologist": 1 },
  "34796": { "Cosmetologist": 1 },
  "31495": { "Cosmetologist": 1 },
  "60120": { "Cosmetologist": 4 },
  "55016": { "Cosmetologist": 1 },
  "90007": { "Cosmetologist": 1 },
  "91361": { "Cosmetologist": 1 },
  "25430": { "Cosmetologist": 1 },
  "21716": { "Cosmetologist": 2 },
  "33086": { "Cosmetologist": 1 },
  "34692": { "Cosmetologist": 1 },
  "23201": { "Cosmetologist": 1 },
  "21104": { "Cosmetologist": 1 },
  "32741": { "Cosmetologist": 1 },
  "36037": { "Cosmetologist": 2 },
  "40272": { "Cosmetologist": 1 },
  "33343": { "Cosmetologist": 1 },
  "39364": { "Cosmetologist": 1 },
  "11467": { "Cosmetologist": 1 },
  "93203": { "Cosmetologist": 2 },
  "11705": { "Cosmetologist": 1 },
  "55445": { "Cosmetologist": 1 },
  "40484": { "Cosmetologist": 1 },
  "55319": { "Cosmetologist": 1 },
  "42837": { "Cosmetologist": 1 },
  "83669": { "Cosmetologist": 2 },
  "49706": { "Cosmetologist": 25, "Barber": 2 },
  "48234": { "Cosmetologist": 118, "Barber": 27 },
  "37722": { "Cosmetologist": 1 },
  "43950": { "Cosmetologist": 2 },
  "90630": { "Cosmetologist": 1 },
  "60452": { "Cosmetologist": 1 },
  "29805": { "Cosmetologist": 1 },
  "54002": { "Cosmetologist": 1 },
  "48184": { "Cosmetologist": 91, "Barber": 10 },
  "61944": { "Cosmetologist": 1 },
  "71238": { "Cosmetologist": 1 },
  "33817": { "Cosmetologist": 2 },
  "35511": { "Cosmetologist": 1 },
  "85621": { "Cosmetologist": 1 },
  "48197": { "Cosmetologist": 307, "Barber": 23 },
  "70006": { "Cosmetologist": 2 },
  "48220": { "Cosmetologist": 177, "Barber": 7 },
  "32823": { "Cosmetologist": 1 },
  "22973": { "Cosmetologist": 1 },
  "49457": { "Cosmetologist": 69 },
  "34029": { "Cosmetologist": 1 },
  "12090": { "Cosmetologist": 1 },
  "94547": { "Cosmetologist": 1 },
  "34647": { "Cosmetologist": 1 },
  "44685": { "Cosmetologist": 1 },
  "34804": { "Cosmetologist": 1 },
  "60555": { "Cosmetologist": 2 },
  "98124": { "Cosmetologist": 1 },
  "31557": { "Cosmetologist": 1 },
  "33719": { "Cosmetologist": 1 },
  "95023": { "Cosmetologist": 1 },
  "36111": { "Cosmetologist": 2 },
  "41063": { "Cosmetologist": 1 },
  "19963": { "Cosmetologist": 2 },
  "55057": { "Cosmetologist": 1 },
  "34547": { "Cosmetologist": 1 },
  "93110": { "Cosmetologist": 1 },
  "34701": { "Cosmetologist": 1 },
  "36619": { "Cosmetologist": 2 },
  "45885": { "Cosmetologist": 1 },
  "72019": { "Cosmetologist": 1 },
  "48162": { "Cosmetologist": 190, "Barber": 7 },
  "94302": { "Cosmetologist": 1 },
  "32186": { "Cosmetologist": 1 },
  "13673": { "Cosmetologist": 1 },
  "26241": { "Cosmetologist": 2 },
  "34784": { "Cosmetologist": 1 },
  "38967": { "Cosmetologist": 1 },
  "34407": { "Cosmetologist": 1 },
  "35111": { "Cosmetologist": 1 },
  "94105": { "Cosmetologist": 43 },
  "99999": { "Cosmetologist": 1 },
  "34844": { "Cosmetologist": 1 },
  "91402": { "Cosmetologist": 1 },
  "32975": { "Cosmetologist": 1 },
  "32711": { "Cosmetologist": 2 },
  "32491": { "Cosmetologist": 1 },
  "53076": { "Cosmetologist": 1 },
  "60067": { "Cosmetologist": 3 },
  "98155": { "Cosmetologist": 1 },
  "61016": { "Cosmetologist": 1 },
  "71854": { "Cosmetologist": 1 },
  "75356": { "Cosmetologist": 1 },
  "46960": { "Cosmetologist": 1 },
  "12106": { "Cosmetologist": 1 },
  "33657": { "Cosmetologist": 1 },
  "53045": { "Cosmetologist": 1 },
  "11370": { "Cosmetologist": 1 },
  "36572": { "Cosmetologist": 2 },
  "12803": { "Cosmetologist": 1 },
  "00732": { "Cosmetologist": 1 },
  "92658": { "Cosmetologist": 1 },
  "93230": { "Cosmetologist": 1 },
  "98102": { "Cosmetologist": 2 },
  "20620": { "Cosmetologist": 1 },
  "60638": { "Cosmetologist": 2 },
  "26582": { "Cosmetologist": 1 },
  "23835": { "Cosmetologist": 1 },
  "39690": { "Cosmetologist": 1 },
  "90660": { "Cosmetologist": 148 },
  "72143": { "Cosmetologist": 2 },
  "94546": { "Cosmetologist": 1 },
  "35907": { "Cosmetologist": 3 },
  "54703": { "Cosmetologist": 1 },
  "77207": { "Cosmetologist": 1 },
  "49315": { "Cosmetologist": 276, "Barber": 5 },
  "14823": { "Cosmetologist": 1 },
  "33829": { "Cosmetologist": 1 },
  "41143": { "Cosmetologist": 1 },
  "33640": { "Cosmetologist": 1 },
  "60612": { "Cosmetologist": 3 },
  "39823": { "Cosmetologist": 1 },
  "43906": { "Cosmetologist": 1 },
  "34733": { "Cosmetologist": 2 },
  "31114": { "Cosmetologist": 1 },
  "36445": { "Cosmetologist": 1 },
  "92106": { "Cosmetologist": 3 },
  "35120": { "Cosmetologist": 1 },
  "55075": { "Cosmetologist": 1 },
  "94597": { "Cosmetologist": 2 },
  "42714": { "Cosmetologist": 1 },
  "90061": { "Cosmetologist": 1 },
  "48801": { "Cosmetologist": 80, "Barber": 13 },
  "31568": { "Cosmetologist": 2 },
  "36539": { "Cosmetologist": 1 },
  "34177": { "Cosmetologist": 1 },
  "93002": { "Cosmetologist": 1 },
  "91791": { "Cosmetologist": 1 },
  "95210": { "Cosmetologist": 1 },
  "53083": { "Cosmetologist": 1 },
  "61104": { "Cosmetologist": 1 },
  "39440": { "Cosmetologist": 1 },
  "14081": { "Cosmetologist": 1 },
  "32142": { "Cosmetologist": 2 },
  "70065": { "Cosmetologist": 4 },
  "93013": { "Cosmetologist": 1 },
  "40744": { "Cosmetologist": 1 },
  "24347": { "Cosmetologist": 1 },
  "43269": { "Cosmetologist": 1 },
  "95212": { "Cosmetologist": 1 },
  "10706": { "Cosmetologist": 1 },
  "60521": { "Cosmetologist": 2 },
  "43619": { "Cosmetologist": 1 },
  "31719": { "Cosmetologist": 1 },
  "44045": { "Cosmetologist": 1 },
  "32212": { "Cosmetologist": 2 },
  "10505": { "Cosmetologist": 1 },
  "29115": { "Cosmetologist": 1 },
  "90746": { "Cosmetologist": 1 },
  "11580": { "Cosmetologist": 2 },
  "48875": { "Cosmetologist": 93, "Barber": 4 },
  "23526": { "Cosmetologist": 1 },
  "33989": { "Cosmetologist": 1 },
  "57287": { "Cosmetologist": 1 },
  "56468": { "Cosmetologist": 1 },
  "48883": { "Cosmetologist": 61, "Barber": 1 },
  "45640": { "Cosmetologist": 1 },
  "40222": { "Cosmetologist": 1 },
  "48066": { "Cosmetologist": 296, "Barber": 20 },
  "14051": { "Cosmetologist": 3 },
  "45248": { "Cosmetologist": 1 },
  "49253": { "Cosmetologist": 26 },
  "34058": { "Cosmetologist": 1 },
  "14085": { "Cosmetologist": 1 },
  "37782": { "Cosmetologist": 1 },
  "33124": { "Cosmetologist": 2 },
  "21531": { "Cosmetologist": 1 },
  "29123": { "Cosmetologist": 1 },
  "35905": { "Cosmetologist": 1 },
  "22556": { "Cosmetologist": 2 },
  "34774": { "Cosmetologist": 3 },
  "48054": { "Cosmetologist": 74, "Barber": 3 },
  "11697": { "Cosmetologist": 1 },
  "10550": { "Cosmetologist": 1 },
  "14011": { "Cosmetologist": 1 },
  "53012": { "Cosmetologist": 1 },
  "40211": { "Cosmetologist": 3 },
  "14502": { "Cosmetologist": 1 },
  "10708": { "Cosmetologist": 1 },
  "19126": { "Cosmetologist": 1 },
  "70820": { "Cosmetologist": 1 },
  "62933": { "Cosmetologist": 1 },
  "43964": { "Cosmetologist": 1 },
  "11702": { "Cosmetologist": 1 },
  "55425": { "Cosmetologist": 1 },
  "60081": { "Cosmetologist": 2 },
  "32770": { "Cosmetologist": 1 },
  "11733": { "Cosmetologist": 1 },
  "35045": { "Cosmetologist": 2 },
  "48313": { "Cosmetologist": 314, "Barber": 14 },
  "60136": { "Cosmetologist": 1 },
  "52084": { "Cosmetologist": 1 },
  "33384": { "Cosmetologist": 1 },
  "33999": { "Cosmetologist": 1 },
  "83704": { "Cosmetologist": 3 },
  "14211": { "Cosmetologist": 1 },
  "11766": { "Cosmetologist": 2 },
  "36693": { "Cosmetologist": 3 },
  "11935": { "Cosmetologist": 1 },
  "55920": { "Cosmetologist": 1 },
  "60090": { "Cosmetologist": 3 },
  "44030": { "Cosmetologist": 1 },
  "43074": { "Cosmetologist": 1 },
  "11721": { "Cosmetologist": 1 },
  "14605": { "Cosmetologist": 1 },
  "94954": { "Cosmetologist": 2 },
  "36545": { "Cosmetologist": 1 },
  "35654": { "Cosmetologist": 1 },
  "55429": { "Cosmetologist": 3 },
  "35234": { "Cosmetologist": 1 },
  "49707": { "Cosmetologist": 171, "Barber": 3 },
  "30244": { "Cosmetologist": 1 },
  "62712": { "Cosmetologist": 2 },
  "60621": { "Cosmetologist": 1 },
  "13502": { "Cosmetologist": 1 },
  "49456": { "Cosmetologist": 142, "Barber": 3 },
  "31142": { "Cosmetologist": 1 },
  "48302": { "Cosmetologist": 73, "Barber": 2 },
  "48228": { "Cosmetologist": 131, "Barber": 36 },
  "11545": { "Cosmetologist": 1 },
  "18343": { "Cosmetologist": 1 },
  "61111": { "Cosmetologist": 1 },
  "90715": { "Cosmetologist": 2 },
  "34257": { "Cosmetologist": 1 },
  "34343": { "Cosmetologist": 1 },
  "23294": { "Cosmetologist": 1 },
  "34663": { "Cosmetologist": 1 },
  "10041": { "Cosmetologist": 1 },
  "12449": { "Cosmetologist": 1 },
  "87532": { "Cosmetologist": 1 },
  "92589": { "Cosmetologist": 1 },
  "33663": { "Cosmetologist": 1 },
  "12302": { "Cosmetologist": 1 },
  "39296": { "Cosmetologist": 2 },
  "11769": { "Cosmetologist": 1 },
  "38809": { "Cosmetologist": 1 },
  "32268": { "Cosmetologist": 1 },
  "40511": { "Cosmetologist": 1 },
  "31779": { "Cosmetologist": 1 },
  "34897": { "Cosmetologist": 1 },
  "53765": { "Cosmetologist": 1 },
  "55109": { "Cosmetologist": 1 },
  "33396": { "Cosmetologist": 1 },
  "38829": { "Cosmetologist": 1 },
  "83440": { "Cosmetologist": 2 },
  "35248": { "Cosmetologist": 1 },
  "33105": { "Cosmetologist": 1 },
  "32313": { "Cosmetologist": 1 },
  "37759": { "Cosmetologist": 1 },
  "14622": { "Cosmetologist": 1 },
  "34161": { "Cosmetologist": 1 },
  "33450": { "Cosmetologist": 2 },
  "93311": { "Cosmetologist": 1 },
  "33959": { "Cosmetologist": 1 },
  "34776": { "Cosmetologist": 1 },
  "35171": { "Cosmetologist": 1 },
  "14174": { "Cosmetologist": 1 },
  "32740": { "Cosmetologist": 1 },
  "32276": { "Cosmetologist": 1 },
  "39350": { "Cosmetologist": 1 },
  "31546": { "Cosmetologist": 1 },
  "11762": { "Cosmetologist": 1, "Barber": 1 },
  "60957": { "Cosmetologist": 1 },
  "23226": { "Cosmetologist": 1 },
  "12205": { "Cosmetologist": 2 },
  "49007": { "Cosmetologist": 47, "Barber": 8 },
  "47197": { "Cosmetologist": 1 },
  "11942": { "Cosmetologist": 1 },
  "60108": { "Cosmetologist": 1 },
  "11542": { "Cosmetologist": 2 },
  "36320": { "Cosmetologist": 1 },
  "11348": { "Cosmetologist": 2 },
  "25635": { "Cosmetologist": 1 },
  "33038": { "Cosmetologist": 1 },
  "29224": { "Cosmetologist": 1 },
  "32910": { "Cosmetologist": 1 },
  "13040": { "Cosmetologist": 1 },
  "53235": { "Cosmetologist": 1 },
  "60069": { "Cosmetologist": 1 },
  "33822": { "Cosmetologist": 1 },
  "10598": { "Cosmetologist": 3 },
  "90803": { "Cosmetologist": 2 },
  "34582": { "Cosmetologist": 1 },
  "36498": { "Cosmetologist": 1 },
  "10302": { "Cosmetologist": 1 },
  "49690": { "Cosmetologist": 53 },
  "32354": { "Cosmetologist": 1 },
  "95159": { "Cosmetologist": 1 },
  "90280": { "Cosmetologist": 1 },
  "90604": { "Cosmetologist": 1 },
  "33226": { "Cosmetologist": 1 },
  "34283": { "Cosmetologist": 1 },
  "15124": { "Cosmetologist": 1 },
  "53210": { "Cosmetologist": 1 },
  "95356": { "Cosmetologist": 1 },
  "44451": { "Cosmetologist": 1 },
  "20737": { "Cosmetologist": 1 },
  "86301": { "Cosmetologist": 3 },
  "10310": { "Cosmetologist": 1 },
  "71957": { "Cosmetologist": 1 },
  "53097": { "Cosmetologist": 2 },
  "33379": { "Cosmetologist": 1 },
  "11797": { "Cosmetologist": 1 },
  "10562": { "Cosmetologist": 1 },
  "30186": { "Cosmetologist": 1 },
  "26041": { "Cosmetologist": 1 },
  "34579": { "Cosmetologist": 1 },
  "32973": { "Cosmetologist": 1 },
  "92625": { "Cosmetologist": 1 },
  "95148": { "Cosmetologist": 2 },
  "21767": { "Cosmetologist": 1 },
  "32983": { "Cosmetologist": 1 },
  "91752": { "Cosmetologist": 1 },
  "61301": { "Cosmetologist": 2 },
  "48640": { "Cosmetologist": 159, "Barber": 2 },
  "32192": { "Cosmetologist": 1 },
  "13617": { "Cosmetologist": 1 },
  "44847": { "Cosmetologist": 1 },
  "34468": { "Cosmetologist": 1 },
  "91436": { "Cosmetologist": 1 },
  "12752": { "Cosmetologist": 1 },
  "45213": { "Cosmetologist": 1 },
  "60714": { "Cosmetologist": 1 },
  "98371": { "Cosmetologist": 1 },
  "95914": { "Cosmetologist": 1 },
  "48227": { "Cosmetologist": 116, "Barber": 32 },
  "14219": { "Cosmetologist": 2 },
  "10303": { "Cosmetologist": 1 },
  "24574": { "Cosmetologist": 1 },
  "13089": { "Cosmetologist": 1 },
  "48324": { "Cosmetologist": 104, "Barber": 5 },
  "48706": { "Cosmetologist": 329, "Barber": 9 },
  "49047": { "Cosmetologist": 82, "Barber": 3 },
  "19804": { "Cosmetologist": 1 },
  "85385": { "Cosmetologist": 1 },
  "32161": { "Cosmetologist": 1 },
  "24572": { "Cosmetologist": 1 },
  "48342": { "Cosmetologist": 56, "Barber": 10 },
  "31536": { "Cosmetologist": 2 },
  "72124": { "Cosmetologist": 1 },
  "90713": { "Cosmetologist": 1 },
  "49705": { "Cosmetologist": 5 },
  "98272": { "Cosmetologist": 1 },
  "48207": { "Cosmetologist": 59, "Barber": 8 },
  "33661": { "Cosmetologist": 1 },
  "32972": { "Cosmetologist": 1 },
  "48186": { "Cosmetologist": 199, "Barber": 8 },
  "21050": { "Cosmetologist": 2 },
  "22660": { "Cosmetologist": 1 },
  "60119": { "Cosmetologist": 2 },
  "12144": { "Cosmetologist": 1 },
  "33079": { "Cosmetologist": 1 },
  "44626": { "Cosmetologist": 1 },
  "71104": { "Cosmetologist": 1 },
  "35167": { "Cosmetologist": 1 },
  "32045": { "Cosmetologist": 1 },
  "93550": { "Cosmetologist": 2 },
  "33293": { "Cosmetologist": 1 },
  "35545": { "Cosmetologist": 1 },
  "12043": { "Cosmetologist": 1 },
  "60172": { "Cosmetologist": 1 },
  "13159": { "Cosmetologist": 1 },
  "31563": { "Cosmetologist": 1 },
  "95608": { "Cosmetologist": 1 },
  "92553": { "Cosmetologist": 1 },
  "14514": { "Cosmetologist": 1 },
  "22802": { "Cosmetologist": 3 },
  "96792": { "Cosmetologist": 2 },
  "40583": { "Cosmetologist": 1 },
  "60184": { "Cosmetologist": 1 },
  "54669": { "Cosmetologist": 1 },
  "48116": { "Cosmetologist": 176, "Barber": 12 },
  "23504": { "Cosmetologist": 1 },
  "25260": { "Cosmetologist": 1 },
  "11951": { "Cosmetologist": 1 },
  "90293": { "Cosmetologist": 1 },
  "32419": { "Cosmetologist": 1 },
  "14901": { "Cosmetologist": 1 },
  "10038": { "Cosmetologist": 2 },
  "30222": { "Cosmetologist": 1 },
  "14830": { "Cosmetologist": 1 },
  "34555": { "Cosmetologist": 1 },
  "89434": { "Cosmetologist": 1 },
  "21911": { "Cosmetologist": 2 },
  "45771": { "Cosmetologist": 1 },
  "41139": { "Cosmetologist": 2 },
  "41169": { "Cosmetologist": 1 },
  "33924": { "Cosmetologist": 1 },
  "14086": { "Cosmetologist": 1 },
  "77305": { "Cosmetologist": 1 },
  "98226": { "Cosmetologist": 2 },
  "60446": { "Cosmetologist": 2 },
  "33490": { "Cosmetologist": 1 },
  "95360": { "Cosmetologist": 2 },
  "20833": { "Cosmetologist": 1 },
  "44688": { "Cosmetologist": 1 },
  "33787": { "Cosmetologist": 1 },
  "33099": { "Cosmetologist": 1 },
  "91710": { "Cosmetologist": 1 },
  "84720": { "Cosmetologist": 1 },
  "33208": { "Cosmetologist": 1 },
  "53705": { "Cosmetologist": 1 },
  "43286": { "Cosmetologist": 1 },
  "45327": { "Cosmetologist": 1 },
  "48131": { "Cosmetologist": 67 },
  "61525": { "Cosmetologist": 1 },
  "91423": { "Cosmetologist": 1 },
  "45628": { "Cosmetologist": 1 },
  "72903": { "Cosmetologist": 1 },
  "38828": { "Cosmetologist": 1 },
  "32889": { "Cosmetologist": 1 },
  "48888": { "Cosmetologist": 42, "Barber": 1 },
  "34174": { "Cosmetologist": 1 },
  "33270": { "Cosmetologist": 1 },
  "48334": { "Cosmetologist": 93, "Barber": 11 },
  "35068": { "Cosmetologist": 1 },
  "35624": { "Cosmetologist": 1 },
  "33268": { "Cosmetologist": 1 },
  "33007": { "Cosmetologist": 1 },
  "33589": { "Cosmetologist": 1 },
  "39104": { "Cosmetologist": 1 },
  "49093": { "Cosmetologist": 118, "Barber": 4 },
  "32525": { "Cosmetologist": 1 },
  "62806": { "Cosmetologist": 1 },
  "10927": { "Cosmetologist": 1 },
  "34765": { "Cosmetologist": 1 },
  "35660": { "Cosmetologist": 1 },
  "48316": { "Cosmetologist": 308, "Barber": 10 },
  "41005": { "Cosmetologist": 2 },
  "48026": { "Cosmetologist": 104, "Barber": 9 },
  "44010": { "Cosmetologist": 1 },
  "44811": { "Cosmetologist": 1 },
  "92054": { "Cosmetologist": 1 },
  "95005": { "Cosmetologist": 1 },
  "15104": { "Cosmetologist": 2 },
  "32194": { "Cosmetologist": 1 },
  "32661": { "Cosmetologist": 1 },
  "45879": { "Cosmetologist": 1 },
  "74145": { "Cosmetologist": 1 },
  "43112": { "Cosmetologist": 1 },
  "35610": { "Cosmetologist": 1 },
  "48114": { "Cosmetologist": 168, "Barber": 3 },
  "48040": { "Cosmetologist": 115, "Barber": 2 },
  "12118": { "Cosmetologist": 2 },
  "84145": { "Cosmetologist": 44 },
  "12188": { "Cosmetologist": 1 },
  "14561": { "Cosmetologist": 1 },
  "12790": { "Cosmetologist": 2 },
  "32782": { "Cosmetologist": 1 },
  "53222": { "Cosmetologist": 2 },
  "60516": { "Cosmetologist": 1 },
  "34412": { "Cosmetologist": 1 },
  "38668": { "Cosmetologist": 1 },
  "39567": { "Cosmetologist": 1 },
  "49637": { "Cosmetologist": 33 },
  "60629": { "Cosmetologist": 1 },
  "32026": { "Cosmetologist": 1 },
  "34977": { "Cosmetologist": 1 },
  "43952": { "Cosmetologist": 1 },
  "55746": { "Cosmetologist": 1 },
  "02857": { "Cosmetologist": 1 },
  "24135": { "Cosmetologist": 1 },
  "71040": { "Cosmetologist": 1 },
  "92587": { "Cosmetologist": 1 },
  "14617": { "Cosmetologist": 1 },
  "48505": { "Cosmetologist": 72, "Barber": 11 },
  "39187": { "Cosmetologist": 1 },
  "49740": { "Cosmetologist": 39, "Barber": 2 },
  "60442": { "Cosmetologist": 1 },
  "70811": { "Cosmetologist": 1 },
  "14059": { "Cosmetologist": 1 },
  "33984": { "Cosmetologist": 1 },
  "33216": { "Cosmetologist": 1 },
  "53018": { "Cosmetologist": 1 },
  "11552": { "Cosmetologist": 1 },
  "36605": { "Cosmetologist": 1 },
  "32929": { "Cosmetologist": 1 },
  "73008": { "Cosmetologist": 1 },
  "49009": { "Cosmetologist": 246, "Barber": 4 },
  "95320": { "Cosmetologist": 1 },
  "42566": { "Cosmetologist": 1 },
  "62540": { "Cosmetologist": 1 },
  "33249": { "Cosmetologist": 1 },
  "12456": { "Cosmetologist": 1 },
  "12525": { "Cosmetologist": 1 },
  "94104": { "Cosmetologist": 3 },
  "85615": { "Cosmetologist": 1 },
  "98390": { "Cosmetologist": 1 },
  "13102": { "Cosmetologist": 1 },
  "14422": { "Cosmetologist": 1 },
  "33279": { "Cosmetologist": 1 },
  "23439": { "Cosmetologist": 1 },
  "32121": { "Cosmetologist": 1 },
  "53527": { "Cosmetologist": 1 },
  "33642": { "Cosmetologist": 1 },
  "98445": { "Cosmetologist": 1 },
  "71742": { "Cosmetologist": 1 },
  "58482": { "Cosmetologist": 1 },
  "98444": { "Cosmetologist": 1 },
  "14770": { "Cosmetologist": 1 },
  "60191": { "Cosmetologist": 1 },
  "33641": { "Cosmetologist": 1 },
  "46197": { "Cosmetologist": 1 },
  "18972": { "Cosmetologist": 1 },
  "60035": { "Cosmetologist": 1 },
  "88426": { "Cosmetologist": 1 },
  "73117": { "Cosmetologist": 1 },
  "56501": { "Cosmetologist": 1 },
  "45662": { "Cosmetologist": 1 },
  "56732": { "Cosmetologist": 1 },
  "33103": { "Cosmetologist": 1 },
  "34792": { "Cosmetologist": 1 },
  "95382": { "Cosmetologist": 1 },
  "14212": { "Cosmetologist": 1 },
  "34634": { "Cosmetologist": 1 },
  "41041": { "Cosmetologist": 1 },
  "55917": { "Cosmetologist": 1 },
  "91401": { "Cosmetologist": 1 },
  "32697": { "Cosmetologist": 1 },
  "90505": { "Cosmetologist": 2 },
  "92808": { "Cosmetologist": 1 },
  "97045": { "Cosmetologist": 11 },
  "53005": { "Cosmetologist": 2 },
  "60123": { "Cosmetologist": 2 },
  "32574": { "Cosmetologist": 1 },
  "35176": { "Cosmetologist": 1 },
  "38804": { "Cosmetologist": 1 },
  "95207": { "Cosmetologist": 1 },
  "26471": { "Cosmetologist": 1 },
  "98837": { "Cosmetologist": 1 },
  "44047": { "Cosmetologist": 1 },
  "14226": { "Cosmetologist": 1 },
  "10595": { "Cosmetologist": 1 },
  "95949": { "Cosmetologist": 1 },
  "44441": { "Cosmetologist": 1 },
  "11791": { "Cosmetologist": 2 },
  "48176": { "Cosmetologist": 140, "Barber": 2 },
  "11040": { "Cosmetologist": 1 },
  "10925": { "Cosmetologist": 1 },
  "94538": { "Cosmetologist": 1 },
  "60187": { "Cosmetologist": 1 },
  "33997": { "Cosmetologist": 1 },
  "54950": { "Cosmetologist": 1 },
  "11703": { "Cosmetologist": 1 },
  "43938": { "Cosmetologist": 1 },
  "99011": { "Cosmetologist": 1 },
  "60002": { "Cosmetologist": 2 },
  "43345": { "Cosmetologist": 1 },
  "55616": { "Cosmetologist": 1 },
  "42041": { "Cosmetologist": 1 },
  "35475": { "Cosmetologist": 1 },
  "49650": { "Barber": 1, "Cosmetologist": 28 },
  "49008": { "Barber": 6, "Cosmetologist": 66 },
  "48128": { "Barber": 10, "Cosmetologist": 77 },
  "48212": { "Barber": 21, "Cosmetologist": 51 },
  "48413": { "Barber": 3, "Cosmetologist": 72 },
  "49246": { "Barber": 3, "Cosmetologist": 44 },
  "48041": { "Barber": 1, "Cosmetologist": 45 },
  "49230": { "Barber": 10, "Cosmetologist": 103 },
  "48219": { "Barber": 33, "Cosmetologist": 170 },
  "48140": { "Barber": 2, "Cosmetologist": 34 },
  "48884": { "Barber": 1, "Cosmetologist": 27 },
  "48048": { "Barber": 5, "Cosmetologist": 68 },
  "48216": { "Barber": 3, "Cosmetologist": 14 },
  "48211": { "Barber": 2, "Cosmetologist": 8 },
  "48607": { "Barber": 4, "Cosmetologist": 1 },
  "48192": { "Barber": 9, "Cosmetologist": 190 },
  "48120": { "Barber": 6, "Cosmetologist": 18 },
  "48910": { "Barber": 17, "Cosmetologist": 203 },
  "49519": { "Barber": 2, "Cosmetologist": 202 },
  "49836": { "Barber": 1, "Cosmetologist": 4 },
  "49277": { "Barber": 2, "Cosmetologist": 24 },
  "48340": { "Barber": 9, "Cosmetologist": 88 },
  "48238": { "Barber": 23, "Cosmetologist": 57 },
  "49264": { "Barber": 5, "Cosmetologist": 8 },
  "48504": { "Barber": 30, "Cosmetologist": 94 },
  "49629": { "Barber": 1, "Cosmetologist": 18 },
  "49505": { "Barber": 7, "Cosmetologist": 224 },
  "48221": { "Barber": 24, "Cosmetologist": 155 },
  "48202": { "Barber": 7, "Cosmetologist": 47 },
  "48122": { "Barber": 3, "Cosmetologist": 40 },
  "41623": { "Barber": 1 },
  "48101": { "Barber": 15, "Cosmetologist": 227 },
  "49601": { "Barber": 3, "Cosmetologist": 125 },
  "48519": { "Barber": 4, "Cosmetologist": 58 },
  "49752": { "Barber": 1, "Cosmetologist": 2 },
  "49688": { "Barber": 1, "Cosmetologist": 15 },
  "48375": { "Barber": 4, "Cosmetologist": 112 },
  "48638": { "Barber": 8, "Cosmetologist": 89 },
  "48393": { "Barber": 9, "Cosmetologist": 110 },
  "48209": { "Barber": 4, "Cosmetologist": 33 },
  "49338": { "Barber": 1, "Cosmetologist": 16 },
  "49002": { "Barber": 4, "Cosmetologist": 131 },
  "48822": { "Barber": 5, "Cosmetologist": 22 },
  "48203": { "Barber": 12, "Cosmetologist": 65 },
  "48134": { "Barber": 10, "Cosmetologist": 186 },
  "48835": { "Barber": 2, "Cosmetologist": 36 },
  "48453": { "Barber": 2, "Cosmetologist": 33 },
  "48854": { "Barber": 11, "Cosmetologist": 146 },
  "48734": { "Barber": 2, "Cosmetologist": 71 },
  "48446": { "Barber": 6, "Cosmetologist": 295 },
  "48015": { "Barber": 4, "Cosmetologist": 52 },
  "48879": { "Barber": 20, "Cosmetologist": 162 },
  "49346": { "Barber": 1, "Cosmetologist": 38 },
  "48165": { "Barber": 3, "Cosmetologist": 57 },
  "48377": { "Barber": 3, "Cosmetologist": 84 },
  "49080": { "Barber": 1, "Cosmetologist": 122 },
  "48206": { "Barber": 10, "Cosmetologist": 43 },
  "48415": { "Barber": 4, "Cosmetologist": 71 },
  "49332": { "Barber": 3, "Cosmetologist": 14 },
  "48886": { "Barber": 2, "Cosmetologist": 13 },
  "49083": { "Barber": 3, "Cosmetologist": 43 },
  "48915": { "Barber": 4, "Cosmetologist": 45 },
  "48223": { "Barber": 13, "Cosmetologist": 73 },
  "49759": { "Barber": 1, "Cosmetologist": 8 },
  "49765": { "Barber": 1, "Cosmetologist": 22 },
  "48529": { "Barber": 6, "Cosmetologist": 45 },
  "48412": { "Barber": 4, "Cosmetologist": 62 },
  "48062": { "Barber": 2, "Cosmetologist": 85 },
  "49229": { "Barber": 2, "Cosmetologist": 24 },
  "49938": { "Barber": 1, "Cosmetologist": 35 },
  "48329": { "Barber": 19, "Cosmetologist": 217 },
  "48890": { "Barber": 4, "Cosmetologist": 16 },
  "48872": { "Barber": 6, "Cosmetologist": 43 },
  "48757": { "Barber": 3, "Cosmetologist": 52 },
  "48848": { "Barber": 4, "Cosmetologist": 71 },
  "48098": { "Barber": 4, "Cosmetologist": 104 },
  "48624": { "Barber": 7, "Cosmetologist": 92 },
  "49428": { "Barber": 2, "Cosmetologist": 284 },
  "48662": { "Barber": 1, "Cosmetologist": 10 },
  "48150": { "Barber": 7, "Cosmetologist": 206 },
  "48626": { "Barber": 2, "Cosmetologist": 53 },
  "48651": { "Barber": 3, "Cosmetologist": 39 },
  "48708": { "Barber": 6, "Cosmetologist": 183 },
  "48164": { "Barber": 3, "Cosmetologist": 93 },
  "48501": { "Barber": 1, "Cosmetologist": 4 },
  "49090": { "Barber": 5, "Cosmetologist": 70 },
  "49112": { "Barber": 1, "Cosmetologist": 23 },
  "49326": { "Barber": 1, "Cosmetologist": 28 },
  "48821": { "Barber": 4, "Cosmetologist": 38 },
  "48746": { "Barber": 5, "Cosmetologist": 65 },
  "49331": { "Barber": 6, "Cosmetologist": 137 },
  "48813": { "Barber": 10, "Cosmetologist": 121 },
  "48732": { "Barber": 3, "Cosmetologist": 84 },
  "48125": { "Barber": 10, "Cosmetologist": 126 },
  "48622": { "Barber": 3, "Cosmetologist": 56 },
  "48866": { "Barber": 1, "Cosmetologist": 29 },
  "48240": { "Barber": 17, "Cosmetologist": 98 },
  "48654": { "Barber": 1, "Cosmetologist": 24 },
  "49097": { "Barber": 4, "Cosmetologist": 113 },
  "48343": { "Barber": 1, "Cosmetologist": 2 },
  "48616": { "Barber": 6, "Cosmetologist": 57 },
  "49058": { "Barber": 6, "Cosmetologist": 104 },
  "48426": { "Barber": 1, "Cosmetologist": 3 },
  "48095": { "Barber": 2, "Cosmetologist": 72 },
  "48067": { "Barber": 8, "Cosmetologist": 129 },
  "48160": { "Barber": 2, "Cosmetologist": 105 },
  "48836": { "Barber": 7, "Cosmetologist": 105 },
  "48182": { "Barber": 5, "Cosmetologist": 119 },
  "49106": { "Barber": 2, "Cosmetologist": 39 },
  "48462": { "Barber": 2, "Cosmetologist": 140 },
  "48429": { "Barber": 8, "Cosmetologist": 75 },
  "49544": { "Barber": 2, "Cosmetologist": 79 },
  "48509": { "Barber": 12, "Cosmetologist": 90 },
  "49868": { "Barber": 1, "Cosmetologist": 22 },
  "48030": { "Barber": 5, "Cosmetologist": 97 },
  "49504": { "Barber": 7, "Cosmetologist": 238 },
  "48204": { "Barber": 12, "Cosmetologist": 50 },
  "49016": { "Barber": 2, "Cosmetologist": 1 },
  "49064": { "Barber": 3, "Cosmetologist": 21 },
  "49415": { "Barber": 3, "Cosmetologist": 67 },
  "49534": { "Barber": 4, "Cosmetologist": 216 },
  "48649": { "Barber": 2, "Cosmetologist": 16 },
  "48450": { "Barber": 2, "Cosmetologist": 44 },
  "49022": { "Barber": 9, "Cosmetologist": 113 },
  "48739": { "Barber": 2, "Cosmetologist": 18 },
  "48210": { "Barber": 4, "Cosmetologist": 32 },
  "48856": { "Barber": 2, "Cosmetologist": 6 },
  "49437": { "Barber": 3, "Cosmetologist": 46 },
  "44606": { "Barber": 1 },
  "49615": { "Barber": 1, "Cosmetologist": 27 },
  "48229": { "Barber": 3, "Cosmetologist": 18 },
  "48104": { "Barber": 5, "Cosmetologist": 47 },
  "48003": { "Barber": 2, "Cosmetologist": 64 },
  "49622": { "Barber": 2, "Cosmetologist": 29 },
  "43061": { "Barber": 1, "Cosmetologist": 1 },
  "49065": { "Barber": 1, "Cosmetologist": 53 },
  "49858": { "Barber": 3, "Cosmetologist": 56 },
  "49468": { "Barber": 1, "Cosmetologist": 2 },
  "48612": { "Barber": 1, "Cosmetologist": 43 },
  "48117": { "Barber": 2, "Cosmetologist": 81 },
  "48362": { "Barber": 5, "Cosmetologist": 132 },
  "45226": { "Barber": 1 },
  "48871": { "Barber": 1, "Cosmetologist": 16 },
  "48208": { "Barber": 1, "Cosmetologist": 22 },
  "48348": { "Barber": 2, "Cosmetologist": 178 },
  "49727": { "Barber": 1, "Cosmetologist": 34 },
  "49454": { "Barber": 1, "Cosmetologist": 24 },
  "48895": { "Barber": 3, "Cosmetologist": 76 },
  "49060": { "Barber": 1, "Cosmetologist": 8 },
  "48817": { "Barber": 2, "Cosmetologist": 44 },
  "54121": { "Barber": 1, "Cosmetologist": 9 },
  "49099": { "Barber": 1, "Cosmetologist": 19 },
  "48658": { "Barber": 1, "Cosmetologist": 23 },
  "49781": { "Barber": 2, "Cosmetologist": 21 },
  "49905": { "Barber": 1, "Cosmetologist": 8 },
  "49010": { "Barber": 2, "Cosmetologist": 120 },
  "49610": { "Barber": 1, "Cosmetologist": 2 },
  "49038": { "Barber": 3, "Cosmetologist": 70 },
  "48768": { "Barber": 5, "Cosmetologist": 62 },
  "48840": { "Barber": 9, "Cosmetologist": 83 },
  "48014": { "Barber": 2, "Cosmetologist": 30 },
  "48006": { "Barber": 2, "Cosmetologist": 28 },
  "48894": { "Barber": 1, "Cosmetologist": 25 },
  "48435": { "Barber": 1, "Cosmetologist": 13 },
  "48461": { "Barber": 1, "Cosmetologist": 62 },
  "48445": { "Barber": 1, "Cosmetologist": 17 },
  "49435": { "Barber": 1, "Cosmetologist": 46 },
  "48851": { "Barber": 2, "Cosmetologist": 17 },
  "48037": { "Barber": 1, "Cosmetologist": 5 },
  "49001": { "Barber": 7, "Cosmetologist": 89 },
  "48418": { "Barber": 6, "Cosmetologist": 38 },
  "49013": { "Barber": 2, "Cosmetologist": 23 },
  "48845": { "Barber": 2, "Cosmetologist": 7 },
  "48376": { "Barber": 1, "Cosmetologist": 3 },
  "49404": { "Barber": 2, "Cosmetologist": 83 },
  "49777": { "Barber": 1, "Cosmetologist": 7 },
  "48050": { "Barber": 1, "Cosmetologist": 13 },
  "48105": { "Barber": 1, "Cosmetologist": 54 },
  "49749": { "Barber": 1, "Cosmetologist": 27 },
  "49665": { "Barber": 1, "Cosmetologist": 23 },
  "48455": { "Barber": 2, "Cosmetologist": 83 },
  "49649": { "Barber": 3, "Cosmetologist": 47 },
  "48335": { "Barber": 3, "Cosmetologist": 88 },
  "49228": { "Barber": 3, "Cosmetologist": 25 },
  "49319": { "Barber": 2, "Cosmetologist": 157 },
  "97024": { "Barber": 1, "Cosmetologist": 1 },
  "48230": { "Barber": 1, "Cosmetologist": 76 },
  "48215": { "Barber": 4, "Cosmetologist": 33 },
  "49817": { "Barber": 1, "Cosmetologist": 1 },
  "48610": { "Barber": 1, "Cosmetologist": 20 },
  "48742": { "Barber": 2, "Cosmetologist": 17 },
  "49738": { "Barber": 2, "Cosmetologist": 61 },
  "49635": { "Barber": 1, "Cosmetologist": 20 },
  "48627": { "Barber": 1, "Cosmetologist": 4 },
  "49614": { "Barber": 1, "Cosmetologist": 19 },
  "49057": { "Barber": 1, "Cosmetologist": 30 },
  "48158": { "Barber": 3, "Cosmetologist": 62 },
  "48705": { "Barber": 2 },
  "48069": { "Barber": 1, "Cosmetologist": 13 },
  "48656": { "Barber": 2, "Cosmetologist": 19 },
  "48159": { "Barber": 1, "Cosmetologist": 24 },
  "49348": { "Barber": 1, "Cosmetologist": 146 },
  "49645": { "Barber": 1, "Cosmetologist": 10 },
  "03894": { "Barber": 1 },
  "49285": { "Barber": 1, "Cosmetologist": 45 },
  "99503": { "Barber": 1 },
  "48471": { "Barber": 1, "Cosmetologist": 31 },
  "49791": { "Barber": 1, "Cosmetologist": 6 },
  "49078": { "Barber": 3, "Cosmetologist": 67 },
  "48427": { "Barber": 1, "Cosmetologist": 22 },
  "49682": { "Barber": 3, "Cosmetologist": 24 },
  "46616": { "Barber": 1, "Cosmetologist": 2 },
  "48436": { "Barber": 1, "Cosmetologist": 39 },
  "49072": { "Barber": 1, "Cosmetologist": 24 },
  "48832": { "Barber": 2, "Cosmetologist": 12 },
  "49421": { "Barber": 1, "Cosmetologist": 34 },
  "49330": { "Barber": 1, "Cosmetologist": 49 },
  "49233": { "Barber": 1, "Cosmetologist": 29 },
  "48857": { "Barber": 5, "Cosmetologist": 26 },
  "48137": { "Barber": 3, "Cosmetologist": 40 },
  "49718": { "Barber": 1, "Cosmetologist": 2 },
  "49719": { "Barber": 1, "Cosmetologist": 7 },
  "48846": { "Barber": 3, "Cosmetologist": 98 },
  "49451": { "Barber": 1, "Cosmetologist": 55 },
  "60443": { "Barber": 1 },
  "48933": { "Barber": 2, "Cosmetologist": 9 },
  "46540": { "Barber": 1, "Cosmetologist": 1 },
  "49045": { "Barber": 3, "Cosmetologist": 31 },
  "85335": { "Barber": 1, "Cosmetologist": 3 },
  "48809": { "Barber": 1, "Cosmetologist": 82 },
  "49422": { "Barber": 1, "Cosmetologist": 2 },
  "48244": { "Barber": 1, "Cosmetologist": 2 },
  "49655": { "Barber": 1, "Cosmetologist": 17 },
  "48723": { "Barber": 1, "Cosmetologist": 63 },
  "49651": { "Barber": 2, "Cosmetologist": 53 },
  "49887": { "Barber": 1, "Cosmetologist": 6 },
  "49301": { "Barber": 3, "Cosmetologist": 99 },
  "48437": { "Barber": 1, "Cosmetologist": 3 },
  "48072": { "Barber": 3, "Cosmetologist": 103 },
  "49406": { "Barber": 1, "Cosmetologist": 14 },
  "49657": { "Barber": 1, "Cosmetologist": 36 },
  "99324": { "Barber": 1 },
  "49913": { "Barber": 1, "Cosmetologist": 23 },
  "49676": { "Barber": 1, "Cosmetologist": 29 },
  "48381": { "Barber": 3, "Cosmetologist": 117 },
  "48655": { "Barber": 4, "Cosmetologist": 42 },
  "49448": { "Barber": 1, "Cosmetologist": 34 },
  "49876": { "Barber": 1, "Cosmetologist": 8 },
  "49347": { "Barber": 1, "Cosmetologist": 10 },
  "49012": { "Barber": 2, "Cosmetologist": 20 },
  "48456": { "Barber": 2, "Cosmetologist": 5 },
  "48064": { "Barber": 2, "Cosmetologist": 45 },
  "48881": { "Barber": 3, "Cosmetologist": 51 },
  "49079": { "Barber": 5, "Cosmetologist": 126 },
  "48770": { "Barber": 1, "Cosmetologist": 11 },
  "48611": { "Barber": 2, "Cosmetologist": 55 },
  "49266": { "Barber": 3, "Cosmetologist": 18 },
  "48847": { "Barber": 2, "Cosmetologist": 40 },
  "48005": { "Barber": 3, "Cosmetologist": 62 },
  "49670": { "Barber": 1, "Cosmetologist": 7 },
  "49021": { "Barber": 1, "Cosmetologist": 33 },
  "48422": { "Barber": 1, "Cosmetologist": 42 },
  "49403": { "Barber": 1, "Cosmetologist": 30 },
  "48893": { "Barber": 1, "Cosmetologist": 31 },
  "48367": { "Barber": 1, "Cosmetologist": 35 },
  "49663": { "Barber": 1, "Cosmetologist": 29 },
  "49838": { "Barber": 1, "Cosmetologist": 1 },
  "48320": { "Barber": 3, "Cosmetologist": 50 },
  "49329": { "Barber": 1, "Cosmetologist": 67 },
  "49946": { "Barber": 1, "Cosmetologist": 20 },
  "49335": { "Barber": 2, "Cosmetologist": 1 },
  "48632": { "Barber": 3, "Cosmetologist": 27 },
  "48138": { "Barber": 3, "Cosmetologist": 69 },
  "49046": { "Barber": 1, "Cosmetologist": 42 },
  "49339": { "Barber": 1, "Cosmetologist": 20 },
  "49762": { "Barber": 1, "Cosmetologist": 4 },
  "49344": { "Barber": 3, "Cosmetologist": 34 },
  "49247": { "Barber": 2, "Cosmetologist": 38 },
  "49621": { "Barber": 1, "Cosmetologist": 22 },
  "92009": { "Barber": 1 },
  "49125": { "Barber": 2, "Cosmetologist": 9 },
  "48090": { "Barber": 1 },
  "48838": { "Barber": 3, "Cosmetologist": 143 },
  "49241": { "Barber": 1, "Cosmetologist": 16 },
  "48370": { "Barber": 1, "Cosmetologist": 10 },
  "49102": { "Barber": 1, "Cosmetologist": 9 },
  "49746": { "Barber": 2, "Cosmetologist": 22 },
  "41164": { "Barber": 1 },
  "49270": { "Barber": 1, "Cosmetologist": 51 },
  "48118": { "Barber": 2, "Cosmetologist": 82 },
  "60487": { "Barber": 1, "Cosmetologist": 1 },
  "45160": { "Barber": 1 },
  "48025": { "Barber": 1, "Cosmetologist": 55 },
  "48145": { "Barber": 1, "Cosmetologist": 24 },
  "48361": { "Barber": 1, "Cosmetologist": 2 },
  "49849": { "Barber": 2, "Cosmetologist": 87 },
  "48623": { "Barber": 1, "Cosmetologist": 111 },
  "49458": { "Barber": 1 },
  "49802": { "Barber": 1, "Cosmetologist": 33 },
  "49935": { "Barber": 1, "Cosmetologist": 28 },
  "48049": { "Barber": 1, "Cosmetologist": 40 },
  "49782": { "Barber": 1, "Cosmetologist": 4 },
  "49455": { "Barber": 1, "Cosmetologist": 19 },
  "48414": { "Barber": 3, "Cosmetologist": 17 },
  "49240": { "Barber": 3, "Cosmetologist": 71 },
  "48897": { "Barber": 2, "Cosmetologist": 16 },
  "48428": { "Barber": 1, "Cosmetologist": 61 },
  "48463": { "Barber": 1, "Cosmetologist": 32 },
  "48465": { "Barber": 1, "Cosmetologist": 5 },
  "48855": { "Barber": 2, "Cosmetologist": 143 },
  "48615": { "Barber": 1, "Cosmetologist": 20 },
  "48744": { "Barber": 1, "Cosmetologist": 32 },
  "48731": { "Barber": 2, "Cosmetologist": 7 },
  "48614": { "Barber": 1, "Cosmetologist": 9 },
  "49076": { "Barber": 2, "Cosmetologist": 21 },
  "48476": { "Barber": 1, "Cosmetologist": 3 },
  "48634": { "Barber": 1, "Cosmetologist": 29 },
  "49053": { "Barber": 1, "Cosmetologist": 43 },
  "49337": { "Barber": 1, "Cosmetologist": 85 },
  "71449": { "Barber": 1 },
  "48759": { "Barber": 1, "Cosmetologist": 27 },
  "49916": { "Barber": 1, "Cosmetologist": 7 },
  "49646": { "Barber": 2, "Cosmetologist": 54 },
  "48002": { "Barber": 1, "Cosmetologist": 31 },
  "48311": { "Barber": 1, "Cosmetologist": 2 },
  "49111": { "Barber": 1, "Cosmetologist": 22 },
  "49309": { "Barber": 1, "Cosmetologist": 2 },
  "48725": { "Barber": 1, "Cosmetologist": 18 },
  "48217": { "Barber": 1, "Cosmetologist": 16 },
  "49306": { "Barber": 1, "Cosmetologist": 106 },
  "49310": { "Barber": 1, "Cosmetologist": 14 },
  "49227": { "Barber": 1, "Cosmetologist": 7 },
  "49616": { "Barber": 1, "Cosmetologist": 19 },
  "49030": { "Barber": 2, "Cosmetologist": 15 },
  "48885": { "Barber": 1, "Cosmetologist": 8 },
  "48657": { "Barber": 1, "Cosmetologist": 48 },
  "48218": { "Barber": 1, "Cosmetologist": 18 },
  "48416": { "Barber": 1, "Cosmetologist": 45 },
  "48756": { "Barber": 1, "Cosmetologist": 21 },
  "48449": { "Barber": 1, "Cosmetologist": 30 },
  "48850": { "Barber": 1, "Cosmetologist": 29 },
  "48865": { "Barber": 1, "Cosmetologist": 8 },
  "49094": { "Barber": 2, "Cosmetologist": 20 },
  "49052": { "Barber": 1, "Cosmetologist": 2 },
  "48046": { "Barber": 1 },
  "49242": { "Barber": 1, "Cosmetologist": 82 },
  "49425": { "Barber": 1, "Cosmetologist": 15 },
  "48737": { "Barber": 1, "Cosmetologist": 9 },
  "49042": { "Barber": 1, "Cosmetologist": 25 },
  "49274": { "Barber": 1, "Cosmetologist": 23 },
  "48063": { "Barber": 1, "Cosmetologist": 36 },
  "25506": { "Barber": 1 },
  "49251": { "Barber": 1, "Cosmetologist": 48 },
  "85548": { "Barber": 1 },
  "49333": { "Barber": 1, "Cosmetologist": 117 },
  "49342": { "Barber": 1, "Cosmetologist": 11 },
  "49234": { "Barber": 1, "Cosmetologist": 26 },
  "61607": { "Barber": 1 },
  "54534": { "Cosmetologist": 10 },
  "49776": { "Cosmetologist": 16 },
  "48829": { "Cosmetologist": 22 },
  "49460": { "Cosmetologist": 76 },
  "48726": { "Cosmetologist": 37 },
  "48417": { "Cosmetologist": 21 },
  "49734": { "Cosmetologist": 9 },
  "48766": { "Cosmetologist": 7 },
  "49323": { "Cosmetologist": 118 },
  "49318": { "Cosmetologist": 18 },
  "49032": { "Cosmetologist": 15 },
  "49620": { "Cosmetologist": 30 },
  "49259": { "Cosmetologist": 24 },
  "49284": { "Cosmetologist": 26 },
  "49668": { "Cosmetologist": 25 },
  "49840": { "Cosmetologist": 4 },
  "49287": { "Cosmetologist": 11 },
  "48892": { "Cosmetologist": 37 },
  "49236": { "Cosmetologist": 29 },
  "74080": { "Cosmetologist": 1 },
  "32476": { "Cosmetologist": 1 },
  "49755": { "Cosmetologist": 7 },
  "48454": { "Cosmetologist": 12 },
  "48023": { "Cosmetologist": 41 },
  "48432": { "Cosmetologist": 11 },
  "49677": { "Cosmetologist": 48 },
  "49401": { "Cosmetologist": 166 },
  "49780": { "Cosmetologist": 12 },
  "60110": { "Cosmetologist": 1 },
  "49837": { "Cosmetologist": 83 },
  "49948": { "Cosmetologist": 7 },
  "49841": { "Cosmetologist": 45 },
  "49927": { "Cosmetologist": 5 },
  "49753": { "Cosmetologist": 12 },
  "49293": { "Cosmetologist": 1 },
  "09603": { "Cosmetologist": 1 },
  "48720": { "Cosmetologist": 8 },
  "49328": { "Cosmetologist": 42 },
  "95818": { "Cosmetologist": 1 },
  "49633": { "Cosmetologist": 29 },
  "84054": { "Cosmetologist": 1 },
  "48738": { "Cosmetologist": 8 },
  "48325": { "Cosmetologist": 4 },
  "49088": { "Cosmetologist": 35 },
  "48419": { "Cosmetologist": 15 },
  "49891": { "Cosmetologist": 6 },
  "49630": { "Cosmetologist": 9 },
  "49756": { "Cosmetologist": 17 },
  "48733": { "Cosmetologist": 14 },
  "49092": { "Cosmetologist": 12 },
  "49245": { "Cosmetologist": 34 },
  "98201": { "Cosmetologist": 1 },
  "39456": { "Cosmetologist": 1 },
  "49026": { "Cosmetologist": 15 },
  "48012": { "Cosmetologist": 9 },
  "49303": { "Cosmetologist": 12 },
  "49870": { "Cosmetologist": 17 },
  "49653": { "Cosmetologist": 19 },
  "49419": { "Cosmetologist": 106 },
  "48613": { "Cosmetologist": 6 },
  "48878": { "Cosmetologist": 12 },
  "96148": { "Cosmetologist": 1 },
  "49715": { "Cosmetologist": 13 },
  "49343": { "Cosmetologist": 52 },
  "54208": { "Cosmetologist": 1 },
  "54125": { "Cosmetologist": 1 },
  "45750": { "Cosmetologist": 1 },
  "48531": { "Cosmetologist": 1 },
  "49911": { "Cosmetologist": 19 },
  "53218": { "Cosmetologist": 2 },
  "49726": { "Cosmetologist": 4 },
  "48818": { "Cosmetologist": 13 },
  "71226": { "Cosmetologist": 1 },
  "49237": { "Cosmetologist": 24 },
  "49894": { "Cosmetologist": 8 },
  "48806": { "Cosmetologist": 8 },
  "49871": { "Cosmetologist": 3 },
  "49659": { "Cosmetologist": 28 },
  "06037": { "Cosmetologist": 1 },
  "49866": { "Cosmetologist": 60 },
  "37315": { "Cosmetologist": 1 },
  "49955": { "Cosmetologist": 4 },
  "44839": { "Cosmetologist": 1 },
  "49033": { "Cosmetologist": 14 },
  "49410": { "Cosmetologist": 10 },
  "49250": { "Cosmetologist": 30 },
  "49709": { "Cosmetologist": 20 },
  "48767": { "Cosmetologist": 14 },
  "49664": { "Cosmetologist": 16 },
  "49835": { "Cosmetologist": 2 },
  "48876": { "Cosmetologist": 30 },
  "48421": { "Cosmetologist": 55 },
  "48860": { "Cosmetologist": 8 },
  "49070": { "Cosmetologist": 21 },
  "49639": { "Cosmetologist": 16 },
  "49034": { "Cosmetologist": 19 },
  "48502": { "Cosmetologist": 1 },
  "48652": { "Cosmetologist": 12 },
  "49689": { "Cosmetologist": 4 },
  "49302": { "Cosmetologist": 67 },
  "49256": { "Cosmetologist": 26 },
  "49096": { "Cosmetologist": 10 },
  "49619": { "Cosmetologist": 7 },
  "02653": { "Cosmetologist": 1 },
  "55736": { "Cosmetologist": 1 },
  "72022": { "Cosmetologist": 1 },
  "49680": { "Cosmetologist": 14 },
  "49327": { "Cosmetologist": 68 },
  "48630": { "Cosmetologist": 3 },
  "48637": { "Cosmetologist": 27 },
  "42207": { "Cosmetologist": 1 },
  "46365": { "Cosmetologist": 2 },
  "49892": { "Cosmetologist": 15 },
  "48741": { "Cosmetologist": 10 },
  "48475": { "Cosmetologist": 28 },
  "49271": { "Cosmetologist": 9 },
  "48831": { "Cosmetologist": 40 },
  "49885": { "Cosmetologist": 12 },
  "49724": { "Cosmetologist": 11 },
  "49325": { "Cosmetologist": 25 },
  "49743": { "Cosmetologist": 4 },
  "48815": { "Cosmetologist": 17 },
  "49440": { "Cosmetologist": 8 },
  "48853": { "Cosmetologist": 8 },
  "49050": { "Cosmetologist": 9 },
  "49269": { "Cosmetologist": 44 },
  "91784": { "Cosmetologist": 1 },
  "60551": { "Cosmetologist": 1 },
  "49056": { "Cosmetologist": 23 },
  "49774": { "Cosmetologist": 11 },
  "49967": { "Cosmetologist": 3 },
  "58630": { "Cosmetologist": 1 },
  "54143": { "Cosmetologist": 23 },
  "48022": { "Cosmetologist": 31 },
  "49880": { "Cosmetologist": 9 },
  "49818": { "Cosmetologist": 6 },
  "48004": { "Cosmetologist": 1 },
  "48729": { "Cosmetologist": 9 },
  "30510": { "Cosmetologist": 1 },
  "48472": { "Cosmetologist": 10 },
  "49255": { "Cosmetologist": 6 },
  "53150": { "Cosmetologist": 1 },
  "10006": { "Cosmetologist": 1 },
  "48834": { "Cosmetologist": 18 },
  "49618": { "Cosmetologist": 9 },
  "49807": { "Cosmetologist": 38 },
  "49267": { "Cosmetologist": 12 },
  "94603": { "Cosmetologist": 1 },
  "48747": { "Cosmetologist": 12 },
  "49081": { "Cosmetologist": 5 },
  "49861": { "Cosmetologist": 1 },
  "49730": { "Cosmetologist": 10 },
  "49968": { "Cosmetologist": 11 },
  "49744": { "Cosmetologist": 5 },
  "48318": { "Cosmetologist": 2 },
  "48133": { "Cosmetologist": 23 },
  "48440": { "Cosmetologist": 1 },
  "49729": { "Cosmetologist": 8 },
  "48811": { "Cosmetologist": 13 },
  "53065": { "Cosmetologist": 1 },
  "48873": { "Cosmetologist": 12 },
  "42544": { "Cosmetologist": 1 },
  "49340": { "Cosmetologist": 19 },
  "49204": { "Cosmetologist": 5 },
  "49766": { "Cosmetologist": 13 },
  "49815": { "Cosmetologist": 4 },
  "91350": { "Cosmetologist": 2 },
  "49795": { "Cosmetologist": 11 },
  "49066": { "Cosmetologist": 4 },
  "49827": { "Cosmetologist": 3 },
  "48631": { "Cosmetologist": 34 },
  "49027": { "Cosmetologist": 2 },
  "48891": { "Cosmetologist": 12 },
  "48619": { "Cosmetologist": 2 },
  "92308": { "Cosmetologist": 1 },
  "10589": { "Cosmetologist": 1 },
  "49628": { "Cosmetologist": 3 },
  "49279": { "Cosmetologist": 11 },
  "55066": { "Cosmetologist": 1 },
  "49446": { "Cosmetologist": 16 },
  "62418": { "Cosmetologist": 1 },
  "49262": { "Cosmetologist": 7 },
  "48112": { "Cosmetologist": 7 },
  "49128": { "Cosmetologist": 19 },
  "49613": { "Cosmetologist": 6 },
  "48761": { "Cosmetologist": 7 },
  "48701": { "Cosmetologist": 12 },
  "48628": { "Cosmetologist": 21 },
  "12148": { "Cosmetologist": 1 },
  "44890": { "Cosmetologist": 2 },
  "48659": { "Cosmetologist": 20 },
  "49896": { "Cosmetologist": 8 },
  "49312": { "Cosmetologist": 1 },
  "01564": { "Cosmetologist": 1 },
  "49733": { "Cosmetologist": 11 },
  "71068": { "Cosmetologist": 1 },
  "49314": { "Cosmetologist": 1 },
  "48468": { "Cosmetologist": 6 },
  "43567": { "Cosmetologist": 4 },
  "49077": { "Cosmetologist": 1 },
  "48861": { "Cosmetologist": 15 },
  "40854": { "Cosmetologist": 1 },
  "49825": { "Cosmetologist": 3 },
  "49235": { "Cosmetologist": 15 },
  "93401": { "Cosmetologist": 1 },
  "48305": { "Cosmetologist": 1 },
  "99654": { "Cosmetologist": 3 },
  "48743": { "Cosmetologist": 1 },
  "48466": { "Cosmetologist": 13 },
  "49061": { "Cosmetologist": 11 },
  "48745": { "Cosmetologist": 8 },
  "48740": { "Cosmetologist": 16 },
  "35634": { "Cosmetologist": 1 },
  "37930": { "Cosmetologist": 1 },
  "49879": { "Cosmetologist": 6 },
  "49322": { "Cosmetologist": 11 },
  "48469": { "Cosmetologist": 14 },
  "49872": { "Cosmetologist": 2 },
  "40505": { "Cosmetologist": 1 },
  "49067": { "Cosmetologist": 23 },
  "48464": { "Cosmetologist": 10 },
  "49625": { "Cosmetologist": 4 },
  "62401": { "Cosmetologist": 1 },
  "99674": { "Cosmetologist": 2 },
  "49452": { "Cosmetologist": 7 },
  "49436": { "Cosmetologist": 8 },
  "93636": { "Cosmetologist": 1 },
  "48013": { "Cosmetologist": 1 },
  "48764": { "Cosmetologist": 2 },
  "49853": { "Cosmetologist": 2 },
  "49683": { "Cosmetologist": 8 },
  "20667": { "Cosmetologist": 1 },
  "49631": { "Cosmetologist": 28 },
  "48754": { "Cosmetologist": 5 },
  "49598": { "Cosmetologist": 1 },
  "22654": { "Cosmetologist": 1 },
  "89301": { "Cosmetologist": 1 },
  "49745": { "Cosmetologist": 1 },
  "49031": { "Cosmetologist": 27 },
  "43554": { "Cosmetologist": 4 },
  "48727": { "Cosmetologist": 9 },
  "59919": { "Cosmetologist": 1 },
  "48099": { "Cosmetologist": 4 },
  "48703": { "Cosmetologist": 18 },
  "48070": { "Cosmetologist": 19 },
  "49336": { "Cosmetologist": 22 },
  "49011": { "Cosmetologist": 16 },
  "84088": { "Cosmetologist": 1 },
  "49632": { "Cosmetologist": 11 },
  "48032": { "Cosmetologist": 18 },
  "49679": { "Cosmetologist": 6 },
  "48807": { "Cosmetologist": 4 },
  "49272": { "Cosmetologist": 20 },
  "49969": { "Cosmetologist": 1 },
  "74567": { "Cosmetologist": 1 },
  "49377": { "Cosmetologist": 1 },
  "47806": { "Cosmetologist": 1 },
  "48605": { "Cosmetologist": 3 },
  "49713": { "Cosmetologist": 10 },
  "48333": { "Cosmetologist": 1 },
  "89408": { "Cosmetologist": 1 },
  "48762": { "Cosmetologist": 7 },
  "55369": { "Cosmetologist": 1 },
  "94960": { "Cosmetologist": 1 },
  "48027": { "Cosmetologist": 32 },
  "49626": { "Cosmetologist": 3 },
  "49252": { "Cosmetologist": 18 },
  "46553": { "Cosmetologist": 1 },
  "61883": { "Cosmetologist": 1 },
  "22546": { "Cosmetologist": 2 },
  "49656": { "Cosmetologist": 6 },
  "49814": { "Cosmetologist": 10 },
  "49113": { "Cosmetologist": 6 },
  "49895": { "Cosmetologist": 5 },
  "22610": { "Cosmetologist": 1 },
  "54891": { "Cosmetologist": 2 },
  "49873": { "Cosmetologist": 1 },
  "49518": { "Cosmetologist": 4 },
  "49710": { "Cosmetologist": 3 },
  "54550": { "Cosmetologist": 5 },
  "49238": { "Cosmetologist": 18 },
  "49831": { "Cosmetologist": 2 },
  "95128": { "Cosmetologist": 1 },
  "94403": { "Cosmetologist": 1 },
  "49915": { "Cosmetologist": 3 },
  "98338": { "Cosmetologist": 1 },
  "40033": { "Cosmetologist": 2 },
  "14214": { "Cosmetologist": 1 },
  "49667": { "Cosmetologist": 3 },
  "48870": { "Cosmetologist": 2 },
  "85236": { "Cosmetologist": 1 },
  "49725": { "Cosmetologist": 4 },
  "48366": { "Cosmetologist": 3 },
  "60605": { "Cosmetologist": 2 },
  "84526": { "Cosmetologist": 1 },
  "38303": { "Cosmetologist": 1 },
  "49788": { "Cosmetologist": 4 },
  "48816": { "Cosmetologist": 1 },
  "48621": { "Cosmetologist": 7 },
  "49963": { "Cosmetologist": 5 },
  "49812": { "Cosmetologist": 9 },
  "48909": { "Cosmetologist": 3 },
  "49232": { "Cosmetologist": 6 },
  "74032": { "Cosmetologist": 1 },
  "49126": { "Cosmetologist": 6 },
  "48068": { "Cosmetologist": 4 },
  "49695": { "Cosmetologist": 1 },
  "49089": { "Cosmetologist": 9 },
  "54728": { "Cosmetologist": 1 },
  "49701": { "Cosmetologist": 6 },
  "98110": { "Cosmetologist": 1 },
  "49261": { "Cosmetologist": 2 },
  "53188": { "Cosmetologist": 1 },
  "24301": { "Cosmetologist": 1 },
  "06824": { "Cosmetologist": 1 },
  "49028": { "Cosmetologist": 31 },
  "48345": { "Cosmetologist": 1 },
  "54003": { "Cosmetologist": 1 },
  "70647": { "Cosmetologist": 1 },
  "48635": { "Cosmetologist": 8 },
  "49902": { "Cosmetologist": 1 },
  "54547": { "Cosmetologist": 2 },
  "43001": { "Cosmetologist": 1 },
  "48282": { "Cosmetologist": 1 },
  "49834": { "Cosmetologist": 1 },
  "48874": { "Cosmetologist": 2 },
  "36783": { "Cosmetologist": 1 },
  "92878": { "Cosmetologist": 1 },
  "43521": { "Cosmetologist": 4 },
  "89415": { "Cosmetologist": 1 },
  "54901": { "Cosmetologist": 2 },
  "72644": { "Cosmetologist": 1 },
  "49722": { "Cosmetologist": 1 },
  "49757": { "Cosmetologist": 4 },
  "49305": { "Cosmetologist": 10 },
  "49276": { "Cosmetologist": 4 },
  "49847": { "Cosmetologist": 5 },
  "40353": { "Cosmetologist": 1 },
  "94591": { "Cosmetologist": 1 },
  "97048": { "Cosmetologist": 1 },
  "95301": { "Cosmetologist": 1 },
  "49283": { "Cosmetologist": 14 },
  "49443": { "Cosmetologist": 2 },
  "49908": { "Cosmetologist": 7 },
  "54817": { "Cosmetologist": 1 },
  "02703": { "Cosmetologist": 1 },
  "88008": { "Cosmetologist": 1 },
  "62711": { "Cosmetologist": 1 },
  "48721": { "Cosmetologist": 2 },
  "01930": { "Cosmetologist": 1 },
  "49808": { "Cosmetologist": 1 },
  "92241": { "Cosmetologist": 1 },
  "43451": { "Cosmetologist": 1 },
  "44233": { "Cosmetologist": 2 },
  "40390": { "Cosmetologist": 2 },
  "02139": { "Cosmetologist": 1 },
  "45810": { "Cosmetologist": 1 },
  "94582": { "Cosmetologist": 1 },
  "62821": { "Cosmetologist": 1 },
  "40042": { "Cosmetologist": 1 },
  "60940": { "Cosmetologist": 1 },
  "54151": { "Cosmetologist": 12 },
  "48121": { "Cosmetologist": 3 },
  "72346": { "Cosmetologist": 1 },
  "91006": { "Cosmetologist": 1 },
  "45863": { "Cosmetologist": 1 },
  "49095": { "Cosmetologist": 5 },
  "49051": { "Cosmetologist": 22 },
  "13215": { "Cosmetologist": 1 },
  "48136": { "Cosmetologist": 1 },
  "48139": { "Cosmetologist": 3 },
  "49075": { "Cosmetologist": 3 },
  "49912": { "Cosmetologist": 4 },
  "85358": { "Cosmetologist": 1 },
  "48028": { "Cosmetologist": 8 },
  "49958": { "Cosmetologist": 6 },
  "49769": { "Cosmetologist": 10 },
  "48841": { "Cosmetologist": 3 },
  "13116": { "Cosmetologist": 1 },
  "49642": { "Cosmetologist": 2 },
  "58258": { "Cosmetologist": 1 },
  "04736": { "Cosmetologist": 1 },
  "49918": { "Cosmetologist": 1 },
  "49043": { "Cosmetologist": 9 },
  "49881": { "Cosmetologist": 3 },
  "42304": { "Cosmetologist": 1 },
  "96260": { "Cosmetologist": 1 },
  "97078": { "Cosmetologist": 1 },
  "49893": { "Cosmetologist": 8 },
  "48175": { "Cosmetologist": 2 },
  "49130": { "Cosmetologist": 2 },
  "48804": { "Cosmetologist": 1 },
  "35670": { "Cosmetologist": 1 },
  "49612": { "Cosmetologist": 5 },
  "48434": { "Cosmetologist": 2 },
  "48153": { "Cosmetologist": 2 },
  "48760": { "Cosmetologist": 6 },
  "48055": { "Cosmetologist": 1 },
  "49062": { "Cosmetologist": 1 },
  "49747": { "Cosmetologist": 6 },
  "40311": { "Cosmetologist": 1 },
  "93720": { "Cosmetologist": 1 },
  "49638": { "Cosmetologist": 3 },
  "55811": { "Cosmetologist": 1 },
  "85636": { "Cosmetologist": 1 },
  "49411": { "Cosmetologist": 14 },
  "94578": { "Cosmetologist": 1 },
  "49796": { "Cosmetologist": 2 },
  "44044": { "Cosmetologist": 1 },
  "72160": { "Cosmetologist": 1 },
  "49910": { "Cosmetologist": 2 },
  "49104": { "Cosmetologist": 1 },
  "49129": { "Cosmetologist": 1 },
  "43556": { "Cosmetologist": 1 },
  "54501": { "Cosmetologist": 1 },
  "44411": { "Cosmetologist": 1 },
  "97471": { "Cosmetologist": 1 },
  "49623": { "Cosmetologist": 1 },
  "45107": { "Cosmetologist": 1 },
  "01545": { "Cosmetologist": 1 },
  "49288": { "Cosmetologist": 3 },
  "85935": { "Cosmetologist": 1 },
  "97232": { "Cosmetologist": 1 },
  "54538": { "Cosmetologist": 1 },
  "44084": { "Cosmetologist": 1 },
  "54155": { "Cosmetologist": 1 },
  "48470": { "Cosmetologist": 6 },
  "85637": { "Cosmetologist": 1 },
  "21152": { "Cosmetologist": 1 },
  "73701": { "Cosmetologist": 1 },
  "48061": { "Cosmetologist": 2 },
  "73644": { "Cosmetologist": 1 },
  "53946": { "Cosmetologist": 1 },
  "73080": { "Cosmetologist": 1 },
  "58746": { "Cosmetologist": 1 },
  "49934": { "Cosmetologist": 6 },
  "85045": { "Cosmetologist": 1 },
  "01731": { "Cosmetologist": 1 },
  "49627": { "Cosmetologist": 2 },
  "60040": { "Cosmetologist": 1 },
  "49282": { "Cosmetologist": 3 },
  "49716": { "Cosmetologist": 2 },
  "48749": { "Cosmetologist": 6 },
  "86427": { "Cosmetologist": 1 },
  "16502": { "Cosmetologist": 1 },
  "43431": { "Cosmetologist": 1 },
  "48308": { "Cosmetologist": 1 },
  "95327": { "Cosmetologist": 1 },
  "96772": { "Cosmetologist": 1 },
  "29151": { "Cosmetologist": 1 },
  "49760": { "Cosmetologist": 1 },
  "03820": { "Cosmetologist": 1 },
  "95628": { "Cosmetologist": 1 },
  "40055": { "Cosmetologist": 1 },
  "49821": { "Cosmetologist": 6 },
  "91935": { "Cosmetologist": 1 },
  "45322": { "Cosmetologist": 2 },
  "48157": { "Cosmetologist": 4 },
  "49405": { "Cosmetologist": 5 },
  "72212": { "Cosmetologist": 1 },
  "48143": { "Cosmetologist": 2 },
  "49925": { "Cosmetologist": 3 },
  "48347": { "Cosmetologist": 3 },
  "06375": { "Cosmetologist": 1 },
  "55343": { "Cosmetologist": 2 },
  "49816": { "Cosmetologist": 5 },
  "71302": { "Cosmetologist": 1 },
  "49019": { "Cosmetologist": 1 },
  "49248": { "Cosmetologist": 6 },
  "54156": { "Cosmetologist": 1 },
  "48852": { "Cosmetologist": 2 },
  "49029": { "Cosmetologist": 6 },
  "31727": { "Cosmetologist": 1 },
  "49901": { "Cosmetologist": 2 },
  "06095": { "Cosmetologist": 1 },
  "98019": { "Cosmetologist": 1 },
  "05819": { "Cosmetologist": 1 },
  "49555": { "Cosmetologist": 1 },
  "44410": { "Cosmetologist": 1 },
  "30355": { "Cosmetologist": 1 },
  "40220": { "Cosmetologist": 1 },
  "48889": { "Cosmetologist": 6 },
  "99362": { "Cosmetologist": 1 },
  "53589": { "Cosmetologist": 2 },
  "49917": { "Cosmetologist": 2 },
  "54476": { "Cosmetologist": 1 },
  "48190": { "Cosmetologist": 4 },
  "95747": { "Cosmetologist": 1 },
  "60661": { "Cosmetologist": 1 },
  "54311": { "Cosmetologist": 4 },
  "54542": { "Cosmetologist": 1 },
  "98188": { "Cosmetologist": 1 },
  "40734": { "Cosmetologist": 1 },
  "92354": { "Cosmetologist": 1 },
  "49822": { "Cosmetologist": 1 },
  "48303": { "Cosmetologist": 3 },
  "49208": { "Cosmetologist": 1 },
  "38871": { "Cosmetologist": 1 },
  "00820": { "Cosmetologist": 1 },
  "39204": { "Cosmetologist": 1 },
  "09112": { "Cosmetologist": 1 },
  "53402": { "Cosmetologist": 1 },
  "97603": { "Cosmetologist": 1 },
  "48735": { "Cosmetologist": 7 },
  "78720": { "Cosmetologist": 1 },
  "54944": { "Cosmetologist": 1 },
  "38902": { "Cosmetologist": 1 },
  "58758": { "Cosmetologist": 1 },
  "89140": { "Cosmetologist": 1 },
  "03087": { "Cosmetologist": 1 },
  "48480": { "Cosmetologist": 1 },
  "48243": { "Cosmetologist": 1 },
  "39464": { "Cosmetologist": 1 },
  "29924": { "Cosmetologist": 1 },
  "41501": { "Cosmetologist": 1 },
  "91326": { "Cosmetologist": 1 },
  "60016": { "Cosmetologist": 1 },
  "54512": { "Cosmetologist": 2 },
  "49952": { "Cosmetologist": 2 },
  "98221": { "Cosmetologist": 1 },
  "49636": { "Cosmetologist": 1 },
  "25570": { "Cosmetologist": 1 },
  "54102": { "Cosmetologist": 1 },
  "48232": { "Cosmetologist": 1 },
  "48728": { "Cosmetologist": 4 },
  "98278": { "Cosmetologist": 1 },
  "49793": { "Cosmetologist": 1 },
  "49886": { "Cosmetologist": 3 },
  "48332": { "Cosmetologist": 4 },
  "68317": { "Cosmetologist": 1 },
  "72443": { "Cosmetologist": 1 },
  "58401": { "Cosmetologist": 1 },
  "98249": { "Cosmetologist": 1 },
  "60642": { "Cosmetologist": 2 },
  "49950": { "Cosmetologist": 3 },
  "49414": { "Cosmetologist": 1 },
  "40370": { "Cosmetologist": 1 },
  "48406": { "Cosmetologist": 1 },
  "53177": { "Cosmetologist": 1 },
  "22733": { "Cosmetologist": 1 },
  "40844": { "Cosmetologist": 1 },
  "94912": { "Cosmetologist": 1 },
  "92867": { "Cosmetologist": 1 },
  "43547": { "Cosmetologist": 1 },
  "99929": { "Cosmetologist": 1 },
  "49806": { "Cosmetologist": 3 },
  "54115": { "Cosmetologist": 3 },
  "14526": { "Cosmetologist": 1 },
  "54848": { "Cosmetologist": 1 },
  "49473": { "Cosmetologist": 1 },
  "94085": { "Cosmetologist": 1 },
  "72949": { "Cosmetologist": 1 },
  "48683": { "Cosmetologist": 1 },
  "42748": { "Cosmetologist": 1 },
  "98002": { "Cosmetologist": 1 },
  "60615": { "Cosmetologist": 1 },
  "48411": { "Cosmetologist": 2 },
  "49003": { "Cosmetologist": 2 },
  "01843": { "Cosmetologist": 1 },
  "38642": { "Cosmetologist": 1 },
  "91744": { "Cosmetologist": 1 },
  "48636": { "Cosmetologist": 1 },
  "25678": { "Cosmetologist": 1 },
  "29643": { "Cosmetologist": 1 },
  "48765": { "Cosmetologist": 2 },
  "68003": { "Cosmetologist": 1 },
  "01776": { "Cosmetologist": 1 },
  "49666": { "Cosmetologist": 2 },
  "60639": { "Cosmetologist": 1 },
  "90294": { "Cosmetologist": 1 },
  "74107": { "Cosmetologist": 1 },
  "06385": { "Cosmetologist": 1 },
  "49921": { "Cosmetologist": 2 },
  "54568": { "Cosmetologist": 1 },
  "45065": { "Cosmetologist": 1 },
  "48713": { "Cosmetologist": 1 },
  "58532": { "Cosmetologist": 1 },
  "93710": { "Cosmetologist": 1 },
  "84124": { "Cosmetologist": 1 },
  "49226": { "Cosmetologist": 1 },
  "61603": { "Cosmetologist": 1 },
  "53022": { "Cosmetologist": 1 },
  "98021": { "Cosmetologist": 2 },
  "42259": { "Cosmetologist": 1 },
  "43506": { "Cosmetologist": 1 },
  "49922": { "Cosmetologist": 2 },
  "43540": { "Cosmetologist": 1 },
  "49820": { "Cosmetologist": 2 },
  "49268": { "Cosmetologist": 4 },
  "49959": { "Cosmetologist": 3 },
  "45896": { "Cosmetologist": 1 },
  "49654": { "Cosmetologist": 1 },
  "49510": { "Cosmetologist": 1 },
  "24256": { "Cosmetologist": 1 },
  "06820": { "Cosmetologist": 1 },
  "91504": { "Cosmetologist": 1 },
  "95405": { "Cosmetologist": 1 },
  "60008": { "Cosmetologist": 1 },
  "98360": { "Cosmetologist": 1 },
  "98075": { "Cosmetologist": 1 },
  "49311": { "Cosmetologist": 1 },
  "39702": { "Cosmetologist": 1 },
  "53208": { "Cosmetologist": 1 },
  "98168": { "Cosmetologist": 1 },
  "92868": { "Cosmetologist": 1 },
  "91977": { "Cosmetologist": 1 },
  "74467": { "Cosmetologist": 1 },
  "93514": { "Cosmetologist": 1 },
  "48844": { "Cosmetologist": 2 },
  "49947": { "Cosmetologist": 1 },
  "40895": { "Cosmetologist": 1 },
  "10594": { "Cosmetologist": 1 },
  "59457": { "Cosmetologist": 1 },
  "60305": { "Cosmetologist": 1 },
  "02816": { "Cosmetologist": 1 },
  "74964": { "Cosmetologist": 1 },
  "91387": { "Cosmetologist": 1 },
  "49020": { "Cosmetologist": 1 },
  "54531": { "Cosmetologist": 1 },
  "49736": { "Cosmetologist": 1 },
  "90039": { "Cosmetologist": 1 },
  "55311": { "Cosmetologist": 1 },
  "49748": { "Cosmetologist": 1 },
  "49962": { "Cosmetologist": 1 },
  "49501": { "Cosmetologist": 1 },
  "98340": { "Cosmetologist": 1 },
  "48901": { "Cosmetologist": 3 },
  "49281": { "Cosmetologist": 1 },
  "22947": { "Cosmetologist": 1 },
  "95011": { "Cosmetologist": 1 },
  "35218": { "Cosmetologist": 1 },
  "72513": { "Cosmetologist": 1 },
  "43533": { "Cosmetologist": 3 },
  "49154": { "Cosmetologist": 1 },
  "02494": { "Cosmetologist": 1 },
  "49874": { "Cosmetologist": 2 },
  "00802": { "Cosmetologist": 1 },
  "40504": { "Cosmetologist": 1 },
  "87745": { "Cosmetologist": 1 },
  "40856": { "Cosmetologist": 1 },
  "39212": { "Cosmetologist": 2 },
  "44875": { "Cosmetologist": 1 },
  "98335": { "Cosmetologist": 1 },
  "35621": { "Cosmetologist": 1 },
  "12962": { "Cosmetologist": 1 },
  "74015": { "Cosmetologist": 1 },
  "59729": { "Cosmetologist": 1 },
  "60430": { "Cosmetologist": 1 },
  "40307": { "Cosmetologist": 1 },
  "06379": { "Cosmetologist": 1 },
  "62522": { "Cosmetologist": 1 },
  "49674": { "Cosmetologist": 1 },
  "41007": { "Cosmetologist": 1 },
  "15238": { "Cosmetologist": 1 },
  "58785": { "Cosmetologist": 1 },
  "40380": { "Cosmetologist": 1 },
  "92376": { "Cosmetologist": 1 },
  "49532": { "Cosmetologist": 1 },
  "21017": { "Cosmetologist": 1 },
  "56178": { "Cosmetologist": 1 },
  "96809": { "Cosmetologist": 1 },
  "48608": { "Cosmetologist": 1 },
  "49516": { "Cosmetologist": 1 },
  "83340": { "Cosmetologist": 1 },
  "48679": { "Cosmetologist": 1 },
  "85263": { "Cosmetologist": 1 },
  "35611": { "Cosmetologist": 1 },
  "92646": { "Cosmetologist": 1 },
  "49805": { "Cosmetologist": 1 },
  "29691": { "Cosmetologist": 1 },
  "03773": { "Cosmetologist": 1 },
  "62351": { "Cosmetologist": 1 },
  "38560": { "Cosmetologist": 1 },
  "49845": { "Cosmetologist": 1 },
  "56071": { "Cosmetologist": 1 },
  "10913": { "Cosmetologist": 1 },
  "49611": { "Cosmetologist": 2 },
  "91768": { "Cosmetologist": 1 },
  "59730": { "Cosmetologist": 1 },
  "38357": { "Cosmetologist": 1 },
  "23103": { "Cosmetologist": 1 },
  "58759": { "Cosmetologist": 1 },
  "35014": { "Cosmetologist": 1 },
  "72523": { "Cosmetologist": 1 },
  "77342": { "Cosmetologist": 1 },
  "54159": { "Cosmetologist": 1 },
  "59826": { "Cosmetologist": 1 },
  "44641": { "Cosmetologist": 1 },
  "98270": { "Cosmetologist": 1 },
  "54302": { "Cosmetologist": 3 },
  "55046": { "Cosmetologist": 1 },
  "45858": { "Cosmetologist": 1 },
  "40205": { "Cosmetologist": 1 },
  "97214": { "Cosmetologist": 1 },
  "38061": { "Cosmetologist": 1 },
  "49792": { "Cosmetologist": 1 },
  "60304": { "Cosmetologist": 1 },
  "92586": { "Cosmetologist": 1 },
  "16675": { "Cosmetologist": 1 },
  "49970": { "Cosmetologist": 1 },
  "70115": { "Cosmetologist": 1 },
  "40012": { "Cosmetologist": 1 },
  "48177": { "Cosmetologist": 1 },
  "01923": { "Cosmetologist": 1 },
  "62549": { "Cosmetologist": 1 },
  "43543": { "Cosmetologist": 1 },
  "55741": { "Cosmetologist": 1 },
  "06067": { "Cosmetologist": 1 },
  "94965": { "Cosmetologist": 1 },
  "29424": { "Cosmetologist": 1 },
  "48805": { "Cosmetologist": 1 },
  "43570": { "Cosmetologist": 1 },
  "98661": { "Cosmetologist": 1 },
  "06840": { "Cosmetologist": 1 },
  "48107": { "Cosmetologist": 1 },
  "53190": { "Cosmetologist": 1 },
  "46747": { "Cosmetologist": 1 },
  "43460": { "Cosmetologist": 1 },
  "92886": { "Cosmetologist": 1 },
  "40510": { "Cosmetologist": 1 },
  "02081": { "Cosmetologist": 1 },
  "60558": { "Cosmetologist": 1 },
  "84075": { "Cosmetologist": 2 },
  "40360": { "Cosmetologist": 1 },
  "44807": { "Cosmetologist": 1 },
  "55421": { "Cosmetologist": 1 },
  "73072": { "Cosmetologist": 1 },
  "03079": { "Cosmetologist": 1 },
  "02152": { "Cosmetologist": 1 },
  "38923": { "Cosmetologist": 1 },
  "53901": { "Cosmetologist": 1 },
  "83676": { "Cosmetologist": 1 },
  "85271": { "Cosmetologist": 1 },
  "29652": { "Cosmetologist": 1 },
  "06812": { "Cosmetologist": 1 },
  "98902": { "Cosmetologist": 1 },
  "54974": { "Cosmetologist": 1 },
  "90027": { "Cosmetologist": 1 },
  "02601": { "Cosmetologist": 1 },
  "59829": { "Cosmetologist": 1 },
  "06001": { "Cosmetologist": 1 },
  "47230": { "Cosmetologist": 1 },
  "48714": { "Cosmetologist": 1 },
  "35005": { "Cosmetologist": 1 },
  "21210": { "Cosmetologist": 1 },
  "54154": { "Cosmetologist": 1 },
  "61108": { "Cosmetologist": 1 },
  "54161": { "Cosmetologist": 1 },
  "46358": { "Cosmetologist": 1 },
  "54171": { "Cosmetologist": 1 },
  "49429": { "Cosmetologist": 1 },
  "46854": { "Cosmetologist": 1 },
  "35986": { "Cosmetologist": 1 },
  "96737": { "Cosmetologist": 1 },
  "60513": { "Cosmetologist": 1 },
  "41301": { "Cosmetologist": 1 },
  "45887": { "Cosmetologist": 1 },
  "54473": { "Cosmetologist": 1 },
  "54856": { "Cosmetologist": 1 },
  "72661": { "Cosmetologist": 1 },
  "72021": { "Cosmetologist": 1 },
  "84123": { "Cosmetologist": 1 },
  "54004": { "Cosmetologist": 1 },
  "49116": { "Cosmetologist": 1 },
  "54409": { "Cosmetologist": 1 },
  "85544": { "Cosmetologist": 1 },
  "94552": { "Cosmetologist": 1 },
  "92707": { "Cosmetologist": 1 },
  "02632": { "Cosmetologist": 1 },
  "24324": { "Cosmetologist": 1 },
  "92020": { "Cosmetologist": 1 },
  "97080": { "Cosmetologist": 1 },
  "42339": { "Cosmetologist": 1 }
}
