import { create } from 'zustand';
import { persist, subscribeWithSelector } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';

import { MapboxGeoJSONFeature } from 'mapbox-gl';
import type {
  ExtendedBrandResult,
  ExtendedPlacesResult,
} from '../services/poiService';
import usePOIStore from './usePOIStore';

export enum MapStyle {
  Streets = 'streets-v12',
  Satellite = 'satellite-streets-v12',
  Light = 'light-v11',
  Dark = 'dark-v11',
}

type Point = {
  lng: number;
  lat: number;
};

interface MFHState {
  completed: boolean;
  underConstruction: boolean;
}

function getCenterOfUSA(): Point {
  const boundingBox = [-125.0011, 24.9493, -66.9326, 49.5904];
  const lat = (boundingBox[1] + boundingBox[3]) / 2;
  const lng = (boundingBox[0] + boundingBox[2]) / 2;
  return { lat, lng };
}

export type DemographicEntity =
  | {
      type: 'point';
      pos: {
        lat: number;
        lng: number;
      };
    }
  | {
      type: 'territory';
      id: string;
    };

interface DynamicMapStore {
  evaluatedPinId: string | null;
  setEvaluatedPinId: (pinId: string | null) => void;

  evaluatedTerritoryId: string | null;
  setEvaluatedTerritoryId: (territoryId: string | null) => void;

  selectedPinGroup: string | null;
  setSelectedPinGroup: (group: string | null) => void;

  selectedTerritoryGroup: string | null;
  setSelectedTerritoryGroup: (group: string | null) => void;

  zoomLevel: number;
  setZoomLevel: (zoomLevel: number) => void;

  showLayerZoom: number;
  setShowLayerZoom: (showLayerZoom: number) => void;

  lastLocation: Point;
  setLastLocation: (location: Point) => void;

  zipsVisible: boolean;
  toggleZipsVisible: () => void;

  heatmapVisible: boolean;
  toggleHeatmapVisible: () => void;

  trafficVisible: boolean;
  toggleTrafficVisible: () => void;

  zonesVisible: boolean;
  toggleZonesVisible: () => void;

  poisVisible: boolean;
  togglePoisVisible: () => void;

  selectedMFH: MFHState;
  toggleCompletedMFH: () => void;
  toggleUnderConstructionMFH: () => void;

  selectedTracts: string[];
  setSelectedTracts: (tracts: string[]) => void;

  selectedBlockGroups: string[];
  setSelectedBlockGroups: (blockGroups: string[]) => void;

  poisEnabled: boolean;
  togglePoisEnabled: () => void;

  selectedPOIGroups: string[];
  setSelectedPOIGroups: (groupNames: string[]) => void;
  togglePOIGroup: (groupName: string) => void;

  selectedBrands: (ExtendedBrandResult | ExtendedPlacesResult)[];
  toggleBrand: (id: string) => void;

  mfhEnabled: boolean;
  setMFHEnabled: (enabled: boolean) => void;
  toggleMFHEnabled: () => void;

  resetPOIs: () => void;

  mapStyle: MapStyle;
  setMapStyle: (style: MapStyle) => void;

  popupInfo: Point | null;
  setPopupInfo: (info: Point | null) => void;

  evaluatedDemographicEntity: DemographicEntity | null;
  setEvaluatedDemographicEntity: (entity: DemographicEntity | null) => void;

  drawerMenuOpen: boolean;
  setDrawerMenuOpen: (open: boolean) => void;

  selectedCustomPinId: string | null;
  selectCustomPinId: (pinId: string | null) => void;

  selectedTerritoryId: string | null;
  selectTerritoryId: (territoryId: string | null) => void;

  hiddenPinGroups: string[];
  toggleGroupVisibility: (group: string) => void;

  hiddenTerritoryGroups: string[];
  toggleTerritoryGroupVisibility: (group: string) => void;

  territoriesEnabled: boolean;
  toggleTerritoriesEnabled: () => void;

  territoryFillEnabled: boolean;
  toggleTerritoryFillEnabled: () => void;

  clickedPOiFeature: MapboxGeoJSONFeature | null;
  setClickedPOiFeature: (feature: MapboxGeoJSONFeature | null) => void;

  alertMessagePin: string;
  setAlertMessagePin: (message: string) => void;

  alertSeverityPin: string;
  setAlertSeverityPin: (
    severity: 'info' | 'success' | 'warning' | 'error'
  ) => void;

  alertMessageTerritory: string;
  setAlertMessageTerritory: (message: string) => void;

  alertSeverityTerritory: string;
  setAlertSeverityTerritory: (
    severity: 'info' | 'success' | 'warning' | 'error'
  ) => void;

  isEditingTerritory: boolean;
  setIsEditingTerritory: (isEditing: boolean) => void;
}

const useDynamicMapStore = create(
  persist(
    subscribeWithSelector<DynamicMapStore>((set) => ({
      evaluatedPinId: null,
      setEvaluatedPinId: (pinId) => set(() => ({ evaluatedPinId: pinId })),

      evaluatedTerritoryId: null,
      setEvaluatedTerritoryId: (territoryId) =>
        set(() => ({ evaluatedTerritoryId: territoryId })),

      selectedPinGroup: null,
      setSelectedPinGroup: (group) => set(() => ({ selectedPinGroup: group })),

      selectedTerritoryGroup: null,
      setSelectedTerritoryGroup: (group) =>
        set(() => ({ selectedTerritoryGroup: group })),

      zoomLevel: 4.5,
      setZoomLevel: (zoomLevel: number) => set(() => ({ zoomLevel })),

      showLayerZoom: 6,
      setShowLayerZoom: (showLayerZoom: number) =>
        set(() => ({ showLayerZoom })),

      lastLocation: getCenterOfUSA(),
      setLastLocation: (location: Point) =>
        set(() => ({ lastLocation: location })),

      zipsVisible: false,
      toggleZipsVisible: () =>
        set((state) => ({ zipsVisible: !state.zipsVisible })),

      heatmapVisible: false,
      toggleHeatmapVisible: () =>
        set((state) => ({ heatmapVisible: !state.heatmapVisible })),

      trafficVisible: false,
      toggleTrafficVisible: () =>
        set((state) => ({ trafficVisible: !state.trafficVisible })),

      zonesVisible: false,
      toggleZonesVisible: () =>
        set((state) => ({ zonesVisible: !state.zonesVisible })),

      poisVisible: false,
      togglePoisVisible: () =>
        set((state) => ({ poisVisible: !state.poisVisible })),

      selectedMFH: {
        completed: false,
        underConstruction: false,
      },
      toggleCompletedMFH: () =>
        set((state) => ({
          selectedMFH: {
            ...state.selectedMFH,
            completed: !state.selectedMFH.completed,
          },
        })),
      toggleUnderConstructionMFH: () =>
        set((state) => ({
          selectedMFH: {
            ...state.selectedMFH,
            underConstruction: !state.selectedMFH.underConstruction,
          },
        })),

      selectedTracts: [],
      setSelectedTracts: (tracts: string[]) =>
        set(() => ({ selectedTracts: tracts })),

      selectedBlockGroups: [],
      setSelectedBlockGroups: (blockGroups: string[]) =>
        set(() => ({ selectedBlockGroups: blockGroups })),

      poisEnabled: true,
      togglePoisEnabled: () =>
        set((state) => ({ poisEnabled: !state.poisEnabled })),

      selectedPOIGroups: [],
      setSelectedPOIGroups: (groupNames: string[]) =>
        set(() => ({ selectedPOIGroups: groupNames })),

      // Toggles a POI group and all brands from that group on or off
      togglePOIGroup: (groupName: string) =>
        set((state) => {
          if (state.selectedPOIGroups.includes(groupName)) {
            // If the POI group is already selected, unselect it and all matching brands
            const selectedPOIGroups = state.selectedPOIGroups.filter(
              (selected) => selected !== groupName
            );
            const selectedBrands = state.selectedBrands.filter(
              (selected) => selected.group !== groupName
            );
            return { selectedPOIGroups, selectedBrands };
          } else {
            // If the POI group is not selected, select it and all matching brands
            const selectedPOIGroups = [...state.selectedPOIGroups, groupName];
            const poiGroup = usePOIStore
              .getState()
              .poiGroups.find((group) => group.group === groupName);

            const newBrands = [
              ...(poiGroup?.brandResults || []),
              ...(poiGroup?.queryResults || []),
            ];

            const existingBrandIds = state.selectedBrands.map(
              (brand) => brand.id
            );
            const filteredBrands = newBrands.filter(
              (brand) => !existingBrandIds.includes(brand.id)
            );
            const selectedBrands = [...state.selectedBrands, ...filteredBrands];
            return { selectedPOIGroups, selectedBrands };
          }
        }),

      selectedBrands: [],
      toggleBrand: (id: string) =>
        set((state) => {
          const brandOrQuery = usePOIStore
            .getState()
            .poiGroups.flatMap((group) => [
              ...group.brandResults,
              ...group.queryResults,
            ])
            .find((brandOrQuery) => brandOrQuery.id === id);

          if (brandOrQuery == null) return {};

          const selectedBrands = state.selectedBrands.some(
            (selected) => selected.id === id
          )
            ? state.selectedBrands.filter((b) => b.id !== id)
            : [...state.selectedBrands, brandOrQuery];

          return { selectedBrands };
        }),

      mfhEnabled: false,
      setMFHEnabled: (enabled: boolean) => set(() => ({ mfhEnabled: enabled })),
      toggleMFHEnabled: () =>
        set((state) =>
          state.mfhEnabled
            ? {
                mfhEnabled: false,
                selectedMFH: { completed: false, underConstruction: false },
              }
            : {
                mfhEnabled: true,
                selectedMFH: { completed: true, underConstruction: true },
              }
        ),

      resetPOIs: () => {
        set(() => ({
          selectedPOIGroups: [],
          selectedBrands: [],
          selectedMFH: { completed: false, underConstruction: false },
          clickedPOiFeature: null,
        }));
      },

      mapStyle: MapStyle.Streets,
      setMapStyle: (style) => set(() => ({ mapStyle: style })),

      popupInfo: null,
      setPopupInfo: (info: any) => set(() => ({ popupInfo: info })),

      drawerMenuOpen: false,
      setDrawerMenuOpen: (open: boolean) =>
        set(() => ({ drawerMenuOpen: open })),

      selectedCustomPinId: null,
      selectCustomPinId: (pinId) => set(() => ({ selectedCustomPinId: pinId })),

      selectedTerritoryId: null,
      selectTerritoryId: (territoryId) =>
        set(() => ({ selectedTerritoryId: territoryId })),

      evaluatedDemographicEntity: null,
      setEvaluatedDemographicEntity: (entity) =>
        set(() => ({ evaluatedDemographicEntity: entity })),

      hiddenPinGroups: [],
      toggleGroupVisibility: (group) =>
        set((prevState) => {
          const newhiddenPinGroups = [...prevState.hiddenPinGroups];
          const index = newhiddenPinGroups.indexOf(group);
          if (index !== -1) {
            newhiddenPinGroups.splice(index, 1);
          } else {
            newhiddenPinGroups.push(group);
          }
          return { hiddenPinGroups: newhiddenPinGroups };
        }),

      hiddenTerritoryGroups: [],
      toggleTerritoryGroupVisibility: (group) =>
        set((prevState) => {
          const newhiddenTerritoryGroups = [...prevState.hiddenTerritoryGroups];
          const index = newhiddenTerritoryGroups.indexOf(group);
          if (index !== -1) {
            newhiddenTerritoryGroups.splice(index, 1);
          } else {
            newhiddenTerritoryGroups.push(group);
          }
          return { hiddenTerritoryGroups: newhiddenTerritoryGroups };
        }),

      clickedPOiFeature: null,
      setClickedPOiFeature: (feature) =>
        set(() => ({ clickedPOiFeature: feature })),

      alertMessagePin: '',
      setAlertMessagePin: (message) =>
        set(() => ({ alertMessagePin: message })),

      alertSeverityPin: 'success',
      setAlertSeverityPin: (severity) =>
        set(() => ({ alertSeverityPin: severity })),

      territoryFillEnabled: true,
      toggleTerritoryFillEnabled: () =>
        set((state) => ({ territoryFillEnabled: !state.territoryFillEnabled })),

      territoriesEnabled: true,
      toggleTerritoriesEnabled: () =>
        set((state) => ({ territoriesEnabled: !state.territoriesEnabled })),

      alertMessageTerritory: '',
      setAlertMessageTerritory: (message) =>
        set(() => ({ alertMessageTerritory: message })),

      alertSeverityTerritory: 'success',
      setAlertSeverityTerritory: (severity) =>
        set(() => ({ alertSeverityTerritory: severity })),

      isEditingTerritory: false,
      setIsEditingTerritory: (isEditing) =>
        set(() => ({ isEditingTerritory: isEditing })),
    })),

    {
      version: 1, // Increment this version when the state shape has a breaking change
      name: 'map-state',
    }
  )
);

// Unhides a territory group when it is selected
useDynamicMapStore.subscribe(
  (state) => state.selectedTerritoryGroup,
  (selectedTerritoryGroup) => {
    useDynamicMapStore.setState((state) => {
      const hiddenTerritoryGroups = state.hiddenTerritoryGroups.filter(
        (group) => group !== selectedTerritoryGroup
      );
      return { hiddenTerritoryGroups };
    });
  },
  { equalityFn: shallow }
);

// Unhides a pin group when it is selected
useDynamicMapStore.subscribe(
  (state) => state.selectedPinGroup,
  (selectedPinGroup) => {
    useDynamicMapStore.setState((state) => {
      const hiddenPinGroups = state.hiddenPinGroups.filter(
        (group) => group !== selectedPinGroup
      );
      return { hiddenPinGroups };
    });
  },
  { equalityFn: shallow }
);

// Updates whether or not the POI group checkbox is selected when the selected brands change
useDynamicMapStore.subscribe(
  (state) => [state.selectedBrands, state.setSelectedPOIGroups] as const,
  ([selectedBrands, setSelectedPOIGroups]) => {
    const brandOrPlaceResults = usePOIStore
      .getState()
      .poiGroups.flatMap((group) => [
        ...group.brandResults,
        ...group.queryResults,
      ]);

    const brandsByGroupName = brandOrPlaceResults.reduce<{
      [groupName: string]: (ExtendedBrandResult | ExtendedPlacesResult)[];
    }>(
      (acc, brand) => ({
        ...acc,
        [brand.group]: [...(acc[brand.group] || []), brand],
      }),
      {}
    );

    const groupBrandsSelected = Object.entries(brandsByGroupName).reduce<
      string[]
    >((acc, [groupName, brands]) => {
      const selectedBrandsInGroup = brands.filter((brand) =>
        selectedBrands.some((selected) => selected.id === brand.id)
      );
      if (selectedBrandsInGroup.length === brands.length) {
        acc.push(groupName);
      }
      return acc;
    }, []);

    setSelectedPOIGroups(groupBrandsSelected);
  },
  { equalityFn: shallow }
);

// Updates whether or not the "MFH" checkbox is selected when the selected MFH types change
useDynamicMapStore.subscribe(
  (state) => [state.selectedMFH, state.setMFHEnabled] as const,
  ([selectedMFH, setMFHEnabled]) => {
    const { completed, underConstruction } = selectedMFH;

    setMFHEnabled(completed && underConstruction);
  },
  { equalityFn: shallow }
);

export default useDynamicMapStore;
