import { MenuOpen as MenuOpenIcon } from '@mui/icons-material';

export default function ToggleDrawerButton({
  toggleDrawer,
  drawerOpen,
}: {
  toggleDrawer: () => void;
  drawerOpen: boolean;
}) {
  const DrawerOpenIcon = () => <MenuOpenIcon />;
  const DrawerCloseIcon = () => (
    <MenuOpenIcon sx={{ transform: 'scaleX(-1)' }} />
  );
  return (
    <div id="menu-icon" onClick={toggleDrawer}>
      {drawerOpen ? <DrawerCloseIcon /> : <DrawerOpenIcon />}
    </div>
  );
}
