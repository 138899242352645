import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { memo, useEffect, useState } from 'react';
import tinycolor from 'tinycolor2';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Slider,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DragIndicator as DragIndicatorIcon,
  Edit as EditIcon,
  MoreVert as MoreVertIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';

import formatDayTime from '~/src/common/helpers/formatDayTime';
import getMatchOpacity from '~/src/features/dynamic-map/helpers/getMatchOpacity';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import useBlockGroupsStore from '../../../../dynamic-map/hooks/useBlockGroupStore';
import useLayersStore from '../../../../dynamic-map/hooks/useLayersStore';
import useTrafficStore from '../../../../dynamic-map/hooks/useTrafficStore';

export function CensusMatchButtonGroup() {
  const blockGroups = useBlockGroupsStore((state) => state.blockGroups);
  const selectedBlockGroups = useDynamicMapStore(
    (state) => state.selectedBlockGroups,
  );
  const setSelectedBlockGroups = useDynamicMapStore(
    (state) => state.setSelectedBlockGroups,
  );

  return (
    <ToggleButtonGroup
      value={selectedBlockGroups}
      onChange={(_, selectedGroups: string[]) => {
        setSelectedBlockGroups(selectedGroups);
      }}
      aria-label="toggle layer groups"
      exclusive={false}
      sx={{ marginRight: 1 }}
    >
      {blockGroups.map((blockGroup) => {
        const backgroundColor = `rgba(54, 4, 194, ${getMatchOpacity(
          blockGroup.groupName,
        )})`;
        const textColor =
          tinycolor(backgroundColor).getLuminance() < 0.6 ? '#fff' : '#000';
        const hoverColor = tinycolor(backgroundColor).darken(10).toHexString();

        return (
          <ToggleButton
            key={blockGroup.groupName}
            value={`block_group_match-${blockGroup.groupName}`}
            sx={{
              backgroundColor: (theme) =>
                theme.palette.action.disabledBackground,
              '&.Mui-selected': {
                backgroundColor,
                color: textColor,
                '&:hover': {
                  backgroundColor: hoverColor,
                },
              },
            }}
          >
            {blockGroup.groupName}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}

interface SortableItemProps {
  index: number;
  onEdit: () => void;
  onDuplicate: () => void;
  onRemove: () => void;
}

const getChipColor = (layerType: string) => {
  if (!layerType || layerType.length === 0)
    return { color: '#CCCCCC', lightness: 60 }; // Default color

  const firstLetter = layerType[0];
  const charCode = firstLetter.charCodeAt(0);
  const hue = (charCode * 137) % 360; // Using a simple multiplier for variation
  const lightness = 60; // Fixed lightness

  return { color: `hsl(${hue}, 50%, ${lightness}%)`, lightness };
};

const SortableItem: React.FC<SortableItemProps> = memo(
  ({ index, onEdit, onRemove }) => {
    const updateLayer = useLayersStore((state) => state.updateLayer);
    const id = useLayersStore((state) => state.layers[index as number].id);
    const type = useLayersStore((state) => state.layers[index as number].type);
    const imageUrl = useLayersStore(
      (state) => state.layers[index as number].imageUrl,
    );
    const opacity = useLayersStore(
      (state) => state.layers[index as number].opacity,
    );
    const displayName = useLayersStore(
      (state) => state.layers[index].displayName,
    );

    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({ id });
    const [hidden, setHidden] = useState<boolean>(false);
    const [sliderValue, setSliderValue] = useState<number>(
      opacity !== null && opacity !== undefined ? opacity * 100 : 100,
    );
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const trafficEnabled = useTrafficStore((state) => state.trafficEnabled);

    const setDayPart = useTrafficStore(
      (state) => state.setTrafficSelectionHour,
    );

    const [useCustomDayTime, setUseCustomDayTime] = useState(false);

    const timeMarks = [...Array(24)].map((_, index) => {
      const hour = index === 0 || index === 12 ? 12 : index % 12;
      const period = index < 12 ? 'AM' : 'PM';
      return { value: index, label: index % 4 === 0 ? `${hour}${period}` : '' };
    });

    //controller conditions
    const blockGroups = useBlockGroupsStore((state) => state.blockGroups);

    const handleLayerMenuClick = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleDelete = () => {
      onRemove();
      handleClose();
    };

    const handleSliderChange = (
      event: React.SyntheticEvent | Event,
      newValue: number | number[],
    ) => {
      const singleValue = Array.isArray(newValue) ? newValue[0] : newValue;
      setSliderValue(singleValue);
    };

    const handleSliderCommit = (
      event: React.SyntheticEvent | Event,
      newValue: number | number[],
    ) => {
      const singleValue = Array.isArray(newValue) ? newValue[0] : newValue;
      const opacityValue = singleValue / 100;
      updateLayer(id, { opacity: opacityValue }); // Update the layer opacity here
    };

    const mediaStyle = {
      width: '80px', // Adjust as needed
      height: '80px', // Adjust as needed
      borderRadius: '4px', // Adjust as needed
      overflow: 'hidden',
    };

    const handleToggleClick = (e: any) => {
      setHidden((prevHidden) => {
        const newHidden = !prevHidden;
        handleSliderChange(e, newHidden ? 0 : 100);
        handleSliderCommit(e, newHidden ? 0 : 100); // Set the slider value based on the newHidden value
        return newHidden;
      });
    };

    useEffect(() => {
      if (sliderValue === 0) {
        setHidden(true);
      } else {
        setHidden(false);
      }
    }, [sliderValue]);

    const { color: chipColor, lightness } = getChipColor(type);
    const textColor = lightness > 60 ? 'black' : 'white';

    useEffect(() => {
      if (useCustomDayTime) {
        setDayPart(12);
      } else {
        setDayPart(-1);
      }
    }, [useCustomDayTime]);

    const TrafficController = () => {
      const trafficSelection = useTrafficStore(
        (state) => state.trafficSelection,
      );
      const setDayType = useTrafficStore(
        (state) => state.setTrafficSelectionDay,
      );
      const setDayPart = useTrafficStore(
        (state) => state.setTrafficSelectionHour,
      );

      const handleSelectChange = (event: { target: { value: any } }) => {
        const isCustomDayTime = !!event.target.value;
        setUseCustomDayTime(isCustomDayTime);
        if (isCustomDayTime) {
          // Set default values when switching to custom day/time
          setDayType(1); // Example: set to Sunday
          setDayPart(12); // Example: set to noon
        } else {
          // Set to average daily traffic
          setDayType(0); // 0 for average of all days
          setDayPart(-1); // -1 for average of all hours
        }
      };

      return (
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            paddingTop: 1,
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Box display="flex" alignItems="center" alignContent="center">
            <Box>
              <Select
                labelId="demo-simple-select-helper-label"
                value={useCustomDayTime ? 1 : 0}
                onChange={handleSelectChange}
              >
                <MenuItem value={0}>Average Daily Traffic</MenuItem>
                <MenuItem value={1}>Custom Day/Time Traffic</MenuItem>
              </Select>
            </Box>
          </Box>
          {trafficEnabled && useCustomDayTime && (
            <Box margin={'1em 0'}>
              {/* ToggleButtonGroup with Max Width */}
              <Box flex="1" pr={1} sx={{ maxWidth: 300 }}>
                {/* Adjust the max-width as per your design */}
                <ToggleButtonGroup
                  aria-label="days of the week"
                  value={trafficSelection.selectedDay}
                  exclusive
                  fullWidth={false} // Prevent full width
                >
                  {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(
                    (day, index) => (
                      <ToggleButton
                        key={day}
                        value={index + 1}
                        color={
                          trafficSelection?.selectedDay === index
                            ? 'primary'
                            : 'secondary'
                        }
                        onClick={() => {
                          setDayType(index + 1);
                        }}
                      >
                        {day}
                      </ToggleButton>
                    ),
                  )}
                </ToggleButtonGroup>
              </Box>
              <Slider
                defaultValue={12}
                step={1}
                marks={timeMarks}
                min={6}
                max={23}
                onChange={(event, newValue) => setDayPart(newValue as number)}
                sx={{ py: 2 }}
              />
              <Box display="flex" justifyContent="space-between">
                <Box flex="1" pl={2}>
                  <div>
                    {formatDayTime(
                      trafficSelection.selectedDay,
                      trafficSelection.selectedHour,
                    )}
                  </div>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      );
    };

    return (
      <Box
        ref={setNodeRef}
        sx={{
          transform: CSS.Transform.toString(transform),
          transition,
        }}
      >
        <Card variant="outlined">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '8px',
              gap: '8px', // Adjust as needed
              flexShrink: 0,
              boxShadow: '0 0 2px rgba(0, 0, 0, 0.25)',
              opacity: isDragging ? 0.25 : 1,
            }}
          >
            {/* Drag Button */}
            <IconButton {...listeners} {...attributes} size="small">
              <DragIndicatorIcon />
            </IconButton>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
              }}
            >
              <Grid
                container
                sx={{
                  flexGrow: 1,
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    position: 'relative',
                    display: 'inline-block',
                  }}
                >
                  <CardMedia
                    sx={{ ...mediaStyle }}
                    component="img"
                    image={
                      imageUrl ||
                      'https://plotr-public.s3.amazonaws.com/assets/images/default_map_layer_preview.png'
                    }
                    alt={displayName}
                  />
                  {index !== undefined && (
                    <Chip
                      label={index + 1}
                      size="small"
                      color="primary"
                      sx={{ position: 'absolute', bottom: 0, left: '-4px' }}
                    />
                  )}
                  {type !== undefined && (
                    <Chip
                      label={type.length ? type[0].toLowerCase() : 'N/A'}
                      size="small"
                      sx={{
                        backgroundColor: chipColor,
                        color: textColor,
                        position: 'absolute',
                        top: 0,
                        left: '-4px',
                      }}
                    />
                  )}
                </Box>
                <Grid item xs>
                  <CardContent
                    sx={{
                      padding: '.5em',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                    }}
                  >
                    <Typography variant="subtitle1">{displayName}</Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        pt: 1,
                      }}
                    >
                      <Tooltip title="Toggle Visibility">
                        <IconButton
                          size="small"
                          onClick={handleToggleClick}
                          // sx={{ margin: '10px' }}
                        >
                          {hidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </Tooltip>
                      <Slider
                        value={sliderValue}
                        min={0}
                        max={100}
                        aria-label="Transparency"
                        size="small"
                        valueLabelDisplay="auto"
                        valueLabelFormat={() => `${sliderValue}%`}
                        onChange={handleSliderChange}
                        onChangeCommitted={handleSliderCommit}
                        sx={{ mx: 2 }}
                      />
                    </Box>
                  </CardContent>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Tooltip title="Edit Layer" placement="top">
                      <IconButton size="small" onClick={onEdit}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={handleLayerMenuClick}
                      size="small"
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleDelete}>Delete Layer</MenuItem>
                    </Menu>
                  </Box>
                </Grid>
              </Grid>
              {id === 'block_group_match' && (
                <Box
                  sx={{
                    display: 'flex',
                    flexGrow: 1,
                    pt: 1,
                    alignItems: 'center',
                  }}
                >
                  <CensusMatchButtonGroup />
                  <Typography component="span">
                    {blockGroups
                      ? 'Block Group Match Grade'
                      : 'Census Tract Match Grade'}
                  </Typography>
                </Box>
              )}
              {id === 'traffic_volume' && <TrafficController />}
            </Box>
          </Box>
        </Card>
      </Box>
    );
  },
);

export default SortableItem;
