export function preventDuplicateItems<T>(key: keyof T) {
  const seen = new Set<T[keyof T]>();

  return (...itemArrays: T[][]) => {
    return itemArrays.flat().filter((item) => {
      const value = item[key];

      if (seen.has(value)) return false;

      seen.add(value);
      return true;
    });
  };
}
