type CallbackTiming = 'before' | 'after';

export function createMethodInterceptor<T extends object>(
  targetObject: T,
  callback: (methodName: string, ...args: unknown[]) => void,
  timing: CallbackTiming = 'before' // Default to 'before' if not specified
): T {
  // Recursive function to apply the interceptor proxy to nested objects
  function applyInterceptor<U extends object>(
    obj: U,
    nestedProperty?: string
  ): U {
    return new Proxy(obj, {
      get(target, property, receiver): unknown {
        const originalValue = Reflect.get(target, property, receiver);

        // If the property is an object and not null, recursively intercept it
        if (
          originalValue != null &&
          typeof originalValue === 'object' &&
          property !== 'constructor'
        ) {
          const prop =
            nestedProperty != null
              ? `${nestedProperty}.${String(property)}`
              : String(property);
          return applyInterceptor(originalValue, prop);
        }

        // Check if the property is a function
        if (typeof originalValue === 'function' && property !== 'constructor') {
          return function (this: T, ...args: unknown[]) {
            const methodPath = nestedProperty
              ? `${nestedProperty}.${String(property)}`
              : String(property);

            // Execute callback before the original function, if specified
            if (timing === 'before') {
              callback(methodPath, ...args);
            }

            // Execute the original function
            const result = originalValue.apply(this, args);

            // Check if the function is asynchronous
            if (result instanceof Promise) {
              // Wait for the promise to resolve and then execute the callback, if specified
              return result.then((resolvedValue: unknown) => {
                if (timing === 'after') {
                  callback(methodPath, ...args);
                }
                return resolvedValue;
              });
            }

            // If the function is not asynchronous, execute callback after the function, if specified
            if (timing === 'after') {
              callback(methodPath, ...args);
            }

            return result;
          };
        }
        return originalValue;
      },
    });
  }

  return applyInterceptor(targetObject);
}
