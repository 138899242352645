import { base64ToBytes, bytesToBase64, isGzipped } from '@plotr/common-utils';
import { MultiplayerData } from '@plotr/multiplayer-data';
import { gunzipSync, gzipSync } from 'fflate';
import { useCallback, useEffect, useState } from 'react';
import { Room, WebrtcProvider } from 'y-webrtc';
import * as Y from 'yjs';
import useDebounce from './useDebounce';

async function fetchRoomState(
  api: string,
  room: string,
  token: string
): Promise<Uint8Array | null> {
  const getRoomEndpoint = `${api}/room/${room}`;

  const res = await fetch(getRoomEndpoint, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!res.ok) {
    throw new Error(`Error getting room, ${room}`);
  }

  const json = await res.json();
  const data =
    json != null && typeof json === 'object' && 'data' in json
      ? json.data
      : null;

  if (data == null) {
    return null;
  }

  if (typeof data !== 'string') {
    throw new Error(`Invalid data type for room, ${room}`);
  }

  const bytes = base64ToBytes(data);
  return isGzipped(bytes) ? gunzipSync(bytes) : bytes;
}

function createWebRTCProvider(
  room: string,
  ydoc: Y.Doc,
  password: string,
  signaling: string
): WebrtcProvider {
  return new WebrtcProvider(room, ydoc, {
    password,
    signaling: [signaling],
  });
}

function initMultiplayerData(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  multiplayerData: MultiplayerData<any, any>,
  roomData: Uint8Array | null
): void {
  if (roomData == null) {
    multiplayerData.init();
  } else {
    const serverYdoc = new Y.Doc();
    Y.applyUpdateV2(serverYdoc, roomData);

    multiplayerData.init(serverYdoc);
  }
}

function syncServerState(
  api: string,
  room: string,
  token: string,
  update: Uint8Array
) {
  const syncEndpoint = `${api}/room/${room}`;
  const base64Update = bytesToBase64(gzipSync(update));

  console.log('syncing server state...');

  fetch(syncEndpoint, {
    method: 'PUT',
    body: JSON.stringify({ data: base64Update }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    keepalive: false,
  }).then((res) => {
    if (!res.ok) {
      console.error(
        `Error syncing room, ${room}: ${res.status} ${res.statusText}`
      );
    }
  });
}

export interface RoomConnectionResult {
  provider: WebrtcProvider | null;
  ydoc: Y.Doc | null;
  isLoading: boolean;
}

export interface UseRoomConnectionParams {
  signaling: string;
  api: string;
  room: string | null;
  token: string | null;
  password: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: MultiplayerData<any, any> | null;
}

export default function useRoomConnection(
  params: UseRoomConnectionParams
): RoomConnectionResult {
  const {
    signaling,
    api,
    room,
    token,
    password,
    data: multiplayerData,
  } = params;

  const [provider, setProvider] = useState<WebrtcProvider | null>(null);
  const [ydoc, setYdoc] = useState<Y.Doc | null>(null);
  const [needServerState, setNeedServerState] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const syncServerStateCallback = useCallback(
    (update: Uint8Array) => {
      if (api == null || room == null || token == null) return;
      syncServerState(api, room, token, update);
    },
    [api, room, token]
  );

  const debouncedSyncServerState = useDebounce(syncServerStateCallback, 1000);

  // Get latest state from the server
  useEffect(() => {
    if (room == null || token == null || multiplayerData == null) return;
    if (!needServerState) return;

    fetchRoomState(api, room, token)
      .then((roomData) => {
        initMultiplayerData(multiplayerData, roomData);
        setYdoc(multiplayerData.ydoc);
        setNeedServerState(false);
      })
      .catch((err) => {
        console.error(err);
        setNeedServerState(true);
      });
  }, [api, room, token, multiplayerData, needServerState]);

  // Connect to WebRTC room
  useEffect(() => {
    if (room == null || password == null || ydoc == null) return;

    const newProvider = createWebRTCProvider(room, ydoc, password, signaling);
    setProvider(newProvider);

    return () => {
      newProvider.destroy();
      setProvider(null);
    };
  }, [room, password, signaling, ydoc]);

  const onUpdateCallback = useCallback(
    (_update: Uint8Array, origin: Room | null) => {
      if (ydoc == null) return;
      const fromPeer = origin?.provider === provider;

      // don't sync updates that come from peers
      if (!fromPeer) {
        // HACK: Ignore incremental update and sync full state (not ideal, but it works well for now)
        const fullUpdate = Y.encodeStateAsUpdateV2(ydoc);
        debouncedSyncServerState(fullUpdate);
      }
    },
    [provider, debouncedSyncServerState, ydoc]
  );

  // sync state updates to the server
  useEffect(() => {
    multiplayerData?.onUpdate(onUpdateCallback);
    return () => {
      debouncedSyncServerState.flush();
      multiplayerData?.offUpdate(onUpdateCallback);
    };
  }, [multiplayerData, debouncedSyncServerState, onUpdateCallback]);

  // sync any pending state updates before page unload
  useEffect(() => {
    const syncOnUnload = () => {
      if (ydoc == null) return;

      debouncedSyncServerState.flush();
    };

    window.addEventListener('beforeunload', syncOnUnload);
    return () => {
      window.removeEventListener('beforeunload', syncOnUnload);
    };
  }, [ydoc, debouncedSyncServerState]);

  // set isLoading to true when room changes
  useEffect(() => {
    setIsLoading(true);
  }, [room]);

  // set isLoading to false when ydoc update is detected
  useEffect(() => {
    const doneLoading = (): void => {
      setIsLoading(false);
    };

    ydoc?.on('updateV2', doneLoading);
    return () => {
      ydoc?.off('updateV2', doneLoading);
    };
  }, [ydoc]);

  return {
    provider,
    ydoc: ydoc,
    isLoading,
  };
}
