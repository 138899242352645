import { useEffect, useState } from 'react';
import { Box, Button, Grid, Paper, Tooltip, Typography } from '@mui/material';
import {
  FlightTakeoff as FlyToIcon,
  Insights as InsightsIcon,
} from '@mui/icons-material';
import { parseEnv } from '@plotr/common-utils';
import { CustomPin } from '@plotr/plotr-multiplayer-data/src';

import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';
import useMapContext from '../../dynamic-map/hooks/useMapContext';

const env = parseEnv({ MAPBOX_API_KEY: process.env.MAPBOX_API_KEY });

const PinCard = ({ pin }: { pin: CustomPin }) => {
  const map = useMapContext();
  const [address, setAddress] = useState<string | null>(null);

  const evaluatedDemographicEntity = useDynamicMapStore(
    (state) => state.evaluatedDemographicEntity
  );
  const setEvaluatedDemographicEntity = useDynamicMapStore(
    (state) => state.setEvaluatedDemographicEntity
  );

  const evaluatedDemographicPoint =
    evaluatedDemographicEntity?.type === 'point'
      ? evaluatedDemographicEntity.pos
      : null;

  // fetch pin address based on lat/lng
  useEffect(() => {
    fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${pin.pos.lng},${pin.pos.lat}.json?access_token=${env.MAPBOX_API_KEY}`
    )
      .then((response) => response.json())
      .then((data) => {
        const address = data.features[0].place_name;
        setAddress(address);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [pin.pos]);

  const onClickFlyTo = () => {
    map?.flyTo({ center: pin.pos, zoom: 15 });
  };

  const onClickDemographics = () => {
    if (
      evaluatedDemographicPoint?.lat === pin.pos.lat &&
      evaluatedDemographicPoint?.lng === pin.pos.lng
    ) {
      setEvaluatedDemographicEntity(null);
      return;
    }
    setEvaluatedDemographicEntity({
      type: 'point',
      pos: pin.pos,
    });
  };

  const determineDemographicButtonBackground = () => {
    if (
      evaluatedDemographicPoint?.lat === pin.pos.lat &&
      evaluatedDemographicPoint?.lng === pin.pos.lng
    ) {
      return 'contained';
    }
    return 'outlined';
  };

  return (
    <Paper key={pin.id} elevation={2}>
      <Box padding={2} my="0.5rem">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={7}>
            <Typography variant="h6" gutterBottom>
              {pin.label}
            </Typography>
            <Tooltip title={address ?? 'Loading address...'}>
              <Typography variant="caption" noWrap>
                <Box
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {address ?? 'Loading address...'}
                </Box>
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={5}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              height="100%"
            >
              <Button
                variant="outlined"
                startIcon={<FlyToIcon />}
                onClick={onClickFlyTo}
                fullWidth
              >
                <Typography style={{ fontSize: '11px' }}>Fly To</Typography>
              </Button>
              <Button
                variant={determineDemographicButtonBackground()}
                startIcon={<InsightsIcon />}
                onClick={onClickDemographics}
                fullWidth
              >
                <Typography style={{ fontSize: '11px' }}>
                  Demographics
                </Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default PinCard;
