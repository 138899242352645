import { useState } from 'react';
import {
  Check as CheckIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Edit as EditIcon,
  List as ListIcon,
} from '@mui/icons-material';
import {
  Paper,
  Box,
  TextField,
  IconButton,
  Typography,
  Button,
} from '@mui/material';
import { plotrMultiplayerData } from '@plotr/plotr-multiplayer-data';

import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';

const PinGroupCard = ({
  group,
  pinCount,
  onAction,
}: {
  group: string;
  pinCount: number;
  onAction: () => void;
}) => {
  const customPinMethods = plotrMultiplayerData.methods?.pins;
  const [newGroupName, setNewGroupName] = useState<string>('');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const hiddenPinGroups = useDynamicMapStore((state) => state.hiddenPinGroups);
  const toggleGroupVisibility = useDynamicMapStore(
    (state) => state.toggleGroupVisibility
  );

  const submitNewName = () => {
    if (customPinMethods == null) return;

    customPinMethods.renameGroup(group, newGroupName);
    setIsEditing(false);
  };

  return (
    <Paper key={group} variant="outlined" sx={{ marginBottom: 1 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={3}
      >
        <Box display="flex" alignItems="center" flex={1} marginRight={2}>
          {isEditing ? (
            <>
              <TextField
                autoFocus
                size="small"
                value={newGroupName}
                onChange={(event) => setNewGroupName(event.target.value)}
                onBlur={submitNewName}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    submitNewName();
                  } else if (e.key === 'Escape') {
                    setIsEditing(false);
                  }
                }}
                disabled={customPinMethods == null}
                sx={{ marginRight: 1 }}
              />
              <IconButton onClick={submitNewName}>
                <CheckIcon />
              </IconButton>
            </>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" sx={{ flexGrow: 1 }}>
                {group}
              </Typography>
              <IconButton
                onClick={() => {
                  setIsEditing(true);
                  setNewGroupName(group);
                }}
                size="small"
                sx={{ marginLeft: 1 }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Box>
        <Box display="flex" alignItems="center" marginRight={2}>
          <IconButton
            onClick={() => {
              toggleGroupVisibility(group);
            }}
            size="small"
            sx={{ marginLeft: 1 }}
          >
            {hiddenPinGroups.includes(group) ? (
              <VisibilityOffIcon />
            ) : (
              <VisibilityIcon />
            )}
          </IconButton>
        </Box>
        <Button
          variant="outlined"
          color="primary"
          endIcon={<ListIcon />}
          onClick={onAction}
          size="small"
          sx={{
            borderRadius: 1,
            textTransform: 'none',
            minWidth: '120px',
          }}
        >
          View Pins ({pinCount})
        </Button>
      </Box>
    </Paper>
  );
};

export default PinGroupCard;
