import { useCallback, useEffect, useMemo, useState } from 'react';

import { LockIcon } from '~/src/common/components/LockableButton';
import getMapLayerDefinitions, {
  Color,
} from '~/src/features/dynamic-map-controller/data/getMapLayerDefinitions';
import useBlockGroupsStore from '~/src/features/dynamic-map/hooks/useBlockGroupStore';
import { useSubscriptionDialog } from '~/src/global/components/SubscriptionDialog';
import useAccessToken from '~/src/global/hooks/useAccessToken';
import useSettingsStore from '~/src/global/hooks/useSettingsStore';
import { checkPermission } from '~/src/global/services/permissionService';
import useLayersStore, {
  ControllableLayerGroup,
  LayerCard,
  LayerDataConfig,
  LayerStyleConfig,
} from '../../../../../dynamic-map/hooks/useLayersStore';
import useTrafficStore from '../../../../../dynamic-map/hooks/useTrafficStore';
import { LayerType } from '../LayerManager';
import LayerImageCategories from './LayerImageCategories';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { FeatureCollection } from 'geojson';

function MapPreview({ selectedLayer }: { selectedLayer: LayerCard | null }) {
  return (
    <img
      src={
        selectedLayer?.imageUrl !== undefined && selectedLayer?.imageUrl !== ''
          ? selectedLayer?.imageUrl
          : 'https://via.placeholder.com/150'
      }
      alt="Map Preview"
      style={{
        width: '100%',
        aspectRatio: '1/1',
      }}
    />
  );
}

export type EditLayerMode = 'create' | 'edit';

interface EditLayerProps {
  mode: EditLayerMode | null;
  selectedLayer: LayerCard | null;
  layerTypeForNew?: string;
  onClose: () => void;
  onSave: (layerDetails: LayerCard) => void;
}

interface CustomLabel {
  show?: boolean;
  value?: string;
}

type LayerImageCategoryKey = keyof typeof LayerImageCategories;
type LayerFeatureType = 'polygon' | 'line' | 'point';

interface BaseLayerConfig {
  displayName: string;
  permission?: string;
  idLabelFlag?: boolean;
  customMinLabel?: CustomLabel;
  customMaxLabel?: CustomLabel;
  category: LayerImageCategoryKey;
  featureType: LayerFeatureType;
  isBoundary?: boolean;
}

export interface TilesetSource {
  source: string;
  source_layer: string;
  label: string;
}

type BlockGroupSource = {
  label: 'Block Groups';
  source: 'luketruitt1.block_group_insights';
  source_layer: 'block_group_insights';
} & TilesetSource;

type ZipcodeSource = {
  label: 'Zip Codes';
  source: 'luketruitt1.insights_zipcode';
  source_layer: 'insights_zipcode';
} & TilesetSource;

type OtherTilesetSource = {
  label: string;
  source: string;
  source_layer: string;
} & TilesetSource;

type GeoJSONSource = {
  label: 'GeoJSON';
  source: string | FeatureCollection;
};

export type InsightTilesetSources =
  | BlockGroupSource
  | ZipcodeSource
  | OtherTilesetSource
  | GeoJSONSource;

export type Expression = Array<ExpressionValue>;
type ExpressionValue = string | number | boolean | Expression;

interface InsightLayerConfig extends BaseLayerConfig {
  type: LayerType.Insights;
  sourceTileset: InsightTilesetSources;
  styleConfig: LayerStyleConfig;
  dataConfig?: LayerDataConfig;
}

interface EnterpriseLayerConfig extends BaseLayerConfig {
  type: LayerType.Enterprise;
  component: ControllableLayerGroup;
  styleConfig: LayerStyleConfig;
}

export type LayerConfig = InsightLayerConfig | EnterpriseLayerConfig;

const heatmapLayerIds: string[] = ['zones', 'zone-numbers'];

export default function EditLayerModal({
  mode,
  selectedLayer,
  layerTypeForNew,
  onClose,
  onSave,
}: EditLayerProps) {
  const layers = useLayersStore((state) => state.layers);
  const [selectedInsight, setSelectedInsight] = useState<string>('');
  const [layerDisplayName, setLayerDisplayName] = useState<string>('');
  const [imageURL, setImageURL] = useState<string>('');
  const [layerDetails, setLayerDetails] = useState<LayerCard | null>(
    selectedLayer
  );
  const [layerType, setLayerType] = useState<string>('');

  const [error, setError] = useState<string>('');
  const [warning, setWarning] = useState<string>('');

  //enterprise layers
  const blockGroups = useBlockGroupsStore((state) => state.blockGroups);
  const layerInfo = useTrafficStore((state) => state.layerInfo);
  const { accessToken } = useAccessToken();
  const [permissions, setPermissions] = useState<Record<string, boolean>>({});
  const { open: openSubscriptionDialog } = useSubscriptionDialog();

  useEffect(() => {
    // Reset form fields when modal opens or selectedLayer changes
    if (mode != null && selectedLayer != null) {
      setSelectedInsight(selectedLayer?.insight || '');
      setLayerDisplayName(selectedLayer?.displayName || '');
      setImageURL(selectedLayer?.imageUrl || '');
      setLayerDetails(selectedLayer || null);
    }
  }, [mode, selectedLayer]);

  const clearForm = () => {
    setLayerDisplayName('');
    setImageURL('');
    setSelectedInsight('');
    setLayerDetails(null);
    setError('');
    setWarning('');
  };

  useEffect(() => {
    setLayerDetails(selectedLayer ?? null);
    setError('');
    setWarning('');
  }, [selectedLayer, mode]);

  const validateLayerName = useCallback(
    (name: string): string => {
      if (!name.trim()) return 'Layer name cannot be empty.';
      if (name.length > 50) return 'Layer name must be 50 characters or less.';
      if (!/^[a-zA-Z0-9-_ ]+$/.test(name))
        return 'Layer name contains invalid characters.';
      const doesNameExist = layers.some(
        (layer: LayerCard) =>
          layer.displayName === name &&
          layer.displayName !== selectedLayer?.displayName
      );
      if (doesNameExist)
        return 'A layer with this name already exists. Please choose a different name.';
      return '';
    },
    [layers, selectedLayer]
  );

  useEffect(() => {
    setLayerType(selectedLayer?.type ?? layerTypeForNew ?? '');
  }, [selectedLayer, layerTypeForNew]);

  const handleSave = () => {
    if (!layerDetails) return;
    const error = validateLayerName(layerDetails.id);
    if (error) {
      setError(error);
    } else {
      onSave(layerDetails);
      setError(''); // Clear error on successful save
    }
  };

  const BlockGroupBaseLayerID = 'block_groups';
  const blockGroupMatchLayerIds: string[] = useMemo(
    () => [
      BlockGroupBaseLayerID,
      ...blockGroups.map((blockGroupSet) => `BG-${blockGroupSet.groupName}`),
    ],
    [blockGroups]
  );

  const trafficLayerIds = layerInfo
    .filter((layer) => layer?.fields && Object.keys(layer.fields).length > 0)
    .map((layer) => `traffic_volume-${layer.id}`);

  //TODO: layers currently have 3 states: locked, unlocked, and disabled. We need a fourth state, hidden, to handle when a layer requires a permission to view in the dropdown

  //**Unipolar data thresholds are calculated at percentiles*/
  //**[Zero, 50th, 75th, 90th, 95th] */

  // TODO: The user may have access to one Enterprise layer (based on User Settings), but not another. We should disable the layer buttons they don't have access to.

  const enterpriseLayers: Record<string, LayerConfig> = useMemo(
    () => ({
      retail_proximity_heatmap: {
        type: LayerType.Enterprise,
        featureType: 'polygon',
        displayName: 'Retail Proximity Heatmap',
        component: { layerIds: heatmapLayerIds },
        styleConfig: {
          colors: ['#008000', '#FFFF00', '#FFA500', '#FF8C00', '#FF0000'],
          threshold: [8, 6, 4, 2, 0],
          unit: 'cells',
          boundLabels: ['Far', 'Near'],
        },
        permission: 'read:enterprise_retail_proximity',
        category: 'Retail.RetailProximity',
      },
      block_group_match: {
        type: LayerType.Enterprise,
        featureType: 'polygon',
        displayName: 'Customer Block Group Match',
        component: { layerIds: blockGroupMatchLayerIds },
        styleConfig: {
          threshold: ['C', 'B', 'A'],
          colors: [
            'rgba(54, 4, 194, 0.3)',
            'rgba(54, 4, 194, 0.65)',
            'rgba(54, 4, 194, 1)',
          ],
          unit: 'match grade',
        },
        permission: 'read:enterprise_bg_match',
        category: 'Miscellaneous',
      },
    }),
    [blockGroupMatchLayerIds]
  );

  const squareMetersPerSquareMile = 2589988.11;

  const customerLayers: Record<string, LayerConfig> = {
    kidstrong_search_demand: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Search Demand - Kidstrong',
      category: 'Fitness.Search Demand',
      sourceTileset: {
        label: 'Search Demand',
        source: 'luketruitt1.search_demand_zipcode',
        source_layer: 'search_demand_zipcode',
      },
      styleConfig: {
        idLabelFlag: true,
        // 50th percentile: 0 - not included
        // 90th percentile: 259
        // 95th percentile: 545
        // 99th percentile: 1550
        threshold: [0, 259, 545, 1550, 6000],
        opacityStops: [0, 1, 1, 1, 1],
        colors: [
          Color.white,
          Color.yellow,
          Color.red,
          Color.purple,
          Color.darkPurple,
        ],
        expression: ['get', 'kidstrong__impressions'],
        fallback: 0,
        unit: 'impressions',
      },
      dataConfig: {
        header: 'zip_code',
        caption: 'kidstrong__date_range',
        fields: [
          {
            Impressions: {
              key: 'kidstrong__impressions',
              format: 'integer',
            },
          },
          {
            Clicks: {
              key: 'topsail__clicks',
              format: 'integer',
            },
          },
          {
            'Avg. CPC': {
              key: 'topsail__average_cpc',
              format: 'currency',
            },
          },
        ],
      },
    },
    kids_gymnastics_search_demand: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Search Demand - Kids Gymnastics',
      category: 'Gyms.Search Demand',
      sourceTileset: {
        label: 'Search Demand',
        source: 'luketruitt1.search_demand_zipcode',
        source_layer: 'search_demand_zipcode',
      },
      styleConfig: {
        idLabelFlag: true,
        // 50th percentile: 0 - not included
        // 90th percentile: 80
        // 95th percentile: 205
        // 99th percentile: 439
        threshold: [0, 80, 205, 439, 1400],
        opacityStops: [0, 1, 1, 1, 1],
        colors: [
          Color.white,
          Color.yellow,
          Color.red,
          Color.purple,
          Color.darkPurple,
        ],
        expression: ['get', 'kids_gymnastics__impressions'],
        fallback: 0,
        unit: 'impressions',
      },
      dataConfig: {
        header: 'zip_code',
        caption: 'kids_gymnastics__date_range',
        fields: [
          {
            Impressions: {
              key: 'kids_gymnastics__impressions',
              format: 'integer',
            },
          },
          {
            Clicks: {
              key: 'kids_gymnastics__clicks',
              format: 'integer',
            },
          },
          {
            'Avg. CPC': {
              key: 'kids_gymnastics__average_cpc',
              format: 'currency',
            },
          },
        ],
      },
    },
    topsail_search_demand: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Search Demand - Topsail 5-21-2024',
      category: 'Restaurant.Search Demand',
      sourceTileset: {
        label: 'Search Demand',
        source: 'luketruitt1.search_demand_zipcode',
        source_layer: 'search_demand_zipcode',
      },
      styleConfig: {
        idLabelFlag: true,
        // 50th percentile: 10
        // 90th percentile: 236
        // 95th percentile: 490
        // 99th percentile: 1067
        threshold: [0, 10, 236, 490, 1067, 6000],
        opacityStops: [0, 1, 1, 1, 1, 1],
        colors: [
          Color.white,
          Color.yellow,
          Color.orange,
          Color.red,
          Color.purple,
          Color.darkPurple,
        ],
        expression: ['get', 'topsail__impressions'],
        fallback: 0,
        unit: 'impressions',
      },
      dataConfig: {
        header: 'zip_code',
        caption: 'topsail__date_range',
        fields: [
          {
            Impressions: {
              key: 'topsail__impressions',
              format: 'integer',
            },
          },
          {
            Clicks: {
              key: 'topsail__clicks',
              format: 'integer',
            },
          },
          {
            'Avg. CPC': {
              key: 'topsail__average_cpc',
              format: 'currency',
            },
          },
        ],
      },
    },
    topsail_search_demand_v2: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Search Demand - Topsail 7-7-2024',
      category: 'Restaurant.Search Demand',
      sourceTileset: {
        label: 'Search Demand',
        source: 'luketruitt1.search_demand_zipcode',
        source_layer: 'search_demand_zipcode',
      },
      styleConfig: {
        idLabelFlag: true,
        // 50th percentile: 10
        // 90th percentile: 236
        // 95th percentile: 490
        // 99th percentile: 1067
        threshold: [0, 10, 236, 490, 1067, 6000],
        opacityStops: [0, 1, 1, 1, 1, 1],
        colors: [
          Color.white,
          Color.yellow,
          Color.orange,
          Color.red,
          Color.purple,
          Color.darkPurple,
        ],
        expression: ['get', 'topsail_v2__impressions'],
        fallback: 0,
        unit: 'impressions',
      },
      dataConfig: {
        header: 'zip_code',
        caption: 'date_range',
        fields: [
          {
            Impressions: {
              key: 'topsail_v2__impressions',
              format: 'integer',
            },
          },
          {
            Clicks: {
              key: 'topsail_v2__clicks',
              format: 'integer',
            },
          },
          {
            'Avg. CPC': {
              key: 'topsail_v2__average_cpc',
              format: 'currency',
            },
          },
        ],
      },
    },
    kidstrong_customer_heatmap: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Zipcode - Customer Location Heatmap',
      category: 'Restaurant.Search Demand',
      sourceTileset: {
        label: 'Client Data',
        source: 'luketruitt1.client_data_zipcode',
        source_layer: 'client_data_zipcode',
      },
      styleConfig: {
        threshold: [0, 1, 5, 10, 15],
        opacityStops: [0, 1, 1, 1, 1],
        colors: [
          Color.white,
          Color.yellow,
          Color.orange,
          Color.red,
          Color.purple,
        ],
        expression: ['coalesce', ['get', 'kidstrong_cus_count'], 0],
        fallback: 0,
        unit: 'customers',
        boundLabels: ['Low', 'High'],
        hideBoundValues: true,
      },
      dataConfig: {
        header: 'zip_code',
      },
    },
    seafood_search_demand: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Search Demand - Seafood',
      category: 'Restaurant.Search Demand',
      sourceTileset: {
        label: 'Search Demand',
        source: 'luketruitt1.search_demand_zipcode',
        source_layer: 'search_demand_zipcode',
      },
      styleConfig: {
        idLabelFlag: true,
        // 50th percentile: 18
        // 90th percentile: 357
        // 95th percentile: 737
        // 99th percentile: 2118
        threshold: [0, 18, 357, 737, 2118, 12000],
        opacityStops: [0, 1, 1, 1, 1, 1],
        colors: [
          Color.white,
          Color.yellow,
          Color.orange,
          Color.red,
          Color.purple,
          Color.darkPurple,
        ],
        expression: ['get', 'seafood__impressions'],
        fallback: 0,
        unit: 'impressions',
      },
      dataConfig: {
        header: 'zip_code',
        caption: 'seafood__date_range',
        fields: [
          {
            Impressions: {
              key: 'seafood__impressions',
              format: 'integer',
            },
          },
          {
            Clicks: {
              key: 'seafood__clicks',
              format: 'integer',
            },
          },
          {
            'Avg. CPC': {
              key: 'seafood__average_cpc',
              format: 'currency',
            },
          },
        ],
      },
    },
    fitness_search_demand: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Search Demand - Fitness',
      category: 'Restaurant.Search Demand',
      sourceTileset: {
        label: 'Search Demand',
        source: 'luketruitt1.search_demand_zipcode',
        source_layer: 'search_demand_zipcode',
      },
      styleConfig: {
        idLabelFlag: true,
        threshold: [0, 50, 530, 2115, 8400],
        opacityStops: [0, 1, 1, 1, 1],
        colors: [
          Color.white,
          Color.yellow,
          Color.red,
          Color.purple,
          Color.darkPurple,
        ],
        expression: ['get', 'fitness_demand__impressions'],
        fallback: 0,
        unit: 'impressions',
      },
      dataConfig: {
        header: 'zip_code',
        caption: 'fitness_demand__date_range',
        fields: [
          {
            Impressions: {
              key: 'fitness_demand__impressions',
              format: 'integer',
            },
          },
          {
            Clicks: {
              key: 'fitness_demand__clicks',
              format: 'integer',
            },
          },
          {
            'Avg. CPC': {
              key: 'fitness_demand__average_cpc',
              format: 'currency',
            },
          },
        ],
      },
    },
    city_lifestyle_zips: {
      type: LayerType.Insights,
      displayName: 'City Lifestyle - Zip Codes',
      featureType: 'polygon',
      category: 'Miscellaneous.City Lifestyle',
      styleConfig: {
        colors: ['rgba(173, 216, 230, 0.5)'],
      },
      dataConfig: {
        header: 'zip_code',
        caption: 'primary_city',
        fields: [
          { Population: 'population' },
          {
            'Median Home Value': {
              key: 'avg_median_home_value',
              format: 'dollars',
            },
          },
          {
            'Median Household Income': {
              key: 'avg_median_household_income',
              format: 'dollars',
            },
          },
          { 'Owner Occupied Homes': 'owner_occupied_homes' },
          {
            'Percent Owner Occupied': {
              key: 'percent_owner_occupied',
              format: 'percent',
            },
          },
          { 'Seasonal Population': 'seasonal_population' },
          {
            'Percent Seasonal Population': {
              key: 'percent_seasonal_population',
              format: 'percent',
            },
          },
          {
            'Count of Local Businesses': {
              expression: [
                '+',
                ['coalesce', ['get', 'business_counts_Auto Dealers'], 0],
                ['coalesce', ['get', 'business_counts_Beauty Salons'], 0],
                ['coalesce', ['get', 'business_counts_Chiropractors'], 0],
                [
                  'coalesce',
                  ['get', 'business_counts_Building Contractors'],
                  0,
                ],
                [
                  'coalesce',
                  ['get', 'business_counts_Equipment Contractors'],
                  0,
                ],
                [
                  'coalesce',
                  ['get', 'business_counts_Specialty Contractors'],
                  0,
                ],
                ['coalesce', ['get', 'business_counts_Dentists'], 0],
                ['coalesce', ['get', 'business_counts_Physicians'], 0],
                ['coalesce', ['get', 'business_counts_Florists'], 0],
                ['coalesce', ['get', 'business_counts_Insurance Agencies'], 0],
                ['coalesce', ['get', 'business_counts_Design Services'], 0],
                ['coalesce', ['get', 'business_counts_Jewelry Stores'], 0],
                ['coalesce', ['get', 'business_counts_Loan Brokers'], 0],
                [
                  'coalesce',
                  ['get', 'business_counts_Health Practitioners'],
                  0,
                ],
                ['coalesce', ['get', 'business_counts_Optometrists'], 0],
                ['coalesce', ['get', 'business_counts_Commercial Banking'], 0],
                ['coalesce', ['get', 'business_counts_Investment Advice'], 0],
                ['coalesce', ['get', 'business_counts_Hospitals'], 0],
                ['coalesce', ['get', 'business_counts_Real Estate Agents'], 0],
                [
                  'coalesce',
                  ['get', 'business_counts_Landscaping Services'],
                  0,
                ],
                [
                  'coalesce',
                  ['get', 'business_counts_Residential Construction'],
                  0,
                ],
                ['coalesce', ['get', 'business_counts_Law Practices'], 0],
                ['coalesce', ['get', 'business_counts_Veterinary Services'], 0],
              ],
            },
          },
        ],
      },
      sourceTileset: {
        label: 'Zip Codes',
        source: 'luketruitt1.city-life-zipcodes',
        source_layer: 'city-life-zipcodes',
      },
    },
    city_lifestyle_places: {
      type: LayerType.Insights,
      displayName: 'City Lifestyle - Cities',
      featureType: 'polygon',
      category: 'Miscellaneous.City Lifestyle',
      styleConfig: {
        colors: ['rgba(173, 216, 230, 0.5)'],
      },
      dataConfig: {
        header: 'city_id',
        fields: [
          { Population: 'population' },
          {
            'Median Home Value': {
              key: 'avg_median_home_value',
              format: 'dollars',
            },
          },
          {
            'Median Household Income': {
              key: 'avg_median_household_income',
              format: 'dollars',
            },
          },
          { 'Owner Occupied Homes': 'owner_occupied_homes' },
          {
            'Percent Owner Occupied': {
              key: 'percent_owner_occupied',
              format: 'percent',
            },
          },
          { 'Seasonal Population': 'seasonal_population' },
          {
            'Percent Seasonal Population': {
              key: 'percent_seasonal_population',
              format: 'percent',
            },
          },
          {
            'Count of Local Businesses': {
              expression: [
                '+',
                ['coalesce', ['get', 'business_counts_Auto Dealers'], 0],
                ['coalesce', ['get', 'business_counts_Beauty Salons'], 0],
                ['coalesce', ['get', 'business_counts_Chiropractors'], 0],
                [
                  'coalesce',
                  ['get', 'business_counts_Building Contractors'],
                  0,
                ],
                [
                  'coalesce',
                  ['get', 'business_counts_Equipment Contractors'],
                  0,
                ],
                [
                  'coalesce',
                  ['get', 'business_counts_Specialty Contractors'],
                  0,
                ],
                ['coalesce', ['get', 'business_counts_Dentists'], 0],
                ['coalesce', ['get', 'business_counts_Physicians'], 0],
                ['coalesce', ['get', 'business_counts_Florists'], 0],
                ['coalesce', ['get', 'business_counts_Insurance Agencies'], 0],
                ['coalesce', ['get', 'business_counts_Design Services'], 0],
                ['coalesce', ['get', 'business_counts_Jewelry Stores'], 0],
                ['coalesce', ['get', 'business_counts_Loan Brokers'], 0],
                [
                  'coalesce',
                  ['get', 'business_counts_Health Practitioners'],
                  0,
                ],
                ['coalesce', ['get', 'business_counts_Optometrists'], 0],
                ['coalesce', ['get', 'business_counts_Commercial Banking'], 0],
                ['coalesce', ['get', 'business_counts_Investment Advice'], 0],
                ['coalesce', ['get', 'business_counts_Hospitals'], 0],
                ['coalesce', ['get', 'business_counts_Real Estate Agents'], 0],
                [
                  'coalesce',
                  ['get', 'business_counts_Landscaping Services'],
                  0,
                ],
                [
                  'coalesce',
                  ['get', 'business_counts_Residential Construction'],
                  0,
                ],
                ['coalesce', ['get', 'business_counts_Law Practices'], 0],
                ['coalesce', ['get', 'business_counts_Veterinary Services'], 0],
              ],
            },
          },
          { 'Zip Code Count': 'zip_code_count' },
          {
            'Zip Codes': {
              key: 'zip_codes',
              format: 'chips',
            },
          },
        ],
      },
      sourceTileset: {
        label: 'Cities',
        source: 'luketruitt1.cities',
        source_layer: 'cities',
      },
    },
    city_lifestyle_local_businesses: {
      type: LayerType.Insights,
      displayName: 'City Lifestyle - Local Businesses',
      featureType: 'polygon',
      category: 'Miscellaneous.City Lifestyle',
      sourceTileset: {
        label: 'City Lifestyle - Local Businesses',
        source: 'luketruitt1.local-business-zip-counts',
        source_layer: 'local-business-zip-counts',
      },
      styleConfig: {
        colors: [Color.white, Color.blue, Color.blue, Color.purple],
        threshold: [0, 50, 400, 800],
        opacityStops: [0, 0.5, 1, 1],
        fallback: 0,
        expression: [
          'case',
          ['==', ['get', 'area'], 0],
          0,
          [
            'round',
            [
              '*',
              ['/', ['get', 'total_businesses'], ['get', 'area']],
              squareMetersPerSquareMile,
            ],
          ],
        ],
        unit: 'businesses / mi\u00B2',
      },
      dataConfig: {
        header: 'zip_code',
        fields: [
          { 'Count of Local Businesses': 'total_businesses' },
          {
            'Local Businesses / mi\u00B2': {
              expression: [
                'case',
                ['==', ['get', 'area'], 0],
                0,
                [
                  'round',
                  [
                    '*',
                    ['/', ['get', 'total_businesses'], ['get', 'area']],
                    squareMetersPerSquareMile,
                  ],
                ],
              ],
            },
          },
        ],
      },
    },
    multi_family_housing: {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: 'Block Group - Multi-Family Housing Units',
      category: 'Population.Income',
      sourceTileset: {
        label: 'Block Group Insights',
        source: 'luketruitt1.block_group_insights',
        source_layer: 'block_group_insights',
      },
      styleConfig: {
        threshold: [0, 100, 3000, 7500],
        colors: [Color.white, Color.yellow, Color.orange, Color.red],
        fallback: 0,
        expression: [
          'case',
          ['==', ['get', 'area'], 0],
          0,
          [
            'round',
            [
              '*',
              ['/', ['get', 'mfh_units_total'], ['get', 'area']],
              squareMetersPerSquareMile,
            ],
          ],
        ],
        opacityStops: [0, 1, 1, 1],
        unit: 'units / mi\u00B2',
      },
      dataConfig: {
        header: 'id',
        fields: [
          { 'Multi-Family Units': 'mfh_units_total' },
          {
            'Units / mi\u00B2': {
              expression: [
                'case',
                ['==', ['get', 'area'], 0],
                0,
                [
                  'round',
                  [
                    '*',
                    ['/', ['get', 'mfh_units_total'], ['get', 'area']],
                    squareMetersPerSquareMile,
                  ],
                ],
              ],
            },
          },
        ],
      },
    },
    zip_franchising_interest: {
      type: LayerType.Insights,
      featureType: 'polygon',
      styleConfig: {
        idLabelFlag: true,
        threshold: [0, 173, 706, 1766.48, 10000],
        opacityStops: [0, 0, 1, 1, 1],
        colors: [
          Color.yellow,
          Color.yellowOrange,
          Color.orange,
          Color.darkOrange,
          Color.red,
        ],
        expression: ['get', 'franchising_impressions'],
        fallback: 0,
        unit: 'impressions',
      },
      dataConfig: {
        header: 'id',
        fields: [{ 'Franchising Impressions': 'franchising_impressions' }],
      },
      sourceTileset: {
        label: 'Zip Codes',
        source: 'luketruitt1.insights_zipcode',
        source_layer: 'insights_zipcode',
      },
      displayName: 'Franchising Demand',
      permission: 'read:insight_franchising_interest',
      category: 'Retail.Franchise',
    },
    zip_consumer_retail_intent: {
      type: LayerType.Insights,
      featureType: 'polygon',
      styleConfig: {
        idLabelFlag: true,
        threshold: [0, 11787.08, 34384.36, 98819.33, 134000],
        opacityStops: [0, 1, 1, 1, 1],
        colors: [
          Color.yellow,
          Color.yellowOrange,
          Color.orange,
          Color.darkOrange,
          Color.red,
        ],
        expression: ['get', 'retail_intent_impressions'],
        fallback: 0,
        unit: 'impressions',
      },
      dataConfig: {
        header: 'id',
        fields: [{ 'Retail Intent Impressions': 'retail_intent_impressions' }],
      },
      sourceTileset: {
        label: 'Zip Codes',
        source: 'luketruitt1.insights_zipcode',
        source_layer: 'insights_zipcode',
      },
      displayName: 'Consumer Demand',
      permission: 'read:insight_consumer_retail_intent',
      category: 'Retail',
    },
    zip_search_carls_jr: {
      type: LayerType.Insights,
      featureType: 'polygon',
      styleConfig: {
        idLabelFlag: true,
        threshold: [0, 13, 27, 41, 42],
        opacityStops: [0, 1, 1, 1],
        colors: [
          Color.yellow,
          Color.yellowOrange,
          Color.orange,
          Color.darkOrange,
          Color.red,
        ],
        expression: ['get', 'carls_junior_impressions'],
        fallback: 0,
        unit: 'impressions',
      },
      dataConfig: {
        header: 'id',
        fields: [{ 'Carls Jr Impressions': 'carls_junior_impressions' }],
      },
      sourceTileset: {
        label: 'Zip Codes',
        source: 'luketruitt1.insights_zipcode',
        source_layer: 'insights_zipcode',
      },
      displayName: `Consumer Demand - Carls Jr`,
      permission: 'read:ent_walkons',
      category: 'Restaurant',
    },
    designated_fuel_corridors: {
      type: LayerType.Insights,
      featureType: 'line',
      sourceTileset: {
        label: 'Aiquetech',
        source: 'luketruitt1.aiquetech',
        source_layer: 'designated_fuel_corridors',
      },
      displayName: 'Designated Fuel Corridors',
      category: 'Miscellaneous.Search American',
      styleConfig: { colors: ['#000'] },
    },
    power_transmission_lines: {
      type: LayerType.Insights,
      featureType: 'line',
      sourceTileset: {
        label: 'Aiquetech',
        source: 'luketruitt1.aiquetech',
        source_layer: 'power_transmission_lines',
      },
      displayName: 'Power Transmission Lines',
      styleConfig: { colors: [Color.red] },
      category: 'Miscellaneous.Search American',
    },
  };

  const enabledCustomerLayers = useSettingsStore(
    (state) => state.userSettings?.customerLayers ?? []
  );

  const insights: {
    [key: string]: LayerConfig;
  } = useMemo(
    () => ({
      ...Object.entries(customerLayers).reduce(
        (acc, [key, value]) =>
          enabledCustomerLayers.includes(key) ? { ...acc, [key]: value } : acc,
        {}
      ),
      ...getMapLayerDefinitions(trafficLayerIds),
    }),
    [trafficLayerIds]
  );

  useEffect(() => {
    const handleChange = () => {
      if (!layerDetails || !layerDisplayName || !selectedInsight) return;

      setError(validateLayerName(layerDisplayName));

      const selectedConfig =
        insights[selectedInsight] || enterpriseLayers[selectedInsight];

      const detailBuilder: Partial<LayerCard> = {
        ...layerDetails,
        displayName: layerDisplayName,
        type: selectedConfig.type,
        insight: selectedInsight,
        imageUrl: LayerImageCategories[selectedConfig.category],
        layerGroup: { layerIds: [layerDetails.id] },
        featureType: selectedConfig.featureType,
        isBoundary: selectedConfig.isBoundary,
      };
      if (selectedConfig.type === LayerType.Insights) {
        detailBuilder.sourceTileset = selectedConfig.sourceTileset;
        detailBuilder.styleConfig = selectedConfig.styleConfig;
        detailBuilder.dataConfig = selectedConfig.dataConfig;

        setLayerDetails(detailBuilder as LayerCard);
      } else if (selectedConfig.type === LayerType.Enterprise) {
        const enterpriseDetails = {
          id: selectedInsight,
          styleConfig: selectedConfig.styleConfig,
        };
        setLayerDetails({
          ...detailBuilder,
          ...enterpriseDetails,
        } as LayerCard);
      }
    };

    handleChange();
  }, [layerDisplayName, selectedInsight, imageURL, validateLayerName]);

  useEffect(() => {
    async function fetchPermissions() {
      if (accessToken) {
        // Explicitly typing the Set elements as string
        const permissionKeys = new Set<string>();

        // Extract permissions from insights and enterpriseLayers
        Object.values(insights).forEach((item) => {
          if (item.permission) permissionKeys.add(item.permission);
        });
        Object.values(enterpriseLayers).forEach((item) => {
          if (item.permission) permissionKeys.add(item.permission);
        });

        const permissionsResult: Record<string, boolean> = {};

        for (const key of permissionKeys) {
          permissionsResult[key] = await checkPermission(accessToken, key);
        }

        setPermissions(permissionsResult);
      }
    }
    fetchPermissions();
  }, [accessToken]);

  let optionsToRender: { [key: string]: LayerConfig } = {};
  if (selectedLayer) {
    // Determine whether the selected layer is an Insight or Enterprise layer
    optionsToRender =
      selectedLayer.type === LayerType.Insights ? insights : enterpriseLayers;
  } else {
    // If creating a new layer, use layerTypeForNew to determine which options to show
    optionsToRender =
      layerTypeForNew === LayerType.Insights ? insights : enterpriseLayers;
  }

  return (
    <Dialog open={mode != null} onClose={onClose} fullWidth>
      <DialogTitle>
        {mode === 'edit'
          ? 'Edit Layer'
          : 'Create New ' + (layerTypeForNew ?? 'Layer')}
      </DialogTitle>
      {layerDetails && (
        <>
          <DialogContent>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Grid item xs={12}>
                    <MapPreview selectedLayer={selectedLayer} />
                  </Grid>
                  <Grid item xs={12} sx={{ alignContent: 'center' }}></Grid>
                </Grid>
                <Grid item xs={12} sm={8}>
                  {layerType === 'Insights Layer' && mode === 'create' && (
                    <FormControl fullWidth>
                      <InputLabel id="insight-select-label">
                        Select Data Insight
                      </InputLabel>
                      <Select
                        labelId="insight-select-label"
                        id="insight-select"
                        value={selectedInsight}
                        label="Select Data Insight"
                        onChange={(e) => {
                          const newInsightKey = e.target.value as string;
                          const selectedLayer = optionsToRender[newInsightKey];

                          // Check if the selected option is locked
                          if (
                            selectedLayer &&
                            selectedLayer.permission &&
                            !permissions[selectedLayer.permission]
                          ) {
                            //if the user already has a subscription, redirect to the enterprise contact form

                            //Else, open the subscription modal for locked items
                            openSubscriptionDialog();
                          } else {
                            // Proceed with existing logic
                            const currentDisplayName =
                              insights[selectedInsight]?.displayName ?? '';
                            if (
                              layerDisplayName === currentDisplayName ||
                              currentDisplayName === ''
                            ) {
                              setLayerDisplayName(
                                insights[newInsightKey].displayName
                              );
                              setSelectedInsight(newInsightKey); // Update the selected insight
                            } else {
                              // Show a warning or helper message
                              setWarning(
                                `Do you want to rename the layer to match the new insight?`
                              );
                              setSelectedInsight(newInsightKey);
                            }
                          }
                        }}
                      >
                        {Object.entries(optionsToRender).map(([key, layer]) => {
                          const isLocked = layer.permission
                            ? !permissions[layer.permission]
                            : false;
                          const isDisabled = layers.some(
                            (l) => l.insight === key
                          );

                          return (
                            <MenuItem
                              key={key}
                              value={key}
                              disabled={isDisabled}
                            >
                              {isLocked && <LockIcon active={false} />}
                              {layer.displayName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                  {layerType === 'Enterprise Layer' && mode === 'create' && (
                    <FormControl fullWidth>
                      <InputLabel id="enterprise-select-label">
                        Select Enterprise Layer
                      </InputLabel>
                      <Select
                        labelId="enterprise-select-label"
                        id="enterprise-select"
                        value={selectedInsight}
                        label="Select Enterprise Layer"
                        onChange={(e) => {
                          const newLayerKey = e.target.value as string;
                          setSelectedInsight(newLayerKey); //
                          setLayerDisplayName(
                            enterpriseLayers[newLayerKey].displayName
                          );
                        }}
                      >
                        {Object.entries(optionsToRender).map(([key, layer]) => {
                          const isLocked = layer.permission
                            ? !permissions[layer.permission]
                            : false;
                          const isDisabled = layers.some((l) => l.id === key);

                          return (
                            <MenuItem
                              key={key}
                              value={key}
                              disabled={isDisabled}
                            >
                              {isLocked && <LockIcon active={false} />}
                              {layer.displayName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                  <TextField
                    error={!!error}
                    helperText={error || warning}
                    name="id"
                    label="Layer Display Name"
                    value={layerDisplayName}
                    onChange={(e) => setLayerDisplayName(e.target.value)}
                    margin="dense"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                onClose();
                clearForm();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleSave();
                clearForm();
              }}
              disabled={!!error}
            >
              {selectedLayer ? 'Save Changes' : 'Create Layer'}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
