import turfCircle from '@turf/circle';
import { useEffect, useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import {
  Feature,
  FeatureCollection,
  GeoJsonProperties,
  Point,
  Polygon,
} from 'geojson';

import useSettingsStore from '~/src/global/hooks/useSettingsStore';
import { GroupComponentProps } from '../../../hooks/useLayersStore';
import { LayerInfo } from '../../../services/mapboxService';
import { ClientZonesByUrbanArea } from '../../../services/zonesService';

import useDynamicMapStore from '../../../hooks/useDynamicMapStore';
import useLayerIds from '../../../hooks/useLayerIds';
import useZonesStore from '../../../hooks/useZonesStore';
import useHeatmapStore from '../hooks/useHeatmapStore';

type WithWOM<T> = T & {
  wom: {
    min: number;
    max: number;
    average: number;
  };
};

function hasWOM<T>(data: T): data is WithWOM<T> {
  return (
    typeof data === 'object' &&
    data != null &&
    'wom' in data &&
    data.wom != null
  );
}

function getZonesGeoJSON(
  topLocationsByCity: ClientZonesByUrbanArea,
): FeatureCollection<Polygon> {
  const zoneDisplayRadiusInMiles = 1.5;

  const geojson: FeatureCollection<Polygon> = {
    type: 'FeatureCollection',
    features: Object.values(topLocationsByCity).flatMap((city) => {
      return city.features.map((feature: Feature<Point>) =>
        turfCircle(feature.geometry.coordinates, zoneDisplayRadiusInMiles, {
          units: 'miles',
          properties: {
            ...feature.properties,
            latitude: feature.geometry.coordinates[1],
            longitude: feature.geometry.coordinates[0],
            market_max: city.features.length,
          },
        }),
      );
    }),
  };

  return geojson;
}

const purple = '#bcbddc';

export const BASE_LAYER_ID = 'zones';

export default function HeatmapZoneSources(props: GroupComponentProps) {
  const { showLayerZoom, beforeId, enabled, opacity } = props;
  const zones = useZonesStore((state) => state.zones);
  const zonesVisible = useDynamicMapStore((state) => state.zonesVisible);

  const userSettings = useSettingsStore((state) => state.userSettings);

  const [topLocationsByCity, setTopLocationsByCity] =
    useState<ClientZonesByUrbanArea | null>(null);

  useEffect(() => {
    if (zones == null) return;

    setTopLocationsByCity(zones);
  }, [zones]);

  const [zonesGeoJSON, setZonesGeoJSON] =
    useState<FeatureCollection<Polygon> | null>(null);

  useEffect(() => {
    if (topLocationsByCity == null) return;

    setZonesGeoJSON(getZonesGeoJSON(topLocationsByCity));
  }, [topLocationsByCity, zones]);

  const mapboxURLs =
    userSettings?.enterpriseLayerConfig?.retailProximityHeatmap?.mapboxURLs ??
    [];
  const minWOMThreshold =
    userSettings?.enterpriseLayerConfig?.retailProximityHeatmap
      ?.minWomThreshold ?? null;

  const isNationallyRanked =
    zonesGeoJSON?.features.every(
      (feature) => feature.properties?.national_rank != null,
    ) ?? false;

  const zoneDisplayNumber = isNationallyRanked
    ? 'national_rank'
    : 'market_rank';

  const layerInfoBySource = useHeatmapStore((state) => state.layerInfoBySource);
  const heatmapVisible = useDynamicMapStore((state) => state.heatmapVisible);
  const toggleHeatmapVisible = useDynamicMapStore(
    (state) => state.toggleHeatmapVisible,
  );

  const stateLabelIds = useLayerIds((layer) =>
    layer.id.startsWith('state-label'),
  );

  useEffect(() => {
    if (heatmapVisible !== enabled) {
      toggleHeatmapVisible();
    }
  }, [enabled, heatmapVisible]);

  //TODO: Separate zones and heatmap into separate components that can be moved independently
  return (
    <>
      {mapboxURLs.map((url) => (
        <HeatmapSource
          key={url}
          {...{
            url,
            layerInfo: layerInfoBySource[url] ?? [],
            showLayerZoom,
            minWOMThreshold,
            topLocationsByCity,
            beforeId,
            opacity,
          }}
        />
      ))}
      <Source
        id={BASE_LAYER_ID}
        type="geojson"
        data={zonesGeoJSON ?? { type: 'FeatureCollection', features: [] }}
      >
        <Layer
          id={BASE_LAYER_ID}
          type="fill"
          minzoom={showLayerZoom}
          layout={{
            visibility: zonesVisible ? 'visible' : 'none',
          }}
          paint={{
            'fill-color': 'white',
            'fill-opacity': 0.75,
            'fill-outline-color': '#000',
          }}
          beforeId={stateLabelIds[0]}
        />
        <Layer
          id="zone-numbers"
          type="symbol"
          minzoom={8}
          layout={{
            visibility: zonesVisible ? 'visible' : 'none',
            'text-field': ['concat', '', ['get', zoneDisplayNumber]],
            'text-size': 40,
          }}
          paint={{
            'text-color': 'black',
            'text-halo-color': 'rgba(255, 255, 255, 0.75)',
            'text-halo-width': 5,
          }}
          beforeId={BASE_LAYER_ID}
        />
      </Source>
      {/* <ContextMenu /> */}
    </>
  );
}

function HeatmapSource({
  url,
  layerInfo,
  showLayerZoom,
  minWOMThreshold,
  topLocationsByCity,
  beforeId,
  opacity,
}: {
  url: string;
  layerInfo: LayerInfo[];
  showLayerZoom: number;
  minWOMThreshold: number | null;
  topLocationsByCity: {
    [cityName: string]:
      | (FeatureCollection<Point> & {
          properties: GeoJsonProperties;
        })
      | WithWOM<
          FeatureCollection<Point> & {
            properties: GeoJsonProperties;
          }
        >;
  } | null;
  beforeId: string | undefined;
  opacity: number;
}) {
  const heatmapVisible = useDynamicMapStore((state) => state.heatmapVisible);

  const roadLabelIds = useLayerIds((layer) =>
    layer.id.startsWith('road-label'),
  );

  return (
    <Source id={url} type="vector" url={url}>
      {minWOMThreshold != null
        ? layerInfo.map((layer) => (
            <Layer
              id={`retail_proximity_heatmap-${layer.id}`}
              key={`retail_proximity_heatmap-${layer.id}`}
              type="fill"
              source-layer={layer.id}
              minzoom={showLayerZoom}
              layout={{
                visibility: heatmapVisible ? 'visible' : 'none',
              }}
              beforeId={beforeId}
              paint={{
                'fill-color': [
                  'case',
                  [
                    'all',
                    ['>=', ['get', 'wom'], minWOMThreshold * 0.8],
                    ['!=', ['get', 'vmt'], null],
                  ],
                  'red', // If wom >= threshold and vmt is not null, color red
                  [
                    'all',
                    ['>=', ['get', 'wom'], minWOMThreshold * 0.8],
                    ['==', ['get', 'dtt'], null],
                  ],
                  'yellow', // If wom >= threshold, vmt is null and dtt is null, color yellow
                  ['>=', ['get', 'wom'], minWOMThreshold * 0.8],
                  [
                    'interpolate',
                    ['linear'],
                    ['get', 'dtt'],
                    0,
                    'red',
                    2,
                    'orange', // Lower 'dtt' is better
                    6,
                    'yellow',
                    10,
                    'green', // Higher 'dtt' is worse
                  ], // If wom >= threshold, interpolate based on 'dtt'
                  'grey', // Default color (if wom < threshold)
                ],
                'fill-opacity': opacity ?? 0,
              }}
            />
          ))
        : topLocationsByCity
          ? Object.entries(topLocationsByCity).map(([cityName, cityData]) => {
              if (hasWOM(cityData)) {
                const hasWOMRange =
                  cityData.wom.min !== cityData.wom.max &&
                  cityData.features.length > 2;
                const lowerBound =
                  cityData.wom.max -
                  (cityData.wom.max - cityData.wom.average) / 2;
                const range = hasWOMRange
                  ? cityData.wom.max - cityData.wom.min
                  : cityData.wom.max - lowerBound;

                return hasWOMRange ? (
                  <Layer
                    id={`retail_proximity_heatmap-${cityName}`}
                    key={`retail_proximity_heatmap-${cityName}`}
                    type="fill"
                    source-layer={cityName}
                    minzoom={showLayerZoom}
                    layout={{
                      visibility: heatmapVisible ? 'visible' : 'none',
                    }}
                    paint={{
                      'fill-color': [
                        'interpolate',
                        ['linear'],
                        [
                          'case',
                          ['any', ['==', ['get', 'wom'], 0]],
                          -100,
                          ['/', ['-', ['get', 'wom'], cityData.wom.min], range],
                        ],
                        -2,
                        'grey',
                        -1.5,
                        'purple',
                        -0.5,
                        'blue',
                        -0.25,
                        'teal',
                        0,
                        'yellow',
                        0.35,
                        'orange',
                        0.6,
                        'red',
                      ],
                      'fill-opacity': opacity ?? 0,
                    }}
                    // beforeId={beforeId ?? roadLabelIds[0]}
                  />
                ) : (
                  <Layer
                    id={`retail_proximity_heatmap-${cityName}`}
                    key={`retail_proximity_heatmap-${cityName}`}
                    type="fill"
                    source-layer={cityName}
                    minzoom={showLayerZoom}
                    layout={{
                      visibility: heatmapVisible ? 'visible' : 'none',
                    }}
                    paint={{
                      'fill-color': [
                        'interpolate',
                        ['linear'],
                        [
                          'case',
                          ['any', ['==', ['get', 'wom'], 0]],
                          -100,
                          ['/', ['-', ['get', 'wom'], lowerBound], range],
                        ],
                        -3,
                        'grey',
                        -2.5,
                        'purple',
                        -2,
                        'blue',
                        -1.5,
                        'teal',
                        -0.5,
                        'yellow',
                        -0.25,
                        'orange',
                        0,
                        'red',
                      ],
                      'fill-opacity': opacity ?? 0,
                    }}
                    // beforeId={beforeId ?? roadLabelIds[0]}
                  />
                );
              }

              return null;
            })
          : null}
    </Source>
  );
}
