import { Result } from '@mapbox/mapbox-gl-geocoder';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import { parseEnv } from '@plotr/common-utils';
import { CustomPin, plotrMultiplayerData } from '@plotr/plotr-multiplayer-data';
import { useCallback, useEffect, useRef } from 'react';
import MapGL, { MapRef, NavigationControl } from 'react-map-gl';
import { v4 as randomUUID } from 'uuid';
import './DynamicMap.css';

import useSettingsStore from '../../global/hooks/useSettingsStore';
import DemographicPointLookup from '../demographic-point-lookup/DemographicPointLookup';
import DynamicMapController from '../dynamic-map-controller/DynamicMapController';
import GeocoderControl from './components/GeocoderControl';
import { MFHSource } from './components/MFHSource';
import { POISource } from './components/POISource/POISource';
import useUserResources from './hooks/useUserResources';

import useResizeObserver from '~/src/common/hooks/useResizeObserver';
import { MapProvider } from '~/src/features/dynamic-map/hooks/useMapContext';
import useCustomPins from '~/src/global/hooks/useCustomPins';
import MapContextMenu from '../context-menu/MapContextMenu';
import ToggleDrawerButton from '../custom-drawer/ToggleDrawerButton';
import CustomPinMarker from '../custom-pins/CustomPinMarker';
import CustomDrawnTerritories from '../custom-territories/sources/CustomDrawnTerritories';
import CustomTerritories from '../custom-territories/sources/CustomTerritories';
import DataLayerHovercard from '../data-hover-card/DataLayerHovercard';
import EditPinPopup from '../demographic-point-lookup/components/EditPinPopup';
import DemographicInformationController from '../dynamic-map-controller/DemographicInfoController';
import ClientGeometrySource from './components/ClientGeometriesSource';
import CustomLayersSource from './components/CustomLayersSource/CustomLayersSource';
import MapLegend from './components/MapLegend';
import { ZipCodesSource } from './components/ZipCodesSource';
import useDynamicMapStore from './hooks/useDynamicMapStore';
import useDemographicSearch from '../demographic-point-lookup/hooks/useDemographicSearch';

const env = parseEnv({ MAPBOX_API_KEY: process.env.MAPBOX_API_KEY });

const CustomPins = () => {
  const customPins = useCustomPins();
  const hiddenPinGroups = useDynamicMapStore((state) => state.hiddenPinGroups);

  const filteredPins = customPins.filter(
    (pin) => !hiddenPinGroups.includes(pin.group)
  );

  return (
    <>
      {filteredPins.map((pin) => (
        <CustomPinMarker pin={pin} key={pin.id} />
      ))}
    </>
  );
};

export default function DynamicMap() {
  const mapRef = useRef<MapRef | null>(null);
  const mapContainerRef = useRef<HTMLElement | null>(null);
  const mapStyle = useDynamicMapStore((state) => state.mapStyle);
  const showLayerZoom = useDynamicMapStore((state) => state.showLayerZoom);
  const drawerMenuOpen = useDynamicMapStore((state) => state.drawerMenuOpen);
  const setDrawerMenuOpen = useDynamicMapStore(
    (state) => state.setDrawerMenuOpen
  );
  const isEditingTerritory = useDynamicMapStore(
    (state) => state.isEditingTerritory
  );

  // user settings are loaded first, in UserDashboard.tsx
  const userSettings = useSettingsStore((state) => state.userSettings);
  const { isLoading } = useUserResources(userSettings);

  const customPinMethods = plotrMultiplayerData.methods?.pins;

  const selectCustomPinId = useDynamicMapStore(
    (state) => state.selectCustomPinId
  );

  const resizeCallback = useCallback(() => {
    if (mapRef.current) {
      mapRef.current.resize();
    }
  }, []);

  useResizeObserver(mapContainerRef, resizeCallback, 50);

  // handle searching for demographic data
  useDemographicSearch();

  const zoomLevel = useDynamicMapStore((state) => state.zoomLevel);
  const setZoomLevel = useDynamicMapStore((state) => state.setZoomLevel);

  const lastLocation = useDynamicMapStore((state) => state.lastLocation);
  const setLastLocation = useDynamicMapStore((state) => state.setLastLocation);

  const toggleDrawer = () => {
    setDrawerMenuOpen(!drawerMenuOpen);
  };

  const evaluatedPinId = useDynamicMapStore((state) => state.evaluatedPinId);

  // if evaluatedPinId is set, open the drawer menu to show the demographic info
  useEffect(() => {
    if (evaluatedPinId != null) {
      setDrawerMenuOpen(true);
    }
  }, [evaluatedPinId, setDrawerMenuOpen]);

  //halo around map if in edit mode
  useEffect(() => {
    if (mapContainerRef.current) {
      if (isEditingTerritory) {
        mapContainerRef.current.classList.add('edit-mode-halo');
      } else {
        mapContainerRef.current.classList.remove('edit-mode-halo');
      }
    }
  }, [isEditingTerritory]);

  return (
    <MapProvider map={mapRef.current}>
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <DemographicInformationController />
        <Box
          flex={1}
          maxWidth={'100%'}
          ref={mapContainerRef}
          position="relative"
        >
          <MapGL
            styleDiffing={false}
            ref={mapRef}
            mapboxAccessToken={env.MAPBOX_API_KEY}
            mapStyle={`mapbox://styles/mapbox/${mapStyle}`}
            attributionControl={true}
            initialViewState={{
              zoom: zoomLevel,
              latitude: lastLocation.lat,
              longitude: lastLocation.lng,
            }}
            onMoveEnd={(e) => {
              setZoomLevel(e.viewState.zoom);
              setLastLocation({
                lat: e.viewState.latitude,
                lng: e.viewState.longitude,
              });
            }}
          >
            <MapContextMenu />
            <DemographicPointLookup mapContainerRef={mapContainerRef} />
            <NavigationControl position="bottom-right" />
            {/*
              Wait for customPinMethods to be defined before rendering GeocoderControl.
              This is necessary because GeocoderControl will add a custom pin on address
              search, and it doesn't re-render when onResult dependencies change.
            */}
            {customPinMethods != null && (
              <GeocoderControl
                mapboxAccessToken={env.MAPBOX_API_KEY}
                position="top-left"
                limit={4}
                marker={false}
                onResult={(e: { result: Result }) => {
                  // drop a custom pin if result is an address
                  if (e.result && e.result?.place_type[0] === 'address') {
                    const newPin: CustomPin = {
                      id: randomUUID(),
                      group: 'Default Group',
                      label: e.result.text,
                      tags: [],
                      pos: { lng: e.result.center[0], lat: e.result.center[1] },
                      keyValuePairs: {},
                    };

                    customPinMethods.addPin(newPin);
                    selectCustomPinId(newPin.id);
                  }
                }}
              />
            )}
            <DataLayerHovercard />
            {userSettings != null && (
              <ZipCodesSource showLayerZoom={showLayerZoom} />
            )}
            {userSettings?.featureFlags?.territories && (
              <>
                <CustomTerritories />
                <CustomDrawnTerritories />
              </>
            )}
            <CustomLayersSource />
            <ClientGeometrySource
              beforeId={undefined}
              showLayerZoom={0}
              enabled={true}
              opacity={0.8}
            />
            <MFHSource />
            <POISource />
            <CustomPins />
            <EditPinPopup />
          </MapGL>
          <ToggleDrawerButton
            toggleDrawer={toggleDrawer}
            drawerOpen={drawerMenuOpen}
          />
          <MapLegend />
        </Box>
        <DynamicMapController />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </MapProvider>
  );
}
