import { useCallback, useEffect, useState } from 'react';
import { generateReport } from '~/src/global/services/idealspotReportService';

interface UseGenerateReportProps {
  lat?: number;
  lng?: number;
  accessToken: string | null;
  reportTemplateId?: string;
}

interface UseGenerateReportReturn {
  generate: () => void;
  isLoading: boolean;
  error: boolean;
  url: string | null;
}

export const useGenerateReport = ({
  lat,
  lng,
  accessToken,
  reportTemplateId,
}: UseGenerateReportProps): UseGenerateReportReturn => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    setUrl(null);
  }, [lat, lng]);

  const generate = useCallback(() => {
    if (!accessToken || !lat || !lng) {
      setError(true);
      return;
    }

    setIsLoading(true);
    setError(false);

    generateReport(lat, lng, accessToken, reportTemplateId)
      .then((resultUrl) => {
        setIsLoading(false);
        if (resultUrl) {
          setUrl(resultUrl);
        } else {
          setError(true);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(true);
        console.error('Error generating report:', error);
      });
  }, [lat, lng, accessToken, reportTemplateId]);

  return { generate, isLoading, error, url };
};
