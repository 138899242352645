import { create } from 'zustand';

interface Point {
  lng: number;
  lat: number;
}

export interface ContextMenuOption {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
  remainOpenOnClick?: boolean;
}

export interface ContextMenuStore {
  position: Point | null;
  offset: [number, number];
  options: ContextMenuOption[];

  setContextMenu: (
    store: Partial<Pick<ContextMenuStore, 'options' | 'position' | 'offset'>>,
  ) => void;
}

const useContextMenuStore = create<ContextMenuStore>((set) => ({
  position: null,
  offset: [0, 0],
  options: [],

  setContextMenu: (store) => set(store),
}));

export default useContextMenuStore;
