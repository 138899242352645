import type { ReactNode } from 'react';
import { MultiplayerData, MultiplayerMethods } from '@plotr/multiplayer-data';

import useRoomPassword from './helpers/useRoomPassword';
import useRoomConnection from './helpers/useRoomConnection';
import usePresence from './helpers/usePresence';
import { MultiplayerContext } from './helpers/useMultiplayerContext';

export interface MultiplayerProviderProps {
  room: string | null;
  token: string | null;
  api: string;
  signaling: string;
  children: ReactNode;
  data: MultiplayerData<MultiplayerMethods, Record<string, MultiplayerMethods>>;
}

const MultiplayerProvider = (props: MultiplayerProviderProps): JSX.Element => {
  const { room, token, api, signaling, children, data } = props;

  const { password, error } = useRoomPassword(api, room, token);

  const { provider, ydoc, isLoading } = useRoomConnection({
    signaling,
    api,
    room,
    token,
    password,
    data,
  });
  const { presence, localPresence, setLocalPresence } = usePresence(provider);

  return (
    <MultiplayerContext.Provider
      value={{
        setLocalPresence,
        presence,
        localPresence,
        isLoading,
        error,
        ydoc,
      }}
    >
      {children}
    </MultiplayerContext.Provider>
  );
};

export default MultiplayerProvider;
