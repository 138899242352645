import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import formatNumber from '~/src/common/helpers/formatNumber';
import hexToRGBA from '~/src/common/helpers/hexToRGBA';
import useLayersStore, { formatValue } from '../hooks/useLayersStore';
import useTrafficStore from '../hooks/useTrafficStore';

function generateSharpGradient(colors: string[]): string {
  const step = 100 / colors.length;
  const gradientParts = colors.map((color, index) => {
    return `${color} ${index * step}%, ${color} ${(index + 1) * step}%`;
  });
  return `linear-gradient(to right, ${gradientParts.join(', ')})`;
}

const MapLegendContainer = (props: { children: React.ReactNode }) => {
  const [expanded, setExpanded] = useState(true);
  const showData = useLayersStore((state) => state.showData);
  const setShowData = useLayersStore((state) => state.setShowData);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box
      position="absolute"
      right="4rem"
      bottom="3rem"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      sx={{
        transition: 'all 0.5s', // Smooth transition for the whole container
      }}
    >
      <Card raised>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Legend
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {/* Divider or additional spacing here if needed */}
              <Divider
                orientation="vertical"
                flexItem
                sx={{ height: '24px', mx: 2 }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={showData}
                    onChange={(e) => setShowData(e.target.checked)}
                  />
                }
                label="Show Data"
                sx={{ marginRight: '1rem' }}
              />
              <IconButton size="small" onClick={handleExpandClick}>
                {expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </Box>
          </Box>

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                paddingTop: '1rem',
              }}
            >
              {props.children}
            </Box>
          </Collapse>
        </CardContent>
      </Card>
    </Box>
  );
};

interface BaseLayerProps {
  title: string;
  uniform: boolean;
}

interface UniformLayerProps extends BaseLayerProps {
  colors: string; // Single color for uniform layers
  uniform: true;
}

interface NonUniformLayerProps extends BaseLayerProps {
  boundLabels?: string[];
  lowerBoundValue?: number | string;
  upperBoundValue?: number | string;
  unit?: string;
  colors: string[]; // Array of colors for non-uniform layers
  threshold?: number[] | string[];
  opacityStops?: number[];
  uniform: false;
  hideBoundValues?: boolean;
}

type MapLegendProps = UniformLayerProps | NonUniformLayerProps;

const SingleMapLegend = (props: MapLegendProps) => {
  if (props.uniform) {
    const { colors } = props;
    return (
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          paddingBottom: '.5rem',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        {/* Title to the left */}
        <Typography
          variant="body2"
          sx={{
            maxWidth: '150px', // Set a maximum width for the title
            whiteSpace: 'normal', // Allow wrapping
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginRight: 1, // Add some margin to the right of the title
          }}
        >
          {props.title}
        </Typography>

        {/* Color block representation */}
        <Box
          sx={{
            width: 200, // Fixed width for the color block
            height: '10px', // Fixed height for the color block
            backgroundColor: colors, // Background color from the props
            borderRadius: '4px', // Optional: Rounded corners for the color block
          }}
        />
      </Paper>
    );
  } else {
    const {
      title,
      boundLabels,
      lowerBoundValue,
      upperBoundValue,
      unit,
      colors,
      opacityStops = [],
      threshold = [],
      hideBoundValues = false,
    } = props;

    const isNumericThreshold = typeof threshold[0] === 'number';
    const isWithoutThreshold = threshold.length === 0;

    // scale color widths based on threshold
    const thresholdRatios = isNumericThreshold
      ? (threshold as number[]).map(
          (t, _, arr) => (t - arr[0]) / (arr[arr.length - 1] - arr[0])
        )
      : [];

    const linearColorGradient = isNumericThreshold
      ? colors.map((color, index) => {
          try {
            return `${hexToRGBA(color, opacityStops[index])} ${Math.round(thresholdRatios[index] * 100)}%`;
          } catch (error) {
            console.error(error);
            return `${color} ${Math.round(thresholdRatios[index] * 100)}%`;
          }
        })
      : [];

    return (
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'start',
          paddingBottom: '.5rem',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        {/* Title to the left */}
        <Box>
          <Typography
            variant="body2"
            sx={{
              maxWidth: '150px', // Set a maximum width for the title
              whiteSpace: 'normal', // Allow wrapping
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              marginRight: 1, // Add some margin to the right of the title
            }}
          >
            {title}
          </Typography>
          {unit && <Typography variant="caption">({unit})</Typography>}
        </Box>

        {/* Fixed width color scale and bounds */}
        <Box sx={{ width: 200, marginLeft: 2, alignItems: 'flex-end' }}>
          <div
            style={{
              height: 10,
              backgroundImage: isNumericThreshold
                ? `linear-gradient(to right, ${linearColorGradient})`
                : isWithoutThreshold
                  ? `linear-gradient(to right, ${colors.toString()})`
                  : generateSharpGradient(colors),
              borderRadius: 5,
            }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: 1,
            }}
          >
            <Typography variant="caption">
              {boundLabels ? boundLabels[0] : null}
              {!hideBoundValues && lowerBoundValue !== undefined
                ? ` (${
                    typeof lowerBoundValue === 'number'
                      ? formatNumber(lowerBoundValue, 0)
                      : lowerBoundValue
                  })`
                : ''}
            </Typography>
            <Typography variant="caption">
              {boundLabels ? boundLabels[1] : null}
              {!hideBoundValues && upperBoundValue !== undefined
                ? ` (${
                    typeof upperBoundValue === 'number'
                      ? formatNumber(upperBoundValue, 0)
                      : upperBoundValue
                  })`
                : ''}
            </Typography>
          </Box>
        </Box>
      </Paper>
    );
  }
};

export default function MapLegend() {
  const layers = useLayersStore((state) => state.layers);
  const trafficRange = useTrafficStore((state) => state.trafficRange);
  const isLoading = useTrafficStore((state) => state.isLoading);

  return !isLoading && layers.length > 0 ? (
    <MapLegendContainer>
      {layers.map((layer, index) => {
        const isUniform = layer.styleConfig.colors.length === 1;

        if (isUniform) {
          return (
            <SingleMapLegend
              key={index}
              uniform={isUniform}
              title={layer.displayName}
              colors={layer.styleConfig.colors[0]}
            />
          );
        } else {
          const lowerBoundValue = layer.styleConfig.threshold?.[0];
          const upperBoundValue =
            layer.styleConfig.threshold?.[
              layer.styleConfig.threshold?.length - 1
            ];
          const format = layer.styleConfig.format;

          return (
            <SingleMapLegend
              key={index}
              uniform={isUniform}
              title={layer.displayName}
              boundLabels={layer.styleConfig.boundLabels}
              colors={layer.styleConfig.colors}
              threshold={layer.styleConfig.threshold}
              opacityStops={layer.styleConfig.opacityStops}
              lowerBoundValue={
                layer.insight === 'traffic_volume'
                  ? trafficRange.min
                  : isUniform || lowerBoundValue == null
                    ? undefined
                    : (formatValue(lowerBoundValue, format) as string | number)
              }
              upperBoundValue={
                layer.insight === 'traffic_volume'
                  ? trafficRange.max
                  : isUniform || upperBoundValue == null
                    ? undefined
                    : (formatValue(upperBoundValue, format) as string | number)
              }
              unit={layer.styleConfig.unit}
              hideBoundValues={layer.styleConfig.hideBoundValues}
            />
          );
        }
      })}
    </MapLegendContainer>
  ) : null;
}
