import { useState } from 'react';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';

interface EditEntityDialogProps {
  isOpen: boolean;
  dialogTitle: string;
  children: React.ReactNode;
  isDeletable?: boolean;
  entityName?: string;
  saveDisabled?: boolean;
  inputError?: string;
  onClose?: () => void;
  onSave?: () => void;
  onDelete?: () => void;
}

export default function EditEntityDialog(props: EditEntityDialogProps) {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const handleDelete = () => {
    props.onDelete?.();
    setIsDeleteOpen(false);
  };

  return (
    <>
      <Dialog open={props.isOpen} onClose={props.onClose}>
        <DialogTitle>{props.dialogTitle}</DialogTitle>
        <DialogContent>
          <Box padding={2} style={{ width: 400 }}>
            {props.inputError && (
              <Alert sx={{ marginBottom: 4 }} severity="error">
                {props.inputError}
              </Alert>
            )}
            <Grid
              container
              spacing={2}
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={(e) => {
                e.preventDefault();
                props.onSave?.();
              }}
            >
              {props.children}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          {props.isDeletable && (
            <Button
              color="error"
              sx={{ marginRight: 'auto' }}
              onClick={() => setIsDeleteOpen(true)}
            >
              Delete
            </Button>
          )}
          <Button onClick={props.onClose}>Cancel</Button>
          <Button onClick={props.onSave} disabled={props.saveDisabled ?? false}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <DeleteConfirmationDialog
        isOpen={isDeleteOpen}
        onClose={() => {
          setIsDeleteOpen(false);
        }}
        onConfirm={() => {
          handleDelete();
          props.onClose?.();
        }}
        itemName={props.entityName}
      />
    </>
  );
}
