import { Grid } from '@mui/material';
import { CustomTerritory } from '@plotr/plotr-multiplayer-data/src';
import useCustomTerritories from '../../dynamic-map/hooks/useCustomTerritories';
import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';
import { BoundaryCard } from './BoundaryCard';

type BoundaryCardsProps = {
  removeAllBoundaries: () => void;
  removeSingleBoundary: (boundaryId: string) => void;
};

export const BoundaryCards = ({ removeSingleBoundary }: BoundaryCardsProps) => {
  const customTerritories = useCustomTerritories();
  const evaluatedTerritoryId = useDynamicMapStore(
    (state) => state.evaluatedTerritoryId
  );

  const territory = customTerritories.find(
    (territory: CustomTerritory) => territory.id === evaluatedTerritoryId
  );

  return (
    <Grid container spacing={1} flexShrink={1} overflow="auto">
      {territory != null &&
        Object.values(territory.boundaries ?? {}).map((boundary: any) => (
          <Grid item xs={6} key={boundary.id}>
            <BoundaryCard
              boundaryId={boundary.id}
              onRemove={() => removeSingleBoundary(boundary.id)}
            />
          </Grid>
        ))}
    </Grid>
  );
};
