import { Layer } from 'react-map-gl';
import useDynamicMapStore from '../../../hooks/useDynamicMapStore';
import useLayerIds from '../../../hooks/useLayerIds';
import useMapImage from '../../../hooks/useMapImage';
import { ExtendedBrandResult } from '../../../services/poiService';
import { defaultPOILayer, generatePOIFilter } from '../POIHelpers';

export default function POIBrandPinLayer({
  brandResult: brand,
}: {
  brandResult: ExtendedBrandResult;
}) {
  const layerId = `poi-${brand.id}`;
  const imageId = `${brand.id}-pin`;

  const badgeId = brand.badge?.id;
  const [badgeLayer] = useLayerIds(
    (layer) => layer.id === `poi-${badgeId}-badge`,
  );

  const poisEnabled = useDynamicMapStore((state) => state.poisEnabled);
  const clickedPoiFeature = useDynamicMapStore(
    (state) => state.clickedPOiFeature,
  );
  const isPinReady = useMapImage({
    id: imageId,
    src: brand.pinImage,
  });

  return (
    <>
      <Layer
        {...defaultPOILayer}
        id={layerId}
        layout={{
          ...defaultPOILayer.layout,
          ...(isPinReady ? { 'icon-image': imageId } : {}),
          visibility: poisEnabled ? 'visible' : 'none',
        }}
        filter={
          clickedPoiFeature
            ? [
                'all',
                generatePOIFilter([brand.id]),
                ['!=', ['get', 'id'], clickedPoiFeature?.properties?.id],
              ]
            : generatePOIFilter([brand.id])
        }
        metadata={{
          brandId: brand.id,
          brandName: brand.name,
        }}
        beforeId={badgeLayer}
      />
    </>
  );
}
