import { useEffect, useState } from 'react';
import useMapContext from './useMapContext';

interface MapPinProps {
  id: string;
  src?: string;
  onError?: (error: Error) => void;
  onSuccess?: (image: HTMLImageElement | ImageBitmap) => void;
}

export default function useMapImage({
  id,
  src,
  onError,
}: MapPinProps): boolean {
  const map = useMapContext();
  const [loadedImage, setLoadedImage] = useState<
    HTMLImageElement | ImageBitmap | null
  >(null);

  // Load the image
  useEffect(() => {
    if (map == null || src == null || loadedImage != null) return;

    map.loadImage(src, (error, image) => {
      if (error) {
        onError?.(error);
        return;
      }

      if (image != null) {
        setLoadedImage(image);
      }
    });
  }, [map, id, src, onError, loadedImage]);

  // Add the image to the map
  useEffect(() => {
    if (map == null || loadedImage == null) return;

    if (!map.hasImage(id)) {
      map.addImage(id, loadedImage);
    }
  }, [map, id, src, loadedImage]);

  // Add image again for "styleimagemissing" events
  useEffect(() => {
    if (map == null || loadedImage == null) return;

    const styleImageMissingHandler = (event: { id: string }) => {
      if (event.id === id) {
        map.addImage(id, loadedImage);
      }
    };

    map.on('styleimagemissing', styleImageMissingHandler);

    return () => {
      map.off('styleimagemissing', styleImageMissingHandler);
    };
  }, [map, id, src, loadedImage]);

  return map?.hasImage(id) ?? false;
}
