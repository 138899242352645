import { create } from 'zustand';
import type { ClientZonesByUrbanArea } from '../services/zonesService';
import * as ZonesService from '../services/zonesService';

export interface ZonesStore {
  zones: ClientZonesByUrbanArea | null;
  error: Error | null;
  isLoading: boolean;
  fetchZones(apiPath: string): Promise<void>;
}

const useZonesStore = create<ZonesStore>((set) => ({
  zones: null,
  error: null,
  isLoading: false,
  async fetchZones(apiPath: string): Promise<void> {
    set({ isLoading: true, error: null });

    try {
      const zones = await ZonesService.fetchClientZones(apiPath);

      if (zones == null) {
        set({ error: new Error('No zones found'), isLoading: false });
      } else {
        set({ zones, error: null, isLoading: false });
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        set({ error, isLoading: false });
      } else {
        console.error('Error fetching zones: ', error);
        set({ error: new Error('Unknown error'), isLoading: false });
      }
    }
  },
}));

export default useZonesStore;
