import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Paper, Typography } from '@mui/material';

export const BoundaryCard = ({
  boundaryId,
  onRemove,
}: {
  boundaryId: string;
  onRemove: () => void;
}) => {
  return (
    <Paper
      key={boundaryId}
      variant="outlined"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 1,
        marginBottom: 1,
        height: '100%',
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Typography variant="body2" color="textPrimary">
          {boundaryId}
        </Typography>
      </Box>
      <IconButton aria-label="remove" onClick={onRemove} size="small">
        <DeleteIcon />
      </IconButton>
    </Paper>
  );
};
