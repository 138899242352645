import { create } from 'zustand';
import * as POIService from '../services/poiService';
import type { POIGroupRequest, POIGroupResults } from '../services/poiService';

export interface POIStore {
  poiGroups: POIGroupResults[];
  error: Error | null;
  isLoading: boolean;
  // _activeFetchCount: to sync the isLoading state across multiple simultaneous fetches
  _activeFetchCount: number;
  fetchPOIGroups: (
    poiGroupRequests: POIGroupRequest[],
    // setter: if provided, allows custom merging of brands rather than full replacement
    setter?: (
      prevResults: POIGroupResults[],
      poiGroupResults: POIGroupResults[],
    ) => POIGroupResults[],
  ) => Promise<void>;
}

const usePOIStore = create<POIStore>((set, get) => ({
  poiGroups: [],
  error: null,
  isLoading: false,
  _activeFetchCount: 0,
  fetchPOIGroups: async (poiGroupRequests, setter) => {
    set({
      isLoading: true,
      _activeFetchCount: get()._activeFetchCount + 1,
    });

    try {
      const poiGroupResults = (
        await Promise.all(poiGroupRequests.map(POIService.fetchPOIGroup))
      ).flat();

      if (typeof setter === 'function') {
        set({
          poiGroups: setter(get().poiGroups, poiGroupResults),
        });
      } else {
        set({ poiGroups: poiGroupResults });
      }
    } catch (error) {
      if (error instanceof Error) {
        set({ error });
      } else {
        console.error('Error fetching POI groups', error);
      }
    } finally {
      const newFetchCount = get()._activeFetchCount - 1;

      set({
        isLoading: newFetchCount > 0,
        _activeFetchCount: newFetchCount,
      });
    }
  },
}));

export default usePOIStore;
