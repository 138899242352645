import { debounce } from '@plotr/common-utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CircularProgress,
  Button,
  ButtonGroup,
  Chip,
  Grid,
  Slider,
} from '@mui/material';
import { CloudDownload as CloudDownloadIcon } from '@mui/icons-material';

import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import { useGenerateReport } from '~/src/features/dynamic-map/hooks/useGenerateReport';
import useAccessToken from '~/src/global/hooks/useAccessToken';
import useCustomPins from '~/src/global/hooks/useCustomPins';
import { checkPermission } from '~/src/global/services/permissionService';
import useDemographicStore, {
  searchSettings,
} from '../hooks/useDemographicStore';
import { PointBoundaryType } from '../services/getPointBoundary';

const BOUNDARY_TYPES = ['radius', 'driving', 'walking', 'cycling'] as const;

const PointEvalMenu = () => {
  const [canDownloadReport, setCanDownloadReport] = useState(false);

  const searchRadius = useDemographicStore(
    (state) => state.demographicSearchRadius
  );
  const setSearchRadius = useDemographicStore(
    (state) => state.setDemographicSearchRadius
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps -- debounce has no deps
  const debouncedSetSearchRadius = useCallback(debounce(setSearchRadius, 500), [
    setSearchRadius,
  ]);

  const [localRadius, setLocalRadius] = useState(searchRadius);

  const { accessToken } = useAccessToken();

  useEffect(() => {
    if (accessToken) {
      checkPermission(accessToken, 'read:idealspot_reporting').then(
        setCanDownloadReport
      );
    }
  }, [accessToken]);

  // Update the local radius when the search radius changes
  useEffect(() => {
    setLocalRadius(searchRadius);
  }, [searchRadius]);

  // Reduce API calls by only updating the search radius when the user stops moving the slider
  useEffect(() => {
    if (localRadius !== null) {
      debouncedSetSearchRadius(localRadius);
    }
  }, [localRadius, debouncedSetSearchRadius]);

  const searchType: PointBoundaryType = useDemographicStore(
    (state) => state.boundaryType
  );
  const setSearchType = useDemographicStore(
    (state) => state.setDemographicBoundaryType
  );

  const evaluatedPinId = useDynamicMapStore((state) => state.evaluatedPinId);
  const customPins = useCustomPins();

  const evaluatedPin = useMemo(() => {
    return evaluatedPinId == null
      ? null
      : customPins.find(({ id }) => id === evaluatedPinId) ?? null;
  }, [customPins, evaluatedPinId]);

  const pinPos = useMemo(() => {
    const lng = evaluatedPin?.pos.lng;
    const lat = evaluatedPin?.pos.lat;

    return lat == null || lng == null ? null : { lng, lat };
  }, [evaluatedPin?.pos.lng, evaluatedPin?.pos.lat]);

  const {
    generate,
    isLoading: reportIsLoading,
    error: reportError,
    url: reportUrl,
  } = useGenerateReport({
    lat: pinPos?.lat,
    lng: pinPos?.lng,
    accessToken,
  });

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={9.5}>
        <ButtonGroup
          color="primary"
          aria-label="outlined primary button group"
          variant="outlined"
          fullWidth
        >
          {BOUNDARY_TYPES.map((type) => (
            <Button
              key={type}
              fullWidth
              onClick={() => setSearchType(type as typeof searchType)}
              variant={searchType === type ? 'contained' : 'outlined'}
            >
              {type}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>
      <Grid item xs={2.5}>
        <Chip
          label={`${searchRadius} ${searchType === 'radius' ? 'mi' : 'min'}`}
        />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center">
        <Slider
          value={localRadius ?? searchSettings[searchType].min}
          onChange={(_, radius) => setLocalRadius(radius as number)}
          step={searchSettings[searchType].step}
          marks={searchSettings[searchType].marks}
          min={searchSettings[searchType].min}
          max={searchSettings[searchType].max}
          valueLabelDisplay="off"
          sx={{ width: '85%' }}
        />
      </Grid>
      {canDownloadReport && (
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            startIcon={
              reportIsLoading ? (
                <CircularProgress size={24} />
              ) : (
                <CloudDownloadIcon />
              )
            }
            onClick={
              reportUrl ? () => window.open(reportUrl, '_blank') : generate
            }
            variant={reportUrl ? 'contained' : 'outlined'}
            disabled={reportIsLoading}
          >
            {reportIsLoading
              ? 'Generating...'
              : reportUrl
                ? 'Report Ready - View Here'
                : reportError
                  ? 'Report Generation Failed - Retry?'
                  : 'Download Full Report'}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default PointEvalMenu;
