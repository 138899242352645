import { Box, CircularProgress, Typography } from '@mui/material';
import useDynamicMapStore from '../dynamic-map/hooks/useDynamicMapStore';
import PointEvalMenu from './components/PointEvalMenu';
import SummaryDisplay from './components/SummaryDisplay';
import useDemographicStore from './hooks/useDemographicStore';

export default function DemographicSummary() {
  const summaryData = useDemographicStore(
    (state) => state.demographicSummaryData
  );

  const isDemographicDataLoading = useDemographicStore(
    (state) => state.isDemographicDataLoading
  );

  const evaluatedDemographicEntity = useDynamicMapStore(
    (state) => state.evaluatedDemographicEntity
  );

  const evaluatedDemographicPoint =
    evaluatedDemographicEntity?.type === 'point'
      ? evaluatedDemographicEntity.pos
      : null;

  return (
    <Box height={1} display="flex" flexDirection="column">
      {evaluatedDemographicPoint && (
        <Box flex="0 1 auto" paddingBottom={2}>
          <PointEvalMenu />
        </Box>
      )}
      <Box flex="1">
        {isDemographicDataLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
            sx={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}
          >
            <Box textAlign="center">
              <CircularProgress />
              <Typography variant="h6" sx={{ marginTop: 2 }}>
                Getting Pulse...
              </Typography>
            </Box>
          </Box>
        ) : (
          <SummaryDisplay dataPoints={summaryData?.data ?? []} />
        )}
      </Box>
    </Box>
  );
}
