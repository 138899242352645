import { Feature, Point, Polygon, MultiPolygon } from 'geojson';
import turfCircle from '@turf/circle';
import { parseEnv } from '@plotr/common-utils/src';

const env = parseEnv({ MAPBOX_API_KEY: process.env.MAPBOX_API_KEY });

export type PointBoundaryType = 'radius' | 'driving' | 'cycling' | 'walking';

export interface GetPointBoundaryParams {
  pointFeature: Feature<Point>;
  boundaryType: PointBoundaryType;
  radius?: number;
}

export default async function getPointBoundary(
  params: GetPointBoundaryParams
): Promise<Feature<Polygon | MultiPolygon>> {
  const isRadiusSearch = params.boundaryType === 'radius';
  const pointLng = params.pointFeature.geometry.coordinates[0];
  const pointLat = params.pointFeature.geometry.coordinates[1];

  if (isRadiusSearch && params.radius != null) {
    const circleBoundary = turfCircle([pointLng, pointLat], params.radius, {
      steps: 64,
      units: 'miles',
    });

    // Add a center property to circleBoundary's properties
    circleBoundary.properties = circleBoundary.properties || {};
    circleBoundary.properties.center = {
      longitude: pointLng,
      latitude: pointLat,
    };

    return circleBoundary;
  }

  const response = await fetch(
    `https://api.mapbox.com/isochrone/v1/mapbox/${params.boundaryType}/${pointLng},${pointLat}?contours_minutes=${params.radius}&polygons=true&access_token=${env.MAPBOX_API_KEY}`
  );

  if (!response.ok) {
    const responseBody = await response.text();
    throw new Error(
      `HTTP ${response.status} ${response.statusText}: ${responseBody}`
    );
  }

  const boundaryData = await response.json();
  const singleBoundary = boundaryData.features[0];

  // Add a center property to singleBoundary's properties
  singleBoundary.properties = singleBoundary.properties || {};
  singleBoundary.properties.center = {
    longitude: pointLng,
    latitude: pointLat,
  };

  return singleBoundary;
}
