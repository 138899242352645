import z from 'zod';
import * as Y from 'yjs';
import { yFromJSON } from '@plotr/multiplayer-data';

export const pinSchema = z.object({
  id: z.string(),
  label: z.string(),
  group: z.string(),
  tags: z.array(z.string()),
  pos: z.object({
    lng: z.number(),
    lat: z.number(),
  }),
  keyValuePairs: z.record(z.coerce.string()),
});

export type CustomPin = z.infer<typeof pinSchema>;

export type YCustomPin = Y.Map<
  string | Y.Array<string> | Y.Map<number> | Y.Map<string>
>;

const getPins = (ydoc: Y.Doc) => ydoc.getArray<YCustomPin>('customPins');

export default (ydoc: Y.Doc) => ({
  addPin: (pin: CustomPin) => {
    const yCustomPin = yFromJSON(pin) as YCustomPin;
    const existingPins = getPins(ydoc);
    const pinIndex = existingPins
      .toArray()
      .findIndex((existingPin) => existingPin.get('id') === pin.id);

    // Remove existing pin with same id, if it exists
    // This makes importing pins from user files idempotent
    if (pinIndex !== -1) {
      existingPins.delete(pinIndex);
    }
    existingPins.push([yCustomPin]);
  },
  addPins: (pins: CustomPin[]) => {
    const existingPins = getPins(ydoc);
    const existingPinIndexById = new Map(
      existingPins.toArray().map((pin, index) => [pin.get('id'), index])
    );

    // Remove existing pin with same id, if it exists
    // This makes importing pins from user files idempotent
    pins.forEach((pin) => {
      const yCustomPin = yFromJSON(pin) as YCustomPin;
      if (existingPinIndexById.has(pin.id)) {
        const existingPinIndex = existingPinIndexById.get(pin.id);
        if (existingPinIndex != null) {
          existingPins.delete(existingPinIndex);
        }
      }
      existingPins.push([yCustomPin]);
    });
  },
  removePin: (pinId: string) => {
    const pins = getPins(ydoc);
    const pinIndex =
      pins.toArray().findIndex((pin) => pin.get('id') === pinId) ?? -1;

    pins.delete(pinIndex);
  },
  removePins: (pinIds: string[]) => {
    const pins = getPins(ydoc);

    pinIds.forEach((pinId) => {
      const pinIndex =
        pins.toArray().findIndex((pin) => pin.get('id') === pinId) ?? -1;

      pins.delete(pinIndex);
    });
  },
  setLabel: (pinId: string, label: string) => {
    const pins = getPins(ydoc);
    const pinIndex =
      pins.toArray().findIndex((pin) => pin.get('id') === pinId) ?? -1;

    pins.get(pinIndex)?.set('label', label);
  },
  setGroup: (pinId: string, group: string) => {
    const pins = getPins(ydoc);
    const pinIndex =
      pins.toArray().findIndex((pin) => pin.get('id') === pinId) ?? -1;

    pins.get(pinIndex)?.set('group', group);
  },
  addTag: (pinId: string, tag: string) => {
    const pins = getPins(ydoc);
    const pinIndex =
      pins.toArray().findIndex((pin) => pin.get('id') === pinId) ?? -1;
    const pin = pins.get(pinIndex);
    let tags = pin?.get('tags') as Y.Array<string> | undefined;

    if (!tags) {
      tags = new Y.Array<string>();
      pin?.set('tags', tags);
    }

    // only add tag if it doesn't already exist
    if (tags?.toArray().findIndex((t) => t === tag) !== -1) return;

    tags?.push([tag]);
  },
  removeTag: (pinId: string, tag: string) => {
    const pins = getPins(ydoc);
    const pinIndex =
      pins.toArray().findIndex((pin) => pin.get('id') === pinId) ?? -1;
    const pin = pins.get(pinIndex);
    const tags = pin?.get('tags') as Y.Array<string> | undefined;
    const tagIndex = tags?.toArray().findIndex((t) => t === tag) ?? -1;
    tags?.delete(tagIndex, 1);
  },
  setPos: (pinId: string, pos: { lng: number; lat: number }) => {
    const pins = getPins(ydoc);
    const pinIndex =
      pins.toArray().findIndex((pin) => pin.get('id') === pinId) ?? -1;
    const pin = pins.get(pinIndex);
    let pinPos = pin?.get('pos') as Y.Map<number> | undefined;
    if (!pinPos) {
      pinPos = new Y.Map<number>();
      pin?.set('pos', pinPos);
    }
    pinPos?.set('lng', pos.lng);
    pinPos?.set('lat', pos.lat);
  },
  renameGroup: (oldName: string, newName: string) => {
    const pins = getPins(ydoc);
    pins.toArray().forEach((pin) => {
      if (pin.get('group') === oldName) {
        pin.set('group', newName);
      }
    });
  },
  addKeyValuePair: (pinId: string, key: string, value: string) => {
    try {
      const pins = getPins(ydoc);
      const pinIndex =
        pins.toArray().findIndex((pin) => pin.get('id') === pinId) ?? -1;
      const pin = pins.get(pinIndex);
      let keyValuePairs = pin?.get('keyValuePairs') as
        | Y.Map<string>
        | undefined;
      if (!keyValuePairs) {
        keyValuePairs = new Y.Map<string>();
        pin?.set('keyValuePairs', keyValuePairs);
      }
      keyValuePairs?.set(key, value);
    } catch (error) {
      console.error('Error adding key-value pair:', error);
    }
  },
  removeKeyValuePair: (pinId: string, key: string) => {
    try {
      const pins = getPins(ydoc);
      const pinIndex =
        pins.toArray().findIndex((pin) => pin.get('id') === pinId) ?? -1;
      const pin = pins.get(pinIndex);
      const keyValuePairs = pin?.get('keyValuePairs') as
        | Y.Map<string>
        | undefined;
      keyValuePairs?.delete(key);
    } catch (error) {
      console.error('Error removing key-value pair:', error);
    }
  },
  addKeyValuePairs: (
    pinId: string,
    keyValuePairsToAdd: Map<string, string>
  ) => {
    try {
      const pins = getPins(ydoc);
      const pinIndex =
        pins.toArray().findIndex((pin) => pin.get('id') === pinId) ?? -1;
      const pin = pins.get(pinIndex);
      let keyValuePairs = pin?.get('keyValuePairs') as
        | Y.Map<string>
        | undefined;
      if (!keyValuePairs) {
        keyValuePairs = new Y.Map<string>();
        pin?.set('keyValuePairs', keyValuePairs);
      }
      keyValuePairsToAdd.forEach((value, key) => {
        keyValuePairs?.set(key, value);
      });
    } catch (error) {
      console.error('Error adding key-value pairs:', error);
    }
  },
  removeKeyValuePairs: (pinId: string, keys: string[]) => {
    try {
      const pins = getPins(ydoc);
      const pinIndex =
        pins.toArray().findIndex((pin) => pin.get('id') === pinId) ?? -1;
      const pin = pins.get(pinIndex);
      const keyValuePairs = pin?.get('keyValuePairs') as
        | Y.Map<string>
        | undefined;
      keys.forEach((key) => {
        keyValuePairs?.delete(key);
      });
    } catch (error) {
      console.error('Error removing key-value pairs:', error);
    }
  },
  updateKeyValuePair: (pinId: string, key: string, value: string) => {
    try {
      const pins = getPins(ydoc);
      const pinIndex =
        pins.toArray().findIndex((pin) => pin.get('id') === pinId) ?? -1;
      const pin = pins.get(pinIndex);
      let keyValuePairs = pin?.get('keyValuePairs') as
        | Y.Map<string>
        | undefined;
      if (!keyValuePairs) {
        keyValuePairs = new Y.Map<string>();
        pin?.set('keyValuePairs', keyValuePairs);
      }
      keyValuePairs?.set(key, value);
    } catch (error) {
      console.error('Error updating key-value pair:', error);
    }
  },
});
