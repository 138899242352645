export default function formatDayTime(day: number, hour: number): string {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  let formatted = '';
  formatted += days[day - 1];
  if (hour === -1) {
    formatted += ' all day';
  } else {
    const formattedHour = hour === 0 || hour === 12 ? 12 : hour % 12;
    const period = hour < 12 ? 'AM' : 'PM';
    formatted += ` at ${formattedHour}${period}`;
  }
  return formatted;
}
