import { ReactElement, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import {
  Business as BusinessIcon,
  DataArray as DataArrayIcon,
  Insights as InsightsIcon,
  Layers as LayersIcon,
} from '@mui/icons-material';
import { parseEnv } from '@plotr/common-utils';

import useAccessToken from '~/src/global/hooks/useAccessToken';
import { checkPermission } from '~/src/global/services/permissionService';
import { LayerCard } from '~/src/features/dynamic-map/hooks/useLayersStore';
import {
  LockableButton,
  LockIcon,
} from '~/src/common/components/LockableButton';
import { useSubscriptionDialog } from '~/src/global/components/SubscriptionDialog';
import { LayerType } from './LayerManager';

const env = parseEnv({ API_V1: process.env.API_V1 });

export default function AddLayerModal({
  open,
  onClose, // Updated to match the calling code
  onSelect, // Updated to match the calling code
}: {
  open: boolean;
  onClose: () => void; // Updated to match the calling code
  onSelect: ({
    clickedLayer,
    layerType,
    mode,
  }: {
    clickedLayer?: LayerCard;
    layerType?: LayerType;
    mode: 'create' | 'edit';
  }) => void;
}) {
  const { accessToken } = useAccessToken();
  const [hasEnterprise, setHasEnterprise] = useState<boolean>(false);
  interface LayerItemProps {
    layerType: LayerType;
    secondaryText: string;
    IconComponent: ReactElement;
    isLocked: boolean;
    isDisabled: boolean;
    onSelect: (selection: { layerType: LayerType; mode: 'create' }) => void;
  }

  const LockableLayerButton: React.FC<LayerItemProps> = ({
    layerType,
    secondaryText,
    IconComponent,
    isLocked,
    isDisabled,
    onSelect,
  }) => {
    const [isHovered, setIsHovered] = useState(false);
    const { open: openSubscriptionDialog } = useSubscriptionDialog();

    return (
      <LockableButton isLocked={isLocked} onClick={openSubscriptionDialog}>
        <ListItemButton
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={
            isLocked
              ? openSubscriptionDialog
              : () => onSelect({ layerType, mode: 'create' })
          }
          disabled={isDisabled}
        >
          <ListItemIcon>
            {isLocked ? <LockIcon active={isHovered} /> : IconComponent}
          </ListItemIcon>
          <ListItemText primary={layerType} secondary={secondaryText} />
        </ListItemButton>
      </LockableButton>
    );
  };

  useEffect(() => {
    const checkEnterprise = async () => {
      if (accessToken) {
        const status = await checkPermission(accessToken, 'read:enterprise');
        setHasEnterprise(status);
      }
    };
    checkEnterprise();
  }, [env.API_V1, accessToken]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Layer</DialogTitle>
      <DialogContent>
        <List>
          <LockableLayerButton
            layerType={LayerType.Insights}
            secondaryText="Layer providing analytical insights"
            IconComponent={<InsightsIcon />}
            isLocked={false}
            isDisabled={false}
            onSelect={onSelect}
          />
          <LockableLayerButton
            layerType={LayerType.Enterprise}
            secondaryText="Customized layers for enterprise solutions"
            IconComponent={<BusinessIcon />}
            isLocked={!hasEnterprise}
            isDisabled={false}
            onSelect={onSelect}
          />
          <ListSubheader>Coming Soon:</ListSubheader>
          <LockableLayerButton
            layerType={LayerType.IndustryData}
            secondaryText="Aggregated industry-specific data visualizations"
            IconComponent={<DataArrayIcon />}
            isLocked={false}
            isDisabled={true}
            onSelect={onSelect}
          />
          <LockableLayerButton
            layerType={LayerType.LayerSet}
            secondaryText="Collection of predefined layer templates"
            IconComponent={<LayersIcon />}
            isLocked={false}
            isDisabled={true}
            onSelect={onSelect}
          />
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
