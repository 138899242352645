import { create } from 'zustand';
import { fetchTileset, type LayerInfo } from '../../../services/mapboxService';

export interface HeatmapStore {
  layerInfoBySource: Record<string, LayerInfo[]>;
  error: Error | null;
  isLoading: boolean;
  fetchLayerInfoBySource(sourceURLs: string[]): Promise<void>;
}

const useHeatmapStore = create<HeatmapStore>((set, get) => ({
  layerInfoBySource: {},
  error: null,
  isLoading: false,
  async fetchLayerInfoBySource(sourceURLs: string[]): Promise<void> {
    set({ isLoading: true, error: null });

    try {
      const tilesets = await Promise.all(
        sourceURLs.map((url) => fetchTileset({ tilesetURL: url })),
      );

      set({
        layerInfoBySource: tilesets.reduce<HeatmapStore['layerInfoBySource']>(
          (acc, tileset, i) => {
            acc[sourceURLs[i]] = tileset.vector_layers;
            return acc;
          },
          {},
        ),
        error: null,
        isLoading: false,
      });
    } catch (error: unknown) {
      if (error instanceof Error) {
        set({ error, isLoading: false });
      } else {
        console.error('Error fetching layer info: ', error);
        set({ error: new Error('Unknown error'), isLoading: false });
      }
    }
  },
}));

export default useHeatmapStore;
