function isHexCode(hex: string): boolean {
  return /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hex);
}

export default function hexToRGBA(hex: string, alpha: number = 1): string {
  if (!isHexCode(hex)) {
    throw new Error(`Invalid HEX code: ${hex}`);
  }

  // Remove the hash at the start if it's there
  hex = hex.replace('#', '');

  // Check for a three-character hex code
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((char) => char + char)
      .join('');
  }

  // Parse the hex string
  const r = parseInt(hex.slice(0, 2), 16); // Red
  const g = parseInt(hex.slice(2, 4), 16); // Green
  const b = parseInt(hex.slice(4, 6), 16); // Blue

  // Ensure alpha is between 0 and 1
  alpha = Math.max(0, Math.min(1, alpha));

  // Return the formatted string
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
