export function chunk<T>(array: T[], chunkLength: number): T[][] {
  if (chunkLength < 1) {
    return [];
  }

  const resultLength = Math.ceil(array.length / chunkLength);
  const result: T[][] = new Array(resultLength);

  for (let i = 0; i < resultLength; i++) {
    const chunk: T[] = new Array(
      Math.min(chunkLength, array.length - i * chunkLength),
    );
    for (
      let j = 0;
      j < chunkLength && i * chunkLength + j < array.length;
      j++
    ) {
      chunk[j] = array[i * chunkLength + j];
    }
    result[i] = chunk;
  }

  return result;
}
