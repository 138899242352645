import { Room } from 'y-webrtc';
import * as Y from 'yjs';

export type MultiplayerMigrations = Record<number, (ydoc: Y.Doc) => void>;
export type MultiplayerMethods = Record<string, (...args: never[]) => void>;

export interface MultiplayerDataParams<
  T extends MultiplayerMethods,
  U extends Record<string, T>,
> {
  version: number;
  migrations: MultiplayerMigrations;
  methods: (ydoc: Y.Doc) => U;
}

export class MultiplayerData<
  T extends MultiplayerMethods,
  U extends Record<string, T>,
> {
  readonly version: number;
  public ydoc: Y.Doc | null = null;
  public methods: U | null = null;

  private migrations: MultiplayerMigrations;
  private methodsBuilder: (ydoc: Y.Doc) => U;
  private listeners: ((update: Uint8Array, origin: Room | null) => void)[] = [];

  constructor(params: MultiplayerDataParams<T, U>) {
    this.version = params.version;
    this.migrations = params.migrations;
    this.methodsBuilder = params.methods;
  }

  private migrateVersions(ydoc: Y.Doc) {
    const targetVersion = this.version;
    let docVersion = ydoc.getMap<number>('metadata').get('version') ?? 0;

    if (docVersion !== targetVersion) {
      console.debug('Migrating from', docVersion, 'to', targetVersion);
    }

    while (docVersion < targetVersion) {
      docVersion += 1;
      const migrator = this.migrations[docVersion];

      if (migrator == null) {
        throw new Error(`Missing migration for version ${docVersion}`);
      }

      migrator(ydoc);
      ydoc.getMap<number>('metadata').set('version', docVersion);
    }
  }

  onUpdate(listener: (update: Uint8Array, origin: Room | null) => void) {
    this.listeners.push(listener);
    // If ydoc is not yet available, the listeners will be attached later with init()
    this.ydoc?.on('updateV2', listener);
  }

  offUpdate(listener: (update: Uint8Array, origin: Room | null) => void) {
    const index = this.listeners.indexOf(listener);
    if (index !== -1) {
      this.listeners.splice(index, 1);
    }
    this.ydoc?.off('updateV2', listener);
  }

  init(ydoc?: Y.Doc | null) {
    ydoc = ydoc ?? new Y.Doc();
    // Attach any stored listeners now that ydoc is available
    this.listeners.forEach((listener) => ydoc.on('updateV2', listener));
    this.listeners = [];

    // Version migration will now emit update events to the listeners
    this.migrateVersions(ydoc);

    this.methods = this.methodsBuilder(ydoc);
    this.ydoc = ydoc;
  }
}
