import { parseEnv } from '@plotr/common-utils';
import circle from '@turf/circle';
import { Feature, MultiPolygon, Point, Polygon } from 'geojson';
import { UserSettings } from '~/src/global/services/userSettingsService';
import { Boundary } from '../hooks/useDemographicStore';
import { fetchLocalBusinessCounts } from './localBusinessCountSearch';

const env = parseEnv({
  API_V1: process.env.API_V1,
  MAPBOX_API_KEY: process.env.MAPBOX_API_KEY,
});

export type DemographicResponse =
  | {
      boundary_type: 'radius';
      boundary_measure: 'miles';
      measure_value: number;
      data: DemographicData[];
    }
  | {
      boundary_type: 'custom';
      data: DemographicData[];
    };

export interface DemographicData {
  name: string;
  locked?: boolean;
  fields: DemographicField[];
}

export interface DemographicField {
  name: string;
  value: number | null;
  type: 'integer' | 'percent' | 'USD';
}

export type DemographicSearchParams = {
  accessToken: string;
  feature: Feature<Point | Polygon | MultiPolygon>;
  radius?: number;
  userSettings: UserSettings | Record<string, never> | null;
};

export default async function demographicSearch(
  params: DemographicSearchParams
): Promise<DemographicResponse> {
  const headers = {
    'Content-Type': 'application/json',
    ...(params.accessToken && {
      Authorization: `Bearer ${params.accessToken}`,
    }),
  };

  const demographicPromise = (async () => {
    if (params.feature.geometry.type === 'Point' && params.radius != null) {
      const response = await fetch(`${env.API_V1}/radius_search`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          latitude: params.feature.geometry.coordinates[1],
          longitude: params.feature.geometry.coordinates[0],
          radii: [params.radius],
        }),
      });

      return (await response.json())[0];
    } else {
      const response = await fetch(`${env.API_V1}/boundary_search_bg`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          customBoundary: params.feature,
        }),
      });

      return await response.json();
    }
  })();

  const localBusinessCountsPromise = (async () => {
    if (
      params.userSettings?.featureFlags?.['citylife-local-business-count'] ===
      true
    ) {
      let boundary;
      if (params.feature.geometry.type === 'Point' && params.radius != null) {
        boundary = circle(params.feature.geometry.coordinates, params.radius, {
          units: 'miles',
          steps: 64,
        });
      } else {
        boundary = params.feature;
      }
      try {
        return await fetchLocalBusinessCounts(
          boundary as Boundary,
          params.accessToken
        );
      } catch (error) {
        console.error('Error in localBusinessCountsPromise:', error);
        return null;
      }
    }
    return;
  })();

  const [demographicResponse, localBusinessCounts] = await Promise.all([
    demographicPromise,
    localBusinessCountsPromise,
  ]);

  if (localBusinessCounts) {
    demographicResponse.data.unshift(localBusinessCounts);
  }

  return demographicResponse;
}
