import { useEffect, useRef, type MutableRefObject } from 'react';

export default function useResizeObserver(
  ref: MutableRefObject<HTMLElement | null>,
  callback: () => void,
  debounceTime = 200,
) {
  const resizeTimer = useRef<number>();

  useEffect(() => {
    const { current: currentRef } = ref;

    const resizeObserver = new ResizeObserver(() => {
      if (resizeTimer.current) {
        clearTimeout(resizeTimer.current);
      }

      resizeTimer.current = window.setTimeout(() => {
        callback();
      }, debounceTime);
    });

    if (currentRef) {
      resizeObserver.observe(currentRef);
    }

    return () => {
      if (resizeTimer.current) {
        clearTimeout(resizeTimer.current);
      }
      if (currentRef) {
        resizeObserver.unobserve(currentRef);
      }
    };
  }, [ref, callback, debounceTime]);
}
