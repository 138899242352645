import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Card,
  Collapse,
  Divider,
  IconButton,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  plotrMultiplayerData,
  Rule,
  SourceType,
  TargetType,
} from '@plotr/plotr-multiplayer-data';
import React, { useState } from 'react';
import { HexColorPicker } from 'react-colorful';

import useRulesets from '../../dynamic-map/hooks/useRulesets';

export const getColor = (
  rulesets: Rule[],
  featureProperties: { [key: string]: any }
) => {
  // Default color
  let color = '#888';
  rulesets.forEach((rule) => {
    if (rule.targetType === TargetType.Territories) {
      if (
        rule.sourceType === SourceType.KeyValue &&
        rule.propertyKey &&
        featureProperties[rule.propertyKey] === rule.evaluation.value
      ) {
        color = rule.effect;
      }
      if (rule.sourceType === SourceType.Tag) {
        if (
          featureProperties.tags &&
          featureProperties.tags.includes(rule.evaluation.value)
        ) {
          color = rule.effect;
        }
      }
    }
  });
  return color;
};

const GlobalTerritorySettingsControl = () => {
  const rules = useRulesets();
  const rulesetsMethods = plotrMultiplayerData.methods?.rulesets;
  const [view, setView] = useState('list'); // 'list' or 'create'
  const [newRuleType, setNewRuleType] = useState<SourceType | ''>('');
  const [newRuleValue, setNewRuleValue] = useState('');
  const [propertyKey, setPropertyKey] = useState('');
  const [selectedColor, setSelectedColor] = useState('#ff0000');
  const [colorPickerAnchorEl, setColorPickerAnchorEl] =
    useState<Element | null>(null);
  const [editingRuleId, setEditingRuleId] = useState<string | null>(null);

  const handleAddRule = () => {
    // Validation to ensure that all necessary fields are filled out
    if (
      !newRuleType ||
      !newRuleValue ||
      (newRuleType === SourceType.KeyValue && !propertyKey)
    ) {
      alert('Please fill all required fields.');
      return; // Stop the function if validation fails
    }

    const newRule: Rule = {
      id: `rule-${Date.now()}`,
      targetType: TargetType.Territories,
      sourceType: newRuleType,
      evaluation: {
        operator: '=',
        value:
          newRuleType === SourceType.Tag
            ? newRuleValue.toString().toLocaleLowerCase()
            : newRuleValue,
      },
      effect: selectedColor,
      propertyKey:
        newRuleType === SourceType.KeyValue ? propertyKey : undefined,
    };

    rulesetsMethods?.addRule(newRule);
    setNewRuleType('');
    setNewRuleValue('');
    setPropertyKey('');
    setView('list'); // Switch back to list view after adding rule
  };

  const handleColorChange = (newColor: string) => {
    setSelectedColor(newColor);
    if (editingRuleId) {
      const existingRule = rules.find((rule) => rule.id === editingRuleId);
      if (!existingRule) return;
      rulesetsMethods?.updateRule(editingRuleId, {
        ...existingRule,
        effect: newColor,
      });
    }
  };

  const handleColorPickerOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ruleId: string
  ) => {
    setSelectedColor(
      rules.find((rule) => rule.id === ruleId)?.effect || '#ff0000'
    );
    setColorPickerAnchorEl(event.currentTarget);
    setEditingRuleId(ruleId);
  };

  const handleColorPickerClose = () => {
    setColorPickerAnchorEl(null);
    setEditingRuleId(null);
  };

  return (
    <Box display="flex" flexDirection="column">
      {view === 'list' ? (
        <>
          <Button
            color="info"
            variant="outlined"
            onClick={() => setView('create')}
          >
            Create Rule
          </Button>
          {rules.map((rule, index) => (
            <Box
              key={rule.id}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={1}
            >
              <Typography>
                {rule.sourceType === SourceType.Tag
                  ? `${SourceType.Tag}: ${rule.evaluation.value}`
                  : `Property: ${rule.propertyKey}`}
              </Typography>
              <Box display="flex" alignItems="center">
                <div
                  style={{
                    width: 24,
                    height: 24,
                    backgroundColor: rule.effect,
                    marginRight: 8,
                  }}
                />
                <IconButton
                  onClick={() => rulesetsMethods?.deleteRule(rule.id)}
                  size="small"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          ))}
        </>
      ) : (
        <Box display="flex" flexDirection="column">
          {view === 'create' ? (
            <Box display="flex" justifyContent="space-between">
              {/* Form Fields Column */}
              <Box flex={1} pr={2}>
                <Select
                  value={newRuleType}
                  onChange={(e) => setNewRuleType(e.target.value as SourceType)}
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="" disabled>
                    Select Rule Type
                  </MenuItem>
                  {Object.values(SourceType).map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  fullWidth
                  label="Rule Value"
                  value={newRuleValue}
                  onChange={(e) => setNewRuleValue(e.target.value)}
                  margin="normal"
                />
                {newRuleType === SourceType.KeyValue && (
                  <TextField
                    fullWidth
                    label="Property Key"
                    value={propertyKey}
                    onChange={(e) => setPropertyKey(e.target.value)}
                    margin="normal"
                  />
                )}
                <Button
                  color="info"
                  variant="outlined"
                  onClick={handleAddRule}
                  sx={{ mt: 2 }}
                >
                  Save Rule
                </Button>
                <Button
                  color="secondary"
                  onClick={() => setView('list')}
                  sx={{ mt: 2 }}
                >
                  Cancel
                </Button>
              </Box>

              {/* Color Picker Column */}
              <Box flex={0.5}>
                <HexColorPicker
                  color={selectedColor}
                  onChange={setSelectedColor}
                  style={{ width: '150px', height: '150px' }}
                />
              </Box>
            </Box>
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={() => setView('create')}
            >
              Create Rule
            </Button>
          )}

          {/* Rules List */}
          {view === 'list' &&
            rules.map((rule, index) => (
              <React.Fragment key={rule.id}>
                {index > 0 && <Divider />}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={1}
                >
                  <Typography>
                    {rule.sourceType === SourceType.Tag
                      ? `Tag: ${rule.evaluation.value}`
                      : `Property: ${rule.propertyKey} ${rule.evaluation.operator} ${rule.evaluation.value}`}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation(); // Prevent the event from bubbling to potentially interfering elements
                        handleColorPickerOpen(event, rule.id);
                      }}
                      size="small"
                      sx={{
                        p: 0,
                        minWidth: 36,
                        minHeight: 36,
                        color: 'transparent',
                        backgroundColor: rule.effect,
                        '&:hover': { backgroundColor: rule.effect },
                      }}
                    >
                      <Box
                        sx={{
                          width: 24,
                          height: 24,
                          borderRadius: '50%', // Optional: makes the clickable area round
                        }}
                      />
                    </IconButton>
                    <IconButton
                      onClick={() => rulesetsMethods?.deleteRule(rule.id)}
                      size="small"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>
              </React.Fragment>
            ))}

          <Popover
            open={Boolean(colorPickerAnchorEl)}
            anchorEl={colorPickerAnchorEl}
            onClose={handleColorPickerClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            <HexColorPicker
              color={selectedColor}
              onChange={handleColorChange}
            />
          </Popover>
        </Box>
      )}
    </Box>
  );
};

const GlobalTerritorySettingsMenu = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Card sx={{ width: '100%' }} variant="outlined">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          padding: '8px',
          '&:hover': { backgroundColor: '#f0f0f0', cursor: 'pointer' },
        }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Divider textAlign="center">
          <ExpandMoreIcon
            sx={{
              transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s',
            }}
          />
        </Divider>
        <Typography>Global Territory Settings</Typography>
      </Box>
      <Collapse in={isExpanded}>
        <Box p={2}>
          <GlobalTerritorySettingsControl />
        </Box>
      </Collapse>
    </Card>
  );
};

export default GlobalTerritorySettingsMenu;
