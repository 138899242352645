import { Layer } from 'react-map-gl';
import useDynamicMapStore from '../../../hooks/useDynamicMapStore';
import useMapImage from '../../../hooks/useMapImage';
import {
  defaultPOILayer,
  defaultZoomStops,
  exponentialIconRatio,
} from '../POIHelpers';
import { Badge } from '../../../services/poiService';

export default function POIPlacesBadgeLayer({
  placeIds,
  badge,
}: {
  placeIds: string[];
  badge: Badge;
}) {
  const layerId = `poi-${badge.id}-badge`;

  const poisEnabled = useDynamicMapStore((state) => state.poisEnabled);
  const clickedPoiFeature = useDynamicMapStore(
    (state) => state.clickedPOiFeature,
  );
  const isReady = useMapImage({
    id: badge.id,
    src: badge.src,
    onError: () => {
      console.error('Failed to load badge:', badge.src);
    },
  });

  if (!poisEnabled || !isReady) return null;

  return (
    <Layer
      {...defaultPOILayer}
      id={layerId}
      layout={{
        ...(isReady ? { 'icon-image': badge.id } : {}),
        visibility: poisEnabled ? 'visible' : 'none',
        'icon-allow-overlap': true,
        'icon-size': {
          type: 'exponential' as const,
          stops: [
            [defaultZoomStops[0], badge.size * exponentialIconRatio] as [
              number,
              number,
            ],
            [defaultZoomStops[1], badge.size] as [number, number],
          ],
        },
        'icon-offset': badge.offset,
      }}
      paint={{
        'icon-opacity': defaultPOILayer.paint['icon-opacity'],
      }}
      filter={
        clickedPoiFeature
          ? [
              'all',
              ['in', ['get', 'id'], ['literal', placeIds]],
              ['!=', ['get', 'id'], clickedPoiFeature?.properties?.id],
            ]
          : ['in', ['get', 'id'], ['literal', placeIds]]
      }
    />
  );
}
