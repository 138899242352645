import { useEffect, useState, useCallback } from 'react';
import useMapContext from '~/src/features/dynamic-map/hooks/useMapContext';

export default function useTrackMapChanges() {
  const map = useMapContext();
  const [mapChanges, setMapChanges] = useState(0);

  const mapMoveHandler = useCallback(() => {
    setMapChanges((prev) => prev + 1);
  }, []);

  // Track map changes for forcing re-renders
  useEffect(() => {
    if (map == null) return;

    map.on('moveend', mapMoveHandler);
    map.on('load', mapMoveHandler);

    return () => {
      map.off('moveend', mapMoveHandler);
      map.off('load', mapMoveHandler);
    };
  }, [map, mapMoveHandler]);

  return mapChanges;
}
