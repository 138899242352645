import {
  CustomPin,
  plotrMultiplayerData,
} from '@plotr/plotr-multiplayer-data/src';
import React from 'react';
import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';
import KeyValueTable from './KeyValueTable';

interface CustomPinKVTableProps {
  pin: CustomPin;
}

const CustomPinKVTable: React.FC<CustomPinKVTableProps> = ({ pin }) => {
  const customPinMethods = plotrMultiplayerData.methods?.pins;
  const setAlertMessage = useDynamicMapStore(
    (state) => state.setAlertMessagePin
  );
  const setAlertSeverity = useDynamicMapStore(
    (state) => state.setAlertSeverityPin
  );

  const alertMessage = (
    message: string,
    severity: 'success' | 'info' | 'warning' | 'error'
  ) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
  };

  return (
    <KeyValueTable
      data={new Map(Object.entries(pin?.keyValuePairs || {}))}
      addKeyValuePair={(key, value) =>
        customPinMethods?.addKeyValuePair(pin.id, key, value)
      }
      updateKeyValuePair={(key, value) =>
        customPinMethods?.updateKeyValuePair(pin.id, key, value)
      }
      removeKeyValuePair={(key) =>
        customPinMethods?.removeKeyValuePair(pin.id, key)
      }
      alertMessage={alertMessage}
    />
  );
};

export default CustomPinKVTable;
