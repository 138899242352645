import { useEffect, useMemo, useState } from 'react';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import {
  AddLocation as AddLocationIcon,
  Layers as LayersIcon,
  Map as MapIcon,
  Store as StoreIcon,
  PhotoSizeSelectSmall as PhotoSizeSelectSmallIcon,
} from '@mui/icons-material';

import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import CustomPinsMenu from '~/src/features/custom-pins/menu/CustomPinsMenu';
import useMapContext from '~/src/features/dynamic-map/hooks/useMapContext';
import useSettingsStore from '../../global/hooks/useSettingsStore';
import useClientGeometriesStore from '../dynamic-map/hooks/useClientGeometriesStore';
import useZonesStore from '../dynamic-map/hooks/useZonesStore';
import ClientGeometriesMenu from './components/ClientGeometriesMenu';
import MapLayersMenu from './components/MapLayersMenu';
import { POIMenu } from './components/POIMenu';
import { ZonesMenu } from './components/ZonesMenu';
import CustomDrawer from '../custom-drawer/CustomDrawer';
import CustomTerritoriesMenu from '../custom-territories/menu/CustomTerritoriesMenu';
import useFlyToTerritory from '../dynamic-map/hooks/useFlyToTerritory';
import useCustomTerritories from '../dynamic-map/hooks/useCustomTerritories';
import { CustomTerritory } from '@plotr/plotr-multiplayer-data/src';

export enum MenuName {
  CustomPins = 'Custom Pins',
  Layers = 'Map Layers',
  Territories = 'Territories',
  POIs = 'Points of Interest',
  Zones = 'Selected Zones',
}

const menuIconByName: Record<string, JSX.Element> = {
  [MenuName.CustomPins]: <AddLocationIcon />,
  [MenuName.Layers]: <LayersIcon />,
  [MenuName.Territories]: <PhotoSizeSelectSmallIcon />,
  [MenuName.POIs]: <StoreIcon />,
  [MenuName.Zones]: <MapIcon />,
};

// TODO: on mobile screen sizes, the drawer menu should take up the full screen

export default function DynamicMapController() {
  const zones = useZonesStore((state) => state.zones);
  const clientGeometries = useClientGeometriesStore(
    (state) => state.clientGeometries
  );
  const map = useMapContext();
  const userSettings = useSettingsStore((state) => state.userSettings);

  const isZonesMenuVisible = userSettings && zones && map;
  const isClientGeometriesMenuVisible = map && clientGeometries.length > 0;

  const menuByName: { [key in MenuName]: JSX.Element } = {
    [MenuName.CustomPins]: <CustomPinsMenu />,
    [MenuName.Layers]: <MapLayersMenu />,
    [MenuName.Territories]: (
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        {userSettings?.featureFlags?.territories && <CustomTerritoriesMenu />}
      </Box>
    ),
    [MenuName.POIs]: <POIMenu />,
    [MenuName.Zones]: (
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        {isZonesMenuVisible && (
          <Box
            sx={{
              flex: isClientGeometriesMenuVisible ? '1 1 50%' : '1 0 100%',
              overflow: 'auto',
            }}
          >
            <ZonesMenu />
          </Box>
        )}
        {isClientGeometriesMenuVisible && (
          <Box
            sx={{
              flex: isZonesMenuVisible ? '1 1 50%' : '1 0 100%',
              overflow: 'auto',
            }}
          >
            <ClientGeometriesMenu />
          </Box>
        )}
      </Box>
    ),
  };

  const [activeMenu, setActiveMenu] = useState<MenuName>(MenuName.Layers);
  const drawerMenuOpen = useDynamicMapStore((state) => state.drawerMenuOpen);
  const setDrawerMenuOpen = useDynamicMapStore(
    (state) => state.setDrawerMenuOpen
  );
  const evaluatedPinId = useDynamicMapStore((state) => state.evaluatedPinId);
  const evaluatedDemographicEntity = useDynamicMapStore(
    (state) => state.evaluatedDemographicEntity
  );
  const evaluatedTerritoryId =
    evaluatedDemographicEntity &&
    evaluatedDemographicEntity.type === 'territory' &&
    evaluatedDemographicEntity?.id;

  // if evaluatedPinId is set, show the CustomPins menu
  useEffect(() => {
    if (evaluatedPinId != null) {
      setActiveMenu(MenuName.CustomPins);
    }
  }, [evaluatedPinId]);

  const flyToTerritory = useFlyToTerritory();

  const customTerritories = useCustomTerritories();

  const evaluatedTerritory = useMemo(() => {
    if (evaluatedTerritoryId == null) return null;
    return customTerritories.find(({ id }) => id === evaluatedTerritoryId);
  }, [customTerritories, evaluatedTerritoryId]);

  useEffect(() => {
    if (evaluatedDemographicEntity?.type === 'territory') {
      flyToTerritory(evaluatedTerritory as CustomTerritory);
      setDrawerMenuOpen(true);
      setActiveMenu(MenuName.Territories);
    }
  }, [evaluatedDemographicEntity]);

  return (
    <CustomDrawer drawerOpen={drawerMenuOpen} compressed>
      <>
        <ToggleButtonGroup
          value={activeMenu}
          exclusive
          onChange={(_, value) => {
            if (value != null) {
              setActiveMenu(value);
            }
          }}
          aria-label="menu"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            flexGrow: 0,
          }}
        >
          {Object.values(MenuName).map((menuName) => {
            const isZonesMenu = menuName === MenuName.Zones;
            const isTerritoriesMenu = menuName === MenuName.Territories;

            const userHasZones = Object.keys(zones ?? {}).length > 0;
            const userHasClientGeometries = clientGeometries.length > 0;

            const isDisabledZonesMenu =
              isZonesMenu && !userHasZones && !userHasClientGeometries;

            const isDisabledTerritoriesMenu =
              isTerritoriesMenu && !userSettings?.featureFlags?.territories;

            const isDisabled = isDisabledZonesMenu || isDisabledTerritoriesMenu;

            return isDisabled ? null : (
              <ToggleButton
                key={menuName}
                value={menuName}
                aria-label={menuName}
                size="medium"
                sx={{ width: '100%' }}
                fullWidth={true}
              >
                {menuIconByName[menuName]}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>

        <Box flex={1} paddingY={1} overflow="auto">
          <Box height={1} padding={1}>
            {menuByName[activeMenu]}
          </Box>
        </Box>
      </>
    </CustomDrawer>
  );
}
