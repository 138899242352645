import { FlightTakeoff } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import {
  CustomTerritory,
  TerritoryType,
} from '@plotr/plotr-multiplayer-data/src';
import { polygon } from '@turf/helpers';
import { Feature, Polygon } from 'geojson';
import { Ref, useState } from 'react';

import useGeometryCache from '~/src/common/hooks/useGeometryCache';
import { parseEnv } from '../../../../../common-utils/src';
import fitMapToBoundary from '../../dynamic-map/helpers/fitMapToBoundary';
import useCustomTerritories from '../../dynamic-map/hooks/useCustomTerritories';
import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';
import useMapContext from '../../dynamic-map/hooks/useMapContext';
import EditTerritoryGroupDialog from '../EditTerritoryGroupDialog';

const env = parseEnv({ API_V1: process.env.API_V1 });

const TerritoryGroupCard = ({
  cardRef,
  group,
  territoryCount,
  onAction,
}: {
  cardRef?: Ref<HTMLDivElement>;
  group: string;
  territoryCount: number;
  onAction: () => void;
}) => {
  const map = useMapContext();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const geometryCache = useGeometryCache();
  const hiddenTerritoryGroups = useDynamicMapStore(
    (state) => state.hiddenTerritoryGroups
  );
  const toggleGroupVisibility = useDynamicMapStore(
    (state) => state.toggleTerritoryGroupVisibility
  );

  const customTerritories = useCustomTerritories();

  const handleFlyTo = async () => {
    if (map == null) return;

    const territoriesInGroup = customTerritories.filter(
      (territory) => territory.group === group
    );

    interface TerritoryAccumulator {
      drawnTerritories: CustomTerritory[];
      standardTerritories: CustomTerritory[];
    }

    const { drawnTerritories, standardTerritories } =
      territoriesInGroup.reduce<TerritoryAccumulator>(
        (acc, territory) => {
          if (territory.type === TerritoryType.Custom) {
            acc.drawnTerritories.push(territory);
          } else {
            acc.standardTerritories.push(territory);
          }
          return acc;
        },
        { drawnTerritories: [], standardTerritories: [] }
      );

    const zipcodes = new Set<string>();

    for (const territory of standardTerritories) {
      for (const boundary of Object.values(territory.boundaries)) {
        zipcodes.add(boundary.id.padStart(5, '0'));
      }
    }

    const zipcodesArray = Array.from(zipcodes);

    if (zipcodesArray.length === 0) {
      return; // Do nothing if there are no zip codes
    }
    // Check if we have cached geometry for this group
    const zoomRangeFourToSixteen = new Array(12).fill(0).map((_, i) => i + 4);
    const potentialCacheKeys = zoomRangeFourToSixteen.map(
      (zoom) => `${group}::${zoom}`
    );
    const cachedTerritories = potentialCacheKeys.map((key) =>
      geometryCache.get(key)
    );
    const highestQualityCached = cachedTerritories.reduce((acc, curr) => {
      return (curr?.data.coordinates.length ?? 0) >
        (acc?.data.coordinates.length ?? 0)
        ? curr
        : acc;
    }, null);

    if (highestQualityCached != null) {
      fitMapToBoundary(
        map,
        highestQualityCached.data as unknown as Feature<Polygon>
      );
      return;
    }

    // If we don't have cached geometry, fetch it from the API
    try {
      const endpoint = `${env.API_V1}/merge`;
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ zipcodes: zipcodesArray, bbox: true }),
      });
      const data = await response.json();
      data.features.push(
        ...drawnTerritories.map((territory) => {
          let coordinates =
            territory.boundaries[Object.keys(territory.boundaries)[0]]
              .coordinates;
          return polygon([coordinates]);
        })
      );
      fitMapToBoundary(map, data);
    } catch (err) {
      console.error('Error fetching merged boundaries:', err);
    }
  };

  return (
    <>
      <Paper
        ref={cardRef}
        key={group}
        variant="outlined"
        sx={{
          display: 'flex',
          p: 1,
          cursor: 'pointer',
          '&:hover': {
            boxShadow: 6,
          },
          height: '100%',
        }}
        onClick={onAction}
      >
        <Box flex={1} display="flex" flexDirection="column" alignItems="center">
          <Typography
            variant="body1"
            sx={{
              mb: '0px',
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}
          >
            {group}
          </Typography>
          <Typography
            variant="caption"
            color="text.secondary"
            onClick={(e) => {
              setIsEditing(true);
              e.stopPropagation();
            }}
            sx={{ cursor: 'pointer', '&:hover': { color: 'text.primary' } }}
          >
            Edit
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent={'space-between'}
        >
          <IconButton
            onClick={(e) => {
              toggleGroupVisibility(group);
              e.stopPropagation();
            }}
            size="small"
          >
            {hiddenTerritoryGroups.includes(group) ? (
              <VisibilityOffIcon />
            ) : (
              <VisibilityIcon />
            )}
          </IconButton>
          {handleFlyTo && (
            <IconButton
              onClick={(e) => {
                handleFlyTo();
                e.stopPropagation();
              }}
              size="small"
            >
              <FlightTakeoff sx={{ marginTop: 'auto' }} />
            </IconButton>
          )}
        </Box>
      </Paper>
      <EditTerritoryGroupDialog
        group={group}
        isOpen={isEditing}
        onClose={() => setIsEditing(false)}
        onSave={() => setIsEditing(false)}
      />
    </>
  );
};
export default TerritoryGroupCard;
