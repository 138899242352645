// Hash map to store original strings against their numeric hashes
const hashToString = new Map();

export function hashStringToNumber(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32bit integer
  }
  hash = Math.abs(hash);

  // Store the string against the hash in the map if not already present
  if (!hashToString.has(hash)) {
    hashToString.set(hash, str);
  }

  return hash;
}

export function getStringFromHash(hash: number) {
  return hashToString.get(hash);
}
