import { create } from 'zustand';
import { fetchTileset, LayerInfo } from '../services/mapboxService';
import { checkPermission } from '~/src/global/services/permissionService';

export interface TrafficStore {
  hasPermission: boolean;
  fetchPermission: (accessToken: string) => Promise<boolean>;
  layerInfo: LayerInfo[];
  error: Error | null;
  isLoading: boolean;
  fetchLayerInfo(): Promise<void>;
  trafficSelection: {
    selectedDay: number; // 0-7
    selectedHour: number; // -1 to 23
    direction: number; // 1, 2, 3
  };

  trafficEnabled: boolean;
  setTrafficEnabled: (enabled: boolean) => void;

  trafficRange: { min: number; max: number };
  setTrafficRange: ({ min, max }: { min: number; max: number }) => void;

  selectedTrafficSegments: string[];
  setSelectedTrafficSegments: (segments: string[]) => void;

  setTrafficSelectionDay: (day: number) => void;
  setTrafficSelectionHour: (hour: number) => void;
  setTrafficSelectionDirection: (direction: number) => void;
}

const useTrafficStore = create<TrafficStore>((set) => ({
  hasPermission: false,
  async fetchPermission(accessToken: string): Promise<boolean> {
    // Ensure this matches the return type in the interface
    set({ isLoading: true, error: null });

    try {
      const hasPermission = await checkPermission(accessToken, 'read:traffic');

      set({
        hasPermission, // Update the hasPermission state in your store
        isLoading: false,
      });

      return hasPermission; // Return the boolean value as expected
    } catch (error) {
      set({
        error:
          error instanceof Error
            ? error
            : new Error('An unexpected error occurred'),
        isLoading: false,
      });

      return false; // Return false if an error occurs
    }
  },
  layerInfo: [],
  error: null,
  isLoading: false,
  trafficSelection: {
    selectedDay: 0, // Default value 0 indicating average of all days
    selectedHour: -1, // Default value -1 indicating average of all hours
    direction: 3, // Default: bidirectional
  },

  trafficEnabled: false,
  setTrafficEnabled: (enabled: boolean) => {
    set(() => ({
      trafficEnabled: enabled,
    }));
  },

  async fetchLayerInfo(): Promise<void> {
    set({ isLoading: true, error: null });

    const trafficTilesetURL = 'mapbox://luketruitt1.plotr-traffic';

    try {
      const tileset = await fetchTileset({ tilesetURL: trafficTilesetURL });
      set({
        layerInfo: tileset.vector_layers,
        error: null,
        isLoading: false,
      });
    } catch (error: unknown) {
      if (error instanceof Error) {
        set({ error, isLoading: false });
      } else {
        console.error('Error fetching layer info: ', error);
        set({ error: new Error('Unknown error'), isLoading: false });
      }
    }
  },

  // Traffic selection setters
  setTrafficSelectionDay: (day) => {
    if (day >= 0 && day <= 7) {
      set((state) => ({
        trafficSelection: {
          ...state.trafficSelection,
          selectedDay: day,
        },
      }));
    } else {
      console.error('Invalid day selected for traffic data.');
    }
  },

  setTrafficSelectionHour: (hour) => {
    if (hour >= -1 && hour <= 23) {
      set((state) => ({
        trafficSelection: {
          ...state.trafficSelection,
          selectedHour: hour,
        },
      }));
    } else {
      console.error('Invalid hour selected for traffic data.');
    }
  },

  setTrafficSelectionDirection: (direction) => {
    if ([1, 2, 3].includes(direction)) {
      set((state) => ({
        trafficSelection: {
          ...state.trafficSelection,
          direction: direction,
        },
      }));
    } else {
      console.error('Invalid direction selected for traffic data.');
    }
  },
  trafficRange: { min: 0, max: 1 },
  setTrafficRange: ({ min, max }) => {
    set(() => ({
      trafficRange: {
        min: min,
        max: max,
      },
    }));
  },
  selectedTrafficSegments: [],
  setSelectedTrafficSegments: (segments) => {
    set(() => ({
      selectedTrafficSegments: segments,
    }));
  },
}));

export default useTrafficStore;
