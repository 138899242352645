import { useTheme } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import StripeCheckSVG from '~/src/assets/icons/stripe-check.svg';

import { Box, Button, IconButton, Typography } from '@mui/material';

export default function EnterprisePaywall({
  stripeFonts,
  handleClose,
}: {
  stripeFonts: string;
  handleClose: () => void;
}) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        fontFamily: stripeFonts,
        background: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& .MuiTypography-h5': {
          color: theme.palette.text.primary,
          marginBottom: theme.spacing(2),
        },
        '& .MuiTypography-subtitle1': {
          color: theme.palette.text.secondary,
          marginBottom: theme.spacing(3),
        },
        '& .MuiButton-contained': {
          fontWeight: 700,
          fontSize: '1rem',
          padding: theme.spacing(1, 4),
          marginBottom: theme.spacing(2),
          textTransform: 'none',
        },
      }}
    >
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          color: theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography
        variant="h4"
        sx={{ fontWeight: 700, fontSize: '2.125 rem', marginBottom: '16px' }}
      >
        Looking for more data?
      </Typography>
      <Typography
        sx={{
          fontFamily: stripeFonts,
          fontWeight: 400,
          fontSize: '1 rem',
          margin: 0,
          marginBottom: '16px',
          color: 'rgba(0, 0, 0, 1)',
        }}
      >
        Reach out and we can get you set up with exactly what you need.
      </Typography>
      <Box display="flex" flexDirection="row" marginTop={theme.spacing(2)}>
        <Box margin={theme.spacing(1)}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '20px',
              marginBottom: '16px',
              fontFamily: stripeFonts,
            }}
          >
            Enterprise
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              marginBottom: '16px',
              fontFamily: stripeFonts,
              opacity: 0.5,
              maxWidth: '240px',
            }}
          >
            Talk with an expert for pricing and information.
          </Typography>
          <Button
            href={`https://plotr.com/get-started`}
            sx={{
              backgroundColor: '#ee8a04',
              borderRadius: '22px',
              fontWeight: 500,
              fontSize: '16px',
              fontStyle: 'normal',
              textTransform: 'none',
              width: '240px',
              zIndex: 0,
              color: 'white',
              '&:hover': {
                backgroundColor: '#ee8a04',
                filter: 'brightness(0.97)',
              },
            }}
          >
            Contact us
          </Button>
        </Box>
        <Box margin={theme.spacing(1)} textAlign="left">
          <Typography variant="body2" gutterBottom>
            This includes:
          </Typography>
          <Box
            component="ul"
            sx={{
              listStyle: 'outside',
              lineHeight: 1.2,
              fontSize: '14px',

              '& li': {
                paddingTop: theme.spacing(0.75),
                paddingBottom: theme.spacing(0.75),
                display: 'flex',
                alignItems: 'center',
                '&::before': {
                  content: '""',
                  display: 'block',
                  marginRight: '8px',
                  width: '12px',
                  height: '12px',
                  backgroundImage: `url(${StripeCheckSVG})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  opacity: 0.5,
                },
              },
            }}
          >
            <li>Everything in Pro</li>
            <li>Data Ingestion</li>
            <li>Data Enrichment</li>
            <li>Data Integration</li>
            <li>Consulting Services</li>
            <li>Custom Tooling</li>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
