export default function getMatchOpacity(name: string) {
  switch (name) {
    case 'A':
      return 1;
    case 'B':
      return 0.65;
    case 'C':
      return 0.3;
    default:
      return 0;
  }
}
