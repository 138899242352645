import { Layer } from 'react-map-gl';
import useDynamicMapStore from '../../../hooks/useDynamicMapStore';
import useLayerIds from '../../../hooks/useLayerIds';
import useMapImage from '../../../hooks/useMapImage';
import { ExtendedPlacesResult } from '../../../services/poiService';
import { defaultPOILayer } from '../POIHelpers';

export default function POIPlacesPinLayer({
  placesResult,
}: {
  placesResult: ExtendedPlacesResult;
}) {
  const layerId = `poi-${placesResult.id}`;
  const imageId = `${placesResult.id}-pin`;

  const badgeId = placesResult.badge?.id;
  const [badgeLayer] = useLayerIds(
    (layer) => layer.id === `poi-${badgeId}-badge`,
  );

  const poisEnabled = useDynamicMapStore((state) => state.poisEnabled);
  const clickedPoiFeature = useDynamicMapStore(
    (state) => state.clickedPOiFeature,
  );

  const isPinReady = useMapImage({
    id: imageId,
    src: placesResult.pinImage,
  });

  return (
    'placeIds' in placesResult &&
    Array.isArray(placesResult.placeIds) &&
    placesResult.placeIds.length > 0 && (
      <Layer
        {...defaultPOILayer}
        id={layerId}
        layout={{
          ...defaultPOILayer.layout,
          ...(isPinReady ? { 'icon-image': imageId } : {}),
          visibility: poisEnabled ? 'visible' : 'none',
        }}
        filter={
          clickedPoiFeature
            ? [
                'all',
                ['in', ['get', 'id'], ['literal', placesResult.placeIds]],
                ['!=', ['get', 'id'], clickedPoiFeature?.properties?.id],
              ]
            : ['in', ['get', 'id'], ['literal', placesResult.placeIds]]
        }
        metadata={{
          placeId: placesResult.id,
          placeName: placesResult.name,
        }}
        beforeId={badgeLayer}
      />
    )
  );
}
