import { parseEnv } from '@plotr/common-utils';
import { MultiplayerProvider } from '@plotr/react-multiplayer';
import { plotrMultiplayerData } from '@plotr/plotr-multiplayer-data';

import useAccessToken from '~/src/global/hooks/useAccessToken';
import useSettingsStore from '~/src/global/hooks/useSettingsStore';
import SubscriptionDialog from '~/src/global/components/SubscriptionDialog';
import useDefaultProject from '~/src/global/hooks/useDefaultProject';
import DynamicMap from '~/src/features/dynamic-map/DynamicMap';

import NavBar from './NavBar';
import useAuthRedirect from './useAuthRedirect';
import { useEffect } from 'react';

import { Box, Typography } from '@mui/material';

const env = parseEnv({
  PLOTR_API: process.env.PLOTR_API,
  MULTIPLAYER_SIGNALING_SERVER: process.env.MULTIPLAYER_SIGNALING_SERVER,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

export default function UserDashboard() {
  const { user, isLoading: authLoading, LoadingComponent } = useAuthRedirect();
  const { accessToken, isLoading: tokenLoading } = useAccessToken();

  const defaultProject = useDefaultProject();

  const assignedRoomId = useSettingsStore(
    (state) => state.userSettings?.assignedRoomId
  );

  const userSettingsError = useSettingsStore((state) => state.error);
  const userSettingsLoading = useSettingsStore((state) => state.isLoading);
  const fetchUserSettings = useSettingsStore(
    (state) => state.fetchUserSettings
  );

  // Fetch user settings to get enterprise layer access
  useEffect(() => {
    if (accessToken == null) return;

    fetchUserSettings(accessToken);
  }, [accessToken, fetchUserSettings]);

  // Log any errors fetching user settings
  useEffect(() => {
    if (userSettingsError != null) {
      console.error('Error fetching user settings:', userSettingsError);
    }
  }, [userSettingsError]);

  window.pendo.initialize({
    visitor: {
      id: user?.sub,
      email: user?.email,
    },

    account: {
      id: user?.sub,
      accountName: user?.email,
      payingStatus: 'Team',
    },
  });

  const isLoading =
    authLoading ||
    tokenLoading ||
    userSettingsLoading ||
    defaultProject == null;

  const emailVerified = user?.email_verified ?? false;

  return isLoading ? (
    <LoadingComponent />
  ) : (
    <Box display="flex" flexDirection="column" height="100vh">
      <NavBar />
      <SubscriptionDialog />
      <Box flex={1} overflow="auto">
        {emailVerified ? (
          <MultiplayerProvider
            room={assignedRoomId ?? defaultProject.room._id}
            signaling={env.MULTIPLAYER_SIGNALING_SERVER}
            token={accessToken}
            api={env.PLOTR_API + '/multiplayer'}
            data={plotrMultiplayerData}
          >
            <DynamicMap />
          </MultiplayerProvider>
        ) : user != null ? (
          <Box
            display="flex"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Typography>
              Please check your email to verify your account, then refresh this
              page.
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
