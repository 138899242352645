import { useCallback } from 'react';
import useMapContext from '~/src/features/dynamic-map/hooks/useMapContext';

import MarkerAndBoundary from './components/MarkerAndBoundary';
import useDemographicStore from './hooks/useDemographicStore';
import useDynamicMapStore from '../dynamic-map/hooks/useDynamicMapStore';
import useResizeObserver from '~/src/common/hooks/useResizeObserver';
import fitMapToBoundary from '../dynamic-map/helpers/fitMapToBoundary';

export default function DemographicPointLookup(props: {
  mapContainerRef: React.RefObject<HTMLElement | null>;
}) {
  const boundaryData = useDemographicStore((state) => state.boundaryData);
  const evaluatedDemographicEntity = useDynamicMapStore(
    (state) => state.evaluatedDemographicEntity
  );

  const pinPos =
    evaluatedDemographicEntity?.type === 'point'
      ? evaluatedDemographicEntity.pos
      : null;

  const map = useMapContext();

  const fitToBoundaryCallback = useCallback(() => {
    if (boundaryData == null || pinPos == null || map == null) {
      return;
    }

    fitMapToBoundary(map, boundaryData);
  }, [boundaryData, pinPos, map]);

  useResizeObserver(props.mapContainerRef, fitToBoundaryCallback, 50);

  return pinPos != null ? (
    <MarkerAndBoundary boundaryData={boundaryData} />
  ) : null;
}
