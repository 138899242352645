import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import { persist, subscribeWithSelector } from 'zustand/middleware';
import { Feature, MultiPolygon, Polygon } from 'geojson';

export type DemographicResponse =
  | {
      boundary_type: 'radius';
      boundary_measure: 'miles';
      measure_value: number;
      data: DemographicData[];
    }
  | {
      boundary_type: 'custom';
      data: DemographicData[];
    };

export interface DemographicData {
  name: string;
  locked?: boolean;
  fields: DemographicField[];
}

export interface DemographicField {
  name: string;
  value: number | null;
  type: 'integer' | 'percent' | 'USD';
}

export type Boundary = Feature<Polygon | MultiPolygon>;

export type BoundaryType = 'radius' | 'driving' | 'walking' | 'cycling';

function generateFakeData(type: DemographicField['type']) {
  if (type === 'percent') {
    return Math.random();
  }
  return Math.floor(Math.random() * 500000);
}

export const searchSettings = {
  radius: {
    marks: [
      { value: 1, label: '1 mi' },
      { value: 3, label: '3 mi' },
      { value: 5, label: '5 mi' },
      { value: 7.5, label: '7.5 mi' },
      { value: 10, label: '10 mi' },
    ],
    min: 0.25,
    max: 10,
    step: 0.25,
  },
  driving: {
    marks: [
      { value: 5, label: '5 min' },
      { value: 10, label: '10 min' },
      { value: 15, label: '15 min' },
      { value: 20, label: '20 min' },
      { value: 25, label: '25 min' },
      { value: 30, label: '30 min' },
    ],
    min: 5,
    max: 30,
    step: 5,
  },
  walking: {
    marks: [
      { value: 5, label: '5 min' },
      { value: 10, label: '10 min' },
      { value: 15, label: '15 min' },
      { value: 20, label: '20 min' },
      { value: 30, label: '30 min' },
    ],
    min: 5,
    max: 30,
    step: 5,
  },
  cycling: {
    marks: [
      { value: 5, label: '5 min' },
      { value: 10, label: '10 min' },
      { value: 15, label: '15 min' },
      { value: 20, label: '20 min' },
      { value: 30, label: '30 min' },
    ],
    min: 5,
    max: 30,
    step: 5,
  },
};

interface DemographicStore {
  demographicSummaryData: DemographicResponse | null;
  setDemographicSummaryData: (data: DemographicResponse | null) => void;

  boundaryType: BoundaryType;
  setDemographicBoundaryType: (type: BoundaryType) => void;

  demographicSearchRadius: number;
  setDemographicSearchRadius: (radius: number) => void;

  boundaryData: Boundary | null;
  setBoundaryData: (data: Boundary | null) => void;

  isDemographicDataLoading: boolean;
  setIsDemographicDataLoading: (isLoading: boolean) => void;
}

const useDemographicStore = create(
  persist(
    subscribeWithSelector<DemographicStore>((set) => ({
      demographicSummaryData: null,
      setDemographicSummaryData: (demographicData) =>
        set(() => ({
          demographicSummaryData: {
            ...demographicData,
            data:
              demographicData?.data.map((data) => {
                const isLocked = data.locked ?? false;

                return {
                  ...data,
                  locked: isLocked,
                  fields: data.fields.map((field) => ({
                    ...field,
                    value: isLocked
                      ? generateFakeData(field.type)
                      : field.value,
                  })),
                };
              }) ?? null,
          } as DemographicResponse,
        })),

      boundaryType: 'radius',
      setDemographicBoundaryType: (type: BoundaryType) =>
        set(() => ({ boundaryType: type })),

      demographicSearchRadius: searchSettings.radius.marks[0].value,
      setDemographicSearchRadius: (radius: number) =>
        set(() => ({ demographicSearchRadius: radius })),

      boundaryData: null,
      setBoundaryData: (data: Boundary | null) =>
        set(() => ({ boundaryData: data })),

      isDemographicDataLoading: false,
      setIsDemographicDataLoading: (isLoading: boolean) =>
        set(() => ({ isDemographicDataLoading: isLoading })),
    })),
    {
      name: 'demographic-state',
    }
  )
);

// Resets boundary, summary data, and search radius when boundary type changes
useDemographicStore.subscribe(
  (state) => ({
    boundaryType: state.boundaryType,
    searchRadius: state.demographicSearchRadius,
    setDemographicSummaryData: state.setDemographicSummaryData,
    setBoundaryData: state.setBoundaryData,
    setRadius: state.setDemographicSearchRadius,
  }),
  (
    {
      boundaryType,
      searchRadius,
      setDemographicSummaryData,
      setBoundaryData,
      setRadius,
    },
    { boundaryType: prevBoundaryType }
  ) => {
    if (searchRadius == null) {
      setRadius(searchSettings[boundaryType].marks[0].value);
    }

    if (boundaryType !== prevBoundaryType) {
      setBoundaryData(null);
      setDemographicSummaryData(null);
      setRadius(searchSettings[boundaryType].marks[0].value);
    }
  },
  { equalityFn: shallow }
);

export default useDemographicStore;
