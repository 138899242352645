import { ExpressionSpecification } from 'mapbox-gl';
import getPinURL from '~/src/common/helpers/getPinURL';
import { parseEnv } from '@plotr/common-utils';

const env = parseEnv({ PLOTR_API: process.env.PLOTR_API });

type PlaceQueryCondition = {
  [k in PlaceQueryOperator]?: {
    path: string;
    value: string | string[];
  };
};

function serializeQuery(queryConditions: PlaceQueryCondition[]): string {
  return queryConditions
    .map((condition, index) => {
      return Object.entries(condition)
        .map(([operator, details]) => {
          if (!details) return '';
          const value = Array.isArray(details.value)
            ? details.value.join(',')
            : details.value;
          return `query[${index}][operator]=${encodeURIComponent(
            operator
          )}&query[${index}][path]=${encodeURIComponent(
            details.path
          )}&query[${index}][value]=${encodeURIComponent(value)}`;
        })
        .join('&');
    })
    .join('&');
}

export interface POIBrand {
  id: string;
  name: string;
  naicsCode: string;
  parentId: string | null;
  website: string | null;
  group: string;
}

export type ExtendedBrandResult = POIBrand & {
  group: string;
  badge?: Badge;
  pinImage: string;
};

export interface POIIndustry {
  naicsCode: string;
  topCategory: string;
  subCategory: string | null;
}

export type POIGroupRequest = {
  group: string;
  icon?: string;
  color?: string;
  badge?: Badge;
  brands?: BrandRequest[];
  queries?: PlacesRequest[];
};

export type POIGroupResults = {
  group: string;
  icon?: string;
  color?: string;
  badge?: Badge;
  brandResults: ExtendedBrandResult[];
  queryResults: ExtendedPlacesResult[];
};

export interface Badge {
  id: string;
  src: string;
  offset: [number, number];
  size: number;
}

export interface BrandRequest {
  brandId: string;
  website?: string;
}

export type PlaceQueryOperator =
  | 'equals'
  | 'includes'
  | 'startsWith | greaterThan'
  | 'lessThan'
  | 'greaterThanOrEqualTo'
  | 'lessThanOrEqualTo';

export interface PlacesRequest {
  id: string;
  name: string;
  query: Array<{
    [k in PlaceQueryOperator]: {
      path: string;
      value: string | string[];
    };
  }>;
  website?: string;
}

export type PlacesResult = {
  id: string;
  name: string;
  website?: string;
} & (
  | {
      placeIds: string[];
    }
  | {
      localFilter: ExpressionSpecification;
    }
);

export type ExtendedPlacesResult = PlacesResult & {
  group: string;
  badge?: Badge;
  pinImage: string;
};

async function fetchBrandsById(ids: string[]): Promise<POIBrand[]> {
  if (ids.length === 0) return [];

  console.debug('Fetching brands by id...');

  const response = await fetch(
    `${env.PLOTR_API}/poi-brand?ids=${encodeURIComponent(ids.toString())}`
  );

  if (!response.ok) {
    const responseBody = await response.text();
    throw new Error(
      `HTTP ${response.status} ${response.statusText}: ${responseBody}`
    );
  }

  const brands = (await response.json()) as POIBrand[];

  if (brands == null || (brands?.length ?? 0) === 0) {
    console.warn('No brands found for the given brand ids.');
    return [];
  }

  console.debug(`Brands by id fetched! ${brands.length} brands found.`);

  return brands;
}

async function queryPlaces(
  placeQueries: PlacesRequest[]
): Promise<PlacesResult[]> {
  if (placeQueries.length === 0) return [];

  console.debug('Querying places...');

  const results = await Promise.all(
    placeQueries.map((placeQuery) => {
      const query = serializeQuery(placeQuery.query);

      return fetch(`${env.PLOTR_API}/poi-place?${query}`)
        .then((response) => response.json())
        .then((places) => {
          const placeIds = places.map((place: { id: string }) => place.id);
          if (placeIds == null || (placeIds?.length ?? 0) === 0) {
            console.warn(`No places found for query ${placeQuery.name}.`);
            return null;
          }

          return {
            id: placeQuery.id,
            name: placeQuery.name,
            website: placeQuery.website,
            placeIds,
          };
        })
        .catch((_) => {
          console.warn(`Could not query places for ${placeQuery.name}.`);
          return null;
        });
    })
  );

  console.debug(`Places queried! ${results.length} queries found.`);

  // Filter out null results
  return results.filter((result) => {
    return result != null;
  }) as PlacesResult[];
}

async function fetchBrandMapPin(
  color: string,
  website?: string
): Promise<string> {
  const defaultPin = getPinURL({
    background: '#ffffff',
    color,
    crop: true,
  });

  const faviconPinURL =
    website != null
      ? getPinURL({
          website,
          background: '#ffffff',
          color,
          crop: true,
        })
      : defaultPin;

  return new Promise((resolve) => {
    fetch(faviconPinURL)
      .then((response) => response.blob())
      // Convert the blob to a data URL for easy serialization
      .then((blob) => {
        const blobURL = URL.createObjectURL(blob);
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          if (ctx == null) throw new Error('Could not get canvas context');
          ctx.drawImage(img, 0, 0);
          URL.revokeObjectURL(blobURL); // Clean up the blob URL in browser memory
          const dataURL = canvas.toDataURL();
          resolve(dataURL);
        };
        img.onerror = () => {
          console.warn(
            `Could not fetch favicon for ${website ?? 'default pin'}.`
          );
          resolve(defaultPin);
        };
        img.src = blobURL;
      })
      .catch((_) => {
        console.warn(
          `Could not fetch favicon for ${website ?? 'default pin'}.`
        );
        resolve(defaultPin);
      });
  });
}

export async function fetchPOIGroup(
  poiGroup: POIGroupRequest
): Promise<POIGroupResults> {
  const brandIds = (poiGroup.brands ?? []).map((brand) => brand.brandId);
  const websiteByBrandId = new Map(
    (poiGroup.brands ?? []).map((brand) => [brand.brandId, brand.website])
  );

  // divide queries by what can be completed locally with a Mapbox data expression and what needs to be sent to the server
  const [localPOIQueries, remotePOIQueries] = (poiGroup.queries ?? []).reduce<
    [PlacesRequest[], PlacesRequest[]]
  >(
    (acc, query) => {
      const isLocalQuery = query.query.every((condition) =>
        Object.keys(condition).every(
          (conditionKey) =>
            conditionKey === 'equals' ||
            conditionKey === 'greaterThan' ||
            conditionKey === 'lessThan' ||
            conditionKey === 'greaterThanOrEqualTo' ||
            conditionKey === 'lessThanOrEqualTo'
        )
      );
      if (isLocalQuery) {
        acc[0].push(query);
      } else {
        acc[1].push(query);
      }
      return acc;
    },
    [[], []]
  );

  const [brandResults, queryResults] = await Promise.all([
    fetchBrandsById(brandIds),
    queryPlaces(remotePOIQueries),
  ]);

  const combinedQueryResults = [
    ...queryResults,
    ...localPOIQueries.map((poiQuery) => {
      const localFilter: ExpressionSpecification =
        poiQuery.query.reduce<ExpressionSpecification>((acc, condition) => {
          const [_operator, { path, value }] = Object.entries(condition)[0];

          const operatorMap: { [key: string]: string } = {
            equals: '==',
            greaterThan: '>',
            lessThan: '<',
            greaterThanOrEqualTo: '>=',
            lessThanOrEqualTo: '<=',
          };

          const operator = operatorMap[_operator] || '==';

          // FIXME: this probably doesn't work when "path" is nested rather than simple key
          return acc.length === 0
            ? ['all', [operator, ['get', path], value]]
            : [...acc, [operator, ['get', path], value]];
        }, []);

      return {
        id: poiQuery.id,
        name: poiQuery.name,
        website: poiQuery.website,
        localFilter,
      };
    }),
  ];

  const brandsWithPinsPromises = brandResults.map(async (brandResult) => {
    const brand = {
      ...brandResult,
      website: websiteByBrandId.get(brandResult.id) ?? null,
    };
    const pinImage = await fetchBrandMapPin(
      poiGroup.color ?? '#757575',
      brand.website ?? undefined
    );
    return {
      ...brand,
      group: poiGroup.group,
      badge: poiGroup.badge,
      pinImage,
    };
  });

  const queryResultsWithPinsPromises = combinedQueryResults.map(
    async (queryResult) => {
      const pinImage = await fetchBrandMapPin(
        poiGroup.color ?? '#757575',
        queryResult.website ?? undefined
      );
      return {
        ...queryResult,
        group: poiGroup.group,
        pinImage,
        badge: poiGroup.badge,
      };
    }
  );

  const [brandsWithPins, queryResultsWithPins] = await Promise.all([
    Promise.all(brandsWithPinsPromises),
    Promise.all(queryResultsWithPinsPromises),
  ]);

  const poiGroupResults: POIGroupResults = {
    group: poiGroup.group,
    icon: poiGroup.icon,
    color: poiGroup.color,
    badge: poiGroup.badge,
    brandResults: brandsWithPins,
    queryResults: queryResultsWithPins,
  };

  return poiGroupResults;
}
