import { Layer, Source } from 'react-map-gl';
import useClientGeometriesStore from '../hooks/useClientGeometriesStore';
import useLayerIds from '../hooks/useLayerIds';
import { GroupComponentProps } from '../hooks/useLayersStore';
import useMapImage from '../hooks/useMapImage';
import getPinURL from '~/src/common/helpers/getPinURL';

const exponentialIconRatio = 2 / 9;
const exponentialTextRatio = 3 / 4;
const defaultIconSize = 0.3;
const defaultTextSize = 16;
const defaultZoomStops = [10, 16]; // [minZoom, maxZoom]

export default function ClientGeometrySource({
  opacity = 0,
  enabled,
}: GroupComponentProps) {
  const hiddenGeometryFiles = useClientGeometriesStore(
    (state) => state.hiddenGeometryFiles,
  );

  const clientGeometries = useClientGeometriesStore(
    (state) => state.clientGeometries,
  );

  const visibleClientGeometries = clientGeometries.filter((clientGeometry) => {
    return !hiddenGeometryFiles.includes(clientGeometry.filename);
  });

  const roadLayerId = useLayerIds((layer) =>
    layer.id.startsWith('road-label'),
  )[0];

  const pinImageId = 'geometry-point-pin';
  const isPinImageReady = useMapImage({
    id: pinImageId,
    src: getPinURL({
      color: '#4264fb',
      background: '#FFFFFF',
    }),
  });

  const pointFeatures = visibleClientGeometries.filter((clientGeometry) => {
    const featureType = clientGeometry.data.features[0]?.geometry?.type;
    return featureType === 'Point';
  });

  const polygonFeatures = visibleClientGeometries.filter((clientGeometry) => {
    const featureType = clientGeometry.data.features[0]?.geometry?.type;
    return featureType !== 'Point';
  });

  return (
    <>
      {isPinImageReady
        ? pointFeatures.map((clientGeometry) => {
            const layerId = `client-geometry-${clientGeometry.filename}-points`;
            return (
              <Source
                id={layerId}
                key={layerId}
                type="geojson"
                data={clientGeometry.data}
              >
                <Layer
                  id={`${layerId}-symbol`}
                  type="symbol"
                  source={layerId}
                  layout={{
                    visibility: enabled ? 'visible' : 'none',
                    'icon-image': pinImageId,
                    'icon-size': {
                      type: 'exponential',
                      stops: [
                        [
                          defaultZoomStops[0],
                          defaultIconSize * exponentialIconRatio,
                        ],
                        [defaultZoomStops[1], defaultIconSize],
                      ],
                    },
                    'icon-anchor': 'bottom',
                    'icon-allow-overlap': true,
                  }}
                  beforeId={roadLayerId ?? undefined}
                />
                <Layer
                  id={`${layerId}-text`}
                  type="symbol"
                  source={layerId}
                  layout={{
                    visibility: enabled ? 'visible' : 'none',
                    'text-field': ['get', 'name'],
                    'text-allow-overlap': false,
                    'text-anchor': 'top',
                    'text-size': {
                      type: 'exponential',
                      stops: [
                        [
                          defaultZoomStops[0],
                          defaultTextSize * exponentialTextRatio,
                        ],
                        [defaultZoomStops[1], defaultTextSize],
                      ],
                    },
                  }}
                  paint={{
                    'text-halo-color': 'rgba(255, 255, 255, 0.5)',
                    'text-halo-width': 2,
                    'text-opacity': {
                      type: 'exponential',
                      stops: [
                        [10.5, 0],
                        [11, 1],
                      ],
                    },
                  }}
                  beforeId={roadLayerId ?? undefined}
                />
              </Source>
            );
          })
        : null}
      {polygonFeatures.map((clientGeometry) => {
        const layerId = `client-geometry-${clientGeometry.filename}-polygons`;
        return (
          <Source
            id={layerId}
            key={layerId}
            type="geojson"
            data={clientGeometry.data}
          >
            <Layer
              id={layerId}
              type="fill"
              source={layerId}
              layout={{
                visibility: enabled ? 'visible' : 'none',
              }}
              paint={{
                'fill-outline-color': 'rgba(0,0,0,1)',
                'fill-color': ['get', 'color', ['properties']],
                'fill-opacity': opacity,
              }}
              beforeId={roadLayerId ?? undefined}
            />
          </Source>
        );
      })}
    </>
  );
}
