import { useCallback, useEffect } from 'react';
import { v4 as randomUUID } from 'uuid';
import { MapMouseEvent } from 'mapbox-gl';
import { Point } from 'geojson';
import {
  AddLocation as AddLocationIcon,
  Bolt as BoltIcon,
  GpsFixed as GpsFixedIcon,
} from '@mui/icons-material';
import {
  CustomPin,
  CustomTerritory,
  plotrMultiplayerData,
} from '@plotr/plotr-multiplayer-data';

import useDynamicMapStore, {
  DemographicEntity,
} from '../dynamic-map/hooks/useDynamicMapStore';
import useMapContext from '../dynamic-map/hooks/useMapContext';
import ContextMenu from './ContextMenu';
import useContextMenuStore from './useContextMenuStore';
import useCustomPins from '~/src/global/hooks/useCustomPins';
import useTrackMapChanges from '~/src/global/hooks/useTrackMangeChanges';
import useCustomTerritories from '../dynamic-map/hooks/useCustomTerritories';

export default function MapContextMenu() {
  const map = useMapContext();
  const customPins = useCustomPins();
  const pinMethods = plotrMultiplayerData.methods?.pins;

  const selectCustomPinId = useDynamicMapStore(
    (state) => state.selectCustomPinId
  );

  const setContextMenu = useContextMenuStore((state) => state.setContextMenu);
  const clickedPOiFeature = useDynamicMapStore(
    (state) => state.clickedPOiFeature
  );

  const mapChanges = useTrackMapChanges();
  const evaluatedDemographicEntity = useDynamicMapStore(
    (state) => state.evaluatedDemographicEntity
  );
  const customTerritories = useCustomTerritories();
  const setEvaluatedDemographicEntity = useDynamicMapStore(
    (state) => state.setEvaluatedDemographicEntity
  );

  const setEvaluatedTerritoryId = useDynamicMapStore(
    (state) => state.setEvaluatedTerritoryId
  );

  const setSelectedTerritoryGroup = useDynamicMapStore(
    (state) => state.setSelectedTerritoryGroup
  );

  const centerMapOnPin = useCallback(
    (position: { lng: number; lat: number }) => {
      if (map != null) {
        map.flyTo({ center: [position.lng, position.lat], essential: true });
      }
    },
    [map]
  );

  const dropPin = useCallback(
    (e: MapMouseEvent) => {
      return {
        icon: <AddLocationIcon fontSize="small" />,
        label: 'Drop Pin',
        onClick: () => {
          const newPin: CustomPin = {
            id: randomUUID(),
            group: 'Default Group',
            label: `Pin ${customPins.length + 1}`,
            tags: [],
            pos: e.lngLat,
            keyValuePairs: {},
          };

          pinMethods && pinMethods.addPin(newPin);
          selectCustomPinId(newPin.id);
        },
      };
    },
    [customPins.length, pinMethods, selectCustomPinId]
  );

  const setClickedPOiFeature = useDynamicMapStore(
    (state) => state.setClickedPOiFeature
  );

  // set up the map context menu
  useEffect(() => {
    if (map == null || pinMethods == null) return;

    const handleContextMenu = (e: MapMouseEvent) => {
      const contextMenuOptions = [];

      // try to get feature from clicked on location
      const clickedOnThisFeature = map.queryRenderedFeatures(e.point, {})?.[0];

      const isPOI =
        clickedOnThisFeature.sourceLayer === 'poi-places' ||
        clickedOnThisFeature.sourceLayer === 'multi_family';

      const isDefinedTerritory =
        clickedOnThisFeature.source === 'defined-territories';

      const isDrawnTerritory =
        clickedOnThisFeature.source === 'custom-drawn-territories';

      const isTerritory = isDefinedTerritory || isDrawnTerritory;

      const enableRightClickContextMenu =
        clickedOnThisFeature &&
        clickedOnThisFeature.geometry &&
        (isPOI || isTerritory);

      // if there is a feature and it is a poi or territory
      if (enableRightClickContextMenu) {
        let demographicEntity: DemographicEntity = {
          type: 'territory',
          id: '',
        };
        let territory: CustomTerritory | undefined;

        // if its a territory
        if (isTerritory) {
          if (isDefinedTerritory) {
            demographicEntity = {
              type: 'territory',
              id: clickedOnThisFeature.properties?.id,
            };
          }
          if (isDrawnTerritory) {
            demographicEntity = {
              type: 'territory',
              id: clickedOnThisFeature.properties?.territoryId,
            };
          }
          if (demographicEntity.type === 'territory') {
            if (isDefinedTerritory) {
              // Ensure id exists before accessing it
              territory = customTerritories.find(
                (t) => t.id === clickedOnThisFeature.properties?.id
              );
            } else if (isDrawnTerritory) {
              // Ensure id exists before accessing it
              territory = customTerritories.find(
                (t) => t.id === clickedOnThisFeature.properties?.territoryId
              );
            }
            if (!territory) {
              // Handle the case where no territory is found
              console.error('Territory not found:', demographicEntity.id);
              return; // Optionally return or handle this case as needed
            }
          }
        } else {
          // get its lat long
          const poiFeatureLatLong = clickedOnThisFeature.geometry as Point;
          const latLng = {
            lat: poiFeatureLatLong.coordinates[1],
            lng: poiFeatureLatLong.coordinates[0],
          };

          demographicEntity = {
            type: 'point',
            pos: latLng,
          };
        }

        // get pulse on poi or territory
        const onClickGetPulse = () => {
          setClickedPOiFeature(clickedOnThisFeature);
          setEvaluatedDemographicEntity(demographicEntity);
          if (demographicEntity.type === 'territory' && territory) {
            setSelectedTerritoryGroup(territory?.group);
            setEvaluatedTerritoryId(demographicEntity.id);
          }
        };

        // add get pulse option
        contextMenuOptions.push({
          icon: <BoltIcon fontSize="small" />,
          label: isPOI ? 'Get Pin Pulse' : 'Get Territory Pulse',
          onClick: onClickGetPulse,
        });

        // add center on pin option to only poi
        if (isPOI) {
          contextMenuOptions.push({
            icon: <GpsFixedIcon fontSize="small" />,
            label: 'Center on Pin',
            onClick: () =>
              demographicEntity.type === 'point' &&
              centerMapOnPin(demographicEntity.pos),
          });
        }
      }

      // add drop pin option to both custom pin and poi
      contextMenuOptions.push(dropPin(e));

      // set the context menu options
      setContextMenu({
        position: e.lngLat,
        offset: [0, 0] as [number, number],
        options: contextMenuOptions,
      });
    };

    map.on('contextmenu', handleContextMenu);

    return () => {
      map.off('contextmenu', handleContextMenu);
    };
  }, [
    map,
    customPins.length,
    pinMethods,
    selectCustomPinId,
    setContextMenu,
    mapChanges,
    evaluatedDemographicEntity,
    setEvaluatedDemographicEntity,
    clickedPOiFeature,
    centerMapOnPin,
    dropPin,
    setClickedPOiFeature,
  ]);

  return <ContextMenu />;
}
