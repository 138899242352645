import { AddCircleOutline } from '@mui/icons-material';
import { Alert, Box, Divider, Grid, IconButton, Tooltip } from '@mui/material';
import { useEffect, useMemo, useRef } from 'react';

import {
  CustomTerritory,
  plotrMultiplayerData,
} from '@plotr/plotr-multiplayer-data/src';

import usePrevious from '~/src/common/hooks/usePrevious';
import useCustomTerritories from '~/src/features/dynamic-map/hooks/useCustomTerritories';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import TerritoryGroupCard from '../territory-cards/TerritoryGroupCard';

const CustomTerritoryGroupCardView: React.FC = () => {
  const customTerritories = useCustomTerritories();
  const customTerritoryMethods = plotrMultiplayerData.methods?.territories;

  const territoryGroups = useMemo(() => {
    const groups = new Map<string, number>();
    customTerritories.forEach((territory: CustomTerritory) => {
      groups.set(territory.group, (groups.get(territory.group) || 0) + 1);
    });
    return Array.from(groups.entries()).sort((a, b) =>
      a[0].localeCompare(b[0])
    );
  }, [customTerritories]);
  const previousTerritoryGroups = usePrevious(territoryGroups);

  const newTerritoryGroup = useMemo(() => {
    if (
      previousTerritoryGroups &&
      previousTerritoryGroups.length < territoryGroups.length
    ) {
      const newGroup = territoryGroups.find(
        ([group]) =>
          !previousTerritoryGroups.some(([prevGroup]) => group === prevGroup)
      );
      return newGroup ? newGroup[0] : null;
    }
    return null;
  }, [territoryGroups, previousTerritoryGroups]);

  const setSelectedTerritoryGroup = useDynamicMapStore(
    (state) => state.setSelectedTerritoryGroup
  );

  const newGroupRef = useRef<HTMLDivElement | null>(null);

  // Scroll to newly created group
  useEffect(() => {
    if (newTerritoryGroup && newGroupRef.current) {
      newGroupRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [newTerritoryGroup]);

  const handleCreateTerritoryGroup = () => {
    const existingGroups = new Set();
    customTerritories.forEach((territory) => {
      existingGroups.add(territory.group);
    });

    let newGroupName = 'Default Group';
    let copyNumber = 1;

    // If "Default Group" already exists, find the next available name
    while (existingGroups.has(newGroupName)) {
      newGroupName = `Default Group ${copyNumber}`;
      copyNumber++;
    }

    customTerritoryMethods?.addTerritory({
      id: `territory-${Date.now()}`,
      label: '',
      group: newGroupName,
      boundaries: {},
      keyValuePairs: {},
      tags: [],
    });
  };

  return (
    <Box display="flex" flexDirection="column" height="100%" gap={1}>
      <Box display={'flex'} flexDirection="column" flexShrink={0}>
        <Alert severity="info">
          Organize your territories! Create a territory group to categorize and
          manage related territories. This could be by city, region, or any
          other way that makes sense for your business.
        </Alert>
      </Box>
      <Divider />
      <Box
        sx={{
          overflowY: 'auto',
          marginTop: 0.5,
          flexShrink: 1,
          flexGrow: 0,
        }}
      >
        <Grid container spacing={1} padding={0.5}>
          {territoryGroups.map(([group, count]) => (
            <Grid item xs={6} key={group}>
              <TerritoryGroupCard
                cardRef={group === newTerritoryGroup ? newGroupRef : null}
                key={group}
                group={group}
                onAction={() => setSelectedTerritoryGroup(group)}
                territoryCount={count}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexShrink: 0,
        }}
      >
        <Tooltip title="Add New Territory Group">
          <IconButton
            onClick={handleCreateTerritoryGroup}
            sx={{
              minWidth: 'auto',
              color: 'primary.main',
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
                color: 'primary.dark',
              },
            }}
          >
            <AddCircleOutline sx={{ fontSize: '2rem' }} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );
};

export default CustomTerritoryGroupCardView;
