type ImageCategory = {
  image: string;
  subCategories?: { [subCategory: string]: ImageCategory };
};

const baseURL: string =
  'https://plotr-public.s3.amazonaws.com/assets/images/map-layer-icons/';

const rawCategoryImages: { [category: string]: ImageCategory } = {
  Population: {
    image: 'Population.png',
    subCategories: {
      Growth: {
        image: '10 Yr Pop Growth.png',
      },
      Income: {
        image: 'Median Household Income.png',
      },
    },
  },
  Retail: {
    image: 'Search Retail.png',
    subCategories: {
      RetailProximity: {
        image: 'Retail Proximity.png',
      },
      Franchise: {
        image: 'Search Franchise.png',
      },
    },
  },
  Restaurant: {
    image: `Search Carl's Jr.png`,
  },
  Miscellaneous: {
    image: 'Customer Block Match.png',
    subCategories: {
      'Traffic Volume': {
        image: 'Traffic.png',
      },
      'Search American': {
        image: 'Search American.png',
      },
    },
  },
};

function flattenCategories(
  categories: { [category: string]: ImageCategory },
  parentKey: string = '',
): { [key: string]: string } {
  return Object.keys(categories).reduce<{ [key: string]: string }>(
    (acc, key) => {
      const fullKey = parentKey ? `${parentKey}.${key}` : key;
      acc[fullKey] = `${baseURL}${categories[key].image}`;
      if (categories[key].subCategories) {
        const subCategories = categories[key].subCategories!;
        Object.assign(acc, flattenCategories(subCategories, fullKey));
      }
      return acc;
    },
    {},
  );
}

const LayerImageCategories = flattenCategories(rawCategoryImages);

export default LayerImageCategories;
