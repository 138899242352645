import turfBuffer from '@turf/buffer';
import turfBbox from '@turf/bbox';
import type { MapRef } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import { Feature, MultiPolygon, Polygon } from 'geojson';

export default function fitMapToBoundary(
  map: MapRef,
  boundary: Feature<Polygon | MultiPolygon>,
) {
  const bufferedBoundary = turfBuffer(boundary, 0.3, {
    units: 'kilometers',
  });
  const boundaryBbox = turfBbox(bufferedBoundary);

  const bounds = new mapboxgl.LngLatBounds(
    new mapboxgl.LngLat(boundaryBbox[0], boundaryBbox[1]),
    new mapboxgl.LngLat(boundaryBbox[2], boundaryBbox[3]),
  );
  map.fitBounds(bounds, { padding: 50 });
}
