import { AddCircleOutline, Bolt as BoltIcon } from '@mui/icons-material';
import {
  Alert,
  AlertProps,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as randomUUID } from 'uuid';
import {
  CustomTerritory,
  TerritoryType,
  plotrMultiplayerData,
} from '@plotr/plotr-multiplayer-data/src';

import usePrevious from '~/src/common/hooks/usePrevious';
import useCustomTerritories from '../../dynamic-map/hooks/useCustomTerritories';
import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';
import useFlyToTerritory from '../../dynamic-map/hooks/useFlyToTerritory';
import AddEditTerritoryDialog from '../AddEditTerritoryDialog';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import { BoundaryCards } from '../territory-cards/BoundaryCards';
import TerritoryCard from '../territory-cards/TerritoryCard';
import CustomTerritoryKVTable from './CustomTerritoryKVTable';

const CustomTerritoryCardView = () => {
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [modalMode, setModalMode] = useState<'add' | 'edit'>('add');
  const [selectedTerritory, setSelectedTerritory] =
    useState<CustomTerritory | null>(null);
  const [boundaryToRemove, setBoundaryToRemove] = useState<string | null>(null);
  const [removingAllBoundaries, setRemovingAllBoundaries] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const customTerritories = useCustomTerritories();
  const customTerritoryMethods = plotrMultiplayerData.methods?.territories;

  const selectedTerritoryGroup = useDynamicMapStore(
    (state) => state.selectedTerritoryGroup
  );
  const evaluatedTerritoryId = useDynamicMapStore(
    (state) => state.evaluatedTerritoryId
  );
  const setEvaluatedTerritoryId = useDynamicMapStore(
    (state) => state.setEvaluatedTerritoryId
  );
  const setEvaluatedDemographicEntity = useDynamicMapStore(
    (state) => state.setEvaluatedDemographicEntity
  );
  const isEditingTerritory = useDynamicMapStore(
    (state) => state.isEditingTerritory
  );
  const setIsEditingTerritory = useDynamicMapStore(
    (state) => state.setIsEditingTerritory
  );
  const territoriesInGroup = customTerritories.filter(
    (territory: CustomTerritory) => territory.group === selectedTerritoryGroup
  );
  const previousTerritoriesInGroup = usePrevious(territoriesInGroup);

  const newTerritoryId = territoriesInGroup.find(
    (territory) =>
      !previousTerritoriesInGroup?.some(
        (prevTerritory) => prevTerritory.id === territory.id
      )
  )?.id;

  const newTerritoryRef = useRef<HTMLDivElement | null>(null);

  // Scroll to newly created territory
  useEffect(() => {
    if (newTerritoryId && newTerritoryRef.current) {
      newTerritoryRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [newTerritoryId]);

  const handleTerritorySelect = useCallback(
    ({
      territory,
      mode,
    }: {
      territory?: CustomTerritory;
      mode: 'add' | 'edit';
    }) => {
      setModalMode(mode);
      if (mode === 'add') {
        setOpen(true);
        setSelectedTerritory({
          id: randomUUID(),
          label: '',
          group: selectedTerritoryGroup,
          boundaries: {},
          tags: [],
          type: selectedTerritory?.type,
          keyValuePairs: {},
        } as CustomTerritory);
      } else if (mode === 'edit' && territory) {
        setOpen(true);
        setSelectedTerritory(territory);
        setEvaluatedTerritoryId(territory.id);
      }
    },
    [selectedTerritoryGroup, setEvaluatedTerritoryId, selectedTerritory?.type]
  );

  const handleAddEditTerritoryClose = () => {
    setOpen(false);
    setSelectedTerritory(null);
    setModalMode('add');
  };

  const handleSaveTerritory = (territory: CustomTerritory) => {
    if (customTerritoryMethods) {
      // Update or add the territory
      const existingTerritory = customTerritories.find(
        (t) => t.id === territory.id
      );
      if (existingTerritory) {
        customTerritoryMethods.setLabel(territory.id, territory.label);
        customTerritoryMethods.setGroup(territory.id, territory.group);
        if (territory.type) {
          customTerritoryMethods.setType(territory.id, territory.type);
        }
      } else {
        customTerritoryMethods.addTerritory(territory);
      }
    }
    handleAddEditTerritoryClose();
  };

  const confirmRemoval = () => {
    if (removingAllBoundaries) {
      // Remove all boundaries
      if (evaluatedTerritoryId && customTerritoryMethods) {
        customTerritoryMethods.removeAllBoundaries(evaluatedTerritoryId);
      }
    } else if (
      boundaryToRemove &&
      evaluatedTerritoryId &&
      customTerritoryMethods
    ) {
      // Remove single boundary
      customTerritoryMethods.removeBoundary(
        evaluatedTerritoryId,
        boundaryToRemove
      );
    }

    // Reset modal state
    setDeleteOpen(false);
    setBoundaryToRemove(null);
    setRemovingAllBoundaries(false);
  };

  const handleRemoveBoundary = (boundaryId: string) => {
    // Set state to trigger modal and pass the boundary ID
    setBoundaryToRemove(boundaryId);
    setDeleteOpen(true);
  };

  const removeAllBoundaries = () => {
    // Set state to trigger modal for removing all boundaries
    setRemovingAllBoundaries(true);
    setDeleteOpen(true);
  };

  const evaluatedTerritory = customTerritories.find(
    (t) => t.id === evaluatedTerritoryId
  );

  const toggleEditMode = () => {
    setIsEditingTerritory(!isEditingTerritory);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const alertMessage = useDynamicMapStore(
    (state) => state.alertMessageTerritory
  );
  const setAlertMessage = useDynamicMapStore(
    (state) => state.setAlertMessageTerritory
  );
  const alertSeverity = useDynamicMapStore(
    (state) => state.alertSeverityTerritory
  );
  const setAlertSeverity = useDynamicMapStore(
    (state) => state.setAlertSeverityTerritory
  );

  const clearAlert = () => setAlertMessage('');

  const [tagInput, setTagInput] = useState('');

  useEffect(() => {
    if (alertMessage) {
      const timeout = setTimeout(() => {
        setAlertMessage('');
      }, 1500);
      return () => clearTimeout(timeout);
    }
    return;
  }, [alertMessage, setAlertMessage]);

  //when the component mounts, if there is a territory in the selected group without a type, go straight into editing that territory
  useEffect(() => {
    if (
      selectedTerritoryGroup &&
      territoriesInGroup.length > 0 &&
      territoriesInGroup.some((t) => !t.type) // Check for any territory missing a type
    ) {
      const territoryToEdit = territoriesInGroup.find((t) => !t.type); // Find the first territory without a type
      handleTerritorySelect({
        mode: 'edit',
        territory: territoryToEdit,
      });
    }
  }, [selectedTerritoryGroup, territoriesInGroup, handleTerritorySelect]);

  const flyToTerritory = useFlyToTerritory();

  const getPulse = () => {
    if (evaluatedTerritory == null) return;

    flyToTerritory(evaluatedTerritory);
    setEvaluatedDemographicEntity({
      type: 'territory',
      id: evaluatedTerritory.id,
    });
  };

  return (
    <Box display="flex" flexDirection="column" height="100%" gap={1}>
      <AddEditTerritoryDialog
        open={open}
        territory={selectedTerritory}
        mode={modalMode}
        onClose={handleAddEditTerritoryClose}
        onSave={handleSaveTerritory}
        group={selectedTerritoryGroup || 'Default Group'}
      />

      <DeleteConfirmationDialog
        isOpen={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onConfirm={confirmRemoval}
        itemName={
          removingAllBoundaries
            ? 'all boundaries'
            : boundaryToRemove
              ? `boundary ${boundaryToRemove}`
              : undefined
        }
      />

      {evaluatedTerritory != null ? (
        <Box display="flex" flexDirection="column" height="100%" gap={1}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexShrink: 0,
            }}
          >
            <TerritoryCard
              territory={evaluatedTerritory}
              onEdit={() =>
                handleTerritorySelect({
                  mode: 'edit',
                  territory: evaluatedTerritory,
                })
              }
            />
          </Box>
          <Alert severity="info">
            Click on the &quot;
            {evaluatedTerritory.type === TerritoryType.Custom
              ? 'Draw '
              : 'Edit '}
            Territory&quot; button below to begin adding boundaries to your
            territory. When you are done adding boundaries, click the &quot;Save
            Territory&quot; button to save your changes.
          </Alert>
          <Box display="flex" justifyContent="space-evenly" padding={2}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<BoltIcon />}
              onClick={getPulse}
            >
              Get Pulse
            </Button>
            <Button
              variant={isEditingTerritory ? 'contained' : 'outlined'}
              onClick={toggleEditMode}
              color="secondary"
            >
              {isEditingTerritory
                ? 'Save Territory'
                : evaluatedTerritory.type === TerritoryType.Custom
                  ? 'Draw Territory'
                  : 'Edit Territory'}
            </Button>
          </Box>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="territory tabs"
            //align tabs center using prop
            centered
          >
            <Tab label="Boundaries" />
            <Tab label="Properties" />
            <Tab label="Tags" />
          </Tabs>

          {selectedTab === 0 && (
            <>
              <Box
                sx={{
                  overflowY: 'auto',
                  marginTop: 0.5,
                  flexShrink: 1,
                  flexGrow: 1,
                }}
              >
                <BoundaryCards
                  removeAllBoundaries={removeAllBoundaries}
                  removeSingleBoundary={handleRemoveBoundary}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 2,
                  flexShrink: 0,
                }}
              >
                <Button
                  onClick={removeAllBoundaries}
                  sx={{
                    minWidth: 'auto',
                    padding: '10px',
                    color: 'red',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'red',
                      color: 'white',
                    },
                  }}
                >
                  Delete all boundaries
                </Button>
              </Box>
            </>
          )}

          {selectedTab === 1 && (
            <>
              {alertMessage && (
                <Alert
                  severity={alertSeverity as AlertProps['severity']}
                  onClose={clearAlert}
                  sx={{ margin: 2 }}
                >
                  {alertMessage}
                </Alert>
              )}
              <CustomTerritoryKVTable territory={evaluatedTerritory} />
            </>
          )}

          {selectedTab === 2 &&
            evaluatedTerritory &&
            customTerritoryMethods !== null && (
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={4} mt={1}>
                  <TextField
                    id="tag"
                    label="Add Tag"
                    variant="outlined"
                    size="small"
                    value={tagInput}
                    onChange={(e) => setTagInput(e.target.value)}
                    inputProps={{ maxLength: 30 }}
                    onKeyDown={(keyEvent) => {
                      const finalValue = tagInput.toLowerCase().trim();
                      if (keyEvent.key === 'Enter' && finalValue.length > 0) {
                        if (
                          evaluatedTerritory.tags &&
                          evaluatedTerritory.tags.includes(finalValue)
                        ) {
                          setAlertMessage(
                            'A tag with this name already exists! Please choose another name.'
                          );
                          setAlertSeverity('error');
                        } else {
                          customTerritoryMethods?.addTag(
                            evaluatedTerritory.id,
                            finalValue
                          );
                        }
                        setTagInput('');
                      }
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                {evaluatedTerritory.tags &&
                  evaluatedTerritory.tags.map((tag: string) => (
                    <Grid item key={tag}>
                      <Chip
                        size="small"
                        label={tag}
                        color="primary"
                        onDelete={() => {
                          customTerritoryMethods?.removeTag(
                            evaluatedTerritory.id,
                            tag
                          );
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>
            )}
        </Box>
      ) : (
        <>
          <Box display={'flex'} flexDirection="column" flexShrink={0}>
            <Alert severity="info">
              Build your territories! Now that you&apos;ve chosen a group,
              create a territory to define a specific area within your group.
              Give it a name and choose how you&apos;ll define its boundaries.
            </Alert>
          </Box>
          <Divider />
          <Box
            sx={{
              overflowY: 'auto',
              marginTop: 0.5,
              flexShrink: 1,
            }}
          >
            <Grid container spacing={1}>
              {territoriesInGroup
                .map((territory) => (
                  <Grid item xs={6} key={territory.id}>
                    <TerritoryCard
                      cardRef={
                        territory.id === newTerritoryId ? newTerritoryRef : null
                      }
                      key={territory.id}
                      territory={territory}
                      onEdit={() => {
                        handleTerritorySelect({ mode: 'edit', territory });
                      }}
                    />
                  </Grid>
                ))
                .sort((a, b) =>
                  a.props.territory?.label.localeCompare(
                    b.props.territory?.label
                  )
                )}
            </Grid>
          </Box>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexShrink: 0,
            }}
          >
            <Tooltip title="Add New Territory">
              <IconButton
                onClick={() => handleTerritorySelect({ mode: 'add' })}
                sx={{
                  minWidth: 'auto',
                  color: 'primary.main',
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: 'primary.dark',
                  },
                }}
              >
                <AddCircleOutline sx={{ fontSize: '2rem' }} />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
        </>
      )}
    </Box>
  );
};

export default CustomTerritoryCardView;
