import { useEffect, useState } from 'react';
import { Source } from 'react-map-gl';

import type {
  Badge,
  ExtendedBrandResult,
  ExtendedPlacesResult,
} from '../../services/poiService';
import useDynamicMapStore from '../../hooks/useDynamicMapStore';
import POIBrandBadgeLayer from './poi-layers/POIBrandBadgeLayer';
import POIBrandPinLayer from './poi-layers/POIBrandPinLayer';
import POILocalFilterLayer from './poi-layers/POILocalFilterLayer';
import POIPlacesBadgeLayer from './poi-layers/POIPlacesBadgeLayer';
import POIPlacesPinLayer from './poi-layers/POIPlacesPinLayer';
import POIPulseSourceLayer from './poi-layers/POIPulseSourceLayer';

type BadgeMapping = {
  badge: Badge;
} & (
  | {
      brandIds: string[];
    }
  | {
      placeIds: string[];
    }
);

export function POISource() {
  const selectedBrands = useDynamicMapStore((state) => state.selectedBrands);
  const badgeMappingById = selectedBrands.reduce<Record<string, BadgeMapping>>(
    (acc, brandOrPlacesResult) => {
      const badge = brandOrPlacesResult.badge;
      if (badge == null) return acc;

      const existingBadgeMapping = acc[badge.id];

      if ('placeIds' in brandOrPlacesResult) {
        acc[badge.id] = {
          badge: badge,
          placeIds: (existingBadgeMapping != null &&
          'placeIds' in existingBadgeMapping
            ? existingBadgeMapping.placeIds
            : []
          ).concat(brandOrPlacesResult.placeIds),
        };
      } else {
        acc[badge.id] = {
          badge: badge,
          brandIds: (existingBadgeMapping != null &&
          'brandIds' in existingBadgeMapping
            ? existingBadgeMapping.brandIds
            : []
          ).concat(brandOrPlacesResult.id),
        };
      }

      return acc;
    },
    {}
  );
  const badgeMappings = Object.values(badgeMappingById);

  const evaluatedDemographicEntity = useDynamicMapStore(
    (state) => state.evaluatedDemographicEntity
  );

  const evaluatedDemographicPoint =
    evaluatedDemographicEntity?.type === 'point'
      ? evaluatedDemographicEntity.pos
      : null;

  const clickedFeature = useDynamicMapStore((state) => state.clickedPOiFeature);
  const [matchedBrand, setMatchedBrand] = useState<
    ExtendedBrandResult | ExtendedPlacesResult | null
  >(null);

  useEffect(() => {
    if (clickedFeature && clickedFeature.source !== 'multi_family') {
      const foundBrand = selectedBrands.find(
        (brandOrPlacesResult) =>
          ('id' in brandOrPlacesResult &&
            clickedFeature.properties?.brandId1 === brandOrPlacesResult.id) ||
          ('localFilter' in brandOrPlacesResult &&
            clickedFeature.layer.metadata.placeName ===
              brandOrPlacesResult.name)
      );
      setMatchedBrand(foundBrand || null);
    } else {
      setMatchedBrand(null);
    }
  }, [clickedFeature, selectedBrands]);

  return (
    <>
      <Source
        id="poi-places"
        type="vector"
        url="mapbox://luketruitt1.poi-places"
      >
        {selectedBrands.map((brandOrPlacesResult) =>
          'placeIds' in brandOrPlacesResult ? (
            <POIPlacesPinLayer
              placesResult={brandOrPlacesResult}
              key={brandOrPlacesResult.id}
            />
          ) : 'localFilter' in brandOrPlacesResult ? (
            <POILocalFilterLayer
              placesResult={brandOrPlacesResult}
              key={brandOrPlacesResult.id}
            />
          ) : (
            <POIBrandPinLayer
              brandResult={brandOrPlacesResult}
              key={brandOrPlacesResult.id}
            />
          )
        )}
        {badgeMappings.map((badgeMapping) =>
          'placeIds' in badgeMapping ? (
            <POIPlacesBadgeLayer
              placeIds={badgeMapping.placeIds}
              badge={badgeMapping.badge}
              key={`places-badge-${badgeMapping.badge.id}`}
            />
          ) : (
            <POIBrandBadgeLayer
              brandIds={badgeMapping.brandIds}
              badge={badgeMapping.badge}
              key={`brands-badge-${badgeMapping.badge.id}`}
            />
          )
        )}
      </Source>
      {matchedBrand && clickedFeature && evaluatedDemographicPoint && (
        <POIPulseSourceLayer
          brandId={matchedBrand?.id}
          brandPinImage={matchedBrand?.pinImage}
          brandName={matchedBrand?.name}
        />
      )}
    </>
  );
}
