import { useCallback, useRef } from 'react';

type DebouncedCallback<T extends unknown[]> = ((...args: T) => void) & {
  flush: () => void;
};

export default function useDebounce<T extends unknown[]>(
  callback: (...args: T) => void,
  delay = 500
): DebouncedCallback<T> {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const argsRef = useRef<T | null>(null);

  const debouncedCallback = useCallback(
    (...args: T) => {
      argsRef.current = args;
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  ) as DebouncedCallback<T>;

  debouncedCallback.flush = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    if (argsRef.current) {
      callback(...argsRef.current);
    }
  };

  return debouncedCallback;
}
