import type mapboxgl from 'mapbox-gl';
import { useEffect, useRef } from 'react';
import { type PopupProps, Popup } from 'react-map-gl';
import useMapContext from '~/src/features/dynamic-map/hooks/useMapContext';

// forwards wheel events to the map so that zooming works even if the mouse is over the popup
export default function PopupWithoutWheel(props: PopupProps) {
  const map = useMapContext();
  const ref = useRef<mapboxgl.Popup>(null);

  useEffect(() => {
    const element = ref.current?.getElement();

    const handleMapZooming = (e: WheelEvent) => {
      if (map == null) return;

      e.preventDefault();
      e.stopPropagation();

      const newEvent = new WheelEvent('wheel', e);
      map.getMap().getCanvas().dispatchEvent(newEvent);
    };

    element?.addEventListener('wheel', handleMapZooming);

    return () => {
      element?.removeEventListener('wheel', handleMapZooming);
    };
  }, [ref, map]);

  return <Popup {...props} ref={ref} />;
}
