import { parseEnv } from '@plotr/common-utils';

const env = parseEnv({ MAPBOX_API_KEY: process.env.MAPBOX_API_KEY });

export interface LayerInfo {
  id: string;
  description: string;
  fields?: { [key: string]: any };
}

export interface Tileset {
  vector_layers: LayerInfo[];
}

export type FetchTilesetProps =
  | {
      tilesetId: string;
    }
  | {
      tilesetURL: string;
    };

export async function fetchTileset(props: FetchTilesetProps): Promise<Tileset> {
  const tilesetId =
    'tilesetId' in props
      ? props.tilesetId
      : props.tilesetURL.replace('mapbox://', '');

  const url = `https://api.mapbox.com/v4/${tilesetId}.json?access_token=${env.MAPBOX_API_KEY}`;

  const res = await fetch(url);
  return res.json();
}
