.container {
  display: grid;
  height: 100vh;
  grid-template-columns: [begin-row] auto [row2] 550px [row3] auto [row-end];
  grid-template-rows: [begin] auto [line2] 300px [line3] auto [lineend];
}

.contained {
  background-color: #f2f7ff;
  grid-column: row2 / span 1;
  grid-row: line2 / span 1;

  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;

  border-radius: 30px;
  filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.534));
  color: #414141;
}

.h1 {
  font-size: 5rem;
}

.par {
  font-size: 2rem;
}
