import mapboxgl from 'mapbox-gl';
import licenseData from '../../../data/other/barber_cosmo_licenses.json';
import { useEffect, useState } from 'react';
import { Layer, Popup, Source } from 'react-map-gl';
import useDynamicMapStore from '../hooks/useDynamicMapStore';
import usePrevious from '~/src/common/hooks/usePrevious';
import useMapContext from '~/src/features/dynamic-map/hooks/useMapContext';

type LicenseInfo = Record<string, number>;
type LicenseInfoByZip = Record<string, LicenseInfo>;

function useLicenseInfo(
  licenseInfoByZip: LicenseInfoByZip,
  zipId: number | null,
) {
  const [licenseInfo, setLicenseInfo] = useState<LicenseInfo | null>(null);

  useEffect(() => {
    if (zipId == null) return;

    const zip = zipId.toString().padStart(5, '0');
    const licenseInfo = licenseInfoByZip[zip] ?? null;

    setLicenseInfo(licenseInfo);
  }, [licenseInfoByZip, zipId]);

  return licenseInfo;
}

export function ZipCodesSource(props: { showLayerZoom: number }) {
  const map = useMapContext();
  const zipsVisible = useDynamicMapStore((state) => state.zipsVisible);

  const [hoveredZipId, setHoveredZipId] = useState<number | null>(null);
  const prevHoveredZipId = usePrevious(hoveredZipId);

  const [highlightZip, setHighlightZip] = useState<boolean>(false);

  const [lngLat, setLngLat] = useState<[number, number]>([0, 0]);
  const licenseInfo = useLicenseInfo(licenseData, hoveredZipId);
  const [showPopup, setShowPopup] = useState(false);

  // Manage hovered zip-code highlighting
  useEffect(() => {
    if (map == null) return;

    if (prevHoveredZipId != null) {
      map.setFeatureState(
        {
          source: 'zips',
          sourceLayer: 'nationalzips',
          id: prevHoveredZipId,
        },
        { hover: false },
      );
    }

    if (hoveredZipId != null) {
      map.setFeatureState(
        {
          source: 'zips',
          sourceLayer: 'nationalzips',
          id: hoveredZipId,
        },
        { hover: highlightZip },
      );
    }
  }, [map, hoveredZipId, prevHoveredZipId, highlightZip]);

  // Set up mouse event handlers
  useEffect(() => {
    if (map == null) return;

    // Keep track of the hovered zip code & lat/lng for popup
    const mouseMoveHandler = (
      event: mapboxgl.MapMouseEvent & {
        features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
      } & mapboxgl.EventData,
    ) => {
      const zipId = event.features?.[0].id;

      if (map == null || typeof zipId !== 'number') return;

      setHoveredZipId(zipId);
      setLngLat([event.lngLat.lng, event.lngLat.lat]);
    };

    map.on('mousemove', 'zips-fill', mouseMoveHandler);

    // Show/hide the popup & highlight zips when the user mouses on/off the map
    const mouseOutHandler = () => {
      setShowPopup(false);
      setHighlightZip(false);
    };

    const mouseOverHandler = () => {
      setShowPopup(true);
      setHighlightZip(true);
    };

    map.on('mouseout', mouseOutHandler);
    map.on('mouseover', mouseOverHandler);

    return () => {
      map.off('mousemove', 'zips-fill', mouseMoveHandler);
      map.off('mouseout', mouseOutHandler);
      map.off('mouseover', mouseOverHandler);
    };
  }, [map, setHoveredZipId]);

  return (
    <Source id="zips" type="vector" url="mapbox://luketruitt1.dli35u78">
      <Layer
        id="zips-fill"
        type="fill"
        source="zips"
        source-layer="nationalzips"
        layout={{ visibility: zipsVisible ? 'visible' : 'none' }}
        minzoom={props.showLayerZoom}
        paint={{
          'fill-color': '#e7c200',
          'fill-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            0.7,
            0,
          ],
        }}
      />
      <Layer
        id="zips-border"
        type="line"
        source="zips"
        source-layer="nationalzips"
        layout={{ visibility: zipsVisible ? 'visible' : 'none' }}
        minzoom={props.showLayerZoom}
        paint={{
          'line-color': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            '#a38300',
            '#627BC1',
          ],
          'line-width': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            2,
            0.5,
          ],
          'line-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            1,
            0.1,
          ],
        }}
      />
      {showPopup && hoveredZipId != null && licenseInfo != null ? (
        <ZipcodePopup
          longitude={lngLat[0]}
          latitude={lngLat[1]}
          licenseInfo={licenseInfo}
          hoveredZipId={hoveredZipId}
        />
      ) : null}
    </Source>
  );
}

function ZipcodePopup({
  licenseInfo,
  hoveredZipId,
  latitude,
  longitude,
}: {
  licenseInfo: LicenseInfo;
  hoveredZipId: number;
  latitude: number;
  longitude: number;
}) {
  let totalNumLicenses = 0;

  if ('Barber' in licenseInfo) {
    totalNumLicenses += licenseInfo.Barber;
  }
  if ('Cosmetologist' in licenseInfo) {
    totalNumLicenses += licenseInfo.Cosmetologist;
  }

  return (
    <Popup
      latitude={latitude}
      longitude={longitude}
      closeButton={false}
      closeOnClick={false}
      anchor="bottom"
      offset={10}
      style={{ pointerEvents: 'none' }}
    >
      <div style={{ width: '160px' }}>
        <h6>Zipcode: {hoveredZipId.toString().padStart(5, '0')}</h6>
        <div style={{ lineHeight: '0.4rem', marginTop: '1rem' }}>
          <p>Barbershop Licenses: {licenseInfo.Barber ?? 0}</p>
          <p>Cosmetology Licenses: {licenseInfo.Cosmetologist ?? 0}</p>
          <p>Total Licenses: {totalNumLicenses}</p>
        </div>
      </div>
    </Popup>
  );
}
