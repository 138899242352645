import { User } from '@auth0/auth0-react';
import { Close as CloseIcon } from '@mui/icons-material';
import { DialogContent, IconButton, Typography } from '@mui/material';
import { parseEnv } from '@plotr/common-utils';

const env = parseEnv({
  STRIPE_PRICING_TABLE_ID: process.env.STRIPE_PRICING_TABLE_ID,
  STRIPE_PUBLISHABLE_KEY: process.env.STRIPE_PUBLISHABLE_KEY,
});

interface NewUserDialogProps {
  handleClose: () => void;
  userId: string;
  user: User | undefined;
  stripeFonts: string;
  header: string;
  subheader: string;
}

export default function NewUserDialog({
  handleClose,
  userId,
  user,
  stripeFonts,
  header,
  subheader,
}: NewUserDialogProps) {
  return (
    <DialogContent>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: '0.5rem',
          top: '0.5rem',
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography
        variant="h4"
        sx={{
          mb: 2,
          textAlign: 'center',
          fontWeight: 700,
          fontFamily: stripeFonts,
        }}
      >
        {header}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mb: 2,
          textAlign: 'center',
          fontFamily: stripeFonts,
        }}
      >
        {subheader}
      </Typography>
      <stripe-pricing-table
        pricing-table-id={env.STRIPE_PRICING_TABLE_ID}
        publishable-key={env.STRIPE_PUBLISHABLE_KEY}
        client-reference-id={userId}
        customer-email={user?.email}
      />
    </DialogContent>
  );
}
