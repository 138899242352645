import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  restrictToParentElement,
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';
import { Fragment, useState } from 'react';
import useLayersStore, {
  LayerCard,
} from '../../../../dynamic-map/hooks/useLayersStore';
import SortableItem from './SortableItem';

import AddLayerModal from './AddLayerModal';
import EditLayerModal, {
  type EditLayerMode,
} from './EditLayerModal/EditLayerModal';

import { Box, Button } from '@mui/material';

export enum LayerType {
  Insights = 'Insights Layer',
  Enterprise = 'Enterprise Layer',
  IndustryData = 'Industry Data Layer',
  LayerSet = 'Layer Set',
}

interface LayerManagerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function LayerManager({ open, setOpen }: LayerManagerProps) {
  // Access the map instance
  const layers = useLayersStore((state) => state.layers);
  const setLayers = useLayersStore((state) => state.setLayers);

  const [editLayerMode, setEditLayerMode] = useState<EditLayerMode | null>(
    null,
  );
  const [selectedLayer, setSelectedLayer] = useState<LayerCard | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const [activeId, setActiveId] = useState(null); // Track the active layer being dragged

  function handleDragStart(event: any) {
    setActiveId(event.active.id); // Set the active layer ID when dragging starts
  }

  function handleDragEnd(event: any) {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = layers.findIndex((layer) => layer.id === active.id);
      const newIndex = layers.findIndex((layer) => layer.id === over.id);

      const newLayers = arrayMove(layers, oldIndex, newIndex);

      setLayers(newLayers);
    }
    setActiveId(null); // Reset the active layer ID when dragging ends
  }

  const handleLayerSelect = ({
    clickedLayer,
    layerType,
    mode,
  }: {
    clickedLayer?: LayerCard;
    layerType?: LayerType;
    mode: 'create' | 'edit';
  }) => {
    // If user is adding a new layer, don't look for an existing layer
    if (mode === 'create' && layerType) {
      let newLayerDetails: LayerCard;
      if (layerType === LayerType.Insights) {
        // Create a new layer with default values based on the type
        newLayerDetails = {
          id: `layer-${layers.length.toString()}`,
          featureType: 'polygon',
          displayName: '',
          type: layerType,
          insight: '',
          opacity: 0.75,
          boundLabels: [],
          styleConfig: {
            colors: [],
            threshold: [],
          },
        };
      } else if (layerType === LayerType.Enterprise) {
        newLayerDetails = {
          id: '',
          featureType: 'polygon',
          displayName: '',
          type: layerType,
          insight: '',
          opacity: 0.75,
          boundLabels: [],
          styleConfig: {
            colors: [],
            threshold: [],
          },
        };
      } else {
        return;
      }

      setSelectedLayer(newLayerDetails);
    } else if (mode === 'edit' && clickedLayer) {
      setSelectedLayer(clickedLayer);
    }
    setEditLayerMode(mode); // Open the edit layer dialog in either case
    setOpen(false); // Close the first dialog
  };

  const handleEditLayerClose = () => {
    setEditLayerMode(null);
    setSelectedLayer(null);
  };

  const handleSaveLayer = (layerDetails: LayerCard) => {
    const layerExists =
      selectedLayer?.id &&
      layers.some((layer) => layer.id === selectedLayer.id);

    if (layerExists) {
      setLayers(
        layers.map((layer) =>
          layer.id === selectedLayer.id ? { ...layer, ...layerDetails } : layer,
        ),
      );
    } else {
      const newLayer: LayerCard = {
        ...layerDetails,
      };
      setLayers([newLayer, ...layers]);
    }

    // Reset selected layer and close dialog
    setSelectedLayer(null);
    setEditLayerMode(null);
  };

  function handleRemoveLayer(id: string) {
    setLayers(layers.filter((item) => item.id !== id));
  }

  const handleLayerDuplicate = (layerToDuplicate: LayerCard) => {
    const generateUniqueName = (
      baseName: string,
      property: keyof LayerCard,
    ): string => {
      let newName = baseName + ' copy';
      let counter = 1;
      while (layers.some((layer) => layer[property] === newName)) {
        newName = `${baseName} copy (${counter})`;
        counter++;
      }
      return newName;
    };

    // Call the generateUniqueName function to get a unique ID and display name
    const uniqueId = generateUniqueName(layerToDuplicate.id, 'id');
    const uniqueDisplayName = generateUniqueName(
      layerToDuplicate.displayName,
      'displayName',
    );

    // Find the index of the layer to duplicate
    const sourceIndex = layers.findIndex(
      (layer) => layer.id === layerToDuplicate.id,
    );

    // Create a new layer object with the unique ID and display name
    const newLayer: LayerCard = {
      ...layerToDuplicate,
      id: uniqueId,
      displayName: uniqueDisplayName,
    };

    // Insert the new layer right after the source layer
    setLayers([
      ...layers.slice(0, sourceIndex + 1), // Elements before and including the source layer
      newLayer, // The new duplicated layer
      ...layers.slice(sourceIndex + 1), // Elements after the source layer
    ]);
  };

  return (
    <>
      {layers.length === 0 && (
        <>
          <Box sx={{ fontWeight: 'light' }}>
            Map layers allow you to visually display and organize geographic
            information such as population density, household income, and more.
          </Box>
          <Button
            variant="contained"
            onClick={() => setOpen(true)}
            style={{
              backgroundColor: '#FFA726', // Example orange color, adjust as needed
              fontSize: '1rem',
              color: 'white',
              border: 'none',
              borderRadius: '5px', // Adjust for desired roundness
              padding: '10px 20px', // Adjust for desired size
              textTransform: 'none',
              boxShadow: 'none',
              width: '50%', // Remove Material-UI default shadow
              // fontWeight: 'bold', // Adjust if needed
            }}
          >
            Add Your First Layer
          </Button>
        </>
      )}
      <AddLayerModal
        open={open}
        onClose={() => setOpen(false)}
        onSelect={handleLayerSelect}
      />
      <EditLayerModal
        mode={editLayerMode}
        selectedLayer={selectedLayer}
        onClose={handleEditLayerClose}
        onSave={handleSaveLayer}
      />
      <div
        style={{
          display: 'flex', // Make it a flex container
          flexDirection: 'column', // Stack children vertically
          flexGrow: 1, // Take up all available space
          minHeight: 0, // Prevent flexbox items from overflowing
          overflowY: 'auto', // Add scroll on Y-axis when content overflows
          rowGap: '0.5rem', // Add spacing between children
          maxWidth: '100%',
        }}
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          modifiers={[
            restrictToVerticalAxis,
            restrictToWindowEdges,
            restrictToParentElement,
          ]}
        >
          {activeId && (
            <DragOverlay>
              <SortableItem
                index={layers.findIndex((layer) => layer.id === activeId)}
                onEdit={() => {}}
                onDuplicate={() => {}}
                onRemove={() => {}}
              />
            </DragOverlay>
          )}

          <SortableContext
            items={layers}
            strategy={verticalListSortingStrategy}
          >
            {layers.map((layer, index) => (
              <Fragment key={layer.id}>
                <SortableItem
                  index={index}
                  onEdit={() =>
                    handleLayerSelect({ clickedLayer: layer, mode: 'edit' })
                  }
                  onDuplicate={() => handleLayerDuplicate(layer)}
                  onRemove={() => handleRemoveLayer(layer.id)}
                />
              </Fragment>
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </>
  );
}
