import type { SetStateAction } from 'react';
import type { Doc as YDoc } from 'yjs';
import { createContext, useContext } from 'react';

export type Presence<T> = Record<string, T>;

export interface IMultiplayerContext<T extends object> {
  setLocalPresence: (nextState: SetStateAction<T | null>) => void;
  localPresence: T | null;
  presence: Presence<T> | null;
  isLoading: boolean;
  error: number | null;
  ydoc: YDoc | null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MultiplayerContext = createContext<IMultiplayerContext<any>>({
  setLocalPresence: () => {},
  localPresence: null,
  presence: null,
  isLoading: true,
  error: null,
  ydoc: null,
});

const useMultiplayerContext = <T extends object>(): IMultiplayerContext<T> => {
  const context = useContext<IMultiplayerContext<T>>(MultiplayerContext);
  if (context == null) {
    throw new Error(
      'MultiplayerContext cannot be accessed outside of a MultiplayerProvider'
    );
  }
  return context;
};

export default useMultiplayerContext;
