import { point } from '@turf/helpers';
import { useEffect } from 'react';

import useAccessToken from '~/src/global/hooks/useAccessToken';
import useSettingsStore from '~/src/global/hooks/useSettingsStore';
import useCustomTerritories from '../../dynamic-map/hooks/useCustomTerritories';
import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';
import useDemographicStore from '../hooks/useDemographicStore';
import demographicSearch from '../services/demographicSearch';
import getPointBoundary from '../services/getPointBoundary';
import getTerritoryBoundary from '../services/getTerritoryBoundary';

export default function useDemographicSearch() {
  const boundaryType = useDemographicStore((state) => state.boundaryType);
  const radius = useDemographicStore((state) => state.demographicSearchRadius);
  const boundaryData = useDemographicStore((state) => state.boundaryData);
  const setBoundaryData = useDemographicStore((state) => state.setBoundaryData);
  const setSummaryData = useDemographicStore(
    (state) => state.setDemographicSummaryData
  );
  const setIsDemographicDataLoading = useDemographicStore(
    (state) => state.setIsDemographicDataLoading
  );
  const { accessToken } = useAccessToken();
  const evaluatedDemographicEntity = useDynamicMapStore(
    (state) => state.evaluatedDemographicEntity
  );
  const customTerritories = useCustomTerritories();
  const userSettings = useSettingsStore((state) => state.userSettings);

  const isPoint = evaluatedDemographicEntity?.type === 'point';
  const isTerritory = evaluatedDemographicEntity?.type === 'territory';

  const pointPos = isPoint ? evaluatedDemographicEntity.pos : null;
  const territory = isTerritory
    ? customTerritories.find((t) => t.id === evaluatedDemographicEntity.id)
    : null;

  // Set boundary data for demographic point search
  useEffect(() => {
    if (radius == null || pointPos == null) return;

    setBoundaryData(null);
    setIsDemographicDataLoading(true);
    getPointBoundary({
      pointFeature: point([pointPos.lng, pointPos.lat]),
      boundaryType: boundaryType,
      radius: radius,
    }).then((boundary) => {
      setBoundaryData(boundary);
    });
  }, [
    pointPos,
    radius,
    boundaryType,
    setBoundaryData,
    setIsDemographicDataLoading,
  ]);

  // Set boundary data for demographic territory search
  useEffect(() => {
    if (territory == null || accessToken == null) return;

    setBoundaryData(null);
    setIsDemographicDataLoading(true);
    getTerritoryBoundary({
      accessToken,
      territory,
    }).then((boundary) => {
      setBoundaryData(boundary);
    });
  }, [accessToken, territory, setBoundaryData, setIsDemographicDataLoading]);

  // Fetch demographic search data (avoid race conditions with abort controllers)
  useEffect(() => {
    if (boundaryData == null || accessToken == null) return;

    const abortController = new AbortController();
    const abortSignal = abortController.signal;

    demographicSearch({
      accessToken,
      feature: boundaryData,
      radius,
      userSettings,
    })
      .then((data) => {
        if (!abortSignal.aborted) {
          setIsDemographicDataLoading(false);
          setSummaryData(data);
        }
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          throw err;
        }
      });

    return () => {
      abortController.abort();
    };
  }, [
    boundaryData,
    accessToken,
    radius,
    setIsDemographicDataLoading,
    setSummaryData,
    userSettings,
  ]);
}
