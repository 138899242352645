import { useEffect, useState } from 'react';

export interface RoomPasswordResult {
  password: string | null;
  error: number | null;
}

const useRoomPassword = (
  api: string,
  room: string | null,
  token: string | null,
): RoomPasswordResult => {
  const [password, setPassword] = useState<string | null>(null);
  const [error, setError] = useState<number | null>(null);

  // authenticate user to get password for room
  useEffect(() => {
    if (room == null || token == null) return;

    const authEndpoint = `${api}/room/${room}/auth`;

    fetch(authEndpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          setError(res.status);
          throw new Error(
            `Error getting password from auth endpoint, ${authEndpoint}`,
          );
        }
        return res;
      })
      .then(async (res) => await res.json())
      .then((json) => {
        const password =
          json != null && typeof json === 'object' && 'password' in json
            ? json.password
            : null;

        if (password == null || typeof password !== 'string') {
          throw new Error(`Invalid password for room, ${room}`);
        }

        setPassword(password);
      })
      .catch(console.error);

    return () => {
      setPassword(null);
      setError(null);
    };
  }, [api, room, token]);

  return {
    password,
    error,
  };
};

export default useRoomPassword;
