import { create } from 'zustand';
import type { ClientGeometry } from '../services/clientGeometriesService';
import * as ClientGeometriesService from '../services/clientGeometriesService';

export interface ClientGeometriesState {
  clientGeometries: ClientGeometry[];
  hiddenGeometryFiles: string[];
  toggleGeometryVisibility: (filename: string) => void;
  error: Error | null;
  isLoading: boolean;
}

export interface ClientGeometriesActions {
  fetchClientGeometries: (
    apiPath: string,
    clientGeometries: ClientGeometry[],
  ) => Promise<void>;
}

export const client_geometries_store_name = 'client-geometries-store';

type ClientGeometriesStore = ClientGeometriesState & ClientGeometriesActions;

const useClientGeometriesStore = create<ClientGeometriesStore>((set) => ({
  clientGeometries: [],
  error: null,
  isLoading: false,
  async fetchClientGeometries(
    apiPath: string,
    clientGeometries: ClientGeometry[],
  ) {
    set({ isLoading: true, error: null });

    try {
      const geometries = await ClientGeometriesService.fetchClientGeometries(
        apiPath,
        clientGeometries,
      );
      set({ clientGeometries: geometries, isLoading: false, error: null });
    } catch (error: unknown) {
      if (error instanceof Error) {
        set({ error, isLoading: false });
      } else {
        console.error('Error fetching client geometries: ', error);
        set({ error: new Error('Unknown error'), isLoading: false });
      }
    }
  },
  hiddenGeometryFiles: [],
  toggleGeometryVisibility(filename: string) {
    set((state) => {
      const hiddenGeometryFiles = state.hiddenGeometryFiles.includes(filename)
        ? state.hiddenGeometryFiles.filter((file) => file !== filename)
        : [...state.hiddenGeometryFiles, filename];
      return { hiddenGeometryFiles };
    });
  },
}));

export default useClientGeometriesStore;
