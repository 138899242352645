import { YCustomTerritory } from '@plotr/plotr-multiplayer-data';
import { Rule } from '@plotr/plotr-multiplayer-data/src/lib/rulesets';
import { useMultiplayer } from '@plotr/react-multiplayer';

export default function useRulesets() {
  return useMultiplayer.State<Rule[]>(
    (ydoc) =>
      ydoc
        ?.getArray<YCustomTerritory>('rulesets')
        ?.toArray()
        .map((v) => v.toJSON() as Rule) ?? []
  );
}
