import { ExpressionSpecification } from 'mapbox-gl';

export const exponentialTextRatio = 3 / 4;
export const defaultIconSize = 0.3;
export const defaultTextSize = 16;
export const defaultZoomStops = [10, 16];
export const exponentialIconRatio = 2 / 9;
export const increasedIconSize = 0.304;

export const defaultPOILayer = {
  source: 'poi-places',
  'source-layer': 'poi-places',
  type: 'symbol' as const,
  minzoom: 9.5,
  layout: {
    visibility: 'none' as const,
    'icon-anchor': 'bottom' as const,
    'icon-allow-overlap': true,
    'icon-size': {
      type: 'exponential' as const,
      stops: [
        [defaultZoomStops[0], defaultIconSize * exponentialIconRatio] as [
          number,
          number,
        ],
        [defaultZoomStops[1], defaultIconSize] as [number, number],
      ],
    },
    'text-field': ['get', 'name'] as ['get', string],
    'text-allow-overlap': true,
    'text-anchor': 'top' as const,
    'text-size': {
      type: 'exponential' as const,
      stops: [
        [defaultZoomStops[0], defaultTextSize * exponentialTextRatio] as [
          number,
          number,
        ],
        [defaultZoomStops[1], defaultTextSize] as [number, number],
      ],
    },
  },
  paint: {
    'text-halo-color': 'rgba(255, 255, 255, 0.5)',
    'text-halo-width': 2,
    'icon-opacity': {
      type: 'exponential' as const,
      stops: [[10.5, 0] as [number, number], [11, 1] as [number, number]],
    },
    'text-opacity': {
      type: 'exponential' as const,
      stops: [[10.5, 0] as [number, number], [11, 1] as [number, number]],
    },
  },
};

export function generatePOIFilter(brandIds: string[]): ExpressionSpecification {
  const maxBrandsPerPOI = 24; // the most brands on any given POI is 24 as of (2023-11-17)
  const filterExpression: ExpressionSpecification = ['any'];

  for (let i = 1; i <= maxBrandsPerPOI; i += 1) {
    const expression: ExpressionSpecification = [
      'all',
      ['has', `brandId${i}`],
      ['in', ['get', `brandId${i}`], ['literal', brandIds]],
    ];

    filterExpression.push(expression);
  }

  return filterExpression;
}
