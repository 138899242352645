import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Divider, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import CustomTerritoryCardView from './CustomTerritoryCardView';
import CustomTerritoryGroupCardView from './CustomTerritoryGroupCardView';

import {
  FormatColorFill,
  FormatColorFillOutlined,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import useCustomTerritories from '../../dynamic-map/hooks/useCustomTerritories';
import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';
import GlobalTerritorySettingsMenu from './GlobalTerritorySettingsMenu';

const CustomTerritoriesMenu = () => {
  const territoriesEnabled = useDynamicMapStore(
    (state) => state.territoriesEnabled
  );
  const toggleTerritoriesEnabled = useDynamicMapStore(
    (state) => state.toggleTerritoriesEnabled
  );

  const territoryFillEnabled = useDynamicMapStore(
    (state) => state.territoryFillEnabled
  );

  const toggleTerritoryFillEnabled = useDynamicMapStore(
    (state) => state.toggleTerritoryFillEnabled
  );

  const evaluatedTerritoryId = useDynamicMapStore(
    (state) => state.evaluatedTerritoryId
  );

  const setEvaluatedTerritoryId = useDynamicMapStore(
    (state) => state.setEvaluatedTerritoryId
  );

  const selectedTerritoryGroup = useDynamicMapStore(
    (state) => state.selectedTerritoryGroup
  );

  const setSelectedTerritoryGroup = useDynamicMapStore(
    (state) => state.setSelectedTerritoryGroup
  );

  const customTerritories = useCustomTerritories();

  const evaluatedTerritory = useMemo(() => {
    if (evaluatedTerritoryId == null) return null;
    return customTerritories.find(({ id }) => id === evaluatedTerritoryId);
  }, [customTerritories, evaluatedTerritoryId]);

  const breadcrumbs: { label: string; onClick?: () => void }[] = useMemo(() => {
    const defaultBreadcrumbs = [
      {
        label: 'Territory Groups',
        ...(selectedTerritoryGroup != null || evaluatedTerritory != null
          ? {
              onClick: () => {
                setSelectedTerritoryGroup(null);
                setEvaluatedTerritoryId(null);
              },
            }
          : {}),
      },
    ];

    if (evaluatedTerritory == null && selectedTerritoryGroup != null) {
      return [...defaultBreadcrumbs, { label: selectedTerritoryGroup }];
    }

    if (evaluatedTerritory != null) {
      return [
        ...defaultBreadcrumbs,
        {
          label: evaluatedTerritory.group,
          onClick: () => {
            setSelectedTerritoryGroup(evaluatedTerritory.group);
            setEvaluatedTerritoryId(null);
          },
        },
      ];
    }

    return defaultBreadcrumbs;
  }, [
    evaluatedTerritory,
    selectedTerritoryGroup,
    setEvaluatedTerritoryId,
    setSelectedTerritoryGroup,
  ]);

  return (
    <Box display="flex" height="100%" flexDirection="column">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        flexShrink={0}
        // paddingX={1}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width={'100%'}
        >
          <Typography variant="h6" sx={{ marginRight: 2 }}>
            Territory Builder
          </Typography>
          <Box display="flex" alignItems="center">
            <Divider orientation="vertical" flexItem sx={{ marginRight: 1 }} />
            <Box display="flex" alignItems="center" gap={1}>
              <Tooltip
                title={
                  territoriesEnabled ? 'Hide Territories' : 'Show Territories'
                }
                PopperProps={{
                  modifiers: [
                    {
                      name: 'preventOverflow',
                      options: {
                        boundary: 'window',
                      },
                    },
                  ],
                }}
              >
                <IconButton
                  onClick={toggleTerritoriesEnabled}
                  color={territoriesEnabled ? 'primary' : 'default'}
                >
                  {territoriesEnabled ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Fill Territories"
                PopperProps={{
                  modifiers: [
                    {
                      name: 'preventOverflow',
                      options: {
                        boundary: 'window',
                      },
                    },
                  ],
                }}
              >
                <IconButton
                  onClick={toggleTerritoryFillEnabled}
                  color={
                    territoriesEnabled && territoryFillEnabled
                      ? 'primary'
                      : 'default'
                  }
                  disabled={!territoriesEnabled}
                >
                  {territoryFillEnabled ? (
                    <FormatColorFill />
                  ) : (
                    <FormatColorFillOutlined />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box
        display="flex"
        flexDirection="column"
        // paddingLeft={1}
        gap={1}
        flexShrink={0}
        paddingX={1}
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{ minHeight: '50px' }}
          paddingY={1}
        >
          {(selectedTerritoryGroup != null || evaluatedTerritory != null) && (
            <IconButton
              onClick={() => {
                if (evaluatedTerritory != null) {
                  setEvaluatedTerritoryId(null);
                } else if (selectedTerritoryGroup != null) {
                  setSelectedTerritoryGroup(null);
                }
              }}
            >
              <ArrowBackIcon fontSize="small" />
            </IconButton>
          )}
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs.map((crumb, index) => {
              const clickable = crumb.onClick != null;
              const textColor =
                clickable || index === 0 ? 'text.primary' : 'text.disabled';

              return (
                <Typography
                  key={crumb.label}
                  variant="body1"
                  color={textColor}
                  onClick={clickable ? crumb.onClick : undefined}
                  sx={{
                    cursor: clickable ? 'pointer' : 'default',
                    textDecoration: clickable ? 'underline' : 'none',
                  }}
                >
                  {crumb.label}
                </Typography>
              );
            })}
          </Breadcrumbs>
        </Box>
      </Box>
      <Box flex="1 1 auto" overflow="auto">
        {selectedTerritoryGroup != null ? (
          <CustomTerritoryCardView />
        ) : (
          <CustomTerritoryGroupCardView />
        )}
      </Box>
      <Box flexShrink={0}>
        <GlobalTerritorySettingsMenu />
      </Box>
    </Box>
  );
};

export default CustomTerritoriesMenu;
