import useMultiplayerContext from './helpers/useMultiplayerContext';
import useSyncedState, { type GetStateFn } from './helpers/useSyncedState';

const useMultiplayer = {
  State: <T>(getState: GetStateFn<T>) => {
    return useSyncedState<T>(getState);
  },
  Presence: <T extends object>() => {
    const { presence, localPresence, setLocalPresence } =
      useMultiplayerContext<T>();
    return { presence, localPresence, setLocalPresence };
  },
  Error: () => {
    const { error } = useMultiplayerContext();
    return error;
  },
  Loading: () => {
    const { isLoading } = useMultiplayerContext();
    return isLoading;
  },
};

export default useMultiplayer;
